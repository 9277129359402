import { useEffect, useState } from "react";
import { useLazyGetAdminByIdQuery } from "../../app/services/adminServices";
import { useLazyGetChapterDetailsByIdQuery } from "../../app/services/chapterServices";



const GetChapterName = ({ id }) => {
  const [chapter, setChapter] = useState('')
  const [chapterTitle,setChapterTitle]=useState('')
  const [getAdminData] = useLazyGetAdminByIdQuery()
  const [getChapterDetails] = useLazyGetChapterDetailsByIdQuery();
  useEffect(() => {
    getAdminData(id).then(res => {
      if (res?.data?.status) {
        setChapter(res?.data?.data?.user)
      }
    })
  }, [getAdminData, id])
  useEffect(() => {
    if (chapter) {
      getChapterDetails(chapter).then(res => {
        setChapterTitle(res?.data?.data?.title)
      })
    }
  }, [getChapterDetails, chapter])

  return <div className="text-capitalize">{chapterTitle?chapterTitle:'-----'}</div>;
};
export default GetChapterName;