import { createSlice } from "@reduxjs/toolkit";
const chapterAdminSlice = createSlice({
    name: 'chapter',
    
    //intialState
    initialState: {
        chapterDetails: {},
        roleDetails: {},
    },
    //reducer
    reducers: {
        setChapterDetails: (state, action) => {
            state.chapterDetails = action.payload;
        },
        setRoleDetails: (state, action) => {
            state.roleDetails = action.payload;
        } 
    } 
})

export default chapterAdminSlice.reducer
export const { setChapterDetails } = chapterAdminSlice.actions
export const { setRoleDetails } = chapterAdminSlice.actions
export const selectCurrentChapterAdmin = (state) => state.chapter.chapterDetails
export const selectCurrenChapterRole = (state) => state.chapter.roleDetails