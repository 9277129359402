import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import TopHeader from "../../Component/Header/TopHeader";
import {
  useGetAllTestimonialPaginationMutation,
  useDeleteTestimonialMutation,
} from "../../app/services/testimonialService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import Tooltip from "@mui/material/Tooltip";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";

//table Features
function EnhancedTableHead() {
  const headCells = [
    {
      id: "userImage",
      numeric: false,
      disablePadding: true,
      label: "User Image",
      class: "menus-color",
      width: '300px',
      padding:'16px 16px !importan'
    },
    { id: "name", numeric: true, disablePadding: false, label: "Name", class: "menus-color" },
    { id: "content", numeric: true, disablePadding: false, label: "Content", class: "menus-color" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions", class: "menus-color" },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff", width: headCell?.width ? headCell?.width : "auto", padding: '16px 16px !important' }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={headCell.class ? headCell.class : ""}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        backgroundColor: "#0072bb",
        textAlign: "center",
        color: "white",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {numSelected > 0 && numSelected
          ? `${numSelected} selected`
          : "Member List"}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const ViewTestimonial = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [allTestimonialPagination] = useGetAllTestimonialPaginationMutation();
  const [deleteTestimonial] = useDeleteTestimonialMutation();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedText, setSelectedText] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [allTestimonialData, setAllTestimonialData] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const memberScope = useSelector(selectCurrentScope);
    const [searchTerm, setSearchTerm] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [error, setError] = useState("");

  //get all pagination for testimonial
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
    };
    allTestimonialPagination(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setTotalPage(res?.data?.data?.totalCount || 0);
        setAllTestimonialData(res.data?.data?.testimonialDetails || []);
      } else {
        setAllTestimonialData([])
        setLoading(false);
      }
    });
  }, [allTestimonialPagination, page, itemsPerPage]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  //sliced newsLetter
  //  const slicedTestimonial = allTestimonialData.slice(0, rowsPerPage); // Adjust slicing

  //page click
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  //edit route
  const handleEdit = (id) => {
    if (memberScope === 'PORTAL_SUPER_ADMIN') {
      navigate("/admin/addEditTestimonials/" + id);

    }
    else {
      navigate("/assistantadmin/addEditTestimonials/" + id);

    }
  };

  const handleOpen1 = (event) => {
    setOpen1(true);
    setSelectedText(event);
  };

  const handleClose1 = () => setOpen1(false);

  //delete function for testimonial
  const handleDelete = () => {
    setLoading(true);
    deleteTestimonial(selectedMember).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.error("Testimonial Deleted Successfully!!!");
        handleClose();
        if (allTestimonialData.length <= 1) {
          setPage(page - 1);
          getAllPagination();
        } else {
          getAllPagination();
        }
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Testimonials List</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='p-md-5 p-3 overallPadding'>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
              <span
                style={{ width: '200px' }}
                className="ms-1"
              ></span>
            </div>
            {memberScope === 'PORTAL_SUPER_ADMIN' && (
              <Link to="/admin/addEditTestimonials" className='mt-0'>
                <Button
                  variant="contained"
                  sx={{ width: "max-content", float: "right" }}
                  className="menus-color"
                >
                  Add Testimonial
                </Button>
              </Link>
            )}
            {memberScope === 'PORTAL_ADMIN' && (
              <Link to="/assistantadmin/addEditTestimonials" className='mt-0'>
                <Button
                  variant="contained"
                  sx={{ width: "max-content", float: "right" }}
                  className="menus-color"
                >
                  Add Testimonial
                </Button>
              </Link>
            )}
          </div>
          <Box className="memberTable mb-5 p-0">
            <Paper sx={{ width: "100%", mb: 4 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {allTestimonialData.length > 0 ? (
                      allTestimonialData.map((testimonial, index) => (
                        <TableRow
                          hover
                          key={testimonial._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            <img
                              src={
                                testimonial.imageUrl
                                  ? `${testimonial.imageUrl}?t=${new Date().getTime()}`
                                  : "/assets/images/login/userLogo.png"
                              }
                              width={"40px"}
                              height={"40px"}
                              alt="View Testimonial"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {testimonial.name}
                          </TableCell>
                          <TableCell align="center" onClick={() => handleOpen1(testimonial)}>
                            {testimonial.content.slice(0, 50)}...
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  handleEdit(testimonial._id);
                                }}
                              >
                                <EditIcon style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleOpen(testimonial._id);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow hover>
                          <TableCell colSpan={4} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        sx={style}
                                        className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                                    >

                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                                                Confirmation
                                            </Typography>
                                            <IconButton onClick={handleClose} aria-label="Close">
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        <Box display="flex" justifyContent="center" width="100%">
                                            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                        </Box>
                                        <Typography
                                            id="modal-modal-title"
                                            style={{ color: "#611f1e" }}
                                            variant="h6"
                                            component="h2"
                                        >
                                            Are You Surely Want to Delete this Testimonial?
                                        </Typography>
                                        {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button
                                                variant="outlined"
                                                style={{

                                                    color: "#dc3545",
                                                    border: "1px solid #dc3545",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={handleClose}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="mx-2"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#2e7d32",
                                                    color: "white",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={() => handleDelete()}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </Box>
                                </Modal>
                  <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <h4 style={{ color: "#104e29" }}>Testimonial Content</h4>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        {selectedText?.content}
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "red",
                            border: "1px solid red",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose1}
                        >
                          Close
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
              {allTestimonialData.length > 0 ? (
                <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        min={1}
                        placeholder="Page no."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>

                  {/* Pagination in the Center */}
                  <div className="my-3 my-md-0">
                    <ReactPaginate
                      pageCount={pageCount} // Calculate total pages
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                      nextLabel={
                        pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                      }
                      disabledClassName={"disablebtn"}
                      forcePage={page}
                    />
                  </div>
                  <div className="mb-2 mb-md-0">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('');
                          setSearchTerm('');
                          setPage(0);
                        }}
                        MenuProps={{
                          MenuListProps: {
                            sx: {
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          },
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>

                    </FormControl>
                  </div>
                </div>
              ) : (
                <></>
              )}
          </Box>
        </div>
      )}
    </>
  );
};

export default ViewTestimonial;
