import { api } from "./api";
 
const adminServices = api.injectEndpoints({
  tagTypes:['adminServices'],
  endpoints: (builder) => ({ 
    //update Admin
    updateAdmin: builder.mutation({
      query: ({ id, data }) => ({
        url: `admin/updateAdmin/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["adminServices"],
    }),
    getAdminById: builder.query({
      query: (id) => ({
        url: `admin/get/${id}`,
        method: "get",
      }),
      providesTags: ["adminServices"],
    }),
  }),
  overrideExisting: false,
});
 
export const {
  useUpdateAdminMutation,
  useLazyGetAdminByIdQuery,
} = adminServices;