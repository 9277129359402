import {
  Alert,
  FormHelperText,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
// import { useLazyGetAllChaptersQuery } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { industries, planType } from "../../../constant/constant";
import { Country, State } from "country-state-city";
import {
  useRemoveMemberShipPermanentlyMutation,
  useLazyGetAllMemberShipDetailsQuery,
} from "../../../app/services/memberShipServices";
import {
  useCreateMemberMutation,
  useLazyGetMemberDetailsByIdQuery,
} from "../../../app/services/memberServices";
import { useNavigate } from "react-router-dom";
import { selectCurrentScope } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import InvoiceEmailTemplate from "../InvoiceEmailTemplate"
import html2pdf from "html2pdf.js";


const steps = ["Personal Information", "Payment Information"];
//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  minWidth: 300,
  height: '70%',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
};
const style1 = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  minWidth: 300,
  height: '60%',
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  overflowY: 'scroll'
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  minWidth: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
  height: '80%'
};

function AddEditCorporate() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  // const [selectedChapters, setSelectedChapters] = useState([]);
  // const [getAllChapters] = useLazyGetAllChaptersQuery();
  const [getAllplans] = useLazyGetAllMemberShipDetailsQuery();
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [selectedState, setSelectedState] = useState("");
  const [createMember] = useCreateMemberMutation();
  const countries = Country.getAllCountries();
  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry)
    : [];
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const navigate = useNavigate();
  const [costType, setCostType] = useState([]);
  const [deleteMember] = useRemoveMemberShipPermanentlyMutation();
  const [open, setOpen] = useState(false);
  const [memberDetails, setMemberDetails] = useState('')
  const memberScope = useSelector(selectCurrentScope);
  const [openForm, setOpenForm] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [newFormData, setNewFormData] = useState('')
  const [openInvoice, setOpenInvoice] = useState(false);
  const emailRef = useRef(null);
  const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
  const [memberDetailformail, setMemberDetailformail] = useState()



  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      type: 3,
      city: "",
      pinCode: "",
      state: "",
      country: countries.find(c => c.isoCode === 'IN').isoCode,
      mobileNumber: "",
      landlineNumber: "",
      address: "",
      email: "",
      role: "PORTAL_MEMBER",
      websiteAddressOrganisation: "",
      organisationEstablished: "",
      chapter: "",
      typeOfOrganisation: "",
      uploadOrganisationLogo: "",
      headOfficeLocated: "",
      addressedTo: "",
      employees: "",
      industry: "",
      dummyPlanId: "",
      dummyPlanTypeId: "",
      dummyAmount: "",
      dummyCurrency: "INR",
      organisationName: "",

    },
  });

  const {
    register: registerAdd,
    setValue: setValueAdd,
    watch: watchAdd,
    formState: { errors: errorsAdd },
    handleSubmit: handleSubmitAdd,
    control: controlAdd,
    clearErrors: clearErrorsAdd,
  } = useForm({
    defaultValues: {
      scope: memberScope ? memberScope : '',
      paymentMethod: "",
      emailSent: 0,
      paymentType: "0",
      currency: '',
      amount: '',
      bankTransactionId: ''
    },
    mode: 'onChange'
  })


  useEffect(() => {
    const handlePopState = () => {
      // You can check if you are on a specific route or just redirect unconditionally
      // For example, redirect to "/another" if the back button is pressed
      if (watch("paymentStatus")) {
        navigate("/");
      }
      else {
        window.history.back()
      }
    };

    window.history.pushState(null, "", window.location.href); // Adds an extra history entry
    window.onpopstate = handlePopState;

    return () => {
      window.onpopstate = null; // Clean up when the component is unmounted
    };
  }, [navigate, watch]);

  const selectedplantypeId = watch("dummyPlanTypeId");

  useEffect(() => {
    getAllplans().then((res) => {
      if (res?.data?.status) {
        const filteredPlan = res?.data?.data?.find(
          (item) =>
            item.isDeleted === 0 && item.quantity === 1 && item.planName === "4"
        );
        setValue("dummyPlanId", filteredPlan?._id);
        setValue(
          "planName",
          filteredPlan
            ? planType.find((i) => i?.planId === "4")?.planName
            : "No active plan for this member type"
        );
        setCostType(filteredPlan?.planType || []);
      }
    });
  }, [getAllplans, setValue, setCostType]);

  useEffect(() => {
    costType.forEach((data) => {
      if (data._id === selectedplantypeId) {
        const amountToSet = data.offer === 0 ? data.amount : data.offerAmount;
        setAmount(amountToSet);
        setCurrency(data.currencyType);
      }
    });
  }, [costType, selectedplantypeId]);

  useEffect(() => { }, [costType]);

  useEffect(() => {
    if (amount && currency) {
      setValue("dummyAmount", amount);
      setValue("dummyCurrency", currency);
      setValueAdd("amount", amount)
      setValueAdd("currency", currency);
    }
  }, [amount, currency, setValue, setValueAdd]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
  };

  const handleFormClose = () => {
    setOpenForm(false)
  }

  //  function to handle form submission with isGenerateInvoice data
  const onSubmit = (callback, isGenerateInvoice) => (data) => {
    // Add the isGenerateInvoice to the form data
    const formData = { ...data, isGenerateInvoice };
    callback(formData); // Call AddEditCorporate with the modified data
  };

  const handlePaymentCancelled = useCallback((id) => {
    toast.info("Payment was not completed.");
    deleteMember(id)
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [deleteMember])


  //On submit for save
  const AddEditCorporate = useCallback((data) => {
    if (activeStep === steps.length - 1) {
      setLoading(true);
      if (memberScope && !isPaymentCompleted && (data.isGenerateInvoice === false || data.isGenerateInvoice === undefined)) {
        setOpenForm(true); // Open modal instead of submitting
        setValueAdd("amount", amount)
        setValueAdd("currency", currency);
        setLoading(false);
        return;
      }
      const updatedData = { ...data, country: countries.find(c => c.isoCode === data?.country).name }

      const formData = new FormData();
      Object.entries(updatedData).forEach(([key, value]) => {
        if (value instanceof FileList) {
          if (key !== 'uploadOrganisationLogoFile') {
            formData.append(key, value[0]);
          }
        } else if (
          key !== "sigGroups" &&
          key !== "dummyAmount" &&
          key !== "dummyCurrency"
        ) {
          formData.append(key, value);
        }
      });
      //if the admin crete the member have to send the details in form data
      if (memberScope && (data.isGenerateInvoice === false || data.isGenerateInvoice === undefined)) {
        formData.set("emailSent", 1);  // Ensures only one value is stored
        formData.set("bankTransactionId", data.bankTransactionId || "");
        formData.set("paymentMethod", data.paymentMethod || "");
        formData.set("paymentType", data.paymentType || 0)

      }
      if (memberScope && data.isGenerateInvoice === true) {
        formData.set("scope", memberScope)
      }
      formData.append("dummyAmount", amount);
      formData.append("dummyCurrency", currency);
      if (data.sigGroups && data.sigGroups.length > 0) {
        data.sigGroups.forEach((item) => {
          formData.append("sigGroups", item);
        });
      }
      createMember(formData).then((res) => {
        if (res?.data?.data) {
          if (res?.data?.data.isGenerateInvoice === false && !memberScope) {
            var options = {
              key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
              amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: amount === 0 ? "INR" : "USD",
              config: {
                display: {
                  hide: [
                    { method: 'paylater' },
                    { method: 'emi' },
                    { method: 'wallet' }
                  ]
                }
              },
              name: res?.data?.data?.fullName,
              description: "Membership payment",
              order_id: res?.data?.data?.purchaseData?.orderId, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler: function (response) {
                // Handle successful payment
                gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                  if (res.data?.status) {
                    const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                    setValue("paymentStatus", isPaid);
                    toast.success("Member Saved Successfully");
                    setMemberDetails(res?.data?.data);
                    handleOpen();
                  }
                });
              },
              modal: {
                ondismiss: function () {
                  // Handle modal dismissal (user exits without payment)
                  handlePaymentCancelled(res?.data?.data?._id); // Call your custom function
                },
              },
            };

            var rzp1 = new window.Razorpay(options);
            setLoading(false);
            rzp1.open();

            rzp1.on("payment.failed", function (response) {
              toast.error("Payment Failed Try Again");
            });
          }
          else {
            setLoading(false);
            toast.success("Member Saved Successfully");
            if (memberScope) {
              if (memberScope === 'PORTAL_SUPER_ADMIN') {
                navigate("/admin/membershipPage")
              }
              else if (memberScope === 'PORTAL_SIG_ADMIN') {
                navigate("/sigAdmin/membershipPage")
              }
              else {
                navigate("/chapterAdmin/membershipPage")
              }
            }
            else {
              setOpenInvoice(true)
              getMemberById(res?.data?.data?._id).then((res) => {
                if (res?.data?.status) {
                  console.log(res.data.data)
                  setMemberDetailformail(res?.data?.data)
                }
              })
            }
          }
        } else {
          setLoading(false);
          setIsPaymentCompleted(prev => prev = false)
          toast.error(res?.error?.data?.error);
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [amount, currency, getMemberById, handlePaymentCancelled, navigate, createMember, countries, memberScope, activeStep, isPaymentCompleted, gettMemberDetailsByIdQuery, setValue, setValueAdd]);


  //Get all chapter
  // React.useEffect(() => {
  //     getAllChapters().then((res) => {
  //         setSelectedChapters(res?.data?.data || []);
  //     });
  // }, [
  //     setValue,
  //     getAllChapters,
  // ]);

  const handleDownload = () => {
    const element = emailRef.current; // Get the referenced DOM element
    const options = {
      margin: 1,
      filename: "GenerateInvoice.pdf",
      html2canvas: { scale: 2 }, // Improves quality
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf()
      .from(element)
      .set(options)
      .outputPdf('dataurl') // Generate PDF in-memory and open/download automatically
      .then((pdfContent) => {
        const link = document.createElement('a');
        link.href = pdfContent; // Use the in-memory PDF content
        link.download = 'GenerateInvoice.pdf';
        link.click(); // Trigger download
      });
  };


  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const validateFile = (fileList) => {
    const file = fileList[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 2 MB size limit
    if (!fileList || fileList.length === 0) {
      setValue("uploadOrganisationLogo", "");
      return "File is required!";
    }
    if (!allowedTypes.includes(file.type)) {
      setValue("uploadOrganisationLogo", "");
      return "Only images files are allowed!";
    }
    if (file.size > maxSize) {
      setValue("uploadOrganisationLogo", "");
      return "File size should not exceed 2MB!";
    }
    setValue("uploadOrganisationLogo", file);
    return true;
  };

  useEffect(() => {
    if (isPaymentCompleted) {
      setOpenForm(false); // close modal 
      AddEditCorporate(newFormData, false);
    }
  }, [isPaymentCompleted, newFormData, AddEditCorporate]);
  //to store the modal form data and the member form data
  const addMember = (formData) => {
    const data = getValues();
    const updatedData = { ...data, ...formData }
    setNewFormData(updatedData);
    setIsPaymentCompleted(prev => prev = true)
  }

  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">
            Corporate Supporter Membership
          </h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box className="p-md-5 p-3 overallPadding">
          <div className="text-end w-100 mb-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
            >
              Go back to plans page
            </Button>
          </div>
          <p className="descrption mt-3 mb-4">This
            category is ideal for companies, particularly those in the publishing, educational technology,
            and professional development sectors, as well as any other firms and organisations that want
            to support ELTAI’s mission while gaining visibility within the teaching community.
            Corporate supporters receive acknowledgement on ELTAI’s website and can also become
            sponsors or partners in ELTAI’s events, thereby maximizing their visibility. Additionally, they
            can connect with a network of educators, showcase their products or services to members,
            and participate in events as exhibitors or speakers.</p>
          <Stepper activeStep={activeStep} className="mt-3 mb-5">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form onSubmit={handleSubmit(onSubmit(AddEditCorporate, false))}>
            {activeStep === 0 && (
              <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Name of your Organisation
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("organisationName", {
                      required: "Please enter organisation name",
                      minLength: {
                        value: 3,
                        message: "Please enter a valid organisation name",
                      },
                    })}
                    error={!!errors?.organisationName}
                    helperText={errors?.organisationName?.message}
                    placeholder="Name of your organisation"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Who should be addressed to (when we send receipts &amp;
                        journals)?<span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("addressedTo", {
                      required: "Please enter who should be addressed to?",
                      minLength: {
                        value: 3,
                        message: "Please enter a valid name",
                      },
                    })}
                    error={!!errors?.addressedTo}
                    helperText={errors?.addressedTo?.message}
                    placeholder="Who should be addressed to?"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Your Address (for Communication)
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("address", {
                      required: "Please enter a address",
                      minLength: {
                        value: 3,
                        message: "Please enter a valid address",
                      },
                    })}
                    error={!!errors?.address}
                    helperText={errors?.address?.message}
                    placeholder="Your Address"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Your City / Town<span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("city", {
                      required: "Please enter a city / town",
                      minLength: {
                        value: 3,
                        message: "Please enter a valid address",
                      },
                    })}
                    error={!!errors?.city}
                    helperText={errors?.city?.message}
                    placeholder="Your City / Town"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Pin Code<span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("pinCode", {
                      required: "Please enter pin code",
                      pattern: {
                        value: /^\d{6}$/,
                        message: "Pincode must be 6 digits",
                      },
                    })}
                    error={!!errors?.pinCode}
                    helperText={errors?.pinCode?.message}
                    placeholder="Pin Code"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <FormControl
                    fullWidth
                    className="mt-0"
                    error={!!errors.country}
                  >
                    <InputLabel className="text-dark">
                      Country <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please select a country", // Validation rule
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className="bg-white"
                          label={
                            <Typography className="text-dark">
                              Country <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          value={field.value || ''}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                            field.onChange(e);
                            setValue("state", "");
                          }}
                          disabled >
                          <MenuItem value="">
                            <em>Select Country</em>
                          </MenuItem>
                          {countries.map((country) => (
                            <MenuItem
                              key={country.isoCode}
                              value={country.isoCode}
                            >
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.country && (
                      <FormHelperText>{errors.country.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <FormControl
                    fullWidth
                    disabled={!watch("country")}
                    error={!!errors.state}
                  >
                    <InputLabel className="text-dark">
                      State<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: watch("country") && "Please select a state", // Only required if a country is selected
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label={
                            <Typography className="text-dark">
                              State <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          className={
                            !watch("country") && errors.state
                              ? "border border-danger bg-white"
                              : "bg-white"
                          }
                          readOnly={!selectedCountry}
                          value={selectedState}
                          onChange={(e) => {
                            setSelectedState(e.target.value);
                            field.onChange(e);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select State</em>
                          </MenuItem>
                          {states.map((state) => (
                            <MenuItem key={state.isoCode} value={state.name}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.state && (
                      <FormHelperText>{errors.state.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        District (If Any)
                      </Typography>
                    }
                    {...register("district",)}
                    placeholder="District (If Any)"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("mobileNumber", {
                      required: "Please enter a mobile number",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Mobile number must be 10 digits",
                      },
                    })}
                    error={!!errors?.mobileNumber}
                    helperText={errors?.mobileNumber?.message}
                    placeholder=" Mobile Number"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Email Address<span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("email", {
                      required: "Please enter a email address",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email Id",
                      },
                    })}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    placeholder="Email Address"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Landline Number
                      </Typography>
                    }
                    {...register("landlineNumber", {
                      maxLength: { value: 15, message: 'Please enter number below 15 digits' }
                    })}
                    error={!!errors?.landlineNumber}
                    helperText={errors?.landlineNumber?.message}
                    placeholder="Landline Number"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Website Address of your organisation (if any):
                      </Typography>
                    }
                    {...register("websiteAddressOrganisation", {
                      pattern: {
                        value:
                          /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                        message: "Please enter a valid website address",
                      },
                    })}
                    error={!!errors?.websiteAddressOrganisation}
                    helperText={errors?.websiteAddressOrganisation?.message}
                    placeholder="Website address of organisatioWebsite Address of your organisation"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        When was your organisation established?
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    type="date"
                    value={
                      watch("organisationEstablished")
                        ? watch("organisationEstablished").split("T")[0]
                        : ""
                    }
                    {...register("organisationEstablished", {
                      required:
                        "Please select date organisation established on",
                      max: {
                        value: new Date().toISOString().split("T")[0],
                        message: "Future date is not allowed",
                      },
                    })}
                    placeholder="When was your organisation established?"
                    error={!!errors?.organisationEstablished}
                    helperText={errors?.organisationEstablished?.message}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: "Please select a type of your organisation",
                    }}
                    name="typeOfOrganisation"
                    render={({ field, fieldState }) => (
                      <FormControl
                        fullWidth
                        error={!!fieldState.error}
                        variant="outlined"
                      >
                        <InputLabel id="typeOfOrganisation-label">
                          <Typography className=" text-dark">
                            Type of your organisation
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </InputLabel>
                        <Select
                          labelId="typeOfOrganisation-label"
                          id="typeOfOrganisation-select"
                          value={field.value || ""}
                          className="bg-white"
                          onChange={(e) => field.onChange(e.target.value)}
                          label="Type of your organisation" // Add this to connect with the label
                        >
                          <MenuItem value="1">Government</MenuItem>
                          <MenuItem value="2">Private</MenuItem>
                          <MenuItem value="3">Non-Profit</MenuItem>
                        </Select>
                        {fieldState.error && (
                          <FormHelperText>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item lg={4} md={6} xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: "Please Select a Sector Of Your Organisation",
                    }}
                    name="industry"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth error={!!fieldState.error}>
                        <InputLabel id="event">
                          <Typography
                            className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                            style={{ textOverflow: "ellipsis" }}
                          >
                            What industry sector does your organisation belong
                            to?<span style={{ color: "red" }}>*</span>
                          </Typography>
                        </InputLabel>
                        <Select
                          labelId="Country"
                          className="bg-white"
                          id="event"
                          label={
                            <Typography className="text-dark">
                              What industry sector does your organisation belong
                              to?<span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          value={field.value ? field.value : ""}
                          variant="outlined"
                          onChange={(e) => field.onChange(e.target.value)}
                          error={!!fieldState.error}
                        >
                          {industries.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.industry && (
                          <FormHelperText>
                            {errors.industry.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Where is your head office located in?
                      </Typography>
                    }
                    {...register("headOfficeLocated")}
                    error={!!errors?.headOfficeLocated}
                    helperText={errors?.headOfficeLocated?.message}
                    placeholder="Where is your head office located in?"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        How many employees do you have approximately?
                      </Typography>
                    }
                    {...register("employees", {
                      min: { value: 1, message: 'No of value must be greater than zero' },
                      pattern: {
                        value: /^\d/,
                        message: "No of Employees Must be a Number",
                      },
                    })}
                    error={!!errors?.employees}
                    helperText={errors?.employees?.message}
                    placeholder="How many employees do you have approximately?"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  {watch("uploadOrganisationLogo") ? (
                    <>
                      <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-center align-items-center px-3 py-2">
                        <span className="alata-regular">
                          {watch("uploadOrganisationLogo")?.name}
                        </span>
                        <FormLabel
                          htmlFor="uploadOrganisationLogo"
                          style={{ marginLeft: "10px" }}
                        >
                          <Button
                            variant="contained"
                            className="menus-color"
                            component="span"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Choose File{" "}
                            <span
                              style={{ color: "white", paddingLeft: "5px" }}
                            >
                              *
                            </span>
                          </Button>
                        </FormLabel>
                      </div>
                      <TextField
                        className="d-none"
                        fullWidth
                        InputProps={{ type: "file" }}
                        InputLabelProps={{ shrink: true }}
                        label={
                          <Typography className="text-dark">
                            Upload the logo of your organisation
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        inputProps={{ accept: "image/*" }}
                        id="uploadOrganisationLogo"
                        type="file"
                        {...register("uploadOrganisationLogoFile", {
                          required:
                            !watch("uploadOrganisationLogo") &&
                            "Please Select a PDF file",
                          onChange: (e) => {
                            const fileList = e.target.files;
                            const validationResult = validateFile(fileList);
                            if (validationResult === true) {
                              setValue("uploadOrganisationLogo", fileList[0]);
                              clearErrors("uploadOrganisationLogoFile");
                            } else {
                              setValue('uploadOrganisationLogo', '')
                              setValue('uploadOrganisationLogoFile', '')
                              setError("uploadOrganisationLogoFile", {
                                message: validationResult,
                              });
                            }
                          },
                        })}
                        error={!!errors?.uploadOrganisationLogoFile}
                        helperText={errors?.uploadOrganisationLogoFile?.message}
                      />
                      <FormHelperText className="text-secondary-new alata-light">
                        (Your logo will be displayed on our website along with
                        your institution’s name. Please upload a clear
                        JPG/JPEG/PNG only)
                        {errors?.uploadOrganisationLogoFile?.message}
                      </FormHelperText>
                      {errors?.uploadOrganisationLogoFile && (
                        <FormHelperText className="text-danger alata-light">
                          {errors?.uploadOrganisationLogoFile?.message}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <>
                      <TextField
                        InputProps={{ type: "file" }}
                        InputLabelProps={{ shrink: true }}
                        label={
                          <Typography className="text-dark">
                            Upload the logo of your Organisation
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        fullWidth
                        inputProps={{ accept: "image/*," }}
                        id="file-upload"
                        type="file"
                        {...register("uploadOrganisationLogoFile", {
                          required:
                            !watch("uploadOrganisationLogo") &&
                            "Please Select a file",
                          onChange: (e) => {
                            const fileList = e.target.files;
                            const validationResult = validateFile(fileList);
                            if (validationResult === true) {
                              clearErrors("uploadOrganisationLogoFile");
                              setValue("uploadOrganisationLogo", fileList[0]);
                            } else {
                              setValue('uploadOrganisationLogo', '')
                              setValue('uploadOrganisationLogoFile', '')
                              setError("uploadOrganisationLogoFile", {
                                message: validationResult,
                              });
                            }
                          },
                        })}
                        error={!!errors.uploadOrganisationLogoFile}
                        helperText={errors?.uploadOrganisationLogoFile?.message}
                      />
                      <FormHelperText className="text-secondary-new alata-regular">
                        (Your logo will be displayed on our website along with
                        your organisation’s name. Please upload a clear
                        JPG/JPEG/PNG only)
                      </FormHelperText>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <div className="row row-gap-3">
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl fullWidth error={!!errors.dummyPlanId}>
                    <InputLabel className="text-dark"></InputLabel>
                    <TextField
                      label={
                        <Typography className="text-dark">
                          Membership Type{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("planName")}
                      InputProps={{ readOnly: true }}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    // className="mt-0"
                    fullWidth
                    error={!!errors.dummyPlanTypeId}
                    disabled={
                      watch("planName") ===
                      "No active plan for this member type"
                    }
                  >
                    <InputLabel
                      htmlFor="dummyPlanTypeId"
                      className="text-dark" // Ensure that the label is associated with the input
                    >
                      Membership Plan {" "}
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Controller
                      name="dummyPlanTypeId"
                      control={control}
                      rules={{
                        required:
                          watch("planName") ===
                            "No active plan for this member type"
                            ? "No active plan for this member type"
                            : "Please select a membership plan ",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Membership plan "
                          className="bg-white"
                          id="dummyPlanTypeId"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            setAmount("");
                          }}
                          value={watch("dummyPlanTypeId") || ""}
                        >
                          <MenuItem value="">
                            <em>Select a membership plan </em>
                          </MenuItem>
                          {costType &&
                            costType.map((plan) => (
                              <MenuItem key={plan._id} value={plan._id}>
                                <span>
                                  {plan.planTypeName} ({(plan.planTypeName === ('Annual Plan' || 'Annual')) ? 'Only For New Members' : plan.duration + ' Years'})
                                  {plan.offer === 0 ? (
                                    <span className="mx-2">
                                      {(currency === 1 ? "$" : "₹") +
                                        plan.amount}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="text-decoration-line-through mx-2">
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.amount}
                                      </span>
                                      <span>
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.offerAmount}
                                      </span>
                                      <span className="ms-2">
                                        ({plan.offer + '% off '})
                                      </span>
                                    </>
                                  )}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.dummyPlanTypeId && (
                      <FormHelperText>
                        {errors.dummyPlanTypeId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {/* Chapter code if needed uncomment it */}
                {/* <div className="col-lg-4 col-md-6 col-12 ">
                                    <Controller
                                        control={control}
                                        rules={{ required: "Please Select a Chapter" }}
                                        name="chapter"
                                        render={({ field, fieldState }) => (
                                            <FormControl fullWidth>
                                                <InputLabel className="text-dark" id="event">
                                                    Which chapter Would You Like To Join{" "}
                                                    <span style={{ color: "red" }}>*</span>
                                                </InputLabel>
                                                <Select
                                                   className="bg-white"
                                                    labelId="event"
                                                    id="event"
                                                    label={
                                                        <Typography className="text-dark">
                                                            Chapter<span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    value={field.value || ""}
                                                    variant="outlined"
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                    error={!!fieldState.error}
                                                >
                                                    {selectedChapters.map((data) => (
                                                        <MenuItem key={data._id} value={data._id}>
                                                            {data.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div> */}
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    component="fieldset"
                    error={!!errors.emailMessage}
                  >
                    <FormLabel className="text-dark" component="legend">
                      Would you like to receive updates from ELTAI by email?{" "}
                      <sapn className="text-danger">*</sapn>
                    </FormLabel>
                    <Controller
                      name="emailMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select a option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.emailMessage && (
                      <FormHelperText>
                        {errors.emailMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {amount && (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="card card-amount-membership">
                      <p className="fw-bold text-center p-3">
                        You have to pay {currency === 1 ? "$" : "₹"}
                        {amount}
                      </p>
                      {/* Assuming this is a form field */}
                      <input
                        type="hidden"
                        {...register("dummyAmount", { value: amount })}
                      />
                      <input
                        type="hidden"
                        {...register("dummyCurrency", { value: currency })}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              {(activeStep === steps.length - 1) && <Button type="button" variant="contained" className="menus-color me-4" onClick={handleSubmit(onSubmit(AddEditCorporate, true))}>
                Generate Invoice
              </Button>}
              <Button type="submit" variant="contained" className="menus-color">
                {activeStep === steps.length - 1 ? "Pay Now" : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={style}
          container
          className="overflow-y-scroll"
        >
          <Grid item xs={12} mb={3}>
            <h3 className="invoice fw-bold fs-4 mb-4 title-font">Membership Details</h3>
            <p className="alata-regular fw-bold">
              Membership Type:{" "}
              <span className="fw-normal">{watch("planName")}</span>
            </p>
            <p className="alata-regular fw-bold">
              Plan Name:{" "}
              <span className="fw-normal">
                {" "}
                {
                  costType.find((i) => i._id === watch("dummyPlanTypeId"))
                    ?.planTypeName
                }
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Membership valid from:{" "}
              <span className="fw-normal">
                {memberDetails && memberDetails?.plans[0]?.dummyStartDate &&
                  memberDetails?.plans[0]?.dummyStartDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Membership valid till:{" "}
              <span className="fw-normal">
                {memberDetails && memberDetails?.plans[0]?.dummyEndDate &&
                  memberDetails?.plans[0]?.dummyEndDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
          </Grid>
          <Grid item xs={12} mb={3}>
            <h3 className="invoice fw-bold fs-4 mb-4">Payment Details</h3>
            <p className="alata-regular fw-bold">
              Amount Paid:{" "}
              <span className="fw-normal">
                {memberDetails &&
                  memberDetails?.plans[0]?.amount}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Mode of Payment:{" "}
              <span className={`fw-normal ${(memberDetails && memberDetails?.plans[0]?.paymentMethod === 'upi') ? "text-uppercase" : 'text-capitalize'}`}>
                {memberDetails && memberDetails?.plans[0]?.paymentMethod}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Payment UTR Number: <span className="fw-normal">{memberDetails && memberDetails?.plans[0]?.bankTransactionId}</span>{" "}
            </p>
            <p className="alata-regular fw-bold">
              Payment Date:{" "}
              <span className="fw-normal">
                {memberDetails && memberDetails?.plans[0]?.paymentDate &&
                  memberDetails?.plans[0]?.paymentDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>

            <Alert className="mb-3" style={{ width: "100%" }} severity="success">
              Payment Success
            </Alert>
            <p className="alata-regular fw-bold justified">
              Note:{" "}
              <span className="fw-normal text-danger">
                Your login credentials will be sent through email to this mail
                address{" "}
                <span className="menus-green-color">
                  {watch("email") && watch("email")}
                </span>{" "}
                once the admin activate your account
              </span>
            </p>
          </Grid>
          <div className="text-center w-100">
            <Button
              className="mx-2"
              variant="contained"
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
          </div>
        </Grid>
      </Modal>
      <Modal
        open={openForm}
        onClose={handleFormClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} component={'form'} onSubmit={handleSubmitAdd(addMember)} className="d-flex w-100 align-items-center flex-column gap-3">
          <h4 style={{ color: "#104e29" }}>Payment Details</h4>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <span className="alata-regular">Payment Amount: &nbsp;<span className="text-decoration-none text-secondary-new">{watchAdd('currency') === "0" || watchAdd('currency') === 0 || watch('dummyCurrency') === "" ? '₹' : '$'}&nbsp;{watchAdd('amount')}</span>
              </span>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Controller
                control={controlAdd}
                rules={{ required: "Please select a payment type" }}
                name="paymentType"
                render={({ field }) => (
                  <FormControl fullWidth error={!!errorsAdd.paymentType}>
                    <InputLabel id="paymentType-label" className="text-dark">
                      Payment Type <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="paymentType-label"
                      id="paymentType"
                      label='Payment Type *'
                      variant="outlined"
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValueAdd('bankTransactionId', '')
                        setValueAdd('paymentMethod', '')
                        clearErrorsAdd("bankTransactionId");

                      }}
                    >
                      <MenuItem value="0">Cash</MenuItem>
                      <MenuItem value="1">Online</MenuItem>
                      <MenuItem value="2">Cheque /Demand Draft (DD)</MenuItem>
                    </Select>
                    <FormHelperText>{errorsAdd?.paymentType?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {watchAdd('paymentType') === "2" && <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label={
                  <Typography className="text-dark">
                    Cheque ID / Demand Draft (DD) No<span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                {...registerAdd("bankTransactionId", {
                  required: watchAdd('paymentType') === "2" ? "Please enter Cheque ID" : false,
                })}
                error={!!errorsAdd.bankTransactionId}
                helperText={errorsAdd?.bankTransactionId?.message}
              />
            </Grid>}
            {watchAdd('paymentType') === "1" && <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={controlAdd}
                rules={{ required: "Please select a payment method" }}
                name="paymentMethod"
                render={({ field }) => (
                  <FormControl fullWidth error={!!errorsAdd.paymentMethod}>
                    <InputLabel id="paymentMethod-label" variant="outlined">
                      Payment Method <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="paymentMethod-label"
                      id="paymentMethod"
                      label='Payment Method *'
                      variant="outlined"
                    >
                      <MenuItem value="upi">UPI</MenuItem>
                      <MenuItem value="netbanking">Net Banking</MenuItem>
                      <MenuItem value="card">Card</MenuItem>
                    </Select>
                    <FormHelperText>{errorsAdd?.paymentMethod?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>}
            {watchAdd('paymentType') === "1" && watchAdd('paymentMethod') !== "card" && <>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label={
                    <Typography className="text-dark">
                      Transaction ID <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  {...registerAdd("bankTransactionId", {
                    required: watchAdd('paymentType') === "1" ? "Please enter Transaction ID" : false,
                  })}
                  error={!!errorsAdd.bankTransactionId}
                  helperText={errorsAdd?.bankTransactionId?.message}
                />
              </Grid>
            </>
            }
          </Grid>
          <div className="d-flex justify-content-center mt-3 gap-3">
            <Button
              variant="outlined"
              color="error"
              className="rounded-0"
              onClick={handleFormClose}
            >
              cancel
            </Button>

            <Button
              variant="contained"
              className="menus-color"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openInvoice}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={style2}
          container
          className="overflow-y-scroll no-scrollbar"
        >
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}><b>Thank You!</b></h2>
            <hr className="line" />
            <p className="invoice_txt">
              We have sent an invoice to your email. It is also available for download now. &nbsp;<button
                style={{
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "var(--all-menus-color)",
                  cursor: "pointer",
                  padding: 0,
                }}
                onClick={handleDownload}
              >
                Click here
              </button>  to download it.

              <br />  The membership fee can be paid via cash, cheque, demand draft (DD), or internet bank transfer. Please send the cheque/DD/transaction details to our office by post. Ensure that the invoice number is mentioned in the transaction reference.

              <br></br> For any queries, feel free to contact us at <a className='text-dark' href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a>.
            </p>
            <div id="email-template" style={{ display: 'none' }}>
              <InvoiceEmailTemplate ref={emailRef} offer={costType.find((i) => i._id === watch("dummyPlanTypeId"))
                ?.offer}
                offeramount={costType.find((i) => i._id === watch("dummyPlanTypeId"))
                  ?.offerAmount}
                discountamount={costType.find((i) => i._id === watch("dummyPlanTypeId"))
                  ?.discountAmount}
                discount={costType.find((i) => i._id === watch("dummyPlanTypeId"))
                  ?.discount}
                orginamt={
                  costType.find((i) => i._id === watch("dummyPlanTypeId"))
                    ?.amount
                }
                plantype={watch("planName")}
                planname={
                  costType.find((i) => i._id === watch("dummyPlanTypeId"))
                    ?.planTypeName
                } amount={memberDetailformail?.plans[0]?.dummyAmount} startdate={memberDetailformail?.plans[0]?.dummyStartDate && memberDetailformail?.plans[0]?.dummyStartDate.split("T")[0]
                  .split("-")
                  .reverse()
                  .join("-")} enddate={memberDetailformail?.plans[0]?.dummyEndDate && memberDetailformail?.plans[0]?.dummyEndDate.split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")} date={memberDetailformail?.createdAt.split("T")[0].split("-")
                      .reverse()
                      .join("-")} name={memberDetailformail?.organisationName} memberId={memberDetailformail?.userId} state={memberDetailformail?.state} district={memberDetailformail?.district} city={memberDetailformail?.city} address={memberDetailformail?.address} email={memberDetailformail?.email} mobileNumber={memberDetailformail?.mobileNumber} />
            </div>
            <hr className="line" />

          </Grid>

          <div className="text-center d-flex justify-content-center w-100">
            <Button
              className="mx-2"
              variant="contained"
              style={{
                backgroundColor: "#104e29",
                color: "white",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
          </div>
        </Grid>
      </Modal>
    </>
  );
}

export default AddEditCorporate;
