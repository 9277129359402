import { Box, Button, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import { useParams } from 'react-router-dom';
import { useLazyGetSIGByIdQuery, useMakeAdminSIGMutation, useRemoveAdminSIGMutation } from '../../../app/services/SIGServices';
import { toast } from 'react-toastify';
import Spinner from "../../../Component/spinner/spinner"
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from '@mui/icons-material/Key';
import { useResetPasswordMutation } from '../../../app/services/loginServices';

//table features
function EnhancedTableHead() {
  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Email Id' },
    { id: 'role', numeric: true, disablePadding: false, label: 'Role' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};
const MakeAdminSig = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const handleOpen = (member) => { setOpen(true); setSelectedMember(member) };
  const handleClose = () => setOpen(false);
  const { sigGroupId } = useParams()
  const [getSigById, { data: sigData }] = useLazyGetSIGByIdQuery()
  const [makeMemberAdmin] = useMakeAdminSIGMutation();
  const [removeMemberAdmin] = useRemoveAdminSIGMutation();
  const [resetPassword] = useResetPasswordMutation();
  useEffect(() => {
    getSigById(sigGroupId)
  }, [getSigById, sigGroupId])

  //remove admin
  const removeAdmin = (member) => {
    const data = {
      sigGroupId,
      roleId: member._id,
      email: member.email,
    }
    setLoading(true);
    removeMemberAdmin(data).then(res => {
      if (res.data?.data) {
        setLoading(false);
        toast.success(res.data?.message)
        getSigById(sigGroupId)
      }
      else {
        setLoading(false);
        toast.error(res.data?.message)
      }
    })
  }

  //makeAdmin
  const makeAdmin = () => {
    const data = {
      sigGroupId,
      roleId: selectedMember._id,
      email: selectedMember.email,
      password: selectedMember.email.split("@")[0]
    }
    setLoading(true);
    handleClose()
    makeMemberAdmin(data).then(res => {
      if (res.data.status) {
        setLoading(false);
        toast.success(res.data?.message)
        getSigById(sigGroupId)
      }
      else {
        setLoading(false);
        toast.error(res.data?.err?.message)
      }
    })
  }

  //Reset Password
  const handleResetPassword = (email) => {
    var data = {
      email: email,
      role: "PORTAL_SIG_ADMIN"
    }
    resetPassword(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      }
      else {
        toast.error(res?.data?.message)
      }
    }).catch(err => console.error(err))
  }

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Make/Remove Admin</h2>
        </div>
      </div>
      <span className="ms-4" style={{ width: "20%" }}></span>

      {loading ? (
        <Spinner />
      ) : (
        <Box className="p-md-5 p-3 overallPadding">
          <div className='text-end mb-3'>
            <Button onClick={() => window.history.back()} variant="contained" className='menus-color'
            >Back</Button>
          </div>
          <Paper>
            {/* <EnhancedTableToolbar /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead />
                <TableBody>
                  {sigData?.data?.roleDetails.length > 0 ? sigData?.data?.roleDetails
                    .map((member, index) => (
                      <TableRow
                        hover
                        key={member._id}
                        sx={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white' }}
                      >
                        <TableCell align="center">{member.name}</TableCell>
                        <TableCell align="center">{member.email}</TableCell>
                        <TableCell align="center">{member.role}</TableCell>
                        <TableCell align="center">
                          {member.isAdmin ? (
                            <>
                              <Tooltip title="Remove Admin">
                                <IconButton
                                  color="error"
                                  onClick={() => removeAdmin(member)}
                                  title="Remove Admin"
                                  disabled={!member.isAdmin}
                                  aria-label="approve"
                                >
                                  <CloseIcon color="error"></CloseIcon>
                                </IconButton>
                              </Tooltip>
                              <IconButton onClick={() => handleResetPassword(member.email)}>
                                <Tooltip title="Reset Password">
                                  <KeyIcon style={{ color: 'darkred' }} />
                                </Tooltip>
                              </IconButton>
                            </>
                          )
                            : <>
                              {!sigData?.data?.roleDetails.some(role => role.isAdmin) ?
                                <Tooltip title="Make Admin">
                                  <IconButton
                                    onClick={() => handleOpen(member)}
                                    aria-label="approve"
                                    title="Make Admin"
                                  >
                                    <DoneIcon color="success"></DoneIcon>
                                  </IconButton>
                                </Tooltip> : '----'
                              }
                            </>}
                        </TableCell>
                      </TableRow>
                    )) : (<>
                      <TableRow>
                        <TableCell colSpan={4} align="center">No Data Available</TableCell>
                      </TableRow>
                    </>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='d-grid place-items-center gap-3 mx-auto'>
          <Typography id="modal-modal-title" className="justified" variant="h6" component="h2" style={{ color: "#134b2b" }}>
            Are you sure you want to make <span className="text-secondary-new text-capitalize">{selectedMember && selectedMember?.name}</span> an admin for this SIG group?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="outlined" className="rounded-0 me-3" color="error" onClick={handleClose} >No</Button>
            <Button onClick={() => makeAdmin()} className="menus-color" type='submit' variant='contained'>Yes</Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default MakeAdminSig