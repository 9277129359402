import { Box, Button, IconButton, Paper, TableBody, TableCell, Table, TableContainer, TableHead, TableRow, Tooltip, Typography, Modal, Grid, Divider } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../../Component/spinner/spinner';
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactPaginate from 'react-paginate';
import { useGetAllBannerPaginationMutation, useEnableDisableBannerByIdMutation, useDeleteBannerByIdMutation } from '../../../app/services/bannerServices';
import placeholder from '../../../assets/images/placeholder/placeholder.png'
import { toast } from 'react-toastify';
import { selectCurrentScope } from '../../../app/auth/authSlice';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';

//Modal usage
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    padding: 4,
    overflow: 'scroll'
};
const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    placeItems: "center",
    // width:'700px',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    padding: 4,
    height: '75vh',
    zIndex: '9999999'

};
function EnhancedTableHead() {
    const headCells = [
        { id: "bannertext", numeric: false, disablePadding: true, label: "Banner Text" },
        {
            id: "bannerImage",
            numeric: true,
            disablePadding: false,
            label: "Banner Image",
        },
        {
            id: "buttonText",
            numeric: true,
            disablePadding: false,
            label: "Button Text",
        },
        {
            id: "buttonLink",
            numeric: true,
            disablePadding: false,
            label: "Button Link",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

const BannerContentTable = () => {
    const [loading, setLoading] = useState(true);
    const [allBannerContent, setAllBannerContent] = useState([]);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [totalPage, setTotalPage] = useState(0);
    const [getAllBannerPagination] = useGetAllBannerPaginationMutation()
    const [enableDisableBanner] = useEnableDisableBannerByIdMutation();
    const [deleteBanner] = useDeleteBannerByIdMutation()
    const [selectedId, setSelectedId] = useState('');
    const [selectedBanner, setSelectedBanner] = useState(null)
    const currentScope = useSelector(selectCurrentScope);


    //get all pagination for testimonial
    const getAllPagination = useCallback(() => {
        var data = {
            page: page + 1,
            itemsPerPage: rowsPerPage,
            role: (currentScope) ? currentScope : ""
        };
        setLoading(true)
        getAllBannerPagination(data).then((res) => {
            if (res.data?.status) {
                setLoading(false);
                setTotalPage(res?.data?.data?.totalCount || 0);
                setAllBannerContent(res.data?.data?.bannerDetails || []);
            } else {
                if (page > 0) {
                    setPage(0);
                    setLoading(true)
                    getAllBannerPagination(data).then(res => {
                        if (res.data?.status)
                            setLoading(false);
                        setTotalPage(res?.data?.data?.totalCount || 0);
                        setAllBannerContent(res.data?.data?.bannerDetails || []);
                    })
                }
                setAllBannerContent([])
                setLoading(false);
            }
        });
    }, [getAllBannerPagination, currentScope, page, rowsPerPage]);

    //useEffect
    useEffect(() => {
        getAllPagination();
    }, [getAllPagination]);

    // Enable/Disable
    const handleEnableDisable = (id) => {
        setLoading(true)
        enableDisableBanner(id).then((res) => {
            setLoading(false)
            if (res?.data?.status) {
                getAllPagination()
                toast.success(res?.data?.message);
            } else {
                getAllPagination()
                toast.error(res?.data?.message);
            }
        })
            .catch((err) => {
                console.error(err)
            })
    }
    const handleOpen = (id) => {
        if (totalPage <= 1) {
            toast.error("Can't delete the last available banner")
        }
        else {
            setSelectedId(id)
            setOpen(true);
        }
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen1 = (banner) => {
        setSelectedBanner(banner)
        setOpen1(true);
    }
    const handleClose1 = () => {
        setOpen1(false);
    }
    const handleDelete = () => {
        setLoading(true)
        deleteBanner(selectedId).then((res) => {
            setLoading(false)
            handleClose();
            if (res?.data?.status) {
                getAllPagination()
                toast.success(res?.data?.message);
            } else {
                getAllPagination()
                toast.error(res?.data?.message);
            }
        })
            .catch((err) => {
                console.error(err)
            })

    }
    const handlePageClick = ({ selected }) => {
        setPage(selected);
    };
    //calculate pagecount
    const pageCount = Math.ceil(totalPage / rowsPerPage);


    return (
        <>
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">Banner Content</h2>
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) :
                <div className='p-md-5 p-3 overallPadding'>
                    <Link
                        style={{ float: "right" }}
                        to={'/admin/banner-content-form'}
                    >
                        <Button
                            variant="contained"
                            className="menus-color"
                            type="submit"
                        >
                            Add Banner
                        </Button>
                    </Link>
                    <Box
                        className="memberTable"
                    >
                        <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <EnhancedTableHead />
                                <TableBody>
                                    {allBannerContent?.length > 0 ? (
                                        allBannerContent.map((banner, index) => (
                                            <TableRow
                                                hover
                                                key={banner._id}
                                                sx={{
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <TableCell align="center" >{banner.text.slice(0, 50)}...</TableCell>
                                                <TableCell align="center" style={{ width: '200px' }}>
                                                    <img
                                                        src={
                                                            banner.desktopBannerImage
                                                                ? `${banner.desktopBannerImage}?t=${new Date().getTime()}`
                                                                : placeholder
                                                        }
                                                        className='w-100'
                                                        alt="View Testimonial"
                                                    />
                                                </TableCell>
                                                <TableCell align="center" >{banner.buttonName}</TableCell>
                                                <TableCell align="center" >{banner.buttonLink.slice(0, 20)}...</TableCell>
                                                <TableCell
                                                    align="center" >
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        gap="8px"
                                                        flexWrap="wrap"
                                                    >
                                                        {!(banner.isDeleted === 2) && (
                                                            <Tooltip title="View" placement="top">
                                                                <IconButton
                                                                    aria-label="ViewIcon"
                                                                    onClick={() => handleOpen1(banner)}
                                                                >
                                                                    <VisibilityIcon className="icon-vieweye-color" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {!(banner.isDeleted === 2) && (
                                                            <Tooltip title="Edit" placement="top">
                                                                <IconButton aria-label="EditIcon">
                                                                    <Link
                                                                        to={'/admin/banner-content-form/' + banner._id}
                                                                    >
                                                                        <EditIcon style={{ color: "green" }} />
                                                                    </Link>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                                                            {banner?.isDeleted === 0 ? (
                                                                <Tooltip title="Enable" placement="top">
                                                                    <IconButton
                                                                        aria-label="enable"
                                                                        onClick={() => {
                                                                            handleEnableDisable(banner._id);
                                                                        }}
                                                                    >
                                                                        <LockOpenIcon style={{ color: "green" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : banner?.isDeleted === 2 ? (
                                                                <Tooltip title="Disable" placement="top">
                                                                    <IconButton
                                                                        aria-label="disable"
                                                                        onClick={() => {
                                                                            handleEnableDisable(banner._id);
                                                                        }}
                                                                    >
                                                                        <LockIcon style={{ color: "red" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : null}
                                                            <Tooltip title="Delete" placement="top">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => handleOpen(banner._id)}
                                                                >
                                                                    <DeleteIcon style={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow sx={{
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                        }}>
                                            <TableCell colSpan={5} align="center">
                                                No Data Available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <Modal
                                    open={open1}
                                    onClose={handleClose1}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Grid sx={style1} container className='overflow-y-scroll' maxWidth={'sm'}>
                                        {/* <Typography
                                            id="modal-modal-title"
                                            variant="h4"
                                            component="h2"
                                            className='alata-bold text-center w-100 menus-green-color'
                                        >
                                            Banner Content
                                        </Typography> */}
                                        <Container maxWidth={false} sx={{ px: 3 }}>
                                            <Box className="d-flex justify-content-between pt-2">
                                                <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                                                    View Announcement
                                                </Typography>
                                                <IconButton onClick={handleClose1} aria-label="Close">
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Container>
                                        <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                                            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                        </Box>
                                        <Grid item xs={12} mb={3}>
                                            <h4 className='alata-regular title-font' style={{ color: "#611f1e" }} >Desktop Banner Image</h4>
                                            <img src={selectedBanner?.desktopBannerImage} className='w-75 img-fluid' alt="" />
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <h4 className='alata-regular title-font' style={{ color: "#611f1e" }}>Mobile Banner Image</h4>
                                            <img src={selectedBanner?.mobileBannerImage} className='w-75 img-fluid' alt="" />
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <h4 className='alata-regular title-font' style={{ color: "#611f1e" }}>Banner Text Content</h4>
                                            <p className='alata-regular'>{selectedBanner?.text}</p>
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <h4 className='alata-regular title-font' style={{ color: "#611f1e" }}>Button Link</h4>
                                            <a className='alata-regular' href={selectedBanner?.buttonLink}>{selectedBanner?.buttonLink}</a>

                                        </Grid>
                                  
                                    </Grid>
                                </Modal>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        sx={style}
                                        className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                                    >

                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                                                Confirmation
                                            </Typography>
                                            <IconButton onClick={handleClose} aria-label="Close">
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        <Box display="flex" justifyContent="center" width="100%">
                                            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                        </Box>
                                        <Typography
                                            id="modal-modal-title"
                                            style={{ color: "#611f1e" }}
                                            variant="h6"
                                            component="h2"
                                        >
                                            Are You Surely Want to Delete this Banner?
                                        </Typography>
                                        {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button
                                                variant="outlined"
                                                style={{

                                                    color: "#dc3545",
                                                    border: "1px solid #dc3545",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={handleClose}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="mx-2"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#2e7d32",
                                                    color: "white",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={() => handleDelete()}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </Box>
                                </Modal>
                            </Table>
                        </TableContainer>

                        <br />
                        {allBannerContent?.length > 0 && (
                            <ReactPaginate
                                pageCount={pageCount} // Calculate total pages
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={page}
                                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                                nextLabel={
                                    pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                                }
                                disabledClassName={"disablebtn"}
                            />
                        )}
                    </Box>
                    <br />
                </div >
            }
        </>
    )
}

export default BannerContentTable