import React from 'react'
import TopHeader from '../Component/Header/TopHeader'
import { Container, Typography, } from '@mui/material'
import '../style/css/event/viewEvent.css'
import eventimg1 from '../../src/assets/images/Event/cal.png'
import eventimg2 from '../../src/assets/images/Event/clock.png'
import eventimg3 from '../../src/assets/images/Event/icon1.png'
import eventimg4 from '../../src/assets/images/Event/image3.png'
import eventimg5 from '../../src/assets/images/Event/image2.png'
import eventimg6 from '../../src/assets/images/Event/event-logo.png'
import eventimg7 from '../../src/assets/images/Event/image1.png'

const ViewEvent = () => {
    //icon containter
    const icons = [
        {
            id: 1,
            icon: eventimg1,
            detailsTitle: "Date",
            details: "27-2-2024",
        },
        {
            id: 2,
            icon: eventimg2,
            detailsTitle: "Time",
            details: "04:30PM IST",
        },
        {
            id: 3,
            icon: eventimg3,
            detailsTitle: "App",
            details: "Google Meet",
        },
    ]
    //staffDetails 
    const StaffDetails = [
        {
            id: 1,
            img: eventimg4,
            role: "Moderator",
            Name: "Prof.C.Sharada",
            Dept: "  Department  of English Literature ",
            University: "The English And  Foreign ",
            city: "Languages University,(EFLU) Hyderabad",
        },
        {
            id: 2,
            img: eventimg5,
            role: "Host",
            Name: "Mr.Rahul Kale",
            Dept: 'Assistant professor',
            University: 'Applied & Social Science',
            city: "Tolani Maritime Institute, Pune"
        },

    ]
    return (
        <div>
            <TopHeader />
            {/* <Box className="banner_text"></Box> */}
            <div className='pt-1 mb-5'>
                <div className='p-0'>
                    <div className=' Architects-header d-flex align-items-center justify-content-center '>
                        <h2 className='title-font text-center '>View Chapter Details </h2>
                    </div>
                </div>
            </div>

            <div className=' '>
                <Container maxWidth={'xl'}>
                    <div className='row'>
                        <div className='col-md-3'>
                            <h3 className='text-dark font-family mt-3'>WEBINAR</h3>
                        </div>
                        <div className='col-md-6'>
                            <h2 className=' event-font-color text-center mt-5'>ELTAI Webinar 119</h2>
                            <div className='card view-event-title-card shadow p-2 mb-5 bg-white'>
                                <div className='card-body'>
                                    <h2 className=' event-font-color text-center mt-2 '>Exploring Leadership In Education</h2>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 mt-3 d-flex justify-content-center align-item-center' >
                            <img src={eventimg6} width={'150px'} height={'100px'} alt="view event" />
                        </div>
                    </div>
                </Container>
                <div className='view-event-container'>
                    <div className='row p-0'>
                        <div className='col-md-4 col-sm-12 event_col_4 p-0'>
                            <div className='row mt-5'>
                                {StaffDetails.map((staff, i) => (
                                    <div className='row align-items-center justify-content-center h-100' key={i}>
                                        <div className='col-4 ms-2'>
                                            <img src={staff.img} className='mt-5 w-100' alt="view event" />
                                        </div>
                                        <div className='col-7 p-0'>
                                            <h3 className='mt-5 event-font-color'>{staff.role}</h3>
                                            <p className='text-dark staff-details p-0 m-0'> {staff.Name}</p>
                                            <p className='text-dark staff-details p-0 m-0'> {staff.Dept}</p>
                                            <p className='text-dark staff-details p-0 m-0'> {staff.University}</p>
                                            <p className='text-dark staff-details p-0 m-0'> {staff.city}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-md-8 col-sm-8 event_col_8 ' >
                            <div className='event_card'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 event_text_card font-family1'>
                                        <h6 className='event_text_card_heading'>Dr.Maithry Shinde</h6>
                                        <p style={{ fontSize: "19px" }}>Assistant Professor<br /> Department Of English<br />St Mary's College, Yousufguda<br />Hyderabad</p>
                                    </div>
                                    <div className='col-md-6 col-sm-12 event_image_card'>
                                        <img src={eventimg7} className='view-event-webinar-image' width={"200px"} height={"300px"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <div className='row mb-3'>
                        <div className='col-lg-4 col-sm-4'>
                        </div>
                        <div className='col-sm-8 col-lg-8'>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-4 '>
                                    <button className='view-event-button mt-3 d-flex align-item-center'>Register</button>
                                </div>
                                <div className='col-sm-12 col-lg-8'>
                                    <div className='row'>
                                        {icons.map((icon, index) => (
                                            <div key={index}>
                                                <div className="col-lg-3 col-md-3 col-sm-12 d-flex flex-column my-md-0 mx-3 my-md-2 my-3 p-2 justify-content-center align-items-center" style={{ backgroundColor: "#0072bc", height: 'auto' }}>
                                                    <img src={icon.icon} width={'35px'} height={'35px'} alt="view event" />
                                                    <Typography style={{ fontSize: '10px' }}>
                                                        {icon.detailsTitle}
                                                    </Typography>
                                                    <Typography style={{ fontSize: '10px' }}>
                                                        {icon.details}
                                                    </Typography>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container maxWidth={'xl'}>
                    <p className=' text-center view-event-bgcolor event-font-color p-3 footer-view-event-collabarate'>In Collaboartion with St Mary's College, Yousufguda, Hyderabad</p>
                </Container>
            </div>
        </div>
    )
}

export default ViewEvent