import { Divider, Modal } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopHeader from "../../Component/Header/TopHeader";
import { Box, IconButton, Typography } from "@mui/material";
import {
  useGetAllFilteredEventsPaginationMutation,
  useGetAllEventsPaginationMutation,
  useDeleteEventMutation,
  useLazyGetEnableDisableEventQuery,
} from "../../app/services/eventService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactPaginate from "react-paginate";
// import "../../style/css/journalForm.css";
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "../../Component/spinner/spinner";
import { toast } from "react-toastify";
import { selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
import GroupIcon from "@mui/icons-material/Group";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useGetEventTypeByIdQuery } from "../../app/services/eventTypeServices";
import GetChapterName from "../../Component/HomePage/GetChapterName";
import GetSIGName from "../../Component/HomePage/GetSigName";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const GetEventType = ({ id }) => {
  const { data, error, isLoading } = useGetEventTypeByIdQuery(id);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data) return <p>No event type found</p>;

  return <div>{data?.data?.name}</div>;
};
//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "Level", numeric: false, disablePadding: true, label: "Level" },
    { id: "Type", numeric: false, disablePadding: true, label: "Type" },
    { id: "title", numeric: false, disablePadding: true, label: "Event Title" },
    {
      id: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
    },
    { id: "Mode", numeric: false, disablePadding: true, label: "Mode" },
    {
      id: "CreatedBy",
      numeric: true,
      disablePadding: false,
      label: "Created by",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '300px' },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff", minWidth: headCell?.width ? headCell?.width : 'auto' }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function EventTable() {
  const [page, setPage] = useState(0);
  const userId = useSelector(selectUserId);
  const roleDetails = useSelector(selectCurrenChapterRole);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [allEvent, setAllEvents] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [filterEvents, setFilterEvents] = useState(1);
  const navigate = useNavigate();
  const [getAllEventList] = useGetAllFilteredEventsPaginationMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvent1, setSelectedEvent1] = useState(null);
  const memberScope = useSelector(selectCurrentScope);
  const [EnableDisable] = useLazyGetEnableDisableEventQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedEvent(id);
  };
  const handleClose = () => setOpen(false);
  const handleOpen1 = (event) => {
    setOpen1(true);
    setSelectedEvent1(event);
  };
  const handleClose1 = () => setOpen1(false);

  //Get all events list
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      // role: memberScope ? memberScope : "",
      filter: filterEvents
    };
    getAllEventList(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setTotalPage(res?.data?.data?.totalCount || 0);
        setAllEvents(
          res.data?.data?.eventDetails.map((i) => ({
            ...i,
            nonMemberDetails: i?.nonMemberDetails ? i?.nonMemberDetails : [],
            membersId: i?.membersId ? i?.membersId : [],
          })) || []
        );
      } else {
        setLoading(false);
        setAllEvents([])
      }
    });
  }, [getAllEventList, page, itemsPerPage, memberScope, filterEvents]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  //Delete function
  const handleDelete = () => {
    setLoading(true);
    deleteEvent(selectedEvent)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          toast.error("Event Deleted Successfully!!!");
          handleClose();
          if (allEvent.length <= 1) {
            setPage(page - 1);
            getAllPagination();
          } else {
            getAllPagination();
          }
        } else {
          toast.error(res?.data?.err?.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id)
      .then((res) => {
        if (res?.data?.data?.isDeleted === 0) {
          getAllPagination();
          toast.success(res?.data?.message);
        } else {
          getAllPagination();
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">
            Event List
          </h2>
        </div>
      </div>
      <div className="p-md-5 p-3 overallPadding">
        <div style={{display:'flex', justifyContent:'space-between', marginRight: "3px" }} >
          <FormControl variant="outlined">
            <InputLabel></InputLabel>
            <Select
              // className="filteredEvents"
              style={{width:'200px',
                height:'43px'
              }}
              value={filterEvents}
              onChange={(e) => {
                const value = e.target.value;
                setFilterEvents(value);
                setPage(0)
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                  },
                },
              }}
            >
              {/* <MenuItem value={2}>All</MenuItem> */}
              <MenuItem value={0}>Past</MenuItem>
              <MenuItem value={1}>Upcoming</MenuItem>
            </Select>

          </FormControl>
          <Link
            style={{ display: 'flex', justifyContent: 'space-between', marginRight: "3px" }}
            to={
              memberScope === "PORTAL_SUPER_ADMIN"
                ? "/admin/eventForm"
                : memberScope === "PORTAL_ADMIN"
                  ? "/assistantAdmin/eventForm"
                  : memberScope === "PORTAL_SIG_ADMIN"
                    ? "/sigAdmin/eventForm"
                    : "/chapterAdmin/eventForm"
            }
          >
            <Button
              variant="contained"
              className="menus-color mb-3"
              type="submit"
              style={{
                backgroundColor: "#1769aa",
                color: "#fff",
                padding: "6px 16px",
                borderRadius: "4px",
                textTransform: "uppercase"
              }}
            >Add Event
            </Button>
          </Link>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <Box>
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {allEvent?.length > 0 ? (
                      allEvent.map((event, index) => (
                        <TableRow
                          hover
                          key={event._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {event?.levelOfEvent}
                          </TableCell>
                          <TableCell align="center">
                            <GetEventType id={event?.eventTypeId} />
                          </TableCell>
                          <TableCell align="center">
                            {event.title}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(event.startDate).toLocaleDateString(
                              "en-In",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                            {event.endDate ? (
                              <>
                                <br></br>to <br />
                              </>
                            ) : (
                              ""
                            )}
                            {event.endDate
                              ? new Date(event.endDate).toLocaleDateString(
                                "en-In",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                              : ""}
                          </TableCell>
                          <TableCell align="center" >
                            {event.eventMode}
                          </TableCell>
                          <TableCell align="center" >
                            {event?.createdBy?.role === "PORTAL_SUPER_ADMIN" ||
                              event?.createdBy?.role === "PORTAL_ADMIN" ? (
                              "ELTAI"
                            ) : event?.createdBy?.role ===
                              "PORTAL_CHAPTER_ADMIN" ? (
                              <GetChapterName id={event?.createdBy?.id} />
                            ) : (
                              <GetSIGName id={event?.createdBy?.id} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {event?.isDeleted === 2 ? <span
                              className="text-danger fw-bold"
                            >
                              Disabled
                            </span> :
                              <>
                                {event.isApproved === 1 && (
                                  <span
                                    style={{ color: "Green", fontWeight: "600" }}
                                  >
                                    Approved
                                  </span>
                                )}
                                {event.isApproved === 0 && (
                                  <span
                                    style={{
                                      color: "#766bcb",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Pending
                                  </span>
                                )}
                                {event.isApproved === 2 && (
                                  <p
                                    onClick={() => handleOpen1(event)}
                                    className="text-danger text-decoration-underline fw-bold"
                                  >
                                    Rejected
                                  </p>
                                )}
                              </>}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              verticalAlign: "middle", // Align content to the middle of the cell
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              gap="8px"
                              flexWrap="wrap"
                            >
                              {!(event.isDeleted === 2) && (
                                <Tooltip title="View" placement="top">
                                  <IconButton
                                    aria-label="ViewIcon"
                                    onClick={() =>
                                      navigate("/VieweventFeed/" + event._id)
                                    }
                                  >
                                    <VisibilityIcon className="icon-vieweye-color" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {(memberScope === "PORTAL_SUPER_ADMIN" ||
                                memberScope === "PORTAL_SIG_ADMIN" ||
                                memberScope === "PORTAL_CHAPTER_ADMIN" ||
                                memberScope === "PORTAL_ADMIN") && (
                                  <>
                                    {((memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN")
                                      ?
                                      (event?.isApproved === 0 ||
                                        event?.isApproved === 2 || event?.isApproved === 1)
                                      : Object.keys(roleDetails).length !== 0
                                        ? event.createdBy.id === userId &&
                                        (event?.isApproved === 0 ||
                                          event?.isApproved === 2)
                                        : event.createdBy.id === userId &&
                                        (event?.isApproved === 0 ||
                                          event?.isApproved === 2)) && (
                                        <>
                                          {event.membersId.length === 0 &&
                                            event?.nonMemberDetails.length ===
                                            0 && (
                                              <>
                                                {!(event.isDeleted === 2) && (
                                                  <Tooltip
                                                    title="Edit"
                                                    placement="top"
                                                  >
                                                    <IconButton aria-label="EditIcon">
                                                      <Link
                                                        to={
                                                          memberScope ===
                                                            "PORTAL_SUPER_ADMIN"
                                                            ? "/admin/eventForm/" +
                                                            event._id
                                                            : memberScope ===
                                                              "PORTAL_ADMIN"
                                                              ? "/assistantAdmin/eventForm/" +
                                                              event._id
                                                              : memberScope ===
                                                                "PORTAL_SIG_ADMIN"
                                                                ? "/sigAdmin/eventForm/" +
                                                                event._id
                                                                : "/chapterAdmin/eventForm/" +
                                                                event._id
                                                        }
                                                      >
                                                        <EditIcon
                                                          style={{ color: "green" }}
                                                        />
                                                      </Link>
                                                    </IconButton>
                                                  </Tooltip>
                                                )}
                                              </>
                                            )}
                                        </>
                                      )}
                                    {event.isApproved === 1 && (
                                      <Tooltip
                                        title="Member Register/Non-Member Register"
                                        placement="top"
                                      >
                                        <IconButton
                                          aria-label="member"
                                          disabled={event.isApproved === 2}
                                          onClick={() =>
                                            navigate(
                                              "/viewRegistered/" + event._id
                                            )
                                          }
                                        >
                                          <GroupIcon
                                            style={{
                                              color: "purple",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {(memberScope === "PORTAL_SUPER_ADMIN" ||
                                      memberScope === "PORTAL_ADMIN"
                                      ?
                                      event.isApproved !== ''
                                      : Object.keys(roleDetails).length !== 0
                                        ? event.createdBy.id === userId &&
                                        event.isApproved !== 1
                                        : event.createdBy.id === userId &&
                                        event.isApproved !== 1) && (
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "8px",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {event?.isDeleted === 0 ? (
                                            <Tooltip title="Enable" placement="top">
                                              <IconButton
                                                aria-label="enable"
                                                onClick={() => {
                                                  handleEnableDisable(event._id);
                                                }}
                                              >
                                                <LockOpenIcon
                                                  style={{ color: "green" }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          ) : event?.isDeleted === 2 ? (
                                            <Tooltip
                                              title="Disable"
                                              placement="top"
                                            >
                                              <IconButton
                                                aria-label="disable"
                                                onClick={() => {
                                                  handleEnableDisable(event._id);
                                                }}
                                              >
                                                <LockIcon
                                                  style={{ color: "red" }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          ) : null}
                                          <Tooltip title="Delete" placement="top">
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() => handleOpen(event._id)}
                                            >
                                              <DeleteIcon
                                                style={{ color: "red" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      )}
                                    {event?.isDeleted === 2 && memberScope === ('PORTAL_SIG_ADMIN' || 'PORTAL_CHAPTER_ADMIN') && <span>
                                      -----
                                    </span>}
                                  </>
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Event?
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#2e7d32",
                            border: "1px solid rgba(46, 125, 50, 0.5)",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#dc3545",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal> */}
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                    >

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                          Confirmation
                        </Typography>
                        <IconButton onClick={handleClose} aria-label="Close">
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Box display="flex" justifyContent="center" width="100%">
                        <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                      </Box>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Event?
                      </Typography>
                      {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          variant="outlined"
                          style={{

                            color: "#dc3545",
                            border: "1px solid #dc3545",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#2e7d32",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex  flex-column gap-3"
                    >
                      {/* <h4 style={{ color: "#104e29" }}>Rejection Reason</h4> */}
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                          Rejection Reason
                        </Typography>
                        <IconButton onClick={handleClose1} aria-label="Close">
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Box display="flex" justifyContent="center" width="100%">
                        <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                      </Box>
                      <Typography
                        id="modal-modal-title"
                        // style={{ color: "#611f1e", textAlign: "justify" }}
                        variant="h6"
                        component="h2"
                        style={{
                          textAlign: 'justify',
                          color: "#611f1e",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",

                        }}
                      >
                        {selectedEvent1?.rejectionReason}
                      </Typography>
                      {/* <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "red",
                            border: "1px solid red",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose1}
                        >
                          Close
                        </Button>
                      </div> */}
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
              <br />
              {allEvent?.length > 0 && (
                <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        min={1}
                        placeholder="Page no."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>

                  {/* Pagination in the Center */}
                  <div className="my-3 my-md-0">
                    <ReactPaginate
                      pageCount={pageCount} // Calculate total pages
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      forcePage={page}
                      previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                      nextLabel={
                        pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                      }
                      disabledClassName={"disablebtn"}
                    />
                  </div>
                  <div className="mb-2 mb-md-0">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('');
                          setSearchTerm('');
                          setPage(0);
                        }}
                        MenuProps={{
                          MenuListProps: {
                            sx: {
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          },
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>

                    </FormControl>
                  </div>
                </div>

              )}
            </Box>
            <br />
          </div>
        )}
      </div >
    </>
  );
}
