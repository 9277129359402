import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  useLazyGetChapterDetailsByIdQuery,
  useMakeAdminMutation,
  useRemoveAdminMutation,
} from "../../app/services/chapterServices";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import TopHeader from "../../Component/Header/TopHeader";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from '@mui/icons-material/Key';
import Tooltip from "@mui/material/Tooltip";
import { useResetPasswordMutation } from "../../app/services/loginServices";


//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "calories", numeric: true, disablePadding: false, label: "Email Id" },
    { id: "role", numeric: true, disablePadding: false, label: "Role" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        backgroundColor: "#0072bb",
        textAlign: "center",
        color: "white",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {numSelected > 0 ? `${numSelected} selected` : "Office Bearers List"}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 550,
  minWidth: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MakeAdmin = () => {
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleClose = () => setOpen(false);
  const { chapterId } = useParams();
  const [chapterDetails, { data: chapterData }] =
    useLazyGetChapterDetailsByIdQuery();
  const [makeMemberAdmin] = useMakeAdminMutation();
  const [removeMemberAdmin] = useRemoveAdminMutation();
  const [resetPassword] = useResetPasswordMutation();

  useEffect(() => {
    chapterDetails(chapterId);
  }, [chapterDetails, chapterId]);

  //Remove admin
  const removeAdmin = (member) => {
    const data = {
      chapterId,
      roleId: member._id,
      email: member.email,
    };
    setLoading(true);
    removeMemberAdmin(data).then((res) => {
      if (res.data?.data) {
        setLoading(false);
        chapterDetails(chapterId);
        toast.success("Admin removed successfully");
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Make admin
  const makeAdmin = () => {
    const data = {
      chapterId: chapterId,
      roleId: selectedMember._id,
      email: selectedMember.email,
      password: selectedMember.email.split('@')[0],
    };
    setLoading(true);
    makeMemberAdmin(data).then((res) => {
      handleClose();
      setLoading(false);
      if (res.data.status) {
        chapterDetails(chapterId);
        toast.success(res.data?.message);
      } else {
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Reset Password
  const handleResetPassword = (email) => {
    var data = {
      email: email,
      role: "PORTAL_CHAPTER_ADMIN"
    }
    resetPassword(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      }
      else {
        toast.error(res?.data?.message)
      }
    }).catch(err => console.error(err))
  }

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Make/Remove Admin</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box className='p-md-5 p-3 overallPadding'>
          <div
            className="text-end w-100 mb-3"
          >
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
            >
              Back
            </Button>
          </div>
          <TableContainer className='shadow-none'>

            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {chapterData?.data?.roleDetails.length > 0 ? (
                  chapterData?.data?.roleDetails.map((member, index) => (
                    <TableRow
                      hover
                      key={member._id}
                      sx={{
                        cursor: "pointer",
                        backgroundColor:
                          index % 2 === 0 ? "#f0f0f0" : "white",
                      }}
                    >
                      <TableCell align="center">{member.name}</TableCell>
                      <TableCell align="center">{member.email}</TableCell>
                      <TableCell align="center">{member.role}</TableCell>
                      <TableCell align="center">
                        {member.isAdmin ? (
                          <>
                            <Tooltip title="Remove Admin">
                              <IconButton
                                color="error"
                                onClick={() => removeAdmin(member)}
                                title="Remove Admin"
                                disabled={!member.isAdmin}
                                aria-label="approve"
                              >
                                <CloseIcon color="error"></CloseIcon>
                              </IconButton>
                            </Tooltip>
                            <IconButton onClick={() => handleResetPassword(member.email)}>
                              <Tooltip title="Reset Password">
                                <KeyIcon style={{ color: 'darkred' }} />
                              </Tooltip>
                            </IconButton>
                          </>
                        ) : (
                          <>
                            {!chapterData?.data?.roleDetails.some(role => role.isAdmin) ?
                              <Tooltip title="Make Admin">
                                <IconButton
                                  onClick={() => handleOpen(member)}
                                  aria-label="approve"
                                  title="Make Admin"
                                >
                                  <DoneIcon color="success"></DoneIcon>
                                </IconButton>
                              </Tooltip> : '----'
                            }
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='d-grid place-items-center gap-3 mx-auto'>
          <Typography id="modal-modal-title" className="justified" variant="h6" component="h2" style={{ color: "#134b2b" }}>
            Are you sure you want to make <span className="text-secondary-new text-capitalize">{selectedMember && selectedMember?.name}</span> an admin for this chapter?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="outlined" className="rounded-0 me-3" color="error" onClick={handleClose} >No</Button>
            <Button onClick={() => makeAdmin()} className="menus-color" type='submit' variant='contained'>Yes</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MakeAdmin;
