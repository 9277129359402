import { api } from "./api";

const membershipServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //getAllMemberDetails
    getAllMemberDetails: builder.query({
      query: () => "member/getall",
    }),

    //getMemberDetailsById
    getMemberDetailsById: builder.query({
      query: (id) => "member/get/" + id,
    }),

    //createMember
    createMember: builder.mutation({
      query: (newMemberData) => ({
        url: "member/createMember",
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: newMemberData,
      }),
    }),

    createDualMember: builder.mutation({
      query: (newMemberData) => ({
        url: "member/createDualMember",
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: newMemberData,
      }),
    }),

    //Update Member
    UpdateMember: builder.mutation({
      query: (data) => ({
        url: "member/updatemember/" + data.id,
        method: "PUT",
        body: data.formData,
      }),
    }),

    //Update plan Renewal
    UpdatePlansRenewal: builder.mutation({
      query: (data) => ({
        url: `member/updateMemberRenewal/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:data
      }),
    }),
    getInstituteBasedOnState: builder.query({
      query: () => "member/getInstituteBasedOnState",
    }),

    //to approve the notpaid member list
    addNotPaidMember: builder.mutation({
      query: (newMemberData) => ({
        url: "/member/notPaidMember",
        method: "POST",
        // headers: { "Content-Type": "application/json" },
        body: newMemberData,
      }),
    }),

    //updateChangeplan
    UpdateChangePlan: builder.mutation({
      query: ({ id, data }) => ({
        url: `member/updateChangePlan/` + id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
    //updateChangeplan
    searchMember: builder.mutation({
      query: (data) => ({
        url: `member/searchMember`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAllMemberDetailsQuery,
  useLazyGetMemberDetailsByIdQuery,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useUpdatePlansRenewalMutation,
  useUpdateChangePlanMutation,
  useLazyGetInstituteBasedOnStateQuery,
  useCreateDualMemberMutation,
  useAddNotPaidMemberMutation,
  useSearchMemberMutation,
} = membershipServices;
