import React, { useState, useEffect, useCallback } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../../style/css/header.css';
import '../../style/css/banner.css';
import { selectCurrentScope, selectCurrentUser, selectImageUrl } from '../../app/auth/authSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import banner1 from '../../assets/images/banner/banner2.png'
import banner2 from '../../assets/images/AboutPage/abt_bnr1.png'
import banner3 from '../../assets/images/banner/form_banner.png'
import banner5 from '../../assets/images/banner/viewwebinar.png'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { logOut } from '../../app/auth/authSlice';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import Logout from '@mui/icons-material/Logout';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { selectCurrentMember, setMemberDetails } from '../../app/features/memberSlice';
import { setRoleDetails, selectCurrentChapterAdmin, selectCurrenChapterRole, setChapterDetails } from "../../app/features/chapterAdminSlice";
import { setSigRoleDetails, selectCurrenSigRole, setSigDetails } from '../../app/features/sigSlice';
import { api } from '../../app/services/api';
// import { useGetAllAnnouncementQuery } from '../../app/services/announcementService';
import Box from '@mui/material/Box';
import logo from '../../assets/images/logos/ELTA@I.svg'
import XIcon from '@mui/icons-material/X';
import constitutionpdf from '../../assets/pdf/Constitution-ELTAI.pdf'
import Modal from '@mui/material/Modal';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useChangePasswordMutation } from '../../app/services/loginServices';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Header() {
    const [backgroundImage, setBackgroundImage] = useState('');
    const memberScope = useSelector(selectCurrentScope)
    const [dropdownHover, setDropdownHover] = useState(null);
    const navigate = useNavigate()
    const currentLocation = useLocation();
    const [subDropdownHover, setSubDropdownHover] = useState(null);
    const [topBarButtons, setTopBarButtons] = useState(0); // Default top position
    // const { data: getAllAnnouncement } = useGetAllAnnouncementQuery(0); //API call of get All announcement
    const [navMoreShow, setNavMoreShow] = useState(false)
    const [expanded, setExpanded] = useState(false); // Manage expanded state
    const [isMobile, setIsMobile] = useState(false);
    const [cursorStyle, setCursorStyle] = useState("pointer")
    const [opens, setOpens] = React.useState(false);
    const handleOpens = () => setOpens(true);
    const handleCloses = () => {
        setOpens(false)
        reset()
    }
    const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false })
    const [changePassword] = useChangePasswordMutation();


    // //Scrolling 
    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollTop = window.pageYOffset;

    //         if (scrollTop > 50) {
    //             setTopPosition(0); // Change top position while scrolling

    //         } else {
    //             setTopPosition(0); // Default top position
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [role, setRole] = useState('')
    const open = Boolean(anchorEl);
    const disPatch = useDispatch();
    const authDetails = useSelector(selectCurrentUser);
    const chapterDetail = useSelector(selectCurrentChapterAdmin);
    const memberDetails = useSelector(selectCurrentMember);
    const roleDetails = useSelector(selectCurrenChapterRole);
    const sigRoleDetails = useSelector(selectCurrenSigRole);
    const sigdetail = useSelector(selectCurrenSigRole)
    const sigImageUrl = useSelector(setSigRoleDetails)
    const chapterImageUrl = useSelector(selectImageUrl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const { control, handleSubmit, watch, formState: { errors }, reset } = useForm({ mode: 'onChange', defaultValues: { newPassword: '', oldPassword: '' } });

    const onChangePassword = (data) => {
        // var authDetails = localStorage.getItem('persist:root')
        // setEmail((memberScope === "PORTAL_SIG_ADMIN") ? SigAdminManagement : (memberScope === "PORTAL_CHAPTER_ADMIN") ? ChapterAdminManagement : (memberScope === "PORTAL_ADMIN") ? AssistantAdminManagement : SuperAdminManagement);
        // const updateData = { data }
        data.email = authDetails
        changePassword(data).then((res) => {
            if (res?.data?.status) {

                setOpens(false);
                reset()
                toast.success(res?.data?.message);
                handleLogout()

            }
            else {
                toast.error(res?.data?.message)
            }
        }).catch(err => console.error(err))

    };

    const oldPassword = watch("oldPassword");

    //handle Click show password
    const handleClickShowPassword = (type) => {
        setShowPassword({ ...showPassword, [type]: !showPassword[type] });
    };

    //Close
    const handleClose = () => {
        setAnchorEl(null);
    };

    //profile set
    const getAvatarSrc = (memberScope, memberDetails, chapterImageUrl, sigImageUrl) => {
        return `${chapterImageUrl}?t=${new Date().getTime()}`;
    };

    const AvatarComponent = ({ memberScope, memberDetails, chapterImageUrl, sigImageUrl }) => {
        const avatarSrc = getAvatarSrc(memberScope, memberDetails, chapterImageUrl, sigImageUrl);
        return <Avatar sx={{ width: 32, height: 32, bgcolor: '#f13700' }} src={avatarSrc} />;
    };

    const membershipRouteUrl = (id) => {
        if (memberDetails.type === "0" || memberDetails.type === "4") {
            navigate(`/member/teacher-member/${id}`);
        } else if (memberDetails.type === "1") {
            navigate(`/member/student-member/${id}`);
        } else if (memberDetails.type === "2") {
            navigate(`/member/View-institution/${id}`);
        } else {
            navigate(`/member/view-corporate/${id}`);
        }
    }


    //handle Log Out
    const handleLogout = () => {
        disPatch(logOut())
        navigate('/login', { replace: true, state: { from: location } })
        disPatch(setMemberDetails({}))
        disPatch(setRoleDetails({}))
        disPatch(setSigRoleDetails({}))
        disPatch(setChapterDetails({}))
        disPatch(setSigDetails({}))
        disPatch(api.util.resetApiState())
    }

    //handleREsize
    const handleResize = useCallback(() => {
        const width = window.innerWidth;
        if (width >= 769 && width <= 1430) {
            setTopBarButtons(7)
            if (width < 992) {
                setTopBarButtons(56)
                setIsMobile(true);
            } else {
                setTopBarButtons(7)
                setIsMobile(false);
            }
            setNavMoreShow(true);
        } else if (width < 992) {
            setIsMobile(true);
            setTopBarButtons(56)
            setNavMoreShow(false);
        } else if (width >= 992) {
            setTopBarButtons(7)
            setNavMoreShow(false);
            setIsMobile(false);
        } else {
            // setTopBarButtons(7)
            setNavMoreShow(false);
        }
    }, []);



    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially to set state based on the initial window size
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    //role member Scope
    useEffect(() => {
        if (memberScope === 'PORTAL_SUPER_ADMIN') {
            setRole('Admin');
        }
        else if (memberScope === 'PORTAL_MEMBER') {
            setRole('Member');
        }
        else if (memberScope === 'PORTAL_SIG_ADMIN') {
            setRole('SIG Admin');
        }
        else if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
            setRole('Chapter Admin');
        }
        else if (memberScope === 'PORTAL_ADMIN') {
            setRole('Assistant Admin');
        }
    }, [memberScope]);

    //route of Potral 
    const routeToPortal = () => {
        if (role === 'Member') {
            navigate('/member/viewMember')
        }
        else if (role === 'SIG Admin') {
            navigate('/sigAdmin/sigDetails')
        }
        else if (role === 'Chapter Admin') {
            navigate('/chapterAdmin/chapterNewsFeed')
        }
        else if (role === "Admin") {
            setCursorStyle("default");
            return;
        }
        else if (role === "Assistant Admin") {
            setCursorStyle("default");
            return;
        }
    }

    //handlemouseenter
    const handleMouseEnter = (index) => {
        setDropdownHover(index);
        setSubDropdownHover(null); // Reset sub-dropdown hover
    };

    //handlemouseleave
    const handleMouseLeave = () => {
        setDropdownHover(null);
        setSubDropdownHover(null);
    };

    const handleMobileClick = (index) => {
        if (isMobile) {
            // Toggle dropdown visibility on mobile
            setDropdownHover(dropdownHover === index ? null : index);
        }
    };


    const handleSubMobileClick = (index) => {
        if (isMobile) {
            // Toggle nested submenu on mobile
            setSubDropdownHover(subDropdownHover === index ? null : index);
        }
    };

    //handle Sub Mouse Enter
    const handleSubMouseEnter = (index) => {
        setSubDropdownHover(index);
        // if(isMobile){
        //     setSubDropdownHover(subDropdownHover === index ? null : index);
        // }
    };

    //handle Sub Mouse Leave
    const handleSubMouseLeave = () => {
        setSubDropdownHover(null);
    };

    //menu Items
    const menuItems = memberScope === 'PORTAL_SUPER_ADMIN' ?
        (!navMoreShow) ? (
            [
                {
                    label: 'My Dashboard',
                    url: '/admin/management',
                },
                {
                    label: 'Home',
                    url: '/admin/'
                },
                {
                    label: 'About',
                    // url:'/aboutUs',
                    subMenu: [
                        { label: 'Who We Are', url: '/admin/aboutUs' },
                        { label: 'Our Vision', url: '/admin/vision' },
                        { label: 'Our Logo', url: '/admin/ourLogo' },
                        { label: 'Our Mission', url: '/admin/mission' },
                        { label: "Present Office Bearers", url: '/admin/officeBearers' }
                    ]
                },
                {
                    label: 'Join',
                    url: '',
                    subMenu: [
                        { label: 'Membership', url: '/admin/membership-details' },
                        { label: 'Membership Plans', url: '/admin/viewMembershipPlans' },
                        { label: 'Member List', url: '/admin/allMemberList' },
                        { label: 'Membership Benefits', url: '/member-benefits' },
                        // { label: 'Become a member ', url: "/membershipPage" },
                        { label: 'Our Institutional Members', url: '/membership-institution' },
                    ]
                },
                {
                    label: 'Network',
                    subMenu: [
                        {
                            label: 'Chapters',
                            subMenu: [
                                { label: 'Chapters List', url: '/admin/view-Chapter' },
                                { label: "Start a Chapter", url: '/admin/chapter-starting' }
                            ]
                        },
                        {
                            label: 'SIGs',
                            url: '/eltai-sig',
                            subMenu: [
                                { label: 'Literature SIG' },
                                { label: 'Computer Technology SIG' },
                                { label: 'Business English SIG' },
                                { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                { label: 'SIG List', url: '/admin/addEditSigGroups' },
                            ]
                        },
                    ]
                },
                {
                    label: 'Publish',
                    url: '',
                    subMenu: [
                        { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                        { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                        { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                        { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                        { label: "Our Newsletter" },

                    ]
                },
                {
                    label: 'India Reads',
                    subMenu: [
                        { label: 'Why India Reads ', url: '/India-Reads' },
                        { label: 'Our Updates', url: '/news-home' },
                        { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                    ]
                },
                {
                    label: 'Partner',
                    subMenu: [
                        { label: 'Partner with Us  ' },
                        { label: 'Our Partners', url: '/partner' },
                        { label: 'Partners List', url: '/admin/partner-table' },
                    ]
                },
                {
                    label: 'Consult',
                    url: ''
                },
                {
                    label: 'Awards',
                    url: ''
                },
                {
                    label: 'Participate ',
                    subMenu: [
                        { label: 'Our Annual Conference', url: 'annual-conference' },
                        { label: 'Our Webinars ' },
                        // { label: 'Annual Conference List', url: '/admin/annualConferenceList' },
                    ]
                },
                {
                    label: 'Archives ',
                    subMenu: [
                        { label: 'Reports   ' },
                        { label: 'Galleries ', url: '/view-Gallery', },
                    ]
                },
                {
                    label: 'Contact Us',
                    url: '/admin/contact-us'
                },
                {
                    label: 'More',
                    subMenu: [
                        { label: 'Category ', url: '/admin/viewCategory' },
                        { label: 'Assistant Admin', url: '/admin/assistantAdmin' },
                        { label: 'Our Updates', url: '/admin/journalTable' },
                        // { label: 'Testimonials', url: '/admin/viewTestimonials' },
                        // { label: 'Newsletter', url: '/admin/viewNewsLetter' },
                        { label: 'Annual Conference', url: '/admin/annualConferenceList' },
                        { label: 'Report Non Receipt', url: '/admin/non-receipt' },
                    ]
                }
            ]
        ) : (
            [
                {
                    label: 'My Dashboard',
                    url: '/admin/management',
                },
                {
                    label: 'Home',
                    url: '/admin/'
                },
                {
                    label: 'About',
                    subMenu: [
                        { label: 'Who We Are', url: '/admin/aboutUs' },
                        { label: 'Our Vision', url: '/admin/vision' },
                        { label: 'Our Logo', url: '/admin/ourLogo' },
                        { label: 'Our Mission', url: '/admin/mission' },
                        { label: "Present Office Bearers", url: '/admin/officeBearers' }
                    ]
                },
                {
                    label: 'Join',
                    url: '',
                    subMenu: [
                        { label: 'Membership', url: '/admin/membership-details' },
                        { label: 'Membership Plans', url: '/admin/viewMembershipPlans' },
                        { label: 'Member List', url: '/admin/allMemberList' },
                        { label: 'Membership Benefits', url: '/member-benefits' },
                        // { label: 'Become a member ', url: "/membershipPage" },
                        { label: 'Our Institutional Members', url: '/membership-institution' },
                        // { label: 'Membership Management', url: '/admin/membershipManagement' },
                    ]
                },
                {
                    label: 'Network',
                    subMenu: [
                        {
                            label: 'Chapters',
                            subMenu: [
                                { label: 'Chapters List', url: '/admin/view-Chapter' },
                                { label: "Start a Chapter", url: '/admin/chapter-starting' }
                            ]
                        },
                        {
                            label: 'SIGs',
                            url: '/eltai-sig',
                            subMenu: [
                                // { label: 'Sig List', url: '/addEditSigGroups' },
                                { label: 'Literature SIG' },
                                { label: 'Computer Technology SIG' },
                                { label: 'Business English SIG' },
                                // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                { label: 'SIG List', url: '/admin/addEditSigGroups' },
                            ]
                        },
                    ]
                },
                {
                    label: 'Publish',
                    url: '',
                    subMenu: [
                        { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                        { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                        { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                        { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                        { label: "Our Newsletter" },

                    ]
                },
                {
                    label: 'India Reads',
                    subMenu: [
                        { label: 'Why India Reads ', url: '/India-Reads' },
                        { label: 'Our Updates', url: '/news-home' },
                        { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                    ]
                },
                {
                    label: 'Partner',
                    subMenu: [
                        { label: 'Partner with Us  ' },
                        { label: 'Our Partners', url: '/partner' },
                        { label: 'Partners List', url: '/admin/partner-table' },
                    ]
                },
                {
                    label: 'Contact Us',
                    url: '/admin/contact-us'
                },
                {
                    label: 'More',
                    subMenu: [
                        { label: 'Consult', url: '' },
                        { label: 'Awards', url: '' },
                        {
                            label: 'Archives ', subMenu: [
                                { label: 'Reports   ' },
                                { label: 'Galleries ', url: '/view-Gallery', },
                            ]
                        },
                        {
                            label: 'Participate ',
                            subMenu: [
                                { label: 'Our Annual Conference', url: 'annual-conference' },
                                { label: 'Our Webinars ' },
                                { label: 'Annual Conference List', url: '/admin/annualConferenceList' },
                            ]
                        },
                        { label: 'Category ', url: '/admin/viewCategory' },
                        { label: 'Assistant Admin', url: '/admin/assistantAdmin' },
                        { label: 'Our Updates', url: '/admin/journalTable' },
                        // { label: 'Testimonials', url: '/admin/viewTestimonials' },
                        // { label: 'Newsletter', url: '/admin/viewNewsLetter' },
                        { label: 'Annual Conference', url: '/admin/annualConferenceList' },
                        { label: 'Report Non Receipt', url: '/admin/non-receipt' },


                        // {
                        //     label: 'Member Report', subMenu: [
                        //         { label: "Category Wise Membership", url: '/admin/category-wise-member-ship' },
                        //         { label: "State Membership", url: '/admin/state-member-ship' },

                    ]
                }
            ]
        ) :
        memberScope === 'PORTAL_SIG_ADMIN' ?
            (!navMoreShow) ? (
                [
                    {
                        label: 'My Dashboard',
                        url: '/sigAdmin/management',
                    },
                    {
                        label: 'Home',
                        url: '/sigAdmin/'
                    },
                    {
                        label: 'About',
                        subMenu: [
                            { label: 'Who We Are', url: '/sigAdmin/aboutUs' },
                            { label: 'Our Vision', url: '/sigAdmin/vision' },
                            { label: 'Our Mission', url: '/sigAdmin/mission' },
                            { label: 'Our Logo', url: '/sigAdmin/ourLogo' },
                            { label: "Present Office Bearers", url: '/sigAdmin/officeBearers' }
                        ]
                    },
                    {
                        label: 'Join',
                        url: '',
                        subMenu: [
                            { label: 'Membership', url: '/sigAdmin/membership-details' },
                            { label: 'Membership Benefits', url: '/member-benefits' },
                            // { label: 'Become a member ', url: "/membershipPage" },
                            { label: 'Our Institutional Members', url: '/membership-institution' },
                        ]
                    },
                    {
                        label: 'Network',
                        subMenu: [
                            {
                                label: 'Chapters',
                                subMenu: [
                                    { label: 'Chapters List', url: '/sigAdmin/view-Chapter' },
                                    { label: "Start a Chapter", url: '/sigAdmin/chapter-starting' }
                                ]
                            },
                            {
                                label: 'SIGs',
                                url: '/eltai-sig',
                                subMenu: [
                                    { label: 'Literature SIG' },
                                    { label: 'Computer Technology SIG' },
                                    { label: 'Business English SIG' },
                                    { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                    { label: 'SIG List', url: '/sigAdmin/addEditSigGroups' },
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Publish',
                        url: '',
                        subMenu: [
                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                            { label: "Our Newsletter" },

                        ]
                    },
                    {
                        label: 'India Reads',
                        subMenu: [
                            { label: 'Why India Reads ', url: '/India-Reads' },
                            { label: 'Our Updates', url: '/news-home' },
                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                        ]
                    },
                    {
                        label: 'Partner',
                        subMenu: [
                            { label: 'Partner with Us  ' },
                            { label: 'Our Partners', url: '/partner' },
                        ]
                    },
                    {
                        label: 'Consult',
                        url: ''
                    },
                    {
                        label: 'Awards',
                        url: ''
                    },
                    {
                        label: 'Participate ',
                        subMenu: [
                            { label: 'Our Annual Conference', url: 'annual-conference' },
                            { label: 'Our Webinars ' },
                        ]
                    },
                    {
                        label: 'Archives ',
                        subMenu: [
                            { label: 'Reports   ' },
                            { label: 'Galleries ', url: '/view-Gallery', },
                        ]
                    },
                    {
                        label: 'Contact Us',
                        url: 'sigAdmin/contact-us'
                    },
                    {
                        label: 'More',
                        subMenu: [
                            { label: 'Our Updates', url: '/sigAdmin/journalTable' },
                            // { label: 'Announcement', url: '/sigadmin/importannoucenment' },
                        ]
                    },
                ]
            ) : (
                [
                    {
                        label: 'My Dashboard',
                        url: '/sigAdmin/management',
                    },
                    {
                        label: 'Home',
                        url: '/sigAdmin/'
                    },
                    {
                        label: 'About',
                        subMenu: [
                            { label: 'Who We Are', url: '/sigAdmin/aboutUs' },
                            { label: 'Our Vision', url: '/sigAdmin/vision' },
                            { label: 'Our Mission', url: '/sigAdmin/mission' },
                            { label: 'Our Logo', url: '/sigAdmin/ourLogo' },
                            { label: "Present Office Bearers", url: '/sigAdmin/officeBearers' }
                        ]
                    },
                    {
                        label: 'Join',
                        url: '',
                        subMenu: [
                            { label: 'Membership', url: '/sigAdmin/membership-details' },
                            { label: 'Membership Benefits', url: '/member-benefits' },
                            // { label: 'Become a member ', url: "/membershipPage" },
                            { label: 'Our Institutional Members', url: '/membership-institution' },
                        ]
                    },
                    {
                        label: 'Network',
                        subMenu: [
                            {
                                label: 'Chapters',
                                subMenu: [
                                    { label: 'Chapters List', url: '/sigAdmin/view-Chapter' },
                                    { label: "Start a Chapter", url: '/sigAdmin/chapter-starting' }
                                ]
                            },
                            {
                                label: 'SIGs',
                                url: '/eltai-sig',
                                subMenu: [
                                    { label: 'Literature SIG' },
                                    { label: 'Computer Technology SIG' },
                                    { label: 'Business English SIG' },
                                    { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                    { label: 'SIG List ', url: '/sigAdmin/addEditSigGroups' },
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Publish',
                        url: '',
                        subMenu: [
                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                            { label: "Our Newsletter" },

                        ]
                    },
                    {
                        label: 'India Reads',
                        subMenu: [
                            { label: 'Why India Reads ', url: '/India-Reads' },
                            { label: 'Our Updates', url: '/news-home' },
                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                        ]
                    },
                    {
                        label: 'Partner',
                        subMenu: [
                            { label: 'Partner with Us  ' },
                            { label: 'Our Partners', url: '/partner' },
                        ]
                    },
                    {
                        label: 'Contact Us',
                        url: 'sigAdmin/contact-us'
                    },
                    {
                        label: 'More',
                        subMenu: [
                            { label: 'Consult', url: '' },
                            { label: 'Awards', url: '' },
                            {
                                label: 'Participate ', subMenu: [
                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                    { label: 'Our Webinars ' },
                                ]
                            },
                            {
                                label: 'Archives ', subMenu: [
                                    { label: 'Reports   ' },
                                    { label: 'Galleries ', url: '/view-Gallery', },
                                ]
                            },
                            { label: 'Our Updates', url: '/sigAdmin/journalTable' },
                            // { label: 'Announcement', url: '/sigadmin/importannoucenment' }
                        ]
                    },
                ]
            )
            : memberScope === 'PORTAL_CHAPTER_ADMIN' ?
                (!navMoreShow) ? (
                    [
                        {
                            label: 'My Dashboard',
                            url: '/chapterAdmin/management',
                        },
                        {
                            label: 'Home',
                            url: '/chapterAdmin/'
                        },
                        {
                            label: 'About',
                            subMenu: [
                                { label: 'Who We Are', url: '/chapterAdmin/aboutUs' },
                                { label: 'Our Vision', url: '/chapterAdmin/vision' },
                                { label: 'Our Mission', url: '/chapterAdmin/mission' },
                                { label: 'Our Logo', url: '/chapterAdmin/ourLogo' },
                                { label: "Present Office Bearers", url: '/chapterAdmin/officeBearers' }
                            ]
                        },
                        {
                            label: 'Join',
                            url: '',
                            subMenu: [
                                { label: 'Membership', url: '/chapterAdmin/membership-details' },
                                // { label: 'Membership Plans', url: '/admin/viewMembershipPlans' },
                                // { label: 'Member List', url: '/admin/allMemberList' },
                                { label: 'Membership Benefits', url: '/member-benefits' },
                                // { label: 'Become a member ', url: "/membershipPage" },
                                { label: 'Our Institutional Members', url: '/membership-institution' },
                            ]
                        },
                        {
                            label: 'Network',
                            subMenu: [
                                {
                                    label: 'Chapters',
                                    subMenu: [
                                        { label: 'Chapters List', url: '/chapterAdmin/view-Chapter' },
                                        { label: "Start a Chapter", url: '/chapterAdmin/chapter-starting' }
                                    ]
                                },
                                {
                                    label: 'SIGs',
                                    url: '/eltai-sig',
                                    subMenu: [
                                        { label: 'Literature SIG' },
                                        { label: 'Computer Technology SIG' },
                                        { label: 'Business English SIG' },
                                        { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                        { label: 'SIG List', url: '/chapterAdmin/addEditSigGroups' },
                                    ]
                                },
                            ]
                        },
                        {
                            label: 'Publish',
                            url: '',
                            subMenu: [
                                { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                { label: "Our Newsletter" },

                            ]
                        },
                        {
                            label: 'India Reads',
                            subMenu: [
                                { label: 'Why India Reads ', url: '/India-Reads' },
                                { label: 'Our Updates', url: '/news-home' },
                                { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                            ]
                        },
                        {
                            label: 'Partner',
                            subMenu: [
                                { label: 'Partner with Us  ' },
                                { label: 'Our Partners', url: '/partner' },
                            ]
                        },
                        {
                            label: 'Consult',
                            url: ''
                        },
                        {
                            label: 'Awards',
                            url: ''
                        },
                        {
                            label: 'Participate ',
                            subMenu: [
                                { label: 'Our Annual Conference', url: 'annual-conference' },
                                { label: 'Our Webinars ' },
                            ]
                        },
                        {
                            label: 'Archives ',
                            subMenu: [
                                { label: 'Reports   ' },
                                { label: 'Galleries ', url: '/view-Gallery', },
                            ]
                        },
                        {
                            label: 'Contact us',
                            url: '/chapterAdmin/contact-us'
                        },
                        {
                            label: 'More',
                            subMenu: [
                                { label: 'Our Updates', url: '/chapterAdmin/journalTable' },
                                // { label: 'Announcement', url: '/chapterAdmin/importannoucenment' },
                            ]
                        },
                    ]
                ) : (
                    [
                        {
                            label: 'My Dashboard',
                            url: '/chapterAdmin/management',
                        },
                        {
                            label: 'Home',
                            url: '/chapterAdmin/'
                        },
                        {
                            label: 'About',
                            subMenu: [
                                { label: 'Who We Are', url: '/chapterAdmin/aboutUs' },
                                { label: 'Our Vision', url: '/chapterAdmin/vision' },
                                { label: 'Our Mission', url: '/chapterAdmin/mission' },
                                { label: 'Our Logo', url: '/chapterAdmin/ourLogo' },
                                { label: "Present Office Bearers", url: '/chapterAdmin/officeBearers' }
                            ]
                        },
                        {
                            label: 'Join',
                            url: '',
                            subMenu: [
                                { label: 'Membership', url: '/chapterAdmin/membership-details' },
                                { label: 'Membership Benefits', url: '/member-benefits' },
                                // { label: 'Become a member ', url: "/membershipPage" },
                                { label: 'Our Institutional Members', url: '/membership-institution' },
                            ]
                        },
                        {
                            label: 'Network',
                            subMenu: [
                                {
                                    label: 'Chapters',
                                    subMenu: [
                                        { label: 'Chapters List', url: '/chapterAdmin/view-Chapter' },
                                        { label: "Start a Chapter", url: '/chapterAdmin/chapter-starting' }
                                    ]
                                },
                                {
                                    label: 'SIGs',
                                    url: '/eltai-sig',
                                    subMenu: [
                                        { label: 'Literature SIG' },
                                        { label: 'Computer Technology SIG' },
                                        { label: 'Business English SIG' },
                                        { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                        { label: 'SIG List', url: '/chapterAdmin/addEditSigGroups' },
                                    ]
                                },
                            ]
                        },
                        {
                            label: 'Publish',
                            url: '',
                            subMenu: [
                                { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                { label: "Our Newsletter" },

                            ]
                        },
                        {
                            label: 'India Reads',
                            subMenu: [
                                { label: 'Why India Reads ', url: '/India-Reads' },
                                { label: 'Our Updates', url: '/news-home' },
                                { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                            ]
                        },
                        {
                            label: 'Partner',
                            subMenu: [
                                { label: 'Partner with Us  ' },
                                { label: 'Our Partners', url: '/partner' },
                            ]
                        },

                        {
                            label: 'Contact us',
                            url: '/chapterAdmin/contact-us'
                        },
                        {
                            label: 'More',
                            subMenu: [
                                { label: 'Consult', url: '' },
                                { label: 'Awards', url: '' },
                                {
                                    label: 'Participate ', subMenu: [
                                        { label: 'Our Annual Conference', url: 'annual-conference' },
                                        { label: 'Our Webinars ' },
                                    ]
                                },
                                {
                                    label: 'Archives ', subMenu: [
                                        { label: 'Reports   ' },
                                        { label: 'Galleries ', url: '/view-Gallery', },
                                    ]
                                },
                                { label: 'Our Updates', url: '/chapterAdmin/journalTable' },
                            ]
                        },
                    ]
                )
                : memberScope === 'PORTAL_ADMIN' ?
                    (!navMoreShow) ? (
                        [
                            {
                                label: 'My Dashboard',
                                url: '/assistantadmin/management',
                            },
                            {
                                label: 'Home',
                                url: '/assistantadmin/'
                            },
                            {
                                label: 'About',
                                subMenu: [
                                    { label: 'Who We Are', url: '/assistantadmin/aboutUs' },
                                    { label: 'Our Vision', url: '/assistantadmin/vision' },
                                    { label: 'Our Logo', url: '/assistantadmin/ourLogo' },
                                    { label: 'Our Mission', url: '/assistantadmin/mission' },
                                    { label: "Present Office Bearers", url: '/assistantadmin/officeBearers' }
                                ]
                            },
                            {
                                label: 'Join',
                                url: '',
                                subMenu: [
                                    { label: 'Membership', url: '/assistantadmin/membership-details' },
                                    // { label: 'Membership Plans', url: '/assistantadmin/viewMembershipPlans' },
                                    // { label: 'Member List', url: '/assistantadmin/allMemberList' },
                                    { label: 'Membership Benefits', url: '/member-benefits' },
                                    // { label: 'Become a member ', url: "/membershipPage" },
                                    { label: 'Our Institutional Members', url: '/membership-institution' },
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Chapters List', url: '/assistantadmin/view-Chapter' },
                                            { label: "Start a Chapter", url: '/assistantadmin/chapter-starting' }
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        url: '/eltai-sig',
                                        subMenu: [
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                            { label: 'SIG List', url: '/assistantadmin/addEditSigGroups' },
                                        ]
                                    },
                                ]
                            },
                            {
                                label: 'Publish',
                                url: '',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'Our Updates', url: '/news-home' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    { label: 'Partner with Us  ' },
                                    { label: 'Our Partners', url: '/partner' },
                                    { label: 'Partners List', url: '/assistantadmin/partner-table' },
                                ]
                            },
                            {
                                label: 'Consult',
                                url: ''
                            },
                            {
                                label: 'Awards',
                                url: ''
                            },
                            {
                                label: 'Participate ',
                                subMenu: [
                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                    { label: 'Our Webinars ' },
                                    { label: 'Annual Conference List', url: '/assistantadmin/annualConferenceList' },
                                ]
                            },
                            {
                                label: 'Archives ',
                                subMenu: [
                                    { label: 'Reports   ' },
                                    { label: 'Galleries ', url: '/view-Gallery', },
                                ]
                            },
                            {
                                label: 'Contact Us',
                                url: '/assistantadmin/contact-us'
                            },
                            {
                                label: 'More',
                                subMenu: [
                                    { label: 'Category ', url: '/assistantadmin/viewCategory' },
                                    // { label: 'Testimonials', url: '/assistantadmin/viewTestimonials' },
                                    { label: 'Our Updates', url: '/assistantadmin/journalTable' },
                                    // { label: 'Newsletter', url: '/assistantadmin/viewNewsLetter' },
                                    // { label: 'Admin Approval', url: '/assistantadmin/approval' },
                                    // { label: 'Newsletter', url: '/assistantadmin/viewNewsLetter' },
                                    { label: 'Annual Conference', url: '/assistantadmin/annualConferenceList' },
                                    // { label: 'Report Non Receipt', url: '/assistantadmin/non-receipt' },

                                ]
                            },
                        ]
                    ) : (
                        [
                            {
                                label: 'My Dashboard',
                                url: '/assistantadmin/management',
                            },
                            {
                                label: 'Home',
                                url: '/assistantadmin/'
                            },
                            {
                                label: 'About',
                                subMenu: [
                                    { label: 'Who We Are', url: '/assistantadmin/aboutUs' },
                                    { label: 'Our Vision', url: '/assistantadmin/vision' },
                                    { label: 'Our Logo', url: '/assistantadmin/ourLogo' },
                                    { label: 'Our Mission', url: '/assistantadmin/mission' },
                                    { label: "Present Office Bearers", url: '/assistantadmin/officeBearers' }
                                ]
                            },
                            {
                                label: 'Join',
                                url: '',
                                subMenu: [
                                    { label: 'Membership', url: '/assistantadmin/membership-details' },
                                    // { label: 'Membership Plans', url: '/assistantadmin/viewMembershipPlans' },
                                    // { label: 'Member List', url: '/assistantadmin/allMemberList' },
                                    { label: 'Membership Benefits', url: '/member-benefits' },
                                    // { label: 'Become a member ', url: "/membershipPage" },
                                    { label: 'Our Institutional Members', url: '/membership-institution' },
                                ]
                            },
                            {
                                label: 'Network',
                                subMenu: [
                                    {
                                        label: 'Chapters',
                                        subMenu: [
                                            { label: 'Chapters List', url: '/assistantadmin/view-Chapter' },
                                            { label: "Start a Chapter", url: '/assistantadmin/chapter-starting' }
                                        ]
                                    },
                                    {
                                        label: 'SIGs',
                                        url: '/eltai-sig',
                                        subMenu: [
                                            { label: 'Literature SIG' },
                                            { label: 'Computer Technology SIG' },
                                            { label: 'Business English SIG' },
                                            { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                            { label: 'SIG List', url: '/assistantadmin/addEditSigGroups' },
                                        ]
                                    },
                                ]
                            },
                            {
                                label: 'Publish',
                                url: '',
                                subMenu: [
                                    { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                    { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                    { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                    { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                    { label: "Our Newsletter" },

                                ]
                            },
                            {
                                label: 'India Reads',
                                subMenu: [
                                    { label: 'Why India Reads ', url: '/India-Reads' },
                                    { label: 'Our Updates', url: '/news-home' },
                                    { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                ]
                            },
                            {
                                label: 'Partner',
                                subMenu: [
                                    { label: 'Partner with Us  ' },
                                    { label: 'Our Partners', url: '/partner' },
                                    { label: 'Partners List', url: '/assistantadmin/partner-table' },
                                ]
                            },

                            {
                                label: 'Contact Us',
                                url: '/assistantadmin/contact-us'
                            },
                            {
                                label: 'More',
                                subMenu: [
                                    { label: 'Consult', url: '' },
                                    { label: 'Awards', url: '' },
                                    {
                                        label: 'Participate ', subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            // { label: 'Annual Conference List', url: '/assistantadmin/annualConferenceList' },
                                        ]
                                    },
                                    {
                                        label: 'Archives ', subMenu: [
                                            { label: 'Reports   ' },
                                            { label: 'Galleries ', url: '/view-Gallery', },
                                        ]
                                    },
                                    { label: 'Category ', url: '/assistantadmin/viewCategory' },
                                    // { label: 'Testimonials', url: '/assistantadmin/viewTestimonials' },
                                    { label: 'Our Updates', url: '/assistantadmin/journalTable' },
                                    // { label: 'Newsletter', url: '/assistantadmin/viewNewsLetter' },
                                    { label: 'Admin Approval', url: '/assistantadmin/approval' }
                                ]
                            },
                        ]
                    ) :
                    memberScope === 'PORTAL_MEMBER' ?
                        (!navMoreShow) ?
                            (
                                [
                                    {
                                        label: 'My Dashboard',
                                        url: '/member/viewMember'
                                    },
                                    {
                                        label: 'Home',
                                        url: '/'
                                    },
                                    {
                                        label: 'About',
                                        // url: '/aboutUs',
                                        subMenu: [
                                            { label: 'Who We Are', url: '/aboutUs' },
                                            { label: 'Our Vision', url: '/vision' },
                                            { label: 'Our Mission', url: '/mission' },
                                            { label: 'Our Logo', url: '/ourLogo' },
                                            { label: 'Our History', url: '/ourHistory' },
                                            { label: 'Our Architects', url: '/ourArchitects' },
                                            // { label: 'Founder' },
                                            { label: 'Our Constitution', url: constitutionpdf },
                                            { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                            // { label: 'Endowments' },
                                            // { label: "Present Office Bearers", url: '/officeBearers' },
                                            // { label: "Committees" },
                                        ]
                                    },
                                    {
                                        label: 'Join',
                                        // url: '/join-eltai',
                                        subMenu: [
                                            { label: 'Membership', url: '/membership-details' },
                                            { label: 'Membership Benefits', url: '/member-benefits' },
                                            // { label: 'Become a member ', url: "/membershipPage" },
                                            { label: 'Our Institutional Members', url: '/membership-institution' },
                                            { label: 'Membership Plans', url: '/member/join-eltai' },
                                        ]
                                    },
                                    {
                                        label: 'Network',
                                        subMenu: [
                                            {
                                                label: 'Chapters',
                                                subMenu: [
                                                    { label: 'Chapters List', url: '/member/view-Chapter' },
                                                    { label: "Start a Chapter", url: '/member/chapter-starting' }
                                                ]
                                            },
                                            {
                                                label: 'SIGs',
                                                url: '/eltai-sig',
                                                subMenu: [
                                                    // { label: 'Sig List', url: '/addEditSigGroups' },
                                                    { label: 'Literature SIG' },
                                                    { label: 'Computer Technology SIG' },
                                                    { label: 'Business English SIG' },
                                                    // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                                    { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                                    { label: 'SIG List', url: '/member/addEditSigGroups' },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label: 'Publish',
                                        url: '',
                                        subMenu: [
                                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                            { label: "Our Newsletter" },

                                        ]
                                    },
                                    {
                                        label: 'India Reads',
                                        subMenu: [
                                            { label: 'Why India Reads ', url: '/India-Reads' },
                                            { label: 'Our Updates', url: '/news-home' },
                                            // { label: 'News', url: '/news' },
                                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                        ]
                                    },
                                    {
                                        label: 'Partner',
                                        subMenu: [
                                            { label: 'Partner with Us  ' },
                                            { label: 'Our Partners', url: '/partner' },
                                        ]
                                    },
                                    {
                                        label: 'Consult',
                                        url: ''
                                    },
                                    {
                                        label: 'Awards',
                                        url: ''
                                    },
                                    {
                                        label: 'Participate ',
                                        subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            // {
                                            //     label: 'Our Upcoming Events',
                                            //     url: '/member/eventFeed'
                                            // }
                                            // { label: 'Calendar of Events', url: '/calendar-of-events' },
                                        ]
                                    },
                                    {
                                        label: 'Archives ',
                                        subMenu: [
                                            { label: 'Reports   ' },
                                            { label: 'Galleries ', url: '/view-Gallery', },
                                        ]
                                    },
                                    {
                                        label: 'Contact Us', url: '/contact-us'
                                    }
                                ]
                            ) : (
                                [
                                    {
                                        label: 'My Dashboard',
                                        url: '/member/viewMember'
                                    },
                                    {
                                        label: 'Home',
                                        url: '/'
                                    },
                                    {
                                        label: 'About',
                                        // url: '/aboutUs',
                                        subMenu: [
                                            { label: 'Who We Are ', url: '/aboutUs' },
                                            { label: 'Our Vision', url: '/vision' },
                                            { label: 'Our Mission', url: '/mission' },
                                            { label: 'Our Logo', url: '/ourLogo' },
                                            { label: 'Our History', url: '/ourHistory' },
                                            { label: 'Our Architects', url: '/ourArchitects' },
                                            // { label: 'Founder' },
                                            { label: 'Our Constitution', url: constitutionpdf },
                                            { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                            // { label: 'Endowments' },
                                            // { label: "Present Office Bearers", url: '/officeBearers' },
                                            // { label: "Committees" },
                                        ]
                                    },
                                    {
                                        label: 'Join',
                                        // url: '/join-eltai',
                                        subMenu: [
                                            { label: 'Membership', url: '/membership-details' },
                                            { label: 'Membership Benefits', url: '/member-benefits' },
                                            // { label: 'Become a member ', url: '/membershipPage' },
                                            { label: 'Our Institutional Members', url: '/membership-institution' },
                                            { label: 'Membership Plans', url: '/member/join-eltai' },

                                        ]
                                    },
                                    {
                                        label: 'Network',
                                        subMenu: [
                                            {
                                                label: 'Chapters',
                                                subMenu: [
                                                    { label: 'Chapters List', url: '/member/view-Chapter' },
                                                    { label: "Start a Chapter", url: '/member/chapter-starting' }
                                                ]
                                            },
                                            {
                                                label: 'SIGs',
                                                url: '/eltai-sig',
                                                subMenu: [
                                                    // { label: 'Sig List', url: '/addEditSigGroups' },
                                                    { label: 'Literature SIG' },
                                                    { label: 'Computer Technology SIG' },
                                                    { label: 'Business English SIG' },
                                                    // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                                    { label: 'Starting a SIG with ELTAI ', url: 'starting-sig' },
                                                    { label: 'SIG List', url: '/member/addEditSigGroups' },
                                                ]
                                            },
                                        ]
                                    },
                                    // {
                                    //     label: 'Chapters',
                                    //     url: '/chapters',
                                    //     subMenu: [
                                    //         { label: 'Chapters List', url: '/view-Chapter' },
                                    //         { label: "Starting a Chapter", url: '/starting-chapter' },

                                    //     ]
                                    // },
                                    {
                                        label: 'Publish',
                                        url: '',
                                        subMenu: [
                                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                            { label: "Our Newsletter" },

                                        ]
                                    },
                                    // {
                                    //     label: 'SIGs',
                                    //     subMenu: [
                                    //         { label: 'Sig List', url: '/addEditSigGroups' },
                                    //         { label: 'Literature SIG' },
                                    //         { label: 'Computer Technology SIG' },
                                    //         { label: 'Business English SIG' },
                                    //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                                    //     ]
                                    // },
                                    {
                                        label: 'India Reads',
                                        subMenu: [
                                            { label: 'Why India Reads ', url: '/India-Reads' },
                                            { label: 'Our Updates', url: '/news-home' },
                                            // { label: 'News', url: '/news' },
                                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                        ]
                                    },
                                    {
                                        label: 'Partner',
                                        subMenu: [
                                            { label: 'Partner with Us ' },
                                            { label: 'Our Partners', url: '/partner' },
                                        ]
                                    },
                                    {
                                        label: 'Contact Us', url: '/contact-us'
                                    },
                                    {
                                        label: 'More',
                                        subMenu: [
                                            {
                                                label: 'Consult',
                                                url: ''
                                            },
                                            {
                                                label: 'Awards',
                                                url: ''
                                            },
                                            {
                                                label: 'Participate ',
                                                subMenu: [
                                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                                    { label: 'Our Webinars ' },
                                                    // {
                                                    //     label: 'Our Upcoming Events',
                                                    //     url: '/eventFeed'
                                                    // }
                                                    // { label: 'Calendar of Events', url: '/calendar-of-events' },
                                                ]
                                            },
                                            {
                                                label: 'Archives ',
                                                subMenu: [
                                                    { label: 'Reports   ' },
                                                    { label: 'Galleries ', url: '/view-Gallery', },
                                                ]
                                            }
                                        ]
                                    },
                                    // {
                                    //     label: 'Consult',
                                    //     url: ''
                                    // },
                                    // {
                                    //     label: 'Awards',
                                    //     url: ''
                                    // },
                                    // {
                                    //     label: 'Participate ',
                                    //     subMenu: [
                                    //         { label: 'Our Annual Conference',url:'annual-conference' },
                                    //         { label: 'Our Webinars ' },
                                    //         {label:'Our Upcoming Events'},
                                    //         {label:'calendar'},
                                    //     ]
                                    // },
                                    // {
                                    //     label: 'Archives ',
                                    //     subMenu: [
                                    //         { label: 'Reports   ' },
                                    //         { label: 'Galleries ' },
                                    //     ]
                                    // },
                                    // {
                                    //     label: 'Contact Us',url:'/contact-us' 
                                    // }
                                ]

                            )
                        :

                        (!navMoreShow) ?
                            (
                                [
                                    {
                                        label: 'Home',
                                        url: '/'
                                    },
                                    {
                                        label: 'About',
                                        // url: '/aboutUs',
                                        subMenu: [
                                            { label: 'Who We Are ', url: '/aboutUs' },
                                            { label: 'Our Vision', url: '/vision' },
                                            { label: 'Our Mission', url: '/mission' },
                                            { label: 'Our Logo', url: '/ourLogo' },
                                            { label: 'Our History', url: '/ourHistory' },
                                            { label: 'Our Architects', url: '/ourArchitects' },
                                            // { label: 'Founder' },
                                            { label: 'Our Constitution', url: constitutionpdf },
                                            { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                            // { label: 'Endowments' },
                                            // { label: "Present Office Bearers", url: '/officeBearers' },
                                            // { label: "Committees" },
                                        ]
                                    },
                                    {
                                        label: 'Join',
                                        // url: '/join-eltai',
                                        subMenu: [
                                            { label: 'Membership', url: '/membership-details' },
                                            { label: 'Membership Benefits', url: '/member-benefits' },
                                            // { label: 'Become a member ', url: '/membershipPage' },
                                            { label: 'Our Institutional Members', url: '/membership-institution' }

                                        ]
                                    },
                                    {
                                        label: 'Network',
                                        subMenu: [
                                            {
                                                label: 'Chapters',
                                                subMenu: [
                                                    { label: 'Find a Chapter ', url: "/view-Chapter" },
                                                    { label: "Starting Chapter", url: "/chapter-starting" }
                                                ]
                                            },
                                            {
                                                label: 'SIGs',
                                                url: '/eltai-sig',
                                                subMenu: [
                                                    // { label: 'Sig List', url: '/addEditSigGroups' },
                                                    { label: 'Literature SIG' },
                                                    { label: 'Computer Technology SIG' },
                                                    { label: 'Business English SIG' },
                                                    // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                                    { label: 'Starting a SIG with ELTAI ', url: '/starting-sig' },
                                                ]
                                            },
                                        ]
                                    },
                                    // {
                                    //     label: 'Chapters',
                                    //     url: '/chapters',
                                    //     subMenu: [
                                    //         { label: 'Chapters List', url: '/view-Chapter' },
                                    //         { label: "Starting a Chapter", url: '/starting-chapter' },

                                    //     ]
                                    // },
                                    {
                                        label: 'Publish',
                                        url: '',
                                        subMenu: [
                                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                            { label: "Our Newsletter" },

                                        ]
                                    },
                                    // {
                                    //     label: 'SIGs',
                                    //     subMenu: [
                                    //         { label: 'Sig List', url: '/addEditSigGroups' },
                                    //         { label: 'Literature SIG' },
                                    //         { label: 'Computer Technology SIG' },
                                    //         { label: 'Business English SIG' },
                                    //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                                    //     ]
                                    // },
                                    {
                                        label: 'India Reads',
                                        subMenu: [
                                            { label: 'Why India Reads ', url: '/India-Reads' },
                                            { label: 'Our Updates', url: '/news-home' },
                                            // { label: 'News', url: '/news' },
                                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                        ]
                                    },
                                    {
                                        label: 'Partner',
                                        subMenu: [
                                            { label: 'Partner with Us  ' },
                                            { label: 'Our Partners', url: '/partner' },
                                        ]
                                    },
                                    {
                                        label: 'Consult',
                                        url: ''
                                    },
                                    {
                                        label: 'Awards ',
                                        url: ''
                                    },
                                    {
                                        label: 'Participate ',
                                        subMenu: [
                                            { label: 'Our Annual Conference', url: 'annual-conference' },
                                            { label: 'Our Webinars ' },
                                            { label: 'Our Upcoming Events', url: '/eventFeed' }
                                            // { label: 'Calendar of Events', url: '/calendar-of-events' },
                                        ]
                                    },
                                    {
                                        label: 'Archives ',
                                        subMenu: [
                                            { label: 'Reports   ' },
                                            { label: 'Galleries ', url: '/view-Gallery', },
                                        ]
                                    },
                                    {
                                        label: 'Contact Us', url: '/contact-us'
                                    }
                                ]
                            ) : (
                                [
                                    {
                                        label: 'Home',
                                        url: '/'
                                    },
                                    {
                                        label: 'About',
                                        // url: '/aboutUs',
                                        subMenu: [
                                            { label: 'Who We Are ', url: '/aboutUs' },
                                            { label: 'Our Vision', url: '/vision' },
                                            { label: 'Our Mission', url: '/mission' },
                                            { label: 'Our Logo', url: '/ourLogo' },
                                            { label: 'Our History', url: '/ourHistory' },
                                            { label: 'Our Architects', url: '/ourArchitects' },
                                            // { label: 'Founder' },
                                            { label: 'Our Constitution', url: constitutionpdf },
                                            { label: 'Our Executive Committee', url: '/ourExecutiveCommittee' },
                                            // { label: 'Endowments' },
                                            // { label: "Present Office Bearers", url: '/officeBearers' },
                                            // { label: "Committees" },
                                        ]
                                    },
                                    {
                                        label: 'Join',
                                        // url: '/join-eltai',
                                        subMenu: [
                                            { label: 'Membership', url: '/membership-details' },
                                            { label: 'Membership Benefits', url: '/member-benefits' },
                                            { label: 'Become a member ', url: '/membershipPage' },
                                            { label: 'Our Institutional Members', url: '/membership-institution' }
                                        ]
                                    },
                                    {
                                        label: 'Network',
                                        subMenu: [
                                            {
                                                label: 'Chapters',
                                                subMenu: [
                                                    { label: 'Find a Chapter ', url: "/view-Chapter" },
                                                    { label: "Starting Chapter", url: "/chapter-starting" }
                                                ]
                                            },
                                            {
                                                label: 'SIGs',
                                                url: '/eltai-sig',
                                                subMenu: [
                                                    // { label: 'Sig List', url: '/addEditSigGroups' },
                                                    { label: 'Literature SIG' },
                                                    { label: 'Computer Technology SIG' },
                                                    { label: 'Business English SIG' },
                                                    // { label: 'SIGs with ELTAI ', url: '/eltai-sig' },
                                                    { label: 'Starting a SIG with ELTAI ', url: '/starting-sig' },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label: 'Publish',
                                        url: '',
                                        subMenu: [
                                            { label: "Journal of English Language Teaching (JELT)", url: 'https://journals.eltai.in/index.php/jelt/index' },
                                            { label: "Journal of Teaching & Research in English Literature (JTREL)", url: 'https://journals.eltai.in/index.php/jtrel' },
                                            { label: "Journal of Technology for ELT (JTELT)", url: 'https://journals.eltai.in/index.php/jtelt' },
                                            { label: "ELT Practitioner", url: 'https://sites.google.com/view/theeltpractitioner/' },
                                            { label: "Our Newsletter" },

                                        ]
                                    },
                                    // {
                                    //     label: 'SIGs',
                                    //     subMenu: [
                                    //         { label: 'Sig List', url: '/addEditSigGroups' },
                                    //         { label: 'Literature SIG' },
                                    //         { label: 'Computer Technology SIG' },
                                    //         { label: 'Business English SIG' },
                                    //         { label: 'Starting a Chapter with ELTAI ',url:'/eltai-sig' },
                                    //     ]
                                    // },
                                    {
                                        label: 'India Reads',
                                        subMenu: [
                                            { label: 'Why India Reads ', url: '/India-Reads' },
                                            { label: 'Our Updates', url: '/news-home' },
                                            // { label: 'News', url: '/news' },
                                            { label: 'LMS', url: 'https://indiareads.eltai.in/' },
                                        ]
                                    },
                                    {
                                        label: 'Partner',
                                        subMenu: [
                                            { label: 'Partner with Us  ' },
                                            { label: 'Our Partners', url: '/partner' },
                                        ]
                                    },
                                    {
                                        label: 'Contact Us', url: '/contact-us'
                                    },
                                    {
                                        label: 'More',
                                        subMenu: [
                                            {
                                                label: 'Consult',
                                                url: ''
                                            },
                                            {
                                                label: 'Awards',
                                                url: ''
                                            },
                                            {
                                                label: 'Participate ',
                                                subMenu: [
                                                    { label: 'Our Annual Conference', url: 'annual-conference' },
                                                    { label: 'Our Webinars ' },
                                                    { label: 'Our Upcoming Events', url: '/eventFeed' }
                                                    // { label: 'Calendar of Events', url: '/calendar-of-events' },
                                                ]
                                            },
                                            {
                                                label: 'Archives ',
                                                subMenu: [
                                                    { label: 'Reports   ' },
                                                    { label: 'Galleries ', url: '/view-Gallery', },
                                                ]
                                            }
                                        ]
                                    },
                                    // {
                                    //     label: 'Consult',
                                    //     url: ''
                                    // },
                                    // {
                                    //     label: 'Awards',
                                    //     url: ''
                                    // },
                                    // {
                                    //     label: 'Participate ',
                                    //     subMenu: [
                                    //         { label: 'Our Annual Conference',url:'annual-conference' },
                                    //         { label: 'Our Webinars ' },
                                    //         {label:'Our Upcoming Events'},
                                    //         {label:'calendar'},
                                    //     ]
                                    // },
                                    // {
                                    //     label: 'Archives ',
                                    //     subMenu: [
                                    //         { label: 'Reports   ' },
                                    //         { label: 'Galleries ' },
                                    //     ]
                                    // },
                                    // {
                                    //     label: 'Contact Us',url:'/contact-us' 
                                    // }
                                ]

                            )

    //Update BackGrond Image
    useEffect(() => {
        const updateBackgroundImage = () => {
            const currentUrl = window.location.pathname;
            // Logic to set background image based on current URL
            let newBackgroundImage = '';
            let url;
            if (process.env.REACT_APP_PUBLIC_URL) {
                if (process.env.REACT_APP_PUBLIC_URL !== '' || process.env.REACT_APP_PUBLIC_URL !== undefined) {
                    if (memberScope === 'PORTAL_SUPER_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'admin')[1];
                    }
                    else if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'chapterAdmin')[1];
                    }
                    else if (memberScope === 'PORTAL_SIG_ADMIN') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'sigAdmin')[1];
                    }
                    else if (memberScope === 'PORTAL_MEMBER') {
                        url = currentUrl.split(process.env.REACT_APP_PUBLIC_URL + 'member')[1];
                    }
                    else {
                        url = '/';
                    }
                }
                else {
                    url = currentUrl
                }
            }
            else {
                if (memberScope === 'PORTAL_CHAPTER_ADMIN') {
                    url = currentUrl.split('chapterAdmin')[1];
                }
                else if (memberScope === 'PORTAL_SIG_ADMIN') {
                    url = currentUrl.split('sigAdmin')[1];
                }
                else if (memberScope === 'PORTAL_MEMBER') {
                    url = currentUrl.split('member')[1];
                }
                else {
                    url = currentUrl
                }
            }

            // Example: Set different background images for different URLs
            switch (url) {
                case '/':
                    newBackgroundImage = `url(${banner1})`;
                    break;
                case '/aboutUs':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/officeBearers':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/chapterForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/view-Event':
                    newBackgroundImage = `url(${banner5})`;
                    break;
                case '/chapterJoinMember':
                    newBackgroundImage = `url(${banner5})`;
                    break;
                case '/eventForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/memberList':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/eventType':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sigGroup':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/category':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewCategory':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/gallery-table':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/add-edit-Gallery':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/announcement':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/allMemberList':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/eventList':
                    newBackgroundImage = `url(${banner1})`;
                    break;
                case '/eventFeed':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/memberForm':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/forumTable':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/forumForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewForum':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sigGroupForm':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewSig':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addEditSigGroups':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewJob':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addJob':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewMember':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/viewNewsLetter':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/addNewsLetter':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/resume-table':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/addResume':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/chapterDetail':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/sigDetail':
                    newBackgroundImage = `url(${banner2})`;
                    break;
                case '/approval':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/viewGallery':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/sig-joined-Member':
                    newBackgroundImage = `url(${banner3})`;
                    break;
                case '/profile':
                    newBackgroundImage = `url(${banner3})`;
                    break;

                // Add more cases as needed
                default:
                    // Add a case for dynamic chapterForm/:chapterId route
                    if (currentUrl.includes('/chapterForm/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewJournal/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/memberList/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/category/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/evenTable/') || currentUrl.includes('/admin/') || currentUrl.includes('/eventForm/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sig-member-table/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/gallery-table/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditTestimonials/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewRegistered/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/journalTable')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditJournal')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addResume/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addNewsLetter/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/readMoreForum/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sigAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/admin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sigAdmin/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/VieweventFeed/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/add-edit-Gallery/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/view-Chapter/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/addEditMembershipPlan/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/viewGallery/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/sig-joined-Member/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/profile/')) {
                        newBackgroundImage = `url(${banner3})`;
                    }
                    if (currentUrl.includes('/chapterJoinMember/')) {
                        newBackgroundImage = `url(${banner5})`;
                    }
                    break;
            }

            setBackgroundImage(newBackgroundImage);
        };
        updateBackgroundImage();
    }, [memberScope]);

    // Render SubMenu Items
    const renderSubMenuItems = (subMenuItems) => {
        return subMenuItems?.map((subMenuItem, index) => {
            if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
                return (
                    <NavDropdown
                        title={
                            <span
                                onClick={(e) => {
                                    if (subMenuItem.url) {
                                        e.preventDefault(); // Prevent dropdown toggle
                                        navigate(subMenuItem.url); // Navigate to the URL
                                    }
                                }}
                                className=" nestedurl"
                            >
                                {subMenuItem.label}
                            </span>
                        }
                        id={`submenu-${index}`}
                        key={subMenuItem.label || index}
                        style={{ textTransform: 'none !important', fontWeight: '600', fontFamily: 'poppins' }}
                        onMouseEnter={() => handleSubMouseEnter(index)}
                        onMouseLeave={handleSubMouseLeave}
                        show={subDropdownHover === index} // Show nested submenu when hovered
                        onClick={() => handleSubMobileClick(index)}

                        className={`custom-nav-dropdown-item `}
                    >
                        {renderSubMenuItems(subMenuItem.subMenu)} {/* Recursive call for nested submenus */}
                    </NavDropdown>
                );
            } else {
                return (
                    <NavDropdown.Item
                        onClick={() => {
                            if (subMenuItem.url) {
                                if (subMenuItem.url.startsWith('http')) {
                                    // Open external links in a new tab
                                    window.open(subMenuItem.url, '_blank');
                                } else if (subMenuItem.url.endsWith('.pdf')) {
                                    // Open PDF in a new tab
                                    window.open(subMenuItem.url, '_blank');
                                } else {
                                    // Navigate within the app for internal URLs
                                    navigate(subMenuItem.url);
                                }
                            } else {
                                console.warn(`No URL defined for submenu item: ${subMenuItem.label}`);
                            }

                            // Close all dropdowns
                            setDropdownHover(null);
                            setSubDropdownHover(null);
                            setExpanded(false);
                        }}
                        key={subMenuItem.label || index}
                        style={{ textTransform: 'none', fontFamily: 'Poppins' }}
                        className={currentLocation.pathname === subMenuItem.url ? 'active' : ''}
                    >
                        {subMenuItem.label}
                    </NavDropdown.Item>
                );
            }
        });
    };

    return (
        <>
            <style>{`
            .banner_text {
                background-image: ${backgroundImage};
                background-size: cover;
                background-position: center;
                padding: 0px;
            }
            .navbar-dark .navbar-nav .nav-item .dropdown-menu .nav-link {
                color: black ;
            }
            .active-menu {
                color: #ff0400; 
            }
        `}</style>

            <div className='top-nav header-containter d-flex justify-content-between topSection' >
                {/* <div className='col-1'></div> */}
                <div className='d-flex align-items-center' >
                    <img src={logo} style={{ height: '40px' }} alt='' />
                </div>
                <div className={`d-flex justify-content-end align-items-center ${memberScope?'me-3 me-md-4 pe-3':''}`}>
                    <div className="social-menu d-flex align-items-center me-4 mt-1">
                        <ul>
                            <li><a className='nav ps-0' href="https://www.facebook.com/ELTAI.India" target="blank"><i className="fa fa-facebook icon-config"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.instagram.com/eltai.india" target="blank"><i className="fa fa-instagram icon-config-insta"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.youtube.com/@ELTAIIndia" target="blank"><i className="fa fa-youtube icon-config-utube"></i></a></li>
                            <li className="twitter-list"><a className='nav ps-0' href="https://x.com/EltaiIndia" target="blank"><XIcon className="social-media-footer-icon-header" /></a></li>
                        </ul>
                    </div>
                    <div style={{ zIndex: '1300' }}>
                        {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_MEMBER' || memberScope === 'PORTAL_ADMIN' ? (
                            <React.Fragment>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', position: 'absolute', top: topBarButtons }} className="profileAvatar">
                                    <Tooltip title="Profile">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            className='menu-btn header-menu-btn'
                                        >
                                            <AvatarComponent
                                                memberScope={memberScope}
                                                memberDetails={memberDetails}
                                                chapterImageUrl={chapterImageUrl}
                                                sigImageUrl={sigImageUrl}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'primary.main',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {memberScope === 'PORTAL_MEMBER' && (
                                        <MenuItem className='px-3 py-2' onClick={() => membershipRouteUrl(memberDetails?._id)}>
                                            <div className='text-center d-flex'>
                                                <ListItemIcon>
                                                    <AccountCircleIcon fontSize="small" />
                                                </ListItemIcon>
                                                Profile
                                            </div>
                                        </MenuItem>
                                    )}
                                    {memberScope === 'PORTAL_CHAPTER_ADMIN' && (
                                        <MenuItem className='px-3 py-2' onClick={() => navigate(`chapterAdmin/chapterProfile/${chapterDetail?._id}`)}>
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            Profile
                                        </MenuItem>
                                    )}
                                    {memberScope === 'PORTAL_SIG_ADMIN' && (
                                        <MenuItem className='px-3 py-2' onClick={() => navigate(`sigAdmin/sigadminProfile/${sigdetail?._id}`)}>
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            Profile
                                        </MenuItem>
                                    )}

                                    <Divider />

                                    {memberScope === 'PORTAL_MEMBER' ? (
                                        <MenuItem onClick={routeToPortal} className='text-start px-3 py-2'>
                                            {memberDetails?.type === "0" || memberDetails?.type === "4" || memberDetails?.type === "1"
                                                ? <div className='text-center d-flex'>
                                                    <ListItemIcon>
                                                        <PersonIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {memberDetails?.fullName}
                                                </div>

                                                : memberDetails?.type === "2"
                                                    ? <>
                                                        <ListItemIcon>
                                                            <PersonIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        {memberDetails?.institution}
                                                    </>
                                                    : <div>
                                                        <ListItemIcon>
                                                            <PersonIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        {memberDetails?.organisationName}
                                                    </div>
                                            }
                                            {memberDetails?.type === "0" || memberDetails?.type === "4" || memberDetails?.type === "1"
                                                ? <>
                                                    <p className='m-0'>&nbsp;(INDIVIDUAL)</p>
                                                </>
                                                : memberDetails?.type === "1"
                                                    ? <>
                                                        <p className='m-0'>&nbsp;(INDIVIDUAL)</p>
                                                    </>
                                                    : memberDetails?.type === "2"
                                                        ? <>
                                                            <p className='m-0'>&nbsp;(INSTITUTION)</p>
                                                        </>
                                                        : <>
                                                            <p className='m-0'>&nbsp;(INSTITUTION)</p>
                                                        </>
                                            }
                                        </MenuItem>

                                    ) : memberScope === 'PORTAL_CHAPTER_ADMIN' ? (<MenuItem onClick={routeToPortal} className='text-start px-3 py-2'>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        {roleDetails && roleDetails.name}  ({role})
                                    </MenuItem>) : memberScope === 'PORTAL_SIG_ADMIN' ? (
                                        <MenuItem className='px-3 py-2' onClick={routeToPortal}>
                                            <ListItemIcon>
                                                <PersonIcon fontSize="small" />
                                            </ListItemIcon>
                                            {sigRoleDetails && sigRoleDetails.name} ({role})
                                        </MenuItem>) : memberScope === 'PORTAL_ADMIN' ? (
                                            <MenuItem className='px-3 py-2' onClick={routeToPortal}>
                                                <ListItemIcon>
                                                    <PersonIcon fontSize="small" />
                                                </ListItemIcon>
                                                {role}
                                            </MenuItem>) : (<MenuItem className='px-3 py-2' onClick={routeToPortal} style={{ cursor: cursorStyle }}>
                                                <div className=' d-flex justify-content-start w-100' style={{ cursor: cursorStyle, pointerEvents: role === "Admin" ? "none" : "auto" }}
                                                    disabled={role === "Admin"}>
                                                    <ListItemIcon>
                                                        <PersonIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {role}</div>
                                            </MenuItem>)}
                                    <Divider />

                                    <MenuItem className='px-3 py-2' onClick={handleOpens} style={{ cursor: 'pointer' }}>
                                        <ListItemIcon>
                                            <SyncLockIcon fontSize="small" />
                                        </ListItemIcon>
                                        Change Password
                                    </MenuItem><hr style={{ color: '#dad2d2de', margin: '0px' }} />
                                    <MenuItem className='px-3 py-2' onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                        ) : (

                            <Box className="btn_container d-flex  pb-1"  >
                                <Link to='/login' className='text-nowrap ps-0 me-2' ><Button className='top_nav_btn oswald text-capitalize'>Login</Button></Link>
                                <Link to="/membershipPage" ><Button className='top_nav_btn_join oswald text-capitalize'>Join</Button></Link>
                            </Box>
                        )}
                    </div>
                </div>
                <Modal
                    open={opens}
                    onClose={handleCloses}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" className='text-center' variant="h6" component="h2" style={{ color: '#103d23' }}>
                            Change Password
                        </Typography>
                        <form noValidate className='mt-5' onSubmit={handleSubmit(onChangePassword)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {/* <div className='mb-5'>
                                        <Controller
                                            control={control}
                                            name="oldPassword"
                                            render={({ field, formState }) => (
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    InputLabelProps={{ shrink: true }}
                                                    label={
                                                        <Typography className="text-dark">
                                                            Old Password <span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    id="oldPassword"
                                                    value={field.value}
                                                    error={!!formState?.errors?.oldPassword}
                                                    helperText={formState?.errors?.oldPassword?.message}
                                                    placeholder="Enter a old password"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <Controller
                                            control={control}
                                            name="newPassword"
                                            render={({ field, formState }) => (
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    InputLabelProps={{ shrink: true }}
                                                    label={
                                                        <Typography className="text-dark">
                                                            New Password <span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    id="newPassword"
                                                    value={field.value}
                                                    error={!!formState?.errors?.newPassword}
                                                    helperText={formState?.errors?.newPassword?.message}
                                                    placeholder="Enter a new password"
                                                    {...field}
                                                />
                                            )}
                                            
                                        />
                                    </div> */}
                                    <div className="mb-5">
                                        <Controller
                                            control={control}
                                            name="oldPassword"
                                            rules={{ required: "Old password is required." }}
                                            render={({ field }) => (
                                                <TextField
                                                    type={showPassword?.confirmPassword ? 'text' : 'password'}
                                                    fullWidth
                                                    // type="password"
                                                    autoComplete="off"
                                                    InputLabelProps={{ shrink: true }}

                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => handleClickShowPassword('confirmPassword')}
                                                                    edge="end"
                                                                >
                                                                    {showPassword?.confirmPassword ? <VisibilityOffIcon sx={{ color: '#611f1e !important' }} /> : <VisibilityIcon sx={{ color: '#611f1e !important' }} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    label={
                                                        <Typography className="text-dark">
                                                            Old Password <span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    id="oldPassword"
                                                    value={field.value}
                                                    error={!!errors.oldPassword}
                                                    helperText={errors.oldPassword?.message}
                                                    placeholder="Enter your old password"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <Controller
                                            control={control}
                                            name="newPassword"
                                            rules={{
                                                required: "New password is required.",
                                                validate: (value) =>
                                                    value !== oldPassword || "New password cannot be the same as the old password.",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    type={showPassword?.newPassword ? 'text' : 'password'}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => handleClickShowPassword('newPassword')}
                                                                    edge="end"
                                                                >
                                                                    {showPassword?.newPassword ? <VisibilityOffIcon sx={{ color: '#611f1e !important' }} /> : <VisibilityIcon sx={{ color: '#611f1e !important' }} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    label={
                                                        <Typography className="text-dark">
                                                            New Password <span style={{ color: "red" }}>*</span>
                                                        </Typography>
                                                    }
                                                    id="newPassword"
                                                    value={field.value}
                                                    error={!!errors.newPassword}
                                                    helperText={errors.newPassword?.message}
                                                    placeholder="Enter your new password"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', fontFamily: "Poppins" }}>
                                <Button variant="outlined" color="error" onClick={handleCloses} style={{ marginRight: '10px' }}>Cancel</Button>
                                <Button variant='contained' type="submit" style={{ color: 'white', textTransform: "uppercase" }} className="menus-color"> Submit</Button>
                            </Box>
                        </form>
                    </Box>
                </Modal>

            </div>
            {/* <div className='row top-nav header-containter' >
                <div className='col-1'></div>
                <div className='col-5 d-flex align-items-center' >
                    <img src={logo} style={{ height: '40px' }} alt='' />
                </div>
                <div className='col-5 d-flex justify-content-end align-items-center'>
                    <div className="social-menu d-flex align-items-center mt-1">
                        <ul>
                            <li><a className='nav ps-0' href="https://www.facebook.com/ELTAI.India" target="blank"><i className="fa fa-facebook icon-config"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.instagram.com/eltai.india" target="blank"><i className="fa fa-instagram icon-config-insta"></i></a></li>
                            <li><a className='nav ps-0' href="https://www.youtube.com/@ELTAIIndia" target="blank"><i className="fa fa-youtube icon-config-utube"></i></a></li>
                            <li className="twitter-list"><a className='nav ps-0' href="https://x.com/EltaiIndia" target="blank"><XIcon className="social-media-footer-icon-header" /></a></li>
                        </ul>
                    </div>
                    <div style={{ zIndex: '1300' }}>
                        {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_MEMBER' || memberScope === 'PORTAL_ADMIN' ? (
                            <React.Fragment>
                                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', position: 'absolute', top: topBarButtons }}>
                                    <Tooltip title="Profile">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            className='menu-btn header-menu-btn'
                                        >
                                            <Avatar className="" sx={{ width: 32, height: 32, bgcolor: '#f13700 ' }}><PersonIcon className='accountIcon' /></Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'primary.main',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {memberScope === 'PORTAL_MEMBER' ? (
                                        <MenuItem onClick={() => membershipRouteUrl(memberDetails?._id)}>
                                            {memberDetails?.type === "0"
                                                ? <div className='text-center'>
                                                    <ListItemIcon>
                                                        <Avatar src={memberDetails?.imageUrl} />
                                                    </ListItemIcon>
                                                    Profile
                                                </div>
                                                : memberDetails?.type === "1"
                                                    ? <div className='text-center'>
                                                        <ListItemIcon>
                                                            <Avatar src={memberDetails?.imageUrl} />
                                                        </ListItemIcon>
                                                        Profile
                                                    </div>
                                                    : memberDetails?.type === "2"
                                                        ? <>
                                                            <ListItemIcon>
                                                                <Avatar src={memberDetails?.uploadInstitutionLogo} />
                                                            </ListItemIcon>
                                                            Profile
                                                        </>
                                                        : <div>
                                                            <ListItemIcon>
                                                                <Avatar src={memberDetails?.uploadOrganisationLogo} />
                                                            </ListItemIcon>
                                                            Profile
                                                        </div>
                                            }
                                        </MenuItem>
                                    ) : null}
                                    {memberScope === 'PORTAL_CHAPTER_ADMIN' ?
                                        <MenuItem onClick={() => navigate(`chapterAdmin/chapterProfile/${chapterDetail?._id}`)}>
                                            <ListItemIcon>
                                                <Avatar src={chapterImageUrl?.payload?.auth?.imageUrl} />
                                            </ListItemIcon>
                                            Profile
                                        </MenuItem>
                                        : <></>}
                                    {memberScope === 'PORTAL_SIG_ADMIN' ?
                                        <MenuItem onClick={() => navigate(`sigAdmin/sigadminProfile/${sigdetail?._id}`)}>
                                            <ListItemIcon>
                                                <Avatar src={sigImageUrl?.payload?.auth?.imageUrl} />
                                            </ListItemIcon>
                                            Profile
                                        </MenuItem>
                                        : <></>}
                                    <Divider />

                                    {memberScope === 'PORTAL_MEMBER' ? (
                                        <MenuItem onClick={routeToPortal}>
                                            {memberDetails?.type === "0"||memberDetails?.type === "4"
                                                ? <div className='text-center'>
                                                    {memberDetails?.fullName}
                                                </div>
                                                : memberDetails?.type === "1"
                                                    ? <div className='text-center'>
                                                        {memberDetails?.fullName}
                                                    </div>
                                                    : memberDetails?.type === "2"
                                                        ? <>
                                                            {memberDetails?.institution}
                                                        </>
                                                        : <div>
                                                            {memberDetails?.organisationName}
                                                        </div>
                                            }
                                            {memberDetails?.type === "0"||memberDetails?.type === "4"
                                                ? <>
                                                    <p className='m-0'>&nbsp;(INDIVIDUAL)</p>
                                                </>
                                                : memberDetails?.type === "1"
                                                    ? <>
                                                        <p className='m-0'>&nbsp;(INDIVIDUAL)</p>
                                                    </>
                                                    : memberDetails?.type === "2"
                                                        ? <>
                                                            <p className='m-0'>&nbsp;(INSTITUTION)</p>
                                                        </>
                                                        : <>
                                                            <p className='m-0'>&nbsp;(INSTITUTION)</p>
                                                        </>
                                            }
                                        </MenuItem>

                                    ) : memberScope === 'PORTAL_CHAPTER_ADMIN' ? (<MenuItem onClick={routeToPortal}>
                                        {roleDetails && roleDetails.name}  ({role})
                                    </MenuItem>) : memberScope === 'PORTAL_SIG_ADMIN' ? (
                                        <MenuItem onClick={routeToPortal}>
                                            {sigRoleDetails && sigRoleDetails.name} ({role})
                                        </MenuItem>) : memberScope === 'PORTAL_ADMIN' ? (
                                            <MenuItem onClick={routeToPortal}>
                                                {role}
                                            </MenuItem>) : (<MenuItem onClick={routeToPortal} style={{ cursor: cursorStyle }}>
                                                <div className='d-flex justify-content-center w-100' style={{ cursor: cursorStyle, pointerEvents: role === "Admin" ? "none" : "auto" }}
                                                    disabled={role === "Admin"}>{role}</div>
                                            </MenuItem>)}
                                    <Divider />

                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                        ) : (

                            <Box className="btn_container d-flex me-5 pb-1"  >
                                <Link to='/login' className='text-nowrap ps-0 me-2' ><Button className='top_nav_btn oswald text-capitalize'>Login</Button></Link>
                                <a href="/membershipPage" target='blank' ><Button className='top_nav_btn_join oswald text-capitalize'>Join</Button></a>
                            </Box>
                        )}
                    </div>
                </div>

            </div> */}


            {/* <Navbar expand="lg" className={`bg_clr sticky`} variant="dark" expanded={expanded}>
                <Container fluid className="header-container mt-xs-4">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                    <Navbar.Collapse id="responsive-navbar-nav" className={isMobile ? 'mobile-style' : 'desktop-style'}>
                        <Nav className="d-flex justify-content-between mt-xs-4  mx-4 align-items-center">
                            <Nav.Link className='p-0 me-4'>
                            </Nav.Link>

                            {menuItems.map((menuItem, index) => (

                                menuItem.subMenu ? (

                                    <NavDropdown
                                        title={
                                            <span
                                                onClick={(e) => {
                                                    if (menuItem.url) {
                                                        e.preventDefault(); // Prevent dropdown toggle
                                                        navigate(menuItem.url); // Navigate to the URL
                                                        setExpanded(false); // Collapse navbar
                                                    }
                                                }}
                                                className={`menu-label ${menuItem.url ? 'clickable' : ''}`}
                                            >
                                                {menuItem.label}
                                            </span>
                                        }
                                        id={`menu-${index}`}
                                        key={index}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        show={dropdownHover === index}
                                        active={
                                            menuItem?.subMenu?.some(item => item.url === currentLocation.pathname ||
                                                item.subMenu?.some(subItem => subItem.url === (currentLocation.pathname ||
                                                    item.subMenu?.some(subItem => subItem.url === (currentLocation.pathname)))),
                                               
                                            )
                                        }

                                        onClick={() => handleMobileClick(index)} // Handle click for mobile
                                        className={`custom-nav-dropdown eltai-home-nav-bar nav-item ${menuItem.label === "More" ? "dropdown-right drpdown_left" : ""}`}
                                        style={{
                                            borderRight: index !== menuItems.length - 1 ? '1px solid #ccc' : 'none',
                                        }}
                                    >

                                        {renderSubMenuItems(menuItem.subMenu)}
                                    </NavDropdown>
                                ) : (
                                    <Nav.Link
                                        onClick={() => {
                                            navigate(menuItem.url);
                                            setExpanded(false); // Collapse navbar after selecting
                                        }}
                                        key={index}
                                        active={currentLocation.pathname === menuItem?.url}
                                        style={{
                                            fontWeight: '600',
                                            textTransform: 'none',
                                            fontFamily: 'poppins',
                                            borderRight: index !== menuItems.length - 1 ? '1px solid #ccc' : 'none',
                                        }} className={`custom-nav-link nav-item ${menuItem.label === "More" ? "dropdown-right" : ""}`}
                                    >
                                        {menuItem.label}
                                    </Nav.Link>
                                )
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}

            <Navbar expand="lg" className="bg_clr sticky" variant="dark" expanded={expanded}>
                <Container fluid className="header-container mt-xs-4">
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={() => setExpanded(!expanded)}
                    />
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className={isMobile ? 'mobile-style' : 'desktop-style'}
                    >
                        <Nav className="d-flex justify-content-between mt-xs-4 mx-4 align-items-center">
                            <Nav.Link className="p-0 me-4">
                                {/* Placeholder for logo */}
                            </Nav.Link>

                            {menuItems.map((menuItem, index) => (
                                menuItem.subMenu ? (
                                    <NavDropdown
                                        title={
                                            <span
                                                onClick={(e) => {
                                                    if (menuItem.url) {
                                                        e.preventDefault(); // Prevent dropdown toggle
                                                        navigate(menuItem.url); // Navigate to the URL
                                                        setExpanded(false); // Collapse navbar
                                                    }
                                                }}
                                                className={`menu-label ${menuItem.url ? 'clickable' : ''}`}
                                            >
                                                {menuItem.label}
                                            </span>
                                        }
                                        id={`menu-${index}`}
                                        key={index}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        show={dropdownHover === index}
                                        active={
                                            menuItem?.subMenu?.some(
                                                (item) =>
                                                    item.url === currentLocation.pathname ||
                                                    item.subMenu?.some(
                                                        (subItem) => subItem.url === currentLocation.pathname
                                                    )
                                            )
                                        }
                                        onClick={() => handleMobileClick(index)}
                                        className={`custom-nav-dropdown eltai-home-nav-bar nav-item ${menuItem.label === 'More' ? 'dropdown-right drpdown_left' : ''
                                            }`}
                                        // style={{
                                        //     borderRight:
                                        //         index !== menuItems.length - 1 ? '1px solid #ccc' : 'none',
                                        // }}
                                        style={{
                                            borderRight: window.innerWidth >= 992 && index !== menuItems.length - 1 ? '1px solid #ccc' : 'none',
                                        }}
                                    >
                                        {renderSubMenuItems(menuItem.subMenu)}
                                    </NavDropdown>
                                ) : (
                                    <Nav.Link
                                        onClick={() => {
                                            navigate(menuItem.url);
                                            setExpanded(false); // Collapse navbar after selecting
                                        }}
                                        key={index}
                                        active={currentLocation.pathname === menuItem?.url}
                                        style={{
                                            fontWeight: '600',
                                            textTransform: 'none',
                                            fontFamily: 'Poppins',
                                            borderRight: window.innerWidth >= 992 && index !== menuItems.length - 1 ? '1px solid #ccc' : 'none',
                                        }}
                                        className={`custom-nav-link nav-item ${menuItem.label === 'More' ? 'dropdown-right' : ''
                                            }`}
                                    >
                                        {menuItem.label}
                                    </Nav.Link>
                                )
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
