import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import { useFieldArray, useForm } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  useSaveSIGGroupMutation,
  useUpdateSIGGroupMutation,
  useLazyGetSIGGroupByIdQuery,
} from "../../app/services/SIGGroupServices";
import "../../style/css/SIGGroupTable.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { useRemoveAdminSIGMutation } from "../../app/services/SIGServices";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";

const SIGGroupForm = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [saveSIGGroup] = useSaveSIGGroupMutation();
  const [updateSIGGroup] = useUpdateSIGGroupMutation();
  const { sigGroupId } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialData, setInitalData] = useState([]);
  const [removeMemberAdmin] = useRemoveAdminSIGMutation();
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const [isAdmin, setIsAdmin] = useState('')

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      groupName: "",
      description: "",
      removedAdminEmail: [],
      adminsId: [],
      roleDetails: [],
      createdBy: {
        id: "",
        role: "",
      },//required
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roleDetails",
  });

  // Adding a new empty object to roleDetails array when "Add Office Bearers" button is clicked
  const addRoleDetails = () => {
    append({
      name: "",
      address: "",
      mobile: "",
      email: "",
      role: "",
      isAdmin: false,
      password: "",
    });
  };

  // Removing Roledetails for each row except the first one
  const removeRoleDetails = (index) => {
    const isAdmin = getValues(`roleDetails.${index}.isAdmin`)
    const roleDetail = getValues(`roleDetails.${index}`)
    if (isAdmin) {
      removeAdmin(roleDetail, index)
    }
    else {
      remove(index);
    }
  };
  //remove admin
  const removeAdmin = (member, index) => {
    const data = {
      sigGroupId,
      roleId: member._id,
      email: member.email,
    }
    setLoading(true);
    removeMemberAdmin(data).then(res => {
      if (res.data?.data) {
        setLoading(false);
        toast.success(res.data?.message)
        remove(index)
      }
      else {
        setLoading(false);
        toast.error(res.data?.message)
      }
    })
  }

  const [getSigGroupById] = useLazyGetSIGGroupByIdQuery(sigGroupId);

  //getSigGroupById
  useEffect(() => {
    if (sigGroupId) {
      setLoading(true);
      getSigGroupById(sigGroupId).then((res) => {
        if (res.data.status) {
          setLoading(false);
          const sigData = res.data?.data;
          if (sigData) {
            setInitalData(res.data?.data);
            setValue("groupName", sigData.groupName);
            setValue("description", sigData.description);
            if (sigData.roleDetails.length !== 0) {
              setValue("roleDetails", sigData.roleDetails);
            }
          }
        }
      });
    }
  }, [sigGroupId, getSigGroupById, setValue]);

  //saveSig Group
  const onSaveSIGGroup = (formData) => {
    const isDuplicateEmail = formData?.roleDetails.some(
      (detail, index, self) =>
        self.findIndex((t) => t.email === detail.email) !== index
    );
    if (isDuplicateEmail) {
      toast.error("Please enter unique emails");
      return;
    }
    setLoading(true);
    trigger().then((isValid) => {
      if (isValid) {
        setData([...data, formData]);
        formData.createdBy = {
          id: userId,
          role: memberScope,
        };
        if (sigGroupId) {
          const formRoleDetailsIds = formData.roleDetails.map(
            (role) => role._id
          );
          const filteredData = initialData?.roleDetails?.filter((item) => {
            const isIncluded = formRoleDetailsIds.includes(item._id);
            return !isIncluded;
          });
          const isAdminStatus = filteredData.filter(
            (item) => item.isAdmin === true
          );
          if (isAdminStatus) {
            const email = isAdminStatus.map((item) => item.email);
            setValue("removedAdminEmail", email);
            setValue("adminsId", initialData.adminsId);
            formData.removedAdminEmail = watch("removedAdminEmail");
            formData.adminsId = watch("adminsId");
            // formData.roleDetails = formData.roleDetails.map(({ email, ...rest }) => rest)
            let requestIdAndData = { id: sigGroupId, data: formData };
            updateSIGGroup(requestIdAndData).then((res) => {
              if (res.data?.status) {
                setLoading(false);
                toast.success(res.data?.message);
                navigate("/addEditSigGroups");
              } else {
                setLoading(false);
                toast.error(res.data?.err?.message);
              }
            });
          }
        } else {

          saveSIGGroup(formData).then((res) => {
            if (res.data?.status) {
              setLoading(false);
              toast.success(res.data?.message);
              navigate("/addEditSigGroups");
            } else {
              setLoading(false);
              toast.error(res.data?.err?.message);
            }
          });
        }
      }
    });
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">{sigGroupId ? "Edit SIG" : "Add SIG"}</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          className="d-flex flex-column p-md-5 p-3 overallPadding ">
          <div className="text-end w-100">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}>
              Back
            </Button>
          </div>
          <div style={{ marginTop: "25px", marginBottom: "50px" }}>
            <form onSubmit={handleSubmit(onSaveSIGGroup)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "49%" }}
                  id="groupName"
                  label={
                    <Typography className="text-dark">
                      Group Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  error={!!errors.groupName}
                  helperText={errors.groupName?.message}
                  {...register("groupName", {
                    required: "Please enter a group name",
                    minLength:{value:3,
                      message:'"Group name must be minimum 3 characters"'
                    } ,
                    maxLength:{value:30,
                      message:'"Group name must be maximum 30 characters"'
                    } ,
                  })}
                  placeholder="Enter group name"

                />
                <TextField
                multiline
                rows={3}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "49%" }}
                  id="description"
                  label={
                    <Typography className="text-dark">
                      Description <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  {...register("description", {
                    required: "Please enter a description",
                  })}
                  placeholder="Enter description"

                />
              </div>
              <div style={{ width: "49%", marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  className="menus-color"
                  onClick={addRoleDetails}
                >
                  Add Office Bearers
                </Button>
              </div>
              {fields.map((details, index) => (
                <div key={details.id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: "49%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.name`, {
                        required: "Please enter a name",
                        minLength: {
                          value: 3,
                          message: "Name must be min length of 3",
                        },
                      })}
                      error={!!errors?.roleDetails?.[index]?.name}
                      helperText={errors?.roleDetails?.[index]?.name?.message}
                      placeholder="Enter name"

                    />
                    <TextField
                      style={{ width: "49%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      // disabled={chapterId}
                      InputProps={{ readOnly: !!fields[index].email.length > 0 }}
                      {...register(`roleDetails.${index}.email`, {
                        required: "Please enter a email ID",
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Please enter valid email",
                        },
                        validate: (value) => {
                          const roles = getValues('roleDetails')
                          const sameMailRoles = roles.filter(item => item.email === value)
                          if (sameMailRoles.length > 1) {
                            return 'Email ID Already Exists !!!'
                          }
                        },onChange:()=>{
                          setValue(`roleDetails.${index}.isAdmin`,false)
                        }
                      })}
                      error={!!errors?.roleDetails?.[index]?.email}
                      helperText={errors?.roleDetails?.[index]?.email?.message}
                      placeholder="Enter email"

                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: "49%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Mobile <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.mobile`, {
                        required: "Please enter a mobile number",
                        validate: (value) => {
                          if (String(value).length !== 10) {
                            return "Please enter a valid 10-digit phone number";
                          }
                          // Additional validation logic if needed
                          return true; // Validation passed
                        },
                      })}
                      error={!!errors?.roleDetails?.[index]?.mobile}
                      helperText={errors?.roleDetails?.[index]?.mobile?.message}
                      placeholder="Enter mobile number"

                    />
                    <TextField
                      style={{ width: "49%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Role <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.role`, {
                        required: "Role is required",
                      })}
                      error={!!errors?.roleDetails?.[index]?.role}
                      helperText={errors?.roleDetails?.[index]?.role?.message}
                      placeholder="Enter role"

                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: "49%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Address <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.address`, {
                        required: "Please enter a address",
                      })}
                      error={!!errors?.roleDetails?.[index]?.address}
                      helperText={errors?.roleDetails?.[index]?.address?.message}
                      placeholder="Enter address"

                    />
                    <div style={{ display: "flex", width: "49%" }}>
                      {!sigGroupId && (isAdmin === index || (watch('roleDetails') && watch('roleDetails').every(item => !item.isAdmin)))&&(
                        <div
                          style={{ width: "20%" }}
                          className={sigGroupId ? "disable_checkbox" : ""}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{ color: sigGroupId ? "grey" : "#1976d2" }}
                                checked={watch(`roleDetails.${index}.isAdmin`)}
                                {...register(`roleDetails.${index}.isAdmin`, {
                                  validate: () => {
                                    if (watch(`roleDetails.${index}.email`)) {
                                      setValue(`roleDetails.${index}.password`, watch(`roleDetails.${index}.email`).split('@')[0])
                                      return true
                                    }
                                    // else {
                                    //   return 'Please enter a mail to make this office bearer a admin'
                                    // }
                                  }, onChange: (e) => {
                                    if (e.target.value) {
                                      setIsAdmin(index)
                                    }
                                  }
                                })}

                              />
                            }
                            label={
                              <Typography className="text-dark">Admin</Typography>
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "50px" }}>
                      <div className="float-end">
                        <Button className="border-radius-membership-wallpage-button"
                          variant="contained"
                          style={{ background: "red" }}
                          onClick={() => removeRoleDetails(index)}
                        >
                          <DeleteIcon />
                          Remove
                        </Button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center mt-5">
                <Button variant="contained" className="menus-color" type="submit">
                  {sigGroupId ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </Box>
      )}
    </>
  );
};

export default SIGGroupForm;
