import { useEffect, useState } from "react";
import { useLazyGetAdminByIdQuery } from "../../app/services/adminServices";
import { useLazyGetSIGGroupByIdQuery } from "../../app/services/SIGGroupServices";

const GetSIGName = ({ id }) => {
    const [sig,setSig]=useState("")
    const [sigName,setSigName]=useState('')
    const [getAdminData] = useLazyGetAdminByIdQuery()
    const [getSigDetails]=useLazyGetSIGGroupByIdQuery()
    useEffect(() => {
      getAdminData(id).then(res => {
        if (res?.data?.status) {
          setSig(res?.data?.data?.user)
        }
      })
    }, [getAdminData,id])
    useEffect(() => {
      if (sig) {
        getSigDetails(sig).then(res => {
          setSigName(res?.data?.data?.groupName)
        })
      }
    }, [getSigDetails, sig])
  
    return <div className="text-capitalize">{sigName?sigName:'-------'}</div>;
  };
  
  export default GetSIGName;