import React, { useState, useEffect } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import {
  usePaginationCategoryMutation,
  useDeleteCategoryMutation,
  useEnableDisableCategoryMutation,
} from "../../../app/services/categoryService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Spinner from "../../../Component/spinner/spinner";
import { Container, Divider, Modal, Typography } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import {
  selectCurrentScope,
} from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

//table head for category
function EnhancedCategoryTableHead() {
  const headCells = [
    {
      id: "categoryname",
      numeric: false,
      disablePadding: true,
      label: "Category Name"
    },
    {
      id: "subCategoryname",
      numeric: true,
      disablePadding: false,
      label: "Sub Category Name"
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

const ViewCategory = () => {
  const [selectedOpt, setSelectedOpt] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); //route
  const [page, setPage] = React.useState(0); //state for set page
  const [rowsPerPage, setRowsPerPage] = React.useState(5); //state for item per page
  const [getAllPaginationCategoryList] = usePaginationCategoryMutation(); //API call of Get all
  const [deleteCategory] = useDeleteCategoryMutation(); //API call of delete
  const [enableDisableCategory] = useEnableDisableCategoryMutation(); //API call of anableDisable
  const [type, setType] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [allCategory, setAllCategory] = useState([]);
  const memberScope = useSelector(selectCurrentScope);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");

  const handleOpen = (id) => {
    setOpen(true);
    setSelectedId(id);
  };
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);


  //calling event data
  useEffect(() => {
    setRowsPerPage(5);
    const data = { type: 0 };
    fetchData(data);
    // Fetch data on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, type]); // Update useEffect dependencies

  //getAllPendingEventList
  const fetchData = (datas) => {
    var data = {
      type: type,
      page: page + 1,
      itemsPerPage: itemsPerPage,
    };
    getAllPaginationCategoryList(data)
      .then((res) => {
        setLoading(false);
        setAllCategory(res?.data?.data?.categoryDetails || []);
        setTotalPage(res?.data?.data.totalCount || 0);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching pending event list:", error);
      });
  };

  //page Click for category
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //sliced category
  const slicedCategory = allCategory.slice(0, rowsPerPage); // Adjust slicing

  //get change value
  const getChangeValue = (eve) => {
    var data = {
      type: eve.target.value,
    };
    setType(eve.target.value);
    fetchData(data);
  };

  //Edit category&subcategory
  const handleEdit = (id) => {
    if (memberScope === 'PORTAL_SUPER_ADMIN') {
      navigate("/admin/category/" + id);
    }
    else {
      navigate("/assistantAdmin/category/" + id);
    }

  };

  //Delete category & subcategory
  const handleDelete = (id) => {
    const data = { id: selectedId };
    setLoading(true);
    deleteCategory(data).then((res) => {
      if (res.data.status) {
        setLoading(false);
        handleClose();
        if (slicedCategory.length <= 1) {
          setPage(page - 1);
          fetchData();
        } else {
          fetchData();
        }
      } else {
        setLoading(false);
        toast.error(res?.data?.err?.message);
      }
    });
  };

  //Enable and disable category & subcategory
  const handleEnableDisable = (id, num) => {
    const data = { id: id };
    setLoading(true);
    enableDisableCategory(data).then((res) => {
      if (res.data.status) {
        setLoading(false);
        if (num === 0) {
          //set num for diff the enable and disable
          toast.error("Category Disabled successfully"); //set alert message
        } else {
          toast.success("Category  Enabled successfully"); //set alert message
        }
        // Call fetchData again to fetch updated category list after deletion
        fetchData();
      } else {
        setLoading(false);
        if (num === 0) {
          toast.error("Fail to Disable Category "); //set alert message
        } else {
          toast.error("Fail to Enable Category "); //set alert message
        }
      }
    });
  };

  //add Category
  const addCategory = () => {
    if (memberScope === 'PORTAL_SUPER_ADMIN') {
      navigate("/admin/category/");
    }
    else {
      navigate("/assistantadmin/category/");
    }
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);


  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
    color: "#611f1e"

  };

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Category List</h2>
        </div>
      </div>

      <Container maxWidth={'xxl'} className='p-md-5 p-3 overallPadding'>
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="container mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "20px",
                }}
              >
                <FormControl style={{ width: "57%" }}>
                  <InputLabel shrink={true} id="type-label" className="text-dark">
                    Type
                  </InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    label="type"
                    required
                    value={selectedOpt}
                    onChange={getChangeValue}
                  >
                    <MenuItem onClick={() => setSelectedOpt(0)} value={0}>
                      Job
                    </MenuItem>
                    <MenuItem onClick={() => setSelectedOpt(1)} value={1}>
                      Forums
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="contained"
                className="menus-color"
                onClick={addCategory}
              >
                ADD Category{" "}
              </Button>
            </div>
          </div>
        </div>
        {/* </Container> */}
        {loading ? (
          <Spinner />
        ) : (
          <Box className="memberTable mb-5 p-0">
            <Paper sx={{ width: "100%", mb: 4 }}>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedCategoryTableHead />
                  <TableBody>
                    {slicedCategory.length > 0 ? (
                      slicedCategory.map((category, index) => (
                        <TableRow
                          hover
                          key={category._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {category.categoryName}
                          </TableCell>
                          <TableCell align="center">
                            {category.subCategory &&
                              category.subCategory.length > 0 ? (
                              <>
                                {category.subCategory.map(
                                  (subcategory, index) => (
                                    <span key={subcategory._id}>
                                      {subcategory.subCategoryName}
                                      {index !==
                                        category.subCategory.length - 1 && ", "}
                                    </span>
                                  )
                                )}
                              </>
                            ) : (
                              <>------</>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {!(category.isDeleted === 2) && (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleEdit(category._id)}
                                >
                                  <EditIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {category.isDeleted === 0 && (
                              <Tooltip title="Enable">
                                <IconButton
                                  aria-label="enable"
                                  onClick={() =>
                                    handleEnableDisable(category._id, 0)
                                  }
                                >
                                  <LockOpenIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {category.isDeleted === 2 && (
                              <Tooltip title="Disable">
                                <IconButton
                                  aria-label="disable"
                                  onClick={() =>
                                    handleEnableDisable(category._id, 1)
                                  }
                                >
                                  <LockIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="approve"
                                onClick={() => handleOpen(category._id)}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <p className="text-black text-center">
                              No Data Available
                            </p>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={style}
                  className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                >

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                      Confirmation
                    </Typography>
                    <IconButton onClick={handleClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Box display="flex" justifyContent="center" width="100%">
                    <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    style={{ color: "#611f1e" }}
                    variant="h6"
                    component="h2"
                  >
                    Are You Surely Want to Delete this Category?
                  </Typography>
                  {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      style={{

                        color: "#dc3545",
                        border: "1px solid #dc3545",
                        padding: "5px 15px",
                        borderRadius: "4px",
                      }}
                      onClick={handleClose}
                    >
                      No
                    </Button>
                    <Button
                      className="mx-2"
                      variant="contained"
                      style={{
                        backgroundColor: "#2e7d32",
                        color: "white",
                        padding: "5px 15px",
                        borderRadius: "4px",
                      }}
                      onClick={() => handleDelete()}
                    >
                      Yes
                    </Button>
                  </div>
                </Box>
              </Modal>
            </Paper>
            {slicedCategory.length > 0 ? (

              <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                <div className="mb-2 mb-md-0">
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      min={1}
                      placeholder="Page no."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div className="goPage cursor-pointer" onClick={handlePageChange}>
                      <h5 className="p-2 m-0">Go</h5>
                    </div>
                  </div>
                  {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                </div>

                {/* Pagination in the Center */}
                <div className="my-3 my-md-0">
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disablebtn"}
                    forcePage={page}
                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                    nextLabel={
                      pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                    }
                  />
                </div>
                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      className="itemsperpage"
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('');
                        setSearchTerm('');
                        setPage(0);
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>

                  </FormControl>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Box>

        )}
      </Container>
    </div>
  );
}

export default ViewCategory;
