import React, { useCallback, useEffect, useState } from 'react';
import '../../style/css/banner2.css';
import { useGetAllBannerPaginationMutation } from '../../app/services/bannerServices.js';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Banner() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allBannerContent, setAllBannerContent] = useState([]);
    const [getAllBannerPagination] = useGetAllBannerPaginationMutation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Function to fetch banners
    const getAllPagination = useCallback(() => {
        const data = {
            page: '', // Adjust page and itemsPerPage if necessary
            itemsPerPage: '',
            role: ""

        };

        getAllBannerPagination(data).then((res) => {
            if (res.data?.status) {
                const bannerDetails = res.data?.data?.bannerDetails || [];
                setAllBannerContent(bannerDetails);
            } else {
                setAllBannerContent([]);
            }
        });
    }, [getAllBannerPagination]);

    // Update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
    }, []);

    // Fetch banners on mount
    useEffect(() => {
        getAllPagination();
    }, [getAllPagination]);

    // Update the current banner every 5 seconds
    useEffect(() => {
        if (allBannerContent.length === 0) return;

        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % allBannerContent.length);
        }, 5000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, [allBannerContent]);

    const processLink = (link) => {
        // Ensure the link starts with a protocol
        if (!link.startsWith('http')) {
            link = `http://${link}`;
        }

        // Split the URL into parts by colon (:) and slash (/)
        const parts = link.split(/[:/]+/);

        // Example: Replace 'localhost' with 'example.com'
        const index = parts.indexOf('localhost'); // Find the index of 'localhost'
        if (index !== -1) {
            parts.splice(index, 1, 'example.com'); // Replace 'localhost' with 'example.com'
        }

        // Reconstruct the URL
        return `${parts[0]}://${parts[1]}${parts.length > 2 ? '/' + parts.slice(2).join('/') : ''}`;
    };


    return (
        <div className="banner-container" style={{ position: 'relative', overflow: 'hidden', height: '500px' }}>
            {allBannerContent.map((banner, index) => (
                <div
                    key={index}
                    className={`banner-slide ${index === currentIndex ? 'active' : ''}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: `${(index - currentIndex) * 100}%`,
                        width: '100%',
                        height: '100%',
                        transition: 'left 0.5s ease-in-out',
                        backgroundImage: `url(${isMobile ? banner.mobileBannerImage : banner.desktopBannerImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div
                        className="banner-text content-font"
                        style={{
                            position: 'absolute',

                            left: '7%',
                            color: 'black',
                            background: 'rgb(255 255 255 / 62%)',
                            padding: '10px 20px',
                            fontFamily: 'poppins'
                        }}
                    >
                        {banner.text || 'Default Banner Text'}<br />
                        <Link to={processLink(banner.buttonLink)} target='_blank'>
                            <Button className='float-end dynamicButton py-1'>{banner.buttonName}</Button>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Banner;
