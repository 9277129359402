import TopHeader from "../../Component/Header/TopHeader";
import { useFieldArray, useForm } from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, TextField, Grid, FormLabel, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useRemoveAdminMutation, useSaveChapterMutation } from "../../app/services/chapterServices";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetChapterDetailsByIdQuery } from "../../app/services/chapterServices";
import { useUpdateChapterMutation } from "../../app/services/chapterServices";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { Typography } from "@mui/material";
import { Controller } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import placeholder from '../../assets/images/placeholder/placeholder.png'
import { Country, State } from "country-state-city";
import { Salutation } from "../../constant/constant";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";

const ChapterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //route
  const [initialData, setInitalData] = useState([]);
  const [removeMemberAdmin] = useRemoveAdminMutation();
  const countries = Country.getAllCountries();
  const [isAdmin, setIsAdmin] = useState(null);
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);

  //chapterForm Initialization
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange', // Validate on form submission initially
    reValidateMode: 'onChange',
    defaultValues: {
      countryCategory: '',
      country: "",
      state: "",
      title: "",
      district: "",
      establishedOn: '',
      imageUrl: "",
      roleDetails: [],
      removedAdminEmail: [],
      adminsId: [],
      createdBy: {
        id: "",
        role: "",
      },//required
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roleDetails",
  });
  const states = watch('country')
    ? State.getStatesOfCountry(watch('country'))
    : [];
  // Adding a new empty object to roleDetails array when "Add Office Bearers" button is clicked
  const addRoleDetails = () => {
    append({
      role: "",
      salutation: '',
      name: "",
      designation: '',
      institution: '',
      address: "",
      pincode: '',
      mobile: "",
      email: "",
      roleImageUrl: "",
      isAdmin: false,
      password: "",
    });
  };

  // Removing Roledetails for each row except the first one
  const removeRoleDetails = (index) => {
    setValue(`roleDetails.${index}.isAdmin`, false);
    const isAdmin = getValues(`roleDetails.${index}.isAdmin`)
    const roleDetail = getValues(`roleDetails.${index}`)
    const allChapterData = getValues()
    setRemovedRoleId([...removedRoleId,roleDetail._id]);
    if (chapterId) {
      // Make a shallow copy of the roleDetails array
      const newData = [...allChapterData?.roleDetails];
      // Remove the element at the specified index
      newData.splice(index, 1);
      // Create a new object with the updated roleDetails array
      const newChapter = { ...allChapterData, roleDetails: newData };
      setChapterData(newChapter);
    }
    if (isAdmin) {
      removeAdmin(roleDetail, index)
    }
    else {
      remove(index);
    }
  };

  //Remove admin
  const removeAdmin = (member, index) => {
    const data = {
      chapterId,
      roleId: member._id,
      email: member.email,
    };
    setLoading(true);
    removeMemberAdmin(data).then((res) => {
      if (res.data?.data) {
        remove(index);
        setLoading(false);
        toast.success(res.data?.message);
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  const [saveChapter] = useSaveChapterMutation(); //calling save API service
  const { chapterId } = useParams(); //get Id from params
  const [chapterDetailById] = useLazyGetChapterDetailsByIdQuery(); //calling getby chapter id API service
  const [chapterData, setChapterData] = useState(null); //state for get id data
  const [updateChapter] = useUpdateChapterMutation(); //API for Update
  const [selectedImage, setSelectedImage] = useState();
  const [selectedRoleImage, setSelectedRoleImage] = useState([]);
  const [removedRoleId,setRemovedRoleId]=useState([]);

  //get by id
  const fetchChapterData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await chapterDetailById(chapterId);
      if (response.data.status) {
        setLoading(false);
        setChapterData(response.data.data); // Update the state with the fetched data
        setInitalData(response.data?.data);
      } else {
        setLoading(false);
        toast.error(response.data?.err?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [chapterDetailById, chapterId]);
  useEffect(() => {
    if (chapterId) {
      fetchChapterData(); // Call the function to fetch chapter data
    }
  }, [fetchChapterData, chapterId]);

  // Patch form values from chapterData
  useEffect(() => {
    if (chapterData) {
      setValue("title", chapterData.title);
      setValue("state", chapterData.state);
      setValue('country', countries.find(c => c.name === chapterData?.country)?.isoCode || chapterData?.country)
      setValue('countryCategory', chapterData?.countryCategory)
      setValue('district', chapterData?.district)
      setValue('establishedOn', chapterData?.establishedOn ? chapterData?.establishedOn.split('T')[0] : '')
      setValue("imageUrl", chapterData.imageUrl)
      if (chapterData.roleDetails.length !== 0) {
        setValue("roleDetails", chapterData.roleDetails);
      }
      const roleImages = chapterData.roleDetails.map(
        (item) => item.roleImageUrl
      );
      setSelectedRoleImage(roleImages);
    }
  }, [chapterData, countries, setValue]);

  //Form submit
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    if (chapterId) {
      const formRoleDetailsIds = formData.roleDetails.map(
        (role) => role._id
      );
      const filteredData = initialData?.roleDetails?.filter((item) => {
        const isIncluded = formRoleDetailsIds.includes(item._id);
        return !isIncluded;
      });
      const isAdminStatus = filteredData.filter(
        (item) => item.isAdmin === true
      );
      if (isAdminStatus) {
        const email = isAdminStatus.map((item) => item.email);
        setValue("removedAdminEmail", email);
        setValue("adminsId", initialData.adminsId);
        formData.removedAdminEmail = watch("removedAdminEmail")
        formData.adminsId = watch("adminsId")
        const base64Regex =
          /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
        formData.imageUrl = base64Regex.test(formData.imageUrl)
          ? formData.imageUrl
          : formData?.imageUrl.split("/").slice(-2).join("/");
        formData.roleDetails = formData.roleDetails.map((item) => {
          return {
            ...item,
            roleImageUrl: base64Regex.test(item.roleImageUrl)
              ? item.roleImageUrl
              : item.roleImageUrl?.split("/").slice(-3).join("/"),
          };
        });
        const updatedData = { ...formData, country: countries.find(c => c.isoCode === formData.country)?.name,removedRoleId:removedRoleId }
        const data = { id: chapterId, formData: updatedData };

        try {
          const res = await updateChapter(data);
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data?.message);
            navigate("/view-Chapter");
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message);
          }
        } catch (error) {
          setLoading(false);
          toast.error("Failed to update chapter" + error);
        }
      }
    }
    //Save Chapter API
    else {
      const updatedData = { ...formData, country: countries.find(c => c.isoCode === formData.country).name }
      updatedData.createdBy = {
        id: userId,
        role: memberScope,
      };
      saveChapter(updatedData).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data?.message)
          navigate("/view-Chapter"); // Navigate after the success message disappears
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message)
        }
      });
    }
  };

  const handleImageFileChange = (event) => {
    const file = event.target.files[0];

    if (!file || !file.type.startsWith("image/")) {
      setSelectedImage(null);
      setValue("imageUrl", "");
      setValue("image", "");
      setError("image", { message: "Please select an image file" });
      return;
    }

    // Check file size (2 MB limit)
    if (file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue("imageUrl", "");
      setValue("image", "");
      setError("image", { message: "Image size exceeds 2MB!" });
      return;
    }

    // If file is valid, proceed with dimension validation
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          // Check image dimensions with ±10px tolerance for width (420 to 430) and exact height (370 to 380)
          if (img.width < 420 || img.width > 430 || img.height < 370 || img.height > 380) {
            setSelectedImage(null);
            setValue("imageUrl", "");
            setValue("image", "");
            setError("image", { message: "Image dimensions must be between 420x370 and 430x380 pixels" });
            return;
          } else {
            const base64String = e.target.result;
            setSelectedImage(base64String); // Set base64 image string
            setValue("imageUrl", base64String);
            clearErrors("image"); // Clear errors if everything is valid
          }
        };
      };
      reader.readAsDataURL(file); // Start reading the file
    }
  };
  const handleRoleImageFileChange = (event, index) => {
    const file = event.target.files[0];

    // Check if file is selected and is an image
    if (!file || !file.type.startsWith("image/")) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null; // Reset the image at the specified index
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "");
      setValue(`roleDetails.${index}.roleImage`, "");
      setError(`roleDetails.${index}.roleImage`, { message: 'Please select an image file' });
      return;
    }

    // Check file size (2MB limit)
    if (file.size > 2 * 1024 * 1024) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "");
      setValue(`roleDetails.${index}.roleImage`, "");
      setError(`roleDetails.${index}.roleImage`, { message: 'Image size exceeds 2MB!' });
      clearErrors(`roleDetails.${index}.roleImageUrl`);
      return;
    }

    if (file) {
      // Process the image if it's valid
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Validate the image dimensions (140x140 to 150x150)
          if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
            // Reset the image if dimensions are invalid
            setSelectedRoleImage((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = null;
              return newImages;
            });
            setValue(`roleDetails.${index}.roleImageUrl`, "");
            setValue(`roleDetails.${index}.roleImage`, "");
            setError(`roleDetails.${index}.roleImage`, { message: "Image dimensions must be between 140x140 and 150x150 pixels" });
            return; // Early return to exit the function
          }

          // If the image is valid, set the image state
          const base64String = e.target.result;
          setSelectedRoleImage((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = base64String; // Replace image at the specified index
            return newImages;
          });

          setValue(`roleDetails.${index}.roleImageUrl`, base64String);
          clearErrors(`roleDetails.${index}.roleImage`);
        };
      };

      // Start reading the file
      reader.readAsDataURL(file);
    }
  }
  // Remove image function maybe future use
  // const removeRoleImage = (index) => {
  //   // Clear the selected role image
  //   setSelectedRoleImage((prevImages) => {
  //     const newImages = [...prevImages];
  //     newImages[index] = ''; // Set the image to an empty string
  //     return newImages;
  //   });
  //   // Update the form field value
  //   setValue(`roleDetails.${index}.roleImageUrl`, '');
  //   setValue(`roleDetails.${index}.roleImage`, '');
  //   // Optionally clear errors if the field is mandatory during editing
  //   clearErrors(`roleDetails.${index}.roleImage`);
  // };

  // const removeImage = () => {
  //   // Clear the selected role image
  //   setSelectedImage('');
  //   // Update the form field value
  //   setValue(`imageUrl`, '');
  //   setValue('image', '')
  //   // Optionally clear errors if the field is mandatory during editing
  //   clearErrors(`image`);
  // };


  return (
    <div>
      <TopHeader />
      {chapterId ? (
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">Edit Chapter</h2>
          </div>
        </div>) : (
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">Add Chapter</h2>
          </div>
        </div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="p-md-5 p-3 overallPadding">
          <div className="text-end mb-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}>
              Back
            </Button>
          </div>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4} className="d-flex justify-content-between">
              <Controller className='w-100' rules={{ required: "Please select country category" }} control={control} name="countryCategory" render={({ field, fieldState }) => (
                <FormControl className="w-100">
                  <InputLabel id='state-label'>
                    <span className="text-dark">Country Category</span>
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    id="State"
                    labelId="state-label"
                    fullWidth
                    label={<span className="text-black">Country Category <span className="text-danger">*</span></span>}
                    value={field?.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      if (e.target.value === 'India') {
                        setValue('country', 'IN')
                      }
                      else {
                        setValue('country', '')
                      }
                    }}
                    error={!!fieldState.error}
                  >
                    <MenuItem value={'India'}>
                      India
                    </MenuItem>
                    <MenuItem value={'Overseas'}>
                      Overseas
                    </MenuItem>
                  </Select>
                  <FormHelperText className="text-danger">
                    {fieldState?.error?.message}
                  </FormHelperText>
                </FormControl>
              )} />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                fullWidth
                className="mt-0"
                error={!!errors.country}
                disabled={watch('countryCategory') === 'India'}
              >
                <InputLabel className="text-dark">
                  Country <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Please select a country", // Validation rule
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="bg-white"
                      label={
                        <Typography className="text-dark">
                          Country <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      value={field.value || ''}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setValue("state", "");
                      }}
                    >
                      <MenuItem value="">
                        <em>Select Country</em>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.isoCode}
                          value={country.isoCode}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.country && (
                  <FormHelperText>
                    {errors.country.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                fullWidth
                disabled={!watch("country")}
                error={!!errors.state}
              >
                <InputLabel className="text-dark">
                  State<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: watch("country") && "Please select a state", // Only required if a country is selected
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={
                        <Typography className="text-dark">
                          State <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      readOnly={!watch('country')}
                      value={field.value || ''}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select State</em>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem key={state.isoCode} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.state && (
                  <FormHelperText>{errors.state.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                helperText={errors?.district?.message}
                fullWidth
                error={!!errors?.district}
                id="district"
                placeholder="Enter chapter district"
                label={
                  <Typography className="text-dark">
                    District <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("district", { required: "Please enter a district" })}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                helperText={errors?.title?.message}
                error={!!errors?.title}
                fullWidth
                id="title"
                placeholder="Enter chapter name"
                label={
                  <Typography className="text-dark">
                    Name of the Chapter <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("title", { required: "Please enter name",maxLength:{value:'35',message:'Please enter chapter name less than 35 letters'} })}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="establishedOn"
                label={
                  <Typography className="text-dark">
                    Established On <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                type="date"
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                }}
                {...register("establishedOn", {
                  required: "Please select a date which chapter is established", validate: (value) => {
                    const year = new Date(value).getFullYear().toString();
                    if (year.length > 4) {
                      return "Year cannot exceed 4 digits";
                    }
                    return true;
                  }, max: { value: new Date().toISOString().split("T")[0], message: 'Please select past or present date' }
                })}
                error={!!errors?.establishedOn}
                helperText={errors?.establishedOn?.message}
              />
            </Grid>
            {chapterId ? (
              <Grid item xs={12} md={6} lg={4}>

                <div className="d-flex align-items-end ps-3">
                  <img
                    src={selectedImage ? selectedImage : watch("imageUrl") ? `${watch("imageUrl")}?t=${new Date().getTime()}` : placeholder} // Fetched image from backend with cache busting
                    height="100"
                    alt="chapterform"
                  />
                  <TextField
                    accept="image/jpeg, image/png"
                    id="imageUrl"
                    InputProps={{ type: 'file', }}
                    inputProps={{ accept: "image/*" }}
                    style={{ display: "none" }}
                    {...register('image', {
                      required: !watch('imageUrl') && 'Please select a image',
                      onChange: handleImageFileChange
                    })}
                    fullWidth
                  />

                  <label
                    htmlFor="imageUrl"
                    style={{ marginLeft: "10px" }}
                  >
                    <Button
                      variant="contained"
                      className="menus-color"
                      component="span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Choose Image File
                    </Button>
                  </label>
                  {/* <IconButton >
                    <Tooltip title='Delete Image'>
                      <DeleteIcon className="text-danger" onClick={() => removeImage()} />
                    </Tooltip>
                  </IconButton> */}
                </div>
                {errors?.image &&
                  <FormHelperText error={!!errors?.image}>{errors?.image?.message}</FormHelperText>
                }
                <FormHelperText className="w-100 d-flex align-items-end mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image dimensions must be between 420x370 and 430x380 pixels</FormHelperText>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} lg={4}>
                {watch('imageUrl') ? (
                  <>
                    <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                      <div>
                        <span className="alata-regular">
                          {watch("image")[0]?.name}
                        </span>
                        <FormLabel
                          htmlFor="imageUrl"
                          style={{ marginLeft: "10px" }}
                        >
                          <Button
                            variant="contained"
                            className="menus-color"
                            component="span"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Choose File{" "}
                            <span
                              style={{ color: "white", paddingLeft: "5px" }}
                            >
                              *
                            </span>
                          </Button>
                        </FormLabel>
                      </div>
                      {/* <IconButton >
                        <Tooltip title='Delete Image'>
                          <DeleteIcon className="text-danger" onClick={() => removeImage()} />
                        </Tooltip>
                      </IconButton> */}
                    </div>
                    <TextField
                      className="d-none"
                      InputProps={{ type: "file" }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.image}
                      helperText={errors?.image?.message}
                      label={
                        <Typography>
                          Chapter Logo
                          <span style={{ color: "red" }}>*</span>{" "}
                        </Typography>
                      }
                      inputProps={{ accept: "image/*" }}
                      id="imageUrl"
                      {...register('image', {
                        required: !watch('imageUrl') && 'Please select a image',
                        onChange: handleImageFileChange
                      })}
                    />
                    <FormHelperText className="w-100 d-flex align-items-end mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image dimensions must be between 420x370 and 430x380 pixels</FormHelperText>
                  </>
                ) : (
                  <>
                    <div className="d-flex">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        id="imageUrl"
                        inputProps={{ accept: "image/*" }}
                        error={!!errors?.image}
                        helperText={errors?.image?.message}
                        label={<Typography className="text-dark"> Chapter Logo<span className="text-danger">*</span></Typography>}
                        type="file"
                        {...register('image', {
                          required: !watch('imageUrl') && 'Please select a image',
                          onChange: handleImageFileChange
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <IconButton
                                aria-label="toggle password visibility"
                                sx={{
                                  marginRight: "-8.5px",
                                }}
                              >
                                <Tooltip title='Delete Image'>
                                  <DeleteIcon className="text-danger" onClick={() => removeImage()} />
                                </Tooltip>
                              </IconButton> */}
                            </InputAdornment>
                          ),
                          sx: { paddingLeft: "1.2rem" },
                          style: { color: 'white', }
                        }}
                      />
                    </div>
                    <FormHelperText className="w-100 d-flex align-items-end mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image dimensions must be between 420x370 and 430x380 pixels</FormHelperText>
                  </>
                )}

              </Grid>
            )}

            <Grid item xs={12} md={6} lg={12}>
              <Button variant="contained" className="menus-color" onClick={addRoleDetails}>
                Add Office Bearers
              </Button>
            </Grid>

            {fields.map((details, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} lg={4} className="d-flex justify-content-between">
                  <Controller className='w-100' rules={{ required: "Please select role of the office bearer" }} control={control} name={`roleDetails.${index}.role`} render={({ field, fieldState }) => (
                    <FormControl className="w-100">
                      <InputLabel id='state-label'>
                        <span className="text-dark">Role</span>
                        <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        id="State"
                        labelId="state-label"
                        fullWidth
                        label={<span className="text-black">Role<span className="text-danger">*</span></span>}
                        value={field?.value || ""}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                        }}
                        error={!!fieldState.error}
                      >
                        <MenuItem value={'Convenor'}>
                          Convenor
                        </MenuItem>
                        <MenuItem value={'Co-convenor'}>
                          Co-convenor
                        </MenuItem>
                        <MenuItem value={'Executive Member'}>
                          Executive Member
                        </MenuItem>
                      </Select>
                      <FormHelperText className="text-danger">
                        {fieldState?.error?.message}
                      </FormHelperText>
                    </FormControl>
                  )} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Controller
                    control={control}
                    rules={{ required: "Please select an option" }}
                    name={`roleDetails.${index}.salutation`}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id="Salutation" className="text-dark">
                          Salutation
                          <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="Salutation"
                          className="bg-white"
                          id="Salutation"
                          label={
                            <Typography className="text-dark">
                              Salutation <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          value={field.value === "" ? "" : field.value}
                          variant="outlined"
                          onChange={(e) => field.onChange(e.target.value)}
                          error={!!fieldState.error}
                        >
                          {Salutation.map((data, index) =>
                          (
                            <MenuItem key={data.Name} value={data.id}>
                              {data.Name}
                            </MenuItem>
                          )
                          )}
                        </Select>
                        <FormHelperText error={!!fieldState?.error}>{fieldState?.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder="Enter office bearer name"
                    label={
                      <Typography className="text-dark">
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.name`, {
                      required: "Please enter a name",
                      minLength: {
                        value: 3,
                        message: "Name must be min length of 3",
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.name}
                    helperText={errors?.roleDetails?.[index]?.name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder="Enter designation"
                    label={
                      <Typography className="text-dark">
                        Designation <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.designation`, {
                      required: "Please enter a designation",
                    })}
                    error={!!errors?.roleDetails?.[index]?.designation}
                    helperText={errors?.roleDetails?.[index]?.designation?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    placeholder="Enter Institution"
                    label={
                      <Typography className="text-dark">
                        Institution <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.institution`, {
                      required: "Please enter a institution",
                    })}
                    error={!!errors?.roleDetails?.[index]?.institution}
                    helperText={errors?.roleDetails?.[index]?.institution?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter office bearer address"
                    label={
                      <Typography className="text-dark">
                        Complete Address for Communication <span style={{ color: "red" }}></span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.address`,
                      //    {
                      //   required: "Please enter address for communication",
                      // }
                    )}
                    fullWidth
                  // error={!!errors?.roleDetails?.[index]?.address}
                  // helperText={errors?.roleDetails?.[index]?.address?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter office bearer pincode"
                    label={
                      <Typography className="text-dark">
                        Pincode <span style={{ color: "red" }}></span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.pincode`,
                      //   {
                      //   required: "Please enter pincode for communication",
                      // }
                      {
                        maxLength: {
                          value: 6,
                          message: "Pincode must be exactly 6 digits",
                        },
                      }
                    )}
                    fullWidth
                    error={!!errors?.roleDetails?.[index]?.pincode}
                    helperText={errors?.roleDetails?.[index]?.pincode?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter office bearer email"
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Email <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    InputProps={{
                      readOnly: !!chapterData?.roleDetails?.[index]?.email
                    }}
                    {...register(`roleDetails.${index}.email`,
                      {
                        required: "Please enter a email",
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Please enter valid email",
                        },
                        validate: (value) => {
                          const roles = getValues('roleDetails')
                          const sameMailRoles = roles.filter(item => item.email === value)
                          if (sameMailRoles.length > 1) {
                            return 'Email ID already exists !!!'
                          }
                        },
                        onChange: () => {
                          setValue(`roleDetails.${index}.isAdmin`, false)
                          clearErrors(`roleDetails.${index}.isAdmin`)
                        }
                      }
                    )}
                    error={!!errors?.roleDetails?.[index]?.email}
                    helperText={errors?.roleDetails?.[index]?.email?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter office bearer mobile number"
                    label={
                      <Typography className="text-dark">
                        Mobile 
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.mobile`,
                      //    {
                      //   required: "Please enter a mobile number",
                      //   pattern: {
                      //     value: /^\d{10}$/,
                      //     message: "Mobile number must be 10 digits",
                      //   },
                      // }
                      {
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Mobile number must be 10 digits",
                        },
                      }
                    )}
                    fullWidth
                    error={!!errors?.roleDetails?.[index]?.mobile}
                    helperText={errors?.roleDetails?.[index]?.mobile?.message}
                  />
                </Grid>

                {!chapterId ? (
                  <>
                    <Grid item xs={12} md={6} lg={4}>
                      {watch(`roleDetails.${index}.roleImageUrl`) ? (
                        <>
                          <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                            <div>
                              <span className="alata-regular">
                                {watch(`roleDetails.${index}.roleImage`)[0]?.name}
                              </span>
                              <FormLabel
                                htmlFor={`roleImageUrl-${index}`}
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  variant="contained"
                                  className="menus-color"
                                  component="span"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Choose File{" "}
                                  <span
                                    style={{ color: "white", paddingLeft: "5px" }}
                                  >
                                    *
                                  </span>
                                </Button>
                              </FormLabel>
                            </div>
                            {/* <IconButton >
                              <Tooltip title='Delete Image'>
                                <DeleteIcon className="text-danger" onClick={() => removeRoleImage(index)} />
                              </Tooltip>
                            </IconButton> */}
                          </div>
                          <TextField
                            className="d-none"
                            InputProps={{ type: "file" }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={!!errors?.image}
                            helperText={errors?.image?.message}
                            label={
                              <Typography>
                                Photograph
                                <span style={{ color: "red" }}>*</span>{" "}
                              </Typography>
                            }
                            inputProps={{ accept: "image/*" }}
                            id={`roleImageUrl-${index}`}
                            {...register(`roleDetails.${index}.roleImage`, {
                              required: !watch(`roleDetails.${index}.roleImageUrl`) && 'Please select a image',
                              onChange: (e) => {
                                handleRoleImageFileChange(e, index)
                              }
                            })}
                          />
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-end">
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              variant="outlined"
                              error={!!errors?.roleDetails?.[index]?.roleImage}
                              helperText={errors?.roleDetails?.[index]?.roleImage?.message}
                              id={`roleImageUrl-${index}`}
                              inputProps={{ accept: "image/*" }}
                              {...register(`roleDetails.${index}.roleImage`, {
                                required: !watch(`roleDetails.${index}.roleImageUrl`) && 'Please select a image',
                                onChange: (e) => {
                                  handleRoleImageFileChange(e, index)
                                }
                              })}
                              label={<Typography className="text-dark">Photograph <span className="text-danger">*</span></Typography>}
                              placeholder="Please Select a image"
                              type="file"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {/* <IconButton
                                      aria-label="toggle password visibility"
                                      sx={{
                                        marginRight: "-8.5px",
                                      }}
                                    >
                                      <Tooltip title='Delete Image'>
                                        <DeleteIcon className="text-danger" onClick={() => removeRoleImage(index)} />
                                      </Tooltip>
                                    </IconButton> */}
                                  </InputAdornment>
                                ),
                                sx: { paddingLeft: "1.2rem" },
                                style: { color: 'white', }
                              }}
                            />
                          </div>
                        </>
                      )}
                      <FormHelperText className="w-100 d-flex align-items-end mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image dimensions must be between 140x140 and 150x150 pixels</FormHelperText>
                    </Grid>

                  </>
                ) : (<>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className="d-flex align-items-end w-100 ps-3">
                      <img
                        src={
                          selectedRoleImage[index]
                            ? selectedRoleImage[index].startsWith("data:image/")
                              ? selectedRoleImage[index]
                              : `${selectedRoleImage[index]}?t=${new Date().getTime()}`
                            : watch(`roleDetails.${index}.roleImageUrl`) ? watch(`roleDetails.${index}.roleImageUrl`) : placeholder
                        }
                        height="100"
                        alt={(!watch(`roleDetails.${index}.roleImageUrl`)) ? "roleImage" : ""}
                      />
                      <TextField
                        id={`roleImageUrl-${index}`}
                        InputProps={{ type: 'file' }}
                        inputProps={{ accept: "image/*" }}
                        label={<Typography className="text-dark"> Image</Typography>}
                        variant="outlined"
                        placeholder="Please Select a image"
                        style={{ display: "none" }}
                        {...register(`roleDetails.${index}.roleImage`, {
                          required: !watch(`roleDetails.${index}.roleImageUrl`) && 'Please select a image',
                          onChange: (e) => {
                            handleRoleImageFileChange(e, index)
                          }
                        })}
                      />
                      <label
                        htmlFor={`roleImageUrl-${index}`}
                        style={{ marginLeft: "10px" }}
                      >
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose Image File
                        </Button>
                      </label>
                      {/* <IconButton >
                        <Tooltip title='Delete Image'>
                          <DeleteIcon className="text-danger" onClick={() => removeRoleImage(index)} />
                        </Tooltip>
                      </IconButton> */}
                    </div>
                    {errors?.roleDetails?.[index]?.roleImage && <FormHelperText error={!!errors?.roleDetails?.[index]?.roleImage}>{errors.roleDetails?.[index]?.roleImage?.message}</FormHelperText>}
                    <FormHelperText className="w-100 d-flex align-items-end mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image dimensions must be between 140x140 and 150x150 pixels</FormHelperText>
                  </Grid>
                </>)}
                {(!chapterId && (isAdmin === index || watch('roleDetails').every(item => !item.isAdmin))) &&
                  <Grid item xs={12} md={6} lg={4} className="d-flex flex-sm-row flex-column">
                    <FormControl error={!!errors?.roleDetails?.[index]?.isAdmin}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: chapterId ? "grey" : "#1976d2" }}
                            checked={watch(`roleDetails.${index}.isAdmin`)}
                            {...register(`roleDetails.${index}.isAdmin`, {
                              validate: () => {
                                if (watch(`roleDetails.${index}.email`)) {
                                  setValue(`roleDetails.${index}.password`, watch(`roleDetails.${index}.email`).split('@')[0])
                                  return true
                                }
                                // else {
                                //   return 'Please enter a mail to make this office bearer a admin'
                                // }
                              }, onChange: (e) => {
                                if (e.target.value) {
                                  setIsAdmin(index)
                                }
                              }
                            })}
                          />
                        }
                        label="Admin"
                      />
                      {errors?.roleDetails?.[index]?.isAdmin && <FormHelperText error={!!errors?.roleDetails?.[index]?.isAdmin}>{errors?.roleDetails?.[index]?.isAdmin?.message}</FormHelperText>}
                    </FormControl>

                  </Grid>}
                {!chapterId && !(isAdmin === index || watch('roleDetails').every(item => !item.isAdmin)) &&
                  <Grid item xs={'none'} lg={4} md={6}></Grid>}
                <Grid item xs={12} md={6} lg={chapterId ? 8 : 4}>
                  <div className="h-100 w-100 d-flex justify-content-end align-items-center" >
                    <Button
                      variant="contained"
                      style={{ background: "red" }}
                      onClick={() => removeRoleDetails(index)}
                    >
                      <DeleteIcon />
                      Remove
                    </Button>
                  </div><br />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} lg={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" className="menus-color" type="submit">
                  {chapterId ? "Update" : "Submit"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )
      }
    </div >
  );
};

export default ChapterForm;
