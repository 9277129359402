import { api } from "./api";
 
const memberListServices = api.injectEndpoints({
  endpoints: (builder) => ({
    //getAllMember
    getAllMember:builder.mutation({
      query:(data)=>({
        url: "member/getall",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:data
      })
    }),

    //GetAllMemberList
    getAllMemberList: builder.mutation({
      query: (newMemberListData) => ({
        url: "member/getAllMemberPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newMemberListData,
      }),
    }),

    //enableDisable Member
    enableDisableMemberList:builder.mutation({
      query:(id)=>({
        url:`member/enableDisable/`+id,
        method:"PUT",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags:["Member"],
    }),

    //UpdateMember
    updateMember: builder.mutation({
      query: ({ id, data }) => ({
        url: `member/updatemember/${id}`,
        method: "PUT",
        // headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    deleteMemberList: builder.mutation({
      query: (id) => ({
        url: `member/deletemember/${id}`,
        method: "PUT",
        // headers: { "Content-Type": "application/json" },
      }),
    }),

    //getPendingMemberById
    getPendingMemberById: builder.query({
      query: (id) => `member/get/${id}`,
    }),
    getAllPendingMember:builder.mutation({
      query:(data)=>({
        url: "member/getMemberPendingList",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:data
      })
    }),
    approveRejectMember:builder.mutation({
      query:(data)=>({
        url: "member/approveRejectMember",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body:data
      })
    }),

  }),
  overrideExisting: false,
});
 
export const {
  useGetAllMemberMutation,
  useGetAllMemberListMutation,
  useEnableDisableMemberListMutation,
  useUpdateMemberMutation,
  useLazyGetPendingMemberByIdQuery,
  useGetAllPendingMemberMutation,
  useApproveRejectMemberMutation,
  useDeleteMemberListMutation,
} = memberListServices;