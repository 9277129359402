import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { Box, Button, TextField, Typography } from '@mui/material';
import "../../style/css/journalForm.css";
import TopHeader from "../../Component/Header/TopHeader";
import { useForm, Controller } from "react-hook-form";
import {
  useSaveForumMutation,
  useUpdateForumMutation,
  useLazyGetForumByIdQuery,
} from "../../app/services/forumServices";
import { useSavePdfForumMutation } from "../../app/services/forumPdfServices";
import placeholder from "../../assets/images/placeholder/placeholder.png"
import {
  selectCurrentScope,
  selectUserId
} from "../../app/auth/authSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import { useSelector } from "react-redux";
import { useGetAllCategoryMutation } from "../../app/services/categoryService";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
dayjs.extend(customParseFormat);


function ForumForm() {
  const [loading, setLoading] = useState(false);
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;
  const { forumId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [saveForum, { isLoading }] = useSaveForumMutation();
  const [savePdfForum] = useSavePdfForumMutation();
  const [getForumById] = useLazyGetForumByIdQuery(forumId);
  const [updateForum] = useUpdateForumMutation();
  const [getAllCategory] = useGetAllCategoryMutation();
  const [pdfErrorMessage, setPdfErrorMessage] = useState("");
  const fileInputRefPdf = useRef(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isPdfFileError, setIsPdfFileError] = useState(false);
  const [selectedDesktopImage, setSelectedDesktopImage] = useState();

  // useForm hook for form data handling
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    trigger,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: "",
      name: "",
      description: "",
      headline: "",
      uploadImage: "",
      pdfDocumentID: "",
      createdBy: {
        id: "",
        role: "",
      },
      SIGGroupsId: [],
      chaptersId: [],
      uploadPdf: "",
      pdflink: "",
    },
  });

  //Get all category
  const fetchCategory = useCallback(async () => {
    try {
      const response = await getAllCategory({ type: 1 });
      setAllCategory(response.data.data);
    } catch (error) { }
  }, [getAllCategory]);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  //Get forum by id
  useEffect(() => {
    if (forumId) {
      setLoading(true);
      getForumById(forumId).then((res) => {
        if (res.data.status) {
          setLoading(false);
          const data = res.data?.data;
          Object.entries(data).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
      });
    }
  }, [forumId, getForumById, setValue]);

  const pdflink = watch("pdflink");

  //On submit for save and update
  const onSaveForum = (formData) => {
    if (isPdfFileError) {
      return;
    }
    if (pdfFile) {
      const pdfFormData = new FormData();
      pdfFormData.append("uploadPdf", pdfFile);
      savePdfForum(pdfFormData).then((res) => {
        if (res.data.status) {
          if (res.data?.data?.documentID) {
            toast.success(res.data?.message);
            formData.pdfDocumentID = res.data?.data?.documentID;
          }
          setLoading(true);
          trigger().then((isValid) => {
            if (!forumId) {
              formData.createdBy = {
                id: userId,
                role: memberScope,
              };
            }
            if (isValid) {
              if (!formData.pdfDocumentID) {
                return;
              }
              if (userId && memberScope === "PORTAL_SIG_ADMIN") {
                formData.SIGGroupsId = [sigId];
              } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
                formData.chaptersId = [chapterId];
              }
              setData([...data, formData]);
              if (forumId) {
                let requestIdAndData;
                requestIdAndData = {
                  id: forumId,
                  data: formData,
                };
                updateForum(requestIdAndData)
                  .then((res) => {
                    if (res.data.status) {
                      setLoading(false);
                      toast.success(res.data?.message);
                      reset();
                      if (memberScope === "PORTAL_SUPER_ADMIN") {
                        navigate("/admin/forumTable");
                      }
                      if (memberScope === "PORTAL_ADMIN") {
                        navigate("/assistantadmin/forumTable");
                      }
                      if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                        navigate("/chapterAdmin/forumTable");
                      }
                      if (memberScope === "PORTAL_SIG_ADMIN") {
                        navigate("/sigAdmin/forumTable");
                      }
                    } else {
                      toast.error(res.data?.err?.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                  });
              } else {
                saveForum(formData)
                  .then((res) => {
                    if (res.data?.status) {
                      setLoading(false);
                      toast.success(res.data?.message);
                      reset();
                      if (memberScope === "PORTAL_SUPER_ADMIN") {
                        navigate("/admin/forumTable");
                      }
                      if (memberScope === "PORTAL_ADMIN") {
                        navigate("/assistantadmin/forumTable");
                      }
                      if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                        navigate("/chapterAdmin/forumTable");
                      }
                      if (memberScope === "PORTAL_SIG_ADMIN") {
                        navigate("/sigAdmin/forumTable");
                      }
                    }
                  })
                  .catch((error) => {
                    toast.error(error);
                  });
              }
            }
          });
        }
      });
    } else {
      trigger().then((isValid) => {
        if (isValid) {
          setLoading(true);
          if(!forumId){
            formData.createdBy = {
              id: userId,
              role: memberScope,
            };
          }
          if (userId && memberScope === "PORTAL_SIG_ADMIN") {
            formData.SIGGroupsId = [sigId];
          } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
            formData.chaptersId = [chapterId];
          }
          setData([...data, formData]);
          if (forumId) {
            let requestIdAndData;
            requestIdAndData = {
              id: forumId,
              data: formData,
            };
            const base64Regex =
              /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
            const uploadImg = formData?.uploadImage;
            if (base64Regex.test(formData?.uploadImage)) {
              formData.uploadImage = uploadImg; // No change needed if it's base64
            } else {
              formData.uploadImage = formData.uploadImage
                .split("/")
                .slice(-2)
                .join("/");
            }
            updateForum(requestIdAndData)
              .then((res) => {
                if (res.data.status) {
                  setLoading(false);
                  toast.success(res.data?.message);
                  reset();
                  if (memberScope === "PORTAL_SUPER_ADMIN") {
                    navigate("/admin/forumTable");
                  }
                  if (memberScope === "PORTAL_ADMIN") {
                    navigate("/assistantAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                    navigate("/chapterAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_SIG_ADMIN") {
                    navigate("/sigAdmin/forumTable");
                  }
                } else {
                  toast.error(res.data?.err?.message);
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          } else {
            saveForum(formData)
              .then((res) => {
                if (res.data?.status) {
                  setLoading(false);
                  toast.success(res.data?.message);
                  reset();
                  if (memberScope === "PORTAL_SUPER_ADMIN") {
                    navigate("/admin/forumTable");
                  }
                  if (memberScope === "PORTAL_ADMIN") {
                    navigate("/assistantAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_CHAPTER_ADMIN") {
                    navigate("/chapterAdmin/forumTable");
                  }
                  if (memberScope === "PORTAL_SIG_ADMIN") {
                    navigate("/sigAdmin/forumTable");
                  }
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          }
        }
      });
    }
  };

  //Event image handling
  const handleDesktopImageChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedDesktopImage(null);
      // setError("desktopImage", { message: "Please select a image file" });
      setValue("uploadImage", "");
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedDesktopImage(null);
      // setError("desktopImage", { message: "File size exceed 2 mb" });
      setValue("uploadImage", "");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {

          if (
            img.width < 355 ||
            img.width > 375 ||
            img.height < 450 ||
            img.height > 470
          ) {
            setSelectedDesktopImage(null);
            setValue("uploadImage", "");
            setError("desktopImage", {
              message: "Image dimensions must be 355–375px wide and 450–470px hight",
            });
            return;
          }
          let base64String = e.target.result;
          setSelectedDesktopImage(reader.result);
          setValue("uploadImage", base64String);
          clearErrors("desktopImage");
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //Pdf handling
  const handlePdfFileChange = async (event) => {
    const pdfFile = event.target.files[0];
    if (!pdfFile) {
      setPdfFile(null);
      setPdfErrorMessage("");
      setIsPdfFileError(false);
      setValue("pdfDocumentID", "");
      return;
    }
    if (pdfFile.type !== "application/pdf") {
      setError("pdfDocumentID", {
        message: "Allow only PDF",
      });
      setPdfFile(null);
      setIsPdfFileError(true);
      setValue("pdfDocumentID", "");
      return;
    } else {
      setIsPdfFileError(false);
      setPdfErrorMessage("");
      setPdfFile(pdfFile);
    }
  };

  const validateFile = (file) => {
    const allowedTypes = ["application/pdf"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (!file || file.length === 0) {
      // No file selected, so reset the state
      setPdfErrorMessage("");
      setIsPdfFileError(false);
      setValue("pdfDocumentID", "");
      return true; // No file to validate
    }

    const pdfFile = file[0];

    if (pdfFile) {
      return true; // Skip validation
    }

    if (!allowedTypes.includes(pdfFile.type)) {
      setPdfErrorMessage("Only PDFs are allowed!");
      setIsPdfFileError(true);
      return false;
    }

    if (pdfFile.size > maxSize) {
      setPdfErrorMessage("File size should not exceed 5MB!");
      setIsPdfFileError(true);
      return false;
    }

    setPdfErrorMessage("");
    setIsPdfFileError(false);
    setPdfFile(pdfFile);
    return true; // Validation passed
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">{forumId ? "Edit Forum" : "Add Forum"}</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          component={"forum"}
          className="d-flex flex-column p-md-5 p-3 overallPadding ">
          <div className="text-end w-100 mb-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
          <div style={{ marginTop: "25px", marginBottom: "50px" }}>
            <form onSubmit={handleSubmit(onSaveForum)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Controller
                  control={control}
                  name="category"
                  render={({ field, fieldState }) => (
                    <FormControl style={{ width: "30%" }}>
                      <InputLabel id="category-label">
                        Category <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        style={{ backgroundColor: "#f0f0f0" }}
                        labelId="category-label"
                        id="category"
                        label={
                          <Typography className="text-dark">
                            Category <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        value={field.value ? field.value : ""}
                        name={field.name}
                        onChange={field.onChange}
                        error={!!fieldState?.error}
                        variant="outlined"
                      >
                        {!isLoading && allCategory?.length > 0 ? (
                          allCategory?.filter(item => item.isDeleted === 0)
                            .map((item, index) => (
                              <MenuItem key={index} value={item._id}>
                                {item.categoryName}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem>Loading....</MenuItem>
                        )}
                      </Select>
                      <FormHelperText className="text-danger">
                        {fieldState?.error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                  rules={{ required: "Please Select a Category" }}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "30%" }}
                  id="name"
                  label={
                    <Typography className="text-dark">
                      Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  placeholder="Enter Name"
                  {...register("name", { required: "Please Enter a Name" })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "30%" }}
                  id="headline"
                  label={
                    <Typography className="text-dark">
                      HeadLine <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  placeholder="Enter Headline"
                  {...register("headline", {
                    required: "Please Enter a Headline",
                  })}
                  error={!!errors?.headline}
                  helperText={errors?.headline?.message}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "30%" }}
                  id="description"
                  label={
                    <Typography className="text-dark">
                      Description <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  placeholder="Enter description"
                  {...register("description", { required: "Please enter description" })}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  multiline
                />
                <div
                  style={{ width: "30%", display: "flex", alignItems: "center", flexDirection: 'column' }}
                >
                  {forumId ? (

                    <div className="d-flex flex-column w-100 align-items-center h-100 row-gap-3">
                      <img
                        src={
                          selectedDesktopImage
                            ? selectedDesktopImage
                            : watch("uploadImage")
                              ? watch("uploadImage")
                              : placeholder
                        } // Fetched image from backend with cache busting
                        alt="Spotlight Img"
                        className="img-fluid w-75 mb-4"
                        style={{
                          objectFit: "scale-down",
                          aspectRatio: "4/1",
                        }}
                      />
                      <TextField
                        sx={{ mt: 2 }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: "image/jpeg, image/png" }}
                        id="uploadImage"
                        label={
                          <Typography className="text-dark">
                            Image <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        style={{ display: "none" }}
                        type="file"
                        {...register(`desktopImage`, {
                          validate: (value) => {
                            const file = value[0];
                            if (!watch(`image`)) {

                              if (file && file.size > 2 * 1024 * 1024) {
                                setValue(`image`, "");
                                return "File size exceeds 2mb";
                              }
                              return true;
                            }
                          },
                          onChange: (e) => handleDesktopImageChange(e),
                        })}
                      />
                      {console.log(errors?.desktopImage?.message)}
                      {!!errors?.desktopImage && (
                        <p className="error-message mb-0">

                          {errors?.desktopImage?.message}
                        </p>
                      )}
                      <div className="text-center">
                        <label htmlFor="uploadImage">
                          <Button
                            variant="contained"
                            className="menus-color"
                            component="span"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Choose Image
                          </Button>
                        </label>
                      </div>
                    </div>
                  ) : (

                    <div style={{ width: "100%", display: "flex" }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: "image/jpeg, image/png" }}
                        className="w-100"
                        id="uploadImage"
                        error={!!errors?.desktopImage}
                        helperText={errors?.desktopImage?.message}
                        label={
                          <Typography className="text-dark">
                            Image
                          </Typography>
                        }
                        type="file"
                        {...register(`desktopImage`, {
                          validate: (value) => {
                            const file = value[0];
                            if (!watch(`image`)) {
                              if (file && file.size > 2 * 1024 * 1024) {
                                setValue(`image`, "");
                                return "File size exceeds 2mb";
                              }

                              return true;
                            }
                          },

                          onChange: (e) => handleDesktopImageChange(e),
                        })}
                      />
                      {console.log(errors?.image?.message)}
                    </div>

                  )}
                </div>
                {forumId && pdflink ? (
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: 'column'
                    }}
                  >
                    <div>
                      <a
                        href={pdflink}
                        cursor="pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "block", marginTop: "10px" }}
                      >
                        View Uploaded PDF
                      </a>
                    </div>
                    <div>
                      <TextField InputProps={{ type: 'file' }} fullWidth InputLabelProps={{ shrink: true }}
                        label={<Typography className="text-dark text-capitalize">PDF
                        </Typography>}
                        ref={fileInputRefPdf}
                        accept=".pdf"
                        style={{ display: "none" }}
                        id="pdfFile"
                        type="file"
                        {...register("pdfDocumentID", {
                          validate: validateFile, // Custom validation function
                          onChange: (e) => handlePdfFileChange(e)
                        })}
                        error={!!errors?.pdfDocumentID}
                        helperText={errors?.pdfDocumentID?.message} ></TextField>
                      <label htmlFor="pdfFile" style={{ marginLeft: "10px" }}>
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose PDF File
                        </Button>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center"
                      , flexDirection: 'column'
                    }}
                  >

                    <div style={{ width: "100%" }}>
                      <TextField InputProps={{ type: 'file' }} fullWidth InputLabelProps={{ shrink: true }}
                        label={<Typography className="text-dark text-capitalize">PDF
                        </Typography>} ref={fileInputRefPdf}
                        accept=".pdf"
                        style={{ width: "100%" }}
                        id="pdfFile"
                        type="file"
                        {...register("pdfDocumentID", {
                          validate: validateFile, // Custom validation function,
                          onChange: (e) => handlePdfFileChange(e)
                        })}
                        error={!!errors?.pdfDocumentID}
                        helperText={errors?.pdfDocumentID?.message} ></TextField>
                    </div>
                  </div>
                )}
              </div>
              {pdfErrorMessage && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  {pdfErrorMessage}
                </div>
              )}
              <div
                style={{ display: "flex", justifyContent: "center", gap: "10px" }}
              >
                <>
                  <Button variant="contained" className="menus-color" type="submit">
                    {forumId ? "Update" : "Submit"}
                  </Button>
                </>
              </div>
            </form>
          </div>
        </Box>
      )}
    </>
  );
}

export default ForumForm;
