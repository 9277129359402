import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../Component/Header/TopHeader'
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { Toolbar, Typography, IconButton, Link, Modal, Button, TextField, InputAdornment, Divider, Container, } from '@mui/material';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useGetPendingEventListMutation, } from '../../app/services/eventService';
import { useGetPendingForumListMutation, } from '../../app/services/forumServices';
import { useGetPendingGalleryListMutation } from '../../app/services/galleryService';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLazyGetCategoryDetailsByIdQuery } from "../../app/services/categoryService";
import Spinner from "../../Component/spinner/spinner"
import { toast } from 'react-toastify';
import { useDeleteImportantAnnouncementMutation, useGetApproveRejectImportantAnnouncementMutation, useLazyGetImportantAnnouncementDetailsByIdQuery, useLazyGetImportantAnnouncementEnableDisableQuery, usePaginationImportantAnnouncementMutation } from '../../app/services/importantAnnouncementService';
import { useSelector } from 'react-redux';
import { selectCurrentScope, selectUserId } from '../../app/auth/authSlice';
import { selectCurrenChapterRole } from '../../app/features/chapterAdminSlice';
import { useForm } from 'react-hook-form';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DOMPurify from "dompurify";
import { useGetEventTypeByIdQuery } from '../../app/services/eventTypeServices';
import GetChapterName from '../../Component/HomePage/GetChapterName';
import GetSIGName from '../../Component/HomePage/GetSigName';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import ChapterPendingTable from '../../Component/ChapterPendingTable';
import SigPendingTable from '../../Component/SigPendingTable';


//event Table 
//Table header
function EnhancedTableHead() {
    const headCells = [
        { id: "Level", numeric: false, disablePadding: true, label: "Level" },
        { id: "Type", numeric: false, disablePadding: true, label: "Type" },
        { id: "title", numeric: false, disablePadding: true, label: "Event Title" },
        {
            id: "date",
            numeric: true,
            disablePadding: false,
            label: "Dates",
        },
        { id: "Mode", numeric: false, disablePadding: true, label: "Mode" },
        {
            id: "CreatedBy",
            numeric: true,
            disablePadding: false,
            label: "Created by",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}
//News table

//forum table

//table toolbar for forum

//table head for forum
function EnhancedForumTableHead() {
    const headCells = [
        {
            id: "categoryName",
            numeric: false,
            disablePadding: true,
            label: "Category Name",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "updatedDate",
            numeric: false,
            disablePadding: true,
            label: "Date",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

//Gallery table

//table toolbar for Gallery
function EnhancedGalleryTableToolbar({ numSelected = 0 }) {
    return (
        <Toolbar
            sx={{
                backgroundColor: "#611f1e",
                textAlign: "center",
                color: "white",
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {numSelected > 0 ? `${numSelected} selected` : "Gallery Table"}
            </Typography>
        </Toolbar>
    );
}

EnhancedGalleryTableToolbar.propTypes = {
    numSelected: PropTypes.number,
};

//table head for gallery
function EnhancedGalleryTableHead() {
    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Gallery Image' },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Description' },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

const CategoryName = ({ id }) => {
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [getCategoryDetailsById] = useLazyGetCategoryDetailsByIdQuery();

    //getCategoryDetailsById
    useEffect(() => {
        getCategoryDetailsById(id)
            .unwrap()
            .then((res) => {
                setCategoryDetails(res?.data?.categoryName);
            })
            .catch((error) => {
                setCategoryDetails("Error fetching data");
            });
    }, [id, getCategoryDetailsById]);
    return categoryDetails;
};


const GetEventType = ({ id }) => {
    const { data, error, isLoading } = useGetEventTypeByIdQuery(id);
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!data) return <p>No event type found</p>;

    return <div>{data?.data?.name}</div>;
};

const AdminApproval = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    //tab Change
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };
    const [page, setPage] = useState(0);
    // const currentLocation = useLocation();
    const { tab } = useParams()
    const rowsPerPage = 5;
    const rowsForumPerPage = 5;
    const rowsGalleryPerPage = 2;
    const [allEvent, setAllEvents] = useState([])
    const [totalPage, setTotalPage] = useState(0);
    const [forumPage, setForumPage] = useState(0);
    const [totalForumPage, setTotalForumPage] = useState(0);
    const [allForum, setAllForum] = useState([]);
    const [galleryPage, setGalleryPage] = useState(0);
    const [totalGalleryPage, setTotalGalleryPage] = useState(0);
    const [allGallery, setAllGallery] = useState([]);
    const [getAllPendingEventList] = useGetPendingEventListMutation();
    const [getAllPendingForumList] = useGetPendingForumListMutation();
    const [getAllPendingGalleryList] = useGetPendingGalleryListMutation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);


    // Announcement code Starts

    const [paginationAnnouncement] = usePaginationImportantAnnouncementMutation();
    const [announcements, setAnnouncements] = useState([]);
    const rowsPerPage5 = 5; //state for item per page
    const [page5, setPage5] = useState(0);
    const [totalPage5, setTotalPage5] = useState(0);
    const [open, setOpen] = useState(false);
    // const [tabId, setTabId] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [deleteAnnouncement] = useDeleteImportantAnnouncementMutation()
    const [EnableDisable] = useLazyGetImportantAnnouncementEnableDisableQuery()
    const [getAnnouncementById] = useLazyGetImportantAnnouncementDetailsByIdQuery()
    const [show, setShow] = useState(false);
    const [getAnnouncement, setGetAnnouncement] = useState()
    const [approveReject] = useGetApproveRejectImportantAnnouncementMutation()
    const [openModal, setOpenModal] = useState(false);
    const userId = useSelector(selectUserId);
    const roleDetails = useSelector(selectCurrenChapterRole);

    const handleOpen = (id) => {
        setOpen(true);
        setSelectedMember(id);
    };

    const handleOpenModal = (id) => {
        setOpenModal(true);
        setSelectedMember(id);
    };

    const handleClose = () => {
        setOpen(false)
        setOpenModal(false);
        setShow(false)
        reset()
    };

    const memberScope = useSelector(selectCurrentScope);

    // Memoized fetchAnnouncements using useCallback
    const fetchAnnouncements = useCallback(async () => {
        // var path = currentLocation.pathname
        const data = {
            page: page5 + 1, // Increment page for the API request
            itemsPerPage: rowsPerPage5,
            role: (memberScope) ? memberScope : "",
            approve: 0
        };
        try {
            const res = await paginationAnnouncement(data);

            if (res?.data?.status) {
                setAnnouncements(res?.data?.data?.newAnnouncementDetails.filter(item => item?.isApproved === 0));
                setTotalPage5(res?.data?.data?.totalCount || 0);
            } else {
                setAnnouncements([])
                setTotalPage5(0)
            }
        } catch (error) {
        } finally {
        }
    }, [page5, rowsPerPage5, paginationAnnouncement, memberScope]); // Only recreate the function if page or rowsPerPage changes
    // Trigger the memoized fetchAnnouncements function when page or rowsPerPage changes
    useEffect(() => {
        fetchAnnouncements();
    }, [fetchAnnouncements]); // Call fetchAnnouncements whenever the callback updates
    useEffect(() => {
        setCurrentTabIndex(tab ? Number(tab) : 0);
    }, [announcements, tab])


    const EnhancedAnnouncementTableHead = () => {
        const headCells = [
            { id: "text", label: "Text" },
            // { id: "rejectReason", label: "Reject Reason" },
            { id: "status", label: "Status" },
            { id: "actions", label: "Actions" }
        ];

        return (
            <TableHead>
                <TableRow className='menus-color'>
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            padding="normal"
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };
    //PageClick
    const handlePageClick = ({ selected }) => {
        setPage5(selected);
    };

    const handleShowClose = () => setShow(false);

    const handleView = (id) => {
        setShow(!show)
        getAnnouncementById(id).then((res) => {
            if (res?.data?.status) {
                setGetAnnouncement(res?.data?.data)
            }
        })
    }

    //handleDelete
    const handleDelete = () => {
        deleteAnnouncement(selectedMember).then((res) => {
            if (res.data?.status) {
                toast.error(res.data.message);
                handleClose();
                if (announcements.length <= 1) {
                    setPage5(page5 - 1);
                    fetchAnnouncements()
                } else {
                    fetchAnnouncements()
                }
            } else {
                toast.error(res.data?.err?.message);
            }
        });
    }

    // Enable/Disable
    const handleEnableDisable = (id) => {
        EnableDisable(id).then((res) => {
            if (res?.data?.data?.isDeleted === 0) {
                fetchAnnouncements()
                toast.success(res?.data?.message);
            } else {
                fetchAnnouncements()
                toast.error(res?.data?.message);
            }
        })
            .catch((err) => {
                console.error(err)
            })
    }

    //calculate pagecount
    const pageCount5 = Math.ceil(totalPage5 / rowsPerPage5);
    const {
        register: registerReject,
        formState: { errors },
        getValues, reset,
        clearErrors, watch
    } = useForm({
        defaultValues: {
            rejectionReason: "",
        },
    });

    //Approve
    const handleApproveReject = ({ id, type }) => {
        const rejectionReason = getValues("rejectionReason");
        let data;
        if (type === 0) {
            data = {
                newAnnouncementsId: id,
                type: type,
            };
        }
        if (type === 1) {
            if (
                rejectionReason === null ||
                rejectionReason === undefined ||
                rejectionReason === ""
            ) {
                return;
            } else {
                clearErrors();
                data = {
                    newAnnouncementsId: id,
                    type: type,
                    rejectionReason,
                };
            }
        }
        approveReject(data).then((res) => {
            if (res?.data?.status) {
                toast.success(res?.data?.message);
                fetchAnnouncements()
                handleClose();
            }
        })
    }


    const sanitizeEditorContent = (html) => {
        // Allow only specific tags for styles (e.g., b, i, u, strong, em)
        return DOMPurify.sanitize(html, {
            ALLOWED_TAGS: ["b", "i", "u", "strong", "em"],
            ALLOWED_ATTR: [], // Disallow all attributes
        });
    };

    //   Announcement code ends


    //Event Function -----------------------started------------------------------//

    //calling event data
    useEffect(() => {
        fetchData(); // Fetch data on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, rowsForumPerPage]); // Update useEffect dependencies


    //getAllPendingEventList
    const fetchData = () => {
        setLoading(true);
        var data = {
            page: page + 1,
            itemsPerPage: rowsPerPage
        }
        getAllPendingEventList(data).then((res) => {
            setLoading(false);
            if (res.data.status) {
                if (res.data.data.eventDetails.length === 0 && page >= 1) {
                    setPage(page - 1);
                }
                const filteredEvents = res?.data?.data?.eventDetails.filter(item => item.isDeleted === 0);
                setAllEvents(filteredEvents || []);
                setTotalPage(res?.data?.data.totalCount || 0);
            }
            else {
                setAllEvents([])
                setTotalPage(0)
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    };

    //page Click for Event
    const handleEventPageClick = ({ selected }) => {
        setPage(selected);
    };


    //calculate pagecount
    const pageCount = Math.ceil(totalPage / rowsPerPage);

    //Event function -------------------------------------- Ended-------------------------------------------//

    //Forum Function -----------------------------------started---------------------------------------------//

    const fetchForumData = useCallback(() => {
        var data = {
            page: forumPage + 1,
            itemsPerPage: rowsForumPerPage
        };
        getAllPendingForumList(data)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    if (res.data.data.forumDetails.length === 0 && forumPage >= 1) {
                        setForumPage(forumPage - 1);
                    }
                    const filteredForum = res?.data?.data.forumDetails.filter(item => item.isDeleted === 0);
                    setAllForum(filteredForum || []);
                    setTotalForumPage(res?.data.data.totalCount);
                }
                else {
                    setAllForum([]);
                    setTotalForumPage(0);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    }, [forumPage, rowsForumPerPage, getAllPendingForumList]); // Add dependencies

    useEffect(() => {
        fetchForumData(); // Fetch forum data whenever forumPage changes
    }, [fetchForumData]); // Add fetchForumData to the dependency array


    //page Click for Forum
    const handleForumPageClick = ({ selected }) => {
        setForumPage(selected);
    };

    //slicedForum
    const slicedForum = allForum.slice(0, rowsForumPerPage); // Adjust slicing

    //calculate pagecount
    const pageCount2 = Math.ceil(totalForumPage / rowsPerPage);

    //Forum function -------------------------------------- Ended-------------------------------------------//

    //Gallery Function -----------------------------------started---------------------------------------------//


    //getAllPendingGalleryList
    const fetchGalleryData = useCallback(() => {
        var data = {
            page: galleryPage + 1,
            itemsPerPage: rowsGalleryPerPage,
            role: memberScope ? memberScope : ''
        };
        getAllPendingGalleryList(data)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    if (res.data.data.pendingDetails.length === 0 && galleryPage >= 1) {
                        setGalleryPage(galleryPage - 1);
                    }
                    setAllGallery(res?.data?.data?.pendingDetails || []);
                    setTotalGalleryPage(res?.data.data.totalCount);
                }
                else {
                    setAllGallery([]);
                    setTotalGalleryPage(0);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error)
            });
    }, [galleryPage, rowsGalleryPerPage, getAllPendingGalleryList, memberScope]);

    //effect to set page
    useEffect(() => {
        fetchGalleryData();
    }, [fetchGalleryData]);



    //page Click for gallery
    const handleGalleryPageClick = ({ selected }) => {
        setGalleryPage(selected);
    };

    //slicedGallery
    const [slicedGallery, setSlicedGallery] = useState([])
    useEffect(() => {
        setSlicedGallery(allGallery); // Adjust slicing
    }, [allGallery])

    //calculate pagecount
    const pageCount3 = Math.ceil(totalGalleryPage / rowsGalleryPerPage);

    //Gallery function -------------------------------------- Ended-------------------------------------------//

    return (
        <div>
            <TopHeader />
            {/* <Box>
                <div className='pt-lg-5 pt-3'>
                    <div className='pt-1'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Admin Approval</h2>
                        </div>
                    </div>
                </div>
            </Box> */}
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">Admin Approval</h2>
                </div>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <div className='p-md-5 p-3 overallPadding'>
                    <div className='row justify-content-center' >
                        <div className='col-md-12'>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    width: "100%",
                                    margin: "0 0",
                                    position: "sticky",
                                    top: "0",
                                    zIndex: "999",
                                    marginBlock: "10px",
                                }}
                            >
                                <Tabs
                                    position="static"
                                    sx={{
                                        backgroundColor: '#611f1e !important',
                                        mx: 'auto',
                                        width: '100%',
                                        display: 'flex',
                                        flexGrow: "1",
                                        "& .MuiButtonBase-root": {
                                            color: "white",  // Change the color of the scroll buttons
                                        }
                                    }}
                                    value={currentTabIndex}
                                    onChange={handleTabChange}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    textColor='white'
                                >
                                    <Tab label="Events" sx={{ width: memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 0 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 0 ? 'white' : '#611f1e !important'}` }} />
                                    <Tab label="Forums" sx={{ width:  memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 1 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 1 ? 'white' : '#611f1e !important'}` }} />
                                    <Tab label="Gallery" sx={{ width:  memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 2 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 2 ? 'white' : '#611f1e !important'}` }} />
                                    <Tab label="Announcements" sx={{ width:  memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 3 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 3 ? 'white' : '#611f1e !important'}` }} />
                                    {memberScope==='PORTAL_SUPER_ADMIN'&&<Tab label="Chapter" sx={{ width:  memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 4 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 4 ? 'white' : '#611f1e !important'}` }} />}
                                    {memberScope==='PORTAL_SUPER_ADMIN'&&<Tab label="SIG" sx={{ width:  memberScope!=='PORTAL_SUPER_ADMIN'?"25%":"16.6%", maxWidth: '100%', color: `${currentTabIndex === 5 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 5 ? 'white' : '#611f1e !important'}` }} />}
                                </Tabs>
                            </Grid>
                            <div>
                                {currentTabIndex === 0 && (
                                    <>
                                        <Box className='mt-5'>
                                            <TableContainer component={Paper} sx={{ boxShadow: 0 }} className='rounded-0'>
                                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                                    <EnhancedTableHead />
                                                    <TableBody>
                                                        {allEvent?.length > 0 ? (
                                                            allEvent.map((event, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={event._id}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor:
                                                                            index % 2 === 0 ? "#f0f0f0" : "white",
                                                                    }}
                                                                >
                                                                    <TableCell align="center">
                                                                        {event?.levelOfEvent}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <GetEventType id={event?.eventTypeId} />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {event.title}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {new Date(event.startDate).toLocaleDateString(
                                                                            "en-In",
                                                                            {
                                                                                year: "numeric",
                                                                                month: "2-digit",
                                                                                day: "2-digit",
                                                                            }
                                                                        )}
                                                                        {event.endDate ? (
                                                                            <>
                                                                                <br></br>to <br />
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {event.endDate
                                                                            ? new Date(event.endDate).toLocaleDateString(
                                                                                "en-In",
                                                                                {
                                                                                    year: "numeric",
                                                                                    month: "2-digit",
                                                                                    day: "2-digit",
                                                                                }
                                                                            )
                                                                            : ""}
                                                                    </TableCell>
                                                                    <TableCell align="center" >
                                                                        {event.eventMode}
                                                                    </TableCell>
                                                                    <TableCell align="center" >
                                                                        {event?.createdBy?.role === "PORTAL_SUPER_ADMIN" ||
                                                                            event?.createdBy?.role === "PORTAL_ADMIN" ? (
                                                                            "ELTAI"
                                                                        ) : event?.createdBy?.role ===
                                                                            "PORTAL_CHAPTER_ADMIN" ? (
                                                                            <GetChapterName id={event?.createdBy?.id} />
                                                                        ) : (
                                                                            <GetSIGName id={event?.createdBy?.id} />
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{
                                                                            verticalAlign: "middle", // Align content to the middle of the cell
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                            gap="8px"
                                                                            flexWrap="wrap"
                                                                        >
                                                                            {!(event.isDeleted === 2) && (
                                                                                <Tooltip title="View" placement="top">
                                                                                    <IconButton
                                                                                        aria-label="ViewIcon"
                                                                                        onClick={() => {
                                                                                            navigate("/VieweventFeed/" + event._id)
                                                                                        }}
                                                                                    >
                                                                                        <VisibilityIcon className="icon-vieweye-color" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={8} align="center">
                                                                    No Data Available
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <br />
                                            {allEvent?.length > 0 && (
                                                <ReactPaginate
                                                    pageCount={pageCount} // Calculate total pages
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={handleEventPageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    forcePage={page}
                                                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                                                    nextLabel={
                                                        pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                                                    }
                                                    disabledClassName={"disablebtn"}
                                                />
                                            )}
                                        </Box>
                                    </>
                                )}
                                {currentTabIndex === 1 && (
                                    <>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <Box className='mt-5'>
                                                <Paper sx={{ width: "100%" }}>
                                                    <TableContainer className='rounded-0'>
                                                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                                            <EnhancedForumTableHead />
                                                            <TableBody>
                                                                {slicedForum.length > 0 ? slicedForum.map((Forum, index) => (
                                                                    <TableRow
                                                                        hover
                                                                        key={Forum._id}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                        }}
                                                                    >
                                                                        <TableCell align="center">
                                                                            <CategoryName id={Forum.category} />
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {Forum.name}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {new Date(Forum.updatedDate).toLocaleDateString(
                                                                                "en-In",
                                                                                {
                                                                                    year: "numeric",
                                                                                    month: "2-digit",
                                                                                    day: "2-digit",
                                                                                })}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Tooltip title="view">
                                                                                <IconButton
                                                                                    aria-label="EditIcon"

                                                                                    onClick={() => navigate("/readMoreForum/" + Forum._id)}
                                                                                >
                                                                                    <VisibilityIcon className='icon-vieweye-color' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )) : (<><TableRow><TableCell colSpan={6}><p className='text-black text-center'>No Data Available</p></TableCell></TableRow></>)}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                                <br />
                                                {slicedForum.length > 0 ? (
                                                    <div className="mb-4">
                                                        <ReactPaginate
                                                            pageCount={pageCount2} // Calculate total pages
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={handleForumPageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            previousLabel={pageCount2 > 1 && forumPage >= 1 ? "Previous" : null}
                                                            nextLabel={pageCount2 > 1 && forumPage + 1 !== pageCount2 ? "Next" : null}
                                                            disabledClassName={"disablebtn"}
                                                            forcePage={forumPage}
                                                        />
                                                    </div>
                                                ) : (<></>)}
                                                <br />
                                            </Box>
                                        )}
                                    </>
                                )}
                                {currentTabIndex === 2 && (
                                    <>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <Box className='mt-5' >
                                                <Paper sx={{ width: "100%" }}>
                                                    {/* <EnhancedGalleryTableToolbar /> */}
                                                    <TableContainer className='rounded-0'>
                                                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                                            <EnhancedGalleryTableHead />
                                                            <TableBody>
                                                                {slicedGallery.length > 0 ? (
                                                                    slicedGallery.map((gallery, index) => (
                                                                        <TableRow
                                                                            hover
                                                                            key={gallery._id}
                                                                            sx={{
                                                                                cursor: "pointer",
                                                                                backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                                                                            }}
                                                                        >
                                                                            <TableCell align="center">
                                                                                <img src={gallery?.imageUrl} width={"150px"} height={"150px"} alt='gallery' />
                                                                            </TableCell>
                                                                            <TableCell width={200} align="center">
                                                                                {gallery.description}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <Tooltip title="View">
                                                                                    <IconButton
                                                                                        aria-label="EditIcon"

                                                                                        onClick={
                                                                                            memberScope === "PORTAL_SUPER_ADMIN"
                                                                                                ? () => navigate("/admin/approveRejectGallery/" + gallery.mainGalleryId)
                                                                                                : () => navigate("/assistantAdmin/approveRejectGallery/" + gallery.mainGalleryId)
                                                                                        }
                                                                                    >
                                                                                        <VisibilityIcon className='icon-vieweye-color' />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                ) : <><TableRow><TableCell colSpan={3}><p className='text-black text-center'>No Data Available</p></TableCell></TableRow></>}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                                <br />
                                                {slicedGallery.length > 0 ? (
                                                    <div className="mb-4">
                                                        <ReactPaginate
                                                            pageCount={pageCount3} // Calculate total pages
                                                            onPageChange={handleGalleryPageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            previousLabel={pageCount3 > 1 && galleryPage >= 1 ? "Previous" : null}
                                                            nextLabel={pageCount3 > 1 && galleryPage + 1 !== pageCount3 ? "Next" : null}
                                                            disabledClassName={"disablebtn"}
                                                            forcePage={galleryPage}
                                                        />
                                                    </div>
                                                ) : (<></>)}
                                                <br />
                                            </Box>
                                        )}
                                    </>
                                )}
                                {currentTabIndex === 3 && (
                                    <Box className='mt-5'>
                                        <Paper sx={{ mb: 1 }}>
                                            <TableContainer className='rounded-0'>
                                                <Table aria-labelledby="tableTitle">
                                                    <EnhancedAnnouncementTableHead />
                                                    <TableBody>
                                                        {announcements.length > 0 ? (
                                                            announcements.map((announcement, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={announcement._id}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        backgroundColor:
                                                                            index % 2 === 0 ? "#f0f0f0" : "white",
                                                                    }}
                                                                >

                                                                    <TableCell align="center" className='text-truncate'
                                                                        style={{ maxWidth: "140px" }}
                                                                        title={sanitizeEditorContent(announcement.text)} // Adds sanitized content as a tooltip
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: sanitizeEditorContent(announcement.text).slice(0, 50) + "...", // Truncated sanitized content
                                                                        }}
                                                                    />
                                                                    {/* <TableCell align="center">
                                                                        {announcement.rejectionReason ? announcement.rejectionReason : "-"}
                                                                    </TableCell> */}
                                                                    <TableCell align="center">
                                                                        {announcement.isApproved === 1 && (
                                                                            <span style={{ color: "Green", fontWeight: "600" }}>
                                                                                Approved
                                                                            </span>
                                                                        )}
                                                                        {announcement.isApproved === 0 && (
                                                                            <span
                                                                                style={{
                                                                                    color: "#766bcb",
                                                                                    fontWeight: "600",
                                                                                }}
                                                                            >
                                                                                Pending
                                                                            </span>
                                                                        )}
                                                                        {announcement.isApproved === 2 && (
                                                                            <span style={{ color: "Red", fontWeight: "600" }}>
                                                                                Rejected
                                                                            </span>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {!(announcement.isDeleted === 2) && (
                                                                            <Tooltip title="View">
                                                                                <IconButton
                                                                                    aria-label="viewIcon"
                                                                                    onClick={() =>
                                                                                        handleView(announcement._id)
                                                                                    }
                                                                                >
                                                                                    <VisibilityIcon className="icon-vieweye-color" />

                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {((memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN")
                                                                            ? announcement?.createdBy?.id === userId &&
                                                                            (announcement?.isApproved === 0 || announcement?.isApproved === 2)
                                                                            : Object.keys(roleDetails).length !== 0
                                                                                ? announcement?.createdBy?.id === userId &&
                                                                                (announcement?.isApproved === 0 || announcement?.isApproved === 2)
                                                                                : announcement?.createdBy?.id === userId &&
                                                                                (announcement?.isApproved === 0 || announcement?.isApproved === 2)) && (
                                                                                <>

                                                                                    <Tooltip title="Edit">
                                                                                        <IconButton aria-label="edit">
                                                                                            <Link
                                                                                                to={
                                                                                                    memberScope === "PORTAL_SUPER_ADMIN"
                                                                                                        ? "/admin/addEditImport-Announment/" + announcement?._id
                                                                                                        : memberScope === "PORTAL_ADMIN"
                                                                                                            ? "/assistantAdmin/addEditImport-Announment/" + announcement?._id
                                                                                                            : memberScope === "PORTAL_SIG_ADMIN"
                                                                                                                ? "/sigAdmin/addEditImport-Announment/" + announcement?._id
                                                                                                                : "/chapterAdmin/addEditImport-Announment/" + announcement?._id
                                                                                                }
                                                                                            >
                                                                                                {!(announcement.isDeleted === 2) && (
                                                                                                    <EditIcon style={{ color: "green" }} />
                                                                                                )}
                                                                                            </Link>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    {announcement?.isDeleted === 0 ? (
                                                                                        <Tooltip title="Enable">
                                                                                            <IconButton
                                                                                                aria-label="enable"
                                                                                                onClick={() => {
                                                                                                    handleEnableDisable(announcement._id);
                                                                                                }}
                                                                                            >
                                                                                                <LockOpenIcon style={{ color: "green" }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    ) : announcement?.isDeleted === 2 ? (
                                                                                        <Tooltip title="Disable">
                                                                                            <IconButton
                                                                                                aria-label="disable"
                                                                                                onClick={() => {
                                                                                                    handleEnableDisable(announcement._id);
                                                                                                }}
                                                                                            >
                                                                                                <LockIcon style={{ color: "red" }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    ) : null}
                                                                                    <Tooltip title="Delete">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            onClick={() => {
                                                                                                handleOpenModal(announcement._id);
                                                                                            }}
                                                                                        >
                                                                                            <DeleteIcon style={{ color: "red" }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </>
                                                                            )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <>
                                                                <TableRow hover>
                                                                    <TableCell colSpan={5} align="center">
                                                                        No Data Available
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </TableBody>
                                                    <Modal
                                                        open={openModal}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box

                                                            className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                width: 400,
                                                                backgroundColor: 'white',
                                                                boxShadow: 24,
                                                                padding: "20px",
                                                                outline: 'none',
                                                            }}
                                                        >
                                                            <Container maxWidth={false} sx={{ px: 3 }}>
                                                                <Box className="d-flex justify-content-between pt-2">
                                                                    <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                                                                        Confirmation
                                                                    </Typography>
                                                                    <IconButton onClick={handleClose} aria-label="Close">
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Container>
                                                            <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                                                                <Divider sx={{ bgcolor: "black", height: "2px", width: "66vh", marginTop: "-15px" }} />
                                                            </Box>
                                                            <Typography
                                                                id="modal-modal-title"
                                                                style={{ color: "#611f1e" }}
                                                                variant="h6"
                                                                component="h2"
                                                            >
                                                                Are You Surely Want to Delete this Announcement?
                                                            </Typography>
                                                            <div>
                                                                <Button
                                                                    variant="outlined"
                                                                    style={{
                                                                        color: "#dc3545",
                                                                        border: "1px solid #dc3545",
                                                                        padding: "5px 15px",
                                                                        borderRadius: "4px",
                                                                    }}
                                                                    onClick={handleClose}
                                                                >
                                                                    No
                                                                </Button>
                                                                <Button
                                                                    className="mx-2"
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: "#2e7d32",
                                                                        //  border: "1px solid #2e7d32",
                                                                        color: "white",
                                                                        padding: "5px 15px",
                                                                        borderRadius: "4px",
                                                                    }}
                                                                    onClick={() => handleDelete()}
                                                                >
                                                                    Yes
                                                                </Button>
                                                            </div>
                                                        </Box>
                                                    </Modal>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box
                                                            component={"form"}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                width: 400,
                                                                transform: 'translate(-50%, -50%)',
                                                                backgroundColor: 'white',
                                                                boxShadow: 24,
                                                                padding: "20px",
                                                                outline: 'none',
                                                            }}
                                                            className="d-flex align-items-center flex-column gap-3"
                                                        >
                                                            <Typography
                                                                component={"h2"}
                                                                className="text-center fs-4 fw-bold text-danger"
                                                            >
                                                                Reject Announcement
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                inputProps={{ maxLength: "300", marginTop: 5 }}
                                                                label={
                                                                    <Typography className="text-dark">
                                                                        Reason For Rejection{" "}
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </Typography>
                                                                }
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {300 - watch('rejectionReason').length}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                error={!!errors?.rejectionReason}
                                                                helperText={errors?.rejectionReason?.message}
                                                                {...registerReject("rejectionReason")}
                                                            />
                                                            <div className="mt-3 mx-auto">
                                                                <Button
                                                                    variant="outlined"
                                                                    color="error"
                                                                    onClick={handleClose}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    className="mx-2"
                                                                    variant="contained"
                                                                    color="success"
                                                                    error
                                                                    onClick={() => handleApproveReject({ id: getAnnouncement?._id, type: 1 })}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </Box>
                                                    </Modal>

                                                    {/* <Modal
                                                        open={show}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box
                                                            component={"form"}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                // width: 400,
                                                                transform: 'translate(-50%, -50%)',
                                                                backgroundColor: 'white',
                                                                boxShadow: 24,
                                                                padding: "20px",
                                                                outline: 'none',
                                                            }}
                                                            className="d-flex align-items-center flex-column gap-3"
                                                        >
                                                            <IconButton
                                                                onClick={handleClose}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 8,
                                                                    right: 8,
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                            <h1 className="modal-title" style={{ color: '#104e29' }}>View Announcement</h1>

                                                            <p
                                                                style={{
                                                                    wordWrap: "break-word",
                                                                    wordBreak: "break-word",
                                                                    overflowWrap: "break-word",
                                                                    whiteSpace: "pre-wrap",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: sanitizeEditorContent(getAnnouncement?.text), // Truncated sanitized content
                                                                }}
                                                                className="text-justify">

                                                            </p>

                                                            <div className="modal-footer d-flex justify-content-between">
                                                                {(getAnnouncement?.isApproved === 0 && (memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN")) && (
                                                                    <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            style={{
                                                                                backgroundColor: "red",
                                                                                color: "white"
                                                                            }}
                                                                            onClick={() => {
                                                                                handleOpen(getAnnouncement._id);
                                                                                handleShowClose();
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            style={{
                                                                                backgroundColor: "green",
                                                                                color: "white"
                                                                            }}
                                                                            onClick={() => {
                                                                                handleApproveReject({ id: getAnnouncement?._id, type: 0 });
                                                                                handleShowClose();
                                                                            }}
                                                                        >
                                                                            Approve
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Box>
                                                    </Modal> */}
                                                    <Modal
                                                        open={show}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box

                                                            className="d-flex align-items-center flex-column gap-3 overflow-x-hidden"
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                width: 600,
                                                                backgroundColor: 'white',
                                                                boxShadow: 24,
                                                                padding: "20px",
                                                                outline: 'none',
                                                            }}
                                                        >
                                                            <Container maxWidth={false} sx={{ px: 3 }}>
                                                                <div className='mt-3'>
                                                                    {/* <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        className='alata-bold text-center w-100 menus-green-color'
                      >
                        View Announcement
                      </Typography> */}
                                                                    <Box className="d-flex justify-content-between pt-2">
                                                                        <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                                                                            View Announcement
                                                                        </Typography>
                                                                        <IconButton onClick={handleClose} aria-label="Close">
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                    <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                                                                        <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                                                    </Box>
                                                                    <p

                                                                        dangerouslySetInnerHTML={{
                                                                            __html: sanitizeEditorContent(getAnnouncement?.text), // Truncated sanitized content
                                                                        }}
                                                                        className="text-justify announcement-text-wrap">

                                                                    </p>
                                                                    {getAnnouncement?.isApproved === 0 && (memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") ? (
                                                                        <div className="d-flex justify-content-center w-100" style={{ gap: "10px" }}>
                                                                            {/* <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                              <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                            </Box> */}
                                                                            <div className='d-flex gap-2'>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        backgroundColor: "red",
                                                                                        color: "white"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleOpen(getAnnouncement._id);
                                                                                        handleShowClose();
                                                                                    }}
                                                                                >
                                                                                    Reject
                                                                                </Button>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        backgroundColor: "green",
                                                                                        color: "white"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        handleApproveReject({ id: getAnnouncement?._id, type: 0 });
                                                                                        handleShowClose();
                                                                                    }}
                                                                                >
                                                                                    Approve
                                                                                </Button>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                    ) :
                                                                        <></>
                                                                    }
                                                                    <div className='d-flex justify-content-center'>
                                                                    </div>
                                                                </div>
                                                            </Container>
                                                        </Box>

                                                    </Modal>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                        <br></br>
                                        <div className="w-100 d-flex justify-content-center pb-4">
                                            {announcements?.length > 0 && (
                                                <ReactPaginate
                                                    pageCount={pageCount5} // Calculate total pages
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    forcePage={page}
                                                    previousLabel={pageCount5 > 1 && page >= 1 ? "Previous" : null}
                                                    nextLabel={
                                                        pageCount5 > 1 && page + 1 !== pageCount5 ? "Next" : null
                                                    }
                                                    disabledClassName={"disablebtn"}
                                                />
                                            )}
                                        </div>
                                        <br></br>
                                    </Box>
                                )}

                                {currentTabIndex === 4 && (
                                    <ChapterPendingTable />
                                )}
                                {currentTabIndex === 5 && (
                                    <SigPendingTable />
                                )}
                            </div>
                        </div>
                    </div>
                </div>)
            }

        </div >
    )
}

export default AdminApproval