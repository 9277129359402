import React, { useState, useEffect, useCallback } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, IconButton, Typography, Modal } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import {
  usePaginationImportantAnnouncementMutation, useDeleteImportantAnnouncementMutation,
  useLazyGetImportantAnnouncementEnableDisableQuery, useLazyGetImportantAnnouncementDetailsByIdQuery,
  useGetApproveRejectImportantAnnouncementMutation
} from '../../../app/services/importantAnnouncementService';
import ReactPaginate from "react-paginate";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  selectCurrentScope,
  selectUserId,
} from "../../../app/auth/authSlice";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { selectCurrenChapterRole, setRoleDetails } from "../../../app/features/chapterAdminSlice";
import { Container, Divider, FormControl, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import DOMPurify from "dompurify";
import GetChapterName from "../../../Component/HomePage/GetChapterName";
import GetSIGName from "../../../Component/HomePage/GetSigName";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";

const ImportantAnnouncementTable = () => {
  const [paginationAnnouncement] = usePaginationImportantAnnouncementMutation();
  const [announcements, setAnnouncements] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10); //state for item per page
  const [error, setError] = useState("")
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [textopen, setTextOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [text, setText] = useState('')
  const [deleteAnnouncement] = useDeleteImportantAnnouncementMutation()
  const [EnableDisable] = useLazyGetImportantAnnouncementEnableDisableQuery()
  const [getAnnouncementById] = useLazyGetImportantAnnouncementDetailsByIdQuery()
  const [show, setShow] = useState(false);
  const [getAnnouncement, setGetAnnouncement] = useState()
  const [selectedAnnounment, setSelectedAnnouncement] = useState()
  const [approveReject] = useGetApproveRejectImportantAnnouncementMutation()
  const [openModal, setOpenModal] = useState(false);
  const userId = useSelector(selectUserId);
  const roleDetails = useSelector(selectCurrenChapterRole);
  const chapterRole = useSelector(setRoleDetails)
  const location = useLocation();
  const { chapterId } = useParams(); //get Id from params
  const {sigGroupId}=useParams()
  const [searchTerm, setSearchTerm] = useState('');


  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleOpen1 = (announcement) => {
    setOpen1(true);
    setSelectedAnnouncement(announcement);
  };

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setSelectedMember(id);
  };

  const handleTextOpen = (text) => {
    setTextOpen(true);
    setText(text)
  };

  const handleClose = () => {
    setOpen(false)
    setOpenModal(false);
    setShow(false)
  };
  const handleClose1 = () => {
    setOpen1(false)
  };

  const memberScope = useSelector(selectCurrentScope);
  const handleTextClose = () => {
    setTextOpen(!textopen)
  }

  // Memoized fetchAnnouncements using useCallback
  const fetchAnnouncements = useCallback(async () => {
    const data = {
        page: page + 1,
        itemsPerPage: rowsPerPage,
        ...(location.pathname.includes('/view-Chapter') && { chapterId }),
        ...(location.pathname.includes('/viewSigDetails') && { sigId: sigGroupId }),
        ...(memberScope && !location.pathname.includes('/view-Chapter') && !location.pathname.includes('/viewSigDetails') && { role: memberScope })
    };

    try {
        const res = await paginationAnnouncement(data);
        if (res?.data?.status) {
            setAnnouncements(res.data.data.newAnnouncementDetails);
            setTotalPage(res.data.data?.totalCount || 0);
        }
    } catch (error) {
        console.error("Error fetching announcements:", error);
    }
}, [page, rowsPerPage, paginationAnnouncement, memberScope, chapterId, sigGroupId, location.pathname]);

  // Trigger the memoized fetchAnnouncements function when page or rowsPerPage changes
  useEffect(() => {
    console.log('hi')
    fetchAnnouncements();
  }, [fetchAnnouncements]); // Call fetchAnnouncements whenever the callback updates
  useEffect(() => {
  }, [announcements])

  //table
  const EnhancedNewsTableHead = () => {
    const headCells = [
      // { id: "Heading", label: "Heading" },
      { id: "text", label: "Text" },
      { id: "createdBy", label: "Created By" },
      { id: "status", label: "Status" },
      { id: "actions", label: "Actions" }
    ];

    return (
      <TableHead>
        <TableRow className='menus-color'>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              padding="normal"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  //PageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  const handleShowClose = () => setShow(false);

  const handleView = (id) => {
    setShow(!show)
    getAnnouncementById(id).then((res) => {
      if (res?.data?.status) {
        setGetAnnouncement(res?.data?.data)
      }
    })
  }

  //handleDelete
  const handleDelete = () => {
    deleteAnnouncement(selectedMember).then((res) => {
      if (res.data?.status) {
        toast.error(res.data.message);
        handleClose();
        if (announcements.length <= 1) {
          setPage(page - 1);
          fetchAnnouncements()
        } else {
          fetchAnnouncements()
        }
      } else {
        toast.error(res.data?.err?.message);
      }
    });
  }

  const sanitizeEditorContent = (html) => {
    // Allow only specific tags for styles (e.g., b, i, u, strong, em)
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ["b", "i", "u", "strong", "em"],
      ALLOWED_ATTR: [], // Disallow all attributes
    });
  };


  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id).then((res) => {
      if (res?.data?.data?.isDeleted === 0) {
        fetchAnnouncements()
        toast.success(res?.data?.message);
      } else {
        fetchAnnouncements()
        toast.error(res?.data?.message);
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);
  const {
    register: registerReject,
    formState: { errors },
    getValues,
    clearErrors, watch
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  //Approve
  const handleApproveReject = ({ id, type }) => {
    const rejectionReason = getValues("rejectionReason");
    let data;
    if (type === 0) {
      data = {
        newAnnouncementsId: id,
        type: type,
      };
    }
    if (type === 1) {
      if (
        rejectionReason === null ||
        rejectionReason === undefined ||
        rejectionReason === ""
      ) {
        return;
      } else {
        clearErrors();
        data = {
          newAnnouncementsId: id,
          type: type,
          rejectionReason,
        };
      }
    }
    approveReject(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        fetchAnnouncements()
        handleClose();
      }
    })
  }
  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > totalPage) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };
  return (
    <div>
      <Box>
        {!location.pathname.includes('/view-Chapter') && 
        !location.pathname.includes('/viewSigDetails') &&<div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">Announcement List</h2>
          </div>
        </div>}
        <div className='p-md-5 p-3 overallPadding '>
          {
            !location.pathname.includes('/view-Chapter') &&
            !location.pathname.includes('/viewSigDetails') && (
              <div className="text-end w-100">
                <Link to={
                  memberScope === "PORTAL_SUPER_ADMIN"
                    ? "/admin/addEditImport-Announment"
                    : memberScope === "PORTAL_ADMIN"
                      ? "/assistantAdmin/addEditImport-Announment"
                      : memberScope === "PORTAL_SIG_ADMIN"
                        ? "/sigAdmin/addEditImport-Announment"
                        : "/chapterAdmin/addEditImport-Announment"
                } className='mt-5'>
                  <Button variant="contained" className="menus-color" style={{ color: "white", marginBottom: "15px" }}>
                    Add Announcement
                  </Button>
                </Link>
              </div>
            )
          }

          <Box>
            {(announcements.length > 0 && !location?.pathname.includes('/view-Chapter')) ?
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedNewsTableHead />
                  <TableBody>
                    {announcements.length > 0 ? (
                      announcements.map((announcement, index) => (
                        <TableRow
                          hover
                          key={announcement._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell
                            align="center"
                            className="text-truncate"
                            onClick={() => handleTextOpen(sanitizeEditorContent(announcement.text))}
                            style={{
                              maxWidth: "140px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                            }}
                            title={sanitizeEditorContent(announcement.text)} // Adds sanitized content as a tooltip
                            dangerouslySetInnerHTML={{
                              __html: sanitizeEditorContent(announcement.text).slice(0, 50) + "...", // Truncated sanitized content
                            }}
                          />
                          <TableCell align="center">  {announcement?.createdBy?.role === "PORTAL_SUPER_ADMIN" ||
                            announcement?.createdBy?.role === "PORTAL_ADMIN" ? (
                            "ELTAI"
                          ) : announcement?.createdBy?.role ===
                            "PORTAL_CHAPTER_ADMIN" ? (
                            <GetChapterName id={announcement?.createdBy?.id} />
                          ) : (
                            <GetSIGName id={announcement?.createdBy?.id} />
                          )}</TableCell>
                          <TableCell align="center">
                            {announcement.isApproved === 1 && (
                              <span style={{ color: "Green", fontWeight: "600" }}>
                                Approved
                              </span>
                            )}
                            {announcement.isApproved === 0 && (
                              <span
                                style={{
                                  color: "#766bcb",
                                  fontWeight: "600",
                                }}
                              >
                                Pending
                              </span>
                            )}
                            {announcement.isApproved === 2 && (
                              <p onClick={() => handleOpen1(announcement)} className='text-primary fw-bold text-danger text-decoration-underline'>
                                Rejected
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {!(announcement.isDeleted === 2) && (
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="viewIcon"
                                  onClick={() =>
                                    handleView(announcement._id)
                                  }
                                >
                                  <VisibilityIcon className="icon-vieweye-color" />

                                </IconButton>
                              </Tooltip>
                            )}
                            {(memberScope === "PORTAL_SUPER_ADMIN"
                              ? announcement?.createdBy?.id === userId &&
                              (announcement?.isApproved === 0 || announcement?.isApproved === 2)

                              : Object?.keys(roleDetails).length !== 0 || Object.keys(chapterRole?.payload?.chapter?.roleDetails).length !== 0
                                ? announcement?.createdBy?.id === userId &&
                                (announcement?.isApproved === 0 || announcement?.isApproved === 2)
                                : announcement?.createdBy?.id === userId &&
                                (announcement?.isApproved === 0 || announcement?.isApproved === 2)) && (
                                <>
                                  {!(announcement.isDeleted === 2) && (
                                    <Tooltip title="Edit">
                                      <IconButton aria-label="edit">
                                        <Link
                                          to={
                                            memberScope === "PORTAL_SUPER_ADMIN"
                                              ? "/admin/addEditImport-Announment/" + announcement?._id
                                              : memberScope === "PORTAL_ADMIN"
                                                ? "/assistantAdmin/addEditImport-Announment/" + announcement?._id
                                                : memberScope === "PORTAL_SIG_ADMIN"
                                                  ? "/sigAdmin/addEditImport-Announment/" + announcement?._id
                                                  : "/chapterAdmin/addEditImport-Announment/" + announcement?._id
                                          }
                                        >
                                          <EditIcon style={{ color: "green" }} />
                                        </Link>
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {announcement?.isDeleted === 0 ? (
                                    <Tooltip title="Enable">
                                      <IconButton
                                        aria-label="enable"
                                        onClick={() => {
                                          handleEnableDisable(announcement._id);
                                        }}
                                      >
                                        <LockOpenIcon style={{ color: "green" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : announcement?.isDeleted === 2 ? (
                                    <Tooltip title="Disable">
                                      <IconButton
                                        aria-label="disable"
                                        onClick={() => {
                                          handleEnableDisable(announcement._id);
                                        }}
                                      >
                                        <LockIcon style={{ color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        handleOpenModal(announcement._id);
                                      }}
                                    >
                                      <DeleteIcon style={{ color: "red" }} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow hover>
                          <TableCell colSpan={5} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box

                      className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: "20px",
                        outline: 'none',
                      }}
                    >
                      <Container maxWidth={false} sx={{ px: 3 }}>
                        <Box className="d-flex justify-content-between pt-2">
                          <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                            Confirmation
                          </Typography>
                          <IconButton onClick={handleClose} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Container>
                      <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                        <Divider sx={{ bgcolor: "black", height: "2px", width: "66vh", marginTop: "-15px" }} />
                      </Box>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Announcement?
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#dc3545",
                            border: "1px solid #dc3545",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#2e7d32",
                            //  border: "1px solid #2e7d32",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    open={textopen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: "20px",
                        outline: 'none',
                      }}
                    >
                      {/* <div className='icon_cloe ' style={{ position: 'absolute', right: 8, top: 8 }}>
                      <IconButton
                        onClick={handleTextClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div> */}

                      <Container maxWidth={false} sx={{ px: 3 }}>
                        <Box className="d-flex justify-content-between pt-2">
                          <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                            View Announcement
                          </Typography>
                          <IconButton onClick={handleTextClose} aria-label="Close">
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                          <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                        </Box>

                        <div className='mt-4'>
                          <Typography
                            id="modal-modal-title"
                            style={{
                              textAlign: 'justify',
                              color: "#611f1e",
                              wordWrap: "break-word",
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                            dangerouslySetInnerHTML={{ __html: sanitizeEditorContent(text) }} // Renders sanitized HTML content
                          />
                        </div>
                      </Container>

                    </Box>
                  </Modal>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      component={"form"}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "50%",
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: "20px",
                      }}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        component={"h2"}
                        className="text-center fs-4 fw-bold text-danger"
                      >
                        Reject Announcement
                      </Typography>
                      <TextField
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Reason For Rejection{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        type='text'
                        inputProps={{ maxLength: "300" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {300 - watch('rejectionReason')?.length}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.rejectionReason}
                        helperText={errors?.rejectionReason?.message}
                        {...registerReject("rejectionReason")}
                      />
                      <div className="mt-3 mx-auto">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          color="success"
                          error
                          onClick={() => handleApproveReject({ id: getAnnouncement?._id, type: 1 })}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    open={show}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box

                      className="d-flex align-items-center flex-column gap-3 overflow-x-hidden"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: "20px",
                        outline: 'none',
                      }}
                    >
                      <Container maxWidth={false} sx={{ px: 3 }}>
                        <div className='mt-3'>
                          {/* <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        className='alata-bold text-center w-100 menus-green-color'
                      >
                        View Announcement
                      </Typography> */}
                          <Box className="d-flex justify-content-between pt-2">
                            <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                              View Announcement
                            </Typography>
                            <IconButton onClick={handleClose} aria-label="Close">
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                          </Box>
                          <p

                            dangerouslySetInnerHTML={{
                              __html: sanitizeEditorContent(getAnnouncement?.text), // Truncated sanitized content
                            }}
                            className="text-justify announcement-text-wrap">

                          </p>
                          {getAnnouncement?.isApproved === 0 && (memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") ? (
                            <div className="d-flex justify-content-center w-100" style={{ gap: "10px" }}>
                              <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                                <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                              </Box>
                              <div className='d-flex gap-2'>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "red",
                                    color: "white"
                                  }}
                                  onClick={() => {
                                    handleOpen(getAnnouncement._id);
                                    handleShowClose();
                                  }}
                                >
                                  Reject
                                </Button>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "green",
                                    color: "white"
                                  }}
                                  onClick={() => {
                                    handleApproveReject({ id: getAnnouncement?._id, type: 0 });
                                    handleShowClose();
                                  }}
                                >
                                  Approve
                                </Button>
                              </div>
                              <div>

                              </div>
                            </div>
                          ) :
                            <></>
                          }
                          <div className='d-flex justify-content-center'>
                          </div>
                        </div>
                      </Container>
                    </Box>

                  </Modal>


                </Table>
              </TableContainer> :
              (
                <p className="text-black fs-2 text-capitalize lead text-center">
                  No Data Available
                </p>
              )
            }
            <br />
            <br />
            {(Object.keys(announcements)?.length !== 0) && <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
              <div className="mb-2 mb-md-0">
                <div className="d-flex gap-2">
                  <input
                    type="number"
                    min={1}
                    placeholder="Page no."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                  />
                  <div className="goPage cursor-pointer" onClick={handlePageChange}>
                    <h5 className="p-2 m-0">Go</h5>
                  </div>
                </div>
                {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
              </div>
              <div className="my-3 my-lg-0">
                <ReactPaginate
                  pageCount={pageCount} // Calculate total pages
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  forcePage={page}
                  previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                  nextLabel={
                    pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                  }
                  disabledClassName={"disablebtn"}
                />

              </div>
              <div className="mb-2 mb-md-0">
                <FormControl variant="outlined">
                  <Select
                    className="itemsperpage"
                    value={rowsPerPage}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRowsPerPage(value);
                      setError('')
                      setSearchTerm('')
                      setPage(0)
                    }}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                      },
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            }
          </Box>
        </div>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box

            className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              backgroundColor: 'white',
              boxShadow: 24,
              padding: "20px",
              outline: 'none',
            }}
          >
            <Container maxWidth={false} sx={{ px: 3 }}>
              <Box className="d-flex justify-content-between pt-2">
                <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                  Rejection Reason
                </Typography>
                <IconButton onClick={handleClose1} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
              </Box>
            </Container>
            {selectedAnnounment?.rejectionReason}
            <div>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default ImportantAnnouncementTable;