import React, { useCallback, useEffect, useState } from 'react'
import { usePaginationGalleryMutation } from '../../../app/services/galleryService'
import Spinner from '../../../Component/spinner/spinner'
import ReactPaginate from "react-paginate";
import { Button, ImageListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function ViewGallery() {
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([])
  let [totalCount, setTotalCount] = useState(0);
  const [getAllGallery] = usePaginationGalleryMutation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [error, setError] = useState("");
  const getAllGalleries = useCallback(() => {
    const data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      role: ""
    }
    setLoading(true)
    getAllGallery(data).then(res => {
      setLoading(false)
      if (res.data?.status) {
        setTotalCount(res?.data?.data?.totalCount)
        setGallery(res.data?.data?.galleryDetails)
      }
    })
  }, [getAllGallery, page, itemsPerPage])
  useEffect(() => {
    getAllGalleries()
  }, [getAllGalleries])

  //handle page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  return (
    <div className='Architects-page-containter pt-lg-5 pt-1'>
      {/* <TopHeader /> */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="pt-3">
            <div className="chapter-heading">
              <h2 className="text-center title-font text-light py-3 mt-1">Galleries</h2>
            </div>
          </div>
          <div className='p-md-5 p-3  overallPadding'>
            <div className='row '>
              <div className='col-md-8'></div>
              <div className='col-md-4'>
                <Button onClick={() => window.history.back()} variant="contained" className='menus-color'
                  style={{ float: 'right', marginRight: 30, borderRadius: 0 }}>Back</Button>
              </div>
            </div>
            <div className="row mx-auto mt-3">
              {gallery.length > 0 ?
                gallery.map((imgs, index) => (
                  <>
                    <div key={index} className="col-md-4 mb-2" onClick={() => navigate('/showGallery/' + imgs._id)}>
                      <ImageListItem>
                        <img
                          style={{ height: '250px', cursor: 'pointer' }}
                          src={imgs?.list[0]?.imageUrl}
                          alt={imgs?.list[0]?.category}
                        />
                        <span
                          style={{ top: "75%", color: "#611f1e", border: "3px soloid #a52a2a " }}
                          className="position-absolute start-50 translate-middle galleryBatch   mt-4 bg-white fs-5 content-font"
                        >
                          {imgs?.category}
                          <span class="visually-hidden">
                            Gallery Category
                          </span>
                        </span>
                      </ImageListItem>
                    </div>
                  </>
                )) : <>
                  <div className='text-center text-danger content-font'>No Data Available</div>
                </>}
            </div>
          </div>
        </>
      )}
      {gallery?.length > 0 ? (
        <div className="overallPadding d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", paddingBottom: "56px" }}>
          <div className="mb-2 mb-md-0">
            <div className="d-flex gap-2">
              <input
                type="number"
                min={1}
                placeholder="Page no."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <div className="goPage cursor-pointer" onClick={handlePageChange}>
                <h5 className="p-2 m-0">Go</h5>
              </div>
            </div>
            {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
          </div>

          {/* Pagination in the Center */}
          <div className="my-3 my-md-0">
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLabel={page > 0 ? "Previous" : null}
              nextLabel={page + 1 < pageCount ? "Next" : null}
              forcePage={page}
              disabledClassName={"disablebtn"}
            />
          </div>
          <div className="mb-2 mb-md-0">
            <FormControl variant="outlined">
              <InputLabel></InputLabel>
              <Select
                className="itemsperpage"
                value={itemsPerPage}
                onChange={(e) => {
                  const value = e.target.value;
                  setItemsPerPage(value);
                  setError('');
                  setSearchTerm('');
                  setPage(0);
                }}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      display: 'flex',
                      flexDirection: 'column',
                    },
                  },
                }}
              >
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={54}>54</MenuItem>
                <MenuItem value={99}>99</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ViewGallery