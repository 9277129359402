import React, { useEffect, useState, useCallback } from "react";
import "../style/css/chapter.css";
import "../style/css/pagination.css";
import { useGetAllChaptersPaginationMutation, useGetChapterBasedOnStateMutation, useLeaveChapterMutation } from "../app/services/chapterServices";
import ReactPaginate from "react-paginate"; // Import CSS file for pagination styles
import { selectCurrentScope } from "../app/auth/authSlice";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Modal, Tooltip, Typography, Button, IconButton, Divider, TextField, InputAdornment } from "@mui/material";
import { selectCurrentMember } from "../app/features/memberSlice";
import GroupIcon from "@mui/icons-material/Group";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from '@mui/icons-material/Login';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { selectCurrentChapterAdmin } from "../../src/app/features/chapterAdminSlice";
import { useSwitchChapterMutation, useLazyGetChapterDetailsByIdQuery, useDeleteChapterbyIdMutation } from "../app/services/chapterServices";
import { toast } from "react-toastify";
import "../style/css/viewChapter.css";
import userLogo from '../assets/images/placeholder/user_logo.jpg'
import placeholderImage from '../assets/images/placeholder/placeholder.png'
import DeleteIcon from "@material-ui/icons/Delete";
import LogoutIcon from "@mui/icons-material/Logout";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { indiaStates, Salutation } from "../constant/constant";
import { Controller, useForm } from "react-hook-form";
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Spinner from '../../src/Component/spinner/spinner'

function ViewChapter() {
  const [chapterBasedState] = useGetChapterBasedOnStateMutation();
  const [Allchapter] = useGetAllChaptersPaginationMutation();
  const [getChapterId] = useLazyGetChapterDetailsByIdQuery();
  const [deleteChapter] = useDeleteChapterbyIdMutation();
  const [memberCurrentChapterApproved, setMemberCurrentChapterApproved] = useState("");
  const chapterDatas = useSelector(selectCurrentChapterAdmin);
  const memberScope = useSelector(selectCurrentScope); //get scope
  const memberDetails = useSelector(selectCurrentMember); //get the member Details
  const [chapterData, setChapterData] = useState({});
  let [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false); //open and close state for dialog
  const [open1, setOpen1] = useState(false); //open and close state for dialog
  const [open2, setOpen2] = useState(false); //open and close state for dialog
  const [currentChapterId, setCurrentChapterId] = useState(""); //state for chapter Id
  const [selectedChapter, setSelectedChapter] = useState(""); //state for chapter Id
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };
  const [switchChapter] = useSwitchChapterMutation(); //API call of switch chapter
  const [oldChapterId, setOldChapterId] = useState(null);
  const [leaveChapter] = useLeaveChapterMutation();
  const navigate = useNavigate();
  const [states, setStates] = useState("All Chapters");
  const { control, watch, register, reset, setValue } = useForm({
    defaultValues: {
      state: 'All Chapters',
      searchTerm: ''
    }
  })
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);

  //Get chapter details
  const fetchChapterData = useCallback(() => {
    if (memberDetails?.chapter) {
      getChapterId(memberDetails?.chapter).then((res) => {
        if (res?.data?.status) {

        } else if (res.data?.err) {
          // setLoading(false);
          setMemberCurrentChapterApproved("");
        }
      });
    } else {
      setMemberCurrentChapterApproved("");
    }
  }, [getChapterId, memberDetails?.chapter]);

  useEffect(() => {
    if (memberDetails?.chapter !== "") {
      fetchChapterData(); // Call the function to fetch chapter data
    } else {
      const oldChapter = chapterData?.chapterData?.find((item) => {
        return item?.membersId?.find((t) => (t?.id === memberDetails?._id && (t.isApproved === 0 || t.isApproved === 1)));
      });
      // if (oldChapter) {
      setOldChapterId(oldChapter?._id);
      for (let i = 0; i < oldChapter?.membersId.length; i++) {
        if (memberDetails?._id === oldChapter.membersId[i].id) {
          setMemberCurrentChapterApproved(oldChapter.membersId[i]?.isApproved);
        }
      }
      // } else {
      //   setOldChapterId('')
      // }
    }
  }, [fetchChapterData, memberDetails?.chapter, chapterData, memberDetails]);

  useEffect(() => { }, [oldChapterId]);

  const getChaptersBasedOnState = useCallback((pageNumber) => {
    setLoading(true)
    setStates(watch('state'))
    if (watch('state') !== "All Chapters") {
      let data = {
        state: watch('state'),
        searchTerm: watch('searchTerm') ?? '',
        page: pageNumber ?? page + 1,
        itemsPerPage: itemsPerPage
      };
      chapterBasedState(data).then((res) => {
        setLoading(false)
        if (res?.data?.status) {
          setChapterData(res?.data?.data);
          setTotalCount(res?.data?.data?.totalCount || 0)
        } else {
          setChapterData({})
        }
      });
    }
    else {
      var data = {
        page: pageNumber ?? page + 1,
        itemsPerPage: itemsPerPage,
        searchTerm: watch('searchTerm') ?? ''
      }
      Allchapter(data).then((res) => {
        setLoading(false)
        if (res?.data?.status) {
          setChapterData(res?.data?.data);
          setTotalCount(res?.data?.data?.totalCount || 0);
        } else {
          setChapterData({})
        }
      })
    }
  }, [Allchapter, chapterBasedState, itemsPerPage, page, watch]);


  const handleNavigate = () => {
    navigate("/chapterForm");
  };

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    setValue('searchTerm', '')
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > totalPages) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  const handleAgree = () => {
    const data = {
      chapterId: currentChapterId,
      memberId: memberDetails?._id,
      oldChapterId: memberDetails?.chapter
        ? memberDetails?.chapter
        : oldChapterId,
      // oldChapterId: '',
      alreadyJoinedChapter: memberCurrentChapterApproved,
    };
    if (currentChapterId) {
      // setLoading(true);
      handleClose();
      switchChapter(data).then((res) => {
        if (res.data?.status) {
          // setLoading(false);
          // setValue('searchTerm', '')
          getChaptersBasedOnState();
          toast.success(res.data?.message);
        } else {
          toast.error(res?.data?.err?.message);
        }
      });
    }
  };

  const handleOpen1 = (id) => {
    setCurrentChapterId(id);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  }
  const handleOpen2 = (chapter) => {
    setSelectedChapter(chapter);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  }

  const handleDelete = () => {
    const data = { id: currentChapterId };

    deleteChapter(data)
      .then((res) => {
        if (res?.data?.status) {
          toast.success('Chapter Deleted Successfully!!!');
          // Check if chapterData is empty or null
          if (chapterData?.chapterData?.length <= 1) {
            if (page !== 0) {
              setPage((prev) => prev - 1);
            }
            getChaptersBasedOnState();
          }
          else {
            // Fetch updated chapters regardless of whether the page was changed
            getChaptersBasedOnState();
          }
          handleClose1(); // Close the modal
        } else {
          toast.error('Chapter Delete Failed!!!');
        }
      })
      .catch((error) => {
        toast.error('Chapter Delete Failed!!!' + error);
      });
  };

  //Modal usage
  const handleOpen = (id) => {
    setCurrentChapterId(id);
    const data = {
      chapterId: id,
      memberId: memberDetails?._id,
      oldChapterId: memberDetails?.chapter
        ? memberDetails?.chapter
        : oldChapterId,
      alreadyJoinedChapter: memberCurrentChapterApproved,
    };
    if (memberDetails?.chapter || oldChapterId) {
      setOpen(true);
    } else {
      // setLoading(true);
      switchChapter(data).then((res) => {
        if (res.data?.status) {
          // setLoading(false);
          getChaptersBasedOnState();
          // setValue('searchTerm', '')
          toast.success(res.data?.message);
        } else {
          toast.error(res?.data?.err?.message);
        }
      });
    }
  };

  const handleClose = () => setOpen(false);

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  //Modal usage
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    minWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const exitChapter = (chapterId) => {
    let data = {
      chapterId,
      memberId: memberDetails?._id
    }
    leaveChapter(data).then(res => {
      if (res?.data?.status) {
        toast.success('Successfully Exited Chapter')
        getChaptersBasedOnState()
      }
      else {
        toast.error('Error in exiting Chapter')
      }
    })


  }

  useEffect(() => {
    getChaptersBasedOnState()
  }, [getChaptersBasedOnState])

  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center title-font text-light py-3 mt-1">Our Chapters</h2>
      </div>
      <div className="p-md-5 p-3  overallPadding">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
            <Box sx={{ minWidth: 120 }} className="mb-4 mb-lg-0">
              <FormControl
                fullWidth
              >
                <InputLabel className="text-dark">
                  State<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={
                        <Typography className="text-dark">
                          State <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      className={"bg-white"}
                      value={field.value || ''}
                      onChange={(e) => {
                        field.onChange(e);
                        setPage(0);
                        setValue('searchTerm', '')
                        getChaptersBasedOnState()
                      }}
                    >
                      {indiaStates.map((state) => (
                        <MenuItem key={state.code} value={state.code}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Box>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12">
            <TextField  label='Chapter Name' {...register('searchTerm')} placeholder="Enter Chapter Name" InputLabelProps={{ shrink: true }} fullWidth InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title='Search'>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        getChaptersBasedOnState(1);
                        setError('');
                        setSearchTerm('')
                        setItemsPerPage(10)
                      }}
                      className="me-1"
                      edge="end"
                    >
                      <SearchIcon color="success" className="fw-bold fs-3" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Reset'>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { reset({ state: watch('state'), searchTerm: '' }); setPage(0); getChaptersBasedOnState(); }}
                      className="me-1"
                      edge="end"
                    >
                      <AutorenewIcon color="error" className="fw-bold fs-3" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }} />
          </div>
          <div className={`col-sm-12 col-lg-4 col-md-6 d-flex align-items-center  ${(Object.keys(chapterData)?.length !== 0) ? "justify-content-between" : "justify-content-end"}`}>
            {((Object.keys(chapterData)?.length !== 0)) ?
              (
                <>
                  <div className="content-font">
                    <p className="m-0"><b>Individual Members :</b>{chapterData?.totalIndividualCount}</p>
                    <p className="mt-2"><b>Institutional Members :</b>{chapterData?.totalInstitutionCount}</p>
                  </div>

                  {((memberScope === 'PORTAL_SUPER_ADMIN') || (memberScope === 'PORTAL_ADMIN')) && (
                    <Link to='/chapterForm' className="incChapter chapter-link-box">
                      <Button variant="contained" className="justify-content-end menus-color addChapter" style={{ textTransform: "uppercase" }}>
                        Add Chapter
                      </Button>
                    </Link>
                  )}
                </>
                // <div className="allChapterData">
                //   {((memberScope === 'PORTAL_SUPER_ADMIN') || (memberScope === 'PORTAL_ADMIN')) && (
                //     <Link to='/chapterForm' className="incChapter chapter-link-box">
                //       <Button variant="contained" className="justify-content-end menus-color addChapter" style={{ textTransform: "uppercase" }}>
                //         Add Chapter
                //       </Button>
                //     </Link>
                //   )}

                //   <h4 className="text-center mt-2">{state ? state : "All chapter"}</h4>
                //   <div className="d-flex justify-content-center mt-4 mb-2">
                //     <div className="chapterDatas">
                //       <h5 className="counts text-center text-light">{chapterData?.totalCount}</h5>
                //       <div className="text-center mb-2"><b className="p-1 capterStructure">Chapters</b></div>
                //     </div>
                //     <div className="individualMember">
                //       <h5 className="counts text-center text-light">{chapterData?.totalIndividualCount}</h5>
                //       <div className="text-center mb-2"><b className="p-1 capterStructure">Individual Members</b></div>
                //     </div>
                //     <div className="institutationalMember">
                //       <h5 className="counts text-center text-light">{chapterData?.totalInstitutionCount}</h5>
                //       <div className="text-center mb-2"><b className=" p-1 capterStructure">Institutional Members</b></div>
                //     </div>
                //   </div>
                // </div>
              ) :
              (
                <div >
                  <div>
                    {(memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_ADMIN') && (
                      <Button onClick={handleNavigate} variant="contained" className="text-light chapter-link d-flex align-items-center justify-content-end menus-color addChapter" style={{ textTransform: "uppercase" }}>
                        Add Chapter
                      </Button>
                    )
                    }
                  </div>
                </div>
              )}
          </div>
        </div>
        {!memberScope && <div className=" mt-4 mt-lg-0">
          <div className="row d-flex justify-content-between px-3 px-md-0">
            <p className="instruction col-12 col-md-6 title-font">Would You Like To Join A Chapter Near You ? Login To Your Membership Portal!</p>
            <p className="instruction col-12 col-md-6 title-font"><Link to="/chapter-starting" style={{ color: 'white' }}>Are You Interested in Starting a Chapter ? Click Here To Know About Starting A Chapter </Link></p>
          </div>
        </div>}
        {loading ? <Spinner></Spinner> : <div>
          {(Object.keys(chapterData)?.length !== 0) ?
            (<>
              <div className="stateHeading title-font">
                <h4 className="py-2">{states ? states : "All Chapters"}({chapterData?.totalCount})</h4>
              </div>

              {chapterData?.chapterData?.map((data) => (
                <div className="card mt-3 bg-white position-relative">
                  {(data?.isApproved === 0 && (memberScope === 'PORTAL_SUPER_ADMIN' || data._id === chapterDatas?._id)) && <span onClick={() => { memberScope === 'PORTAL_SUPER_ADMIN' ? navigate('/admin/view-pending-chapter/' + data?._id) : navigate('/chapterAdmin/view-pending-chapter/' + data?._id) }} class="position-absolute mt-2 ms-2 start-0 top-0 fs-6 badge text-dark p-1 px-2 bg-warning border border-light" style={{ cursor: 'pointer' }}>
                    Pending
                    <span class="visually-hidden">Pending Changes</span>
                  </span>}
                  {(data?.isApproved === 2 && data._id === chapterDatas?._id) && <span onClick={() => { handleOpen2(data) }} class="position-absolute mt-2 ms-2 start-0 top-0 fs-6 badge text-white p-1 px-2 bg-danger border border-light" style={{ cursor: 'pointer' }}>
                    Rejected
                    <span class="visually-hidden">Pending Changes</span>
                  </span>}
                  <div className="position-absolute end-0 mb-3" style={{ top: '-4%' }}><label className="dropdown">
                    <div className="dd-button z-3">
                      ...
                    </div>
                    <input type="checkbox" className="dd-input" id="test" />
                    <ul className="dd-menu z-3 bg-transparent">
                      <li className="mx-0">
                        <Tooltip title="View" placement="left">
                          <Link
                            style={{
                              color: "#FF8000",
                            }}
                            to={
                              memberScope === "PORTAL_SUPER_ADMIN"
                                ? `/admin/view-Chapter/${data?._id}` :
                                memberScope === "PORTAL_ADMIN"
                                  ? `/assistantAdmin/view-Chapter/${data?._id}`
                                  : memberScope === "PORTAL_SIG_ADMIN"
                                    ? `/sigAdmin/view-Chapter/${data?._id}`
                                    : memberScope === "PORTAL_CHAPTER_ADMIN"
                                      ? `/chapterAdmin/view-Chapter/${data?._id}`
                                      : memberScope === "PORTAL_MEMBER"
                                        ? `/MEMBER/view-Chapter/${data?._id}`
                                        : `/view-Chapter/${data?._id}`
                            }
                            role="button"
                          >
                            <VisibilityIcon />
                          </Link>
                        </Tooltip>
                      </li>
                      {(memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === "PORTAL_ADMIN" || data._id === chapterDatas?._id) &&
                        <li className="mx-0">
                          <Tooltip title="Edit" placement="left">
                            <Link
                              style={{
                                color: "#008000",
                              }}
                              to={memberScope === "PORTAL_ADMIN" ? `/assistantAdmin/chapterForm/${data?._id}` : memberScope === 'PORTAL_SUPER_ADMIN' ? `/admin/chapterForm/${data?._id}` : `/chapterAdmin/chapterForm/${data?._id}`}
                              role="button"
                            >
                              <EditIcon />
                            </Link>
                          </Tooltip>
                        </li>}
                      {(memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") && (
                        <>

                          <li className="mx-0">
                            <Tooltip title="Approve/Reject Member" placement="left">
                              <Link
                                style={{
                                  color: "blue",
                                }}
                                to={memberScope === "PORTAL_ADMIN" ? `/assistantAdmin/memberList/${data?._id}` : `/admin/memberList/${data?._id}`}

                                role="button"
                              >
                                <CheckBoxIcon />
                              </Link>
                            </Tooltip>
                          </li>
                          {((memberScope === "PORTAL_CHAPTER_ADMIN" && chapterDatas?._id === data?._id) || (memberScope === 'PORTAL_ADMIN') || (memberScope === 'PORTAL_SUPER_ADMIN')) && (
                            <li className="mx-0">
                              <Tooltip title="Joined Member" placement="left">
                                <Link
                                  style={{
                                    color: "black",
                                  }}
                                  to={memberScope === "PORTAL_CHAPTER_ADMIN" ? `/chapterAdmin/chapterJoinMember/${data._id}` : memberScope === "PORTAL_SUPER_ADMIN" ? `/admin/chapterJoinMember/${data._id}` : `/assistantAdmin/chapterJoinMember/${data._id}`}
                                  role="button"
                                >
                                  <GroupIcon style={{ color: 'purple' }} />
                                </Link>
                              </Tooltip>
                            </li>
                          )}
                          {memberScope === 'PORTAL_SUPER_ADMIN' && <li className="mx-0">
                            <Tooltip title="Make/Remove Admin" placement="left">
                              <Link
                                style={{
                                  color: "aqua",
                                }}
                                to={`/admin/chapter/make-admin/${data?._id}`}
                                role="button"
                              >
                                <AdminPanelSettingsIcon />
                              </Link>
                            </Tooltip>
                          </li>}
                          {((memberScope === 'PORTAL_SUPER_ADMIN') || (memberScope === 'PORTAL_ADMIN')) && (
                            <li className="mx-0 py-1">
                              <Tooltip title="Delete" placement="left">
                                <DeleteIcon onClick={() => handleOpen1(data?._id)} style={{ color: "red" }} />
                              </Tooltip>
                            </li>
                          )}

                        </>
                      )}
                      {(memberScope === 'PORTAL_MEMBER' && (data.membersId?.findIndex((item) => item?.id === memberDetails?._id) === -1)) ?
                        <li className="mx-0">
                          <Tooltip title="Join" placement="left">
                            <Link
                              className="icon-join-color"
                              onClick={() => handleOpen(data?._id)}
                              role="button"
                            >
                              <LoginIcon />
                            </Link>
                          </Tooltip>
                        </li> :
                        (data.membersId?.findIndex((item) => item?.id === memberDetails?._id && item?.isApproved === 1) > -1 &&
                          <li className="mx-0">
                            <Tooltip title="Exit Chapter" placement="left">
                              <Link
                                className="icon-exit-color"
                                onClick={() => exitChapter(data?._id)}
                                role="button"
                              >
                                <LogoutIcon />
                              </Link>
                            </Tooltip>
                          </li>
                        )
                      }
                    </ul>
                  </label></div>
                  <div className="row p-md-3 p-2 my-3">
                    <div className="col-12 col-lg-6 col-xl-4 mt-3 mt-lg-0 text-center">
                      <h4 className="text-center fs-4 alata-bold text-capitalize">{data.title}</h4>
                      {data.imageUrl ?
                        <img
                          src={data.imageUrl && `${data.imageUrl}?t=${new Date().getTime()}`} // Fetched image from backend with cache busting
                          className="chapImg w-100 rounded-0" alt="chapImg" />
                        :
                        <img
                          src={placeholderImage}
                          className="chapImg w-100 rounded-0" alt="chapImg" />
                      }
                      <div>
                        <p className="text-center mb-1 alata-regular"><GroupIcon className="menus-green-color fs-6" /> Members as of now: <span className="text-secondary-new">{data.membersId.filter(item => item.isApproved === 1).length}</span></p>
                        {/* <p className="text-center mb-1 alata-regular"><LocalActivityIcon className="menus-green-color fs-6" /> Activities so far: <span className="text-secondary-new">{data.eventCount ? data.eventCount : '-'}</span></p> */}
                      </div>
                    </div>
                    {(data.roleDetails.length > 0) ? (
                      data.roleDetails.slice(0, 2).map((role) => (
                        <div className="col-12 col-lg-6 col-xl-4 d-flex justify-content-between flex-column">
                          <h5 className="text-center text-capitalize alata-medium fw-medium fs-5"><b>{role?.role}</b></h5>
                          <div className="d-flex justify-content-center">
                            {role.roleImageUrl ?
                              <>
                                <img
                                  src={role.roleImageUrl && `${role.roleImageUrl}?t=${new Date().getTime()}`}
                                  height={150} width={150} className="ConvenorImg object-fit-cover" alt="ConvenorImg" />
                              </> : <>
                                <img
                                  src={userLogo}
                                  height={150} width={150} className="ConvenorImg object-fit-cover" alt="ConvenorImg" />
                              </>}
                          </div>
                          <div className="text-center d-flex flex-column gap-1 mt-3 mx-auto w-75">
                            <p className="text-center alata-bold p-0 m-0 text-uppercase">{Salutation.find(item => item.id === Number(role?.salutation))?.Name}. {role?.name}</p>
                            <p className="alata-regular d-flex gap-1 align-items-start justify-content-center p-0 m-0"><SchoolIcon className="menus-green-color mt-1 fs-6 " />{role?.institution}({role?.designation})</p>
                            <p className="d-flex gap-1 align-items-center justify-content-center text-start alata-regular p-0 m-0"><MailOutlineIcon className="menus-green-color fs-6" />{role?.email}</p>
                            <p className="d-flex gap-1 align-items-center justify-content-center text-start alata-regular p-0 m-0">{role?.mobile ? <><PhoneInTalkIcon className="menus-green-color fs-6" />{role?.mobile}</> : ''}</p>
                            <p className="d-flex align-items-start justify-content-center text-center alata-regular p-0 m-0">{role?.address ? <><LocationOnIcon className="mt-1 menus-green-color fs-6" />{role?.address}</> : ''}</p>
                          </div>
                        </div>
                      )
                      )
                    ) : (
                      <>
                        <div className="d-flex justify-content-center align-items-center col-12 col-lg-6 col-xl-4 mt-3 mt-lg-0 content-font">
                          <h3>No office bearers</h3>
                        </div>
                      </>
                    )
                    }
                  </div>

                </div>
              ))}
            </>)
            :
            (<>
              <h3 className="text-center text-danger mt-4 content-font">No chapter available!</h3>
            </>)
          }
        </div>}
        <br />
        {(Object.keys(chapterData)?.length !== 0) && !loading &&
          <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
            <div className="mb-2 mb-md-0">
              <div className="d-flex gap-2">
                <input
                  type="number"
                  min={1}
                  placeholder="Page no."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                <div className="goPage cursor-pointer" onClick={handlePageChange}>
                  <h5 className="p-2 m-0">Go</h5>
                </div>
              </div>
              {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
            </div>
            <div className="my-3 my-lg-0">

              <ReactPaginate
                pageCount={totalPages} // Replace with the total number of pages
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousLabel={(page + 1) > 1 ? "Previous" : null}
                // nextLabel={currentPage > 1 && page + 1 !== currentPage ? "Next" : null}
                nextLabel={((page + 1) > (totalPages - 1)) ? null : "Next"}
                disabledClassName={"disablebtn"}
                forcePage={page}
              />

            </div>
            <div className="mb-2 mb-md-0">
              <FormControl variant="outlined">
                <InputLabel></InputLabel>
                <Select
                  className="itemsperpage"
                  value={itemsPerPage}
                  onChange={(e) => {
                    const value = e.target.value;
                    setItemsPerPage(value);
                    setError('')
                    setSearchTerm('')
                    setPage(0)
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex align-items-center flex-column gap-3">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {(memberCurrentChapterApproved === 1) ?
              ("As you are currently in another Chapter, would you like to join here?") : ("Would you like to request this chapter? If so, your pending request will be removed or deleted")
            }

          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <Button
              variant="outlined"
              color="error"
              className="rounded-0"
              onClick={handleClose}
            >
              Disagree
            </Button>
            <Button
              className="mx-2 rounded-0"
              variant="contained"
              color="success"
              onClick={handleAgree}
            >
              Agree
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style1}
          tabIndex={-1}
          className="d-flex  flex-column gap-3"
        >
          {/* <h4 style={{ color: "#104e29" }}>Rejection Reason</h4> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
              Rejection Reason
            </Typography>
            <IconButton onClick={handleClose2} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </div>
          <Box display="flex" justifyContent="center" width="100%">
            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e", textAlign: "justify" }}
            variant="h6"
            component="h2"
          >
            {selectedChapter?.rejectionReason}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
        >

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
              Confirmation
            </Typography>
            <IconButton onClick={handleClose1} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </div>
          <Box display="flex" justifyContent="center" width="100%">
            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
          </Box>
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e" }}
            variant="h6"
            component="h2"
          >
            Are You Surely Want to Delete this Chapter?
          </Typography>
          {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              style={{

                color: "#dc3545",
                border: "1px solid #dc3545",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={handleClose1}
            >
              No
            </Button>
            <Button
              className="mx-2"
              variant="contained"
              style={{
                backgroundColor: "#2e7d32",
                color: "white",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ViewChapter;
