import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  TableHead,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllChaptersPaginationMutation } from "../app/services/chapterServices";
import ReactPaginate from "react-paginate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "title",width:'25%', numeric: false, disablePadding: true, label: "Title" },
    {
      id: "logo",
      numeric: true,
      disablePadding: false,
      label: "Logo",
    },
    { id: "state", numeric: false, disablePadding: true, label: "State" },
    {
      id: "establishedOn",
      numeric: true,
      disablePadding: false,
      label: "Established On",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff",width:headCell?.width?headCell?.width:'auto'}}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function ChapterPendingTable() {
  const [allChapters, setAllChapters] = useState([]);
  const [getAllChapterPagination] = useGetAllChaptersPaginationMutation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const [totalPage, setTotalPage] = useState(0);
  const pageCount = Math.ceil(totalPage / itemsPerPage);
  const navigate=useNavigate()

  useEffect(() => {
    const data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      approve: 0,
      searchTerm:''
    };
    getAllChapterPagination(data).then((res) => {
      if (res?.data?.status) {
        setAllChapters(res?.data?.data?.chapterData);
        setTotalPage(res?.data?.data?.totalCount);
      }
    });
  }, [getAllChapterPagination, page, itemsPerPage]);
  //page Click for Event
  const handlechapterPageClick = ({ selected }) => {
    setPage(selected);
  };

  return (
    <>
      <Box className="mt-5">
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 0 }}
          className="rounded-0"
        >
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {allChapters?.length > 0 ? (
                allChapters.map((chapter, index) => (
                  <TableRow
                    hover
                    key={chapter._id}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                    }}
                  >
                    <TableCell align="center">{chapter?.title}</TableCell>
                    <TableCell align="center">
                      <img
                        src={
                          chapter.imageUrl
                            ? `${chapter.imageUrl}?t=${new Date().getTime()}`
                            : "/assets/images/login/userLogo.png"
                        }
                        className="rounded-circle"
                        width={"40px"}
                        height={"40px"}
                        alt="View chapter"
                      />
                    </TableCell>
                    <TableCell align="center">{chapter?.state}</TableCell>
                    <TableCell align="center">
                      {new Date(chapter.establishedOn).toLocaleDateString(
                        "en-In",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        verticalAlign: "middle", // Align content to the middle of the cell
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="8px"
                        flexWrap="wrap"
                      >
                        <Tooltip title="View" placement="top">
                          <IconButton
                            aria-label="ViewIcon"
                            onClick={() => {
                              navigate("/admin/view-pending-Chapter/" + chapter._id)
                            }}
                          >
                            <VisibilityIcon className="icon-vieweye-color" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        {allChapters?.length > 0 && (
          <ReactPaginate
            pageCount={pageCount} // Calculate total pages
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlechapterPageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={page}
            previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
            nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
            disabledClassName={"disablebtn"}
          />
        )}
      </Box>
    </>
  );
}

export default ChapterPendingTable;
