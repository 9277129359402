import React, { useState, useEffect, useCallback } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import { Button, TextField, InputAdornment, Typography, Tooltip, Grid, FormHelperText, Autocomplete, createFilterOptions } from "@mui/material";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  useSaveGalleryMutation,
  useLazyGetAllGalleryQuery,
  useUpdateGalleryMutation,
} from "../../../app/services/galleryService";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { selectCurrentSig } from "../../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../../app/features/chapterAdminSlice";
import Spinner from "../../../Component/spinner/spinner"
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLazyGetAllAnnualConferenceQuery } from "../../../app/services/annualConferenceService";
import { useGetEventBasedOnFilterMutation } from "../../../app/services/eventService";

const filter = createFilterOptions();

const AddEditGallery = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category') && queryParams.get('category').split('_').join(' ');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      category: "",
      list: [{ imageUrl: "", description: "", _id: "", galleryImageUrl: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "list",
  });

  const [duplicateItem, setDuplicateItem] = useState([]);
  const [getAllGallerys, setGetAllGallerys] = useState([]);
  const [getAllGallery] = useLazyGetAllGalleryQuery();
  const [getPastEvents] = useGetEventBasedOnFilterMutation();
  const [saveGallery] = useSaveGalleryMutation();
  const [updateGallery] = useUpdateGalleryMutation();
  const [selectedImage, setSelectedImage] = useState([null]);
  const navigate = useNavigate();
  const { id } = useParams();
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;
  const [loading, setLoading] = useState(false);
  const [mainGallery, setMainGallery] = useState()
  const [getAllAnnualConference] = useLazyGetAllAnnualConferenceQuery();

  //getAllGallery
  useEffect(() => {
    if (category) {
      setValue('category', category)
    }
    else {
      const conferenceNames = getAllGallerys.map(item => item.conferenceName).filter(Boolean);
      const galleryCategories = getAllGallerys.map(item => item.category).filter(Boolean);
      const eventTitles = getAllGallerys.map(item => item.title).filter(Boolean);
      const combinedCategories = [...galleryCategories, ...conferenceNames, ...eventTitles];
      const uniqueCategories = Array.from(new Set(combinedCategories));
      setDuplicateItem(uniqueCategories);
      const singleGallery = getAllGallerys.filter(item => item?.list?.findIndex(t => t._id === id) !== -1)
      const listItem = singleGallery[0]?.list?.filter(item => item?._id === id)
      setValue("list", listItem);
      setValue("category", singleGallery[0]?.category)
      setMainGallery(singleGallery)
    }
  }, [getAllGallerys, id, setValue, category]);

  //handlefile
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Read the image file as a data URL
        const base64String = reader.result;
        if (base64String) {
          const newSelectedImageArr = selectedImage.splice(index, 1, reader.result)
          setSelectedImage(newSelectedImageArr)
          setValue(`list.[${index}].imageUrl`, base64String)
        }
      };
      if (file) {
        // Read the image file
        reader.readAsDataURL(file);
      }
    }
  }

  //save & update
  const onSubmit = (data) => {
    setLoading(true);
    if ((userId && memberScope === "PORTAL_SUPER_ADMIN")) {
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }
      }))
    }
    if ((userId && memberScope === "PORTAL_ADMIN")) {
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }
      }))
    }
    if (userId && memberScope === "PORTAL_SIG_ADMIN") {
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }, SIGGroupsId: [sigId]
      }))
    } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
      data.chaptersId = [chapterId];
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }, chaptersId: [chapterId]
      }))
    }
    if (id) {
      var updatedData;
      const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
      const updateList = data.list.map((data) => {
        const newData = { imageUrl: data.imageUrl, description: data.description, createdBy: data.createdBy, SIGGroupsId: data.SIGGroupsId, chaptersId: data.chaptersId, _id: data._id }
        if (!base64Regex.test(data.imageUrl)) {
          return { ...newData, imageUrl: data.imageUrl.split('/').splice(-2).join('/') }
        }
        else {
          return newData
        }
      });
      updatedData = { id: mainGallery[0]?._id, data: { ...data, list: updateList } };
      updateGallery(updatedData).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data?.message);
          navigate("/gallery-table");
        }
        else {
          setLoading(false)
          toast.error(res?.data?.err?.message)
        }
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      });
    } else {
      const categories = getAllGallerys.map(item => item.category).filter(Boolean);
      const categoryExists = categories.includes(data.category);

      let newData = {
        ...data,
        list: data.list.map((l) => ({
          imageUrl: l.imageUrl,
          description: l.description,
          createdBy: l.createdBy,
          SIGGroupsId: l.SIGGroupsId,
          chaptersId: l.chaptersId
        }))
      };

      // If the category exists, find the matching item and set `createdBy`
      if (categoryExists) {
        const matchingItem = getAllGallerys.find(item => item.category === data.category);
        if (matchingItem) {
          newData.createdBy = { id: matchingItem.createdBy.id, role: matchingItem.createdBy.role };
        }
      } else {
        // If the category does not exist, use a default `createdBy`
        newData.createdBy = { id: userId, role: memberScope };
      }

      setLoading(true);
      saveGallery(newData)
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            toast.success(res.data?.message);
            navigate("/gallery-table");
          } else {
            toast.error(res.data?.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  //getAllGallery
  const getAllData = useCallback(() => {
    // setLoading(true);
    Promise.all([getAllGallery(), getAllAnnualConference(), getPastEvents({ filter: 0, page: "", itemsPerPage: "" })])
      .then((responses) => {
        const [galleryRes, conferenceRes, eventRes] = responses;
        const combinedData = [];
        if (galleryRes.data.status) {
          combinedData.push(...galleryRes.data.data);
          if (conferenceRes.data.status) {
            combinedData.push(...conferenceRes.data.data);
          }
          if (eventRes?.data?.data) {
            combinedData.push(...eventRes?.data?.data?.eventDetails);
          }
          setGetAllGallerys(combinedData);
        }
      })
      .finally(() => {
        // setLoading(false); 
      });
  }, [getAllGallery, getPastEvents, getAllAnnualConference]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);


  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Add Gallery</h2>
        </div>
      </div>
      <div  >

        <div >
          <div className="row">
            <div className="col-md-8">

            </div>

          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="p-md-5 p-3  overallPadding">
            <div className="text-end mb-3">
              <Button onClick={() => window.history.back()} variant="contained" className="menus-color"
              >Back</Button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  {category ?
                    <>
                      <TextField
                        label={<Typography className="text-dark">
                          Album <span className="text-danger">*</span>
                        </Typography>}
                        InputProps={{ readOnly: category }}
                        fullWidth
                        {...register('category', { required: 'Please Select a category' })}
                      />
                    </>
                    : <Controller control={control} rules={{ required: "Please Select a category", }} name="category" render={({ field, fieldState }) => (
                      <Autocomplete
                        value={field.value || ''}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            setValue('category', newValue); // if it's a string, just set the value
                          } else if (newValue && newValue.inputValue) {
                            // if newValue has inputValue, it's a new custom entry
                            setValue('category', newValue.inputValue);
                          } else {
                            setValue('category', newValue); // otherwise, it's a valid option from `duplicateItem`
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;

                          const isExisting = options.some(option => inputValue === option.title);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={duplicateItem}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue; // Show dynamic input option
                          }
                          return option; // Return the option as is for regular options
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option.title || option} {/* Display option title or value */}
                          </li>
                        )}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <Typography className="text-dark">
                                Album <span className="text-danger">*</span>
                              </Typography>
                            }
                          />
                        )}
                      />
                    )} />}
                </Grid>
                {fields.map((field, i) => (
                  <>
                    {i !== 0 && <Grid item xs={12} md={6} lg={4}></Grid>}
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        id="outlined-basic"
                        label={<Typography className="text-dark">
                          Description<span style={{ color: "red" }}>*</span>
                        </Typography>}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        inputProps={{ maxLength: 150 }}
                        {...register(`list.${i}.description`, { required: 'Please enter a description' })}
                        error={!!errors?.list?.[i]?.description}
                        placeholder="Enter a Description"
                        helperText={errors?.list?.[i]?.description?.message}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end"><span style={{ position: 'absolute', bottom: '0', right: '5px' }} className='fw-bold text-primary'>{150 - (watch(`list.${i}.description`) ? watch(`list.${i}.description`).length : 0)}</span></InputAdornment>)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className="d-flex justify-content-center align-items-start gap-1">
                      <div className="w-100">
                        {watch(`list.${i}.imageUrl`) && <img className="w-100 mb-2" src={watch(`list.${i}.imageUrl`)} alt='gallery'></img>}
                        <TextField
                          label={
                            <Typography className="text-dark">
                              Image<span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          type="file"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.list?.[i]?.galleryImageUrl}
                          helperText={errors?.list?.[i]?.galleryImageUrl?.message}
                          inputProps={{ ...field, accept: 'image/*' }}
                          {...register(`list.${i}.galleryImageUrl`, {
                            required: 'Please select an image',
                            validate: async (value) => {
                              const file = value[0];
                              if (!file || !file.type.startsWith("image/")) {
                                return "Please select an image file";
                              }
                              if (file.size > 2 * 1024 * 1024) {
                                return "File size exceeds 2MB";
                              }

                              // Check image resolution
                              const image = new Image();
                              const fileReader = new FileReader();

                              return new Promise((resolve) => {
                                fileReader.onload = (e) => {
                                  image.src = e.target.result;
                                  image.onload = () => {
                                    if (image.width !== 1465 || image.height !== 500) {
                                      resolve("Image resolution must be 1465x500");
                                    } else {
                                      resolve(true);
                                    }
                                  };
                                };
                                fileReader.readAsDataURL(file);
                              });
                            },
                            onChange: (e) => { handleFileChange(e, i); }
                          })}
                        />
                        <FormHelperText className="d-flex align-items-center gap-1">
                          <InfoIcon style={{ color: '#611f1e' }} />The image resolution must be 1465 x 500 pixels.
                        </FormHelperText>
                      </div>
                      {fields.length - 1 === i && (
                        <Button
                          variant="contained"
                          color="success"
                          className="mt-3"
                          onClick={() => append({})}
                        >
                          <Tooltip title="Add">
                            <AddIcon />
                          </Tooltip>
                        </Button>
                      )}
                      {fields.length !== 1 && (
                        <Button
                          className="mt-3"
                          variant="contained"
                          color="error"
                          onClick={() => remove(i)}
                        >
                          <Tooltip title="Delete">
                            < DeleteIcon />
                          </Tooltip>
                        </Button>
                      )}
                    </Grid>
                  </>
                ))}

              </Grid>

              <div className="row  ">
                <div className="col-md-4 mt-2">

                </div>
                <div className="col-md-8">

                </div>

                <div className="row justify-content-center mb-5  mt-3">
                  <div className="col-auto">
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ marginRight: "35px" }}
                      className="justify-content-center menus-color"
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditGallery;
