import React from "react";
import "../../style/css/invoiceEmailTemplate.css";
import eltailogo from '../../../src/assets/images/logos/ELTA@I.svg';


const InvoiceEmailTemplate = React.forwardRef((props, ref) => {
    console.log("name", props.name, props.email, props.amount, props.startdate, props.enddate, props.plantype);
    let offerPercentage = (props.offer) / 100 || 0;
    let offerAmount = '₹' + (props.orginamt * offerPercentage);
    return (
        // <>
        //     <div ref={ref} className="invoicetemp" >
        //         <p>Dear <span class="highlight"> {props.name}</span>,</p>
        //         <p>Thank you for your interest in joining the English Language Teachers’ Association of India (ELTAI)! We would be happy to have you in India’s largest network of English language and literature educators. ELTAI is dedicated to empowering educators, researchers, students by fostering professional development, collaboration, and innovation in teaching and researching English language and literature. With a network of over 5,500 members and 74 chapters, we are proud to support educators across India and beyond.
        //         </p>


        //         <p>Please find attached the invoice for your membership.</p>

        //         <p>The membership fee can be paid via cash, cheque, demand draft (DD), or internet bank transfer. Please send
        //             the cheque/DD/transaction details to our office by post. Ensure that the invoice number is mentioned in the
        //             transaction reference.
        //             For any queries, feel free to contact us at the provided email.
        //         </p>

        //         <h3><b>Our Bank Details:</b></h3>
        //         <p>Bank & Branch: SBI, Villivakkam Branch, Chennai</p>
        //         <p>Account Name: English Language Teachers’ Association of India</p>
        //         <p>Account Number: 30870397943</p>
        //         <p>IFSC Code: SBIN0007108</p>

        //         <p>For any queries, feel free to contact us at <a href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a> or
        //             call us at +91-9344425159 (Monday to Friday, 10 AM – 6 PM).</p>

        //         <p>We look forward to welcoming you to India’s largest network of English language and literature educators!</p>

        //         <div className="footer pb-3">
        //             <p>Warm regards,</p>
        //             <img src={eltailogo} alt="ELTAI Logo" class="logImg" />
        //             <p>
        //                 <b>English Language Teachers’ Association of India (ELTAI)</b><br />
        //                 D-54 Third Floor, Anandham Apartments,<br />
        //                 156, SIDCO Nagar Main Road, Villivakkam,<br />
        //                 Chennai, Tamil Nadu, India – 600049<br />
        //                 Email: indiaeltai@gmail.com | Phone: +91-9344425159
        //             </p>
        //         </div>
        //         <hr className="line" />
        //         <h3 className="stay">Stay Connected</h3>
        //         <p >Follow us on social media to keep up with our news and engage with a dynamic
        //             community of English language
        //             and literature educators, researchers, and students:
        //         </p>
        //         <div class="social-icons ">
        //             <a href="https://www.facebook.com/ELTAI.India" target="_blank" rel="noreferrer" title="Facebook"><i className="fa fa-facebook mailicon-config  social-icon"></i></a>
        //             <a href="https://www.instagram.com/eltai.india" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-instagram mailicon-config  social-icon"></i></a>
        //             <a href="https://x.com/EltaiIndia" target="_blank" rel="noreferrer" title="Twitter"><XIcon className=" mailiconmui-config" /></a>
        //             <a href="[TikTok Link]" target="_blank" rel="noreferrer" title="TikTok"><MusicNoteIcon className="mailiconmui-config" /></a>
        //             <a href="https://www.youtube.com/@ELTAIIndia" rel="noreferrer" target="_blank" title="YouTube"><i className="fa fa-youtube icon-config-utube mailicon-config"></i></a>
        //             <a href="[Telegram Link]" target="_blank" rel="noreferrer" title="Telegram"><i class="fa fa-telegram social-icon  mailicon-config"></i></a>
        //             <a href="[WhatsApp Link]" target="_blank" rel="noreferrer" title="WhatsApp"><i class="fa fa-whatsapp  social-icon mailicon-config" ></i></a>
        //             <a href="[LinkedIn Link]" target="_blank" rel="noreferrer" title="LinkedIn"><i class="fa fa-linkedin-square social-icon mailicon-config" aria-hidden="true"></i></a>
        //         </div>
        //         <p>Spread the word about ELTAI and encourage your colleagues and friends to join our growing community!</p>
        //         <h3>Questions About Your Membership?</h3>
        //         <p>If you have any queries about your membership, please contact us at <a
        //             href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a> or call us on +91-9344425159 (between 10 am
        //             and 6 pm – from Monday to Friday).</p>

        //     </div >
        // </>
        <>
            <div className="receipt-container" ref={ref} >

                <div className="logoemail">
                    <img src={eltailogo} alt="ELTAI Logo" className="logImg" />
                </div>

                <div className="header-content">
                    <h3>English Language Teachers' Association of India (ELTAI)</h3>
                    <p>D-54 Third Floor, Anandham Apartments</p>
                    <p>156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamil Nadu, India - 600049</p>
                    <div className="contact-info">
                        <div>
                            <strong>Email:</strong> <a href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a>
                        </div>
                        <div className="changeLabelStyle">
                            <strong>Mobile:</strong> +91-9344425159
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="title">Membership Invoice</div>
                <table className="receipt-details">
                    <tbody>
                        <tr>
                            <td className="label ">Date:</td>
                            <td className="field">{props.date}</td>
                        </tr>
                        <tr>
                            <td className="label">Issued to:</td>
                            <td className="field" colSpan="3">{props.name}</td>
                        </tr>
                        <tr>
                            <td className="label">Issued for:</td>
                            <td className="field" colSpan="3">{props.memberId} – New</td>
                        </tr>
                        <tr>
                            <td className="label">Address:</td>
                            <td className="field" colSpan="3">

                                {props.address}, {props.city}, {props.district} , {props.state}, {props.country}
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Mobile:</td>
                            <td className="field">{props.mobileNumber}</td>
                        </tr>
                        <tr>
                            <td className="label">Email:</td>
                            <td className="field">{props.email}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="membership-table">
                    <thead>
                        <tr>
                            <th>Membership Details</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {props.plantype} <br /> {props.planname} <br /> Duration from {props.startdate} to {props.enddate}
                            </td>
                            <td>1</td>
                            <td>₹ {props.orginamt}</td>
                        </tr>
                        <tr>
                            <th className="discount">Discounts</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <td className="membership"> {props.plantype}</td>
                            <td> {props.offer}%</td>
                            <td>{offerAmount}</td>
                        </tr>
                        <tr>
                            <td className="membership">Renewal Discount, if renewal</td>
                            <td>-</td>
                            <td>- </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="total-payable text-end">Total Payable</td>
                            <td className="final-amount">₹ {props.amount}</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Our Account Details:</h3>
                <table className="payment-table">
                    <tbody>
                        <tr>
                            <td className="label">
                                <strong>Account Name:</strong>
                            </td>
                            <td>ELTAI (English Language Teachers’ Association of India)</td>
                        </tr>
                        <tr>
                            <td className="label">
                                <strong>Bank & Branch:</strong>
                            </td>
                            <td>SBI, Villivakkam Branch, Chennai</td>
                        </tr>
                        <tr>
                            <td className="label">
                                <strong>Savings Account No:</strong>
                            </td>
                            <td>30870397943</td>
                        </tr>
                        <tr>
                            <td className="label">
                                <strong>IFSC Code:</strong>
                            </td>
                            <td>SBIN0007108</td>
                        </tr>
                    </tbody>
                </table>
                <h4 className="mt-3"><strong>Important Instructions</strong></h4>
                <p>
                    The membership fee can be paid via cash, cheque, demand draft (DD), or internet bank transfer. Please send the
                    cheque/DD/transaction details to our office by post. Ensure that the invoice number is mentioned in the
                    transaction reference. For any queries, feel free to contact us at the provided email.
                </p>
                <div className="authorised">
                    <p className="mb-1">Authorised by</p>
                    <p className="mb-1">
                        <strong>Dr. M. S. Xavier Pradheep Singh</strong>
                    </p>
                    <p className="mb-1">National Secretary, ELTAI</p>
                    <div className="divider"></div>
                </div>
                <div className="note">
                    Thank you for your interest in taking a membership of ELTAI. We are excited to have you as part of our growing
                    community. Please visit <a href="https://www.eltai.in">www.eltai.in</a> to explore the benefits and resources
                    available to you. If you have any questions, contact us at
                    <a href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a> or at <strong>+91-9344425159</strong>.
                </div>
            </div></>

    )
})
export default InvoiceEmailTemplate;
