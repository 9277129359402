import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import TopHeader from '../../Component/Header/TopHeader';
import { useLazyGetSIGByIdQuery } from "../../app/services/SIGServices"
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import { AppBar, } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ViewForum from '../Admin/ViewForum';
import '../../style/css/viewSigDetail.css';
import Spinner from "../../Component/spinner/spinner"
import Event from '../../Component/Portal/Event'
import Gallery from '../../Component/Portal/Gallery';
import ImportantAnnouncementTable from "./../Admin/ImportAnnocement/ImportantAnnouncementTable";

function ViewSigDetail() {
    const [loading, setLoading] = useState(false);
    const { sigGroupId } = useParams();
    const [getSigGroupDetails] = useLazyGetSIGByIdQuery()
    const [currentMainTabIndex, setCurrentMainTabIndex] = useState(0);
    const [getSigData, setGetSigData] = useState([]);
    const [displayedOffice, setDisplayedOffice] = useState(2);
    const [lengthOfRole, setLengthOfRole] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    //handleMainTabChange
    const handleMainTabChange = (e, tabIndex) => {
        setCurrentMainTabIndex(tabIndex);
    };

    //handletabchange
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
        console.log(tabIndex, 'tabIndex')
    };

    //load More Events
    const loadMoreOffice = () => {
        setDisplayedOffice(prevCount => prevCount + 2);
    };

    useEffect(() => {
        if (sigGroupId) {
            getSigGroupDetails(sigGroupId)
        }
    }, [getSigGroupDetails, sigGroupId])

    //Get sig group details by id
    useEffect(() => {
        if (sigGroupId) {
            setLoading(true);
            getSigGroupDetails(sigGroupId).then((res) => {
                if (res.data?.status) {
                    setLoading(false);
                    setGetSigData(res.data.data)
                    setLengthOfRole(res.data.data.roleDetails.length)
                }
            }).catch((err) => {
                setLoading(false);
                console.error(err)
            })
        }
    }, [sigGroupId, getSigGroupDetails])

    return (
        <div className='page-containter'>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">View Sig Details</h2>
                </div>
            </div>
            <div>
                <div className='row '>
                    <div className='col-md-12 mt-3'>
                        <Button onClick={() => window.history.back()} variant="contained" className='menus-color'
                            style={{ float: 'right', marginRight: 30 }} >Back</Button>
                    </div>
                </div>
                <div className='row'>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            maxWidth: "300px",
                            margin: "0 auto",
                            position: "sticky",
                            top: "0",
                            zIndex: "999",
                            marginBlock: "10px",
                        }}
                    >
                        <AppBar>
                        </AppBar>
                        <Tabs
                            position="static" sx={{ mx: 'auto', width: '100%' }}
                            value={currentMainTabIndex}
                            onChange={handleMainTabChange}
                            variant='fullWidth'
                            textColor='white'
                        >
                            <Tab label="About" sx={{ color: `${currentMainTabIndex === 0 ? '#611f1e !important' : 'white'}`, background: `${currentMainTabIndex === 0 ? 'white' : '#611f1e !important'}` }} />
                            <Tab label="Sig" sx={{ color: `${currentMainTabIndex === 1 ? '#611f1e !important' : 'white'}`, background: `${currentMainTabIndex === 1 ? 'white' : '#611f1e !important'}` }} />
                        </Tabs>
                    </Grid>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <div
                            style={{
                                maxHeight: "600px",
                                overflowY: "auto",
                                "&::-webkit-scrollbar": {
                                    width: 0,
                                    height: 0,
                                },
                                scrollbarWidth: "none", // For Firefox
                                msOverflowStyle: "none", // For Internet Explorer & Edge
                            }}
                        >
                            {currentMainTabIndex === 0 && (
                                <>
                                    <div className=' container chapterDetails' style={{ padding: 40 }}>
                                        < h2 style={{ textAlign: 'center', color: '#a52a2a ' }}>{getSigData.groupName}</h2>
                                        <div className='about_section'>
                                            <h2 style={{ color: '#611f1e' }}>About :</h2>
                                            <p style={{ color: 'black', textAlign: 'justify' }}> {getSigData.description}</p>
                                        </div>
                                        <h3 style={{ color: '#611f1e' }}>
                                            Office Bearers
                                        </h3>
                                        <div className='row mb-5'>
                                            <div className='col-md-1'></div>
                                            <div className='col-md-10'>
                                                <div className='row'>
                                                    {
                                                        lengthOfRole !== 0 ?
                                                            getSigData?.roleDetails?.slice(0, displayedOffice).map((data, index) => (
                                                                <div className="contentsig col-lg-6 col-md-6 col-sm-12 mt-5">
                                                                    <div className="individualsig_card" key={index}>
                                                                        <div className="firstinfosig">
                                                                            <div className="row w-100">
                                                                                <div className="col-3"></div>
                                                                                <div className="col-9">
                                                                                    <div className="profileinfosig">
                                                                                        <h1>Name : {data.name}</h1>
                                                                                        <h3 className='text-center'>Role : {data.role}</h3>
                                                                                        <p className="bio text-center">Mobile : {data.mobile} </p>
                                                                                        <p className='text-center text-black' >Email : {data.email}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) : (
                                                                <p className='text-center  lead text-black'>No Office Bearers Available</p>
                                                            )}
                                                </div>
                                            </div>
                                            <div className='col-md-1'>
                                            </div>
                                        </div>
                                        {lengthOfRole > displayedOffice && (
                                            <div className='w-100 text-end mb-3'>
                                                <Button variant='contained' className="menus-color" onClick={loadMoreOffice}> Load More </Button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {currentMainTabIndex === 1 && (
                                <>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            maxWidth: "90%",
                                            margin: "0 auto",
                                            position: "sticky",
                                            top: "0",
                                            zIndex: "999",
                                            marginBlock: "10px",
                                        }}
                                    >
                                        <AppBar>
                                        </AppBar>
                                        {/* <Tabs
                                            position="static" sx={{ borderRadius: "8px", backgroundColor: '#611f1e', mx: 'auto', width: '100%' }}
                                            value={currentTabIndex}
                                            onChange={handleTabChange}
                                            variant='fullWidth'
                                            textColor='white'
                                        >
                                            <Tab label="Events" sx={{ color: `${currentTabIndex === 0 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 0 ? 'white' : '#611f1e'}` }} />
                                            <Tab label="News" sx={{ color: `${currentTabIndex === 1 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 1 ? 'white' : '#611f1e'}` }} />
                                            <Tab label="Forums" sx={{ color: `${currentTabIndex === 2 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 2 ? 'white' : '#611f1e'}` }} />
                                            <Tab label="Gallery" sx={{ color: `${currentTabIndex === 3 ? '#611f1e' : 'white'}`, background: `${currentTabIndex === 3 ? 'white' : '#611f1e'}` }} />
                                        </Tabs> */}
                                        {/* <Tabs
                                            position="static"
                                            sx={{
                                                backgroundColor: '#611f1e',
                                                mx: 'auto',
                                                width: '100%',
                                                display: 'flex',
                                                flexGrow: "1",
                                                "& .MuiButtonBase-root": {
                                                    color: "white",  // Change the color of the scroll buttons
                                                }
                                            }}
                                            value={currentTabIndex}
                                            onChange={handleTabChange}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            textColor='#611f1e'
                                        >
                                            <Tab
                                                label="Events"
                                                sx={{
                                                    width: "25%",
                                                    color: currentTabIndex === 0 ? "#611f1e" : "white",
                                                    backgroundColor: currentTabIndex === 0 ? "white" : "#611f1e"
                                                }}
                                            />
                                            <Tab
                                                label="Forums"
                                                sx={{
                                                    width: "25%",
                                                    color: currentTabIndex === 1 ? "#611f1e" : "white",
                                                    backgroundColor: currentTabIndex === 1 ? "white" : "#611f1e"
                                                }}
                                            />
                                            <Tab
                                                label="Gallery"
                                                sx={{
                                                    width: "25%",
                                                    color: currentTabIndex === 2 ? "#611f1e" : "white",
                                                    backgroundColor: currentTabIndex === 2 ? "white" : "#611f1e"
                                                }}
                                            />
                                            <Tab
                                                label="Announcement"
                                                sx={{
                                                    width: "25%",
                                                    color: currentTabIndex === 3 ? "#611f1e" : "white",
                                                    backgroundColor: currentTabIndex === 3 ? "white" : "#611f1e"
                                                }}
                                            />



                                        </Tabs> */}
                                        <Tabs
                                            position="static"
                                            sx={{
                                                backgroundColor: "#611f1e",
                                                mx: "auto",
                                                width: "100%",
                                                display: "flex",
                                                flexGrow: 1,
                                                "& .MuiButtonBase-root": {
                                                    color: "white", // Ensures scroll button colors are white
                                                },
                                                "& .MuiTabs-indicator": {
                                                    backgroundColor: "white", // Changes the underline (indicator) color
                                                }
                                            }}
                                            value={currentTabIndex}
                                            onChange={handleTabChange}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            TabIndicatorProps={{ style: { backgroundColor: "white" } }} // Active tab underline
                                        >
                                            <Tab
                                                label="Events"
                                                sx={{
                                                    width: "25%",
                                                    textTransform: "none",
                                                    color: "white",
                                                    backgroundColor: "#611f1e",
                                                    "&.Mui-selected": {
                                                        color: "#611f1e",
                                                        backgroundColor: "white",
                                                        fontWeight: "bold",
                                                    }
                                                }}
                                            />
                                            <Tab
                                                label="Forums"
                                                sx={{
                                                    width: "25%",
                                                    textTransform: "none",
                                                    color: "white",
                                                    backgroundColor: "#611f1e",
                                                    "&.Mui-selected": {
                                                        color: "#611f1e",
                                                        backgroundColor: "white",
                                                        fontWeight: "bold",
                                                    }
                                                }}
                                            />
                                            <Tab
                                                label="Gallery"
                                                sx={{
                                                    width: "25%",
                                                    textTransform: "none",
                                                    color: "white",
                                                    backgroundColor: "#611f1e",
                                                    "&.Mui-selected": {
                                                        color: "#611f1e",
                                                        backgroundColor: "white",
                                                        fontWeight: "bold",
                                                    }
                                                }}
                                            />
                                            <Tab
                                                label="Announcement"
                                                sx={{
                                                    width: "25%",
                                                    textTransform: "none",
                                                    color: "white",
                                                    backgroundColor: "#611f1e",
                                                    "&.Mui-selected": {
                                                        color: "#611f1e",
                                                        backgroundColor: "white",
                                                        fontWeight: "bold",
                                                    }
                                                }}
                                            />
                                        </Tabs>

                                    </Grid>
                                    <div
                                        style={{
                                            maxHeight: "600px",
                                            overflowY: "auto",
                                            "&::-webkit-scrollbar": {
                                                width: 0,
                                                height: 0,
                                            },
                                            scrollbarWidth: "none", // For Firefox
                                            msOverflowStyle: "none", // For Internet Explorer & Edge
                                        }}
                                    >
                                        {currentTabIndex === 0 && (<Event />)}


                                        {currentTabIndex === 1 && (
                                            <>
                                                <ViewForum></ViewForum>
                                            </>
                                        )}
                                        {currentTabIndex === 2 && (
                                            <>
                                                <Gallery></Gallery>
                                            </>
                                        )}
                                        {currentTabIndex === 3 && (<ImportantAnnouncementTable />)}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ViewSigDetail