import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../app/services/loginServices";
import { useDispatch } from "react-redux";
import { setCredentials, setImageUrl } from "../app/auth/authSlice";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useLazyGetMemberDetailsByIdQuery } from "../app/services/memberServices";
import { setMemberDetails } from "../app/features/memberSlice";
import { setSigDetails, setSigRoleDetails } from "../app/features/sigSlice";
import { useLazyGetSIGByIdQuery } from "../app/services/SIGServices";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import "../style/css/login.css";
import Grid from "@mui/material/Grid";
import { useLazyGetChapterDetailsByIdQuery } from "../app/services/chapterServices";
import {
  setChapterDetails,
  setRoleDetails,
} from "../app/features/chapterAdminSlice";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import eltailogo from "../../src/assets/images/logos/eltai-svg.svg";
import loginimg from "../../src/assets/images/login/login2.jpg";
import PersonIcon from "@mui/icons-material/Person";
import HttpsIcon from "@mui/icons-material/Https";
import eltailogo from '../../src/assets/images/logos/ELTA@I.svg';



const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginData] = useLoginMutation();
  const [memberDetail] = useLazyGetMemberDetailsByIdQuery();
  const [sigDetail] = useLazyGetSIGByIdQuery();
  const [chapterDetail] = useLazyGetChapterDetailsByIdQuery();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const passwordValue = watch("password");

  //Removed set credentials data when logout
  useEffect(() => {
    localStorage.removeItem("persist:root");
    dispatch(setCredentials({}));
  }, [dispatch]);

  //Form submission with memberscope based
  const handleFormSubmit = async (formData) => {
    const credentials = new URLSearchParams();
    credentials.append("username", formData?.username);
    credentials.append("password", formData?.password);
    const data = await loginData(credentials);
    if (data.data?.status) {
      let userData;
      if (data?.data?.scope === "PORTAL_CHAPTER_ADMIN") {
        userData = {
          user: data?.data?.userData?.email,
          id: data?.data?.userData?._id,
          accessToken: data?.data?.accessToken,
          refreshToken: data?.data?.refreshToken,
          scope: data?.data?.scope,
          chapterId: data?.data?.userData?.chapterId,
          chapterRoleId: data?.data?.userData?.roleId,
          imageUrl: data?.data?.userData?.imageUrl,
        };
        localStorage.setItem('chapterId', userData.chapterId);
      } else if (data?.data?.scope === "PORTAL_SIG_ADMIN") {
        userData = {
          user: data?.data?.userData?.email,
          id: data?.data?.userData?._id,
          accessToken: data?.data?.accessToken,
          refreshToken: data?.data?.refreshToken,
          scope: data?.data?.scope,
          sigId: data?.data?.userData?.sigId,
          sigRoleId: data?.data?.userData?.roleId,
          imageUrl: data?.data?.userData?.imageUrl,
        };
      }
      else if (data?.data?.scope === "PORTAL_ADMIN") {
        userData = {
          user: data?.data?.userData?.email,
          id: data?.data?.userData?._id,
          accessToken: data?.data?.accessToken,
          refreshToken: data?.data?.refreshToken,
          scope: data?.data?.scope,
          sigId: data?.data?.userData?.sigId,
          sigRoleId: data?.data?.userData?.roleId,
          imageUrl: data?.data?.userData?.imageUrl,
        };
      } else {
        userData = {
          user: data?.data?.userData?.email,
          id: data?.data?.userData?._id,
          accessToken: data?.data?.accessToken,
          refreshToken: data?.data?.refreshToken,
          scope: data?.data?.scope,
        };
      }
      setTimeout(async () => {
        if (data?.data?.accessToken) {
          toast.success("Successfully Logged In");
          dispatch(setCredentials(userData));
          if (userData?.scope === "PORTAL_SUPER_ADMIN") {
            navigate('/admin/')
          }
          else if (userData?.scope === "PORTAL_CHAPTER_ADMIN") {
            navigate('/chapterAdmin/chapterNewsFeed')
          }
          else if (userData?.scope === 'PORTAL_SIG_ADMIN') {
            navigate('/sigAdmin/signewfeed');
          }
          else if (userData?.scope === 'PORTAL_ADMIN') {
            navigate('/assistantAdmin/');
          }
          else {
            navigate('/member/viewMember')
          }
        }
        if (userData?.scope === "PORTAL_MEMBER") {
          const memberDetails = await memberDetail(userData?.id);
          if (memberDetails) {
            dispatch(setMemberDetails(memberDetails.data?.data));
            dispatch(setImageUrl({imageUrl:memberDetails.data?.data?.imageUrl?memberDetails.data?.data?.imageUrl:memberDetails.data?.data?.uploadInstitutionLogo?memberDetails.data?.data?.uploadInstitutionLogo:memberDetails.data?.data?.uploadOrganisationLogo?memberDetails.data?.data?.uploadOrganisationLogo:null}))
          }
        }
        else if (userData?.scope === "PORTAL_SIG_ADMIN") {
          const sigDetails = await sigDetail(userData?.sigId);
          const sigRoleDetails = sigDetails.data?.data?.roleDetails.find(item => item?._id === userData?.sigRoleId)
          if (sigDetails) {
            dispatch(setSigDetails(sigDetails.data?.data));
            dispatch(setSigRoleDetails(sigRoleDetails));
          }
        }
        else if (userData?.scope === "PORTAL_CHAPTER_ADMIN") {
          const chapterDetails = await chapterDetail(userData?.chapterId);
          const chapter = chapterDetails.data?.data
          const roleDetails = chapter?.roleDetails.find(item => item._id === userData.chapterRoleId)
          if (chapterDetails) {
            dispatch(setChapterDetails(chapterDetails.data?.data));
          }
          if (roleDetails) {
            dispatch(setRoleDetails(roleDetails));
          }
        }
      }, 500);
    } else {
      toast.error(data.data?.message);
    }
  };

  //show or not passward
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid container className="login-page">
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="d-flex justify-content-center form-content" >
          <img src={eltailogo} className="logo" alt="logo" height={75} onClick={() => navigate('/')} />
          <img src={loginimg} alt="Login" className="img-fluid h-100" style={{ objectFit: 'cover'}} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <h1 className="text-white fs-3 text-center">Let’s get Knowledge!</h1>
            <h2 className="text-white fs-4 lead display-3 text-capitalize text-center">
              Join with ELT@I to start creating magic.
            </h2>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-5 p-sm-0 p-3 d-flex align-items-center justify-content-center flex-column mx-auto">
              <Grid container rowSpacing={1.5} columnSpacing={3} className="w-100 d-flex flex-column align-items-stretch">
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    id="userId"
                    placeholder="User ID"
                    className="login-input"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon className="text-white fs-2" />
                        </InputAdornment>
                      ),
                      style:{color:'white'}
                    }}
                    fullWidth
                    {...register("username", {
                      required: "Please enter user Id",
                    })}
                    type="text"
                    error={!!errors?.username}
                    helperText={errors?.username?.message}
                    autoFocus
                    sx={{
                      width: "100%",
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'none'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white'
                        },
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} >
                  <TextField
                    placeholder="Password"
                    className="login-input"
                    {...register("password", {
                      required: "Please enter password",
                    })}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HttpsIcon className="text-white fs-2" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            sx={{
                              marginRight: "-8.5px",
                            }}
                            disabled={!passwordValue}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon sx={{ color: "white", height: '38px' }} />
                            ) : (
                              <VisibilityIcon sx={{ color: "white", height: '38px' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { paddingLeft: "1.2rem" },
                      style: { color: 'white', }
                    }}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      width: "100%",
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'none'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white'
                        },
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="text-end">
                  <Link
                    to={"/forgotPassword"}
                    className="text-white hover-link"
                  >
                    Forgot Password?
                  </Link>
                </Grid>
                <Grid item xs={12} className="text-center">
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: '120px' }}
                    className="login-btn fw-bold fs-5"
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item xs={12} className="text-center">
                  <Typography className="mt-3 text-white text-center">
                    Don't have an account?
                    <Link
                      className="hover-link"
                      style={{ textDecoration: "none", color: "white" }}
                      // to="/register"
                      to="/membershipPage"
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <Outlet></Outlet>
    </>
  );
};

export default Login;
