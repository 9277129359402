import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TopHeader from "../Component/Header/TopHeader";
import {
  useLazyGetChapterDetailsByIdQuery,
  useApproveRejectupdateChapterMutation,
} from "../../src/app/services/chapterServices";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../style/css/ViewIndividualChapter.css";
import "react-multi-carousel/lib/styles.css";
import ViewForum from "./Admin/ViewForum";
import Spinner from "../Component/spinner/spinner";
import Event from "../Component/Portal/Event";
// import News from '../Component/Portal/News';
import Gallery from "../Component/Portal/Gallery";
import { toast } from "react-toastify";
import userLogo from "../../src/assets/images/login/userLogo.jpg";
import ImportantAnnouncementTable from "./Admin/ImportAnnocement/ImportantAnnouncementTable";
import { Salutation } from "../constant/constant";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import placeholderImage from "../assets/images/placeholder/placeholder.png";
import { InputAdornment, TextField, Typography ,Modal} from "@mui/material";
import { useForm } from "react-hook-form";
//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "20px",
};

function ViewIndividualChapter() {
  const { chapterId } = useParams();
  const [getChapterDetailsById] = useLazyGetChapterDetailsByIdQuery();
  const [currentMainTabIndex, setCurrentMainTabIndex] = useState(0);
  const [getChapterData, setGetChapterData] = useState([]);
  const [displayedOffice, setDisplayedOffice] = useState(4);
  const [lengthOfRole, setLengthOfRole] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [updateDetails, setUpdateDetails] = useState(null);
  const [updatedKeys, setUpdatedKeys] = useState([]);
  const location = useLocation();
  const currentUrl = location.pathname;
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const [loading, setLoading] = useState(false);
  const [approveRejectUpdate] = useApproveRejectupdateChapterMutation();
  const [updatedRoleDetails, setUpdatedRoleDetails] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  //Modal usage
  const handleOpen = () => {
    setOpen((prev) => prev = true);
    console.log("open");
  };

  //close handle
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  //handleMainTabChanges
  const handleMainTabChange = (e, tabIndex) => {
    setCurrentMainTabIndex(tabIndex);
  };

  //load More Events
  const loadMoreOffice = () => {
    setDisplayedOffice((prevCount) => prevCount + 2);
  };

  useEffect(() => {
    if (chapterId) {
      getChapterDetailsById(chapterId);
    }
  }, [getChapterDetailsById, chapterId]);

  //getBy chapterId
  useEffect(() => {
    if (chapterId) {
      setLoading(true);
      getChapterDetailsById(chapterId)
        .then((res) => {
          setLoading(false);
          if (res.data?.status) {
            var chapterData = res?.data?.data;
            setGetChapterData(res?.data?.data);
            setLengthOfRole(currentUrl.includes('pending') ? chapterData?.pendingChanges?.roleDetails ? chapterData?.pendingChanges?.roleDetails.length : chapterData.roleDetails.length : chapterData?.roleDetails.length);
            if (currentUrl.includes("pending")) {
              const update = res?.data?.data?.pendingChanges?.roleDetails
              const original = chapterData?.roleDetails
              const newArray = [
                // Update the items from the `original` array
                ...original.map(item => {
                  // Find corresponding item in the `update` array based on email
                  const updateItem = update.find(u => u.email === item.email);
                  if (updateItem) {
                    // If found, update name and set `isNew: false`
                    return {
                      ...item, name: updateItem.name ? updateItem.name : item?.name, address: updateItem?.address ? updateItem?.address : item?.address, designation: updateItem?.designation ? updateItem?.designation : item?.designation, institution: updateItem?.institution ? updateItem?.institution : item?.institution, mobile: updateItem?.mobile ? updateItem?.mobile : item?.mobile, pincode: updateItem?.pincode ? updateItem?.pincode : item?.pincode, role: updateItem?.role ? updateItem?.role : item?.role, roleImageUrl: updateItem?.roleImageUrl ? updateItem?.roleImageUrl : item?.roleImageUrl, salutation: updateItem?.salutation ? updateItem?.salutation : item?.salutation, isNew: false
                    };
                  }
                  // If no match, keep original item with `isNew: false`
                  return { ...item, isNew: false };
                }),

                // Add the items from the `update` array that are not in `original`
                ...update.filter(u => !original.some(o => o.email === u.email)).map(item => ({
                  ...item,
                  isNew: true // New items from `update` that do not exist in `original`
                }))
              ];
              setUpdatedRoleDetails(newArray);
              setUpdateDetails(res?.data?.data?.pendingChanges);
              Object.entries(res?.data?.data?.pendingChanges).forEach(
                ([key, value]) => {
                  setUpdatedKeys((prev) => [...prev, key]);
                }
              );
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  }, [chapterId, currentUrl, getChapterDetailsById]);
  const approveReject = (type) => {
    const datas = {
      chapterId: chapterId,
      type, // 0 - approved,  1 - reject
      rejectionReason:watch('rejectionReason')
    };
    setLoading(true);
    approveRejectUpdate(datas).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        window?.history.back();
        if (res?.data?.message === "Updated Chapter Approved Successfully!") {
          toast.success(res?.data?.message);
        }
        else {
          toast.error(res?.data?.message);
        }
      }
    });
  };

  return (
    <>
      <div className="page-containter">
        <TopHeader />
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">
              View Chapter Details
            </h2>
          </div>
        </div>
        <div className="p-md-5 p-3 overallPadding">
          <div className="text-end mb-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
            >
              Back
            </Button>
          </div>
          <div className="row">
            {!currentUrl.includes("pending") && (
              <Grid
                container
                spacing={2}
                style={{
                  maxWidth: "300px",
                  margin: "0 auto",
                  position: "sticky",
                  top: "0",
                  zIndex: "999",
                }}
              >
                <Tabs
                  position="static"
                  value={currentMainTabIndex}
                  onChange={handleMainTabChange}
                  variant="fullWidth"
                  textColor="white"
                >
                  <Tab
                    label="About"
                    sx={{
                      color: `${currentMainTabIndex === 0
                        ? "#611f1e !important"
                        : "white !important"
                        }`,
                      background: `${currentMainTabIndex === 0 ? "white" : "#611f1e !important"
                        }`,
                    }}
                  />
                  <Tab
                    label="Chapter"
                    sx={{
                      color: `${currentMainTabIndex === 1
                        ? "#611f1e !important"
                        : "white !important"
                        }`,
                      background: `${currentMainTabIndex === 1
                        ? "white !important"
                        : "#611f1e !important"
                        }`,
                    }}
                  />
                </Tabs>
              </Grid>
            )}
            <div>
              {currentMainTabIndex === 0 && (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div className="container-fluid chapterDetails">
                      <h1 className="text-secondary-new text-center mt-3 alata-bold">
                        {updatedKeys && updatedKeys.includes("title") ? (
                          <span className="bg-warning">
                            {" "}
                            {updateDetails?.title}
                          </span>
                        ) : (
                          getChapterData?.title
                        )}
                      </h1>
                      <div className="row">
                        {/* <h2 className="text-secondary-new alata-bold">
                        Chapter Details:
                      </h2> */}
                        <div className="col-md-6 flex-column d-flex justify-content-center align-items-center">
                          {updatedKeys && updatedKeys.includes("imageUrl") ? (
                            <span className="bg-warning">
                              {" "}
                              <img
                                src={updateDetails?.imageUrl}
                                alt="Chapter logo"
                                style={{ width: '30%' }}
                                className="aspect4 mx-auto"
                              />
                            </span>
                          ) : getChapterData?.imageUrl ? (
                            <img
                              src={getChapterData?.imageUrl}
                              alt="Chapter logo"
                              style={{ width: '30%' }}
                              className="aspect4 mx-auto"
                            />
                          ) : (
                            <img
                              src={placeholderImage}
                              alt="Chapter logo"
                              className="w-25 aspect4 mx-auto rounded-circle"
                            />
                          )}
                        </div>
                        <div className="col-md-6 flex-column d-flex justify-content-center align-items-start">
                          <h3 className="text-secondary-new alata-bold text-capitalize">
                            Established On
                          </h3>
                          <p className="text-center alata-regular text-capitalize">
                            {updatedKeys &&
                              updatedKeys.includes("establishedOn") ?
                              (getChapterData?.establishedOn.split('T')[0] !== updateDetails?.establishedOn) ? <span className="bg-warning">
                                {new Date(
                                  updateDetails.establishedOn
                                ).toLocaleDateString("en-In", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}
                              </span> : getChapterData?.establishedOn &&
                              new Date(
                                getChapterData.establishedOn
                              ).toLocaleDateString("en-In", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              : (
                                getChapterData?.establishedOn &&
                                new Date(
                                  getChapterData.establishedOn
                                ).toLocaleDateString("en-In", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                              )}
                          </p>
                          <h3 className="text-secondary-new alata-bold text-capitalize">
                            District & State
                          </h3>
                          <p
                            className="text-center alata-regular text-capitalize"
                            style={{ textAlign: "justify" }}
                          >
                            {" "}
                            {updatedKeys && updatedKeys.includes("district") ? (
                              <span className="bg-warning">
                                {" "}
                                {updateDetails?.district}
                              </span>
                            ) : (
                              getChapterData?.district
                            )}
                            ,
                            {updatedKeys && updatedKeys.includes("state") ? (
                              <span className="bg-warning">
                                {" "}
                                {updateDetails?.state}
                              </span>
                            ) : (
                              getChapterData?.state
                            )}
                          </p>
                          <h3 className="text-secondary-new alata-bold text-capitalize">
                            Country
                          </h3>
                          <p
                            className="text-center alata-regular text-capitalize"
                            style={{ textAlign: "justify" }}
                          >
                            {" "}
                            {updatedKeys && updatedKeys.includes("country") ? (
                              <span className="bg-warning">
                                {" "}
                                {updateDetails?.country}
                              </span>
                            ) : (
                              getChapterData?.country
                            )}
                          </p>
                        </div>
                      </div>
                      {/* {lengthOfRole >= 1 && ( */}
                      <h2 className="text-secondary-new alata-bold">
                        Office Bearers
                      </h2>
                      {/* )} */}
                      {lengthOfRole === 0 && (
                        <h1 className="text-secondary-new alata-bold text-capitalize">
                          No Office Bearers
                        </h1>
                      )}
                      <div className="row mb-5">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <div className="row">
                            {updateDetails?.roleDetails ? <>
                              {updatedRoleDetails?.slice(0, displayedOffice)
                                .map((data, index) => (
                                  <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <div
                                      className={`individualChapter_card position-relative ${updateDetails?.removedAdminEmail.length && updateDetails?.removedAdminEmail.includes(data.email) && 'opacity-50'}`}
                                      key={index}
                                    >
                                      {data?.isNew &&
                                        <span class="position-absolute poppins-regular top-0 end-0 badge rounded me-3 mt-3 bg-success">
                                          New
                                          <span class="visually-hidden">New</span>
                                        </span>}
                                      {updateDetails?.removedAdminEmail.length > 0 && updateDetails?.removedAdminEmail.includes(data.email) && <span style={{ opacity: '1 !important' }} class="position-absolute poppins-regular top-0 end-0 badge rounded me-3 mt-3 bg-danger">
                                        Removed
                                        <span class="visually-hidden">Removed</span>
                                      </span>}
                                      {data?.isNew ?
                                        <div className="firstinfo">
                                          <div className="row w-100">
                                            <div className="col-4">
                                              {data?.roleImageUrl ? (
                                                <div>
                                                  <img
                                                    src={data?.roleImageUrl}
                                                    style={{ objectFit: 'contain' }}
                                                    className="aspect4  rounded-circle w-100"
                                                    alt="Office bearer profile"
                                                  />
                                                </div>
                                              ) : getChapterData &&
                                                getChapterData?.roleDetails[index]
                                                  ?.roleImageUrl ? (
                                                <img
                                                  src={
                                                    getChapterData?.roleDetails[index]
                                                      ?.roleImageUrl
                                                  }
                                                  style={{ objectFit: 'contain' }}
                                                  className="aspect4 rounded-circle w-100"
                                                  alt="Office bearer profile"
                                                />
                                              ) : (
                                                <>
                                                  <img
                                                    src={userLogo}
                                                    style={{ objectFit: 'contain' }}
                                                    className="aspect4 rounded-circle w-100"
                                                    alt="Office bearer profile"
                                                  />
                                                </>
                                              )}
                                            </div>
                                            <div className="col-8 ps-4  position-relative">
                                              <div className="alata-regular d-flex flex-column gap-3">
                                                <h3 className="menus-green-color mt-3 fs-4 d-flex w-100 text-capitalize">
                                                  <span className="fw-semibold">Name :{" "}</span>
                                                  {data?.salutation !== (null || undefined) ? (<span >
                                                    {
                                                      Salutation.find(
                                                        (item) =>
                                                          item.id ===
                                                          data?.salutation
                                                      )?.Name
                                                    }
                                                  </span>
                                                  ) : (
                                                    getChapterData?.roleDetails[index]
                                                      ?.salutation !== (null || undefined) && (
                                                      Salutation.find(
                                                        (item) =>
                                                          item.id === getChapterData?.roleDetails[index]
                                                            ?.salutation
                                                      )?.Name
                                                    ))}
                                                  .
                                                  {data?.name ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.name
                                                      }
                                                    </span> :
                                                    getChapterData?.roleDetails[index]
                                                      ?.name && (
                                                      getChapterData?.roleDetails[index]?.name

                                                    )}
                                                </h3>
                                                <h3 className="text-secondary-new  text-capitalize">
                                                  <span className="fw-semibold">Role :{" "}</span>
                                                  {data?.role ?
                                                    (<span >
                                                      {" "}
                                                      {
                                                        data?.role
                                                      }
                                                    </span>
                                                    ) : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.role && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.role
                                                      ))}
                                                </h3>
                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">work :{" "}</span>
                                                  {data?.institution ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.institution
                                                      }
                                                    </span>
                                                    : (getChapterData?.roleDetails[index]
                                                      ?.institution && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.institution
                                                      )
                                                    )}
                                                  (
                                                  {data?.designation ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.designation
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.designation && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.designation
                                                      )
                                                    )}
                                                  )
                                                </p>

                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">Mobile :{" "}</span>
                                                  {data?.mobile ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.mobile
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.mobile ? (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.mobile
                                                      ) : '----'
                                                    )}
                                                </p>
                                                <p className=" text-black m-0 p-0">
                                                  <span className="fw-semibold">Email :{" "}</span>
                                                  {data?.email !== getChapterData?.roleDetails[index]
                                                    ?.email ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.email
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.email && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.email
                                                      )
                                                    )}
                                                </p>
                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">Address :{" "}</span>
                                                  {data?.address ?
                                                    <span>
                                                      {" "}
                                                      {
                                                        data?.address
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.address ? (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.address
                                                      ) : '----'
                                                    )}
                                                  {(data?.pincode || getChapterData?.roleDetails[index]
                                                    ?.pincode) && ' - '}
                                                  {data?.pincode ?
                                                    <span>
                                                      {" "}
                                                      {
                                                        data?.pincode
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.pincode && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.pincode
                                                      )
                                                    )}
                                                </p>
                                                {getChapterData?.roleDetails[index]?.isAdmin && (
                                                  <span class="position-absolute top-0 end-0 badge px-0 py-0">
                                                    <AdminPanelSettingsIcon className="menus-green-color fs-4" />
                                                    <span class="visually-hidden">
                                                      admin icon
                                                    </span>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        :

                                        updateDetails?.removedAdminEmail.length && updateDetails?.removedAdminEmail.includes(data.email) ? <div className="firstinfo">
                                          <div className="row w-100">
                                            <div className="col-4">
                                              {data?.roleImageUrl ? (
                                                <div>
                                                  <img
                                                    src={data?.roleImageUrl}
                                                    style={{ objectFit: 'contain' }}
                                                    className="aspect4  rounded-circle w-100"
                                                    alt="Office bearer profile"
                                                  />
                                                </div>
                                              ) : getChapterData &&
                                                getChapterData?.roleDetails[index]
                                                  ?.roleImageUrl ? (
                                                <img
                                                  src={
                                                    getChapterData?.roleDetails[index]
                                                      ?.roleImageUrl
                                                  }
                                                  style={{ objectFit: 'contain' }}
                                                  className="aspect4 rounded-circle w-100"
                                                  alt="Office bearer profile"
                                                />
                                              ) : (
                                                <>
                                                  <img
                                                    src={userLogo}
                                                    style={{ objectFit: 'contain' }}
                                                    className="aspect4 rounded-circle w-100"
                                                    alt="Office bearer profile"
                                                  />
                                                </>
                                              )}
                                            </div>
                                            <div className="col-8 ps-4  position-relative">
                                              <div className="alata-regular d-flex flex-column gap-3">
                                                <h3 className="menus-green-color mt-3 fs-4 d-flex w-100 text-capitalize">
                                                  <span className="fw-semibold">Name :{" "}</span>
                                                  {data?.salutation !== (null || undefined) ? (<span >
                                                    {
                                                      Salutation.find(
                                                        (item) =>
                                                          item.id ===
                                                          data?.salutation
                                                      )?.Name
                                                    }
                                                  </span>
                                                  ) : (
                                                    getChapterData?.roleDetails[index]
                                                      ?.salutation !== (null || undefined) && (
                                                      Salutation.find(
                                                        (item) =>
                                                          item.id === getChapterData?.roleDetails[index]
                                                            ?.salutation
                                                      )?.Name
                                                    ))}
                                                  .
                                                  {data?.name ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.name
                                                      }
                                                    </span> :
                                                    getChapterData?.roleDetails[index]
                                                      ?.name && (
                                                      getChapterData?.roleDetails[index]?.name

                                                    )}
                                                </h3>
                                                <h3 className="text-secondary-new  text-capitalize">
                                                  <span className="fw-semibold">Role :{" "}</span>
                                                  {data?.role ?
                                                    (<span >
                                                      {" "}
                                                      {
                                                        data?.role
                                                      }
                                                    </span>
                                                    ) : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.role && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.role
                                                      ))}
                                                </h3>
                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">work :{" "}</span>
                                                  {data?.institution ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.institution
                                                      }
                                                    </span>
                                                    : (getChapterData?.roleDetails[index]
                                                      ?.institution && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.institution
                                                      )
                                                    )}
                                                  (
                                                  {data?.designation ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.designation
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.designation && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.designation
                                                      )
                                                    )}
                                                  )
                                                </p>

                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">Mobile :{" "}</span>
                                                  {data?.mobile ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.mobile
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.mobile ? (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.mobile
                                                      ) : '----'
                                                    )}
                                                </p>
                                                <p className=" text-black m-0 p-0">
                                                  <span className="fw-semibold">Email :{" "}</span>
                                                  {data?.email !== getChapterData?.roleDetails[index]
                                                    ?.email ?
                                                    <span >
                                                      {" "}
                                                      {
                                                        data?.email
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.email && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.email
                                                      )
                                                    )}
                                                </p>
                                                <p className="bio  text-capitalize m-0 p-0">
                                                  <span className="fw-semibold">Address :{" "}</span>
                                                  {data?.address ?
                                                    <span>
                                                      {" "}
                                                      {
                                                        data?.address
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.address ? (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.address
                                                      ) : '----'
                                                    )}
                                                  {(data?.pincode || getChapterData?.roleDetails[index]
                                                    ?.pincode) && ' - '}
                                                  {data?.pincode ?
                                                    <span>
                                                      {" "}
                                                      {
                                                        data?.pincode
                                                      }
                                                    </span>
                                                    : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.pincode && (
                                                        getChapterData?.roleDetails[
                                                          index
                                                        ]?.pincode
                                                      )
                                                    )}
                                                </p>
                                                {getChapterData?.roleDetails[index]?.isAdmin && (
                                                  <span class="position-absolute top-0 end-0 badge px-0 py-0">
                                                    <AdminPanelSettingsIcon className="menus-green-color fs-4" />
                                                    <span class="visually-hidden">
                                                      admin icon
                                                    </span>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div> :
                                          <div className="firstinfo">
                                            <div className="row w-100">
                                              <div className="col-4">
                                                {getChapterData?.roleDetails.find(item => item.email === data?.email).roleImageUrl !== data?.roleImageUrl ? (
                                                  <div className="bg-warning">
                                                    <img
                                                      src={data?.roleImageUrl}
                                                      style={{ objectFit: 'contain' }}
                                                      className="aspect4  rounded-circle w-100"
                                                      alt="Office bearer profile"
                                                    />
                                                  </div>
                                                ) : getChapterData &&
                                                  getChapterData?.roleDetails[index]
                                                    ?.roleImageUrl ? (
                                                  <img
                                                    src={
                                                      getChapterData?.roleDetails[index]
                                                        ?.roleImageUrl
                                                    }
                                                    style={{ objectFit: 'contain' }}
                                                    className="aspect4 rounded-circle w-100"
                                                    alt="Office bearer profile"
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src={userLogo}
                                                      style={{ objectFit: 'contain' }}
                                                      className="aspect4 rounded-circle w-100"
                                                      alt="Office bearer profile"
                                                    />
                                                  </>
                                                )}
                                              </div>
                                              <div className="col-8 ps-4  position-relative">
                                                <div className="alata-regular d-flex flex-column gap-3 ">
                                                  <h3 className="menus-green-color mt-3 fs-4 text-capitalize">
                                                    <span className="fw-semibold">Name :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).salutation !== data?.salutation ? (<span className="bg-warning">
                                                      {
                                                        Salutation.find(
                                                          (item) =>
                                                            item.id ===
                                                            data?.salutation
                                                        )?.Name
                                                      }
                                                    </span>
                                                    ) : (
                                                      getChapterData?.roleDetails[index]
                                                        ?.salutation !== (null || undefined) && (
                                                        Salutation.find(
                                                          (item) =>
                                                            item.id === getChapterData?.roleDetails[index]
                                                              ?.salutation
                                                        )?.Name
                                                      ))}
                                                    .
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).name !== data?.name ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.name
                                                        }
                                                      </span> :
                                                      getChapterData?.roleDetails[index]
                                                        ?.name && (
                                                        getChapterData?.roleDetails[index]?.name

                                                      )}
                                                  </h3>
                                                  <h3 className="text-secondary-new  text-capitalize">
                                                    <span className="fw-semibold">Role :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).role !== data?.role ?
                                                      (<span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.role
                                                        }
                                                      </span>
                                                      ) : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.role && (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.role
                                                        ))}
                                                  </h3>
                                                  <p className="bio  text-capitalize m-0 p-0">
                                                    <span className="fw-semibold"> work :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).institution !== data?.institution ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.institution
                                                        }
                                                      </span>
                                                      : (getChapterData?.roleDetails[index]
                                                        ?.institution && (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.institution
                                                        )
                                                      )}
                                                    (
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).designation !== data?.designation ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.designation
                                                        }
                                                      </span>
                                                      : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.designation && (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.designation
                                                        )
                                                      )}
                                                    )
                                                  </p>

                                                  <p className="bio  text-capitalize m-0 p-0">
                                                    <span className="fw-semibold">Mobile :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).mobile !== data?.mobile ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.mobile
                                                        }
                                                      </span>
                                                      : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.mobile ? (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.mobile
                                                        ) : '----'
                                                      )}
                                                  </p>
                                                  <p className=" text-black m-0 p-0">
                                                    <span className="fw-semibold">Email :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).email !== data?.email ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.email
                                                        }
                                                      </span>
                                                      : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.email && (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.email
                                                        )
                                                      )}
                                                  </p>
                                                  <p className="bio  text-capitalize m-0 p-0">
                                                    <span className="fw-semibold">Address :{" "}</span>
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).address !== data?.address ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.address
                                                        }
                                                      </span>
                                                      : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.address ? (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.address
                                                        ) : '---'
                                                      )}
                                                    {(data?.pincode || getChapterData?.roleDetails[index]
                                                      ?.pincode) && ' - '}
                                                    {getChapterData?.roleDetails.find(item => item.email === data?.email).pincode !== data?.pincode ?
                                                      <span className="bg-warning">
                                                        {" "}
                                                        {
                                                          data?.pincode
                                                        }
                                                      </span>
                                                      : (
                                                        getChapterData?.roleDetails[index]
                                                          ?.pincode && (
                                                          getChapterData?.roleDetails[
                                                            index
                                                          ]?.pincode
                                                        )
                                                      )}
                                                  </p>
                                                  {getChapterData?.roleDetails[index]?.isAdmin && (
                                                    <span class="position-absolute top-0 end-0 badge px-0 py-0">
                                                      <AdminPanelSettingsIcon className="menus-green-color fs-4" />
                                                      <span class="visually-hidden">
                                                        admin icon
                                                      </span>
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>


                                      }
                                    </div>
                                  </div>
                                ))}
                            </> : <>
                              {getChapterData?.roleDetails
                                ?.slice(0, displayedOffice)
                                .map((data, index) => (
                                  <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                                    <div
                                      className={`individualChapter_card ${updateDetails?.removedAdminEmail.includes(getChapterData?.roleDetails[index].email) && 'opacity-50 position-relative'}`}
                                      key={index}
                                    >
                                      {updateDetails?.removedAdminEmail.includes(getChapterData?.roleDetails[index].email) && <span style={{ opacity: '1 !important' }} class="position-absolute poppins-regular top-0 end-0 badge rounded me-3 mt-3 bg-danger">
                                        Removed
                                        <span class="visually-hidden">Removed</span>
                                      </span>}
                                      <div className="firstinfo">
                                        <div className="row w-100">
                                          <div className="col-4">
                                            {updateDetails?.roleDetails &&
                                              updateDetails?.roleDetails[index]
                                                ?.roleImageUrl ? (
                                              <img
                                                src={
                                                  updateDetails?.roleDetails[index]
                                                    ?.roleImageUrl
                                                }
                                                style={{ objectFit: 'contain' }}
                                                className="aspect4 rounded-circle w-100"
                                                alt="Office bearer profile"
                                              />
                                            ) : data?.roleImageUrl ? (
                                              <img
                                                src={data?.roleImageUrl}
                                                style={{ objectFit: 'contain' }}
                                                className="aspect4 rounded-circle w-100"
                                                alt="Office bearer profile"
                                              />
                                            ) : (
                                              <>
                                                <img
                                                  src={userLogo}
                                                  style={{ objectFit: 'contain' }}
                                                  className="aspect4 rounded-circle w-100"
                                                  alt="Office bearer profile"
                                                />
                                              </>
                                            )}
                                          </div>
                                          <div className="col-8 ps-4  position-relative">
                                            <div className="alata-regular d-flex flex-column gap-3 ">
                                              <h3 className="menus-green-color mt-3 fs-4 text-capitalize">
                                                <span className="fw-semibold">Name :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.salutation ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      Salutation.find(
                                                        (item) =>
                                                          item.id ===
                                                          updateDetails
                                                            ?.roleDetails[index]
                                                            ?.salutation
                                                      ).Name
                                                    }
                                                  </span>
                                                ) : (
                                                  Salutation.find(
                                                    (item) =>
                                                      item.id === data?.salutation
                                                  ).Name
                                                )}
                                                .
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.name ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.name
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.name
                                                )}
                                              </h3>
                                              <h3 className="text-secondary-new  text-capitalize">
                                                <span className="fw-semibold">Role :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.role ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.role
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.role
                                                )}
                                              </h3>
                                              <p className="bio  text-capitalize m-0 p-0">
                                                <span className="fw-semibold">work :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.institution ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.institution
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.institution
                                                )}
                                                (
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.designation ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.designation
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.designation
                                                )}
                                                )
                                              </p>

                                              <p className="bio  text-capitalize m-0 p-0">
                                                <span className="fw-semibold">Mobile :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.mobile ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.mobile
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.mobile ? data?.mobile : '----'
                                                )}
                                              </p>
                                              <p className=" text-black m-0 p-0">
                                                <span className="fw-semibold">Email :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.email ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.email
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.email
                                                )}
                                              </p>
                                              <p className="bio  text-capitalize m-0 p-0">
                                                <span className="fw-semibold">Address :{" "}</span>
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.address ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.address
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.address ? data?.address : '----'
                                                )}
                                                {(data?.pincode || updateDetails?.roleDetails[index]
                                                  ?.pincode) && ' - '}
                                                {updateDetails?.roleDetails &&
                                                  updateDetails?.roleDetails[index]
                                                    ?.pincode ? (
                                                  <span className="bg-warning">
                                                    {" "}
                                                    {
                                                      updateDetails?.roleDetails[
                                                        index
                                                      ]?.pincode
                                                    }
                                                  </span>
                                                ) : (
                                                  data?.pincode
                                                )}
                                              </p>
                                              {data?.isAdmin && (
                                                <span class="position-absolute top-0 end-0 badge px-0 py-0">
                                                  <AdminPanelSettingsIcon className="menus-green-color fs-4" />
                                                  <span class="visually-hidden">
                                                    admin icon
                                                  </span>
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}</>}
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                      </div>

                      {lengthOfRole > displayedOffice && (
                        <div className="w-100 text-end mb-3">
                          <Button
                            variant="contained"
                            className="menus-color"
                            onClick={loadMoreOffice}
                          >
                            {" "}
                            Load More{" "}
                          </Button>
                        </div>
                      )}
                      {currentUrl.includes("pending") && (
                        <>
                          <div className="w-100 text-end mb-3">
                            <Button
                              variant="contained"
                              color="error"
                              className="rounded-0 me-3 "
                              onClick={() => handleOpen()}
                            >
                              {" "}
                              Reject
                            </Button>
                            <Button
                              variant="contained"
                              className="menus-color"
                              onClick={() => approveReject(0)}
                            >
                              {" "}
                              Approve
                            </Button>
                          </div>
                        </>
                      )}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div
                          style={style}
                          className="d-flex align-items-center flex-column gap-3"
                        >
                          <form className="w-100">
                            <Typography
                              component={"h2"}
                              className="text-center fs-4 fw-bold text-danger mb-2"
                            >
                              Reject Chapter Updates
                            </Typography>
                            <TextField
                              inputProps={{ maxLength: "300" }}
                              fullWidth
                              multiline
                              rows={5}
                              label={
                                <Typography className="text-dark">
                                  Reason for Rejection{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" >
                                    {300 - watch("rejectionReason").length}
                                  </InputAdornment>
                                ),
                              }}
                              error={!!errors?.rejectionReason}
                              helperText={errors?.rejectionReason?.message}
                              {...register("rejectionReason", { required: 'Please enter reason for rejection' })}
                            />
                            <div
                              className="mt-3 mx-auto"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                                style={{ marginRight: "10px" }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="menus-color"
                                variant="contained"
                                color="success"
                                onClick={() => approveReject(1)}
                              >
                                Submit
                              </Button>
                            </div>
                          </form>
                        </div>
                      </Modal>
                    </div>
                  )}
                </>
              )}
              {currentMainTabIndex === 1 && (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          maxWidth: "90%",
                          margin: "0 auto",
                          position: "sticky",
                          top: "0",
                          zIndex: "999",
                          marginBlock: "10px",
                        }}
                      >
                        <Tabs
                          position="static"
                          sx={{
                            backgroundColor: "#611f1e",
                            mx: "auto",
                            width: "90%",
                            display: "flex",
                            flexGrow: 1,
                            "& .MuiButtonBase-root": {
                              color: "white", // Change the color of the scroll buttons
                            },
                          }}
                          value={currentTabIndex}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                        >
                          <Tab
                            label="Events"
                            sx={{
                              width: "25%",
                              color:
                                currentTabIndex === 0
                                  ? "#611f1e !important"
                                  : "white !important",
                              backgroundColor:
                                currentTabIndex === 0
                                  ? "white !important"
                                  : "#611f1e !important",
                            }}
                          />
                          <Tab
                            label="Forums"
                            sx={{
                              width: "25%",
                              color:
                                currentTabIndex === 1
                                  ? "#611f1e !important"
                                  : "white !important",
                              backgroundColor:
                                currentTabIndex === 1
                                  ? "white !important"
                                  : "#611f1e !important",
                            }}
                          />
                          <Tab
                            label="Gallery"
                            sx={{
                              width: "25%",
                              color:
                                currentTabIndex === 2
                                  ? "#611f1e !important"
                                  : "white !important",
                              backgroundColor:
                                currentTabIndex === 2
                                  ? "white !important"
                                  : "#611f1e !important",
                            }}
                          />
                          <Tab
                            label="Announcements"
                            sx={{
                              width: "25%",
                              color:
                                currentTabIndex === 3
                                  ? "#611f1e !important"
                                  : "white !important",
                              backgroundColor:
                                currentTabIndex === 3
                                  ? "white !important"
                                  : "#611f1e !important",
                            }}
                          />
                        </Tabs>
                      </Grid>

                      <div
                      // style={{
                      //     maxHeight: "600px",
                      //     overflowY: "auto",
                      //     "&::-webkit-scrollbar": {
                      //         width: 0,
                      //         height: 0,
                      //     },
                      //     scrollbarWidth: "none", // For Firefox
                      //     msOverflowStyle: "none", // For Internet Explorer & Edge
                      // }}
                      >
                        {currentTabIndex === 0 && (
                          <>
                            <Event />
                          </>
                        )}
                        {/* {currentTabIndex === 1 && (
                                                <>
                                                    <News />
                                                </>
                                            )} */}
                        {currentTabIndex === 1 && (
                          <>
                            <ViewForum></ViewForum>
                          </>
                        )}
                        {currentTabIndex === 2 && (
                          <>
                            <Gallery />
                          </>
                        )}
                        {currentTabIndex === 3 && (
                          <>
                            <ImportantAnnouncementTable />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div >

    </>
  );
}

export default ViewIndividualChapter;
