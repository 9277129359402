import React from 'react'
import './InstitutionalMembers.css'
import image15 from '../../../assets/images/partner/150/15.png'
import { Container } from '@mui/material'
import { AndharPradeshInstitute, Corporates, educationInsititue } from '../../../constant/constant'
const InstitutionalMembers = () => {
  return (
    <div className='ourExecutiveCommittee-containter'>
      <div className='pt-1 '>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='title-font text-center '>Our Institutional Members</h2>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center p-5'>
        <img src={image15} alt='our-partner' className='ourExecutiveCommittee_partner-image' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Andhra Pradesh </h4>
        <h4 className='ourExecutiveCommittee_partner-image-number'>12</h4>
      </div>
      <div className='mt-2 pb-3'>
        <Container maxWidth={"lg"}>
          <div className='d-flex'>
            <div className='row'>
              {AndharPradeshInstitute.map((data, i) => {
                return (
                  <>
                    <div className='col-md-4 col-sm-6  col-xl-3 pt-1 pl-1 containter-partner'>
                      <div>
                        <div className='teacher-asso-card p-3'>
                          <div className='d-flex justify-content-center'>
                            <img src={data.img} width={"100px"} alt='our-partner' className='"our-partner-img' />
                          </div>
                          <p className='signika-font details-partner text-center'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </Container>
      </div>
      <div className='d-flex align-item-center justify-content-center p-5'>
        <img src={image15} className='ourExecutiveCommittee_partner-image' alt='our-partner' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Assam </h4>
        <h4 className='ourExecutiveCommittee_partner-image-number'>1</h4>
      </div>
      <div className='mt-2 pb-3'>
        <Container maxWidth={"lg"}>
          <div className='row'>
            <div className='d-flex justify-content-center'>
              {Corporates.map((data, i) => {
                return (
                  <>
                    <div className='col-md-3 pb-3 pt-1 pl-1 containter-partner'>
                      <div className='pb-3'>
                        <div className='teacher-asso-card p-3 pb-3'>
                          <div className='d-flex justify-content-center'>
                            {data.img ? (
                              <img src={data.img} alt="company" />
                            ) : (
                              <></>
                            )}
                          </div>
                          <p className='signika-font details-partner text-center'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </Container>
      </div>
      <div className='d-flex justify-content-center p-5'>
        <img src={image15} className='ourExecutiveCommittee_partner-image' alt='our-partner' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Arunachala Pradesh</h4>
        <h4 className='ourExecutiveCommittee_partner-image-number'>1</h4>
      </div>
      <div className='mt-2 pb-3'>
        <Container maxWidth={"lg"}>
          <div className='row'>
            <div className='d-flex justify-content-center'>
              {educationInsititue.map((data, i) => {
                return (
                  <>
                    <div className='col-md-3 pt-1 pl-1 containter-partner'>
                      <div>
                        <div className='teacher-asso-card p-1'>
                          <div className='d-flex justify-content-center'>
                            {data.img ? (
                              <img src={data.img} alt="company" />
                            ) : (
                              <></>
                            )}
                          </div>
                          <p className='signika-font details-partner text-center mb-0'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </Container>
      </div>

    </div>
  )
}

export default InstitutionalMembers