import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, IconButton, Typography, Modal, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  usePaginationAnnualConferenceMutation,
  useEnableDisableAnnualConferenceMutation,
  useDeleteAnnualConferenceMutation,
} from '../../../app/services/annualConferenceService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { selectCurrentScope } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EnhancedNewsTableHead = () => {
  const headCells = [
    { id: 'conferenceName', label: 'Conference Name' },
    { id: 'venue', label: 'Venue' },
    { id: 'date', label: 'Date' },
    { id: 'actions', label: 'Actions' },
  ];

  return (
    <TableHead>
      <TableRow className="menus-color">
        {headCells.map((headCell) => (
          <TableCell sx={{ textAlign: 'center', color: '#ffff' }} key={headCell.id} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const AnnualConferenceList = () => {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [scope, setScope] = useState();
  const [annualConferences, setannualConferences] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [getAllPagination] = usePaginationAnnualConferenceMutation();
  const [EnableDisable] = useEnableDisableAnnualConferenceMutation();
  const [deleteAnnualConference] = useDeleteAnnualConferenceMutation();
  const navigate = useNavigate();
  const memberScope = useSelector(selectCurrentScope);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");

  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  const fetchData = useCallback(async () => {
    const data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      role: memberScope
    };
    try {
      const res = await getAllPagination(data);
      if (res?.data?.status) {
        setannualConferences(res?.data?.data?.annualConferenceDetails);
        setTotalPage(res?.data?.data?.totalCount);
      }
      else {
        setannualConferences([]);
        setTotalPage(0);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, itemsPerPage, getAllPagination, memberScope]);

  useEffect(() => { }, [annualConferences])


  const handleEnableDisable = (id) => {
    EnableDisable(id)
      .then((res) => {
        if (res?.data?.data?.isDeleted === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
        fetchData(); // Fetch data after enabling/disabling
      })
      .catch((err) => console.error(err));
  };

  const handleOpenModal = (id) => {
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleClose = () => {
    setDeleteId(null);
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    setOpenModal(false);
    try {
      const res = await deleteAnnualConference(id);

      if (res.data?.status) {
        // Fetch latest list length safely
        const confListLength = annualConferences?.annualConferences?.length || 0;

        if (confListLength <= 1 && page > 0) {
          setPage((prev) => prev - 1); // Update page
        } else {
          fetchData(); // Fetch immediately if no page change is needed
        }

        toast.success("Conference deleted successfully!");
      } else {
        toast.error("Error deleting conference");
      }
    } catch (err) {
      console.error(err);
      toast.error("An unexpected error occurred while deleting.");
    }
  };

  // Trigger fetchData when page updates
  useEffect(() => {
    fetchData();

    if (memberScope == "PORTAL_SUPER_ADMIN") setScope("admin");
    if (memberScope == "PORTAL_ADMIN") setScope("assistantAdmin");
  }, [fetchData]);

  const handleEdit = (id) => {
    navigate(`/${scope}/addEditImport-AnnualConference/${id}`);
  };


  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">Annual Conference List</h2>
          </div>
        </div>
        <div className="p-md-5 p-3 overallPadding">
          <div className="d-flex justify-content-end mt-2 mb-4 align-items-center">
            <Link to={`/${scope}/addEditImport-AnnualConference`}>
              <Button variant="contained" className="menus-color" style={{ color: 'white' }}>
                Add Annual Conference
              </Button>
            </Link>
          </div>
          <Box >
            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedNewsTableHead />
                <TableBody>
                  {annualConferences.length > 0 ? (
                    annualConferences.map((annualConference, index) => (
                      <TableRow
                        hover
                        key={annualConference._id}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                        }}
                      >

                        <TableCell align="center">{annualConference.conferenceName}</TableCell>
                        <TableCell align="center">{annualConference.venue}</TableCell>
                        <TableCell align="center">{annualConference.date}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Edit">
                            <IconButton aria-label="editIcon" onClick={() => handleEdit(annualConference._id)}>
                              {!(annualConference.isDeleted === 2) && <EditIcon className="icon-vieweye-color" />}
                            </IconButton>
                          </Tooltip>
                          {annualConference?.isDeleted === 0 ? (
                            <Tooltip title="Enable">
                              <IconButton
                                aria-label="enable"
                                onClick={() => handleEnableDisable(annualConference._id)}
                              >
                                <LockOpenIcon style={{ color: 'green' }} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Disable">
                              <IconButton
                                aria-label="disable"
                                onClick={() => handleEnableDisable(annualConference._id)}
                              >
                                <LockIcon style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => handleOpenModal(annualConference._id)}>
                              <DeleteIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell colSpan={4} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {annualConferences?.length > 0 && (

              <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                <div className="mb-2 mb-md-0">
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      min={1}
                      placeholder="Page no."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div className="goPage cursor-pointer" onClick={handlePageChange}>
                      <h5 className="p-2 m-0">Go</h5>
                    </div>
                  </div>
                  {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                </div>

                {/* Pagination in the Center */}
                <div className="my-3 my-md-0">
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    forcePage={page}
                    previousLabel={pageCount > 1 && page >= 1 ? 'Previous' : null}
                    nextLabel={pageCount > 1 && page + 1 !== pageCount ? 'Next' : null}
                    disabledClassName={'disablebtn'}
                  />
                </div>
                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      className="itemsperpage"
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('');
                        setSearchTerm('');
                        setPage(0);
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>

                  </FormControl>
                </div>
              </div>
            )}
          </Box>
        </div>
        <Modal
                                    open={openModal}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: 400,
                                          backgroundColor: 'white',
                                          boxShadow: 24,
                                          padding: "20px",
                                          outline: 'none',
                                        }}
                                        className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                                    >

                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                                                Confirmation
                                            </Typography>
                                            <IconButton onClick={handleClose} aria-label="Close">
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        <Box display="flex" justifyContent="center" width="100%">
                                            <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                        </Box>
                                        <Typography
                                            id="modal-modal-title"
                                            style={{ color: "#611f1e" }}
                                            variant="h6"
                                            component="h2"
                                        >
                                            Are You Surely Want to Delete this Annual Conference?
                                        </Typography>
                                        {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button
                                                variant="outlined"
                                                style={{

                                                    color: "#dc3545",
                                                    border: "1px solid #dc3545",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={handleClose}
                                            >
                                                No
                                            </Button>
                                            <Button
                                                className="mx-2"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#2e7d32",
                                                    color: "white",
                                                    padding: "5px 15px",
                                                    borderRadius: "4px",
                                                }}
                                                onClick={() => handleDelete(deleteId)}
                                            >
                                                Yes
                                            </Button>
                                        </div>
                                    </Box>
                                </Modal>
      </Box>
      {/* Modal for confirming delete */}
    </div>
  );
};

export default AnnualConferenceList;