import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../auth/authSlice";

// base query for api
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  // global configuration for the api
  refetchOnMountOrArgChange: 15,
  // refetchOnFocus: true,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
//wrapper with base query.
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // Get the refresh token
    const refreshToken = api.getState().auth.refreshToken;

    if (refreshToken) {
        const credentials = new URLSearchParams();
        credentials.append("refresh_token", refreshToken);
      // Send refresh token as part of the body in the POST request
      const refreshResult = await baseQuery(
        {
          url: "auth/refreshToken",
          method: "POST",
          headers:{'Content-Type': 'application/x-WWW-form-urlencoded',},
          body: credentials, // Send refresh token in the body
        },
        api,
        extraOptions
      );

      if (refreshResult?.data) {
        const user = api.getState().auth.user;
        // Store the new token in the state
        api.dispatch(setCredentials({ ...refreshResult.data, user }));

        // Retry the original query with the new access token
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut()); // Log out if refresh fails
      }
    } else {
      api.dispatch(logOut()); // Log out if no refresh token is available
    }
  }

  return result;
};


// main api for all endpoints
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Chapter",
    "Gallery",
    "testimonial",
    "SIGGROUP",
    "Events",
    "Member",
    "Contact",
    "Announcement",
    "AnnualConference",
  ],
  endpoints: (builder) => ({}),
});
