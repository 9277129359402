import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TopHeader from "../../Component/Header/TopHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import Spinner from "../../Component/spinner/spinner";
import "./journalTabel.css";
import { useGetEventBasedOnFilterMutation } from "../../app/services/eventService";
import GetChapterName from "../../Component/HomePage/GetChapterName";
import GetSIGName from "../../Component/HomePage/GetSigName";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { useLazyGetAllGalleryQuery } from "../../app/services/galleryService";
import { toast } from "react-toastify";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

//table head for News
function EnhancedNewsTableHead() {
  const headCells = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "Date",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: true,
      label: "Created by",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '300px' },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff", width: headCell?.width ? headCell.width : 'auto' }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function JournalTable() {
  const [loading, setLoading] = useState(true);
  const memberScope = useSelector(selectCurrentScope);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [getPastEvents] = useGetEventBasedOnFilterMutation();
  const navigate = useNavigate();
  const [allNews, setAllNews] = useState([]);
  const [getAllGallery] = useLazyGetAllGalleryQuery();
  const userId = useSelector(selectUserId);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");

  //Based on memberscope
  const getLinkPath = (event) => {
    switch (memberScope) {
      case "PORTAL_SUPER_ADMIN":
        return "/admin/add-edit-Gallery?category=" + event?.title.split(' ').join('_')
      case "PORTAL_ADMIN":
        return "/assistantAdmin/add-edit-Gallery?category=" + event?.title.split(' ').join('_')
      case "PORTAL_SIG_ADMIN":
        return "/sigAdmin/add-edit-Gallery?category=" + event?.title.split(' ').join('_')
      case "PORTAL_CHAPTER_ADMIN":
        return "/chapterAdmin/add-edit-Gallery?category=" + event?.title.split(' ').join('_')
      default:
        return "";
    }
  };
  //Based on memberscope
  const routeToGallery = (event) => {
    let galleryId = '';
    getAllGallery().then(res => {
      const gallery = res?.data?.data.filter(item => item?.category === event.title);
      if (gallery.length > 0) { // Check if gallery has any items
        galleryId = gallery[0]._id; // Access the _id of the first item in the gallery
        if (galleryId) {
          switch (memberScope) {
            case "PORTAL_SUPER_ADMIN":
              navigate("/admin/showGallery/" + galleryId);
              break;
            case "PORTAL_ADMIN":
              navigate("/assistantAdmin/showGallery/" + galleryId);
              break;
            case "PORTAL_SIG_ADMIN":
              navigate("/sigAdmin/showGallery/" + galleryId);
              break;
            case "PORTAL_CHAPTER_ADMIN":
              navigate("/chapterAdmin/showGallery/" + galleryId);
              break;
            default:
              return "";
          }
        }
      } else {
        toast.error('No Gallery images on this event');
      }
    });
  };
  //getAllPendingEventList
  const fetchData = useCallback(() => {
    var data = {
      filter: 0, //Past Event
      page: page + 1,
      itemsPerPage: itemsPerPage,
      isApproved:1
    };
    getPastEvents(data)
      .then((res) => {
        setLoading(false);
        setAllNews(res?.data?.data?.eventDetails || []);
        setTotalPage(res?.data?.data.totalCount || 0);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [getPastEvents, page, itemsPerPage])

  //calling event data
  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, [fetchData]); // Update useEffect dependencies

  //page Click for News
  const handleNewsPageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };


  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Our Updates</h2>
        </div>
      </div>
      <div className=' p-md-5 p-3 overallPadding'>
        {loading ? (
          <Spinner />
        ) : (
          <Box>
            <TableContainer component={Paper} sx={{ boxShadow: 0 }} className="rounded-0">
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedNewsTableHead />
                <TableBody>
                  {allNews.length > 0 ? (
                    allNews.map((event, index) => (
                      <TableRow
                        hover
                        key={event._id}
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "white",
                        }}
                      >
                        <TableCell align="center">{event.title}</TableCell>
                        <TableCell width={220} align="center"><p style={{ textAlign: 'justify' }} className="text-black">{event?.description.length > 25 ? event?.description.slice(0, 25) + "..." : event?.description}</p></TableCell>
                        <TableCell align="center">
                          {new Date(event.startDate).toLocaleDateString(
                            "en-In",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                          {event.endDate ? (
                            <>
                              <br></br>to <br />
                            </>
                          ) : (
                            ""
                          )}
                          {event.endDate
                            ? new Date(event.endDate).toLocaleDateString(
                              "en-In",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                            : ""}
                        </TableCell>
                        <TableCell align="center" >
                          {event?.createdBy?.role === "PORTAL_SUPER_ADMIN" ||
                            event?.createdBy?.role === "PORTAL_ADMIN" ? (
                            "ELTAI"
                          ) : event?.createdBy?.role ===
                            "PORTAL_CHAPTER_ADMIN" ? (
                            <GetChapterName id={event?.createdBy?.id} />
                          ) : (
                            <GetSIGName id={event?.createdBy?.id} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {(!(event.isDeleted === 2) && (memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_ADMIN' || event?.createdBy?.id === userId)) && (
                            <Tooltip title="Add Album">
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  navigate(getLinkPath(event))
                                }
                              >
                                <AddPhotoAlternateIcon className="icon-vieweye-color" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!(event.isDeleted === 2) && (
                            <Tooltip title="View Album">
                              <IconButton
                                aria-label="view"
                                onClick={() => {
                                  routeToGallery(event)
                                }
                                }
                              >
                                <PhotoLibraryIcon className="text-success" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <p className="text-black text-center">
                            No Data Avaiable
                          </p>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {allNews.length > 0 ? (
              <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                <div className="mb-2 mb-md-0">
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      min={1}
                      placeholder="Page no."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div className="goPage cursor-pointer" onClick={handlePageChange}>
                      <h5 className="p-2 m-0">Go</h5>
                    </div>
                  </div>
                  {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                </div>

                {/* Pagination in the Center */}
                <div className="my-3 my-md-0">
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handleNewsPageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disablebtn"}
                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                    nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                    forcePage={page}
                  />
                </div>
                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      className="itemsperpage"
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('');
                        setSearchTerm('');
                        setPage(0);
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>

                  </FormControl>
                </div>
              </div>
            ) : (<></>)}
          </Box>
        )}
      </div >
    </>
  );
}

export default JournalTable;