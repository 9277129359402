import React, { useEffect, useState } from 'react';
import { TextField, Typography, Button, Container, FormHelperText, Grid } from '@mui/material';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { useSavePartnerMutation, useLazyGetPartnerByIdQuery, useUpdatePartnerMutation } from '../../../app/services/partnerService';
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { selectCurrentScope } from "../../../app/auth/authSlice";

const AddEditPartner = () => {
    const { register, watch, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: { logo: '', description: '', websiteLink: '' },
    });
    const [savePartner] = useSavePartnerMutation();
    const [getPartnerById] = useLazyGetPartnerByIdQuery();
    const [UpdatePartner] = useUpdatePartnerMutation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const memberScope = useSelector(selectCurrentScope);
    const [imageErrorMessage, setImageErrorMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isValidImage, setIsValidImage] = useState(true);

    // Handle image file change
    const handleImageFileChange = (event) => {
        const file = event.target.files[0];
        setImageErrorMessage(""); // Reset error state
        setSelectedFile(null); // Clear previous image
        setValue("logo", null);

        if (!file) {
            setImageErrorMessage("No file selected.");
            setIsValidImage(false);
            return;
        }

        const validTypes = ["image/jpeg", "image/png"];
        const maxSize = 5 * 1024 * 1024; // 5 MB

        if (!validTypes.includes(file.type)) {
            setImageErrorMessage("Please upload a valid JPEG or PNG image.");
            setIsValidImage(false);
            return;
        }

        if (file.size > maxSize) {
            setImageErrorMessage("File size exceeds 5MB.");
            setIsValidImage(false);
            return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            const base64String = reader.result;
            setSelectedFile(base64String); // Show preview regardless of validation

            // Validate image dimensions
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;

                if (width < 115 || width > 135) {
                    setImageErrorMessage(" Width must be between 115px and 135px.");
                    setIsValidImage(false);
                    return;
                }

                if (height < 65 || height > 85) {
                    setImageErrorMessage(" Height must be between 65px and 85px.");
                    setIsValidImage(false);
                    return;
                }

                // ✅ Set form value only if valid
                setValue("logo", base64String);
                setImageErrorMessage(""); // Clear any previous errors
                setIsValidImage(true);
            };

            img.onerror = () => {
                setImageErrorMessage("Invalid image file.");
                setIsValidImage(false);
            };

            img.src = base64String;
        };

        reader.readAsDataURL(file);
    };


    useEffect(() => {

    }, [imageErrorMessage])

    // Save partner
    const onSavePartner = (data) => {
        if (!isValidImage) {
            return; // Stop the form submission if the image is invalid or greater than 2MB
        }
        setLoading(true);

        // Regex to check if logo is a base64 string
        const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;

        // Validate the logo and ensure it is either base64 or a URL
        const logo = base64Regex.test(data?.logo)
            ? data?.logo
            : typeof data?.logo === 'string' ? data?.logo.split("/").slice(-2).join("/") : '';

        // Prepare partner data
        const partnerData = {
            description: data.description,
            websiteLink: data.websiteLink,
            id: data._id,
            logo: selectedFile || logo, // Ensure logo is correctly assigned
        };

        // Determine the save action (update or create)
        const saveAction = id ? UpdatePartner(partnerData) : savePartner(partnerData);

        // Execute the save action
        saveAction
            .then((res) => {
                if (res?.data?.status) {
                    toast.success(id ? "Partner Updated Successfully!!!" : "Partner Created Successfully!!!");
                    reset();
                    navigate(getRedirectPath());
                } else {
                    toast.error(res?.data?.err?.message || "An error occurred.");
                }
                setLoading(false);
            })
            .catch((err) => {
                toast.error("Error occurred while saving partner.");
                setLoading(false);
                console.error(err);
            });
    };



    // Get redirect path based on user scope
    const getRedirectPath = () => {
        if (memberScope === "PORTAL_SUPER_ADMIN") return "/admin/partner-table";
        return "/assistantadmin/partner-table"; // Default
    };

    // Get data for editing
    useEffect(() => {
        if (id) {
            getPartnerById(id).then((res) => {
                if (res?.data?.status) {
                    setSelectedFile(res?.data?.data?.logo);
                    Object.entries(res?.data?.data).forEach(([key, value]) => {
                        setValue(key, value);
                    });
                }
            });
        }
    }, [getPartnerById, id, setValue]);

    return (
        <>
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">{id ? "Edit Our Partner" : "Add  Our Partner"}</h2>
                </div>
            </div>
            <Container maxWidth={'xxl'} className='p-md-5 p-3 overallPadding' >
                <div className='w-100 text-end mb-3'>
                    <Button
                        onClick={() => window.history.back()}
                        variant="contained"
                        className="menus-color"
                    >
                        Back
                    </Button>
                </div>
                {loading ? <Spinner /> : (
                    <form className='w-100 p-0 m-0' onSubmit={handleSubmit(onSavePartner)}>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                {id ? (
                                    selectedFile && (
                                        <>
                                            <img
                                                src={selectedFile}
                                                alt="Logo Preview"
                                                height="150"
                                                className="img-responsive w-100"
                                            />
                                            <TextField
                                                inputProps={{
                                                    accept: "image/jpeg, image/png",
                                                }}
                                                id="thumbnailImage"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={handleImageFileChange}
                                                alt="Journal Form"
                                            />
                                            <div className="d-flex justify-content-center">
                                                <label
                                                    htmlFor="thumbnailImage"
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <Button
                                                        variant="contained menus-color text-colors border-radius-membership-wallpage-button"
                                                        component="span"
                                                        style={{ whiteSpace: "nowrap" }}
                                                        className="mt-2"
                                                    >
                                                        Choose File
                                                    </Button>
                                                </label>
                                            </div>
                                            {imageErrorMessage && (
                                                <FormHelperText error={imageErrorMessage}>
                                                    {imageErrorMessage}
                                                </FormHelperText>
                                            )}
                                            <FormHelperText className='d-flex justify-content-center'><InfoIcon style={{ color: '#611f1e' }} />
                                                <p style={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '0px' }}>width must be between 115 pixels and 135 pixels.</p></FormHelperText>
                                        </>

                                    )
                                ) : (
                                    <>

                                        <div className='d-flex justify-content-center'>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                style={{
                                                    width: "100%",
                                                }}
                                                inputProps={{
                                                    accept: "image/jpeg, image/png",
                                                }}
                                                id="thumbnailImage"
                                                label={
                                                    <Typography className="text-dark">
                                                        Logo <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                }
                                                variant="outlined"
                                                type="file"
                                                {...register("image", {
                                                    required: { value: !watch('logo'), message: 'Please Select a image' },
                                                    onChange: (e) => handleImageFileChange(e),
                                                })}
                                                error={!!errors?.image || imageErrorMessage} // Make sure the field name matches
                                                helperText={errors?.image?.message || imageErrorMessage}
                                            />


                                            {/* {imageErrorMessage && (
                                                <div style={{ color: "red", marginTop: "10px" }}>
                                                    {imageErrorMessage}
                                                </div>
                                            )} */}
                                        </div>
                                        <FormHelperText className='d-flex'><InfoIcon style={{ color: '#611f1e' }} />
                                            <p style={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '0px' }}>width must be between 115 pixels and 135 pixels.</p></FormHelperText>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    id="websiteLink"
                                    label={<Typography className="text-dark">Website Link <span style={{ color: "red" }}>*</span></Typography>}
                                    variant="outlined"
                                    placeholder="Example:www.sample.com"
                                    {...register("websiteLink", {
                                        required: "Please Enter Website Link",
                                        pattern: {
                                            value: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/i,
                                            message: "Please enter a valid URL",
                                        },
                                    })}
                                    error={!!errors?.websiteLink}
                                    helperText={errors?.websiteLink?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    multiline
                                    id="description"
                                    label={<Typography className="text-dark">Description <span style={{ color: "red" }}>*</span></Typography>}
                                    variant="outlined"
                                    placeholder=" Enter Description"
                                    {...register("description", { required: "Please Enter Description" })}
                                    error={!!errors?.description}
                                    helperText={errors?.description?.message}
                                />
                            </Grid>
                            <Grid item xs={12} className='text-center'>
                                <Button variant="contained" className="menus-color mb-5" type="submit" sx={{ mr: 3 }}>
                                    {id ? "Update" : "Submit"}
                                </Button>

                            </Grid>
                        </Grid>

                    </form>
                )}
            </Container >
        </>
    );
};

export default AddEditPartner;
