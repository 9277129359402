import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Tooltip,
  Typography,
  FormHelperText
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller, } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateMemberShipMutation,
  useLazyGetMemberShipDetailsByIdQuery,
  useUpdateMemberShipMutation,
} from "../../app/services/memberShipServices";
import TopHeader from "../../Component/Header/TopHeader";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { planType } from '../../constant/constant';
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const MemberShipPlan = () => {
  const { membershipId } = useParams();
  const [loading, setLoading] = useState(false);
  const [saveMembershipPlan] = useCreateMemberShipMutation();
  const [getMembershipPlansById] = useLazyGetMemberShipDetailsByIdQuery();
  const [upadteMembershipPlan] = useUpdateMemberShipMutation();
  const [hasDiscount, setHasDiscount] = useState([false]);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    getValues,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      planName: "",
      planType: [{
        planTypeName: "",
        amount: "",
        currencyType: "0", // 0 -INR, 1 - USD
        duration: "",
        offer: 0,
        discount: 0,
      }],
      quantity: 1,
      startDate: new Date().toISOString().split("T")[0],
      endDate: "",
    }, mode: 'onChange'
  });
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleOpen = (i) => {
    setOpen(true);
    setSelectedIndex(i);
  };
  const handleClose = () => setOpen(false);
  const {
    fields: planTypeFields,
    append: appendplanType,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "planType",
  });
  //remove plan type
  const removePlanType = () => {
    removeField(selectedIndex);
    handleClose();
  };

  //Validate year
  const validateYearLength = (value) => {
    const year = new Date(value).getFullYear().toString();
    if (year.length > 4) {
      return "Year cannot exceed 4 digits";
    }
    return true;
  };

  useEffect(() => {
    if (membershipId) {
      setLoading(true);
      getMembershipPlansById(membershipId).then((res) => {
        setLoading(false);
        const data = res.data?.data;
        const planName = planType.find(i => i.planId === data?.planName)
        setValue('planName', planName?.planId)
        setSelectedPlanId(planName?.planId)
        if (data?.startDate) {
          setValue('startDate', data?.startDate.split('T')[0])
        }
        if (data?.endDate) {
          setValue('endDate', data?.endDate.split('T')[0])
        }
        const hasDiscountUpdate = data.planType.map(item => item.discount > 0 ? true : false)
        setFilteredPlanTypes(planType.find((plan) => plan.planId === planName?.planId)?.planType)
        setHasDiscount(hasDiscountUpdate)
        setValue("planType", data?.planType);
        setValue('quantity', Number(data?.quantity))
        data?.planType?.forEach((item, index) => {
          setValue(`planType[${index}].currencyType`, String(item.currencyType));
        });
      });
    }
  }, [getMembershipPlansById, setValue, membershipId]);

  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [filteredPlanTypes, setFilteredPlanTypes] = useState([])

  const handlePlanChange = (event) => {
    const selectedId = event.target.value;
    setSelectedPlanId(selectedId);
    setFilteredPlanTypes(planType.find((plan) => plan.planId === selectedId)?.planType)
    setValue('planType', ''); // Reset planType when planName changes
    const noOfUser = planType.find((plan) => plan.planId === selectedId)?.noOfUser || '';
    // Set quantity to the found value
    setValue('quantity', noOfUser);
  };


  useEffect(() => { }, [getMembershipPlansById, membershipId, setValue]);
  //save and update Membership
  const formSubmit = async (formData) => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      const data = {
        planName: formData.planName,
        quantity: Number(formData.quantity),
        startDate: formData.startDate,
        endDate: formData.endDate,
        planType: formData.planType.map((item) => ({
          ...item,
          planTypeName: item.planTypeName,
          duration: Number(item.duration),
          discount: item.discount ? Number(item.discount) : 0,
          currencyType: Number(item.currencyType),
          amount: Number(item.amount),
          offer: item.offer ? Number(item.offer) : 0,
        })),
      };
      if (isValid) {
        if (membershipId) {
          const updatedData = {
            id: membershipId,
            data: formData
          };
          upadteMembershipPlan(updatedData).then((res) => {
            setLoading(false);
            if (res.data.status) {
              toast.success("Membership Plan Updated Successfully!!!");
              reset();
              navigate("/admin/viewMembershipPlans");
            } else {
              toast.error(res.data?.err?.message);
            }
          });
        } else {
          saveMembershipPlan(data).then((res) => {
            setLoading(false);
            if (res.data.status) {
              toast.success("Membership Plan Added Successfully!!!");
              reset();
              navigate("/admin/viewMembershipPlans");
            } else {
              toast.error(res.data?.err?.message);
            }
          });
        }
      }
    }
  };



  return (
    <>
      <TopHeader />
      <div className='pt-1 pb-3'>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='title-font text-center '>{!membershipId ? "Add Membership Plan" : "Edit Membership Plan"}</h2>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="p-md-5 p-3  overallPadding" >
          <div className="mb-3 w-100 text-end">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color rounded-0"
            >
              Back
            </Button>
          </div>
          <Box component={"form"} onSubmit={handleSubmit(formSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth error={!!errors.planName}>
                  <InputLabel className='text-dark'>Plan Name <span style={{ color: "red" }}>*</span></InputLabel>
                  <Controller
                    name="planName"
                    control={control}
                    rules={{ required: 'Please select a membership plan' }}
                    render={({ field }) => (
                      <Select {...field} readOnly={membershipId} label='Plan Name *' value={selectedPlanId} onChange={(e) => {
                        field.onChange(e);
                        handlePlanChange(e);
                      }}>
                        <MenuItem value="">
                          <em>Select a membership plan</em>
                        </MenuItem>
                        {planType.map((plan) => (
                          <MenuItem key={plan.planId} value={plan.planId}>
                            {plan.planName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.planName && (
                    <FormHelperText>
                      {errors.planName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="quantity"
                  disabled={true}
                  label={
                    <Typography className="text-dark">
                      No of User <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 1, max: 2 }}
                  placeholder='No of user accounts'
                  {...register("quantity", {
                    required: "Please enter a no of user 1 or 2",
                    validate: (value) => {
                      if (value < 1 || value > 2) {
                        return 'Please enter 1 or 2'
                      }
                      else {
                        return null
                      }
                    }
                  })}
                  error={!!errors?.quantity}
                  helperText={errors?.quantity?.message}
                />
              </Grid>
              {hasDiscount.some(i => i) &&
                <>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      id="startDate"
                      label={
                        <Typography className="text-dark">
                          Start Date <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="date"
                      {...register("startDate", {
                        required: 'Please select a start date',
                        validate: validateYearLength, onChange: () => {
                          setValue('endDate', '')
                        }
                      })}
                      // inputProps={{
                      //   min:new Date().toISOString().split("T")[0],
                      // }}
                      error={!!errors?.startDate}
                      helperText={errors?.startDate?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      id="endDate"
                      label={
                        <Typography className="text-dark">
                          End Date <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="date"
                      inputProps={{
                        min: watch("startDate"),
                      }}
                      {...register("endDate", {
                        required: "Please Select a End Date",
                        validate: validateYearLength
                      })}
                      error={!!errors?.endDate}
                      helperText={errors?.endDate?.message}
                    />
                  </Grid>

                </>
              }

            </Grid>
            <Box>
              <div className="d-flex justify-content-between mt-4 mb-2">
                <h3 className='fw-bold text-black'>Sub Plan</h3>
                <Button
                  variant="contained"
                  className="menus-color rounded-0"
                  sx={{ maxHeight: "40px" }}
                  type="button"
                  onClick={() => {
                    if (!watch('planName')) {
                      toast.error('Please select Main plan name')
                      return
                    }
                    // Check if we exceed the allowed number of sub plans
                    if (planTypeFields.length >= filteredPlanTypes.length) {
                      toast.error(`Only ${filteredPlanTypes.length} sub plans allowed for this plan`);
                      return;
                    }

                    // Check if all the selected plan types are valid (not empty)
                    const selectedPlanTypes = watch('planType');
                    const isValid = selectedPlanTypes && selectedPlanTypes.every(i => (i.planTypeName && i.planTypeName.trim() !== '') && i.planTypeName !== undefined && i.planTypeName !== null);

                    if (isValid) {
                      // Append new plan type if valid
                      appendplanType({});
                      clearErrors('planType')
                    } else {
                      // Set error and show toast if a plan type is not selected
                      const lastIndex = selectedPlanTypes.length > 0 ? selectedPlanTypes.length - 1 : 0;
                      setError(`planType[${lastIndex}].planTypeName`, {
                        message: 'Please select a plan type',
                      });
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              {planTypeFields.map((speaker, index) => (
                <Grid container rowSpacing={3} columnSpacing={3} className='mb-3'>
                  <Grid item xs={12} md={4} lg={2}>
                    <FormControl fullWidth error={!!errors?.planType?.[index]?.planTypeName}>
                      <InputLabel className='text-dark'>Plan Type <span className='text-danger'>*</span></InputLabel>
                      <Controller
                        name={`planType[${index}].planTypeName`}
                        control={control}
                        rules={{ required: 'Please select a plan type' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label='Plan Type *'
                            disabled={!watch('planName')}
                            onChange={(e) => {
                              field.onChange(e); // Update field value
                              setValue(`planType[${index}].planTypeName`, e.target.value)
                              // Additional logic if needed
                            }}
                          >
                            <MenuItem value="">
                              <em>Select a plan type</em>
                            </MenuItem>
                            {filteredPlanTypes && filteredPlanTypes.map((type) => (
                              <MenuItem disabled={watch('planType')&&watch('planType').some((item2) => { return item2?.planTypeName === type?.planTypeName })} key={type.planTypeId} value={type.planTypeName}>
                                {type.planTypeName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {!!errors?.planType?.[index]?.planTypeName && (
                        <FormHelperText>{errors?.planType?.[index]?.planTypeName?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Plan Type Amount{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`planType[${index}].amount`, {
                        required: "Please enter a valid amount ",
                        pattern: {
                          value: /^\d+$/,
                          message: "Please enter a numeric value",
                        },
                      })}
                      placeholder="Plantype amount"
                      error={!!errors?.planType?.[index]?.amount}
                      helperText={
                        errors?.planType?.[index]?.amount?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} >

                    <div className="form-group w-100 d-flex flex-row flex-md-column">
                      <label> Currency Type <span className="text-danger">*</span></label>
                      <div>
                        <div className="d-flex">
                          {/* <div className="col-6"> */}
                          <div className="form-check me-5">
                            <input
                              className="form-check-input border border-1 border-dark"
                              type="radio"
                              id='inr'  // Unique ID based on index
                              value="0"
                              checked={watch(`planType[${index}].currencyType`) === "0"}
                              onChange={() => setValue(`planType[${index}].currencyType`, "0")}
                              {...register(`planType[${index}].currencyType`, { value: getValues(`planType[${index}].currencyType`) }, { required: 'Please select an currency type' })}
                            />
                            <label className="form-check-label" htmlFor="inr">
                              ₹
                            </label>
                          </div>
                          {/* </div> */}
                          {/* <div className="col-6"> */}
                          <div className="form-check">
                            <input
                              className="form-check-input border border-1 border-dark"
                              type="radio"
                              id='usd'  // Unique ID based on index
                              value="1"
                              checked={watch(`planType[${index}].currencyType`) === "1"}
                              {...register(`planType[${index}].currencyType`, { required: 'Please select an Currency Type' })}
                              onChange={() => setValue(`planType[${index}].currencyType`, "1")}
                            />
                            <label className="form-check-label" htmlFor='usd'>
                              $
                            </label>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {errors?.planType?.[index]?.currencyType && (  // Correctly access the error
                        <div className="text-danger">
                          {errors?.planType?.[index]?.currencyType?.message}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Offer(%){" "}
                        </Typography>
                      }
                      {...register(`planType[${index}].offer`, {
                        pattern: {
                          value: /^\d+$/,
                          message: "Please enter a numeric value",
                        },
                        validate: (value) => {
                          if (value < 0 || value > 100) {
                            return 'Offer must between 1 to 100'
                          }
                          else {
                            return null
                          }
                        }
                      })}
                      placeholder="Offer (%)"
                      type='number'
                      inputProps={{ min: 0 }}
                      error={!!errors?.planType?.[index]?.offer}
                      helperText={
                        errors?.planType?.[index]?.offer?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Renewal Discount (%){" "}
                        </Typography>
                      }
                      {...register(`planType[${index}].discount`, {
                        onChange: (e) => {
                          const value = e.target.value;
                          const updated = [...hasDiscount]; // Create a copy of the array to maintain immutability

                          // Update the element at the `index` position with the new value (true if value > 0, else false)
                          updated[index] = value > 0;

                          setHasDiscount(updated); // Update state with the new array
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: "Please enter a numeric value",
                        },
                        validate: (value) => {
                          if (value < 0 || value > 100) {
                            return 'discount must between 1 to 100'
                          }
                          else {
                            return null
                          }
                        }
                      })}
                      placeholder="Renewal Discount"
                      error={!!errors?.planType?.[index]?.discount}
                      helperText={
                        errors?.planType?.[index]?.discount?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} >
                    <div className='d-flex gap-0'>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Duration <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`planType[${index}].duration`, {
                          required: "Please Enter A Duration Of Plan",
                          pattern: {
                            value: /^\d+$/,
                            message: "Please Enter A Numeric Value",
                          },
                        })}
                        placeholder="Duration of Plan"
                        error={
                          !!errors?.planType?.[index]?.duration
                        }
                        helperText={
                          errors?.planType?.[index]?.duration
                            ?.message
                        }
                      />
                      {index !== 0 && (
                        <div className="d-flex p-0 m-0 align-items-center justify-content-center">
                          <Tooltip title='Delete'>
                            <IconButton
                              className='text-danger p-0'
                              onClick={() => handleOpen(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </Grid>

                </Grid>
                // <Stack direction={{sm:'column',md:'row',lg:'row'}} spacing={3} key={speaker.id}>
                // </Stack>
              ))}
              <div className="text-center">
                <Button variant="contained" className="menus-color rounded-0" type="submit" >
                  {membershipId ? 'Update' : 'Submit'}
                </Button>
              </div>
            </Box>
          </Box>
        </div>

      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex align-items-center flex-column gap-3">
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e" }}
            variant="h6"
            component="h2"
          >
            Are You Surely Want to Delete this Sub Plan?
          </Typography>
          <div>

            <Button variant="outlined" color="success" onClick={handleClose}>
              No
            </Button>
            <Button
              className="mx-2"
              variant="contained"
              color="error"
              onClick={() => removePlanType()}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MemberShipPlan;
