import React, { useEffect, useState } from 'react';
import TopHeader from '../../Component/Header/TopHeader';
import { useLocation } from 'react-router-dom';
import '../../Pages/Membership/MebershipManagement.css';
import { SuperAdminManagement, ChapterAdminManagement, SigAdminManagement, AssistantAdminManagement } from '../../constant/constant';
import { useSelector } from 'react-redux';
import { selectCurrentScope } from '../../app/auth/authSlice';
import { selectCurrentChapterAdmin } from '../../app/features/chapterAdminSlice';
import { selectCurrentSig } from '../../app/features/sigSlice';

const DynamicManagementHomePage = () => {
    const memberScope = useSelector(selectCurrentScope)
    const [management, setManagement] = useState([]);
    const currentLocation = useLocation();
    const chapter=useSelector(selectCurrentChapterAdmin)
    const sig=useSelector(selectCurrentSig)

    useEffect(() => {
        var currentPath = currentLocation.pathname.split('/');
        if (currentPath[2] === 'management') {
            setManagement((memberScope === "PORTAL_SIG_ADMIN") ? {...SigAdminManagement,sigAdminSigDetails:SigAdminManagement.sigAdminSigDetails.map(i=>i.name==="SIG Management"?{...i,url:i.url+'/'+sig?._id}:i)} : (memberScope === "PORTAL_CHAPTER_ADMIN") ? {...ChapterAdminManagement,chapterAdminChapterDetails:ChapterAdminManagement.chapterAdminChapterDetails.map(i=>i.name==="Chapter Management"?{...i,url:i.url+'/'+chapter?._id}:i)} : (memberScope === "PORTAL_ADMIN") ? AssistantAdminManagement : SuperAdminManagement);
        }
    }, [currentLocation.pathname,chapter?._id,sig?._id, memberScope]);

    useEffect(() => {
        console.log(management, "SuperAdminManagement");
    }, [management]);


    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">
                        Management
                    </h2>
                </div>
            </div>

            <div className="accordion p-md-5 p-3 overallPadding" id="accordionExample">
                {Object.entries(management).map(([sectionKey, sectionValue], sectionIndex) => (
                    <div className="accordion-item" key={sectionKey}>
                        <h2 className="accordion-header title-font" id={`heading${sectionIndex}`}>
                            <button
                                className={`accordion-button ${sectionIndex === 0 ? "" : "collapsed"}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${sectionIndex}`}
                                aria-expanded={sectionIndex === 0 ? "true" : "false"}
                                aria-controls={`collapse${sectionIndex}`}
                            >
                                {sectionValue[0].name}
                            </button>
                        </h2>
                        <div
                            id={`collapse${sectionIndex}`}
                            className={`accordion-collapse collapse ${sectionIndex === 0 ? "show" : ""}`}
                            aria-labelledby={`heading${sectionIndex}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="row accordion-body d-flex flex-column flex-md-row justify-content-md-center m-0 content-font">
                                {sectionValue.map((item, itemIndex) => (
                                    <div key={itemIndex} className="mb-3 border menus p-0 col-12 col-md-6 col-lg-3" >
                                        <p className='text-center mb-0 p-1'>{item.type}</p>
                                        <h6 className="text-center border-bottom px-2 managementHeading">{item.heading}</h6>
                                        <a href={item.url} className="btn btn-link d-flex justify-content-center">
                                            <p className="p-3 text-center managementContent d-flex align-items-center">{item.content}</p>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
};

export default DynamicManagementHomePage;
