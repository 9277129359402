import React, { useState, useEffect, useCallback } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, Button, IconButton, Typography, Modal, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  selectCurrentScope,
  selectUserId,
} from "../../../app/auth/authSlice";
import { useSelector } from 'react-redux';
import { selectCurrenChapterRole } from "../../../app/features/chapterAdminSlice";
import { usePaginationPartnerMutation, useDeletePartnerMutation, useLazyGetEnableDisableQuery } from '../../../app/services/partnerService'
import { FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";


const PartnerTable = () => {
  const [PaginationPartner] = usePaginationPartnerMutation();
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [textopen, setTextOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [deletePartner] = useDeletePartnerMutation()
  const [EnableDisable] = useLazyGetEnableDisableQuery()
  const [openModal, setOpenModal] = useState(false);
  const [Description, setDescription] = useState();
  const userId = useSelector(selectUserId);
  const roleDetails = useSelector(selectCurrenChapterRole);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");




  const handleOpenModal = (id) => {
    setOpenModal(true);
    setSelectedMember(id);
  };

  const handleClose = () => {
    setOpenModal(false);
    setTextOpen(false)
  };


  const memberScope = useSelector(selectCurrentScope);

  const handleOpenTextModal = (data) => {
    setTextOpen(true)
    setDescription(data)
  }

  // Memoized fetchPartners using useCallback
  const fetchPartners = useCallback(async () => {
    const data = {
      page: page + 1, // Increment page for the API request
      itemsPerPage: itemsPerPage,
      //   role: (memberScope) ? memberScope : ""
    };

    try {
      const res = await PaginationPartner(data);
      console.log(res,'77')

      if (res?.data?.status) {
        setPartners(res?.data?.data.partnerDetails);
        setTotalPage(res?.data?.data?.totalCount || 0);
      } else {
      }
    } catch (error) {
    } finally {
    }
  }, [page, itemsPerPage, PaginationPartner,]); // Only recreate the function if page or rowsPerPage changes

  // Trigger the memoized fetchPartners function when page or rowsPerPage changes
  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]); // Call fetchPartners whenever the callback updates
  // useEffect(() => {
  // }, [partners])

  //table
  const EnhancedNewsTableHead = () => {
    const headCells = [
      { id: "logo", label: "Logo" },
      { id: "WebsiteLink", label: "Website Link" },
      { id: "Description", label: "Description" },
      { id: "Actions", label: "Actions" }
    ];

    return (
      <TableHead>
        <TableRow className='menus-color'>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              padding="normal"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  //PageClick
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //handleDelete
  const handleDelete = () => {
    deletePartner(selectedMember).then((res) => {
      if (res.data?.status) {
        toast.success(res.data.message);
        handleClose();
        if (partners.length <= 1) {
          setPage(page - 1);
          fetchPartners()
        } else {
          fetchPartners()
        }
      } else {
        toast.error(res.data?.err?.message);
      }
    });
  }

  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id).then((res) => {
      console.log(res?.data)
      if (res?.data?.data?.isDeleted === 0) {
        fetchPartners()
        toast.success(res?.data?.message);
      } else {
        fetchPartners()
        toast.error(res?.data?.message);
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };



  //Approve

  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center title-font text-light py-3 mt-1">Our Partner List</h2>
          </div>
        </div>

        <Container maxWidth={'xxl'} className='w-100 p-md-5 p-3 overallPadding'>
          <div className="text-end w-100" style={{ marginBottom: "-10px" }}>
            <Link to={
              memberScope === "PORTAL_SUPER_ADMIN"
                ? "/admin/add-edit-partner"
                : "/assistantadmin/add-edit-partner"
            } className='mt-5'>
              <Button variant="contained" className="menus-color" style={{ color: "white" }}>
                Add Partner
              </Button>
            </Link>
          </div>
          <Box className="memberTable mb-5 p-0" >
            <Paper sx={{ width: "100%", mb: 1 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedNewsTableHead />
                  <TableBody>
                    {partners.length > 0 ? (
                      partners.map((partner, index) => (
                        <TableRow
                          hover
                          key={partner._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            <img src={partner?.logo} alt="logo" width={"70px"} height="70px" />
                          </TableCell>
                          <TableCell align="center" >
                            <a href={partner?.websiteLink?.startsWith('http') ? partner.websiteLink : `https://${partner?.websiteLink}`} target='_blank' rel="noreferrer">{partner?.websiteLink}</a>
                          </TableCell>
                          <TableCell align="center" onClick={() => handleOpenTextModal(partner?.description)}>
                            {(partner?.description.length < 50) ? partner?.description : partner?.description.slice(0, 50) + "..."}
                          </TableCell>
                          <TableCell align="center">
                            {
                              partner?.isDeleted === 0 && <Tooltip title="Edit">
                                <IconButton aria-label="edit">
                                  <Link
                                    to={
                                      memberScope === "PORTAL_SUPER_ADMIN"
                                        ? "/admin/add-edit-partner/" + partner?._id
                                        : "/assistantadmin/add-edit-partner/" + partner?._id
                                    }
                                  >

                                    <EditIcon style={{ color: "green" }} />

                                  </Link>
                                </IconButton>
                              </Tooltip>
                            }
                            {partner?.isDeleted === 0 ? (
                              <Tooltip title="Enable">
                                <IconButton
                                  aria-label="enable"
                                  onClick={() => {
                                    handleEnableDisable(partner._id);
                                  }}
                                >
                                  <LockOpenIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            ) : partner?.isDeleted === 2 ? (
                              <Tooltip title="Disable">
                                <IconButton
                                  aria-label="disable"
                                  onClick={() => {
                                    handleEnableDisable(partner._id);
                                  }}
                                >
                                  <LockIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleOpenModal(partner._id);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                            {(memberScope === "PORTAL_SUPER_ADMIN"
                              ? partner?.createdBy?.id === userId &&
                              (partner?.isApproved === 0 || partner?.isApproved === 2)
                              : Object.keys(roleDetails).length !== 0
                                ? partner?.createdBy?.id === userId &&
                                (partner?.isApproved === 0 || partner?.isApproved === 2)
                                : partner?.createdBy?.id === userId &&
                                (partner?.isApproved === 0 || partner?.isApproved === 2)) && (
                                <>



                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow hover>
                          <TableCell colSpan={5} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                  <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                     style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        backgroundColor: "white",
                        boxShadow: 24,
                        padding: "20px",
                        outline: "none",
                      }}
                    className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                  >

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                        Confirmation
                      </Typography>
                      <IconButton onClick={handleClose} aria-label="Close">
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%",marginTop:'-20px' }} />
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      style={{ color: "#611f1e" }}
                      variant="h6"
                      component="h2"
                    >
                      Are You Surely Want to Delete this Spotlight?
                    </Typography>
                    {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="outlined"
                        style={{
                         
                          color:"#dc3545",
                          border: "1px solid #dc3545",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={handleClose}
                      >
                        No
                      </Button>
                      <Button
                        className="mx-2"
                        variant="contained"
                        style={{
                           backgroundColor: "#2e7d32",
                          color: "white",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                    </div>
                  </Box>
                </Modal>
                  <Modal
                    open={textopen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box

                      className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        backgroundColor: 'white',
                        boxShadow: 24,
                        padding: "20px",
                        outline: 'none',
                      }}
                    >
                      <div>
                        <p style={{ color: "#611f1e" }}>{Description}</p>
                        <div className='d-flex justify-content-center'>
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={handleClose}
                          >
                            close
                          </Button>
                        </div>

                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
            <br></br>
            {partners?.length > 0 && (

              <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                <div className="mb-2 mb-md-0">
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      min={1}
                      placeholder="Page no."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div className="goPage cursor-pointer" onClick={handlePageChange}>
                      <h5 className="p-2 m-0">Go</h5>
                    </div>
                  </div>
                  {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                </div>

                {/* Pagination in the Center */}
                <div className="my-3 my-md-0">
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={page}
                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                    nextLabel={
                      pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                    }
                    disabledClassName={"disablebtn"}
                  />
                </div>
                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      className="itemsperpage"
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('');
                        setSearchTerm('');
                        setPage(0);
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>

                  </FormControl>
                </div>
              </div>
            )}
            <br></br>
          </Box>
        </Container>
        {/* </div> */}
      </Box>
    </div>
  );
};

export default PartnerTable;

