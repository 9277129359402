import { Modal } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import { useForm } from "react-hook-form";
import {
  useSaveEventTypeMutation,
  usePaginationEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
  useLazyGetEnableDisableEventTypeQuery
} from "../../app/services/eventTypeServices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../../style/css/SIGGroupTable.css";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner"
import ReactPaginate from "react-paginate";
import Tooltip from "@material-ui/core/Tooltip";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function EventType() {
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [saveEventType] = useSaveEventTypeMutation();
  const [updateEventType] = useUpdateEventTypeMutation();
  const [deleteEventType] = useDeleteEventTypeMutation();
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [getPagitionEventType] = usePaginationEventTypeMutation();
  const [page, setPage] = React.useState(0);//state for set page
  const [totalPage, setTotalPage] = useState(0);
  const [allEventType, setAllEventType] = useState([])
  const [error, setError] = useState("");
  const [enableDistable] = useLazyGetEnableDisableEventTypeQuery()
  //handle model open for delete
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedEventType(id);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // modal close
  const handleClose = () => setOpen(false);
  const { register, handleSubmit, trigger, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      color: "#fff",
    },
  });

  //getAllPendingEventList
  const fetchData = useCallback(() => {
    var data = {
      type: 0,
      page: page + 1,
      itemsPerPage: itemsPerPage
    }
    getPagitionEventType(data).then((res) => {
      setLoading(false);
      setAllEventType(res?.data?.data?.eventTypeDetails || []);
      setTotalPage(res?.data?.data.totalCount || 0);
    })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching pending event list:", error);
      });
  },[getPagitionEventType,page,itemsPerPage])

  //calling event data
  useEffect(() => {
    fetchData();
  }, [fetchData, page, itemsPerPage, searchTerm]);



  //page Click for event type
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //sliced Event type
  const slicedEventType = allEventType.slice(0, itemsPerPage); // Adjust slicing

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };


  //save and update event type
  const onSaveEventType = (formData) => {
    setLoading(true);
    // Trigger validation before submitting
    trigger().then((isValid) => {
      if (isValid) {
        setLoading(true)
        setData([...data, formData]);
        if (updatedData) {
          let requestIdAndData = { id: updatedData._id, data: formData };
          updateEventType(requestIdAndData)
            .then((res) => {
              if (res.data.status) {
                setLoading(false)
                fetchData();
                toast.success(res.data?.message)
                setUpdatedData(null)
                reset();
              } else {
                setLoading(false)
                toast.error(res.data?.err?.message)
              }
            })
            .catch((error) => {
              toast.error(error)
            });
        } else {
          saveEventType(formData)
            .then((res) => {
              setLoading(true)
              if (res.data?.status) {
                setLoading(false)
                fetchData();
                toast.success(res.data?.message)
                reset();
              }
              else {
                setLoading(false)
                toast.error(res.data?.err?.message)
              }
            })
            .catch((error) => {
              setLoading(false)
              toast.error(error)
            });
        }
      } else {
        toast.warning('Form Not Valid')
      }
    });
  };

  //patching event type form values
  const handleEdit = (updatedData) => {
    setUpdatedData(updatedData);
    setValue("name", updatedData.name, { shouldValidate: true });
    setValue("color", updatedData.color, { shouldValidate: true });
  };

  //cancel
  const handleCancel = () => {
    reset();
    setUpdatedData({});
  };

  //delete Event Type
  const handleDelete = (
    requestIdAndIsDeleteFlag = { id: selectedEventType, isDeleted: 1 }
  ) => {
    setLoading(true);
    deleteEventType(
      selectedEventType,
      requestIdAndIsDeleteFlag.isDeleted
    ).then((res) => {
      setLoading(true)
      if (res.data?.status) {
        setLoading(false)
        toast.success(res.data?.message)
        handleClose();
        if (allEventType.length <= 1) {
          setPage(page - 1);
          fetchData();
        } else {
          fetchData();
        }
      }
      else {
        setLoading(false)
        toast.error(res.data?.err.message)
      }
    });
  };

  //Enable disable
  const handleEnableDisable = (id) => {
    enableDistable(id).then((res) => {
      if (res?.data?.data?.isDeleted === 0) {
        fetchData();
        toast.success("Event Enable Sucessfully");
      } else {
        fetchData();
        toast.error("Event Disable Sucessfully");
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  //Table head for event type
  function EnhancedTableHead() {
    const headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Event Type Name",
      },
      { id: "calories", numeric: true, disablePadding: false, label: "Color" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };
  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Event Type</h2>
        </div>
      </div>
      <span className="ms-4 mb-5" style={{ width: '140px' }}></span>
      <div className="p-md-5 p-3  overallPadding"
        style={{ marginTop: "10px" }}
      >
        <form onSubmit={handleSubmit(onSaveEventType)}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: 'center',
              gap: '20px',
              marginTop: '5px'
            }}
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              style={{ width: "40%" }}
              id="name"
              label={(
                <Typography className='text-dark'>
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              variant="outlined"
              error={!!errors?.name}
              helperText={errors?.name?.message}
              placeholder="Please Enter a Event Type"
              {...register("name", { required: "Please enter a event type" })}
            />
            {/* <TextField
              label={(
                <Typography className='text-dark'>
                  Choose Color: <span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              InputLabelProps={{ shrink: true }}
              style={{ width: "40%", marginRight: "20px" }}
              id="Color"
              name="color"
              type="color"
              error={!!errors?.color}
              helperText={errors?.color?.message}
              {...register("color", {
                required: "Please select a color", validate: value => { if (value === "#ffffff") return "White color is not allowed" }
              },)}
            /> */}
            <TextField
              label={(
                <Typography className='text-dark'>
                  Choose Color: <span style={{ color: 'red' }}>*</span>
                </Typography>
              )}
              InputLabelProps={{ shrink: true }}
              style={{ width: "40%", marginRight: "20px" }}
              id="Color"
              name="color"
              type="color"
              error={!!errors?.color}
              helperText={errors?.color?.message}
              {...register("color", {
                required: "Please select a color",
                validate: value => {
                  const colorValue = value.toLowerCase(); // Normalize case
                  return colorValue === "#ffffff" ? "White color is not allowed" : true;
                }
              })}
            />

            {updatedData && updatedData._id ? (
              <>
                <Button
                  variant="contained"
                  className="menus-color"
                  type="submit"
                  style={{
                    backgroundColor: "#1769aa",
                    color: "#fff",
                    padding: "6px 16px",
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="menus-color"
                  type="submit"
                  style={{
                    backgroundColor: "#1769aa",
                    color: "#fff",
                    padding: "6px 16px",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                style={{
                  backgroundColor: "#1769aa",
                  color: "#fff",
                  padding: "6px 16px",
                  borderRadius: "4px",
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </form>
        <Box sx={{ width: "100%" }} className="memberTable ">
          {loading ? <Spinner></Spinner> :
            <Paper sx={{ width: "100%", marginBottom: 5 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {
                      slicedEventType.length > 0 ? (slicedEventType.map((row, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell
                            style={{
                              // display: 'flex',
                              //     justify-content: 'center',
                              //     position: 'relative',
                              //     top: '13px',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'relative',
                              top: '13px',
                              borderBottom: 'none'
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: row.color,
                                width: "100px",
                                height: "20px",
                              }}
                            ></div>
                          </TableCell>
                          <TableCell align="center">
                            {row?.isDeleted !== 2 && (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => handleEdit(row)}
                                >
                                  <EditIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            )}

                            {row?.isDeleted === 0 ? <><Tooltip title="Enable">
                              <IconButton
                                aria-label="enable"
                                onClick={() => {
                                  handleEnableDisable(row._id);
                                }}
                              >
                                <LockOpenIcon style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip></> : row?.isDeleted === 2 ? <>
                              <Tooltip title="Disable">
                                <IconButton
                                  aria-label="disable"
                                  onClick={() => {
                                    handleEnableDisable(row._id, false); // Assuming the second action disables
                                  }}
                                >
                                  <LockIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </> : null}
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleOpen(row._id);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Event Type?
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{ color: "#2e7d32", border: "1px solid rgba(46, 125, 50, 0.5)", padding: "5px 15px", borderRadius: "4px" }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{ backgroundColor: "#dc3545", color: "white", padding: "5px 15px", borderRadius: "4px" }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
          }
          {/* {slicedEventType.length > 0 ? (
          <ReactPaginate
            pageCount={pageCount} // Calculate total pages
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
            nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
            disabledClassName={"disablebtn"}
            forcePage={page}
          />
        ) : (<></>)} */}
          {/* <div className="d-flex justify-content-center justify-content-md-between" style={{  alignItems: "center", marginTop: "20px", marginBottom: "56px", flexWrap:'wrap' }}> */}
          {slicedEventType.length > 0 ? (
            <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
              <div className="mb-2 mb-md-0">
                <div className="d-flex gap-2">
                  <input
                    type="number"
                    min={1}
                    placeholder="Page no."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                  />
                  <div className="goPage cursor-pointer" onClick={handlePageChange}>
                    <h5 className="p-2 m-0">Go</h5>
                  </div>
                </div>
                {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
              </div>

              {/* Pagination in the Center */}
              <div className="my-3 my-md-0">
                <ReactPaginate
                  pageCount={pageCount} // Calculate total pages
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                  nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                  disabledClassName={"disablebtn"}
                  forcePage={page}
                />

              </div>

              <div className="mb-2 mb-md-0">
                <FormControl variant="outlined">
                  <InputLabel></InputLabel>
                  <Select
                    className="itemsperpage"
                    value={itemsPerPage}
                    onChange={(e) => {
                      const value = e.target.value;
                      setItemsPerPage(value);
                      setError('')
                      setSearchTerm('');
                      setPage(0)
                    }}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                      },
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : null}
        </Box>
      </div >

    </>
  );
}

export default EventType;