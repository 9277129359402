import { api } from "./api";

const SIGGroupServices = api.injectEndpoints({
  tagTypes: ['SIGGroup'],
  endpoints: (builder) => ({

    //saveSIGGroup
    saveSIGGroup: builder.mutation({
      query: (newSIGGroupData) => ({
        url: "sigGroup/saveSigGroup",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newSIGGroupData,
      }),
      invalidatesTags: ["SIGGroup"],
    }),
    
    //getAllSIGGroup
    getAllSIGGroup: builder.query({
      query: () => ({
        url: "sigGroup/getAll",
      }),
      invalidatesTags: ["SIGGroup"],
    }),

    //getSIG Group By Id
    getSIGGroupById: builder.query({
      query: (id) => `sigGroup/get/${id}`,
    }),
    // getSIGGroupRoleDetailsById: builder.query({
    //   query: (id) => "sigGroup/getRoleDetails/" + id,
    // }),
 
    //Update Sig Group
    updateSIGGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `sigGroup/update/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: ["SIGGroup"],
    }),
    getSIGGroupRoleDetailsById: builder.query({
      query: (id) => "sigGroup/getRoleDetails/" + id,
      providesTags: ['SIGGroup']
    }),
    deleteSIGGroup: builder.mutation({
      query: (data) => ({
        url: `sigGroup/delete/${data.id}`,
        method: "PUT",
        body: { isDeleted: data.isDeleted },
      }),
      invalidatesTags: ["SIGGroup"],
    }),

    //Get All SIG GROUP WITH pagination
    getAllSigPagination: builder.mutation({
      query: (AllSigData) => ({
        url: "sigGroup/getAllSigGroupPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: AllSigData,
      })
    }),
    // updateSIGGroupProfile: builder.mutation({
    //   query: ({ id, data }) => ({
    //     url: `sigGroup/updateSigAdmin//${id}`,
    //     method: "PUT",
    //     headers: { "Content-Type": "application/json" },
    //     body: data,
    //   }),
    //   invalidatesTags: ["SIGGroup"],
    // }),

    //updateSIGGroup profile
    updateSIGGroupProfile: builder.mutation({
      query: (data) => ({
        url: `sigGroup/updateSigAdmin/${data.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags: ['Chapter']
    }),

    //get SIG Join Member List
    getSigJoinMemberList: builder.mutation({
      query: (AllSigData) => ({
        url: "sigGroup/getJoinMemberList",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: AllSigData,
      })
    }),
  }),
  overrideExisting: false,

});

export const {
  useSaveSIGGroupMutation,
  useGetAllSIGGroupQuery,
  useLazyGetAllSIGGroupQuery,
  useLazyGetSIGGroupByIdQuery,
  useGetSIGGroupByIdQuery,
  useUpdateSIGGroupMutation,
  useDeleteSIGGroupMutation,
  useGetAllSigPaginationMutation,
  useGetSigJoinMemberListMutation,
  useUpdateSIGGroupProfileMutation,
  useLazyGet
} = SIGGroupServices;