import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopHeader from "../../Component/Header/TopHeader";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import Spinner from "../../Component/spinner/spinner";
import { useGetSigJoinMemberListMutation } from "../../app/services/SIGGroupServices";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "title", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "Phone Number",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email ID",
    },
  ];
  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function SIGJoinedMember() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [joinMembers, setJoinMembers] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const { id } = useParams();
  const [getJoinMemberList] = useGetSigJoinMemberListMutation();
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");

  // getJoinMemberList
  useEffect(() => {
    getJoinMemberList({ sigId: id, page: page + 1, itemsPerPage: itemsPerPage })
      .unwrap()
      .then((res) => {
        setLoading(false);
        setJoinMembers(res?.data?.joiningMembers || []);
        setTotalPage(res?.data.totalCount || 0);
      })
      .catch(() => { });
  }, [getJoinMemberList, page, itemsPerPage, id]);

  //handlePageClick
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);

  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">SIG Joined Member</h2>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center me-5 mt-5 mb-4">
        <Button
          onClick={() => window.history.back()}
          variant="contained"
          className="menus-color"
        >
          Back
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Box
            sx={{ width: "80%", marginBottom: "110px", marginTop: "20px" }}
            className="memberTable"
          >
            <Paper sx={{ width: "100%", marginTop: "50px", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {joinMembers?.length > 0 ? (
                      joinMembers.map((join, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {join.memberName}
                          </TableCell>
                          <TableCell align="center">
                            {join.memberPhone}
                          </TableCell>
                          <TableCell align="center">
                            {join.memberEmail}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {joinMembers?.length > 0 && (
              <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                <div className="mb-2 mb-md-0">
                  <div className="d-flex gap-2">
                    <input
                      type="number"
                      min={1}
                      placeholder="Page no."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    <div className="goPage cursor-pointer" onClick={handlePageChange}>
                      <h5 className="p-2 m-0">Go</h5>
                    </div>
                  </div>
                  {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                </div>

                {/* Pagination in the Center */}
                <ReactPaginate
                  pageCount={pageCount} // Calculate total pages
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                  nextLabel={
                    pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                  }
                  disabledClassName={"disablebtn"}
                  forcePage={page}
                />

                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      className="itemsperpage"
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('')
                        setSearchTerm('');
                        setPage(0)
                      }}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

            )}
          </Box>
        </div>
      )}
    </>
  );
}
