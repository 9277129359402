import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ReactPaginate from "react-paginate";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ApproveIcon from "@mui/icons-material/CheckCircleOutline";
import {
  useGetAllPendingMemberListMutation,
  useAproveMemberMutation,
  useDeleteMemberMutation,
} from "../../app/services/chapterServices";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/memberTable.css";
import { useParams } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";
import { Button } from "@mui/material";
import { toast } from 'react-toastify'
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import Tooltip from "@mui/material/Tooltip";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";


function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Member Name" },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Chapter Name",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function MemberTable() {
  const { chapterId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [allPendingMembers, setAllPendingMembers] = React.useState([]);
  const [getAllPendingMemberList] = useGetAllPendingMemberListMutation();
  const [approveMember] = useAproveMemberMutation();
  const [deleteMember] = useDeleteMemberMutation();
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");


  //handlePageClick
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };

  //sliced member
  const slicedPendingMember = allPendingMembers.slice(0, itemsPerPage);

  // getAllPendingMemberList
  React.useEffect(() => {
    setLoading(true);
    var data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      chapterId: chapterId
    };
    getAllPendingMemberList(data)
      .then((res) => {
        setLoading(false);
        setAllPendingMembers(res.data.data.pendingMembers || []);
        setTotalPage(res.data.data.totalCount || 0);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, itemsPerPage, chapterId, getAllPendingMemberList]);

  useEffect(() => {
    getAllPendingMemberList(); // Fetch data on component mount
  }, [page, itemsPerPage, getAllPendingMemberList]); // Update useEffect dependencies

  //handle Delete
  const handleDelete = (memberId, chapterId) => {
    setLoading(true);
    const requestData = { memberId, chapterId };
    deleteMember(requestData)
      .then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.error("Member Rejected Successfully!!!");
          setAllPendingMembers((prevMembers) =>
            prevMembers.filter((member) => member.memberId !== memberId)
          );
          if (allPendingMembers.length <= 1) {
            setPage(page - 1);
            getAllPendingMemberList();
          } else {
            getAllPendingMemberList();
          }
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message);
        }
      });
  };

  //handle Approve
  const handleApprove = (memberId, chapterId) => {
    const requestData = { memberId, chapterId };
    setLoading(true);
    approveMember(requestData)
      .then(() => {
        toast.success('Approved Successfully!!!')
        setLoading(false);
        setAllPendingMembers((prevMembers) =>
          prevMembers.filter((member) => member.memberId !== memberId)
        );
        getAllPendingMemberList();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / itemsPerPage);
  const handlePageChange = () => {
    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Member List</h2>
        </div>
      </div>
      <br></br>
      <div className="p-md-5 p-3  overallPadding">
        <div className='mb-3'>
          <Button
            onClick={() => window.history.back()}
            variant="contained"
            className="menus-color mb-3"
            type="submit"
            style={{
              padding: "6px 16px",
              textTransform: "uppercase",
              float: "right",
              marginRight: "3rem"
            }}
          >
            Back
          </Button>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Box
              sx={{ width: "100%" }}
              className="memberTable"
            >
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750, paddingTop: 5 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead />
                    <TableBody>
                      {slicedPendingMember?.length > 0 ? (
                        slicedPendingMember.map((member, index) => (
                          <TableRow
                            hover
                            key={member._id}
                            sx={{
                              cursor: "pointer",
                              backgroundColor:
                                index % 2 === 0 ? "#f0f0f0" : "white",
                            }}
                          >
                            <TableCell align="center">
                              {member.memberName}
                            </TableCell>
                            <TableCell align="center">{member.title}</TableCell>
                            <TableCell align="center">
                              <Tooltip title="Reject">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    handleDelete(member.memberId, member.chapterId)
                                  }
                                >
                                  <HighlightOffTwoToneIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Approve">
                                <IconButton
                                  aria-label="approve"
                                  onClick={() =>
                                    handleApprove(member.memberId, member.chapterId)
                                  }
                                >
                                  <ApproveIcon style={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {allPendingMembers?.length > 0 ? (
                <div className="d-flex justify-content-between flex-md-row flex-column gx-3 gx-md-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        min={1}
                        placeholder="Page no."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>

                  {/* Pagination in the Center */}
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disablebtn"}
                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                    nextLabel={
                      pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                    }
                    forcePage={page}
                  />

                  <div className="mb-2 mb-md-0">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('')
                          setSearchTerm('');
                          setPage(0)
                        }}
                        MenuProps={{
                          MenuListProps: {
                            sx: {
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          },
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
