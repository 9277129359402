import React, { useCallback, useEffect, useState } from 'react'
import { Box, Paper, TableContainer, Table, TableBody, TableCell, TableRow, Tooltip, IconButton, TableHead } from '@mui/material'
import ReactPaginate from 'react-paginate';
import { useGetAllSigPaginationMutation } from '../app/services/SIGGroupServices';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from 'react-router-dom';
function EnhancedTableHead() {
    const headCells = [
        { id: "groupName", numeric: false, disablePadding: true, label: "Group Name" },
        { id: "description", numeric: false, disablePadding: true, label: "Description" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];

    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

function SigPendingTable() {
    const [getAllSig] = useGetAllSigPaginationMutation();
    const [getAllSIGGroup, setGetAllSigGroup] = useState([]);
    const [page, setPage] = useState(0);
    let [totalCount, setTotalCount] = useState(0);
    const rowsPerPage = '10'
    const navigate=useNavigate();

    //fetch data get pagination for sig
    const fetchData = useCallback(() => {
        getAllSig({ page: page + 1, itemsPerPage: rowsPerPage,approve:0, })
            .unwrap()
            .then((res) => {
                if (res.status) {
                    if (res.data.sigDetails.length === 0 && page >= 1) {
                        setPage(page - 1);
                    }
                    setGetAllSigGroup(res?.data.sigDetails || []);
                    setTotalCount(res?.data.totalCount || 0);
                } else {
                    setGetAllSigGroup([]);
                    setTotalCount(0);
                }
            })
            .catch((error) => {
                console.error("Error fetching pending sig group:", error);
            });
    }, [page, rowsPerPage, getAllSig]);
    //calculate pagecount
    const pageCount = Math.ceil(totalCount / rowsPerPage);
    //handle page click
    const handlePageClick = ({ selected }) => {
        setPage(selected);
    };

    // Fetch data on component mount and when dependencies change
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <>
            <Box>
                <>
                    <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EnhancedTableHead />
                            <TableBody>
                                {getAllSIGGroup.length > 0 ? (
                                    getAllSIGGroup.map((row, index) => (
                                        <TableRow
                                            hover
                                            key={index}
                                            sx={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                    index % 2 === 0 ? "#f0f0f0" : "white",
                                            }}
                                        >
                                            <TableCell align="center">{row.groupName}</TableCell>
                                            <TableCell
                                                align="center"
                                                style={{
                                                    width: 500,
                                                }}
                                            >
                                                {row?.description && row?.description.slice(0, 100)}
                                                ...
                                            </TableCell>

                                            <TableCell align="center">
                                                <Tooltip title="View">
                                                    <IconButton
                                                        onClick={() => {navigate('/admin/view-pending-sig/'+row?._id)}}
                                                    >
                                                        <VisibilityIcon className='icon-vieweye-color' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No Data Available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                <div className="w-100 d-flex justify-content-center mt-5 pb-4">
                    {getAllSIGGroup.length > 0 ? (
                        <ReactPaginate
                            pageCount={pageCount} // Replace with the total number of pages
                            pageRangeDisplayed={rowsPerPage}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                            nextLabel={
                                pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                            }
                            forcePage={page}
                            disabledClassName={"disablebtn"}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </Box>
        </>
    )
}

export default SigPendingTable