import React, { useState } from 'react'
import './statemanagement.css'
import { Button } from '@mui/material';
import html2pdf from 'html2pdf.js';
const StateMembership = () => {
  const [isLoading, setIsLoading] = useState(false)
  const data = [
    {
      code: "01",
      state: "Andhra Pradesh",
      totalMembers: 1912,
      teacherMemberships: { total: 1639, active: 359, expired: 1280 },
      studentMemberships: { total: 33, active: 26, expired: 7 },
      instituteMemberships: { total: 233, active: 27, expired: 206 },
      corporateMemberships: { total: 4, active: 4, expired: 0 },
      totalActive: 337,
      totalExpired: 1160,
    },
    {
      code: "02",
      state: "Arunachal Pradesh ",
      totalMembers: 1912,
      teacherMemberships: { total: 1639, active: 359, expired: 1280 },
      studentMemberships: { total: 33, active: 26, expired: 7 },
      instituteMemberships: { total: 233, active: 27, expired: 206 },
      corporateMemberships: { total: 4, active: 4, expired: 0 },
      totalActive: 337,
      totalExpired: 1160,
    },
    {
      code: "03",
      state: "Assam ",
      totalMembers: 1912,
      teacherMemberships: { total: 1639, active: 359, expired: 1280 },
      studentMemberships: { total: 33, active: 26, expired: 7 },
      instituteMemberships: { total: 233, active: 27, expired: 206 },
      corporateMemberships: { total: 4, active: 4, expired: 0 },
      totalActive: 337,
      totalExpired: 1160,
    },

  ];
  const generatePDF = () => {
    setIsLoading(true); // Start loading

    const element = document.getElementById('pdf-content');
    const options = {
      margin: [10, 10, 10, 10],
      filename: 'CategoryWiseMembership.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => setIsLoading(false)) // Stop loading on success
      .catch(() => setIsLoading(false)); // Stop loading on error
  };
  return (
    <div className="ourExecutiveCommittee-container">
      <div className="pt-5">
        <header className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">
            State-wise Membership Report
          </h2>
        </header>
      </div>
      <div className="d-flex justify-content-end mt-5 mx-5 flex-wrap">
        <Button
          onClick={generatePDF}
          variant="contained"
          className="menus-color mb-4"
          style={{ minWidth: '150px', fontSize: '14px' }}
          disabled={isLoading}
        >
          {isLoading ? 'Generating PDF...' : 'Download PDF'}
        </Button>
      </div>
      <div className="container mb-5 table-responsive" style={{
        border: '1px solid #ccc',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
      }}>
        <table className="table table-bordered table-striped" id="pdf-content" style={{ tableLayout: 'auto' }}>
          <thead className="thead-dark">
            <tr>
              <th rowSpan="2" className="text-center name-state-background border-bottom-state-wise-pdf "> State Code</th>
              <th rowSpan="2" className="text-center name-state-background border-bottom-state-wise-pdf">States & UTs</th>
              <th rowSpan="2" className="text-center name-state-wise-background border-bottom-state-wise-pdf">Total  Members</th>
              <th colSpan="3" className='individual-teacher-memberships border-bottom-state-wise-pdf '>Individual Teacher Memberships</th>
              <th colSpan="3" className='individual-student-memberships border-bottom-state-wise-pdf'>Individual Student Memberships</th>
              <th colSpan="3" className='individual-institution-memberships border-bottom-state-wise-pdf '>Educational Institute Memberships</th>
              <th colSpan="3" className='individual-corporatee-memberships border-bottom-state-wise-pdf'>Corporate Memberships</th>
              <th colSpan="2" className='totel-pdf-state-management border-bottom-state-wise-pdf'>Total Memberships</th>
            </tr>
            <tr>
              <th className='individual-teacher-memberships border-bottom-state-wise-pdf '>Total</th>
              <th className='individual-teacher-memberships border-bottom-state-wise-pdf'>Active</th>
              <th className='individual-teacher-memberships border-bottom-state-wise-pdf'>Expired</th>
              <th className='individual-student-memberships border-bottom-state-wise-pdf'>Total</th>
              <th className='individual-student-memberships border-bottom-state-wise-pdf'>Active</th>
              <th className='individual-student-memberships border-bottom-state-wise-pdf'>Expired</th>
              <th className='individual-institution-memberships border-bottom-state-wise-pdf'>Total</th>
              <th className='individual-institution-memberships border-bottom-state-wise-pdf'>Active</th>
              <th className='individual-institution-memberships border-bottom-state-wise-pdf'>Expired</th>
              <th className='individual-corporatee-memberships border-bottom-state-wise-pdf'>Total</th>
              <th className='individual-corporatee-memberships border-bottom-state-wise-pdf'>Active</th>
              <th className='individual-corporatee-memberships border-bottom-state-wise-pdf'>Expired</th>
              <th className='totel-pdf-state-management border-bottom-state-wise-pdf'>Active</th>
              <th className='totel-pdf-state-management border-bottom-state-wise-pdf'>Expired</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td className='name-state-background'>{row.code}</td>
                <td className='name-state-background'>{row.state}</td>
                <td className='name-state-wise-background'>{row.totalMembers}</td>
                <td className='individual-teacher-memberships'>{row.teacherMemberships.total}</td>
                <td className='individual-teacher-memberships'>{row.teacherMemberships.active}</td>
                <td className='individual-teacher-memberships'>{row.teacherMemberships.expired}</td>
                <td className='individual-student-memberships fw-bold'>{row.studentMemberships.total}</td>
                <td className='individual-student-memberships'>{row.studentMemberships.active}</td>
                <td className='individual-student-memberships'>{row.studentMemberships.expired}</td>
                <td className='individual-institution-memberships fw-bold'>{row.instituteMemberships.total}</td>
                <td className='individual-institution-memberships'>{row.instituteMemberships.active}</td>
                <td className='individual-institution-memberships'>{row.instituteMemberships.expired}</td>
                <td className='individual-corporatee-memberships fw-bold'>{row.corporateMemberships.total}</td>
                <td className='individual-corporatee-memberships'>{row.corporateMemberships.active}</td>
                <td className='individual-corporatee-memberships'>{row.corporateMemberships.expired}</td>
                <td className='totel-pdf-state-management'>{row.totalActive}</td>
                <td className='totel-pdf-state-management'>{row.totalExpired}</td>
              </tr>
            ))}
            {/* <tr>
              <td className='name-state-background'></td>
              <td className='name-state-background'></td>
              <td className='name-state-background'></td>
              <td className='individual-teacher-memberships'></td>
              <td className='individual-teacher-memberships'></td>
              <td className='individual-teacher-memberships'></td>
              <td className='individual-student-memberships fw-bold'></td>
              <td className='individual-student-memberships'></td>
              <td className='individual-student-memberships'></td>
              <td className='individual-institution-memberships fw-bold'></td>
              <td className='individual-institution-memberships'></td>
              <td className='individual-institution-memberships'></td>
              <td className='individual-corporatee-memberships fw-bold'></td>
              <td className='individual-corporatee-memberships'></td>
              <td className='individual-corporatee-memberships'></td>
              <td className='totel-pdf-state-management'></td>
              <td className='totel-pdf-state-management'></td>
            </tr> */}
            <tr>
              <td className='fw-bold no-of-totel-pdf-state-management' colSpan="2">Total</td>
              <td className='no-of-totel-pdf-state-management fw-bold'>545</td>
              <td className='no-of-totel-pdf-state-management fw-bold'>4980</td>
              <td className='no-of-totel-pdf-state-management fw-bold' >767</td>
              <td className='no-of-totel-pdf-state-management '>1854</td>
              <td className='no-of-totel-pdf-state-management fw-bold'>345</td>
              <td className='no-of-totel-pdf-state-management'>545</td>
              <td className='no-of-totel-pdf-state-management'>656</td>
              <td className='no-of-totel-pdf-state-management fw-bold'>873</td>
              <td className='no-of-totel-pdf-state-management'>234</td>
              <td className='no-of-totel-pdf-state-management'>098</td>
              <td className='no-of-totel-pdf-state-management fw-bold'>193</td>
              <td className='no-of-totel-pdf-state-management'>388</td>
              <td className='no-of-totel-pdf-state-management '>23</td>
              <td className='no-of-totel-pdf-state-management'>1109</td>
              <td className='no-of-totel-pdf-state-management'>09897</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default StateMembership
