import React, { useState, useEffect } from "react";
import "../../style/css/Membership/memberform.css";
import Box from "@mui/material/Box";
import TopHeader from "../../Component/Header/TopHeader";
import { Typography, TextField, Button, Modal } from "@mui/material";
import { useLazyGetForumByIdQuery } from "../../app/services/forumServices";
import {
  useSaveCommentsForumMutation,
  useGetAllCommentsForumByIdQuery,
  useUpdateCommentsForumMutation,
} from "../../app/services/forumCommentsServices";
import {
  selectCurrenSigRole,
  selectCurrentSig,
} from "../../app/features/sigSlice";
import {
  selectCurrenChapterRole,
  selectCurrentChapterAdmin,
} from "../../app/features/chapterAdminSlice";
import { selectCurrentMember } from "../../app/features/memberSlice";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import ReplySharpIcon from "@mui/icons-material/ReplySharp";
import IconButton from "@mui/material/IconButton";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";
import { toast } from "react-toastify";
import { useGetApproveRejectForumMutation } from "../../app/services/forumServices";

//format Date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
};

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "20px",
};

const ReadMoreForum = (props) => {
  const [loading, setLoading] = useState(false);
  const { forum } = useParams();
  const forumId = props?.forumId || forum;
  const chapterRoleDetails = useSelector(selectCurrenChapterRole);
  const chapterCurrentDetails = useSelector(selectCurrentChapterAdmin);
  const sigRoleDetails = useSelector(selectCurrenSigRole);
  const sigCurrentDetails = useSelector(selectCurrentSig);
  const userId = useSelector(selectUserId);
  const memberDetails = useSelector(selectCurrentMember);
  const [getForumById] = useLazyGetForumByIdQuery(forumId);
  const [post, setPost] = useState(null);
  const [saveCommentsForum] = useSaveCommentsForumMutation();
  const [updateCommentsForum] = useUpdateCommentsForumMutation();
  const { data: getAllCommentsForumById, refetch } =
    useGetAllCommentsForumByIdQuery(forumId, {
      // Ensures the query runs automatically on mount
      skip: !forumId,
    });
  const [comments, setComments] = useState([]);
  const memberScope = useSelector(selectCurrentScope);
  const [activeComment, setActiveComment] = useState(null);
  const [displayedComments, setDisplayedComments] = useState(5);
  const [displayedReplies, setDisplayedReplies] = useState({});
  const [approveRejectForum] = useGetApproveRejectForumMutation();
  const navigate = useNavigate();
  const [isApprovedData, setIsApprovedData] = useState();

  const [open, setOpen] = useState(false);

  // //handle open
  const handleOpen = () => {
    setOpen(true);
  };

  // //handle close
  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  //getAllCommentsForumById
  useEffect(() => {
    if (getAllCommentsForumById?.status) {
      setComments(getAllCommentsForumById.data.comment);
    }
  }, [getAllCommentsForumById]);

  useEffect(() => {
    refetch();
  }, [refetch, forumId]);

  const {
    register: registerReject,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    watch: watchReject,
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  //timeago
  const timeAgo = (commentDate) => {
    if (commentDate) {
      const commentDateTime = new Date(commentDate);
      const now = new Date();
      const diffInMs = now - commentDateTime;
      const seconds = Math.floor(diffInMs / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      let timeAgoString = "";
      if (days > 0) {
        timeAgoString = `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        timeAgoString = `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else if (minutes > 0) {
        timeAgoString = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else {
        timeAgoString = `${seconds} second${seconds > 1 ? "s" : ""} ago`;
      }
      return timeAgoString;
    }
  };

  // useForm hook for form data handling
  const { register, handleSubmit, reset, trigger } = useForm({
    defaultValues: {
      forumId: forumId,
      comment: {
        adminId: "",
        sigId: sigCurrentDetails?._id,
        sigRoleId: sigRoleDetails?._id,
        chapterId: chapterCurrentDetails?._id,
        chapterRoleId: chapterRoleDetails?._id,
        memberId: memberDetails?._id,
        commentContent: "",
      },
    },
  });

  // useForm hook for form data handling
  const {
    register: replyRegister,
    handleSubmit: replyHandleSubmit,
    reset: replyReset,
    setValue,
    trigger: replyTrigger,
    watch,
  } = useForm({
    defaultValues: {
      forumId: forumId,
      commentId: "",
      reply: {
        adminId: "",
        sigId: sigCurrentDetails?._id,
        sigRoleId: sigRoleDetails?._id,
        chapterId: chapterCurrentDetails?._id,
        chapterRoleId: chapterRoleDetails?._id,
        memberId: memberDetails?._id,
        replyContent: "",
      },
    },
  });

  useEffect(() => {
    if (forumId) {
      setLoading(true);
      getForumById(forumId)
        .unwrap()
        .then((res) => {
          const { data } = res;
          setIsApprovedData(data?.isApproved);
          setLoading(false);
          setPost(res?.data);
        });
    }
  }, [forumId, getForumById]);

  useEffect(() => { }, [post]);

  //handleReplyClick
  const handleReplyClick = (commentId) => {
    setValue("commentId", commentId);
    setActiveComment((prevActiveComment) =>
      prevActiveComment === commentId ? null : commentId
    );
    const comment = comments.find((comment) => comment?._id === commentId);
    if (comments?.reply && comments?.reply?.length <= 5) {
      setDisplayedReplies((prevDisplayedReplies) => ({
        ...prevDisplayedReplies,
        [commentId]: comment?.reply.length,
      }));
    } else {
      setDisplayedReplies((prevDisplayedReplies) => ({
        ...prevDisplayedReplies,
        [commentId]: 5,
      }));
    }
  };

  //handleViewmoreComments
  const handleViewMoreComments = () => {
    setDisplayedComments(displayedComments + 5);
  };

  //handleviewmoreReplies
  const handleViewMoreReplies = (commentId) => {
    setDisplayedReplies((prevDisplayedReplies) => ({
      ...prevDisplayedReplies,
      [commentId]: (prevDisplayedReplies[commentId] || 0) + 5,
    }));
  };

  //saveCommentsforum
  const onSaveCommentsForum = async (formData) => {
    if (memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") {
      formData.comment.adminId = userId;
    }
    try {
      const isValid = await trigger();
      if (isValid) {
        if (comments.length <= 0) {
          const data = await saveCommentsForum(formData);
          if (data?.data?.status) {
            toast.success("Comment Added Successfully!!!");
          }
        } else {
          const data = await updateCommentsForum(formData);
          if (data?.data?.status) {
            toast.success("Comment Updated Successfully!!!");
          }
        }
        await refetch().then((res) => {
          if (res.data?.status) {
            setComments(res?.data?.data?.comment);
          }
        });
        reset();
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  //savereplyForum
  const onSaveReplyForum = async (commentId) => {
    let formDataWithCommentId = {
      commentId: commentId,
      forumId: forumId,
      reply: {
        adminId: (memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") ? userId : "",
        sigId: sigCurrentDetails?._id,
        sigRoleId: sigRoleDetails?._id,
        chapterId: chapterCurrentDetails?._id,
        chapterRoleId: chapterRoleDetails?._id,
        memberId: memberDetails?._id,
        replyContent: watch("reply.replyContent"),
      },
    };
    try {
      const isValid = await replyTrigger();
      if (isValid) {
        await updateCommentsForum(formDataWithCommentId);
        refetch().then((res) => {
          if (res?.data?.status) {
            toast.success("Reply Added Successfully!!!");
            setComments(res?.data?.data?.comment);
          }
        });
        replyReset();
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //approve and Reject
  const handleForumApproveReject = (type) => {
    const rejectionReason = getValues("rejectionReason");
    let data;
    if (type === 0) {
      data = {
        forumId: forumId,
        type: type,
      };
    }
    if (type === 1) {
      if (
        rejectionReason === null ||
        rejectionReason === undefined ||
        rejectionReason === ""
      ) {
        setError(
          "rejectionReason",
          { type: "required", message: "Please Enter a Reason For Rejection" },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors();
        data = {
          forumId: forumId,
          type: type,
          rejectionReason,
        };
      }
    }
    setLoading(true);
    approveRejectForum(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        getForumById();
        if (res.data.data.isApproved === 1) {
          toast.success("Forum Approved Successfully!!!");
          if (memberScope === 'PORTAL_SUPER_ADMIN') {
            navigate("/admin/approval");
          }
          else {
            navigate("/assistantAdmin/approval");

          }
        } else {
          toast.success("Forum Rejected Successfully!!!");
          // navigate("/admin/approval");
          if (memberScope === 'PORTAL_SUPER_ADMIN') {
            navigate("/admin/approval");
          }
          else {
            navigate("/assistantAdmin/approval");

          }
        }
      } else {
        toast.error(data.data?.message);
      }
    });
  };

  return (
    post && (
      <>
        {forum !== null && forum !== undefined && (
          <>

            <TopHeader />
            <div className='pt-lg-5 pt-3 '>
              <div className='pt-1'>
                <div className=' Architects-header d-flex align-items-center justify-content-center '>
                  <h2 className='title-font text-center '>View Forum</h2>
                </div>
              </div>
            </div>
          </>
        )}
        {loading ? (
          <Spinner />
        ) : (
          // <Box>
          //   <Grid
          //     container
          //     spacing={2}
          //     mx={"auto"}
          //     className="d-flex justify-content-center"
          //   >
          //     <Grid item xs={forum ? 8 : 12}>
          //       <div>
          //         <Card
          //           sx={{
          //             boxShadow: "none",
          //             background: "transparent",
          //           }}
          //         >
          //           <CardContent className="customScroll">
          //             <div
          //               style={{
          //                 border: "1px solid #a52a2a ",
          //                 borderRadius: "15px",
          //                 padding: "10px",
          //                 paddingBottom: "50px"
          //               }}
          //             >
          //               <Grid container spacing={2} sx={{ width: "1000px" }}>
          //                 <Grid item xs={6}>
          //                   {(isApprovedData === 0 || isApprovedData === 1) && (
          //                     <>
          //                       <div
          //                         style={{
          //                           display: "flex",
          //                           alignItems: "center",
          //                           justifyContent: "space-between",
          //                         }}
          //                       >
          //                         <div
          //                           style={{
          //                             display: "flex",
          //                             alignItems: "center",
          //                           }}
          //                         >
          //                           <div
          //                             style={{
          //                               color: "#000000",
          //                               fontWeightRegular: 400,
          //                               fontFamily: "poppins",
          //                               fontWeight: "bold",
          //                               margin: "0",
          //                               padding: "0",
          //                               marginRight: "10px",
          //                             }}
          //                           >
          //                             {post?.name}
          //                           </div>
          //                           <Typography
          //                             variant="body1"
          //                             color="#000000"
          //                             fontWeight={200}
          //                             fontFamily="poppins"
          //                             style={{
          //                               margin: "0",
          //                               padding: "0",
          //                               marginRight: "10px",
          //                             }}
          //                           >
          //                             Post {post?.postCount}
          //                           </Typography>
          //                           <Typography
          //                             variant="body1"
          //                             color="#000000"
          //                             fontFamily="Poppins"
          //                             style={{
          //                               margin: "0",
          //                               padding: "0",
          //                               marginRight: "10px",
          //                             }}
          //                           >
          //                             {post?.position}
          //                           </Typography>
          //                         </div>
          //                       </div>
          //                       <div
          //                         className="d-flex justify-content-start"
          //                         style={{
          //                           color: "#2C7CC1",
          //                           fontWeight: "bold",
          //                           marginBottom: "0px",
          //                         }}
          //                       >
          //                         <Typography
          //                           variant="h5"
          //                           color="#000000"
          //                           fontFamily="poppins"
          //                           fontWeight="bold"
          //                         >
          //                           {post?.headline}
          //                         </Typography>
          //                       </div>
          //                       <div
          //                         style={{
          //                           display: "flex",
          //                           alignItems: "center",
          //                           justifyContent: "space-between",
          //                           marginBottom: "10px",
          //                         }}
          //                       >
          //                         <div
          //                           style={{
          //                             display: "flex",
          //                             alignItems: "center",
          //                           }}
          //                         >
          //                           <div
          //                             style={{
          //                               marginRight: "5px",
          //                               fontFamily: "Poppins",
          //                               fontWeight: "200",
          //                             }}
          //                           >
          //                             <CategoryName
          //                               id={post?.category}
          //                               style={{
          //                                 color: "#000000",
          //                               }}
          //                             />
          //                           </div>
          //                           <div
          //                             style={{
          //                               fontFamily: "Poppins",
          //                               fontWeight: "200",
          //                               marginRight: "5px",
          //                             }}
          //                           >
          //                             {post?.parentForum}
          //                           </div>
          //                         </div>
          //                       </div>
          //                       <Typography
          //                         variant="body1"
          //                         color="#000000"
          //                         fontFamily="roboto"
          //                         fontWeight={300}
          //                         style={{
          //                           display: "-webkit-box",
          //                           WebkitBoxOrient: "vertical",
          //                           WebkitLineClamp: 4,
          //                           overflow: "hidden",
          //                           textOverflow: "ellipsis",
          //                           wordBreak: "break-word",
          //                           fontSize: "24px",
          //                           marginBottom: "10px",
          //                         }}
          //                       >
          //                         {post?.description}
          //                       </Typography>
          //                       <div
          //                         style={{
          //                           color: "#000000",
          //                           marginBottom: "10px",
          //                         }}
          //                       >
          //                         <div
          //                           style={{
          //                             fontFamily: "poppins",
          //                             fontWeight: "200",
          //                           }}
          //                         >
          //                           {formatDate(post?.updatedDate)}, {post?.updatedTime} By
          //                           { } {post?.name}
          //                         </div>
          //                       </div>
          //                       <div
          //                         style={{
          //                           height: "10px",
          //                           marginBottom: "15px",
          //                         }}
          //                       ></div>
          //                     </>
          //                   )}
          //                 </Grid>
          //                 <Grid item xs={6}>
          //                   {post?.uploadImage && (
          //                     <div>
          //                       <Grid
          //                         item
          //                         xs={12}
          //                         style={{
          //                           display: "flex",
          //                           alignItems: "flex-end",
          //                         }}
          //                       >
          //                         <CardMedia
          //                           component="img"
          //                           image={post?.uploadImage}
          //                           alt="Forum image"
          //                           sx={{
          //                             maxHeight: 150,
          //                             borderRadius: "15px",
          //                             objectFit: "scale-down",
          //                           }}
          //                         />
          //                       </Grid>
          //                       <a
          //                         style={{
          //                           cursor: "pointer",
          //                           color: "#611f1e",
          //                           textDecoration: "underline",
          //                           marginLeft: "150px",
          //                         }}
          //                         rel="noreferrer noopener"
          //                         href={post?.pdflink}
          //                         target="_blank"
          //                       >
          //                         Click to view PDF
          //                       </a>
          //                     </div>
          //                   )}
          //                 </Grid>
          //               </Grid>
          //               {isApprovedData === 0 && (
          //                 <div className="text-end">
          //                   {memberScope === "PORTAL_SUPER_ADMIN" ? (
          //                     <>
          //                       <Button
          //                         variant="contained"
          //                         style={{
          //                           backgroundColor: "red",
          //                         }}
          //                         onClick={() => {
          //                           handleOpen(forum?._id);
          //                         }}
          //                       >
          //                         Reject
          //                       </Button>
          //                       <Button
          //                         variant="contained"
          //                         style={{ backgroundColor: "green", marginLeft: "10px" }}
          //                         onClick={() => handleForumApproveReject(0)}
          //                       >
          //                         Approve
          //                       </Button>
          //                     </>
          //                   ) : (
          //                     <></>
          //                   )}
          //                 </div>
          //               )}
          //               {isApprovedData === 1 && (
          //                 <>
          //                   <form onSubmit={handleSubmit(onSaveCommentsForum)} className="mb-5">
          //                     <div

          //                       style={{ marginBottom: "10px" }}
          //                     >
          //                       <TextField
          //                         className="customTextField"
          //                         label="Add a comment..."
          //                         id="commentContent"
          //                         variant="outlined"
          //                         {...register("comment.commentContent", {
          //                           required: true,
          //                         })}
          //                         multiline={true}
          //                         rows={4}
          //                         sx={{ width: "70%", background: "#f7c0b" }}
          //                       />
          //                     </div>
          //                     <div className="d-flex justify-content-end">
          //                       <Button
          //                         variant="contained"
          //                         type="submit"
          //                         className="menus-color"
          //                       >
          //                         Post
          //                       </Button>
          //                     </div>
          //                   </form>
          //                   {comments?.length > 0 ? (
          //                     <div>
          //                       {comments
          //                         .slice(0, displayedComments)
          //                         .map((data, index) => (
          //                           <div
          //                             key={index}
          //                             style={{ marginBottom: "15px" }}
          //                           >
          //                             <div
          //                               style={{
          //                                 display: "flex",
          //                                 alignItems: "center",
          //                                 marginBottom: "2px",
          //                               }}
          //                             >
          //                               <span style={{ fontWeight: "bold" }}>
          //                                 {data?.commentName}
          //                               </span>
          //                               <span
          //                                 style={{
          //                                   marginLeft: "8px",
          //                                   color: "gray",
          //                                 }}
          //                               >
          //                                 {timeAgo(data.commentDate)}
          //                               </span>
          //                             </div>
          //                             <div
          //                               style={{
          //                                 display: "flex",
          //                                 alignItems: "center",
          //                               }}
          //                             >
          //                               <div>{data?.commentContent}</div>
          //                               {data?.reply &&
          //                                 data?.reply.length > 0 ? (
          //                                 <Button
          //                                   onClick={() =>
          //                                     handleReplyClick(data._id)
          //                                   }
          //                                   style={{ display: "block" }}
          //                                 >
          //                                   {activeComment === data?._id ? (
          //                                     <ReplySharpIcon />
          //                                   ) : data?.reply.length < 2 ? (
          //                                     `${data?.reply.length} Reply`
          //                                   ) : (
          //                                     `${data?.reply.length} Replies`
          //                                   )}
          //                                 </Button>
          //                               ) : (
          //                                 <Button
          //                                   onClick={() =>
          //                                     handleReplyClick(data?._id)
          //                                   }
          //                                 >
          //                                   {activeComment === data?._id ? (
          //                                     <ReplySharpIcon />
          //                                   ) : (
          //                                     <QuickreplyOutlinedIcon />
          //                                   )}
          //                                 </Button>
          //                               )}
          //                             </div>
          //                             {activeComment === data?._id && (
          //                               <div>
          //                                 {data?.reply &&
          //                                   data?.reply.length > 0 && (
          //                                     <div>
          //                                       {data?.reply
          //                                         .slice(
          //                                           0,
          //                                           displayedReplies[
          //                                           data?._id
          //                                           ] || 0
          //                                         )
          //                                         .map((reply, replyIndex) => (
          //                                           <div
          //                                             key={replyIndex}
          //                                             style={{
          //                                               marginLeft: "20px",
          //                                               marginTop: "10px",
          //                                             }}
          //                                           >
          //                                             <div
          //                                               style={{
          //                                                 display: "flex",
          //                                                 alignItems: "center",
          //                                                 marginBottom: "2px",
          //                                               }}
          //                                             >
          //                                               <span
          //                                                 style={{
          //                                                   fontWeight: "bold",
          //                                                 }}
          //                                               >
          //                                                 {reply?.replyName}
          //                                               </span>
          //                                               <span
          //                                                 style={{
          //                                                   marginLeft: "8px",
          //                                                   color: "gray",
          //                                                 }}
          //                                               >
          //                                                 {timeAgo(
          //                                                   reply?.replyDate
          //                                                 )}
          //                                               </span>
          //                                             </div>
          //                                             <div>
          //                                               {reply?.replyContent}
          //                                             </div>
          //                                           </div>
          //                                         ))}
          //                                       {data?.reply.length >
          //                                         (displayedReplies[
          //                                           data?._id
          //                                         ] || 5) && (
          //                                           <Button
          //                                             onClick={() =>
          //                                               handleViewMoreReplies(
          //                                                 data?._id
          //                                               )
          //                                             }
          //                                           >
          //                                             View More Replies
          //                                           </Button>
          //                                         )}
          //                                     </div>
          //                                   )}
          //                                 <form
          //                                   onSubmit={replyHandleSubmit(() =>
          //                                     onSaveReplyForum(activeComment)
          //                                   )}
          //                                 >
          //                                   <TextField
          //                                     className="customTextField"
          //                                     {...replyRegister(
          //                                       "reply.replyContent",
          //                                       {
          //                                         required: true,
          //                                       }
          //                                     )}
          //                                     label="Add a reply..."
          //                                     variant="outlined"
          //                                     multiline
          //                                     size="small"
          //                                     style={{
          //                                       marginTop: "10px",
          //                                       marginBottom: "10px",
          //                                       width: "100%",
          //                                     }}
          //                                     InputProps={{
          //                                       endAdornment: (
          //                                         <InputAdornment position="end">
          //                                           <IconButton
          //                                             type="submit"
          //                                             edge="end"
          //                                             style={{
          //                                               color: "#0072bb",
          //                                             }}
          //                                           >
          //                                             <SendOutlinedIcon />
          //                                           </IconButton>
          //                                         </InputAdornment>
          //                                       ),
          //                                     }}
          //                                   />
          //                                 </form>
          //                               </div>
          //                             )}
          //                           </div>
          //                         ))}
          //                       {comments.length > displayedComments && (
          //                         <Button onClick={handleViewMoreComments}>
          //                           View More Comments
          //                         </Button>
          //                       )}
          //                     </div>
          //                   ) : (
          //                     <span>No comments available</span>
          //                   )}
          //                 </>
          //               )}
          //               <Modal
          //                 open={open}
          //                 onClose={handleClose}
          //                 aria-labelledby="modal-modal-title"
          //                 aria-describedby="modal-modal-description"
          //               >
          //                 <Box
          //                   component={"form"}
          //                   sx={style}
          //                   className="d-flex align-items-center flex-column gap-3"
          //                 >
          //                   <Typography
          //                     component={"h2"}
          //                     className="text-center fs-4 fw-bold text-danger"
          //                   >
          //                     Reject Forum
          //                   </Typography>
          //                   <TextField
          //                     fullWidth
          //                     label={
          //                       <Typography className="text-dark">
          //                         Reason For Rejection{" "}
          //                         <span style={{ color: "red" }}>*</span>
          //                       </Typography>
          //                     }
          //                     error={!!errors?.rejectionReason}
          //                     helperText={errors?.rejectionReason?.message}
          //                     {...registerReject("rejectionReason")}
          //                   />
          //                   <div className="mt-3 mx-auto">
          //                     <Button
          //                       variant="outlined"
          //                       color="error"
          //                       onClick={handleClose}
          //                     >
          //                       Cancel
          //                     </Button>
          //                     <Button
          //                       className="mx-2"
          //                       variant="contained"
          //                       color="success"
          //                       onClick={() => handleForumApproveReject(1)}
          //                     >
          //                       Submit
          //                     </Button>
          //                   </div>
          //                 </Box>
          //               </Modal>
          //             </div>
          //           </CardContent>
          //         </Card>
          //       </div>
          //     </Grid>
          //   </Grid>
          //   <br></br>

          //   <Box className="container mb-5">
          //     <Typography>James Post Kholi forum</Typography>
          //       <div >
          //         <div className="d-flex justify-content-center"><img src={post.uploadImage} alt="forum" style={{ width: '90%' }} /><br /></div>

          //         <div className="d-flex justify-content-end">
          //           <a style={{ cursor: "pointer", color: "#611f1e", textDecoration: "none", position:'relative',right:70 }} rel="noreferrer noopener" href={post?.pdflink} target="_blank"><h2>Click to view PDF</h2> </a>
          //         </div>
          //       </div>
          //     <div >
          //       {/* <div className="col-md-4"></div>
          //     <div className="'col-md-4"><Typography>{post?.description}</Typography></div>
          //     <div className="col-md-4"></div> */}
          //       <Typography className="px-2 px-md-5 fs-5" style={{ lineHeight: '45px',textAlign:'justify' }}>{post?.description}</Typography>
          //       <div className="me-4">
          //         <div className="d-flex justify-content-end">
          //           <Typography>
          //             <h4 className="text-center m-0">BY {post?.name}</h4><br /><h4>{formatDate(post?.updatedDate)}, {post?.updatedTime}</h4>
          //           </Typography>
          //         </div>
          //       </div>

          //       {isApprovedData === 0 && (
          //                 <div className="text-end">
          //                   {memberScope === "PORTAL_SUPER_ADMIN" ? (
          //                     <>
          //                       <Button
          //                         variant="contained"
          //                         style={{
          //                           backgroundColor: "red",
          //                         }}
          //                         onClick={() => {
          //                           handleOpen(forum?._id);
          //                         }}
          //                       >
          //                         Reject
          //                       </Button>
          //                       <Button
          //                         variant="contained"
          //                         style={{ backgroundColor: "green", marginLeft: "10px" }}
          //                         onClick={() => handleForumApproveReject(0)}
          //                       >
          //                         Approve
          //                       </Button>
          //                     </>
          //                   ) : (
          //                     <></>
          //                   )}
          //                 </div>
          //               )}
          //               {isApprovedData === 1 && (
          //                 <>
          //                   <form onSubmit={handleSubmit(onSaveCommentsForum)} className="mb-5">
          //                     <div
          //                       className="d-flex justify-content-center    "
          //                       style={{ marginBottom: "10px" }}
          //                     >
          //                       <TextField
          //                         className="customTextField"
          //                         label="Add a comment..."
          //                         id="commentContent"
          //                         variant="outlined"
          //                         {...register("comment.commentContent", {
          //                           required: true,
          //                         })}
          //                         multiline={true}
          //                         rows={4}
          //                         sx={{ width: "85%", background: "#f7c0b" }}
          //                       />
          //                     </div>
          //                     {/* <div className="d-flex justify-content-end"> */}
          //                       <Button
          //                         variant="contained"
          //                         type="submit"
          //                         className="menus-color postButton" style={{position:'relative',left:'85%',bottom:'-70px'}}
          //                       >
          //                         Post
          //                       </Button>
          //                     {/* </div> */}
          //                   </form>
          //                   {comments?.length > 0 ? (
          //                     <div>
          //                       {comments
          //                         .slice(0, displayedComments)
          //                         .map((data, index) => (
          //                           <div
          //                             key={index}
          //                             style={{ marginBottom: "15px" }}
          //                           >
          //                             <div
          //                               style={{
          //                                 display: "flex",
          //                                 alignItems: "center",
          //                                 marginBottom: "2px",
          //                               }}
          //                             >
          //                               <span style={{ fontWeight: "bold" }}>
          //                                 {data?.commentName}
          //                               </span>
          //                               <span
          //                                 style={{
          //                                   marginLeft: "8px",
          //                                   color: "gray",
          //                                 }}
          //                               >
          //                                 {timeAgo(data.commentDate)}
          //                               </span>
          //                             </div>
          //                             <div
          //                               style={{
          //                                 display: "flex",
          //                                 alignItems: "center",
          //                               }}
          //                             >
          //                               <div>{data?.commentContent}</div>
          //                               {data?.reply &&
          //                                 data?.reply.length > 0 ? (
          //                                 <Button
          //                                   onClick={() =>
          //                                     handleReplyClick(data._id)
          //                                   }
          //                                   style={{ display: "block" }}
          //                                 >
          //                                   {activeComment === data?._id ? (
          //                                     <ReplySharpIcon />
          //                                   ) : data?.reply.length < 2 ? (
          //                                     `${data?.reply.length} Reply`
          //                                   ) : (
          //                                     `${data?.reply.length} Replies`
          //                                   )}
          //                                 </Button>
          //                               ) : (
          //                                 <Button
          //                                   onClick={() =>
          //                                     handleReplyClick(data?._id)
          //                                   }
          //                                 >
          //                                   {activeComment === data?._id ? (
          //                                     <ReplySharpIcon />
          //                                   ) : (
          //                                     <QuickreplyOutlinedIcon />
          //                                   )}
          //                                 </Button>
          //                               )}
          //                             </div>
          //                             {activeComment === data?._id && (
          //                               <div>
          //                                 {data?.reply &&
          //                                   data?.reply.length > 0 && (
          //                                     <div>
          //                                       {data?.reply
          //                                         .slice(
          //                                           0,
          //                                           displayedReplies[
          //                                           data?._id
          //                                           ] || 0
          //                                         )
          //                                         .map((reply, replyIndex) => (
          //                                           <div
          //                                             key={replyIndex}
          //                                             style={{
          //                                               marginLeft: "20px",
          //                                               marginTop: "10px",
          //                                             }}
          //                                           >
          //                                             <div
          //                                               style={{
          //                                                 display: "flex",
          //                                                 alignItems: "center",
          //                                                 marginBottom: "2px",
          //                                               }}
          //                                             >
          //                                               <span
          //                                                 style={{
          //                                                   fontWeight: "bold",
          //                                                 }}
          //                                               >
          //                                                 {reply?.replyName}
          //                                               </span>
          //                                               <span
          //                                                 style={{
          //                                                   marginLeft: "8px",
          //                                                   color: "gray",
          //                                                 }}
          //                                               >
          //                                                 {timeAgo(
          //                                                   reply?.replyDate
          //                                                 )}
          //                                               </span>
          //                                             </div>
          //                                             <div>
          //                                               {reply?.replyContent}
          //                                             </div>
          //                                           </div>
          //                                         ))}
          //                                       {data?.reply.length >
          //                                         (displayedReplies[
          //                                           data?._id
          //                                         ] || 5) && (
          //                                           <Button
          //                                             onClick={() =>
          //                                               handleViewMoreReplies(
          //                                                 data?._id
          //                                               )
          //                                             }
          //                                           >
          //                                             View More Replies
          //                                           </Button>
          //                                         )}
          //                                     </div>
          //                                   )}
          //                                 <form
          //                                   onSubmit={replyHandleSubmit(() =>
          //                                     onSaveReplyForum(activeComment)
          //                                   )}
          //                                 >
          //                                   <TextField
          //                                     className="customTextField"
          //                                     {...replyRegister(
          //                                       "reply.replyContent",
          //                                       {
          //                                         required: true,
          //                                       }
          //                                     )}
          //                                     label="Add a reply..."
          //                                     variant="outlined"
          //                                     multiline
          //                                     size="small"
          //                                     style={{
          //                                       marginTop: "10px",
          //                                       marginBottom: "10px",
          //                                       width: "100%",
          //                                     }}
          //                                     InputProps={{
          //                                       endAdornment: (
          //                                         <InputAdornment position="end">
          //                                           <IconButton
          //                                             type="submit"
          //                                             edge="end"
          //                                             style={{
          //                                               color: "#0072bb",
          //                                             }}
          //                                           >
          //                                             <SendOutlinedIcon />
          //                                           </IconButton>
          //                                         </InputAdornment>
          //                                       ),
          //                                     }}
          //                                   />
          //                                 </form>
          //                               </div>
          //                             )}
          //                           </div>
          //                         ))}
          //                       {comments.length > displayedComments && (
          //                         <Button onClick={handleViewMoreComments}>
          //                           View More Comments
          //                         </Button>
          //                       )}
          //                     </div>
          //                   ) : (
          //                     <span>No comments available</span>
          //                   )}
          //                 </>
          //               )}

          //     </div>

          //   </Box>

          // </Box>
          <Box className=" p-md-5 p-3 overallPadding ">
            <div className="mb-3 d-flex justify-content-end">
              <Button
                onClick={() => window.history.back()}
                variant="contained"
                className="menus-color"

              >
                Back
              </Button>
            </div>
            {/* <Typography>James Post Kholi forum</Typography> */}
            <div className=" d-flex justify-content-between align-items-center">
              <div>
                <h2 className="fw-bold">{post.name} Post</h2>
                <h2>{post.headline}</h2>
              </div>
              {post.pdfDocumentID && (
                <div>
                  <a style={{ cursor: "pointer", color: "#611f1e", textDecoration: "none" }} rel="noreferrer noopener" href={post?.pdflink} target="_blank"><h5>Click to view PDF</h5> </a>
                </div>
              )}
            </div>
            {/* 
            <div>
              <div className="d-flex justify-content-center"><img src={post.uploadImage} alt="forum" style={{ width: '90%' }} /><br /></div>

              <div className="d-flex justify-content-end">
                <a style={{ cursor: "pointer", color: "#611f1e", textDecoration: "none", position: 'relative', right: 79, top: 4 }} rel="noreferrer noopener" href={post?.pdflink} target="_blank"><h2>Click to view PDF</h2> </a>
              </div>

            </div> */}
            <div className='clearfix mt-3 px-md-5 pb-5'>
              {post.uploadImage && <img src={post.uploadImage} alt="forum" style={{ float: 'right', margin: '0px 0px 0px 15px', width: "auto" }} />}
              <Typography className=' styled-news-body' style={{ textAlign: 'justify', fontSize: '22px', paddingBottom: '40px' }}>
                {post?.description}
              </Typography>
            </div>
            <div >
              {/* <div className="d-flex justify-content-center">
                <Typography className="fs-5" style={{ lineHeight: '45px', textAlign: 'justify', width: '90%' }}>{post?.description}</Typography>
              </div> */}
              <div className="me-4">
                <div className="d-flex justify-content-end">
                  <Typography>
                    <h4 className="text-center">BY {post?.name}</h4><h4>{formatDate(post?.updatedDate)}, {post?.updatedTime}</h4>
                  </Typography>
                </div>
              </div>

              {isApprovedData === 0 && (
                <div className="text-end">
                  {memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN" ? (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "red",
                        }}
                        onClick={() => {
                          handleOpen(forum?._id);
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "green", marginLeft: "10px" }}
                        onClick={() => handleForumApproveReject(0)}
                      >
                        Approve
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {isApprovedData === 1 && (
                <>
                  <form onSubmit={handleSubmit(onSaveCommentsForum)} className="mb-5">
                    <div
                      className="d-flex justify-content-center    "
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        className="customTextField"
                        label="Add a comment..."
                        id="commentContent"
                        variant="outlined"
                        {...register("comment.commentContent", {
                          required: true,
                        })}
                        multiline={true}
                        rows={5}
                        sx={{
                          width: "85%", background: "#f7c0b",
                          //    '& .MuiOutlinedInput-root': {
                          //   '&:hover .MuiOutlinedInput-notchedOutline': {
                          //     borderColor: '#bac1c2', // Custom color on hover
                          //   },
                          //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          //     // borderColor: '#bac1c2', // Custom color on focus
                          //   },
                          // },
                          '& .MuiInputLabel-root': {
                            '&.Mui-focused': {
                              color: 'black', // Custom label color on focus
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="me-5 me-md-5 me-lg-0">
                      <Button
                        variant="contained"
                        type="submit"
                        className="menus-color " style={{ position: 'relative', left: '85%', bottom: '-55px' }}
                      >
                        Post
                      </Button>
                    </div>
                  </form>
                  {comments?.length > 0 ? (
                    <div className="px-md-5 
                     cmtSection">
                      {comments
                        .slice(0, displayedComments)
                        .map((data, index) => (
                          <div
                            key={index}
                            style={{ marginBottom: "15px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "2px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {data?.commentName}
                              </span>
                              <span
                                style={{
                                  marginLeft: "8px",
                                  color: "gray",
                                }}
                              >
                                {timeAgo(data.commentDate)}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>{data?.commentContent}</div>
                              {data?.reply &&
                                data?.reply.length > 0 ? (
                                <Button
                                  onClick={() =>
                                    handleReplyClick(data._id)
                                  }
                                  style={{ display: "block" }}
                                >
                                  {activeComment === data?._id ? (
                                    <ReplySharpIcon />
                                  ) : data?.reply.length < 2 ? (
                                    `${data?.reply.length} Reply`
                                  ) : (
                                    `${data?.reply.length} Replies`
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  onClick={() =>
                                    handleReplyClick(data?._id)
                                  }
                                >
                                  {activeComment === data?._id ? (
                                    <ReplySharpIcon />
                                  ) : (
                                    <QuickreplyOutlinedIcon />
                                  )}
                                </Button>
                              )}
                            </div>
                            {activeComment === data?._id && (
                              <div>
                                {data?.reply &&
                                  data?.reply.length > 0 && (
                                    <div>
                                      {data?.reply
                                        .slice(
                                          0,
                                          displayedReplies[
                                          data?._id
                                          ] || 0
                                        )
                                        .map((reply, replyIndex) => (
                                          <div
                                            key={replyIndex}
                                            style={{
                                              marginLeft: "20px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "2px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {reply?.replyName}
                                              </span>
                                              <span
                                                style={{
                                                  marginLeft: "8px",
                                                  color: "gray",
                                                }}
                                              >
                                                {timeAgo(
                                                  reply?.replyDate
                                                )}
                                              </span>
                                            </div>
                                            <div>
                                              {reply?.replyContent}
                                            </div>
                                          </div>
                                        ))}
                                      {data?.reply.length >
                                        (displayedReplies[
                                          data?._id
                                        ] || 5) && (
                                          <Button
                                            onClick={() =>
                                              handleViewMoreReplies(
                                                data?._id
                                              )
                                            }
                                          >
                                            View More Replies
                                          </Button>
                                        )}
                                    </div>
                                  )}
                                <form
                                  onSubmit={replyHandleSubmit(() =>
                                    onSaveReplyForum(activeComment)
                                  )}
                                >
                                  <TextField
                                    className="customTextField"
                                    {...replyRegister(
                                      "reply.replyContent",
                                      {
                                        required: true,
                                      }
                                    )}
                                    label="Add a reply..."
                                    variant="outlined"
                                    multiline
                                    size="small"
                                    style={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            type="submit"
                                            edge="end"
                                            style={{
                                              color: "#0072bb",
                                            }}
                                          >
                                            <SendOutlinedIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </form>
                              </div>
                            )}<br />
                          </div>
                        ))}
                      {comments.length > displayedComments && (
                        <Button onClick={handleViewMoreComments}>
                          View More Comments
                        </Button>
                      )}
                    </div>
                  ) : (
                    <span style={{ position: 'relative', top: 25, left: 109 }}>No comments available</span>
                  )}
                </>
              )}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box

                  sx={style}
                  className="d-flex align-items-center flex-column gap-3"
                >
                  <Typography
                    component={"h2"}
                    className="text-center fs-4 fw-bold text-danger"
                  >
                    Reject Forum
                  </Typography>
                  <TextField
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Reason For Rejection{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    inputProps={{ maxLength: "300" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {300 - watchReject("rejectionReason")?.length}
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.rejectionReason}
                    helperText={errors?.rejectionReason?.message}
                    {...registerReject("rejectionReason")}
                  />
                  <div className="mt-3 mx-auto">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="menus-color mx-2"
                      variant="contained"
                      color="success"
                      onClick={() => handleForumApproveReject(1)}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>
          </Box>
        )}
      </>
    )
  );
};

export default ReadMoreForum;
