import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import '../../../Pages/Admin/AssitantAdmin/AssistantAdmin.css';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import { useGetAssistantAdminMutation, useDeleteAssistantAdminMutation } from '../../../app/services/assistantAdmin';
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import { Box, Typography, Modal, Tooltip, Divider, IconButton } from '@mui/material';
import { useResetPasswordMutation } from '../../../app/services/loginServices';
import KeyIcon from '@mui/icons-material/Key';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';



const AssistantAdmin = () => {
  const [getAllAssistantAdmin] = useGetAssistantAdminMutation();
  const [deleteAssistantAdmin] = useDeleteAssistantAdminMutation();
  const [adminData, setAdminData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [resetPassword] = useResetPasswordMutation();
  const [deleteId, setDeleteId] = useState();


  const handleOpenModal = (id) => {
    setDeleteId(id)
    setOpenModal(true);
    // setSelectedMember(id);
  };

  const handleClose = (id) => {
    setOpenModal(false);
  };


  const getAlladminDataFn = useCallback(() => {
    const data = {
      role: "PORTAL_ADMIN"
    };

    getAllAssistantAdmin(data)
      .then((res) => {
        if (res?.data?.status) {
          setAdminData(res?.data?.data);
        }
        else {
          setAdminData([])
        }
      })
      .catch((err) => console.log("Error fetching data:", err));
  }, [getAllAssistantAdmin])


  useEffect(() => {
    getAlladminDataFn()
  }, [getAlladminDataFn]);


  const handleDelete = (id) => {
    deleteAssistantAdmin(id).then(res => {
      if (res.data?.status) {
        toast.success('Assistant Admin Deleted Successfully!!!')
        getAlladminDataFn()
        setOpenModal(false);
        getAlladminDataFn();
        handleClose();
      }
      else {
        toast.error('Assistant Admin Delete Failed!!!')
        setOpenModal(false);
      }
    })
  }

  //Reset Password
  const handleResetPassword = (email) => {
    var data = {
      email: email,
      role: "PORTAL_ASSISTANT_ADMIN"
    }
    resetPassword(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      }
      else {
        toast.error(res?.data?.message)
      }
    }).catch(err => console.error(err))
  }


  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Assistant Admin</h2>
        </div>
      </div>
      <div className="p-3 p-md-5 overallPadding">
        <div className="d-flex justify-content-end align-items-center mt-5">
          {/* {adminData.length} */}
          {adminData?.length !== 2 ? (
            <div className="d-flex justify-content-end">

              <Link
                to={"/admin/AddEditAssistantAdmin"}
              >
                <Button
                  variant="contained"
                  style={{ marginRight: "35px", color: 'white' }}
                  className="justify-content-end menus-color title-font"
                >
                  ADD ASSISTANT ADMIN
                </Button>
              </Link>
            </div>) : ""
          }
        </div>
        {/* <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-center mt-5 mb-5'> */}
        <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-around mt-5 mb-5'>

          {adminData && adminData.length > 0 ? (
            <>
              {adminData.map((data, i) => (
                <div key={data._id} className="page">
                  <div className="notice-board page-left">
                    <Tooltip title="Delete">
                      <DeleteIcon
                        style={{ color: "red", cursor: "pointer" }}
                        className="float-end ms-2"
                        onClick={() => handleOpenModal(data._id)}
                      />
                    </Tooltip>
                    <Tooltip title="Reset Password" className="float-end ms-2">
                      <KeyIcon
                        style={{ color: "darkred", cursor: "pointer" }}
                        onClick={() => handleResetPassword(data.email)}
                      />
                    </Tooltip>
                    <Tooltip title="Edit" className="float-end">
                      <Link to={`/admin/AddEditAssistantAdmin/${data._id}`}>
                        <EditIcon style={{ color: "green" }} className="float-end" />
                      </Link>
                    </Tooltip>

                    <div className="pinned-card">
                      <div className="realistic-pin"></div>
                      <h3 className="title-font">Assistant Admin</h3>
                      <p className="content-font">
                        <b className="title-font">Name:</b> {data.name}
                      </p>
                      <p className="content-font">
                        <b className="title-font">Email:</b> {data.email}
                      </p>
                      <p className="content-font">
                        <b className="title-font">Mobile No:</b> {data.phone}
                      </p>
                    </div>
                    <b className="text-center page-number content-font">{i + 1}</b>
                  </div>

                  {/* Modal */}
                  <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        backgroundColor: "white",
                        boxShadow: 24,
                        padding: "20px",
                        outline: "none",
                      }}
                      className="d-flex  flex-column gap-3 overflow-x-hidden overflow-y-hidden"
                    >

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                          Confirmation
                        </Typography>
                        <IconButton onClick={handleClose} aria-label="Close">
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Box display="flex" justifyContent="center" width="100%">
                        <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                      </Box>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Assistant Admin?
                      </Typography>
                      {/* <Box display="flex" justifyContent="center" width="100%">
                      <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                    </Box> */}
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          variant="outlined"
                          style={{

                            color: "#dc3545",
                            border: "1px solid #dc3545",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#2e7d32",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete(deleteId)}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </div>
              ))}
            </>
          ) : (
            <div className='d-flex justify-content-center'>
              <ErrorOutlineIcon style={{ fontSize: 50, color: 'red' }} />
              <h3 className="text-danger mt-2">No Data Found</h3>
            </div>
          )}



        </div>
      </div>

    </>
  )
}

export default AssistantAdmin;