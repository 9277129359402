import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useLazyGetEventByIdQuery,
  useJoinEventMutation,
} from "../app/services/eventService";
import "../../src/style/css/individualEvent.css";
import { useLazyGetEventTypeByIdQuery } from "../app/services/eventTypeServices";
import Button from "@mui/material/Button";
import { selectCurrentScope } from "../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useGetApproveRejectEventMutation } from "../app/services/eventService";
import { toast } from "react-toastify";
import Spinner from "../Component/spinner/spinner";
import { InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { selectCurrentMember } from "../app/features/memberSlice";
import Logo from '../assets/images/logos/ELTA@I.svg'
import { useGetChapterDetailsByIdQuery, } from "../app/services/chapterServices";
import { useGetSIGGroupByIdQuery } from "../app/services/SIGGroupServices";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import placeholder from "../assets/images/placeholder/placeholder.png";
import { CalendarTodayOutlined, AccessTime } from "@material-ui/icons";

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "20px",
};
const GetSIGDetails = ({ id }) => {
  const { data } = useGetSIGGroupByIdQuery(id);
  return (data ? <div className="col-lg-4 col-md-6 row-gap-3 d-flex justify-content-center flex-column  align-items-center">
    <img src={placeholder} alt="Eltai Sig Logo" className="img-fluid" style={{ height: '100px' }} />
    <Typography className="poppins-regular fw-semibold menus-green-color text-capitalize" component={'h5'} variant="h5">{data?.data?.groupName}(ELTAI SIG)</Typography>
    <Typography className="poppins-regular w-50 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>D-54 Third Floor,Anandham Apartments, 156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamilnadu, India - 600049.</Typography>
    <Typography className="poppins-regular w-25 text-center menus-green-color" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>www.eltai.in</Typography>
  </div> : <>No Sig Found in this id</>)
}

const GetChapterDetails = ({ id }) => {
  const { data } = useGetChapterDetailsByIdQuery(id);
  return (
    <div className="col-lg-4 col-md-6 row-gap-3 d-flex justify-content-center flex-column  align-items-center">
      <img src={data?.data.imageUrl} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
      <Typography className="poppins-regular fw-semibold menus-green-color text-capitalize" component={'h5'} variant="h5">{data?.data?.title}(ELTAI Chapter)</Typography>
      <Typography className="poppins-regular w-50 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>D-54 Third Floor,Anandham Apartments, 156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamilnadu, India - 600049.</Typography>
      <Typography className="poppins-regular w-25 text-center menus-green-color" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>www.eltai.in</Typography>
    </div>
  )
}

function IndividualEvent() {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [getEventById] = useLazyGetEventByIdQuery();
  const [getEventData, setGetEventData] = useState([]);
  const [getEventTypeById] = useLazyGetEventTypeByIdQuery();
  const [getEventType, setGetEventType] = useState([]);
  const [joinEventService] = useJoinEventMutation();
  const memberScope = useSelector(selectCurrentScope);
  const [approveRejectEvent] = useGetApproveRejectEventMutation();
  const memberDetail = useSelector(selectCurrentMember);
  const [open, setOpen] = useState(false);
  const [nonMemberRegisterOpen, setNonMemberRegisterOpen] = useState(false);
  const [joinButton, setJoinButton] = useState(-105); // Default top position

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  const {
    reset: resetNonMemberForm,
    register: nonMemberRegisterForm,
    formState: { errors: nonMemberFormError },
    handleSubmit: handleSubmitEventNonMember,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
  };

  //close handle
  const handleClose = () => {
    setOpen(false);
    setNonMemberRegisterOpen(false);
    reset();
    resetNonMemberForm();
  };

  //Non member registration
  const handleNonMemberRegistation = () => {
    setNonMemberRegisterOpen(true);
  };

  //Event by id
  useEffect(() => {
    if (eventId) {
      getEventById(eventId);
    }
  }, [getEventById, eventId]);

  //Handle approve or reject events with reason
  const handleApproveReject = (id, type) => {
    const rejectionReason = getValues("rejectionReason");
    let data;
    if (type === 0) {
      data = {
        eventId,
        type: type,
      };
    }
    if (type === 1) {
      if (
        rejectionReason === null ||
        rejectionReason === undefined ||
        rejectionReason === ""
      ) {
        setError(
          "rejectionReason",
          { type: "required", message: "Please Enter a Reason For Rejection" },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors();
        data = {
          eventId,
          type: type,
          rejectionReason,
        };
      }
    }
    setLoading(true);
    handleClose();
    approveRejectEvent(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.success(res?.data?.message);
        if (memberScope === "PORTAL_SUPER_ADMIN") {
          window?.history?.back()
        }
        else {
          window?.history?.back()
        }
      } else {
        setLoading(false);
        toast.error(data.data?.message);
      }
    });
  };

  //Format date
  const formatDate = (dateStr) => {
    const options = { day: 'numeric', year: 'numeric', month: 'long' }; // Options for formatting
    return new Date(dateStr).toLocaleDateString('en-GB', options); // 'en-US' specifies the format
  };

  //Event details by id
  const getEventDetailsById = useCallback(() => {
    setLoading(true);
    getEventById(eventId)
      .then((res) => {
        if (res.data?.status) {
          setLoading(false);
          setGetEventData(res.data.data);
          getEventTypeById(res.data.data.eventTypeId).then((res) => {
            if (res.data?.status) {
              setGetEventType(res.data.data);
            }
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [getEventById, getEventTypeById, eventId]);

  useEffect(() => {
    if (eventId) {
      getEventDetailsById();
    }
  }, [getEventDetailsById, eventId]);

  //Join non member event
  const joinEvent = (event) => {
    if (memberScope === null || memberScope === undefined) {
      handleNonMemberRegistation();
    } else {
      setLoading(true);
      const data = { eventId: event._id, memberId: memberDetail._id };
      joinEventService(data).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.success(res.data.message);
          handleClose();
          reset();
          getEventDetailsById();
        } else {
          setLoading(false);
          toast.error(res.data.err.message);
        }
      });
    }
  };

  //Join non member register
  const nonMemberRegister = (registerData) => {
    const data = {
      eventId: eventId,
      nonMemberDetails: registerData,
    };
    setLoading(true);
    joinEventService(data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setNonMemberRegisterOpen(false);
        resetNonMemberForm();
      } else {
        setLoading(false);
        toast.error(res?.data?.err?.message);
      }
    });
  };
  //handleResize for join button
  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 320 && width <= 991) {
      setJoinButton(-100)
    } else {
      setJoinButton(-105)
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set state based on the initial window size
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize])

  return (
    <div className='page-containter'>
      <div className='pt-5 mb-3'></div>
      <div className="upcoming-class alata-bold">Upcoming Event</div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-fluid">
          <div className="bg-light-green text-end px-md-5 px-3 pt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color me-4"
            >
              Back
            </Button>
          </div>
          <div className="pt-3 d-flex row-gap-1 justify-content-center flex-column align-items-center bg-light-green menus-green-color">
            <Typography className="poppins-regular" component={'h5'} variant="h5">{getEventData?.levelOfEvent}{' '}{getEventType?.name}</Typography>
            <Typography className="poppins-bold fw-bold" component={'h1'} variant="h5">{getEventData?.title}</Typography>
            <Typography className="poppins-regular fs-5" component={'p'} variant={'body2'}><CalendarTodayOutlined />{formatDate(getEventData?.startDate)}{getEventData?.endDate ? ` to ${formatDate(getEventData?.endDate)}` : ''} {getEventData?.startTime ? <><AccessTime /> {getEventData?.startTime} to {getEventData?.endTime}</> : ""}</Typography>
          </div>
          <div className="d-flex row-gap-1 pb-3 pt-3 justify-content-center flex-column align-items-center bg-light-green menus-green-color">
            {getEventData?.venue && <Typography className="poppins-regular" component={'h5'} variant="h5">{getEventData?.venue ? <><span className="fw-semibold">Venue:</span> {getEventData?.venue}</> : ''}</Typography>}
            {getEventData?.mapLink && <Typography className="poppins-regular menus-green-color text-decoration-underline" component={'h5'} variant="h5">{getEventData?.mapLink ? <><LocationOnIcon className="text-decoration-none" /><a className="menus-green-color" href={getEventData?.mapLink} rel="noreferrer" target="_blank">Google Map Link</a></> : ''}</Typography>}
          </div>
          <div className="d-flex row-gap-1 pb-3 pt-3 justify-content-center flex-column align-items-center mb-5 bg-light-green menus-green-color">
            {getEventData?.platform && <Typography className="poppins-regular" component={'h5'} variant="h5">{getEventData?.platform ? <><span className="fw-semibold">Platform: </span>{getEventData?.platform}</> : ''}</Typography>}
            {getEventData?.meetingURL && <Typography className="poppins-regular text-decoration-underline" component={'h5'} variant="h5">{getEventData?.meetingURL ? <a className="menus-green-color" href={getEventData?.meetingURL} rel="noreferrer" target="_blank"><VideoCallIcon className="text-decoration-none" /> Meeting Link</a> : ''}</Typography>}
          </div>
          <div className="d-flex row-gap-3 justify-content-center py-2 flex-column align-items-center bg-light-green menus-green-color">
            <Typography className="poppins-regular fw-bold" component={'h5'} variant="h5">Organised by</Typography>
          </div>
          <div className="p-md-5 p-3 overallPadding row justify-content-center">
            {(getEventData?.jointOrganisers && getEventData?.jointOrganisers[0]?.jointOrganiser === 'ELTAI') &&
              <div className="d-flex row-gap-3 justify-content-center w-100 flex-column menus-green-color align-items-center">
                <img src={Logo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                <Typography className="poppins-regular fw-semibold text-capitalize" component={'h5'} variant="h5">ELTAI</Typography>
                <Typography className="poppins-regular w-25 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>D-54 Third Floor,Anandham Apartments, 156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamilnadu, India - 600049.</Typography>
                <Typography className="poppins-regular w-25 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>www.eltai.in</Typography>
              </div>}
            {(getEventData?.jointOrganisers && getEventData?.jointOrganisers[0]?.jointOrganiser === 'ELTAI SIG') &&
              <GetSIGDetails id={getEventData?.jointOrganisers[0]?.SIGGroupsId[0]} />}
            {(getEventData?.jointOrganisers && getEventData?.jointOrganisers[0]?.jointOrganiser === 'ELTAI Chapter') &&
              <GetChapterDetails id={getEventData?.jointOrganisers[0]?.chaptersId[0]} />}
            {(getEventData?.jointOrganisers && getEventData?.jointOrganisers[0]?.jointOrganiser === 'Member Institution') &&
              <div className="d-flex row-gap-3 justify-content-center w-100 flex-column menus-green-color align-items-center">
                <img src={getEventData?.jointOrganisers[0]?.institutionLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                <Typography className="poppins-regular fw-semibold text-capitalize" component={'h5'} variant="h5">{getEventData?.jointOrganisers[0]?.institutionName}(Member)</Typography>
                <Typography className="poppins-regular w-25 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{getEventData?.jointOrganisers[0]?.institutionAddress}</Typography>
                <Typography className="poppins-regular w-25 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{getEventData?.jointOrganisers[0]?.institutionWebsite}</Typography>
              </div>}
            {(getEventData?.jointOrganisers && getEventData?.jointOrganisers[0]?.jointOrganiser === 'Non-Member Institution') &&
              <div className="col-md-6 d-flex row-gap-3 justify-content-center w-100 flex-column menus-green-color align-items-center">
                <img src={getEventData?.jointOrganisers[0]?.nonMemberLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                <Typography className="poppins-regular fw-semibold text-capitalize" component={'h5'} variant="h5">{getEventData?.jointOrganisers[0]?.nonMemberName}(Non-Member)</Typography>
                <Typography className="poppins-regular w-25 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{getEventData?.jointOrganisers[0]?.nonMemberAddress}</Typography>
                <Typography className="poppins-regular w-25 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{getEventData?.jointOrganisers[0]?.nonMemberWebsite}</Typography>
              </div>}
          </div>
          {getEventData?.jointOrganisers && getEventData?.jointOrganisers.length > 1 &&
            <>
              <div className="d-flex row-gap-3 justify-content-center flex-column align-items-center bg-light-green menus-green-color">
                <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">In collaboration with <span className="fw-normal">{getEventData?.jointOrganisers.length > 2 ? '(Joint Organisers)' : '(Joint Organiser)'}</span></Typography>
              </div>
              <div className="row row-gap-5 p-md-5 p-3 overallPadding justify-content-center">
                {getEventData?.jointOrganisers && getEventData?.jointOrganisers.filter(organiser=>organiser.levelOfOrganiser!=="Main Organiser").sort((a, b) => a.orderOfOrganiser - b.orderOfOrganiser).map((organiser, index) => (                    <>
                      {(getEventData?.jointOrganisers && organiser?.jointOrganiser === 'ELTAI') &&
                        <div className="col-lg-4 col-md-6 d-flex row-gap-3 justify-content-center flex-column align-items-center">
                          <img src={Logo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                          <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">ELTAI</Typography>
                          <Typography className="poppins-regular w-50 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>D-54 Third Floor,Anandham Apartments, 156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamilnadu, India - 600049.</Typography>
                          <Typography className="poppins-regular w-50 text-center menus-green-color" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>www.eltai.in</Typography>
                        </div>}
                      {(getEventData?.jointOrganisers && organiser?.jointOrganiser === 'ELTAI SIG') &&
                        <GetSIGDetails id={organiser?.SIGGroupsId[0]} />
                      }
                      {(getEventData?.jointOrganisers && organiser?.jointOrganiser === 'ELTAI Chapter') &&
                        <GetChapterDetails id={organiser?.chaptersId[0]} />}
                      {(getEventData?.jointOrganisers && organiser?.jointOrganiser === 'Member Institution') &&
                        <div className="col-lg-4 col-md-6 d-flex row-gap-3 justify-content-center flex-column align-items-center">
                          <img src={organiser?.institutionLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                          <Typography className="poppins-regular fw-semibold menus-green-color text-capitalize" component={'h5'} variant="h5">{organiser?.institutionName}(Member)</Typography>
                          <Typography className="poppins-regular w-50 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{organiser?.institutionAddress}</Typography>
                          <Typography className="poppins-regular w-50 text-center menus-green-color" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{organiser?.institutionWebsite}</Typography>
                        </div>}
                      {(getEventData?.jointOrganisers && organiser?.jointOrganiser === 'Non-Member Institution') &&
                        <div className="col-lg-4 col-md-6 d-flex row-gap-3 justify-content-center flex-column align-items-center">
                          <img src={organiser?.nonMemberLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                          <Typography className="poppins-regular fw-semibold menus-green-color text-capitalize" component={'h5'} variant="h5">{organiser?.nonMemberName}(Non-Member)</Typography>
                          <Typography className="poppins-regular w-50 text-center text-capitalize" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{organiser?.nonMemberAddress}</Typography>
                          <Typography className="poppins-regular w-50 text-center menus-green-color" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{organiser?.nonMemberWebsite}</Typography>
                        </div>}
                    </>

                ))}
              </div>

            </>
          }
          {(getEventData?.sponsors && getEventData?.sponsors[0]?.sponsor !== 'None') &&
            <>
              <div className="d-flex row-gap-3 justify-content-center flex-column align-items-center bg-light-green menus-green-color">
                <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">Sponsored by</Typography>
              </div>
              <div className="row row-gap-5 p-md-5 p-3 justify-content-center overallPadding">
                {getEventData?.sponsors && getEventData?.sponsors.map((sponsor, index) => (
                  <>
                    {(getEventData?.sponsors && sponsor?.sponsor === 'ELTAI') &&
                      <div className="col-lg-4 col-md-6 row-gap-3 d-flex justify-content-center flex-column  align-items-center">
                        <img src={Logo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                        <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">ELTAI</Typography>
                        <Typography className="poppins-regular w-50 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>D-54 Third Floor,Anandham Apartments, 156, SIDCO Nagar Main Road, Villivakkam, Chennai, Tamilnadu, India - 600049.</Typography>
                        <Typography className="poppins-regular w-25 menus-green-color text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>www.eltai.in</Typography>
                      </div>}
                    {(getEventData?.sponsors && sponsor?.sponsor === 'ELTAI SIG') &&
                      <GetSIGDetails id={sponsor?.SIGGroupsId[0]} />
                    }
                    {(getEventData?.sponsors && sponsor?.sponsor === 'ELTAI Chapter') &&
                      <GetChapterDetails id={sponsor?.chaptersId[0]} />}
                    {(getEventData?.sponsors && sponsor?.sponsor === 'Member Institution') &&
                      <div className="col-md-6 row-gap-3 d-flex justify-content-center flex-column  align-items-center">
                        <img src={sponsor?.institutionLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                        <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">{sponsor?.institutionName}(Member)</Typography>
                        <Typography className="poppins-regular w-50 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{sponsor?.institutionAddress}</Typography>
                        <Typography className="poppins-regular w-25 menus-green-color text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{sponsor?.institutionWebsite}</Typography>
                      </div>}
                    {(getEventData?.sponsors && sponsor?.sponsor === 'Non-Member Institution') &&
                      <div className="col-md-6 row-gap-3 d-flex justify-content-center flex-column  align-items-center">
                        <img src={sponsor?.nonMemberLogo} alt="Eltai Logo" className="img-fluid" style={{ height: '100px' }} />
                        <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">{sponsor?.nonMemberName}(Non-Member)</Typography>
                        <Typography className="poppins-regular w-50 text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{sponsor?.nonMemberAddress}</Typography>
                        <Typography className="poppins-regular w-25 menus-green-color text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{sponsor?.nonMemberWebsite}</Typography>
                      </div>}
                  </>

                ))}
              </div>
            </>
          }
          <div className="d-flex justify-content-center flex-column align-items-center mb-3 bg-light-green menus-green-color">
            <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">About the Event</Typography>
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center mb-3 px-md-5 px-3 overallPadding">
            <Typography className="poppins-regular justified" component={'p'} variant={'body1'}>{getEventData?.description}</Typography>
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center mb-3 bg-light-green menus-green-color">
            <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">Resource Persons</Typography>
          </div>
          <div className="row row-gap-5 p-md-5 p-3 overallPadding">
            {getEventData?.speakers && getEventData?.speakers.slice().sort((a, b) => a.orderOfSpeaker - b.orderOfSpeaker).map(speaker => (
              <>
                <div className="col-md-2 col-12 d-flex align-items-center">
                  <img src={speaker?.photo} alt="Speaker profile img" style={{ objectFit: 'contain' }} className="rounded-circle w-75 mx-auto" />
                </div>
                <div className="col-md-10 col-12 d-flex flex-column justify-content-center">
                  <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">{speaker?.role}</Typography>
                  <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">{speaker?.name}</Typography>
                  <Typography className="poppins-regular fw-semibold" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{speaker?.designation}, {speaker?.institution}</Typography>
                  <Typography className="poppins-regular justified" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{speaker?.bio}</Typography>
                </div>
              </>
            ))}
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center mb-3 bg-light-green menus-green-color">
            <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">Registration</Typography>
          </div>
          <div className="p-md-5 p-3 overallPadding d-flex flex-column row-gap-3">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-6 d-flex justify-content-between">
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">Is it a free / paid event</Typography>
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">:</Typography>
              </div>
              <div className="col-md-8 col-sm-6 col-6 d-flex align-items-center">
                <Typography className="poppins-regular" component={'p'} variant={'body1'}>{getEventData?.isPaidEvent === 0 ? 'Free' : 'Paid'}</Typography>
              </div>
            </div>
            {getEventData?.isPaidEvent === 1 && <div className="row">
              <div className="col-md-4 col-sm-6 col-6 d-flex justify-content-between">
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">Registration Fee</Typography>
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">:</Typography>
              </div>
              <div className="col-md-8 col-sm-6 col-6 d-flex align-items-center">
                <Typography className="poppins-regular " component={'p'} variant={'body1'}>{getEventData?.feeDetails}</Typography>
              </div>
            </div>}
            <div className="row">
              <div className="col-md-4 col-sm-6 col-6 d-flex justify-content-between">
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">Register at</Typography>
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">:</Typography>
              </div>
              <div className="col-md-8 col-sm-6 col-6 d-flex align-items-center">
                <Typography className="poppins-regular text-decoration-underline menus-green-color" component={'a'} href={getEventData?.registrationLink} variant={'body1'}>Registration Link</Typography>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-6 d-flex justify-content-between">
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">Last Date to Register</Typography>
                <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">:</Typography>
              </div>
              <div className="col-md-8 col-sm-6 col-6 d-flex align-items-center">
                <Typography className="poppins-regular" component={'p'} variant={'body1'}>{formatDate(getEventData?.deadline)}</Typography>
              </div>
            </div>
          </div>
          {(getEventData?.flyer || getEventData?.schedule || getEventData?.brouchure || getEventData?.readingMaterial) &&
            <>
              <div className="d-flex justify-content-center flex-column align-items-center bg-light-green menus-green-color">
                <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">Attachments</Typography>
              </div>
              <div className="p-md-5 p-3 overallPadding d-flex justify-content-center gap-5 align-items-center">
                {getEventData?.flyer && <div>
                  <a
                    href={getEventData?.flyer}
                    cursor="pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    <Button className="bg-light-green rounded-0 border-1 menus-green-btn-outline">
                      Flyer / Poster
                    </Button>
                  </a>
                </div>}
                {getEventData?.brouchure && <div>
                  <a
                    href={getEventData?.brouchure}
                    cursor="pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    <Button className="bg-light-green rounded-0 border-1 menus-green-btn-outline">
                      Brouchure
                    </Button>
                  </a>
                </div>}
                {getEventData?.schedule && <div>
                  <a
                    href={getEventData?.schedule}
                    cursor="pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    <Button className="bg-light-green rounded-0 border-1 menus-green-btn-outline">
                      Schedule
                    </Button>
                  </a>
                </div>}
                {getEventData?.readingMaterial && <div>
                  <a
                    href={getEventData?.readingMaterial}
                    cursor="pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block", marginTop: "10px" }}
                  >
                    <Button className="bg-light-green rounded-0 border-1 menus-green-btn-outline">
                      Reading Material
                    </Button>
                  </a>
                </div>}
              </div>
            </>
          }
          <div className="d-flex justify-content-center flex-column align-items-center bg-light-green menus-green-color">
            <Typography className="poppins-regular fw-bold py-2" component={'h5'} variant="h5">Contact</Typography>
          </div>
          <div className="row row-gap-3 p-md-5 p-3 overallPadding align-items-center justify-content-center">
            {getEventData?.contacts && getEventData?.contacts.map(contact => (
              <>
                <div className="col-lg-4 col-sm-6 col-12 d-flex flex-column justify-content-center align-items-center">
                  <Typography className="poppins-regular fw-semibold menus-green-color" component={'h5'} variant="h5">{contact?.role}</Typography>
                  <Typography className="poppins-regular fw-semibold" component={'h5'} variant="h5">{contact?.name}</Typography>
                  <Typography className="poppins-regular fw-semibold text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}>{contact?.designation}, {contact?.institution}</Typography>
                  <Typography className="poppins-regular text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}> <span className="fw-semibold"> Mobile:</span> {contact?.mobile}</Typography>
                  <Typography className="poppins-regular text-center" style={{ minWidth: '300px' }} component={'p'} variant={'body1'}> <span className="fw-semibold"> Email:</span> {contact?.email}</Typography>
                </div>
              </>
            ))}
          </div>
          <div className="p-md-5 p-3 overallPadding">
            {getEventData?.membersId?.includes(memberDetail?._id) ? (
              <Button
                className="menus-color"
                variant="contained"
                style={{
                  color: "#fff",
                  bottom: "10%",
                  fontWeight: "bold",
                  marginTop: "50px",
                  right: "20px",
                  backgroundColor: "brown",
                  float: "right",
                  top: joinButton,
                  position: "relative"
                }}
              >
                Joined
              </Button>
            ) : (
              <>
                {!(
                  memberScope === "PORTAL_SIG_ADMIN" ||
                  memberScope === "PORTAL_CHAPTER_ADMIN" ||
                  memberScope === "PORTAL_SUPER_ADMIN" ||
                  memberScope === "PORTAL_ADMIN"
                ) &&
                  (((memberScope === undefined || memberScope === null) &&
                    getEventData.isEnableRegistration &&
                    getEventData.isPublic) ||
                    (memberScope === "PORTAL_MEMBER" && (getEventData?.isPublic || getEventData.isMember) && getEventData?.isEnableRegistration)) && (
                    <Button
                      variant="contained"
                      style={{
                        color: "#fff",
                        bottom: "10%",
                        fontWeight: "bold",
                        marginTop: "50px",
                        backgroundColor: "brown",
                        float: "right",
                        top: joinButton,
                        position: "relative"
                      }}
                      className="menus-color me-3"
                      onClick={() => joinEvent(getEventData)}
                    >
                      Join
                    </Button>
                  )}
              </>
            )}
          </div>

          {(memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") ? (
            <>
              {getEventData.isApproved === 0 ? (
                <div className="text-end p-md-5 p-3 overallPadding">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleOpen()}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "20px" }}
                    color="success"
                    onClick={() => handleApproveReject(eventId, 0)}
                  >
                    Approve
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Modal
            open={nonMemberRegisterOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={style}
              className="d-flex align-items-center flex-column gap-3"
            >
              <Typography
                component={"h2"}
                className="text-center fs-4 fw-bold mb-2 code "
                style={{ color: "brown" }}
              >
                Non Member Register Event
              </Typography>
              <form onSubmit={handleSubmitEventNonMember(nonMemberRegister)}
              >
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={
                    <Typography
                      className="text-dark"
                      InputLabelProps={{ shrink: true }}
                    >
                      Name{" "}
                      <span style={{ color: "red", fontFamily: "code" }}>
                        *
                      </span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("name", {
                    required: "Please Enter Name",
                  })}
                  error={!!nonMemberFormError?.name}
                  helperText={nonMemberFormError?.name?.message}
                />
                <TextField
                  style={{ marginTop: "20px" }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={
                    <Typography
                      className="text-dark"
                      InputLabelProps={{ shrink: true }}
                    >
                      Phone Number<span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("phone", {
                    required: "Please Enter Phone Number",
                    validate: (value) => {
                      if (String(value).length !== 10) {
                        return "Please enter a valid 10-digit phone number";
                      }
                    }
                  })}
                  error={!!nonMemberFormError?.phone}
                  helperText={nonMemberFormError?.phone?.message}
                />
                <TextField
                  style={{ width: "100%", marginTop: "20px" }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Email <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...nonMemberRegisterForm("email", {
                    required: "Please Enter a email",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Please Enter a valid Email",
                    },
                  })}
                  error={!!nonMemberFormError?.email}
                  helperText={nonMemberFormError?.email?.message}
                />
                <div className="row">
                  <div className="d-flex justify-content-center">
                    <div
                      className="mt-3 mx-auto"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="menus-color"
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: "brown" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={style}
              className="d-flex align-items-center flex-column gap-3"
            >
              <form className="w-100">
                <Typography
                  component={"h2"}
                  className="title-font text-center fs-4 fw-bold mb-2"
                >
                  Reject Event
                </Typography>
                {/* <TextField
                  inputProps={{ maxLength: "300" }}
                  fullWidth
                  multiline
                  rows={5}
                  label={
                    <Typography className="text-dark">
                      Reason for Rejection{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {300 - watch("rejectionReason").length}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.rejectionReason}
                  helperText={errors?.rejectionReason?.message}
                  {...register("rejectionReason")}
                /> */}
                <TextField
                  inputProps={{ maxLength: "300" }}
                  fullWidth
                  multiline
                  rows={5}
                  label={
                    <Typography className="text-dark">
                      Reason for Rejection <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment 
                      sx={{
                        position: 'relative',
                        top: '60px'
                      }}
              
                      position="end">
                        {300 - (watch("rejectionReason")?.length || 0)}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.rejectionReason}
                  helperText={errors?.rejectionReason?.message}
                  {...register("rejectionReason", {
                    required: "Please enter a reason for rejection",
                  })}
                  onChange={(event) => {
                    setValue("rejectionReason", event.target.value);
                    clearErrors("rejectionReason"); // Clear validation error when typing
                  }}
                />
                <div
                  className="mt-3 mx-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="menus-color"
                    variant="contained"
                    color="success"
                    onClick={() => handleApproveReject(eventId, 1)}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default IndividualEvent;
