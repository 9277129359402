import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { MembershipBenefitsData } from "../../constant/constant";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

const tableHeadCellStyle = {
  border: "2px solid var(--menus-green-color)",
  backgroundColor: "#4F6228",
  color: "white",
  fontFamily: "Poppins"
};
const MembershipBenifits = () => {


  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center title-font text-light py-3 mt-1">
          Membership Benefits
        </h2>
      </div>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 0 }}
        className="p-md-5 p-3 overallPadding my-5"
      >
        <Typography textAlign={'justify'} mb={3} className="content-font">
          The following table outlines the benefits available to members in
          different plans in each membership category. The membership benefits are
          classified into eleven groups: (1) Growth Matters, (2) Resource Matters,
          (3) Connection Matters, (4) Money Matters, (5) Leadership Matters, (6)
          Research Matters, (7) Advocacy Matters, (8) Recognition Matters, (9)
          Experience Matters, (10) Guidance Matters, and (11) Update Matters.
        </Typography>
        <Table className="table-bordered px-md-5 px-2 ">
          <TableHead>
            <TableRow sx={{ border: "2px solid var(--menus-green-color)" }}>
              <TableCell
                sx={{
                  backgroundColor: "var(--menus-green-color)",
                  color: "white",
                  border: "2px solid var(--menus-green-color)",
                  fontFamily: "Alata"
                }}
                align="right"
                colSpan={2}
                rowSpan={2}
              >
                Membership Benefits
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: "var(--menus-green-color)",
                  color: "white",
                  border: "2px solid var(--menus-green-color)",
                  fontFamily: "Alata"
                }}
              >
                Membership category
              </TableCell>
              <TableCell sx={tableHeadCellStyle} colSpan={3}>
                Individual Teacher & Teacher Couple Memberships
              </TableCell>
              <TableCell sx={tableHeadCellStyle}>
                Individual Student Membership
              </TableCell>
              <TableCell sx={tableHeadCellStyle} colSpan={2}>
                Institutional Membership
              </TableCell>
              <TableCell sx={tableHeadCellStyle}>
                Corporate Supporter Membership
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "var(--menus-green-color)",
                  color: "white",
                  border: "2px solid var(--menus-green-color)",
                  fontFamily: "Alata"
                }}
                align="right"
              >
                Plans
              </TableCell>
              <TableCell sx={tableHeadCellStyle}>Annual</TableCell>
              <TableCell sx={tableHeadCellStyle}>Short - term</TableCell>
              <TableCell sx={tableHeadCellStyle}>Long - term</TableCell>
              <TableCell sx={tableHeadCellStyle}>Short - term</TableCell>
              <TableCell sx={tableHeadCellStyle}>Short - term</TableCell>
              <TableCell sx={tableHeadCellStyle}>Long - term</TableCell>
              <TableCell sx={tableHeadCellStyle}>Long - term</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MembershipBenefitsData.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#d2dcc6', fontSize: 19, fontFamily: "Poppins" }} colSpan={10}>
                    <strong>
                      {index + 1}.{item.Category}
                    </strong>
                  </TableCell>
                </TableRow>
                {item.data.map((benefit, benefitIndex) => (
                  <TableRow key={benefitIndex}>
                    <TableCell colSpan={3} sx={{ fontFamily: "Poppins" }}>
                      <strong>
                        {benefit.name
                          ? index +
                          1 +
                          "." +
                          (benefitIndex + 1) +
                          "." +
                          benefit.name +
                          ": "
                          : index + 1 + "." + (benefitIndex + 1)}
                      </strong>
                      {benefit.description}
                    </TableCell>
                    {Object.entries(benefit.plans[0]).map(([key, value]) => (
                      <TableCell align="center">
                        {value === 1 ? (
                          <DoneOutlineIcon
                            sx={{ color: "green" }}
                          ></DoneOutlineIcon>
                        ) : (
                          " - "
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MembershipBenifits;
