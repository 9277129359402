import React, { useState, useEffect, useCallback } from 'react';
import TopHeader from "../../Component/Header/TopHeader";
import {
  Button, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography, Tooltip
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import placeholder from '../../../src/assets/images/Profile/profile.jpg';

import { useUpdateChapterProfileMutation, useLazyGetChapterRoleDetailsByIdQuery } from '../../app/services/chapterServices';
import { selectCurrentRoleId, selectUserId, setImageUrl } from '../../app/auth/authSlice';
import { setRoleDetails } from "../../app/features/chapterAdminSlice";
import { Salutation } from '../../constant/constant';
import ErrorIcon from '@mui/icons-material/Error';


const ChapterProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const roleId = useSelector(selectCurrentRoleId);
  const adminId = useSelector(selectUserId);

  const [updateChapterProfile] = useUpdateChapterProfileMutation();
  const [getChapterRoleDetails] = useLazyGetChapterRoleDetailsByIdQuery();
  const { register, handleSubmit, setValue, control, formState: { errors }, watch, getValues, reset, setError, clearErrors } = useForm(
    {
      mode: "onTouched"  // Validates on touch
    }
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const salutationWatch = watch("salutation");

  // Fetch chapter role details
  const fetchRoleDetails = useCallback(async () => {
    try {
      const res = await getChapterRoleDetails(roleId);
      console.log("API Response:", res?.data?.data);

      if (res?.data?.status) {
        const roleDetails = res.data.data.roleDetails;
        console.log("Fetched Salutation:", roleDetails.roleImageUrl); // 🔥 Logs 0 (valid ID)
        setValue('imageUrl', res?.data?.data?.roleDetails?.roleImageUrl)
        Object.entries(res?.data?.data?.roleDetails).forEach(([key, value]) => {
          setValue(key, value)
        })

        // Ensure salutation is correctly mapped (no need to find if already an ID)
        const validSalutation = roleDetails.salutation;
        // Reset the entire form (ensures fresh state)
        reset({
          ...roleDetails,
          salutation: validSalutation,
          imageUrl: res?.data?.data?.imageUrl || "", // Ensure it's not undefined
        });
        setTimeout(() => {
          setValue("imageUrl", res?.data?.data?.roleDetails?.roleImageUrl || "", { shouldValidate: true, shouldDirty: true });
        }, 0); // 🔥 Ensure reactivity by deferring execution


        // Ensure React Hook Form detects change
        setValue("salutation", validSalutation, { shouldValidate: true, shouldDirty: true });

        console.log("Updated Form Salutation:", getValues("salutation"));
        console.log("Updated Image URL:", getValues("imageUrl"));
      }
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  }, [roleId, getChapterRoleDetails, reset, setValue, getValues]);

  useEffect(() => {
    fetchRoleDetails();
  }, [fetchRoleDetails]);


  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (!file || !file.type.startsWith("image/")) {
      toast.error("Invalid file. Please select an image.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
          setError("image", { message: "Image dimensions must be between 140x140 and 150x150 pixels." });
          setSelectedImage(null); // Reset the preview if invalid
          return;
        }
        clearErrors("image");
        const base64String = e.target.result;
        setSelectedImage(base64String);
        setValue("imageUrl", base64String);
      };
    };

    reader.readAsDataURL(file);
  };


  // Update Chapter Profile
  const chapterUpdateProfile = async (data) => {
    try {
      const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
      const updateData = {
        id,
        formData: {
          name: data.name,
          email: data.email,
          address: data.address,
          pincode: data.pincode,
          designation: data.designation,
          institution: data.institution,
          role: data.role,

          mobile: data.mobile,
          salutation: data.salutation,
          imageUrl: base64Regex.test(data?.imageUrl)
            ? data.imageUrl
            : data.imageUrl.split("/").slice(-2).join("/"),
          roleId,
          adminId,
        },
      };

      const res = await updateChapterProfile(updateData);
      if (res?.data?.status) {
        toast.success(res.data.message);
        dispatch(setRoleDetails(res.data.data?.updateAdminData));
        console.log(res.data.data?.updateAdminData,'res.data.data')
        dispatch(setImageUrl({ imageUrl: res?.data?.data?.updateAdminData?.imageUrl }));
      } else {
        toast.error(res?.data?.err?.message);
      }
    } catch (error) {
      console.error("Profile update error:", error);
      toast.error("Something went wrong!");
    }
  };
  return (
    <div>
      <TopHeader />
      <div className='pt-lg-5 pt-3 '>
        <div className='Architects-header d-flex align-items-center justify-content-center'>
          <h2 className='title-font text-center'>Chapter Profile</h2>
        </div>
      </div>

      <Button
        onClick={() => window.history.back()}
        variant="contained"
        className="menus-color mt-4"
        style={{ float: "right", marginRight: "70px" }}
      >
        Back
      </Button>

      <div className='mb-3 mt-5 p-md-5 p-3 overallPadding'>
        <form onSubmit={handleSubmit(chapterUpdateProfile)}>
          {/* Image Upload Section */}
          <div className='row mb-3 '>
            <div className='col-md-12 d-flex justify-content-center position-relative'>
              {/* Tooltip with Error Icon */}
              <Tooltip title="Image dimensions must be between 140x140 and 150x150 pixels. Image must be .jpg, .jpeg, .png, .jfif">
                <span> {/* Tooltip needs a wrapper if used with non-button elements */}
                  <ErrorIcon
                    style={{
                      width: "20px", // Adjusted to a proper size
                      cursor: "pointer",
                      position: 'absolute',
                      top: "75%", // Adjust for better placement
                      right: "50%", // Align to the right within the div
                      color: "black",
                      backgroundColor: '#d3d3d3',
                      padding: "5px",
                      borderRadius: "50%"
                    }}
                  />
                </span>
              </Tooltip>

              {/* Image Preview */}
              <img
                src={selectedImage || watch("imageUrl") || placeholder}
                height="100"
                alt="Profile"
                style={{ objectFit: "scale-down", width: "200px" }}
                key={watch("imageUrl")}
              />

              {/* Hidden File Input */}
              <TextField
                sx={{ mt: 2 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/jpeg, image/png" }}
                id="imageUrl"
                type="file"
                style={{ display: 'none' }}
                {...register("image", {
                  required: !watch("imageUrl") && "Please Select an Image",
                  validate: (value) => {
                    if (!watch("imageUrl")) {
                      const file = value[0];
                      if (!file || !file.type.startsWith("image/")) return "Invalid image file";
                      if (file.size > 2 * 1024 * 1024) return "File size exceeds 2MB";
                      if (file.width < 140 || file.width > 150 || file.height < 140 || file.height > 150) {
                        setError("image", { message: "Image Size Invalid" });
                        return;
                      }
                    }
                    return true;
                  },
                  onChange: handleImageChange,
                })}
              />

              {/* File Upload Button */}
              <label htmlFor="imageUrl" style={{ marginLeft: "10px" }}>
                <Button variant="contained" className="menus-color border-radius-membership-wallpage-button" component="span">
                  Choose File *
                </Button>
              </label>

              {/* Error Message */}
              {!!errors?.image && <div style={{ color: "red", marginTop: "10px" }}>{errors?.image?.message}</div>}
            </div>

          </div>

          {/* Profile Details */}
          <div className='row'>
            <div className='col-md-4'>
              <Controller
                name="role"
                control={control}
                rules={{ required: "Role is required" }}
                render={({ field, fieldState }) => (
                  <FormControl className="w-100" error={!!fieldState.error}>
                    <InputLabel id="role-label">Role *</InputLabel>
                    <Select label="Role *" {...field} value={field.value || ""} onChange={(e) => {
                      field.onChange(e)
                      console.log(field.value)
                    }
                    }>
                      <MenuItem value="Convenor">Convenor</MenuItem>
                      <MenuItem value="Co-convenor">Co-convenor</MenuItem>
                      <MenuItem value="Executive Member">Executive Member</MenuItem>
                    </Select>
                    <FormHelperText error={fieldState?.error}>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              <TextField className='mt-3'
                InputLabelProps={{ shrink: true }}
                helperText={errors?.designation?.message}
                error={!!errors?.designation}
                fullWidth
                id="designation"
                placeholder="Enter  Designation"
                label={
                  <Typography className="text-dark">
                    Designation <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("designation", { required: "Please enter Designation" })}
              />


              <TextField className='mt-3' label="Pincode" InputLabelProps={{ shrink: true }} placeholder='Enter Pincode' {...register("pincode")} value={watch("pincode") || ""} onChange={(e) => setValue("pincode", e.target.value)} fullWidth />
            </div>


            <div className='col-md-4'>
              <Controller
                name="salutation"
                control={control}
                rules={{ required: "Salutation is required" }}
                render={({ field, fieldState }) => (
                  <FormControl className="w-100" error={!!fieldState.error}>
                    <InputLabel id="salutation-label">Salutation *</InputLabel>
                    <Select
                      label="Salutation *"
                      {...field}
                      value={salutationWatch ?? ""} // 🔥 Ensures controlled input
                      onChange={(e) => {
                        console.log("Salutation Changed:", e.target.value);
                        field.onChange(e.target.value);
                      }}
                    >
                      {Salutation.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.Name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fieldState?.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              {/* <TextField
                className="mt-3"
                label="Institution *"
                {...register("institution", { required: "Please Enter Institution" })}
                error={!!errors.institution}
                helperText={errors.institution?.message}
                fullWidth
                value={watch("institution") || ""}
                onChange={(e) => {
                  setValue("institution", e.target.value);
                  trigger("institution"); // Revalidate on change
                }}
              /> */}
              <TextField
                className='mt-3'
                InputLabelProps={{ shrink: true }}
                helperText={errors?.institution?.message}
                error={!!errors?.institution}
                fullWidth
                id="designation"
                placeholder="Enter Institution"
                label={
                  <Typography className="text-dark">
                    Name of the institution <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("institution", { required: "Please enter institution" })}
              />

              <TextField className='mt-3'
                style={{ width: "100%", }}
                InputLabelProps={{ shrink: true }}
                id="designation"
                label={
                  <Typography className="text-dark">
                    Email ID <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                disabled
                {...register("email", {
                  required: "Please Enter a email",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Please Enter a valid Email",
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email?.message} />


            </div>
            <div className='col-md-4'>
              <TextField
                InputLabelProps={{ shrink: true }}
                helperText={errors?.name?.message}
                error={!!errors?.name}
                fullWidth
                id="designation"
                placeholder="Enter name"
                label={
                  <Typography className="text-dark">
                    Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                {...register("name", { required: "Please enter Name" })}
              />
              <TextField className='mt-3'
              InputLabelProps={{ shrink: true }}
                label={
                  <Typography className="text-dark">
                    Complete Address for Communication <span style={{ color: "red" }}></span>
                  </Typography>
                }
                variant="outlined"
                placeholder="Complete Address for Communication  " {...register("address")} value={watch("address") || ""} onChange={(e) => setValue("address", e.target.value)} fullWidth />
              <TextField className='mt-3' InputLabelProps={{ shrink: true }} placeholder='Enter Mobile Number' label="Mobile " {...register("mobile")} onChange={(e) => setValue("mobile", e.target.value)} value={watch("mobile") || ""} fullWidth />
            </div>

            {/* <div className='col-md-4'>
            
            </div> */}
          </div>

          {/* Submit Button */}
          <div className="d-flex justify-content-center mt-4">
            <Button variant="contained" className="menus-color" type="submit">Update</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChapterProfile;
