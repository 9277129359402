import React, { useEffect, useState, useCallback } from 'react'
import { usePaginationImportantAnnouncementMutation } from '../../../app/services/importantAnnouncementService'
import EastIcon from '@mui/icons-material/East'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './spotlight.css'
import { usePaginationSpotLightMutation } from '../../../app/services/spotlightService'
import { Button } from '@material-ui/core'
import { Modal, Box, Typography, IconButton, Container, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'

const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    placeItems: "center",
    // width:'700px',
    maxWidth: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    padding: 4,
    height: "75vh",
    zIndex: "9999999",
};

function SpotLight() {
    const [page, setPage] = useState(1)
    const [spotlightPage, setSpotlightPage] = useState(1)
    const [announcements, setAnnouncements] = useState([])
    const [spotlights, setSpotlights] = useState([])
    const [paginationAnnouncement] = usePaginationImportantAnnouncementMutation()
    const [PaginationSpotLight] = usePaginationSpotLightMutation()
    const rowsPerPage = 7
    const [loading, setLoading] = useState(false) // Loading state to prevent multiple API requests
    const [isFetching, setIsFetching] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(() => (window.innerWidth > 768 ? 2 : 1));
    const [open, setOpen] = useState(false);
    const [selectedSpotlight, setSelectedSpotlight] = useState(null);
    const [totalCount, setTotalCount] = useState()


    useEffect(() => {
        const updateItemsPerPage = () => {
            setItemsPerPage(window.innerWidth > 768 ? 2 : 1);
        };

        window.addEventListener('resize', updateItemsPerPage);
        updateItemsPerPage(); // Set on initial load

        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);
    // useEffect(() => {

    // }, [itemsPerPage])

    // Fetch spotlight data
    // const fetchSpotLight = useCallback(async () => {
    //     const data = {
    //         page: spotlightPage,
    //         itemsPerPage,
    //         role: "",
    //     };

    //     try {
    //         const res = await PaginationSpotLight(data);
    //         if (res?.data?.status) {
    //             setSpotlights(res.data.data.spotLightDetails);
    //         } else {
    //             console.error("Failed to fetch spotlight data.");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching spotlight data:", error);
    //     }
    // }, [ spotlightPage, itemsPerPage]);

    // // Trigger fetch on dependencies change
    // useEffect(() => {
    //     fetchSpotLight();
    // }, [fetchSpotLight]);

    const fetchSpotLight = useCallback(async () => {
        const data = {
            page: spotlightPage,
            itemsPerPage,
            role: "", // Ensure role is correctly assigned if needed
        };

        try {
            const res = await PaginationSpotLight(data);
            if (res?.data?.status) {
                setSpotlights(res?.data?.data?.spotLightDetails);
                console.log(res?.data?.data?.totalCount)
                setTotalCount(res?.data?.data?.totalCount)
            } else {
                console.error("Failed to fetch spotlight data.");
            }
        } catch (error) {
            console.error("Error fetching spotlight data:", error);
        }
    }, [spotlightPage, itemsPerPage]); // Remove PaginationSpotLight from dependencies

    // Trigger fetch on dependencies change
    useEffect(() => {
        fetchSpotLight();
    }, [fetchSpotLight]);


    const fetchAnnouncements = useCallback(async () => {
        const data = {
            page: page,
            itemsPerPage: rowsPerPage,
            role: "",
        };
        try {
            const res = await paginationAnnouncement(data);
            if (res?.data?.status) {
                // Only append new announcements if there are new ones
                if (page > 1) {
                    setAnnouncements(prev => [...prev, ...res?.data?.data?.newAnnouncementDetails]);
                }
                else {
                    setAnnouncements(res?.data?.data?.newAnnouncementDetails)
                }

            }
        } catch (error) {
            console.error("Error fetching announcements:", error);
        } finally {
            setLoading(false); // Reset loading state
        }
    }, [page, rowsPerPage, paginationAnnouncement]);

    // UseEffect to fetch data when the component first loads

    useEffect(() => {
        fetchAnnouncements();
    }, [fetchAnnouncements]);

    // Handle scroll event and increase page number when bottom is reached
    const handleScroll = (e) => {
        setPage((prev) => prev + 1);
    };


    // Add scroll event listener to announcement container on mount
    useEffect(() => {
        const announcementContainer = document.getElementById('announcement-container');
        if (announcementContainer) {
            announcementContainer.addEventListener('scroll', handleScroll);
        }


        // Cleanup the scroll event listener when the component unmounts
        return () => {
            if (announcementContainer) {
                announcementContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading]); // Re-attach listener only when loading state changes

    useEffect(() => {
        fetchSpotLight();
    }, [fetchSpotLight]);



    // const handleNextPage = () => {
    //     if (isFetching) return;

    //     setIsFetching(true);

    //     setSpotlightPage((prev) => {
    //         const nextPage = prev + 1;

    //         fetchSpotLight(nextPage)
    //             .finally(() => {
    //                 setIsFetching(false);
    //             });

    //         return nextPage;
    //     });
    // };
    const handleNextPage = () => {
        if (isFetching || spotlights.length < itemsPerPage) return;
    
        setIsFetching(true);
    
        setSpotlightPage(prevPage => {
            const nextPage = prevPage + 1;
            fetchSpotLight()
                .finally(() => {
                    setIsFetching(false);
                });
    
            return nextPage;
        });
    };
    
 


    const handlePrevPage = () => {
        if (isFetching) return;

        setIsFetching(true);

        setSpotlightPage((prev) => {
            const prevPage = Math.max(prev - 1, 0);

            fetchSpotLight(prevPage)
                .finally(() => {
                    setIsFetching(false);
                });

            return prevPage;
        });
    };



    useEffect(() => {
        const announcementContainer = document.getElementById('announcement-container')
        if (announcementContainer) {
            announcementContainer.addEventListener('scroll', handleScroll)
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (announcementContainer) {
                announcementContainer.removeEventListener('scroll', handleScroll)
            }
        }
    }, [loading]) // Re-attach listener when loading state changes

    const handleOpen = (spotlight) => {
        setSelectedSpotlight(spotlight);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div className="spotlightHomepage-background-color">
            <div className='p-md-5 p-3 overallPadding'>
                <div className="row">
                    <div className="col-lg-7 pb-3">
                        <div className="d-flex justify-content-between">
                            <h2 className="custom-header title-font" style={{ color: "#ffff" }}>Spotlights</h2>
                            {/* Add your other logic here for navigation */}
                            <div className='Spot-light-header d-flex align-items-center'>
                                <div className="  mt-2 mt-md-0 content-font">
                                    <p className="viewAllEvents Spot-light-header Spot-light-header-border alata-medium mb-0">
                                        <Link to="/view-spot-light" style={{ textDecoration: 'none', color: '#ffff' }}>View All</Link>

                                        <EastIcon />
                                        <ArrowBackIosIcon
                                            onClick={spotlightPage > 1 ? handlePrevPage : null}
                                            className={`ms-1 arrowConfig-sportlight ${spotlightPage <= 1 ? 'disable-prev ' : ''}`}
                                        />
                                        {/* <ArrowForwardIosIcon
                                            onClick={spotlights.length === itemsPerPage ? handleNextPage : undefined}
                                            className={`ms-1 arrowConfig-sportlight ${spotlights.length < itemsPerPage ? 'disable-prev ' : ''}`}
                                        /> */}
                                        <ArrowForwardIosIcon
                                            onClick={spotlightPage * itemsPerPage < totalCount ? handleNextPage : undefined}
                                            className={`ms-1 arrowConfig-sportlight ${spotlightPage * itemsPerPage >= totalCount ? 'disable-prev' : ''}`}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {spotlights && spotlights.length > 0 ? (
                                spotlights.filter(sl => sl?.isDeleted === 0).map((spotlight) => (
                                    <div className="col-md-6" key={spotlight._id}>
                                        <div className="bg-white h-100 d-flex justify-content-between flex-column">
                                            {/* <div className="bg-white h-100 d-flex justify-content-between flex-column"> */}
                                            <div className='d-flex justify-content-center align-items-center py-3'>
                                                <img key={spotlight?.image} src={`${spotlight?.image}?t=${new Date().getTime()}`} alt={spotlight._id} className='aspect4' width={120} height={120} />
                                            </div>
                                            <div className='d-flex justify-content-center align-items-center backgroud-sportlight-Text mb-3 '>
                                                <h6 className='fw-bold poppins-semibold menus-green-color text-center text-capitalize px-3 title-font'>{spotlight?.heading}</h6>
                                            </div>
                                            <div className='pb-2'>
                                                <p className='spotlight-content poppins-light fs-6 justified px-3 content-font'>{spotlight?.content.length > 150 ? spotlight?.content.slice(0, 150) + "...." : spotlight?.content}</p>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-end mb-3">
                                                <Button
                                                    className="spot-light-button dynamicButton content-font"
                                                    style={{ color: '#ffffff', borderRadius: 0, textTransform: 'none' }}
                                                    variant="contained"
                                                    onClick={() => handleOpen(spotlight)}
                                                >
                                                    Know More
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12 content-font">
                                    <p>No spotlights available.</p>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className="col-lg-5">
                        <div className="d-flex justify-content-between">
                            <h2 className="custom-header title-font" style={{ color: "#ffff" }}>Announcements</h2>
                            {/* <div className='Spot-light-header d-flex align-items-center'>
                                <div className="ms-auto align-items-center mt-2 mt-md-0">
                                    <Link>
                                        <p className="Spot-light-header Spot-light-header-border alata-medium mt-4">View All </p>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                        <div
                            id="announcement-container"
                            className="shadow-none Announcement-home-page-card overflow-y-scroll bg-white"
                        >
                            {announcements.length > 0 ? (
                                announcements.map((announcement, i) => (
                                    <div key={i} className="announcements-card-homepage-text py-2 content-font">
                                        <ul>
                                            <li className="annocement-text poppins-regular lh-lg ">
                                                <span
                                                    className='announcementImg'
                                                    style={{
                                                        color: "#611f1e",
                                                        wordWrap: "break-word",
                                                        wordBreak: "break-word",
                                                        overflowWrap: "break-word",
                                                        whiteSpace: "pre-wrap",

                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: announcement.text || "No Content",
                                                    }}
                                                ></span>
                                            </li>
                                        </ul>
                                    </div>
                                ))
                            ) : (
                                <div className='content-font'>No Data Found</div>
                            )}
                            {loading && <div>Loading...</div>}
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={style1}
                            className="d-flex align-items-center flex-column gap-3 overflow-x-hidden"
                        >
                            <Container maxWidth={false} sx={{ px: 3 }}>
                                <Box className="d-flex justify-content-between py-2">

                                    <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }} >
                                        View Spotlights
                                    </Typography>
                                    <IconButton onClick={handleClose} aria-label="Close">
                                        <CloseIcon />
                                    </IconButton>
                                </Box>

                                {/* Centered Divider */}
                                <Box display="flex" justifyContent="center" width="100%">
                                    <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                                </Box>

                                <div className="d-flex justify-content-center">
                                    <h2
                                        className="alata-regular fs-5 text-center py-2"
                                        style={{ color: "#611f1e" }}
                                    >
                                        {selectedSpotlight?.heading}
                                    </h2>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div >
                                        <img
                                            src={selectedSpotlight?.image}
                                            className="img-fluid"
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div className="text-justify" >
                                    <p className="alata-regular spotlight-text-justify p-0 my-3" >
                                        {selectedSpotlight?.content}
                                    </p>
                                </div>
                            </Container>
                        </Box>
                    </Modal>
                </Modal>
            </div>
        </div>
    )
}

export default SpotLight
