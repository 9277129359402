import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
import {
  selectCurrentMember,
  setMemberDetails,
} from "../../app/features/memberSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllMemberListMutation,
  useUpdateMemberMutation,
  useLazyGetPendingMemberByIdQuery,
} from "../../app/services/memberListServices";
import { useLazyGetMemberShipDetailsByIdQuery, } from "../../app/services/memberShipServices";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "../../style/css/tab.css";
import { selectCurrentScope, setImageUrl, selectCurrentRoleId, selectUserId } from "../../app/auth/authSlice";
import "./viewmember.css";
// import ChapterAdminPortal from "../Chapter/ChapterAdminPortal";
// import SigAdminPortal from "../SigAdmin/SigAdminPortal";
// import Event from "../../Component/Portal/Event";
// import News from "../../Component/Portal/News";
// import Gallery from "../../Component/Portal/Gallery";
import Spinner from "../../Component/spinner/spinner";
// import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { Avatar, Container, Divider, FormHelperText, IconButton, Skeleton, Stack, TextField, Tooltip } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLazyGetChapterDetailsByIdQuery } from '../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../app/services/SIGGroupServices'
import { useGetAllModulesMutation } from '../../app/services/getAllModule'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLazyGetChapterRoleDetailsByIdQuery, useUpdateChapterProfileMutation, } from '../../app/services/chapterServices'
import { useLazyGetSIGGroupRoleDetailsByIdQuery } from '../../app/services/chapterServices'
// import {useLazyGetSIGRoleDetailsByIdQuery} from '../../app/services/SIGGroupServices'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { Qualifications, TypeOfInstitution, currently_teaching, planType, PrimarilyTeachingForTeacher, PrimarilyTeachingForInstitution, industries, Modules, Salutation } from './../../constant/constant'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrenSigRole, selectCurrentSig, setSigRoleDetails } from '../../app/features/sigSlice';
import { useUpdateAdminMutation } from '../../app/services/adminServices'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { setRoleDetails, selectCurrenChapterRole, selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import AddBoxIcon from '@mui/icons-material/AddBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LanguageIcon from "@mui/icons-material/Language";
import { useSaveNonReceiptMutation } from '../../app/services/nonReceiptService'
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Viewmember() {
  const [getMembershipDetailsById] = useLazyGetMemberShipDetailsByIdQuery();
  const [getAllModule] = useGetAllModulesMutation()
  const [membershipDetails, setMembershipDetails] = useState();
  const [getAllMemberData] = useGetAllMemberListMutation();
  const [updateMember] = useUpdateMemberMutation();
  const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery();
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterSearch, setFilterSearch] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const memberDetails = useSelector(selectCurrentMember);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const dispatch = useDispatch();
  const currentScope = useSelector(selectCurrentScope);
  const [loading, setLoading] = useState(false);
  const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
  const [getSigById] = useLazyGetSIGGroupByIdQuery()
  const [chapter, setChapter] = useState();
  const [sig, setSig] = useState([])
  const [allmodules, setAllModuces] = useState([])
  const [page, setPage] = useState(1)
  const [getChapterRoleDetailsById] = useLazyGetChapterRoleDetailsByIdQuery();
  const [newsChapter, setNewsChapter] = useState()
  const [newsSIG, setNewsSIG] = useState()
  const [eventChapter, setEventChapter] = useState()
  const [eventSIG, setEventSIG] = useState()
  const [forumChapter, setForumChapter] = useState()
  const [forumSIG, setForumSIG] = useState()
  const [getSIGRoleDetailsById] = useLazyGetSIGGroupRoleDetailsByIdQuery()
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [openTabMobile, setOpenMobile] = useState(false)
  const [openMemberTab, setOpenMemberTab] = useState(false)
  const [announcementChapter, setAnnouncementChapter] = useState()
  const [announcementSIG, setAnnouncementSIG] = useState()
  const [galleryChapter, setGalleryChapter] = useState()
  const [gallerySIG, setGallerySIG] = useState()
  const [planDetails, setPlanDetails] = useState([])
  const [imageKey, setImageKey] = useState(0);
  const [imageUpdate, setImageUpdate] = useState()
  const navigator = useNavigate()
  const [selectedType, setSelectedType] = useState()
  const [eventFilter, setEventFilter] = useState()
  const sigRoleDetails = useSelector(selectCurrenSigRole);
  const chapterRoleDetails = useSelector(selectCurrenChapterRole)
  const [sigDetails, setSigDetails] = useState()
  const [chapterDetails, setChapterDetails1] = useState()
  const [allMemberData, setAllMemberData] = useState([])
  const [oldImage, setOldImage] = useState(selectedImage);
  const sigRoleUserDetails = useSelector(setSigRoleDetails)
  const [updateSigProfile] = useUpdateAdminMutation()
  const [reportNonReceipt, setReportNonReceipt] = useState(false)
  const [noData, setNoData] = useState("")
  const setChapterDetails = useSelector(setRoleDetails);
  const [reportNonReceiptData, setReportNonReceiptData] = useState([])
  const [openModal, setOpenModel] = useState(false)
  const [saveNonReceiptDetails] = useSaveNonReceiptMutation()
  const [triggerFetch, setTriggerFetch] = useState(false);
  const chapterId = useSelector(selectCurrentChapterAdmin);
  const sigId = useSelector(selectCurrentSig);
  const [chapterImageUpdate] = useUpdateChapterProfileMutation()
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [report, setReport] = useState(true)
  const roleId = useSelector(selectCurrentRoleId)
  const adminId = useSelector(selectUserId)
  // console.log(chapterRoleDetails,'chapterRoleDetails')




  //handleopen member profile
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedMember(null);
    setSelectedMembership(null);
    setOpenModel(false)
    reset()
  };
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    reset,
    setError, clearErrors
  } = useForm()

  // const triggerAnimation = () => {
  //   setShouldAnimate(true);
  //   setTimeout(() => setShouldAnimate(false), 350); // Reset after animation duration
  // };


  useEffect(() => {
    if (sigRoleDetails?._id) { // Ensure sigRoleDetails._id exists before making the API call
      getSIGRoleDetailsById(sigRoleDetails?._id)
        .then((res) => {
          if (res?.data?.status) {
            setSigDetails(res?.data?.data);
          } else {
            // Handle cases where the response status indicates failure
          }
        })
        .catch((err) => console.log(err));
    }
    console.log(chapterRoleDetails?._id)
    if (roleId) {
      getChapterRoleDetailsById(roleId).then((res) => {
        if (res?.data.status) {
          console.log(res?.data.data, 'cd')
          setChapterDetails1(res?.data.data)
        }
      }).catch((err) => console.log(err))
    }
  }, [sigRoleDetails?._id, getSIGRoleDetailsById, chapterRoleDetails?._id, getChapterRoleDetailsById, roleId]);


  useEffect(() => {
    console.log(chapterDetails, 'cd')
  }, [sigDetails, chapterDetails])
  //memberDetailsPlan 
  useEffect(() => {
    // setLoading(true);
    var memberDetailsPlan = memberDetails?.plans
    setPlanDetails(memberDetailsPlan)
    memberDetailsPlan?.forEach((memberDetail) => {
      getMembershipDetailsById(memberDetail.planId).then((res) => {
        if (res?.data?.status) {
          setLoading(false);
          setMembershipDetails(res?.data?.data);
        }
      });
    })
    //getAllmember

  }, [getAllMemberData, getMembershipDetailsById, memberDetails?.plans, membershipDetails, setPlanDetails]);

  useEffect(() => {
    const data = {
      page: "",
      itemsPerPage: "",
      type: ""
    }
    getAllMemberData(data).then((res) => {
      if (res?.data?.status) {
        setAllMemberData(res?.data?.data)
      }
      else {
        console.log("err")
      }
    }).catch((err) => console.log(err))
  }, [getAllMemberData])
  useEffect(() => {
    console.log(allMemberData, '230')
  }, [allMemberData])

  const getQualificationLabel = (id) => {
    const qualification = Qualifications.find((q) => q?.id === id);
    return qualification ? qualification?.label : "-";
  };

  const getTypeOfInstitution = (id) => {
    const institution = TypeOfInstitution.find((q) => q?.id === id);
    return institution ? institution?.type : "-";
  };
  const getindustries = (id) => {
    const industry = industries.find(data => data?.id === id);
    return industry ? industry?.name : "";
  };
  const getTypeOfEducation = (id) => {
    const Education = PrimarilyTeachingForInstitution.find((q) => q?.id === id);
    return Education ? Education?.label : "";

  };
  const getOranginication = (id) => {
    const Education = PrimarilyTeachingForTeacher.find((q) => q?.id === id);
    return Education ? Education?.label : "";
  };

  const getSalutation = (id) => {
    const sal = Salutation.find((s) => (s.id === id));
    return sal ? sal.Name : ''
  }

  const getCurrentlyyTeaching = (id) => {
    const teaching = currently_teaching.find((q) => q?.id === id);
    return teaching ? teaching?.label : "";
  };
  const getPrimaryTeachingTeaching = (id) => {
    const teaching1 = PrimarilyTeachingForTeacher.find((q) => q?.id === id);
    return teaching1 ? teaching1?.label : "";
  };
  const planTypeShow = (id) => {
    const plan = planType?.find((data) => data?.planId === id);
    return plan ? plan?.planName : "Unknown Plan";
  };
  useEffect(() => {
    console.log(membershipDetails)

  }, [planDetails, membershipDetails])
  

  const handleReport = () => {
    setPage(1)
    setSelectedType("")
    setEventFilter("")
    setReportNonReceipt(true);
    setShouldAnimate(true)
    // triggerAnimation();
  }
  const handleTypesChange = (e) => {
    if (!report) {
      setReport(true)
    }
    setPage(1)
    setSelectedType(e.target.value)
    setEventFilter("")
    setReportNonReceipt(false);
    setShouldAnimate(true);
    // triggerAnimation();
  }

  useEffect(() => {
    if (reportNonReceipt) {
      setReport(false)
    }
  }, [reportNonReceipt])
  const handleSaveNonReceipt = (data) => {
    saveNonReceiptDetails(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message)
        reset()
        handleReport()
        handleClose()
        setTriggerFetch((prev) => !prev);
      }
      else {
        toast.error(res?.data?.message)
        reset()
        handleClose()
      }
    })
  }
  useEffect(() => {
    let isMounted = true;
    setMemberLoading(true);
    console.log("eventFilter:", eventFilter)
    const data = {
      page,
      itemsPerPage: 7,
      moduleType: selectedType || "",
      filterEvent: eventFilter,
      sigId: sigId?._id || "",
      chapterId: chapterId?._id || "",
      reportNonReceipt: reportNonReceipt
    };

    const fetchModules = () => getAllModule(data);

    const handleResponse = (res) => {
      if (!isMounted) return;
      const { data } = res;
      const { totalCount, modules, eventDetails, reportNonReceiptDetails } = data?.data || {};
      const items = modules || eventDetails || reportNonReceiptDetails || [];

      setMemberLoading(false);

      if (reportNonReceiptDetails) {
        setReportNonReceiptData(prev => (page === 1 ? items : [...prev, ...items]));
      }

      if (data?.status) {
        if (totalCount && items.length > 0) {
          const maxPages = Math.ceil(totalCount / 4);

          if (page <= maxPages) {
            if (page === 1) {
              setAllModuces(items); // Replace previous state if on the first page
              setTimeout(() => {
                setShouldAnimate(false);
              }, 500)
            } else {
              setAllModuces(prev => [...prev, ...items]); // Append if not the first page
            }
            setIsFetching(false);
          }
        }
      } else {
        if (page === 1 && !items.length) {
          setAllModuces([]);
          setNoData("No Data Found");
        }
      }
    };
    const timeoutId = setTimeout(() => {
      fetchModules()
        .then(handleResponse)
        .catch((err) => {
          if (isMounted) {
            setMemberLoading(false);
            setShouldAnimate(false)
            console.error(err);
          }
        });
    }, 1000);

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [page, selectedType, eventFilter, chapterId?._id, sigId?._id, reportNonReceipt, getAllModule, triggerFetch]);




  useEffect(() => {
  }, [noData])

  //get All Divid By CreatebyId 
  useEffect(() => {
    // Check if there are more modules or chapters
    setHasMore(allmodules?.length > 0);

    const fetchRoleDetails = async (module) => {
      const createdBy = module?.createdBy;
      if (!createdBy?.id) {
        console.warn("Module with missing 'createdBy' ID", module);
        return; // Early return if ID is missing
      }

      try {
        if (createdBy?.role === "PORTAL_CHAPTER_ADMIN") {
          const res = await getChapterRoleDetailsById(createdBy?.id);
          const data = res?.data?.data;
          if (data) {
            setNewsChapter(data);
            setEventChapter(data);
            setForumChapter(data);
            setAnnouncementChapter(data);
            setGalleryChapter(data);
          } else {
            console.warn(`No data returned for Chapter ID ${createdBy?.id}`);
          }
        } else {
          const res = await getSIGRoleDetailsById(createdBy?.id);
          const data = res?.data?.data;
          if (data) {
            setNewsSIG(data);
            setEventSIG(data);
            setForumSIG(data);
            setAnnouncementSIG(data);
            setGallerySIG(data);
          } else {
            console.warn(`No data returned for SIG ID ${createdBy?.id}`);
          }
        }
      } catch (error) {
        console.error(`Error fetching role details for ID ${createdBy?.id}:`, error);
      }
    };

    const modulesToProcess = allmodules;
    modulesToProcess?.forEach(fetchRoleDetails);
  }, [allmodules, currentScope, getChapterRoleDetailsById, getSIGRoleDetailsById]);

  useEffect(() => {
  }, [galleryChapter, gallerySIG, announcementChapter, announcementSIG, newsChapter, newsSIG, eventChapter, forumChapter, eventSIG, forumSIG]);


  //when the scroll get the Data
  useEffect(() => {
    const memberFeed = document.querySelector(".member-customScroll");
    const handleScroll = () => {
      if (!memberFeed) return;
      const { scrollTop, scrollHeight, clientHeight } = memberFeed;
      // Check if scroll is near the bottom and if loading, hasMore, or isFetching are false
      if (scrollTop + clientHeight >= scrollHeight - 500 && !loading && hasMore && !isFetching) {
        setIsFetching(true);
        setPage(prevPage => prevPage + 1);

        const data = {
          page: page + 1,
          itemsPerPage: 7,
          moduleType: selectedType || "",
          // ...(eventFilter && { filterEvent: eventFilter }),
          filterEvent: (eventFilter) ? (eventFilter === 0) ? 0 : eventFilter : "",
          sigId: sigId?._id || "",
          chapterId: chapterId?._id || "",
          reportNonReceipt: reportNonReceipt
        };
        getAllModule(data);
        // if(currentScope === "PORTAL_MEMBER"){
        //   getAllModule(data);
        // }else if(currentScope === "PORTAL_CHAPTER_ADMIN"){
        //   chapterFeed(data)
        // }
        // else{

        // } 
        memberFeed.removeEventListener('scroll', handleScroll);
      }
    };

    // Add scroll event listener
    if (memberFeed) {
      memberFeed.addEventListener('scroll', handleScroll);
    }

    // Cleanup scroll event listener
    return () => {
      if (memberFeed) {
        memberFeed.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, hasMore, isFetching, page, getAllModule, selectedType, chapterId?._id, sigId?._id, reportNonReceipt, eventFilter]);

  //chapter Admin details
  useEffect(() => {
    if (memberDetails?.chapter) {
      getChapterById(memberDetails?.chapter).then((res) => {
        setChapter(res?.data?.data?.title);
      });
    }

    //Sig Admin Details
    if (memberDetails?.sigGroups) {
      let groupName = [];
      memberDetails?.sigGroups.forEach((id) => {
        getSigById(id).then((res) => {
          groupName.push(res?.data?.data?.groupName)
        });
      });
      setSig(groupName);
    }
  }, [getChapterById, memberDetails?.chapter, memberDetails?.sigGroups, getSigById]);

  //get planid byid
  const getMembership = (planId) => {
    setLoading(true);
    getMembershipDetailsById(planId).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        const data = res.data?.data;
        setSelectedMembership(data);
      }
    });
  };

  //Date Format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  //show theCard
  const showUserCard = (value) => {
    setSelectedMember(value);
    getMembership(value?.plans[0]?.planId);
    handleOpen();
    setFilterSearch([])
  };

  const handleAddReport = () => {
    setOpenModel(true)
  }
  // member Search
  const handleGetAllSearch = (e) => {
    const searchValue = e?.target.value?.toLowerCase();
    console.log(searchValue)
    let filteredData = [];
    if (searchValue) {
      filteredData = allMemberData?.memberDetails?.filter((item) => {
        if (item?.type === "0" || item?.type === "1" || item?.type === "4") {
          return item?.fullName?.toLowerCase().includes(searchValue);
        } else if (item?.type === "3") {
          return item?.organisationName?.toLowerCase().includes(searchValue);
        } else if (item?.type === "2") {
          return item?.institution?.toLowerCase().includes(searchValue);
        }
        return false;
      });
    }
    console.log(filteredData, 'filteredData')
    setFilterSearch(filteredData);
  };

  const handleUpcomingClick = (id) => {
    console.log(id, "id")
    // const data=Number(id)
    console.log(typeof (id))
    // setSelectedType(2)
    setEventFilter(id)
    setPage(1)
    setSelectedType(2)
    // triggerAnimation();
  }

  useEffect(() => {
    console.log(filterSearch, 'filterSearch')
  }, [filterSearch, chapter, sig]);

  // useEffect(() => { }, [allMemberDetails]);

  //Gallery  
  const GalleryPost = ({ item }) => {
    const [galleryList, setGalleryList] = useState([]);
    const [category, setCategory] = useState([]);

    useEffect(() => {
      if (item?.list && item?.list.length > 0) {
        setGalleryList(item?.list);
        setCategory(item);
      } else {
        setGalleryList([]);
        setCategory([]);
      }
    }, [item]);

    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-gallery';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-gallery';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-gallery';
        case 'PORTAL_ADMIN':
          return 'portal-admin-gallery'
        default:
          return '';
      }
    };
    const handleGallery = (id) => {
      navigator(`/showGallery/${id}`);
    };

    const role = item?.createdBy?.role;


    return (
      <div>
        <div key={item?._id} className={`${getRoleStyle(item?.createdBy?.role)}`}>
          <div className="container-fluid">
            <div className="border w-100 h-auto gallery-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    {role === "PORTAL_CHAPTER_ADMIN" || role === "PORTAL_SIG_ADMIN" ? (
                      <Box display="flex" alignItems="center">
                        <Avatar
                          alt={role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                          src={
                            role === "PORTAL_CHAPTER_ADMIN"
                              ? galleryChapter?.imageUrl
                              : gallerySIG?.imageUrl
                          }
                        />
                        <Box>
                          <Stack>
                            <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, fontSize: '12px' }}>
                              {role === 'PORTAL_CHAPTER_ADMIN' ? (
                                <p className="Chapter-font-color">{galleryChapter?.roleDetails?.name}</p>
                              ) : (
                                <p className="sig-feed-font-color">{gallerySIG?.roleDetails?.name}</p>
                              )}
                            </Typography>
                            <Typography component="span" sx={{ marginLeft: 1, fontSize: "12px" }}>
                              {role === "PORTAL_CHAPTER_ADMIN" ? (
                                <p className="Chapter-font-color">Chapter</p>
                              ) : (
                                <p className="sig-font-color">SIG</p>
                              )}
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    ) : role === "PORTAL_SUPER_ADMIN" ? (
                      <Box display="flex" alignItems="center" className="mt-2">
                        <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                        <Typography component="span" className="fs-6 fw-bold" sx={{ marginLeft: 1, color: '#ED6B1B' }}>
                          Admin
                        </Typography>
                      </Box>
                    ) : (<>
                      <Box display="flex" alignItems="center" className="mt-2">
                        <Avatar alt="Assistant Admin" src="/path/to/avatar.jpg" className="ml-3" />
                        <Typography component="span" className="fs-6 fw-bold" sx={{ marginLeft: 1, color: '#EDAB1B' }}>
                          Assistant Admin
                        </Typography>
                      </Box>
                    </>)}
                  </div>
                  <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                    <span className="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button ">Gallery</span>
                  </div>
                </div>
                <div className="row">
                  <Container maxWidth="md">
                    <div className="row">
                      <div className="col-md-5">
                        <img src={item?.list[0]?.imageUrl} className="galleryPost-image" width="100%" height="150px" alt="gallery" />
                      </div>
                      <div className="col-md-6">
                        <h5 className="mt-3" sx={{ marginLeft: 1, color: '#ED6B1B' }}>{category?.category}</h5>
                        <p sx={{ marginLeft: 1, color: '#ED6B1B' }}>
                          <span className="border border-secondary rounded px-3 py-1">{galleryList.length}</span> Available Images
                        </p>
                        <div className="d-flex align-items-end justify-content-end">
                          <Button variant="contained" className="menus-color border-radius-membership-wallpage-button " onClick={() => handleGallery(item?._id)}
                          >View More</Button>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Article = ({ item }) => {
    useEffect(() => {
    }, [item])

    //getRoleId Divid The Style
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin'
        case 'PORTAL_ADMIN':
          return 'portal-admin'
        default:
          return '';
      }
    };
    // news 
    const handleViewNews = (id) => {
      navigator(`/viewJournal/${id}`)
    }
    return (
      <div>
        <div key={item?._id} className={`${getRoleStyle(item?.createdBy?.role)}`}>
          <div className="container-fluid">
            <div className="border w-100   news-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="row">
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      {
                        (item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" || item?.createdBy?.role === "PORTAL_SIG_ADMIN") ?
                          (<>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                                src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                              />
                              <Box >
                                <Stack  >
                                  <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, fontSize: '12px' }}>
                                    {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <p className="Chapter-font-color" >{newsChapter?.roleDetails?.name}</p> : <p className="sig-feed-font-color">{newsSIG?.roleDetails?.name}</p>}
                                  </Typography>
                                  <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                    {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <p className="Chapter-font-color">Chapter </p> : <p className="sig-font-color">SIG Admin </p>}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>
                          </>) : item?.createdBy?.role === "PORTAL_SUPER_ADMIN" ? (
                            <>
                              <Box display="flex" alignItems="center" className="mt-2">

                                <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                  Admin
                                </Typography>
                              </Box>
                            </>
                          ) : <>
                            <Box display="flex" alignItems="center" className="mt-2">
                              <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                              <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#EDAB1B' }} >
                                Assistant Admin
                              </Typography>
                            </Box>
                          </>
                      }
                    </div>
                    <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                      <span class="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button">Our Update</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <img src={item?.thumbnailImage} alt="news" className="news-image-member-feed" width={"100%"} height={165} />
                  </div>
                  <div className="col-md-9 ps-0 ">
                    <div className="border  news-bg-artical-details-bg-color rounded h-auto" >
                      <Container>
                        <div className="fs-6 fw-bold  ">{item?.headline}</div>
                        <div className="fs-6 news-author-color ">By {item?.authorName}</div>
                        <div className="fs-6 news-body">{item?.summary?.length > 175 ? item?.summary.substring(0, 175) + "...." : item?.summary}</div>

                      </Container>
                      <div className="fs-6  px-3 text-end news-color m-0 d-block">{formatDate(item?.date)}</div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button variant="contained" className="menus-color border-radius-membership-wallpage-button " onClick={() => handleViewNews(item?._id)}
                      >View More</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //component for announcement
  const AnnouncementPost = ({ item }) => {
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-announcement';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-announcement';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-announcement'
        case 'PORTAL_ADMIN':
          return 'portal-admin-announcement'
        default:
          return '';
      }
    };
    return (
      <div>
        {item?.text ?
          <div key={item?._id} className={`${getRoleStyle(item?.createdBy?.role)}`}>
            <div className="container-fluid">
              <div className="border w-100   announcement-bg-color border-radius-member-container mb-3">
                <div className="container p-3">
                  <div className="d-flex justify-content-between mb-2">
                    {/* <Box display="flex" alignItems="center">
              <Avatar
                alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? eventChapter?.imageUrl : eventSIG?.imageUrl}
              />
              <Box >
                <Stack >

                  <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                    {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{eventChapter?.roleDetails?.name}</> : <>{eventSIG?.roleDetails?.name}</>}
                  </Typography>
                  <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                    {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <>Chapter </> : <>SIG </>}
                  </Typography>
                </Stack>
              </Box>
            </Box> */}
                    <div>
                      {
                        (item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" || item?.createdBy?.role === "PORTAL_SIG_ADMIN") ?
                          (<>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                                src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? announcementChapter?.imageUrl : announcementSIG?.imageUrl
                                }
                              />
                              <Box >
                                <Stack >
                                  <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#104E29', fontSize: '12px' }}>
                                    {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <p className="Chapter-font-color">{announcementChapter?.roleDetails?.name}</p> : <p className="sig-feed-font-color">{announcementSIG?.roleDetails?.name}</p>}
                                  </Typography>
                                  <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#104E29', fontSize: "12px" }} >
                                    {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <p className="Chapter-font-color">Chapter </p> : <p className="sig-font-color">SIG </p>}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Box>

                          </>) : item?.createdBy?.role === "PORTAL_SUPER_ADMIN" ? (
                            <>
                              <Box display="flex" alignItems="center" className="mt-2">
                                <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                  Admin
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box display="flex" alignItems="center" className="mt-2">
                                <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                                <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#EDAB1B' }} >
                                  Assistant Admin
                                </Typography>
                              </Box>
                            </>
                          )
                      }

                    </div>
                    <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                      <span class="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button ">Announcement</span>
                    </div>
                  </div>
                  <div className="row">
                    <Container maxWidth="md" className="px-3">
                      {/* <h5 className="text-center">{item?.heading}</h5> */}
                      <p
                        className={`text-justify announcement-text-wrap px-2 ${item?.text?.includes("<img") ? "img-width" : ""}`}
                        dangerouslySetInnerHTML={{
                          __html: item?.text || "No Content",
                        }}
                      ></p>

                      <style jsx>
                        {`
    .img-width img {
      width: 100%;
    }
  `}
                      </style>

                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <>No Announcement Found</>
        }

      </div>
    )
  }
  // Component for Forum 1


  const ForumPost = ({ item }) => {
    const getRoleStyle = (role) => {
      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-forum';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-forum';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-forum';
        case 'PORTAL_ADMIN':
          return 'portal-admin-forum'
        default:
          return '';
      }
    };

    const handleViewForm = (id) => {
      navigator(`/readMoreForum/${id}`);
    };
    useEffect(() => {
      if (item?.category) {
        // handleGetCategoryName(item?.category); // Fetch category name when component mounts or id changes
      }
    }, [item?.category]);

    return (
      <div>
        <div key={item?._id} className={`${getRoleStyle(item?.createdBy?.role)}`}>
          <div className="container-fluid">
            <div className="border w-100 forum-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="row">
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      {(item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' || item?.createdBy?.role === 'PORTAL_SIG_ADMIN') ? (
                        <Box display="flex" alignItems="center">
                          <Avatar className={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter-Admin-avatar" : "SIG-Admin-avatar"}
                            alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                            src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                          />
                          <Box>
                            <Stack>
                              <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                                {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <p className="Chapter-font-color">{newsChapter?.roleDetails?.name}</p> : <p className="sig-font-color">{newsSIG?.roleDetails?.name}</p>}
                              </Typography>
                              <Typography component="span" className=" " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <p className="Chapter-font-color">Chapter </p> : <p className="sig-font-color">SIG Admin </p>}
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                      ) : item?.createdBy?.role === "PORTAL_SUPER_ADMIN" ? (
                        <Box display="flex" alignItems="center" className="mt-2">
                          <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3 super-Admin-avatar" />
                          <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                            Admin
                          </Typography>
                        </Box>
                      ) :
                        <Box display="flex" alignItems="center" className="mt-2">
                          <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3 super-Admin-avatar" />
                          <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#EDAB1B' }} >
                            Assistant Admin
                          </Typography>
                        </Box>
                      }
                    </div>
                    <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                      <span className="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button">Forum</span>
                    </div>
                  </div>
                </div>
              </div>
              <Container>
                <div className="card mb-2 h-auto">
                  <div className="row forum-bg-artical-details-bg-color">
                    <div className="col-md-8">
                      <Container>
                        <h5 className="mt-4">{item?.headline}</h5>
                        <p className="text-justify mt-2">{item?.description?.length > 75 ? item?.description.substring(0, 75) + "...." : item?.description}</p>
                      </Container>
                    </div>
                    <div className="col-md-4">
                      <Card className="border h-auto">
                        <Card className="text-center border p-4 forum-bg-artical-details-bg-color">{item?.categoryDetails?.categoryName}</Card>
                        <Card className="text-center border p-4 forum-bg-artical-details-bg-color">{item?.name}</Card>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button variant="contained" className="menus-color my-2 border-radius-membership-wallpage-button" onClick={() => handleViewForm(item?._id)}>
                    View More
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Skeleten = () => {
    return (
      <div>
        <Container maxWidth="xl" className="mt-3 ">
          <Card className="transparent-loading py-3" aria-busy="true">
            <div className="row">
              <Stack direction="row" spacing={1} className="col-3">
                <Skeleton variant="circular" width={40} height={40} className="mx-2" />
                <Skeleton variant="text" width={60} />
              </Stack>
              <div className="col-7"></div>
              <div className="col-1 mx-1">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-5">
                <Skeleton variant="rectangular" className="mx-3" height={130} />
              </div>
              <div className="col-7">
                <Stack >
                  <Skeleton variant="text" width={430} />
                  <Skeleton variant="text" width={260} />
                  <Skeleton variant="text" width={160} />
                </Stack>
                <div class="d-flex justify-content-end">
                  <Skeleton variant="rectangular" width={100} height={30} className="mt-3" />
                </div>
              </div>
            </div>
          </Card>
        </Container>
      </div>

    )
  }


  //eventpost
  const EventPost = ({ item }) => {
    const getRoleStyle = (role) => {

      switch (role) {
        case 'PORTAL_SUPER_ADMIN':
          return 'portal-super-admin-event';
        case 'PORTAL_CHAPTER_ADMIN':
          return 'portal-chapter-admin-event';
        case 'PORTAL_SIG_ADMIN':
          return 'portal-sig-admin-event'
        case 'PORTAL_ADMIN':
          return 'portal-admin-event'
        default:
          return '';
      }
    };
    const handlIndivalevent = (id) => {
      navigator(`/VieweventFeed/${id}`)
    }
    return (
      <div>
        <div key={item?._id} className={`${getRoleStyle(item?.createdBy?.role)} `}>
          <div className="container-fluid">
            <div className="border w-100   event-bg-color border-radius-member-container mb-3">
              <div className="container p-3">
                <div className="d-flex justify-content-between mb-2">
                  {/* <Box display="flex" alignItems="center">
                    <Avatar
                      alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                      src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? eventChapter?.imageUrl : eventSIG?.imageUrl}
                    />
                    <Box >
                      <Stack >

                        <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, color: '#fff', fontSize: '12px' }}>
                          {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <>{eventChapter?.roleDetails?.name}</> : <>{eventSIG?.roleDetails?.name}</>}
                        </Typography>
                        <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                          {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <>Chapter </> : <>SIG </>}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box> */}
                  <div>
                    {
                      (item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" || item?.createdBy?.role === "PORTAL_SIG_ADMIN") ?
                        (<>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? "Chapter Admin" : "SIG Admin"}
                              src={item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? newsChapter?.imageUrl : newsSIG?.imageUrl}
                            />
                            <Box >
                              <Stack >
                                <Typography component="span" className="fw-bold" sx={{ marginLeft: 1, fontSize: '12px' }}>
                                  {item?.createdBy?.role === 'PORTAL_CHAPTER_ADMIN' ? <p className="Chapter-font-color">{newsChapter?.roleDetails?.name}</p> : <p className="sig-feed-font-color">{newsSIG?.roleDetails?.name}</p>}
                                </Typography>
                                <Typography component="span" className="  " sx={{ marginLeft: 1, color: '#ffff', fontSize: "12px" }} >
                                  {item?.createdBy?.role === "PORTAL_CHAPTER_ADMIN" ? <p className="Chapter-font-color">Chapter </p> : <p className="sig-font-color">SIG Admin </p>}
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>

                        </>) : item?.createdBy?.role === "PORTAL_SUPER_ADMIN" ? (
                          <>
                            <Box display="flex" alignItems="center" className="mt-2">
                              <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                              <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#ED6B1B' }} >
                                Admin
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display="flex" alignItems="center" className="mt-2">
                              <Avatar alt="Admin" src="/path/to/avatar.jpg" className="ml-3" />
                              <Typography component="span" className="fs-6 fw-bold " sx={{ marginLeft: 1, color: '#EDAB1B' }} >
                                Assistant Admin
                              </Typography>
                            </Box>
                          </>
                        )
                    }

                  </div>
                  <div className="news-badge-admin-container d-flex justify-content-end mt-1 ml-5">
                    <span class="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button">Event</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 d-flex justify-content-center">
                    <img src={item?.imageUrl} alt="i" className="news-image-member-feed" width={"100%"} height={160} />
                  </div>
                  <div className="col-md-7 ps-0 ">
                    <div className="event-member-details">
                      <div><h3 className="text-start  admin-font-event-color mb-3">{item?.title}</h3></div>
                      <div>
                        <p className="text-start admin-font-event-color">
                          <CalendarMonthIcon style={{ color: "#000" }} />{" "}
                          {new Date(item?.startDate).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}

                          {item?.endDate && (
                            <> - {new Date(item?.endDate).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                            </>
                          )},&nbsp;&nbsp;
                          <AccessTimeIcon style={{ color: "#000" }} />{" "}
                          {item?.startTime} {item?.endTime && ` - ${item.endTime}`}



                        </p>

                        {(item?.venue || item?.meetingURL) && (
                          <>
                            {item?.venue && (
                              <p className="text-start admin-font-event-color announcement-text-wrap">
                                <LocationOnIcon style={{ color: "#000" }} />
                                {item?.mapLink && ` ${item.mapLink}`}
                              </p>
                            )}

                            {item?.meetingURL && (
                              <p className="text-start admin-font-event-color announcement-text-wrap">
                                <LanguageIcon style={{ color: "#000" }} />{" "}
                                {item?.platform && ` ${item.platform} -`}
                                <a href={item.meetingURL} target="_blank" rel="noopener noreferrer">
                                  {item.meetingURL}
                                </a>

                              </p>
                            )}
                          </>
                        )}

                      </div>

                      {item?.startTime && <div className="text-start admin-font-event-color"><p></p></div>}
                      <div className="d-flex justify-content-end">
                        <Button variant="contained" className="menus-color border-radius-membership-wallpage-button" onClick={() => handlIndivalevent(item?._id)}
                        >View More</Button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  useEffect(() => {
  }, [reportNonReceiptData])


  const ReportNonReceiptItem = ({ item }) => {
    // Role-based display name
    return (
      <div className={`container-fluid portal-member`}>
        <div className="border w-100 forum-bg-color border-radius-member-container mb-3">
          <div className="container p-3">
            <Box display="flex" alignItems="center" className="mt-2">
              <Container maxWidth={"xl"}>
                <div className="d-flex justify-content-between ">
                  <p className="ps-2 non-receipt-font-color">{item?.monthOfJournal}</p>
                  <div className="news-badge-admin-container">
                    <span className="badge badge-pill badge-primary news-badge-admin border-radius-membership-wallpage-button">
                      Report Non Receipt
                    </span>
                  </div>
                </div>
              </Container>
            </Box>
            <Container>
              <div className="card mb-2 h-auto">
                <div className="row forum-bg-artical-details-bg-color">
                  <div className="col-md-8">
                    <Container>
                      <h6 className="mt-4">{item?.description.length > 200 ? `${item?.description.substring(0, 200)}...` : item?.description}</h6>
                      <p className="text-justify mt-2">{item?.comment?.length > 150 ? `${item?.comment?.substring(0, 150)}...` : item?.comment}</p>
                    </Container>
                  </div>
                  <div className="col-md-4">
                    <Card className="border h-auto my-2">
                      <Card className="text-center border p-4  forum-bg-artical-details-bg-color">{formatDate(item?.date)}</Card>
                      <Card className="text-center border p-4  forum-bg-artical-details-bg-color">{item?.status === 0 ? <span style={{ color: "red", fontWeight: 600 }}>Open</span> : <span style={{ color: "green", fontWeight: 600 }}>Close</span>}</Card>
                    </Card>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  };

  // RenderComponent to handle different modules
  // const RenderComponent = ({ item }) => {

  //   if (loading) {
  //     return <p>Loading...</p>; // Display loading spinner or placeholder content
  //   }

  //   if (item?.moduleType !== undefined) {
  //     switch (item?.moduleType) {
  //       case 0:
  //         return <Article key={item?._id} item={item} />;
  //       case 1:
  //         return <ForumPost key={item?._id} item={item} />;
  //       case 2:
  //         return <EventPost key={item?._id} item={item} />;
  //       case 3:
  //         return <GalleryPost key={item?._id} item={item} />;
  //       case 4:
  //         return <AnnouncementPost key={item?._id} item={item} />;
  //       default:
  //         console.warn("Unknown moduleType:", item?.moduleType);
  //         return <p>No Data Found</p>;
  //     }
  //   } else {
  //     return <ReportNonReceiptItem item={item} />;
  //   }
  // };
  // const RenderComponent = ({ item, shouldAnimate }) => {
  //   console.log(shouldAnimate, 'shouldAnimate');

  //   // State to store style and whether the animation has been triggered
  //   const [style, setStyle] = useState({
  //     opacity: 0, // Start with opacity 0 (invisible)
  //     transform: "scale(0.8)", // Start smaller
  //   });

  //   const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has happened

  //   useEffect(() => {
  //     if (shouldAnimate && !hasAnimated) {
  //       // Delay the animation by 50ms to let the render settle
  //       const timer = setTimeout(() => {
  //         setStyle({
  //           opacity: 1,
  //           transform: "scale(1)", // End at normal size
  //           transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
  //         });
  //         setHasAnimated(true); // Mark that animation has happened
  //       }, 50);

  //       return () => clearTimeout(timer);
  //     } else if (!shouldAnimate) {
  //       // No animation, set to normal size and opacity instantly
  //       setStyle({
  //         opacity: 1,
  //         transform: "scale(1)",
  //         transition: "none", // No transition when no animation is required
  //       });
  //     }
  //   }, [shouldAnimate, item, hasAnimated]); // Trigger animation when `shouldAnimate` or `item` changes

  //   if (!item) return null;

  //   return (
  //     <div style={style}>
  //       {item?.moduleType === 0 && <Article item={item} />}
  //       {item?.moduleType === 1 && <ForumPost item={item} />}
  //       {item?.moduleType === 2 && <EventPost item={item} />}
  //       {item?.moduleType === 3 && <GalleryPost item={item} />}
  //       {item?.moduleType === 4 && <AnnouncementPost item={item} />}
  //       {item?.moduleType === undefined && <ReportNonReceiptItem item={item} />}
  //     </div>
  //   );
  // };
  const RenderComponent = ({ item }) => {
    if (!item) return null;

    return (
      <div>
        {item?.moduleType === 0 && <Article item={item} />}
        {item?.moduleType === 1 && <ForumPost item={item} />}
        {item?.moduleType === 2 && <EventPost item={item} />}
        {item?.moduleType === 3 && <GalleryPost item={item} />}
        {item?.moduleType === 4 && <AnnouncementPost item={item} />}
        {console.log(item?.moduleType)}
        {item?.moduleType === undefined && <ReportNonReceiptItem item={item} />}
      </div>
    );
  };


  // const RenderComponent = ({ item }) => {
  //   const [style, setStyle] = useState({
  //     opacity: 0,
  //     transform: "translateX(-100%)", // Start from the left (off-screen)
  //     transition: "transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.6s ease-out", // Elastic easing
  //   });

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       setStyle((prev) => ({
  //         ...prev,
  //         opacity: 1,
  //         transform: "translateX(0)", // Slide to normal position
  //       }));
  //     }, 100);

  //     return () => clearTimeout(timer);
  //   }, [item]);

  //   if (!item) return null;

  //   return (
  //     <div style={style}>
  //       {item?.moduleType === 0 && <Article item={item} />}
  //       {item?.moduleType === 1 && <ForumPost item={item} />}
  //       {item?.moduleType === 2 && <EventPost item={item} />}
  //       {item?.moduleType === 3 && <GalleryPost item={item} />}
  //       {item?.moduleType === 4 && <AnnouncementPost item={item} />}
  //       {item?.moduleType === undefined && <ReportNonReceiptItem item={item} />}
  //     </div>
  //   );
  // };

  //profile Image
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          console.log("width", img.width)
          if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
            setError("image", { message: "Image Size  Invalid" });
            return;
          }
          // Image is valid
          setSelectedImage(reader.result);
          clearErrors("image");
          setImageUpdate(file); // Store the actual file for uploading
          setImageKey((prevKey) => prevKey + 1);
          setShowUploadButton(true);
        };
      };
      reader.readAsDataURL(file);
    }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const img = new Image();
    //     const result=reader.result;
    //     console.log(img,'1402')
    //     console.log("Image width:", img.width);
    //     console.log("Image height:", img.height);
    //     // if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
    //     //   console.log("❌ Setting error for invalid image size"); // Debugging
    //     //   setError("image", {
    //     //     type: "manual",
    //     //     message: "Image dimensions must be exactly between 140x140 and 150x150 pixels.",
    //     //   });}
    //     clearErrors("imageUrl");
    //     setSelectedImage(reader.result); // Set preview image
    //     setImageUpdate(file); // Store the actual file for uploading
    //     setImageKey((prevKey) => prevKey + 1);
    //     setShowUploadButton(true);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };


  const handleIconClick = () => {
    document.getElementById("uploadImage").click();
  };

  //upload
  // const handleUpload = () => {
  //   if (!selectedImage) {
  //     console.error("No image selected");
  //     return;
  //   }
  //   const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,/;
  //   const imageUrl = base64Regex.test(selectedImage) ? selectedImage : "";
  //   if (!imageUrl) {
  //     console.error("Selected image is not a valid base64 image");
  //     return;
  //   }
  //   const requestIdAndData = {
  //     id: memberDetails._id,
  //     data: {
  //       imageUrl,
  //     },
  //   };
  //   updateMember(requestIdAndData).then((res) => {
  //     if (res.data.status) {
  //       getPendingMemberById(memberDetails._id).then((res) => {
  //         if (res?.data?.status) {
  //           dispatch(setMemberDetails(res?.data?.data));
  //           setSelectedImage(imageUrl);
  //           toast.success("Image Uploded Successfully!!!");
  //           setShowUploadButton(false);

  //         } else {
  //           toast.error("Image Uploded Error!!!");
  //         }
  //       });
  //     }
  //   });
  // };
  const handleUpload = () => {
    setOldImage(selectedImage);
    const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
    if (!imageUpdate && currentScope === "PORTAL_MEMBER") {
      console.error("No image selected");
      return;
    }

    // Logic for PORTAL_MEMBER scope
    if (currentScope === "PORTAL_MEMBER") {
      const formData = new FormData();
      formData.append("_id", memberDetails?._id);
      const uploadField = Number(memberDetails?.type) === 2
        ? "uploadInstitutionLogo"
        : Number(memberDetails?.type) === 3
          ? "uploadOrganisationLogo"
          : "imageUrl";

      formData.append(uploadField, imageUpdate);

      // Call the API to update member details
      updateMember({ id: memberDetails?._id, data: formData }).then((res) => {
        if (res?.data?.status) {
          getPendingMemberById(memberDetails?._id).then((res) => {
            if (res?.data?.status) {
              console.log("condtion", memberDetails?.type === '2', memberDetails?.type)
              if (memberDetails?.type === "2") {
                console.log("response 1462", memberDetails?.type, res?.data?.data?.uploadInstitutionLogo)
                dispatch(setImageUrl({ imageUrl: res?.data?.data?.uploadInstitutionLogo }))
              }
              else if (memberDetails?.type === "3") {
                dispatch(setImageUrl({ imageUrl: res?.data?.data?.uploadOrganisationLogo }))
              }
              else {
                dispatch(setImageUrl({ imageUrl: res?.data?.data?.imageUrl }))
              }
              dispatch(setMemberDetails(res?.data?.data));
              setSelectedImage(URL.createObjectURL(imageUpdate)); // Set the new image URL after a successful upload
              toast.success("Image Uploaded Successfully!!!");
              setShowUploadButton(false);
            } else {
              toast.error("Image Upload Error!!!");
              setSelectedImage(oldImage);
            }
          });
        }
        else {
          toast.error(res?.data?.message)
          setShowUploadButton(false);
          setSelectedImage(oldImage);


        }
      }).catch((error) => {
        console.error("Upload Error:", error);
        toast.error("An error occurred during the upload process.");
        setSelectedImage(oldImage);
      });
    }

    // Logic for PORTAL_SIG_ADMIN scope
    else if (currentScope === "PORTAL_SIG_ADMIN") {
      const sigUpdateData = {
        id: sigRoleUserDetails?.payload?.auth?.memberId,
        data: {
          imageUrl: base64Regex.test(selectedImage)
            ? selectedImage
            : selectedImage?.split("/").slice(-2).join("/"),
          role: "PORTAL_SIG_ADMIN",
          // roleId: sigRoleUserDetails?.payload?.auth?.roleId,
          adminId: sigRoleUserDetails?.payload?.auth?.memberId,
        },
      };
      updateSigProfile(sigUpdateData).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          setShowUploadButton(false);
          dispatch(setSigRoleDetails(res?.data?.data));
        } else {
          toast.error("SIG Profile Update Failed");
        }
      }).catch((err) => {
        console.error("SIG Profile Update Error:", err);
        toast.error("An error occurred while updating SIG profile.");
      });
    }

    // Logic for PORTAL_CHAPTER_ADMIN scope (Assuming you will implement it similarly)
    else if (currentScope === "PORTAL_CHAPTER_ADMIN") {
      getChapterRoleDetailsById(setChapterDetails?.payload?.auth?.roleId)
        .then((roleDetailsResponse) => {
          if (!roleDetailsResponse?.data?.data?.roleDetails) {
            toast.error("Failed to retrieve role details");
            return Promise.reject(new Error("Role details not found"));
          }

          const { name, address, mobile, email, designation, institution, role, salutation, pincode } = roleDetailsResponse.data.data.roleDetails;
          const updateData = {
            id: setChapterDetails?.payload?.auth?.chapterId,
            data: {
              imageUrl: base64Regex.test(selectedImage)
                ? selectedImage
                : selectedImage?.split("/")?.slice(-2).join("/") || "",
              // role: "PORTAL_CHAPTER_ADMIN",
              adminId: adminId,
              roleId: roleId,
              name, address, mobile, email, designation,
              institution, role, salutation, pincode
            },
          };

          return chapterImageUpdate(updateData);
        })
        // .then((updateResponse) => {
        //   try {
        //     console.log("Update Response:", updateResponse); // Debugging

        //     if (!updateResponse || !updateResponse.data || !updateResponse.data.status) {
        //       throw new Error("Invalid response from API");
        //     }


        //     toast.success(updateResponse.data.message);
        //     chapterRoleDetails(roleId).then((res) => {
        //       if (res?.data?.status) {
        //         console.log("response",res?.data?.data?.roleDetails?.roleImageUrl)
        //         dispatch(setRoleDetails(res?.data?.data?.roleDetails));
        //         dispatch(setImageUrl({imageUrl:res?.data?.data?.roleDetails?.roleImageUrl}))
        //       }
        //     })
        //     setShowUploadButton(false);
        //     // dispatch(setImageUrl({imageUrl:updateResponse?.data?.data?.roleDetails?.roleImageUrl}))

        //     // Ensure response data exists before dispatching
        //     if (!updateResponse.data.data) {
        //       throw new Error("Missing chapter details in response");
        //     }


        //   } catch (error) {
        //     console.error("Processing Error:", error);
        //     // toast.error("error");
        //   }
        // })
        .then((updateResponse) => {
          try {
            console.log("Update Response:", updateResponse); // Debugging

            if (!updateResponse?.data?.status) {
              throw new Error("Invalid response from API");
            }

            toast.success(updateResponse.data.message);

            const updatedRoleDetails = updateResponse?.data?.data?.updateAdminData?.imageUrl;
            console.log(updatedRoleDetails)
            if (!updatedRoleDetails) {
              throw new Error("Missing chapter details in response");
            }
            dispatch(setRoleDetails(updatedRoleDetails));
            dispatch(setImageUrl({ imageUrl: updatedRoleDetails }));
            setShowUploadButton(false);

          } catch (error) {
            console.error("Processing Error:", error);
            toast.error("Something went wrong!");
          }
        })
        .catch((error) => {
          console.error("Chapter Update Error:", error);
          toast.error("An error occurred while updating the chapter");
        });
    }
  };
  const getMemberImage = (memberDetails) => {
    if (!memberDetails) return null;
    if (memberDetails?.type === "2") {
      return memberDetails?.uploadInstitutionLogo; // For Institutions
    } else if (memberDetails?.type === "3") {
      return memberDetails?.uploadOrganisationLogo; // For Organisations
    } else {
      return memberDetails?.imageUrl || ''; // Default case (for type "0" and "1")
    }
  };

  // Helper function to get the member type label (Teacher, Student, etc.)
  const getMemberTypeLabel = (type) => {
    switch (type) {
      case "0":
        return "(Teacher)";
      case "1":
        return "(Student)";
      case "2":
        return "(Institution)";
      case "3":
        return "(Corporate)";
      case "4":
        return "(Teacher)";
      default:
        return "(Unknown Type)";
    }
  };
  useEffect(() => {
    const fetchPendingMemberDetails = async () => {
      try {
        const res = await getPendingMemberById(memberDetails?._id);
        if (res?.data?.status) {
          let imageUrl;
          if (memberDetails?.type === 3) {
            imageUrl = res?.data?.data?.uploadOrganisationLogo;
          } else if (memberDetails?.type === 2) {
            imageUrl = res?.data?.data?.uploadInstitutionLogo;
          } else {
            imageUrl = res?.data?.data?.imageUrl;
          }
          setSelectedImage(imageUrl);
          dispatch(setMemberDetails(res?.data?.data));
        }
      } catch (error) {
        console.error("Failed to fetch member details:", error);
      }
    };

    if (memberDetails?._id) {
      fetchPendingMemberDetails();
    }
  }, [dispatch, memberDetails?._id, getPendingMemberById, memberDetails?.type]);


  const handlingMobileProfileMember = () => {
    setOpenMobile(!openTabMobile)
    setOpenMemberTab(false)
  }
  const handlingMobileMember = () => {
    setOpenMemberTab(!openMemberTab)
    setOpenMobile(false)
  }
  return (
    <>
      <>
        <Box>
          {/* <TopHeader /> */}
          {/* <Box className="banner_text"></Box> */}
          {loading ? (
            <Spinner />
          ) : (
            <Container maxWidth="xl" className="view-member-container signika-font" >
              <div className="row p-0 ">
                <div className="col-12 d-none d-md-block">

                  <div className="row ">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 d-none d-lg-block">
                      <div className="d-flex justify-content-center mt-3  "  >
                        <h2 className=" border px-5 text-center  border-shape p-2 title-font" style={{ backgroundColor: '#4f8058', color: '#fff' }}>Your Wall</h2>
                      </div>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-md-end">
                      <div className="form-group mt-4 content-font">
                        <select
                          id="modulesDropdown"
                          className="form-select w-100 modulesDropdown"
                          value={selectedType}
                          onChange={handleTypesChange}
                        >
                          <option value="">All</option>
                          {Modules.map((module) => (
                            <option key={module?.id} value={module?.id}>
                              {module?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-block d-lg-none">
                  <div className="d-flex justify-content-between my-3"  >
                    <button className=" btn btn-primary Member-profile-Details-button px-3" onClick={handlingMobileProfileMember}>Profile Details</button>
                    <p className=" border  text-center  border-shape px-4 px-2 mobile-wall-post" style={{ backgroundColor: '#4f8058', color: '#fff' }}>Your Wall</p>
                    <button variant="contained" className="btn btn-primary Member-profile-Details-button px-3" onClick={handlingMobileMember}>{currentScope === "PORTAL_MEMBER" ? "Membership Details" : "Event Details"}</button>
                  </div>
                </div>
                <div className="col-12 d-block d-md-none">
                  <div className="d-flex justify-content-center mb-3">
                    <div className="form-group">
                      <select
                        id="modulesDropdown"
                        className="form-select modulesDropdown"
                        value={selectedType}
                        onChange={handleTypesChange}
                      >
                        <option value="">--Choose --</option>
                        {Modules.map((module) => (
                          <option key={module?.id} value={module?.id}>
                            {module?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {openTabMobile ? (
                  <div className="row">
                    <div className="col-md-4 d-block d-lg-none  member-details--mobileResponsive">
                      <Card className="profile-container card w-100 ps-0">
                        <div className="position-relative member-log-container">
                          {/* <img src={profileBg} alt="profile-bg" id="profileImage" className="w-100" />
                          <div className=" position-absolute translate-middle member-logo">
                            {selectedImage ? (
                              <Avatar
                                key={imageKey} // Ensure the imageKey is used to force a re-render when the image changes
                                alt="Member Logo"
                                className="member-avatar"
                                src={selectedImage} // Prioritize selectedImage first for avatar
                                sx={{ width: 100, height: 100 }}
                              />
                            ) : (
                              <Avatar
                                key={imageKey}
                                alt="Member Logo"
                                className="member-avatar"
                                src={
                                  memberDetails?.type === "0"
                                    ? memberDetails?.imageUrl
                                    : memberDetails?.type === "1"
                                      ? memberDetails?.imageUrl
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.uploadInstitutionLogo
                                        : memberDetails?.uploadOrganisationLogo
                                }
                                sx={{ width: 100, height: 100 }}
                              />
                            )}

                            <input
                              accept="image/jpeg, image/png"
                              style={{ display: "none", position: 'relative' }}
                              id="uploadImage"
                              type="file"
                              onChange={handleImageChange} // This will update selectedImage
                            />

                            {!showUploadButton && (
                              <CameraAltIcon
                                style={{
                                  width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '50%', color: "white"
                                }}
                                onClick={handleIconClick} // Handle image upload icon click
                              />
                            )}

                            {showUploadButton && (
                              <Button
                                variant="contained"
                                style={{ marginTop: "20px" }}
                                onClick={handleUpload} // This triggers the upload process
                              >
                                Upload
                              </Button>
                            )}

                            <div className="position-absolute member-details member-name-color ">
                              <div className="row">
                                <h5 className="text-center fw-bold member-name-color ">
                                  {memberDetails?.type === "0"
                                    ? memberDetails?.fullName
                                    : memberDetails?.type === "1"
                                      ? memberDetails?.fullName
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.institution
                                        : memberDetails?.organisationName}
                                </h5>
                                <p className="text-center member-name-color p-0">
                                  {memberDetails?.type === "0"
                                    ? "(Teacher)"
                                    : memberDetails?.type === "1"
                                      ? "(Student)"
                                      : memberDetails?.type === "2"
                                        ? "(Institution)"
                                        : "(Corporate)"}
                                </p>
                                <p style={{ textAlign: "center", fontSize: '12px' }} />
                                <p className="text-center ">
                                  {memberDetails?.userId}
                                </p>
                              </div>
                            </div>
                          </div> */}
                          <div className="membership-profile">
                            <div className="position-absolute translate-middle member-logo">
                              {/* Avatar: Show selectedImage first, fallback to memberDetails */}
                              <input
                                id="uploadImage"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleImageChange} // Make sure this is correctly assigned
                              />
                              <Avatar
                                key={imageKey} // Use imageKey to force a re-render
                                alt="Member Logo"
                                className="member-avatar"
                                src={
                                  selectedImage ||
                                  (currentScope === "PORTAL_MEMBER" ? getMemberImage(memberDetails)
                                    : currentScope === "PORTAL_SIG_ADMIN"
                                      ? sigDetails?.imageUrl
                                      : chapterDetails?.roleDetails?.roleImageUrl)
                                }
                                sx={{ width: 100, height: 100 }}
                              />

                              {/* Camera icon, visible only if not uploading */}
                              {!showUploadButton && (
                                <CameraAltIcon
                                  style={{
                                    width: "50px", height: '50px', cursor: "pointer", position: 'absolute', top: "70%", left: '70%', color: "black", backgroundColor: '#d3d3d3', padding: "5px", borderRadius: "50%"
                                  }}
                                  onClick={handleIconClick} // Handle image upload icon click
                                />
                              )}
                              <ErrorIcon
                                style={{
                                  width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '90%', color: "black", backgroundColor: '#d3d3d3', padding: "5px", borderRadius: "50%"
                                }}

                              />

                              {/* Upload button, visible only if image is selected */}
                              {showUploadButton && (
                                <Button
                                  variant="contained"
                                  style={{ marginTop: "20px" }}
                                  className='menus-color'
                                  onClick={handleUpload} // Trigger upload process
                                >
                                  Upload
                                </Button>
                              )}
                              <div>
                                {!!errors?.imageUrl && <div style={{ color: "red", marginTop: "10px" }}>{errors?.imageUrl?.message} </div>}
                              </div>

                              {/* Member details (Name and Type) */}
                              <div className="position-absolute member-details member-name-color">
                                <div className="row">
                                  <h5 className="text-center fw-bold member-name-color pt-3">
                                    {
                                      memberDetails?.type === "0" || memberDetails?.type === "1" || memberDetails?.type === "4"
                                        ? `${getSalutation(memberDetails?.salutation)}.${memberDetails?.fullName}`
                                        : memberDetails?.type === "3"
                                          ? memberDetails?.organisationName
                                          : memberDetails?.type === "2"
                                            ? memberDetails?.institution
                                            : sigDetails?.roleDetails?.name ? sigDetails?.roleDetails?.name : `${getSalutation(chapterDetails?.salutation)} ${chapterDetails?.roleDetails?.name}`
                                    }
                                  </h5>
                                  <p className="text-center member-name-color">
                                    {currentScope === "PORTAL_MEMBER" ? getMemberTypeLabel(memberDetails?.type) : currentScope === "PORTAL_SIG_ADMIN" ? "SIG Admin" : "Chapter Admin"}
                                  </p>
                                  <p className="text-center ">
                                    {memberDetails?.userId}
                                  </p>
                                  <p style={{ textAlign: "center", fontSize: '12px' }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="member-details-personal-container">
                          <div >
                            <div class="d-flex member-details-personal-container h-auto">
                              <div className="row">
                                <div>
                                  {/* Email Section */}
                                  <p className="pt-3">
                                    <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                      <EmailRoundedIcon style={{ fontSize: '18px' }} />
                                    </span>
                                    <span className="ms-3">
                                      {currentScope === "PORTAL_MEMBER" ? memberDetails?.email :
                                        currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.email :
                                          chapterDetails?.roleDetails?.email}
                                    </span>
                                  </p>

                                  {/* Phone Section (Hidden if mobile is unavailable) */}
                                  {currentScope === "PORTAL_MEMBER" && memberDetails?.mobileNumber ? (
                                    <p className="mt-3">
                                      <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                        <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                      </span>
                                      <span className="ms-3">{memberDetails?.mobileNumber}</span>
                                    </p>
                                  ) : currentScope === "PORTAL_SIG_ADMIN" && sigDetails?.roleDetails?.mobile ? (
                                    <p className="mt-3">
                                      <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                        <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                      </span>
                                      <span className="ms-3">{sigDetails?.roleDetails?.mobile}</span>
                                    </p>
                                  ) : currentScope === "PORTAL_CHAPTER_ADMIN" && chapterDetails?.roleDetails?.mobile ? (
                                    <p className="mt-3">
                                      <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                        <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                      </span>
                                      <span className="ms-3">{chapterDetails?.roleDetails?.mobile}</span>
                                    </p>
                                  ) : null}

                                  {/* Location Section (Hidden if address is unavailable) */}
                                  {(currentScope === "PORTAL_MEMBER" && memberDetails?.address) ||
                                    (currentScope === "PORTAL_SIG_ADMIN" && sigDetails?.roleDetails?.address) ||
                                    (currentScope === "PORTAL_CHAPTER_ADMIN" && chapterDetails?.roleDetails?.address) ? (
                                    <p>
                                      <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                        <LocationOnIcon style={{ fontSize: '18px' }} />
                                      </span>
                                      <div style={{ marginLeft: '45px', marginTop: '-30px' }}>
                                        <p className="ms-3 m-0 d-block">
                                          {currentScope === "PORTAL_MEMBER" ? memberDetails?.address :
                                            currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.address :
                                              chapterDetails?.roleDetails?.address}
                                        </p>
                                        <p className="ms-3 m-0 d-block">{memberDetails?.city}</p>
                                        <p className="ms-3 m-0 d-block">
                                          {currentScope === "PORTAL_MEMBER" ? memberDetails?.roleDetails?.pincode :
                                            currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.pincode :
                                              chapterDetails?.roleDetails?.pincode}
                                        </p>
                                      </div>
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center member-details-personal-container  mt-5 mb-2">
                            {currentScope === "PORTAL_MEMBER" ? <hr className="w-75 member-details-personal-container"></hr> : <></>}
                          </div>
                          <div className="  member-details-personal-container  ">
                            {currentScope === "PORTAL_MEMBER" ? <div className="text-center"><span className="ms-3  mb-5" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "5px", borderRadius: "50%", marginLeft: "-5px" }} ><StarBorderIcon /></span></div> : <></>}
                            {currentScope === "PORTAL_MEMBER" ?
                              <Container>
                                <h6 className="mb-3 mt-3 text-justify  ">
                                  {/* {getQualificationLabel(memberDetails?.studying)} */}
                                  {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                                    getCurrentlyyTeaching(memberDetails?.teaching)
                                  ) : memberDetails?.type === "1" ? (
                                    getQualificationLabel(memberDetails?.studying)
                                  ) : memberDetails?.type === "2" ? (
                                    getTypeOfEducation(memberDetails?.levelOfInstitution)
                                  ) : (
                                    getOranginication(memberDetails?.industry)
                                  )}

                                </h6>
                                <h6 className="mb-3 mt-3 text-justify  ">

                                  {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                                    memberDetails?.designation
                                  ) : memberDetails?.type === "1" ? (
                                    <> {memberDetails?.course}</>
                                  ) : memberDetails?.type === "2" ? (
                                    getTypeOfInstitution(memberDetails?.typeOfInstitution)
                                  ) : (
                                    getTypeOfInstitution(memberDetails?.typeOfOrganisation)
                                  )}

                                </h6>
                                <h6 className="mb-3 mt-3 text-justify ">
                                  {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                                    <>
                                      <p>{memberDetails?.institution}</p>
                                      <p>{getPrimaryTeachingTeaching(memberDetails?.primarilyTeachingLevel)}</p>
                                      <p>{getTypeOfInstitution(memberDetails?.natureWork)}</p>
                                    </>

                                  ) : memberDetails?.type === "1" ? (
                                    <> <p>{memberDetails?.institution}</p>
                                      <p>{memberDetails?.institutionCity}</p>
                                      <p> {memberDetails?.institutionState}</p>
                                    </>
                                  ) : memberDetails?.type === "2" ? (
                                    <><p className="fw-bold">Teacher Count:<span className="fw-light ml-2">{memberDetails?.teachersInInstitution}</span></p>
                                      <p className="fw-bold"> Student Count:<span className="fw-light ml-2">{memberDetails?.studentsInInstitution}</span></p>
                                      <p className="fw-bold">Website:<span className="fw-light ml-2">{memberDetails?.websiteAddressInstitution}</span></p>
                                      <p className="fw-bold">  Established Year:<span className="fw-light ml-2">{formatDate(memberDetails?.institutionEstablished)}</span></p>
                                    </>
                                  ) : (
                                    <><p className="fw-bold"> Employees Count  :<span className="fw-light ml-2">{memberDetails?.employees}</span></p><br />
                                      <p className="fw-bold">Websites:<span className="fw-light ml-2">{memberDetails?.websiteAddressOrganisation}</span></p>
                                      <p className="fw-bold"> Located:<span className="fw-light ml-2">{memberDetails?.headOfficeLocated}</span></p>
                                      <p className="fw-bold">  Established  Year:<span className="fw-light ml-2">{formatDate(memberDetails?.organisationEstablished)}</span></p>
                                    </>
                                  )}
                                </h6>
                              </Container>
                              : <></>
                            }

                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                  </div>
                ) : (<></>)}
                {openMemberTab ? (
                  <>
                    <div className="row p-0 membership-mobileResponsive">
                      <div className="col-md-4"></div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4 p-0 mb-5 d-block d-lg-none">
                        {
                          planDetails && planDetails?.map((plan, i) => {
                            return (<>
                              <Card className="membershipDetails-card mt-2">
                                <CardContent sx={{ textAlign: "center", marginBottom: '1px' }}>
                                  {
                                    planDetails && planDetails?.map((plan, i) => {
                                      return (<>
                                        <div className="border mt-2  p-2 member-ship-plan-details" style={{}}>
                                          <Typography className=" fw-bold mt-2  memberShip-name">
                                            {/* {membershipDetails && membershipDetails?.planName} */}
                                            Member Since
                                          </Typography>
                                          <p className="text-center m-0 " style={{ fontSize: '15px' }}>{planTypeShow(membershipDetails?.planName)}</p>
                                          <p className="text-center m-0 chapter-border-font-color">{formatDate(plan?.dummyStartDate)}</p>
                                          <div className="fw-bold" style={{ fontSize: '12px' }}>
                                            <Typography >
                                              <span className=" fw-bold" style={{ fontSize: '12px' }}>
                                                {" "}
                                                Expiry On:
                                              </span>{" "}

                                              <p className="text-center">{formatDate(plan?.dummyEndDate)}</p>
                                            </Typography>
                                            <p className="renew-membership" >Renew MemberShip</p>
                                          </div>
                                        </div>
                                      </>)
                                    })
                                  }

                                  <div
                                    className="border mt-4 px-2 mb-2 pt-3 report-non-member "
                                    style={{
                                      backgroundColor: '#f39ea6',
                                      width: '100%',
                                      fontSize: '12px',
                                      // borderRadius: '9px',
                                    }}
                                  >
                                    <p className="fw-bold">Report Non Receipt of Journal Copy</p>
                                    <div className="d-flex justify-content-center">
                                      <Tooltip title='View Non Receipt Reports' placement="top">
                                        <VisibilityIcon className="cursor-pointer" onClick={report ? handleReport : undefined} />
                                      </Tooltip>
                                      <Tooltip title="Add Non Receipt Reports" placement="top">
                                        <AddBoxIcon className="cursor-pointer" onClick={handleAddReport} />
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <Modal open={openModal} onClose={handleClose}>
                                    <Box
                                      className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        backgroundColor: 'white',
                                        boxShadow: 24,
                                        padding: "20px",
                                        outline: 'none',
                                      }}
                                    >
                                      <Typography id="modal-modal-title" style={{ color: "#611f1e" }} variant="h6" component="h2">
                                        Add Report Non-Receipt
                                      </Typography>
                                      <TextField
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        label={
                                          <Typography className="text-dark">
                                            Date  <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        variant="outlined"
                                        type="date"
                                        value={watch("date") ? watch("date").split("T")[0] : ""}
                                        {...register("date", {
                                          required: "Please Select a Date ", max: {
                                            value: new Date().toISOString().split('T')[0],
                                            message: 'Future date is not allowed'
                                          }
                                        })}
                                        error={!!errors?.date}
                                        helperText={errors?.date?.message}
                                      />
                                      <TextField
                                        // InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="monthOfJournal"
                                        label={
                                          <Typography className="text-dark">
                                            Month of Journal <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        variant="outlined"
                                        placeholder="Month of Journal"
                                        {...register("monthOfJournal", {
                                          required: "Month of Journal is required",
                                          minLength: { value: 3, message: "monthOfJournal must be at least 3 characters long" },
                                        })}
                                        error={!!errors?.monthOfJournal}
                                        helperText={errors?.monthOfJournal?.message}
                                      />
                                      <TextField
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        id="description"
                                        placeholder="Description"
                                        label={
                                          <Typography className="text-dark">
                                            Description <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        variant="outlined"
                                        {...register("description", {
                                          required: "Description is required",
                                          minLength: { value: 3, message: "Description must be at least 3 characters long" },
                                        })}
                                        error={!!errors?.description}
                                        helperText={errors?.description?.message}
                                      />
                                      <div>
                                        <Box display="flex" justifyContent="center" width="100%">
                                          <Divider className="black-line-for-modal mb-2" sx={{ width: "100%" }} />
                                        </Box>
                                        <Button
                                          variant="o"
                                          style={{
                                            backgroundColor: "#dc3545",
                                            color: "white",
                                            padding: "5px 15px",
                                            // borderRadius: "4px",
                                          }}
                                          onClick={handleClose}
                                        >
                                          No
                                        </Button>
                                        <Button
                                          className="mx-2"
                                          variant="contained"
                                          style={{

                                            backgroundColor: "#2e7d32",
                                            color: "white",
                                            padding: "5px 15px #2e7d32",
                                            // borderRadius: "4px",
                                          }}
                                          onClick={handleSubmit(handleSaveNonReceipt)}
                                        >
                                          Yes
                                        </Button>
                                      </div>
                                    </Box>
                                  </Modal>
                                  <div className="text-white">
                                  </div>
                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <SearchIcon
                                      style={{
                                        position: 'absolute',
                                        left: '85%',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none',
                                        color: 'grey',
                                      }}
                                    />
                                    <input type="text"
                                      className="customTextField"
                                      style={{}}
                                      fullWidth
                                      placeholder="Search Member"
                                      onChange={(e) => handleGetAllSearch(e)}
                                    />
                                  </div>
                                  {filterSearch && filterSearch?.length > 0 ? (
                                    <ul className="list-group mt-2" style={{ position: 'absolute', zIndex: 10 }}>
                                      {filterSearch.map((item, i) => (
                                        <li
                                          key={i}
                                          className="list-group-item"
                                          onClick={(e) => showUserCard(item)}
                                          style={{ position: 'relative', zIndex: 20 }} // Higher z-index for list items
                                        >
                                          {item?.type === "0" || item?.type === "1" || item?.type === "4"
                                            ? item?.fullName
                                            : item?.type === "3"
                                              ? item?.organisationName
                                              : item?.institution}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : null}
                                  {currentScope === "PORTAL_MEMBER" ? <>
                                    <div
                                      className="card mt-3 position-sticky"
                                      style={{ backgroundColor: '#d9d9d9', zIndex: 5, top: 0, position: 'sticky' }}
                                    >
                                      <h5 className="mt-3">Your Chapter</h5>
                                      <p className="chapter-border-font-color">{chapter ? chapter : "---"}</p>
                                    </div>

                                    <hr style={{ height: "1px", color: "#333", backgroundColor: "#333" }} />

                                    <div className="card mt-3" style={{ backgroundColor: '#d9d9d9', position: 'relative', zIndex: 5 }}>
                                      <h5 className="mt-3">Your SIG</h5>
                                      <p className="chapter-border-font-color">{sig.length > 0 ? sig : "---"}</p>
                                    </div>

                                    <hr style={{ height: "1px", color: "#333", backgroundColor: "#333" }} />
                                  </> :
                                    <></>}



                                  {/* <h5 className="mb-2 member-name-color">Library Resource</h5> */}
                                  {/* <a
                                  href="https://journals.eltai.in/index.php/jelt/index"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block"
                                > */}
                                  <h5 className=" member-name-color">JELT</h5>
                                  {/* </a> */}
                                  {/* <a
                                  href="https://journals.eltai.in/index.php/jtrel"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block"
                                > */}
                                  <h5 className=" member-name-color">JTREL</h5>
                                  {/* </a> */}
                                  {/* <a
                                  href="https://journals.eltai.in/index.php/jtelt"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block"
                                > */}
                                  <h5 className="mb-2 member-name-color">JTELT</h5>
                                  {/* </a> */}
                                  {/* <h5 className="mb-2 member-name-color">ELT Practiontor</h5>
                              <h5 className="mb-2 member-name-color">News Letter</h5> */}
                                  <h5 className="mb-2 member-name-color"><Link to="/India-Reads">India Reads</Link></h5>
                                  <h5 className="mb-2 member-name-color"><Link to="/view-Gallery">Galleries</Link></h5>
                                </CardContent>
                              </Card>
                            </>)
                          })
                        }

                        {currentScope === "PORTAL_CHAPTER_ADMIN" || currentScope === "PORTAL_SIG_ADMIN" ?
                          <Card className="membershipDetails-card ">
                            <CardContent sx={{ textAlign: "center", marginBottom: '1px' }}>
                              <p className="text-center cursor-pointer mt-3 upcoming-event" onClick={() => handleUpcomingClick(1)}>Upcoming Event</p>
                              <p className="text-center cursor-pointer upcoming-event" onClick={() => handleUpcomingClick(0)}>Past Event</p>
                            </CardContent>
                          </Card>
                          : <></>
                        }
                      </div>
                    </div>
                  </>
                ) : (<></>)}
                <div className="col-lg-2 d-none d-lg-block  p-0">
                  <Card className="profile-container card w-100 ps-0">
                    <div className="position-relative member-log-container">
                      {/* <img src={profileBg} alt="profile-bg" id="profileImage" className="w-100" /> */}
                      <div className="membership-profile">
                        <div className="position-absolute translate-middle member-logo">
                          {/* Avatar: Show selectedImage first, fallback to memberDetails */}
                          <input
                            id="uploadImage"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleImageChange} // Make sure this is correctly assigned
                          />
                          <Avatar
                            key={imageKey} // Use imageKey to force a re-render
                            alt="Member Logo"
                            className="member-avatar"
                            src={
                              selectedImage ||
                              (currentScope === "PORTAL_MEMBER" ? getMemberImage(memberDetails)
                                : currentScope === "PORTAL_SIG_ADMIN"
                                  ? sigDetails?.imageUrl
                                  : chapterDetails?.roleDetails?.roleImageUrl
                              )
                            }
                            sx={{ width: 100, height: 100 }}
                          />
                          {/* Camera icon, visible only if not uploading */}
                          {!showUploadButton && (
                            <CameraAltIcon
                              style={{
                                width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '70%', color: "black", backgroundColor: '#d3d3d3', padding: "5px", borderRadius: "50%"
                              }}
                              onClick={handleIconClick} // Handle image upload icon click
                            />
                          )}

                          <Tooltip title="Image dimensions must be between 140x140 and 150x150 pixels   Image must be .jpg,jpeg,.png,.jfif">
                            <ErrorIcon
                              style={{
                                width: "20%", cursor: "pointer", position: 'absolute', top: "70%", left: '95%', color: "black", backgroundColor: '#d3d3d3', padding: "5px", borderRadius: "50%"
                              }}

                            />
                          </Tooltip>


                          {/* Upload button, visible only if image is selected */}
                          {showUploadButton && (
                            <Button
                              variant="contained"
                              style={{ marginTop: "20px" }}
                              className='menus-color'
                              onClick={handleUpload} // Trigger upload process
                            >
                              Upload
                            </Button>
                          )}
                          {console.log(errors)}

                          {/* Member details (Name and Type) */}
                          <div className="position-absolute member-details member-name-color">
                            <div className="row">
                              <h5 className="text-center fw-bold member-name-color pt-3">
                                {
                                  memberDetails?.type === "0" || memberDetails?.type === "1" || memberDetails?.type === "4"
                                    ? `${getSalutation(memberDetails?.salutation)}.${memberDetails?.fullName}`
                                    : memberDetails?.type === "3"
                                      ? memberDetails?.organisationName
                                      : memberDetails?.type === "2"
                                        ? memberDetails?.institution
                                        : sigDetails?.roleDetails?.name ? sigDetails?.roleDetails?.name : `${getSalutation(chapterDetails?.roleDetails.salutation)}.${chapterDetails?.roleDetails?.name}`
                                }
                              </h5>
                              <p className="text-center member-name-color">
                                {currentScope === "PORTAL_MEMBER" ? getMemberTypeLabel(memberDetails?.type) : currentScope === "PORTAL_SIG_ADMIN" ? "SIG Admin" : "Chapter Admin"}
                              </p>
                              <p className="text-center ">
                                {memberDetails?.userId}
                              </p>
                              <p style={{ textAlign: "center", fontSize: '12px' }} />
                            </div>
                          </div>
                        </div>
                        {errors?.image && (
                          <FormHelperText className="pt-3 image-error-membership-wall" error={!!errors?.image}>{errors?.image?.message}</FormHelperText>
                        )}
                      </div>
                    </div>
                    <div className="member-details-personal-container">
                      <div >
                        <div class="d-flex member-details-personal-container h-auto">
                          {/* <div className="row">
                            <div >
                              <p class="pt-3"><span className="ms-3 " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><EmailRoundedIcon style={{ fontSize: '18px' }} /></span>
                                <span span className="ms-3">{currentScope === "PORTAL_MEMBER" ? memberDetails?.email : currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.email : chapterDetails?.roleDetails?.email}</span>
                              </p>
                              <p className="mt-3"><span className="ms-3 " style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px", }} ><PhoneRoundedIcon style={{ fontSize: '18px' }} /></span>
                                <span span className="ms-3">{currentScope === "PORTAL_MEMBER" ? memberDetails?.mobileNumber : currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.mobile : chapterDetails?.roleDetails?.mobile}</span>
                              </p>
                              <p><span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }} ><LocationOnIcon style={{ fontSize: '18px' }} /></span>
                                <div style={{ marginLeft: '45px', marginTop: '-30px' }}>
                                  <p span className="ms-3 m-0 d-block">{currentScope === "PORTAL_MEMBER" ? memberDetails?.address : currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.address : chapterDetails?.roleDetails?.address}</p>
                                  <p span className="ms-3 m-0 d-block">{memberDetails?.city}</p>
                                  <p span className="ms-3 m-0 d-block">{currentScope === "PORTAL_MEMBER" ? memberDetails?.roleDetails?.pincode : currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.pincode : chapterDetails?.roleDetails?.pincode}</p>
                                </div>
                              </p>
                            </div>
                          </div> */}
                          <div className="row">
                            <div>
                              {/* Email Section */}
                              <p className="pt-3">
                                <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                  <EmailRoundedIcon style={{ fontSize: '18px' }} />
                                </span>
                                <span className="ms-3">
                                  {currentScope === "PORTAL_MEMBER" ? memberDetails?.email :
                                    currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.email :
                                      chapterDetails?.roleDetails?.email}
                                </span>
                              </p>

                              {/* Phone Section (Hidden if mobile is unavailable) */}
                              {currentScope === "PORTAL_MEMBER" && memberDetails?.mobileNumber ? (
                                <p className="mt-3">
                                  <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                    <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                  </span>
                                  <span className="ms-3">{memberDetails?.mobileNumber}</span>
                                </p>
                              ) : currentScope === "PORTAL_SIG_ADMIN" && sigDetails?.roleDetails?.mobile ? (
                                <p className="mt-3">
                                  <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                    <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                  </span>
                                  <span className="ms-3">{sigDetails?.roleDetails?.mobile}</span>
                                </p>
                              ) : currentScope === "PORTAL_CHAPTER_ADMIN" && chapterDetails?.roleDetails?.mobile ? (
                                <p className="mt-3">
                                  <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                    <PhoneRoundedIcon style={{ fontSize: '18px' }} />
                                  </span>
                                  <span className="ms-3">{chapterDetails?.roleDetails?.mobile}</span>
                                </p>
                              ) : null}

                              {/* Location Section (Hidden if address is unavailable) */}
                              {(currentScope === "PORTAL_MEMBER" && memberDetails?.address) ||
                                (currentScope === "PORTAL_SIG_ADMIN" && sigDetails?.roleDetails?.address) ||
                                (currentScope === "PORTAL_CHAPTER_ADMIN" && chapterDetails?.roleDetails?.address) ? (
                                <p>
                                  <span className="ms-3" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "4px", borderRadius: "50%", marginLeft: "-5px" }}>
                                    <LocationOnIcon style={{ fontSize: '18px' }} />
                                  </span>
                                  <div style={{ marginLeft: '45px', marginTop: '-30px' }}>
                                    <p className="ms-3 m-0 d-block">
                                      {currentScope === "PORTAL_MEMBER" ? memberDetails?.address :
                                        currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.address :
                                          chapterDetails?.roleDetails?.address}
                                    </p>
                                    <p className="ms-3 m-0 d-block">{memberDetails?.city}</p>
                                    <p className="ms-3 m-0 d-block">
                                      {currentScope === "PORTAL_MEMBER" ? memberDetails?.roleDetails?.pincode :
                                        currentScope === "PORTAL_SIG_ADMIN" ? sigDetails?.roleDetails?.pincode :
                                          chapterDetails?.roleDetails?.pincode}
                                    </p>
                                  </div>
                                </p>
                              ) : null}
                            </div>
                          </div>


                        </div>
                      </div>
                      <div className="d-flex justify-content-center member-details-personal-container  mt-5 mb-2">
                        {currentScope === "PORTAL_MEMBER" ? <hr className="w-75 member-details-personal-container"></hr> : <></>}
                      </div>
                      <div className="  member-details-personal-container  ">
                        {currentScope === "PORTAL_MEMBER" ? <div className="text-center"><span className="ms-3  mb-5" style={{ color: '#5c815c', border: '1px solid #5c815c', padding: "5px", borderRadius: "50%", marginLeft: "-5px" }} ><StarBorderIcon /></span></div> : <></>}
                        <Container>
                          <h6 className="mb-3 mt-3 text-justify  ">
                            {/* {getQualificationLabel(memberDetails?.studying)} */}
                            {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                              getCurrentlyyTeaching(memberDetails?.teaching)
                            ) : memberDetails?.type === "1" ? (
                              getQualificationLabel(memberDetails?.studying)
                            ) : memberDetails?.type === "2" ? (
                              getTypeOfEducation(memberDetails?.levelOfInstitution)
                            ) : (
                              getOranginication(memberDetails?.industry)
                            )}

                          </h6>
                          <h6 className="mb-3 mt-3 text-justify t">

                            {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                              memberDetails?.designation
                            ) : memberDetails?.type === "1" ? (
                              <> {memberDetails?.course}</>
                            ) : memberDetails?.type === "2" ? (
                              getTypeOfInstitution(memberDetails?.typeOfInstitution)
                            ) : (
                              getindustries(memberDetails?.typeOfOrganisation)
                            )}
                          </h6>
                          <h6 className="mb-3 mt-3 text-justify ">
                            {memberDetails?.type === "0" || memberDetails?.type === "4" ? (
                              <>
                                <p>{memberDetails?.institution}</p>
                                <p>{getPrimaryTeachingTeaching(memberDetails?.primarilyTeachingLevel)}</p>
                                <p>{getTypeOfInstitution(memberDetails?.natureWork)}</p>
                              </>

                            ) : memberDetails?.type === "1" ? (
                              <> <p>{memberDetails?.institution}</p>
                                <p>{memberDetails?.institutionCity}</p>
                                <p> {memberDetails?.institutionState}</p>
                              </>
                            ) : memberDetails?.type === "2" ? (
                              <><p className="fw-bold">Teacher Count:<span className="fw-light member-details-count">{memberDetails?.teachersInInstitution}</span></p>
                                <p className="fw-bold"> Student Count:<span className="fw-light member-details-count">{memberDetails?.studentsInInstitution}</span></p>
                                <p className="fw-bold">Website:<span className="fw-light member-details-count">{memberDetails?.websiteAddressInstitution}</span></p>
                                <p className="fw-bold " style={{ fontSize: "14px" }}>  Established Year:<span className="fw-light member-details-count">{formatDate(memberDetails?.institutionEstablished)}</span></p>
                              </>
                            ) : memberDetails?.type === "3" ? (
                              <><p className="fw-bold"> Employees Count :<span className="fw-light member-details-count">{memberDetails?.employees}</span></p>
                                <p className="fw-bold">Website:<span className="fw-light member-details-count">{memberDetails?.websiteAddressOrganisation}</span></p>
                                <p className="fw-bold"> Located:<span className="fw-light member-details-count">{memberDetails?.headOfficeLocated}</span></p>
                                <p className="fw-bold" style={{ fontSize: "14px" }}>  Established  Year:<span className="fw-light member-details-count">{formatDate(memberDetails?.organisationEstablished)
                                }</span></p>
                              </>
                            ) : <></>}
                          </h6>
                        </Container>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-lg-8 ">
                  <div className="member-customScroll z-3">
                    {allmodules && allmodules.length > 0 ? (
                      shouldAnimate ? (
                        <>
                          <Skeleten />
                          <Skeleten />
                        </>
                      ) : (
                        allmodules.map((module) => (
                          <RenderComponent key={module._id} item={module} />
                        ))
                      )
                    ) : (
                      <div className="no-data-container">
                        <div>
                          {noData && (
                            <>
                              <ErrorOutlineIcon style={{ fontSize: 50, color: 'red' }} />
                              <h3 className="text-danger">{noData}</h3>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {memberLoading && (
                      <div className="d-flex justify-content-center">
                        <CircularProgress style={{ color: '#44733b' }} />
                      </div>
                    )}
                  </div>


                </div>
                <div
                  className="col-lg-2 d-none d-lg-block "
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    {
                      planDetails?.length > 0 ? <div className="border membership-title">
                        <p className="text-center mb-0 w-100 border-0" id="membership-details-title" style={{ padding: "6px", textWrap: "nowrap" }}>{currentScope === "PORTAL_MEMBER" ? "Membership Details" : "Event Details"}</p>
                      </div> : <></>
                    }
                    <Card className="membershipDetails-card mt-2">
                      <CardContent sx={{ textAlign: "center", marginBottom: '1px' }}>
                        {
                          planDetails && planDetails?.map((plan, i) => {
                            return (<>
                              <div className="border mt-2  p-2 member-ship-plan-details" style={{}}>
                                <Typography className=" fw-bold mt-2  memberShip-name">
                                  Member Since
                                </Typography>
                                <p className="text-center m-0 " style={{ fontSize: '15px' }}>{planTypeShow(membershipDetails?.planName)}</p>
                                <p className="text-center m-0 chapter-border-font-color">{formatDate(plan?.dummyStartDate)}</p>
                                <div className="fw-bold" style={{ fontSize: '12px' }}>
                                  <Typography >
                                    <span className=" fw-bold" style={{ fontSize: '12px' }}>
                                      {" "}
                                      Expiry On:
                                    </span>{" "}

                                    <p className="text-center">{formatDate(plan?.dummyEndDate)}</p>
                                  </Typography>
                                  <p className="renew-membership" >Renew MemberShip</p>
                                </div>
                              </div>
                            </>)
                          })
                        }

                        {currentScope === "PORTAL_MEMBER" ?
                          <div
                            className="border mt-4 px-2 mb-2 pt-3 report-non-member "
                            style={{
                              backgroundColor: '#f39ea6',
                              width: '100%',
                              fontSize: '12px',
                              // borderRadius: '9px',
                            }}
                          >
                            <p className="fw-bold">Report Non Receipt of Journal Copy</p>
                            <div className="d-flex justify-content-center">
                              <Tooltip title='View Non Receipt Reports' placement="left">
                                <VisibilityIcon className="cursor-pointer " onClick={report ? handleReport : undefined} />
                              </Tooltip>
                              <Tooltip title="Add Non Receipt Reports" placement="left">
                                <AddBoxIcon className="cursor-pointer" onClick={handleAddReport} />
                              </Tooltip>
                            </div>
                          </div> : <></>
                        }
                        <Modal open={openModal} onClose={handleClose}>
                          <Box
                            className="d-flex  flex-column gap-3"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: 400,
                              backgroundColor: 'white',
                              boxShadow: 24,
                              padding: "20px",
                              outline: 'none',
                            }}
                          >
                            {/* <Typography id="modal-modal-title" style={{ color: "#0d4022" }} variant="h6" component="h2">
                              Add Report Non-Receipt
                            </Typography> */}
                            <Box className="d-flex justify-content-between pt-3">
                              <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                                Add Report Non-Receipt
                              </Typography>
                              <IconButton onClick={handleClose} aria-label="Close">
                                <CloseIcon />
                              </IconButton>
                            </Box>
                            <Box display="flex" justifyContent="center" width="100%" style={{ marginTop: "-7px" }}>
                              <Divider className="black-line-for-modal mb-0" sx={{ width: "100%" }} />
                            </Box>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              label={
                                <Typography className="text-dark">
                                  Date  <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              variant="outlined"
                              type="date"
                              value={watch("date") ? watch("date").split("T")[0] : ""}
                              {...register("date", {
                                required: "Please Select a Date ", max: {
                                  value: new Date().toISOString().split('T')[0],
                                  message: 'Future date is not allowed'
                                }
                              })}
                              error={!!errors?.date}
                              helperText={errors?.date?.message}
                            />
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              id="monthOfJournal"
                              label={
                                <Typography className="text-dark">
                                  Month of Journal <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              variant="outlined"
                              {...register("monthOfJournal", {
                                required: "Month of Journal is required",
                                minLength: { value: 3, message: "monthOfJournal must be at least 3 characters long" },
                              })}
                              error={!!errors?.monthOfJournal}
                              helperText={errors?.monthOfJournal?.message}
                            />
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              id="description"
                              label={
                                <Typography className="text-dark">
                                  Description <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              variant="outlined"
                              {...register("description", {
                                required: "Description is required",
                                minLength: { value: 3, message: "Description must be at least 3 characters long" },
                              })}
                              error={!!errors?.description}
                              helperText={errors?.description?.message}
                            />
                            <Box display="flex" justifyContent="center" width="100%">
                              <Divider className="black-line-for-modal mb-2" sx={{ width: "100%" }} />
                            </Box>
                            <div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="outlined"
                                  style={{
                                    color: "#dc3545",
                                    border: "1px solid  #dc3545",
                                    padding: "5px 15px",
                                    // borderRadius: "4px",
                                  }}
                                  onClick={handleClose}
                                >
                                  No
                                </Button>
                                <Button
                                  className="mx-2"
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#0d4022",
                                    color: "white",
                                    padding: "5px 15px",
                                    // borderRadius: "4px",
                                  }}
                                  onClick={handleSubmit(handleSaveNonReceipt)}
                                >
                                  Yes
                                </Button>
                              </div>

                            </div>
                          </Box>
                        </Modal>
                        <div className="text-white">
                        </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <SearchIcon
                            style={{
                              position: 'absolute',
                              left: '85%',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              pointerEvents: 'none',
                              color: 'grey',
                            }}
                          />
                          <input type="text"
                            className="customTextField"
                            style={{}}
                            fullWidth
                            placeholder="Search Member"
                            onChange={(e) => handleGetAllSearch(e)}
                          />
                        </div>
                        {filterSearch && filterSearch.length > 0 ? (
                          <ul className="list-group mt-2" style={{ position: 'absolute', zIndex: 10 }}>
                            {filterSearch.map((item, i) => (
                              <li
                                key={i}
                                className="list-group-item"
                                onClick={(e) => showUserCard(item)}
                                style={{ position: 'relative', zIndex: 20 }} // Higher z-index for list items
                              >
                                {item?.type === "0" || item?.type === "1" || item?.type === "4"
                                  ? item?.fullName
                                  : item?.type === "3"
                                    ? item?.organisationName
                                    : item?.institution}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                        {currentScope === "PORTAL_MEMBER" ? <>
                          <div
                            className="card mt-3 position-sticky"
                            style={{ backgroundColor: '#d9d9d9', zIndex: 5, top: 0, position: 'sticky', borderRadius: 'inherit' }}
                          >
                            <h5 className="mt-3">Your Chapter</h5>
                            <p className="chapter-border-font-color">{chapter ? chapter : "---"}</p>
                          </div>

                          <hr style={{ height: "1px", color: "#333", backgroundColor: "#333" }} />

                          <div className="card mt-3" style={{ backgroundColor: '#d9d9d9', position: 'relative', zIndex: 5, borderRadius: 'inherit' }}>
                            <h5 className="mt-3">Your SIG</h5>
                            <p className="chapter-border-font-color">{sig.length > 0 ? sig : "---"}</p>
                          </div>

                          <hr style={{ height: "1px", color: "#333", backgroundColor: "#333" }} />
                        </> : currentScope === "PORTAL_CHAPTER_ADMIN" ? <>
                          <p className=" member-name-color text-decoration-none my-2">{chapterDetails?.roleDetails.designation}</p>
                          <p className=" member-name-color text-decoration-none">{chapterDetails?.roleDetails.institution}</p>
                        </> : <></>}



                        {/* <h5 className="mb-2 member-name-color">Library Resource</h5> */}
                        {/* <a
                                  href="https://journals.eltai.in/index.php/jelt/index"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block text-decoration-none"
                                > */}
                        <h5 className=" member-name-color text-decoration-none">JELT</h5>
                        {/* </a> */}
                        {/* <a
                                  href="https://journals.eltai.in/index.php/jtrel"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block text-decoration-none"
                                > */}
                        <h5 className=" member-name-color text-decoration-none">JTREL</h5>
                        {/* </a> */}
                        {/* <a
                                  href="https://journals.eltai.in/index.php/jtelt"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="m-0 d-block text-decoration-none"
                                > */}
                        <h5 className="mb-2 member-name-color text-decoration-none">JTELT</h5>
                        {/* </a> */}
                        {/* <h5 className="mb-2 member-name-color">ELT Practiontor</h5>
                              <h5 className="mb-2 member-name-color">News Letter</h5> */}
                        <h5 className="mb-2 member-name-color"><Link to="/India-Reads">India Reads</Link></h5>
                        <h5 className="mb-2 member-name-color"><Link to="/view-Gallery">Galleries</Link></h5>
                      </CardContent>
                    </Card>
                    {currentScope === "PORTAL_CHAPTER_ADMIN" || currentScope === "PORTAL_SIG_ADMIN" ?
                      <Card className="membershipDetails-card ">
                        <div className="text-center py-3">
                          <p className="text-center cursor-pointer mt-3 upcoming-event" onClick={() => handleUpcomingClick(1)}>Upcoming Event</p>
                          <p className="text-center cursor-pointer upcoming-event" onClick={() => handleUpcomingClick(0)}>Past Event</p>
                        </div>
                      </Card>
                      : <></>
                    }
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="d-flex  flex-column gap-3">
            <Box className="d-flex justify-content-between ">
              <Typography variant="h5" sx={{ color: "#0d4022", fontWeight: "bold" }}>
                View Membership  Details
              </Typography>
              <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Divider className="black-line-for-modal mb-2" sx={{ width: "100%" }} />
            </Box>
            <Card sx={{ maxWidth: 345, border: "none", boxShadow: "none" }}>
              {/* <CardMedia
                sx={{ height: 200, width: 200, mx: "auto" }}
                image="/assets/images/login/userLogo.jpg"
                title="Member Details"
              /> */}
              <div className="d-flex justify-content-center">
                <Avatar alt="Member" src={selectedMember?.type === "0" || selectedMember?.type === "1" || selectedMember?.type === "4"
                  ? selectedMember?.imageUrl
                  : selectedMember?.type === "3"
                    ? selectedMember?.uploadOrganisationLogo
                    : selectedMember?.uploadInstitutionLogo} sx={{ width: 100, height: 100 }} />
              </div>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="fw-bold fs-3"
                >
                  {/* {selectedMember &&
                    selectedMember.fullName} */}
                  {selectedMember?.type === "0" || selectedMember?.type === "1" || selectedMember?.type === "4"
                    ? selectedMember?.fullName
                    : selectedMember?.type === "3"
                      ? selectedMember?.organisationName
                      : selectedMember?.institution}
                  {selectedMember?.type === "0" || selectedMember?.type === "4" ? "(Teacher)" : selectedMember?.type === "1" ? "(Student)" : selectedMember?.type === "2" ? "(Institution)" : "(Corporate)"}

                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span className="fw-bold me-2">Member Id:</span>{" "}
                  {selectedMember && selectedMember?.userId}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span className="fw-bold me-2">Phone:</span>{" "}
                  {selectedMember && selectedMember?.mobileNumber}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <span className="fw-bold me-2">Email:</span>
                  {selectedMember && selectedMember?.email}
                </Typography>

                {
                  selectedMembership &&
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className=""
                  >
                    <span className="fw-bold me-2">Plan Name:</span>
                    {selectedMembership && planTypeShow(selectedMembership?.planName)}

                  </Typography>
                }

              </CardContent>
            </Card>
          </Box>
        </Modal>
      </>
      {/* {currentScope === "PORTAL_CHAPTER_ADMIN" && (
        <>
          <ChapterAdminPortal></ChapterAdminPortal>
        </>
      )}
      {currentScope === "PORTAL_SIG_ADMIN" && (
        <>
          <SigAdminPortal></SigAdminPortal>
        </>
      )} */}
    </>
  );
}

export default Viewmember;
