import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import {
  useGetAllMemberListMutation,
  useEnableDisableMemberListMutation, useApproveRejectMemberMutation,
  useDeleteMemberListMutation
} from "../../app/services/memberListServices";
import {
  useLazyGetChapterDetailsByIdQuery,
} from "../../app/services/chapterServices";
import Table from "@mui/material/Table";
import { InputAdornment, Modal, TextField, FormControlLabel, Tabs, Grid, RadioGroup, Radio, FormHelperText, Divider, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container, IconButton, Checkbox, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ReactPaginate from "react-paginate";
import '../../style/css/pagination.css'; // Import CSS file for pagination styles
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner"
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLazyGetSIGGroupByIdQuery } from "../../app/services/SIGGroupServices";
import Tab from '@mui/material/Tab';
import { useForm, Controller } from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyIcon from '@mui/icons-material/Key';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../app/services/loginServices";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAddNotPaidMemberMutation, useSearchMemberMutation, useUpdatePlansRenewalMutation } from "../../app/services/memberServices";
import { useLazyGetMemberShipDetailsByIdQuery } from "../../app/services/memberShipServices";
import { planType } from "../../constant/constant";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close'

const ChapterName = ({ id }) => {
  const [chapterDetails, setChapterDetails] = useState(null);
  const [getChapterDetailsById] = useLazyGetChapterDetailsByIdQuery();



  useEffect(() => {
    getChapterDetailsById(id)
      .unwrap()
      .then((res) => {
        setChapterDetails(res.data.title);
      })
      .catch((error) => {
        console.error("Error fetching chapter details:", error);
      });
  }, [id, getChapterDetailsById]);
  return chapterDetails ? chapterDetails : '-----';
};

const SigName = ({ id }) => {
  const [sigDetails, setSigDetails] = useState(null);
  const [getSigDetailById] = useLazyGetSIGGroupByIdQuery()
  useEffect(() => {
    getSigDetailById(id)
      .unwrap()
      .then((res) => {
        setSigDetails(res.data.groupName);
      })
      .catch((error) => {
        console.error("Error fetching sig details:", error);
      });
  }, [id, getSigDetailById]);
  return sigDetails ? sigDetails : '-----';
};
function EnhancedTableHead({ type }) {
  let headCells
  if (type === '0' || type === '1' || type === undefined || type === null || type === '' || type === "4") {
    headCells = [
      {
        id: "memberId",
        numeric: false,
        disablePadding: true,
        label: "Member ID",
      },
      {
        id: "Name",
        numeric: false,
        disablePadding: true,
        label: "Name",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '170px !important' },
    ];
  }
  else if (type === '2') {
    headCells = [
      {
        id: "memberId",
        numeric: false,
        disablePadding: true,
        label: "Member ID",
      },
      {
        id: "Institution",
        numeric: false,
        disablePadding: true,
        label: "Institution",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '170px !important' },
    ];
  } else if (type === '3') {
    headCells = [
      {
        id: "memberId",
        numeric: false,
        disablePadding: true,
        label: "Member ID",
      },
      {
        id: "Organisation",
        numeric: false,
        disablePadding: true,
        label: "Organisation",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '170px !important' },
    ];
  }

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells && headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff", minWidth: headCell.width ? headCell.width : 'auto' }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function RejectTableHead({ type }) {
  let headCells
  if (type === '0' || type === '1' || type === undefined || type === null || type === '' || type === '4') {
    headCells = [
      {
        id: "Name",
        numeric: false,
        disablePadding: true,
        label: "Name",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      {
        id: "Reject Reason",
        numeric: false,
        disablePadding: true,
        label: "Reject Reason",
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];
  }
  else if (type === '2') {
    headCells = [
      {
        id: "Institution",
        numeric: false,
        disablePadding: true,
        label: "Institution",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      {
        id: "Reject Reason",
        numeric: false,
        disablePadding: true,
        label: "Reject Reason",
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];
  } else if (type === '3') {
    headCells = [
      {
        id: "Organisation",
        numeric: false,
        disablePadding: true,
        label: "Organisation",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email ID",
      },
      {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
      },
      {
        id: "chapter",
        numeric: false,
        disablePadding: true,
        label: "Chapter",
      },
      {
        id: "SIG",
        numeric: false,
        disablePadding: true,
        label: "SIG",
      },
      // {
      //   id: "Address",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Address",
      // },
      {
        id: "Reject Reason",
        numeric: false,
        disablePadding: true,
        label: "Reject Reason",
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];
  }


  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells && headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
const style1 = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  minWidth: 550,
  height: '50%',
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  overflowY: 'scroll',
  "&::-webkit-scrollbar": {
    display: 'none'
  },
};

const GetPlanDetails = ({ id, typeId, value }) => {
  const [getPlanById] = useLazyGetMemberShipDetailsByIdQuery();
  const [plan, setPlan] = useState(null);
  const [planTypes, setPlanTypes] = useState(null);
  useEffect(() => {
    getPlanById(id).then(res => {
      setPlan(res?.data?.data)
      setPlanTypes(res?.data?.data?.planType)
    })
  }, [getPlanById, id])
  if (value === 'plan') {
    return <>{planType.find(item => item.planId === plan?.planName)?.planName}</>
  }
  if (value === 'planType') {
    return <>{planTypes && (planTypes.find(item => item._id === typeId).planTypeName)}({planTypes && planTypes.find(item => item._id === typeId).duration}{planTypes && planTypes.find(item => item._id === typeId).duration > 1 ? ' Years' : ' Year'})</>
  }
}
export default function AllMemberList() {
  const [loading, setLoading] = useState(true);
  const [getAll, setGetAll] = useState([]);
  const [page, setPage] = useState(0);//state for set page
  const [pendingMemberPage, setPendingMemberPage] = useState(0);
  const [rejectionMemberPage, setRejectionMemberPage] = useState(0)
  const [notPaidMemberPage, setNotPaidMemberPage] = useState(0)
  let [pendingMembertotalCount, setPendingMemberTotalCount] = useState(0);
  const [rejectionMemberTotel, setRejectionMemberTotel] = useState(0)
  const [notPaidMemberTotel, setNotPaidMemberTotel] = useState(0)
  let [totalCount, setTotalCount] = useState(0);
  const [getAllMemberList] = useGetAllMemberListMutation();
  const [type, setType] = useState('');
  const [value, setTabValue] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [getAllPendingMemberlist] = useGetAllPendingMemberMutation()
  const [getAllPendingMembers, setGetAllPendingMembers] = useState([])
  const [showButton, setShowButton] = useState(false)
  const [approveRejectMember] = useApproveRejectMemberMutation()
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rejection, setRejection] = useState([])
  const [notPaid, setNotPaid] = useState([])
  const navigate = useNavigate()
  const [open1, setOpen1] = useState(false)
  const [memberRejected, setMemberRejected] = useState(null)
  const [resetPassword] = useResetPasswordMutation();
  const [addNotPaidMember] = useAddNotPaidMemberMutation()
  const [openForm, setOpenForm] = useState(false);
  const [searchMemberApi] = useSearchMemberMutation()
  const [getPlanById] = useLazyGetMemberShipDetailsByIdQuery()
  const [updateMemberRenewal] = useUpdatePlansRenewalMutation()
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState("");
  const [selectedMember, setSelectedMember] = useState(null)
  const [deleteMember] = useDeleteMemberListMutation();
  // const [updateMemberRenewal] = useUpdatePlansRenewalMutation()

  const memberScope = useSelector(selectCurrentScope)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    maxWidth: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  const handleChange = (event, newValue) => {
    if (newValue === 3) {
      setType('');
    }
    setTabValue(newValue);
  };

  const handleClose = () => {
    setOpen(false)
    setOpen1(false);
    reset({ rejectionReason: "" });
  }
  const handleDeleteModalClose = () => {
    setOpenDelete(false)
  }
  const handleDeleteModalOpen = (member) => {
    setSelectedMember(member)
    setOpenDelete(true)
  }
  const handleDeleteMember = () => {
    deleteMember(selectedMember?._id).then(res => {
      if (res?.data?.status)
        toast?.success(res?.data?.message)
      handleDeleteModalClose();
      if (getAll.length === 1) {
        setPage(page - 1)
        fetchDataByTab(value);
      }
      else {
        fetchDataByTab(value);
      }
    })
  }
  const handleView = (member) => {
    if (member.type === "0" || member.type === "4") {
      navigate(`/admin/teacher-member/${member._id}`);
    } else if (member.type === "1") {
      navigate(`/admin/student-member/${member._id}`);
    } else if (member.type === "2") {
      navigate(`/admin/View-institution/${member._id}`);
    } else {
      navigate(`/admin/view-corporate/${member._id}`);
    }
  }
  const {
    register: registerReject,
    getValues,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const {
    control: controlSearch,
    register: registerSearch,
    watch: watchSearch,
    clearErrors: clearErrorsSearch,
    reset: resetSearch,
    handleSubmit,
    setValue: setValueSearch,
    formState: { errors: searchErrors },
  } = useForm({
    mode: 'onChange', defaultValues: {
      searchTerm: '',
      searchBy: '0',
    }
  });

  const {
    control: controlAdd,
    register: registerAdd,
    handleSubmit: handleSubmitAdd,
    setValue: setValueAdd,
    watch: watchAdd,
    reset: resetAdd,
    formState: { errors: errorsAdd },
    clearErrors: nonPaidClearError
  } = useForm({
    mode: "onChange",
    defaultValues: {
      memberId: "",
      paymentType: 0,  // cash: 0, online: 1, cheque: 2
      paymentMethod: "",
      bankTransactionId: "",
      amount: "",
      currency: "0",
      scope: memberScope ? memberScope : '',
    },
  });

  const handleFormClose = () => {
    setOpenForm(false)
    resetAdd({
      memberId: "",
      paymentType: 0,  // cash: 0, online: 1, cheque: 2
      paymentMethod: "",
      bankTransactionId: "",
      amount: "",
      currency: "0",
      scope: memberScope ? memberScope : "", // Ensure scope is updated

    })
  }
  const handleOpenForm = (row) => {
    setOpenForm(true);
    const planAmount = row?.plans[row?.plans?.length - 1];
    let offerAmount;
    let discountAmount;
    getPlanById(planAmount?.dummyPlanId).then(res => {
      const planDetail = res.data?.data?.planType.find(item => item._id === row?.plans[row?.plans?.length - 1].dummyPlanTypeId)
      offerAmount = planDetail.offer > 0 ? planDetail?.offerAmount : 0;
      discountAmount = planDetail.discount > 0 ? planDetail?.discountAmount : 0;
      setValueAdd('originalAmount', planAmount?.dummyAmount)
      setValueAdd('currency', String(planDetail.currencyType))
      if (value === 0) {
        setValueAdd("amount", discountAmount > 0 ? discountAmount : planAmount?.dummyAmount);
      }
      else {
        setValueAdd("amount", offerAmount > 0 ? offerAmount : planAmount?.dummyAmount);
      }
    })

    setValueAdd("memberId", row?._id);
    setValueAdd('membershipPlan', row?.plans[row?.plans?.length - 1].dummyPlanId)
    setValueAdd('membershipPlanType', row?.plans[row?.plans?.length - 1].dummyPlanTypeId)
    setValueAdd("scope", memberScope ? memberScope : ""); // Update scope dynamically
  }
  const AddMember = (data) => {
    addNotPaidMember(data).then(res => {
      if (res.data?.status) {
        toast.success(res.data?.message)
        setLoading(false);
        setOpenForm(false)
        setNotPaidMemberPage(0)
        resetAdd({
          memberId: "",
          paymentType: 0,  // cash: 0, online: 1, cheque: 2
          paymentMethod: "",
          bankTransactionId: "",
          amount: "",
          currency: "0",
        })
        getAllNotPaidPagination()
      }
      else {
        setLoading(false);
        setOpenForm(false)
        toast.error(res.data?.err?.message)
      }
    })

  }

  const handleCheckboxToggle = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id)
        ? prev.filter((rowId) => rowId !== id) // If id is already selected, remove it
        : [...prev, id] // If id is not selected, add it
    );
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked; // Get the checkbox state (true or false)
    setShowButton(checked)
    // Update selectedRows based on whether the checkbox is checked
    setSelectedRows(checked ? getAllPendingMembers.map((row) => row._id) : []);
  };
  // Check if a row is selected

  // Optionally log selectedRows for debugging
  useEffect(() => {
    if (selectedRows.length > 0) {
      setShowButton(false)
    }
  }, [selectedRows]);
  const showButtons = selectedRows.length > 0;
  const shouldShowButtons = showButton || showButtons;

  //get change value
  const getChangeValue = (eve) => {
    const value = eve.target.value
    setPage(0)
    setPendingMemberPage(0)
    setRejectionMemberPage(0)
    setNotPaidMemberPage(0)
    setType(value === 'All' ? '' : value);
    // getAllPagination(eve.target.value);
    setLoading(true)
    setSelectedRows([])
    setValueSearch('searchTerm', '')
  };

  //get all pagination for testimonial
  const getAllPagination = useCallback((types) => {
    var data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      approve: 3,
      type: (types === undefined && (type === null || type === undefined)) ? '0' : types ? types : type
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setGetAll(response.data?.data?.memberDetails || []);
      setTotalCount(response.data?.data?.totalCount || 0);
    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, page, type, itemsPerPage])

  const handleOpen1 = (member) => {
    setOpen1(true);
    setMemberRejected(member)
  };


  const getAllPendingPagination = useCallback(
    (types) => {
      const resolvedType = (types === undefined && (type === null || type === undefined)) ? '0' : types ? types : type // Explicit check
      const data = {
        page: pendingMemberPage + 1,
        itemsPerPage: itemsPerPage,
        type: resolvedType,
        approve: 0,
      };

      setLoading(true);
      getAllMemberList(data)
        .then((response) => {
          setGetAllPendingMembers(response.data?.data?.memberDetails || []);
          setPendingMemberTotalCount(response.data?.data?.totalCount || 0);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getAllMemberList, pendingMemberPage, type, itemsPerPage]
  );

  const getAllRejectionPagination = useCallback((types) => {
    const resolvedType = (types === undefined && (type === null || type === undefined)) ? '0' : types ? types : type
    var data = {
      page: rejectionMemberPage + 1,
      itemsPerPage: itemsPerPage,
      approve: 2,
      type: resolvedType
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setRejection(response.data?.data?.memberDetails || []);
      setRejectionMemberTotel(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, rejectionMemberPage, type, itemsPerPage])

  const getAllNotPaidPagination = useCallback((types) => {
    const resolvedType = (types === undefined && (type === null || type === undefined)) ? '0' : types ? types : type // Simplified type resolution
    var data = {
      page: notPaidMemberPage + 1,
      itemsPerPage: itemsPerPage,
      approve: 4,
      type: resolvedType,
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setNotPaid(response.data?.data?.memberDetails || []);
      setNotPaidMemberTotel(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, notPaidMemberPage, type, itemsPerPage])

  const NotPaidPageCount = Math.ceil(notPaidMemberTotel / itemsPerPage);
  useEffect(() => {
    switch (value) {
      case 0:
        getAllPagination();
        break;
      case 1:
        getAllPendingPagination();
        break;
      case 2:
        getAllRejectionPagination();
        break;
      case 3:
        getAllNotPaidPagination();
        break;
      default:
        console.warn("Unhandled tab value:", value);
    }
  }, [value, getAllPagination, getAllPendingPagination, getAllNotPaidPagination, getAllRejectionPagination]);
  const handleNotPaidPageClick = ({ selected }) => {
    setNotPaidMemberPage(selected);
  };

  function PendingTableHead() {
    let headCells
    const handleSelectAllCheckbox = (e) => handleSelectAll(e);
    if (type === '0' || type === '1' || type === undefined || type === null || type === '' || type === '4') {

      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        // {
        //   id: "Address",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "Address",
        // },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }
    else if (type === '2') {
      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Institution",
          numeric: false,
          disablePadding: true,
          label: "Institution",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        // {
        //   id: "Address",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "Address",
        // },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    } else if (type === '3') {
      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Organisation",
          numeric: false,
          disablePadding: true,
          label: "Organisation",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        // {
        //   id: "Address",
        //   numeric: false,
        //   disablePadding: true,
        //   label: "Address",
        // },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells && headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  //handlepage
  const handlePageClick = ({ selected }) => {
    setSearchTerm('')
    setError("");
    setPage(selected);
  };
  const handlePendingPageClick = ({ selected }) => {
    setPendingMemberPage(selected);
  };
  const handleRejectPageClick = ({ selected }) => {
    setRejectionMemberPage(selected);
  };
  //calculate page count
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const pendingPageCount = Math.ceil(pendingMembertotalCount / itemsPerPage);
  const RejectPageCount = Math.ceil(rejectionMemberTotel / itemsPerPage);

  const handlePageChange = () => {

    const pageNumber = Number(searchTerm);
    if (isNaN(pageNumber) || pageNumber < 0) {
      setError("Page number cannot be less than 1");
    } else if (pageNumber > pageCount) {
      setError("Please enter valid page number");
    } else if (pageNumber === 0) {
      setError("Please enter page number");
    } else {
      setError("");
      setPage(pageNumber - 1);
    }
  };


  const [isEnabledDisabled] = useEnableDisableMemberListMutation();

  //handlrtoggle
  const handleToggle = (user) => {
    const id = user._id;

    if (!id) {
      console.warn("Invalid user ID");
      return;
    }

    setLoading(true);

    isEnabledDisabled(id)
      .unwrap()
      .then((response) => {
        setLoading(false);

        if (response?.status) {
          const isEnabled = response?.data?.isDeleted === 0;
          toast[isEnabled ? "success" : "error"](
            isEnabled ? "Member Enabled" : "Member Disabled"
          );

          // Handle fetching data based on the current tab
          fetchDataByTab(value);
        } else {
          toast.error(response?.err?.message || "An error occurred");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.message || "An unexpected error occurred");
      });
  };

  // Reusable function to fetch data based on the active tab
  const fetchDataByTab = (tabValue) => {
    switch (tabValue) {
      case 0:
        getAllPagination();
        break;
      case 1:
        getAllPendingPagination();
        break;
      case 2:
        getAllRejectionPagination();
        break;
      case 3:
        getAllNotPaidPagination();
        break;
      default:
        console.warn("Unhandled tab value:", tabValue);
    }
  };

  const handleApproveReject = async (type) => {
    if (type === 1) {
      const isValid = await trigger("rejectionReason"); // Trigger validation manually
      if (!isValid) {
        return; // Do not proceed if validation fails
      }
    }

    const rejectionReason = getValues("rejectionReason");
    const data = {
      membersId: selectedRows,
      type,
      ...(type === 1 && { rejectionReason }),
    };

    approveRejectMember(data)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          fetchDataByTab(value);
          handleClose();
          if (type === 1) {
            reset({ rejectionReason: "" }); // Clear the input field
          }
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setSelectedRows([]); // Clear selected rows
  };

  const handleResetPassword = (member) => {
    var data = {
      id: member._id,
      role: "PORTAL_MEMBER"
    }
    resetPassword(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
      }
      else {
        toast.error(res?.data?.message)
      }
    }).catch(err => console.error(err))
  }
  const handleOpen = () => {
    setOpen(true);
  }

  const searchMember = (formData) => {
    setLoading(true)
    const data = {
      "searchBy": Number(formData.searchBy),
      "searchTerm": formData.searchTerm,
      "type": type,
      "page": '',
      "itemsPerPage":''
    }
    searchMemberApi(data).then(res => {
      setLoading(false)
      if (res?.data?.status) {
        setGetAll(res?.data?.data?.memberDetails || []);
        setTotalCount(0);
      }
      else {
        setGetAll([])
      }
    })
  }
  const resetTable = () => {
    setType('')
    setPage(0)
    resetSearch({ searchBy: '0', searchTerm: '' })
    fetchDataByTab(value)
  }
  const renewMember = (data) => {
    resetAdd({})
    setLoading(true)
    updateMemberRenewal(data).then(res => {
      setLoading(false)
      if (res?.data?.status) {
        fetchDataByTab(value);
        handleFormClose();
      }
    })
  }
  function isDateWithinThreeMonths(dateToCompare) {
    const today = new Date();
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(today.getMonth() + 3);
    threeMonthsLater.setDate('01');
    const date = new Date(dateToCompare.split('T')[0]);
    return date >= threeMonthsLater;
  }


  return (
    <>
      <TopHeader />
      <div className="pt-5 mb-4">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">Member List</h2>
        </div>
      </div>
      <Container maxWidth="xl" className=" p-md-5 p-3  overallPadding">
        <Grid
          container
          spacing={2}
          style={{
            maxWidth: '100%',
            margin: "0 auto",
            position: "sticky",
            top: "0",
            zIndex: "999",
            marginBlock: "10px",
          }}
        >
          <Tabs
            position="static"
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            textColor='#611f1e'
            sx={{
              // borderRadius: "8px",
              backgroundColor: '#611f1e !important',
              mx: 'auto',
              width: '100%',
              display: 'flex',
              flexGrow: "1",
              "& .MuiButtonBase-root": {
                color: "white",  // Change the color of the scroll buttons
              }
            }}
          >
            <Tab
              label="All Members"
              sx={{
                width: '25%',
                maxWidth: '100%',
                color: value === 0 ? '#611f1e !important' : 'white',
                background: value === 0 ? 'white' : '#611f1e !important',
              }}
            />
            <Tab
              label="Pending Members"
              sx={{
                width: '25%',
                maxWidth: '100%',
                color: value === 1 ? '#611f1e !important' : 'white',
                background: value === 1 ? 'white' : '#611f1e !important',
              }}
            />
            <Tab
              label="Rejected Members"
              sx={{
                width: '25%',
                maxWidth: '100%',
                color: value === 2 ? '#611f1e !important' : 'white',
                background: value === 2 ? 'white' : '#611f1e !important',
              }}
            />
            <Tab
              label="Not Paid Members"
              sx={{
                width: '25%',
                maxWidth: '100%',
                color: value === 3 ? '#611f1e !important' : 'white',
                background: value === 3 ? 'white' : '#611f1e !important',
              }}
            />
          </Tabs>
        </Grid>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {value === 0 && (
              <>
                <form onSubmit={handleSubmit(searchMember)} className="mt-3 p-3">
                  <Grid container rowSpacing={3} columnSpacing={3} className="d-flex justify-content-start">
                    <Grid item xs={12} md={12} lg={5}>
                      <Controller
                        control={controlSearch}
                        name={`searchBy`}
                        rules={{ required: 'Please select a option' }}
                        render={({ field, fieldState }) => (
                          <>
                            <FormControl fullWidth>
                              <RadioGroup
                                row
                                value={field.value}
                                className="d-flex flex-sm-row flex-column mx-auto"
                                onChange={(event) => {
                                  const selectedValue = event.target.value;
                                  field.onChange(selectedValue);
                                  clearErrorsSearch('searchTerm')
                                }}
                              >
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label={'Name'}
                                />
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label={'Member ID'}
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label={'Mail ID'}
                                />
                                <FormControlLabel
                                  value="3"
                                  control={<Radio />}
                                  label={'Mobile Number'}
                                />
                              </RadioGroup>
                              {fieldState?.error && (
                                <FormHelperText error>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <FormControl className="w-100">
                        <InputLabel shrink={true} id="type-label" sx={{
                          '&.Mui-focused': {
                            color: 'inherit', // Maintain the default color
                          },
                        }}>Type</InputLabel>
                        <Select
                          labelId="type-label"
                          id="type"
                          label="type"
                          required
                          fullWidth
                          value={type === '' ? 'All' : type}
                          onChange={getChangeValue}
                          sx={{
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'inherit', // Maintain the default border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'inherit', // Prevent border change on hover
                            },
                          }}
                        >
                          <MenuItem value='All'>All</MenuItem>
                          <MenuItem value={'0'}> Individual Teacher </MenuItem>
                          <MenuItem value={'4'}>Dual Teacher </MenuItem>
                          <MenuItem value={'1'}>  Individual Student </MenuItem>
                          <MenuItem value={'2'}>  Institutional </MenuItem>
                          <MenuItem value={'3'}>Corporate Supporter </MenuItem>
                        </Select>
                      </FormControl>
                      {shouldShowButtons && value === 1 &&
                        <div >
                          <button className="btn btn-danger" onClick={handleOpen}>Reject</button>
                          <button className="btn btn-primary ms-2" onClick={() => handleApproveReject(0)} style={{ backgroundColor: "#611f1e", border: 'none' }}>Approve</button>
                        </div>
                      }
                    </Grid>
                    <Grid item xs={12} md={8} lg={5} className="d-flex justify-content-between flex-sm-row flex-column align-items-start gap-3">
                      <TextField
                        label={
                          <Typography className="text-dark" >
                            Search Term <span className="text-danger">*</span>
                          </Typography>
                        }
                        InputLabelProps={{ shrink: true }}
                        value={watchSearch('searchTerm') || ''}
                        placeholder="Enter a search term"
                        {...registerSearch('searchTerm', {
                          required: 'Please enter a search term',
                          minLength: { value: 3, message: 'Please enter a valid search term' },
                          pattern: {
                            value: (watchSearch('searchBy') === '2' || watchSearch('searchBy') === 2) ?
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ : false,
                            message: "Enter a valid email ID",
                          }, validate: (value) => {
                            if (watchSearch('searchBy') === "1" || watchSearch('searchBy') === 1) {
                              return (value.length < 10 || value.length > 10) ? 'Member ID must be 10 character alphanumeric value' : true
                            }
                            if (watchSearch('searchBy') === "3" || watchSearch('searchBy') === 3) {
                              const regex = /^\d{10}$/;
                              return !regex.test(value) ? 'Please enter a valid mobile number' : true
                            }
                            return true
                          }
                        })}
                        fullWidth
                        error={!!searchErrors?.searchTerm}

                        helperText={searchErrors?.searchTerm?.message} />
                      <div className="mt-2 d-flex ms-sm-0 ms-auto gap-2">
                        <Button type="submit" variant="contained" className="ms-auto menus-color" >Search</Button>
                        <Button variant="outlined" color="error" className="rounded-0" onClick={resetTable}>Reset</Button>
                      </div>
                    </Grid>

                  </Grid>
                </form>
              </>
            )}
            {(value !== 0 && value !== 3) && <div className="mt-5 w-100 d-flex justify-content-between">
              <FormControl>
                <InputLabel shrink={true} id="type-label" sx={{
                  '&.Mui-focused': {
                    color: 'inherit', // Maintain the default color
                  },
                }}>Type</InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  label="type"
                  required
                  fullWidth
                  value={type === '' ? 'All' : type}
                  onChange={getChangeValue}
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'inherit', // Maintain the default border color
                    },
                    minWidth: '300px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'inherit', // Prevent border change on hover
                    },
                  }}
                >
                  <MenuItem value='All'>All</MenuItem>
                  <MenuItem value={'0'}> Individual Teacher </MenuItem>
                  <MenuItem value={'4'}>Dual Teacher </MenuItem>
                  <MenuItem value={'1'}>  Individual Student </MenuItem>
                  <MenuItem value={'2'}>  Institutional </MenuItem>
                  <MenuItem value={'3'}>Corporate Supporter </MenuItem>
                </Select>
              </FormControl>
              {shouldShowButtons && value === 1 &&
                <div >
                  <button className="btn btn-danger" onClick={handleOpen}>Reject</button>
                  <button className="btn btn-primary ms-2" onClick={() => handleApproveReject(0)} style={{ backgroundColor: "#611f1e", border: 'none' }}>Approve</button>
                </div>
              }

            </div>}
            {value === 3 &&
              <div className="mt-5 w-100 d-flex justify-content-between">
                <FormControl>
                  <InputLabel shrink={true} id="type-label" sx={{
                    '&.Mui-focused': {
                      color: 'inherit', // Maintain the default color
                    },
                  }}>Type</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    label="type"
                    required
                    fullWidth
                    value={type === '' ? 'All' : type}
                    onChange={getChangeValue}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'inherit', // Maintain the default border color
                      },
                      minWidth: '300px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'inherit', // Prevent border change on hover
                      },
                    }}
                  >
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value={'2'}>  Institutional </MenuItem>
                    <MenuItem value={'3'}>Corporate Supporter </MenuItem>
                  </Select>
                </FormControl>
                {shouldShowButtons && value === 1 &&
                  <div >
                    <button className="btn btn-danger" onClick={handleOpen}>Reject</button>
                    <button className="btn btn-primary ms-2" onClick={() => handleApproveReject(0)} style={{ backgroundColor: "#611f1e", border: 'none' }}>Approve</button>
                  </div>
                }
              </div>
            }
            {value === 0 && <Box sx={{ width: "100%" }} className="mt-3">

              <Paper sx={{ width: "100%", mb: 2 }}>

                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead type={type} />
                    <TableBody>
                      {getAll.length > 0 ? (
                        getAll.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="center">{row?.userId}</TableCell>
                            <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.mobileNumber}</TableCell>
                            <TableCell align="center">
                              {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                            </TableCell>
                            <TableCell align="center">
                              {row.sigGroups?.length > 0 ? row?.sigGroups.map(sig => (
                                <>
                                  <SigName id={sig} />, <br></br>
                                </>
                              )) : <p className="text-dark">---</p>}
                            </TableCell>
                            {/* <TableCell align="center" style={{ width: '200px', textOverflow: 'ellipsis', textWrap: 'wrap' }}>{row?.address},&nbsp;{row?.city},&nbsp;{row?.pinCode}.</TableCell> */}
                            <TableCell align="center">
                              <IconButton onClick={() => handleView(row)}>
                                <Tooltip title="View" placement="left">
                                  <VisibilityIcon style={{ color: '#ff8000' }} />
                                </Tooltip>
                              </IconButton>

                              <IconButton onClick={() => handleResetPassword(row)}>
                                <Tooltip title="Reset Password" placement="top">
                                  <KeyIcon style={{ color: 'darkred' }} />
                                </Tooltip>
                              </IconButton>
                              <IconButton onClick={() => handleToggle(row)}>
                                {row.isDeleted === 0 ? (
                                  <Tooltip title="Enable" placement="top">
                                    <LockOpenIcon style={{ color: 'green' }} />
                                  </Tooltip>

                                ) : (
                                  <Tooltip title="Disable" placement="top">
                                    <LockIcon style={{ color: 'red' }} />
                                  </Tooltip>

                                )}
                              </IconButton>
                              {(memberScope === 'PORTAL_SUPER_ADMIN' && (row?.plans.length > 0 && !isDateWithinThreeMonths(row?.plans[row?.plans.length - 1]?.dummyEndDate.split('T')[0]))) && <IconButton onClick={() => { handleOpenForm(row) }}>
                                <Tooltip title="Renew Membership plan" placement="top">
                                  <AutorenewIcon className="text-warning" />
                                </Tooltip>
                              </IconButton>}
                              {memberScope === 'PORTAL_SUPER_ADMIN' && <IconButton onClick={() => { handleDeleteModalOpen(row) }}>
                                <Tooltip title="Delete Member" placement="top">
                                  <DeleteIcon className="text-danger" />
                                </Tooltip>
                              </IconButton>}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {getAll.length > 0 ?
                <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0 content-font" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        placeholder="Page no."
                        value={searchTerm}
                        // onChange={(e) => {
                        //   console.log(e.target.value)
                        //   setSearchTerm(e.target.value)

                        // }}
                        onChange={(e) => {
                          const page = e.target.value ? Math.max(1, Number(e.target.value)) : "";
                          setSearchTerm(page);
                        }}
                        min={1}
                        // onChange={(e) => setSearchTerm(Number(e.target.value - 1))}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>
                  <div className="my-3 my-lg-0">
                    {getAll.length > 0 ? (
                      <ReactPaginate
                        pageCount={pageCount} // Replace with the total number of pages
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={page}
                        previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                        nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                  <div className="mb-2 mb-md-0 content-font">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('')
                          setSearchTerm('');
                          setPage(0)
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div> : <></>}
            </Box>}
            {value === 1 && <Box sx={{ width: "100%" }} className="mt-3">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <PendingTableHead />
                    <TableBody>
                      {getAllPendingMembers.length > 0 ? (
                        getAllPendingMembers.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="center">
                              <Checkbox
                                checked={selectedRows.includes(row._id)}
                                onChange={() => handleCheckboxToggle(row._id)}
                                inputProps={{ 'aria-label': 'Select Row' }}
                              />
                            </TableCell>
                            <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.mobileNumber}</TableCell>
                            <TableCell align="center">
                              {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                            </TableCell>
                            <TableCell align="center">
                              {row.sigGroups?.length > 0 ? row.sigGroups.map((sig) => (
                                <>
                                  <SigName id={sig} />, <br />
                                </>
                              )) : <p className="text-dark">---</p>}
                            </TableCell>
                            {/* <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell> */}
                            <TableCell align="center">
                              <IconButton onClick={() => handleView(row)}>
                                <Tooltip title="View" placement="left">
                                  <VisibilityIcon style={{ color: '#ff8000' }} />
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>


                  </Table>
                </TableContainer>
              </Paper>
              {getAllPendingMembers?.length > 0 ?
                <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        placeholder="Page no."
                        value={searchTerm}
                        // onChange={(e) => setSearchTerm(e.target.value)}
                        // onChange={(e) => setSearchTerm(Number(e.target.value - 1))}
                        onChange={(e) => {
                          const page = e.target.value ? Math.max(1, Number(e.target.value)) : "";
                          setSearchTerm(page);
                        }}
                        min={1}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>
                  <div className="my-3 my-lg-0">
                    {getAllPendingMembers?.length > 0 ? (
                      <ReactPaginate
                        pageCount={pendingPageCount} // Replace with the total number of pages
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        onPageChange={handlePendingPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={pendingMemberPage}
                        previousLabel={pendingPageCount > 1 && pendingMemberPage >= 1 ? "Previous" : null}
                        nextLabel={pendingPageCount > 1 && pendingMemberPage + 1 !== pendingPageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                  <div className="mb-2 mb-md-0">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('')
                          setSearchTerm('');
                          setPage(0)
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> : <></>}
            </Box>}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >

              <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                <Container maxWidth={false} sx={{ px: 3 }}>
                  <Box className="d-flex justify-content-between pt-2">
                    <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                      Rejection Reason
                    </Typography>
                    <IconButton onClick={handleClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Container>
                <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                  <Divider sx={{ bgcolor: "black", height: "2px", width: "45vh", marginTop: "-15px" }} />
                </Box>
                <Typography variant="h4" sx={{ color: "#104e29" }}>
                  Rejection Reason
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 300 }}
                  label={
                    <Typography className="text-dark">
                      Reason For Rejection <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {300 - (watch("rejectionReason")?.length || 0)}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.rejectionReason}
                  helperText={errors?.rejectionReason?.message}
                  {...registerReject("rejectionReason", {
                    required: "Reason for rejection is required.",
                    maxLength: {
                      value: 300,
                      message: "Reason cannot exceed 300 characters.",
                    },
                  })}
                />
                <Box className="mt-3">
                  <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="mx-2"
                    variant="contained"
                    color="success"
                    onClick={() => handleApproveReject(1)}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Modal>
            {/* <Modal
              open={openDelete}
              onClose={handleDeleteModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                <Typography variant="h4" sx={{ color: "#104e29" }}>
                  Delete Member
                </Typography>
                <Typography >
                  Are You sure you want to delete member <span className="text-secondary-new"> {selectedMember?.fullName ? selectedMember.fullName : selectedMember?.organisationName ? selectedMember?.organisationName : selectedMember?.institution ? selectedMember?.institution : ''} </span> ?
                </Typography>

                <Box className="mt-3">
                  <Button variant="outlined" color="error" onClick={handleDeleteModalClose}>
                    No
                  </Button>
                  <Button
                    className="mx-2"
                    variant="contained"
                    color="success"
                    onClick={() => handleDeleteMember()}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal> */}
            <Modal
              open={openDelete}
              onClose={handleDeleteModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box

                className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  backgroundColor: 'white',
                  boxShadow: 24,
                  padding: "20px",
                  outline: 'none',
                }}
              >
                <Container maxWidth={false} sx={{ px: 3 }}>
                  <Box className="d-flex justify-content-between pt-2">
                    <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                      Confirmation
                    </Typography>
                    <IconButton onClick={handleDeleteModalClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Container>
                <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                  <Divider sx={{ bgcolor: "black", height: "2px", width: "46vh", marginTop: "-15px" }} />
                </Box>
                <Typography
                  id="modal-modal-title"
                  style={{ color: "#611f1e" }}
                  variant="h6"
                  component="h2"
                >
                  Are You sure you want to delete member <span className="text-secondary-new"> {selectedMember?.fullName ? selectedMember.fullName : selectedMember?.organisationName ? selectedMember?.organisationName : selectedMember?.institution ? selectedMember?.institution : ''} </span> ?
                </Typography>
                <div>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#dc3545",
                      border: "1px solid #dc3545",
                      padding: "5px 15px",
                      borderRadius: "0px",
                    }}
                    onClick={handleDeleteModalClose}
                  >
                    No
                  </Button>
                  <Button
                    className="mx-2"
                    variant="contained"
                    style={{
                      backgroundColor: "#2e7d32",
                      //  border: "1px solid #2e7d32",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "0px",
                    }}
                    onClick={() => handleDeleteMember()}
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>
            {value === 2 && <Box sx={{ width: "100%" }} className="mt-3">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <RejectTableHead />
                    <TableBody>
                      {rejection.length > 0 ? (
                        rejection.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="center">
                              {row.fullName || row.organisationName || row.institution || "---"}
                            </TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.mobileNumber}</TableCell>
                            <TableCell align="center">
                              {row.chapter?.length > 0 ? (
                                <ChapterName id={row.chapter} />
                              ) : (
                                <p className="text-dark">---</p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.sigGroups?.length > 0 ? (
                                row.sigGroups.map((sig, index) => (
                                  <span key={index}>
                                    <SigName id={sig} />
                                    {index < row.sigGroups.length - 1 && <br />}
                                  </span>
                                ))
                              ) : (
                                <p className="text-dark">---</p>
                              )}
                            </TableCell>
                            {/* <TableCell align="center">
                              {row.address}, {row.city}, {row.pinCode}.
                            </TableCell> */}
                            <TableCell align="center">
                              {row.rejectionReason ? (
                                <p
                                  onClick={() => handleOpen1(row)}
                                  className="text-primary text-decoration-underline"
                                >
                                  {row.rejectionReason.slice(0, 20)}...
                                </p>
                              ) : (
                                "-----"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton onClick={() => handleView(row)}>
                                <Tooltip title="View" placement="left">
                                  <VisibilityIcon style={{ color: '#ff8000' }} />
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {rejection.length > 0 &&
                <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        placeholder="Page no."
                        value={searchTerm}
                        // onChange={(e) => setSearchTerm(e.target.value)}
                        // onChange={(e) => setSearchTerm(Number(e.target.value - 1))}
                        onChange={(e) => {
                          const page = e.target.value ? Math.max(1, Number(e.target.value)) : "";
                          setSearchTerm(page);
                        }}
                        min={1}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>
                  <div className="my-3 my-lg-0">
                    {rejection.length > 0 && (
                      <ReactPaginate
                        pageCount={RejectPageCount}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        onPageChange={handleRejectPageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                        disabledClassName="disablebtn"
                        forcePage={rejectionMemberPage}
                        previousLabel={RejectPageCount > 1 && rejectionMemberPage >= 1 ? "Previous" : null}
                        nextLabel={RejectPageCount > 1 && rejectionMemberPage + 1 !== RejectPageCount ? "Next" : null}
                      />
                    )}
                  </div>
                  <div className="mb-2 mb-md-0">
                    <FormControl variant="outlined">
                      <InputLabel></InputLabel>
                      <Select
                        className="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => {
                          const value = e.target.value;
                          setItemsPerPage(value);
                          setError('')
                          setSearchTerm('');
                          setPage(0)
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              }
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                  <Container maxWidth={false} sx={{ px: 3 }}>
                    <Box className="d-flex justify-content-between pt-2">
                      <Typography variant="h5" className="fw-bold" sx={{ color: "#0d4022 !important", fontWeight: "bold" }}>
                        Rejection Reason
                      </Typography>
                      <IconButton onClick={handleClose} aria-label="Close">
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Container>
                  <Box display="flex" justifyContent="center" width="100%" className='mb-2'>
                    <Divider sx={{ bgcolor: "black", height: "2px", width: "45vh", marginTop: "-15px" }} />
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    style={{ color: "#611f1e" }}
                    className="w-100"
                    variant="h6"
                    component="h2"
                  >
                    {memberRejected?.rejectionReason}
                  </Typography>
                </Box>
              </Modal>
            </Box>}
            {value === 3 && <Box sx={{ width: "100%" }} className="mt-3">
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead />
                    <TableBody>
                      {notPaid.length > 0 ? (
                        notPaid.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="center">{row.userId}</TableCell>
                            <TableCell align="center">
                              {row.fullName || row.organisationName || row.institution || "---"}
                            </TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.mobileNumber}</TableCell>
                            <TableCell align="center">
                              {row.chapter?.length > 0 ? (
                                <ChapterName id={row.chapter} />
                              ) : (
                                <p className="text-dark">---</p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.sigGroups?.length > 0 ? (
                                row.sigGroups.map((sig, index) => (
                                  <span key={index}>
                                    <SigName id={sig} />
                                    {index < row.sigGroups.length - 1 && <br />}
                                  </span>
                                ))
                              ) : (
                                <p className="text-dark">---</p>
                              )}
                            </TableCell>
                            {/* <TableCell align="center">
                              {row.address}, {row.city}, {row.pinCode}.
                            </TableCell> */}
                            <TableCell align="center">
                              <IconButton onClick={() => handleView(row)}>
                                <Tooltip title="View" placement="left">
                                  <VisibilityIcon style={{ color: '#ff8000' }} />
                                </Tooltip>
                              </IconButton>
                              <IconButton onClick={() => handleOpenForm(row)}>
                                <Tooltip title="Add Member" placement="right">
                                  <PersonAddAltOutlinedIcon style={{ color: 'green' }} />
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Paper>
              <div className="d-flex justify-content-between flex-lg-row flex-column gx-3 gx-lg-0" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", marginBottom: "56px" }}>
                {notPaid.length > 0 &&
                  <div className="mb-2 mb-md-0">
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        placeholder="Page no."
                        value={searchTerm}
                        // onChange={(e) => setSearchTerm(e.target.value)}
                        // onChange={(e) => setSearchTerm(Number(e.target.value - 1))}
                        onChange={(e) => {
                          const page = e.target.value ? Math.max(1, Number(e.target.value)) : "";
                          setSearchTerm(page);
                        }}
                        min={1}
                        className="search-input"
                      />
                      <div className="goPage cursor-pointer" onClick={handlePageChange}>
                        <h5 className="p-2 m-0">Go</h5>
                      </div>
                    </div>
                    {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
                  </div>
                }
                <div className="my-3 my-lg-0">
                  {notPaid.length > 0 && (
                    <ReactPaginate
                      pageCount={NotPaidPageCount}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      onPageChange={handleNotPaidPageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      disabledClassName="disablebtn"
                      forcePage={notPaidMemberPage}
                      previousLabel={NotPaidPageCount > 1 && notPaidMemberPage >= 1 ? "Previous" : null}
                      nextLabel={NotPaidPageCount > 1 && notPaidMemberPage + 1 !== NotPaidPageCount ? "Next" : null}
                    />
                  )}
                </div>
                <div className="mb-2 mb-md-0">
                  <FormControl variant="outlined">
                    <InputLabel></InputLabel>
                    <Select
                      value={itemsPerPage}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemsPerPage(value);
                        setError('')
                        setSearchTerm('');
                        setPage(0)
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <Modal
                    open={open1}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                      <h4 style={{ color: "#104e29" }}>Add Member Form</h4>

                      <form onSubmit={handleSubmit(AddMember)}>
                         

                      </form>
                      <Button
                        variant="outlined"
                        style={{
                          color: "red",
                          border: "1px solid red",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Modal> */}
            </Box>}
            <Modal
              open={openForm}
              onClose={handleFormClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1} className="d-flex w-100 align-items-center flex-column gap-3">
                <h4 className="alata-regular fs-3 title-font" style={{ color: "#104e29" }}>{value === 0 ? 'Renew Member' : 'Add Member'}</h4>

                <form className="w-100 d-flex flex-column justify-content-between h-100" onSubmit={handleSubmitAdd(value === 0 ? renewMember : AddMember)}>
                  <Grid container rowSpacing={2} columnSpacing={3}>
                    <Grid item xs={12} md={6} lg={12} className="d-flex">
                      <span className="alata-regular fw-medium">Plan Name: &nbsp;</span>
                      <span className="alata-regular">{watchAdd('membershipPlan') && <GetPlanDetails id={watchAdd('membershipPlan')} typeId={watchAdd('membershipPlanType')} value={'plan'} />}</span>
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} className="d-flex">
                      <span className="alata-regular fw-medium">Plan Type:&nbsp; </span>
                      <span className="alata-regular">{watchAdd('membershipPlanType') && <GetPlanDetails id={watchAdd('membershipPlan')} typeId={watchAdd('membershipPlanType')} value={'planType'} />}</span>
                    </Grid>
                    <Grid item xs={12} md={6} lg={12} className="d-flex">
                      <span className="alata-regular fw-medium">Amount:&nbsp;</span>
                      <span className="alata-regular">{watchAdd('originalAmount') === watchAdd('amount') ? <>{watchAdd('currency') === "0" ? '₹' : '$'}{watchAdd('amount')}</> : <><span className="text-decoration-line-through">{watchAdd('currency') === "0" ? '₹' : '$'}&nbsp;&nbsp;{watchAdd('originalAmount')}</span>&nbsp;<span className="text-decoration-none text-secondary-new">{watchAdd('currency') === "0" ? '₹' : '$'}&nbsp;{watchAdd('amount')}</span> </>}
                      </span>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Controller
                        control={controlAdd}
                        rules={{ required: "Please select a payment type" }}
                        name="paymentType"
                        render={({ field }) => (
                          <FormControl fullWidth error={!!errorsAdd.paymentType}>
                            <InputLabel id="paymentType-label" className="text-dark" >
                              Payment Type <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="paymentType-label"
                              id="paymentType"
                              label='Payment Type *'
                              variant="outlined"
                              onChange={(e) => {
                                field.onChange(e.target.value)
                                setValueAdd('bankTransactionId', '')
                                nonPaidClearError()
                              }}
                            >
                              <MenuItem value="0">Cash</MenuItem>
                              <MenuItem value="1">Online</MenuItem>
                              <MenuItem value="2">Cheque</MenuItem>
                            </Select>
                            <FormHelperText>{errorsAdd?.paymentType?.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    {watchAdd('paymentType') === "2" && <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Cheque ID <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...registerAdd("bankTransactionId", {
                          required: watchAdd('paymentType') === "2" ? "Please enter Cheque ID" : false,
                        })}
                        error={!!errorsAdd.bankTransactionId}
                        helperText={errorsAdd?.bankTransactionId?.message}
                      />
                    </Grid>}
                    {watchAdd('paymentType') === "1" && <Grid item xs={12} md={6} lg={6}>
                      <Controller
                        control={controlAdd}
                        rules={{ required: "Please select a payment method" }}
                        name="paymentMethod"
                        render={({ field }) => (
                          <FormControl fullWidth error={!!errorsAdd.paymentMethod}>
                            <InputLabel id="paymentMethod-label" variant="outlined">
                              Payment Method <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="paymentMethod-label"
                              id="paymentMethod"
                              label='Payment Method *'
                              variant="outlined"
                            >
                              <MenuItem value="upi">UPI</MenuItem>
                              <MenuItem value="netbanking">Net Banking</MenuItem>
                              <MenuItem value="card">Card</MenuItem>
                            </Select>
                            <FormHelperText>{errorsAdd?.paymentMethod?.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>}
                    {watchAdd('paymentType') === "1" && <>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Transaction ID <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...registerAdd("bankTransactionId", {
                            required: watchAdd('paymentType') === "1" ? "Please enter Transaction ID" : false,
                          })}
                          error={!!errorsAdd.bankTransactionId}
                          helperText={errorsAdd?.bankTransactionId?.message}
                        />
                      </Grid>
                    </>
                    }

                  </Grid>
                  <div className="d-flex gap-3 justify-content-center mt-3 pb-3">
                    <Button
                      variant="outlined"
                      color="error"
                      className="rounded-0"
                      onClick={handleFormClose}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="menus-color"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>

                </form>

              </Box>
            </Modal>
          </>
        )}
      </Container>



    </>
  );
}
