import React, { useState, useEffect, useCallback } from 'react'
import './studentMember.css'
import { Box, Container, Avatar, Button, Modal, Typography, InputAdornment, FormControl, MenuItem, InputLabel, Select, Autocomplete, TextField, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { Link, useNavigate } from "react-router-dom";
import { selectUserId, selectCurrentScope, setImageUrl } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices';
import { useParams } from 'react-router-dom';
import { useLazyGetMemberShipDetailsByIdQuery } from '../../../app/services/memberShipServices'
import { useLazyGetChapterDetailsByIdQuery, useGetChapterBasedOnStateMutation } from '../../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../../app/services/SIGGroupServices'
import { useLazyGetAllSIGQuery, } from '../../../app/services/SIGServices'
import { Qualifications1, Salutation, planType } from '../../../constant/constant'
import { useUpdateMemberMutation, useLazyGetPendingMemberByIdQuery, } from '../../../app/services/memberListServices'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import TopHeader from "../../../Component/Header/TopHeader";
import { useForm, Controller } from "react-hook-form";
import EditIcon from '@mui/icons-material/Edit';
import { Country } from 'country-state-city';
import { useApproveRejectMemberMutation } from "../../../app/services/memberListServices";



const StudentMember = () => {
    const navigate = useNavigate();
    const userId = useSelector(selectUserId);

    //handleProfilePic()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
    const { id } = useParams();
    const [membershipbyId] = useLazyGetMemberShipDetailsByIdQuery()
    const [memberDetails, setMemberDetails] = useState()
    const [PriceDetails, setPriceDetails] = useState([])
    const [pricetype, setpriceType] = useState()
    const [chapter, setChapter] = useState()
    const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
    const [getSIGById] = useLazyGetSIGGroupByIdQuery();
    const [sig, setSig] = useState()
    const [imageUpdate, setImageUpdate] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageKey, setImageKey] = useState(0);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery()
    const dispatch = useDispatch();
    const [updateMember] = useUpdateMemberMutation()
    const [oldImage, setOldImage] = useState(selectedImage);
    const memberScope = useSelector(selectCurrentScope);
    const [open, setOpen] = useState(false)
    const [chapterBasedState] = useGetChapterBasedOnStateMutation();
    const [getAllSig] = useLazyGetAllSIGQuery()
    const [chapterState, setChapterState] = useState([])
    const [sigOpen, setSigOpen] = useState()
    const [inputValue, setInputValue] = React.useState("");
    const [allSig, setAllSig] = useState([])
    const [oldSig, setOldSig] = useState([])
    const [approveRejectMember] = useApproveRejectMemberMutation()
    const [rejectOpen, setRejectOpen] = useState()

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "5px",
        p: 4,
    };


    const {
        control,
        handleSubmit,
        // formState: { errors },
        setValue: setValueChapter,
        reset
    } = useForm({
        mode: "onChange",
        defaultValues: {
            chapter: chapter || "",
        },
    });

    const {
        control: controlsig,
        handleSubmit: handleSubmitSIG,
        setValue: setValueSig,
        // reset: sigReset
        //   formState: { errors: errorsBasic },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            // type: '0',
            sigGroups: []
        },
    });

    const {
        register: registerReject,
        getValues,
        watch,
        trigger,
        reset: resetreject,
        formState: { errors: rejecterror },
    } = useForm();

    const fetchMemberDetails = useCallback(async () => {
        try {
            const res = await getMemberById(id); // Ensure `id` is available in scope
            if (res?.data?.status) {
                setMemberDetails(res?.data?.data || {}); // Fallback to {} if undefined
                setPriceDetails(res?.data?.data?.plans || []); // Fallback to [] if undefined
            } else {
                console.error("Unexpected response format or status is false:", res);
                toast.error("Failed to fetch member details."); // Show error feedback
            }
        } catch (err) {
            console.error("Error fetching member details:", err);
            toast.error("An error occurred while fetching member details."); // Show error toast
        }
    }, [id, getMemberById]); // Memoize based on `id` and `getMemberById`

    useEffect(() => {
        fetchMemberDetails(); // Call the callback inside the effect
    }, [fetchMemberDetails]); // Depend on the memoized callback

    const getQualifications = (id) => {
        ;
        const course = Qualifications1.find(data => data.id === id);
        return course ? course.label : "-";
    };


    useEffect(() => {
        if (memberDetails?.chapter) {
            setValueChapter("chapter", memberDetails?.chapter, {
                shouldValidate: false,
                shouldDirty: false,
                shouldTouch: false,
            });
        }
        if (Array.isArray(memberDetails?.sigGroups) && memberDetails.sigGroups.length > 0) {
            setValueSig("sigGroups", memberDetails.sigGroups, {
                shouldValidate: false,
                shouldDirty: false,
                shouldTouch: false,
            });
        } else if (memberDetails?.sigGroups === undefined || memberDetails?.sigGroups === null) {
            setValueSig("sigGroups", [], { // Only set empty array when truly undefined/null
                shouldValidate: false,
                shouldDirty: false,
                shouldTouch: false,
            });
        }
        if (PriceDetails[PriceDetails.length - 1]?.planId) {
            membershipbyId(PriceDetails[PriceDetails.length - 1]?.planId).then((res) => {
                setpriceType(res?.data?.data);
            });
        }
        getChapterById(memberDetails?.chapter).then((res) => {
            if (res?.data?.status) {
                setChapter(res?.data?.data?.title)
            }
        }).catch(err => console.log(err))
        if (memberDetails?.sigGroups) {
            let groupPromises = memberDetails.sigGroups.map((id) =>
                getSIGById(id).then((res) => res?.data?.data?.groupName)
            );

            Promise.all(groupPromises).then((groupNames) => {
                setSig(groupNames);
            });
        }

    }, [memberDetails, PriceDetails, getSIGById, chapter, getChapterById, membershipbyId, setValueChapter, setValueSig]);

    useEffect(() => {
        if (pricetype && chapter && sig) {
        }
    }, [pricetype, sig, chapter]);

    useEffect(() => {
        const fetchData = async () => {
            const data = {
                state: memberDetails?.state,
                page: 1,
                itemsPerPage: '',
                searchTerm: ""
            };

            try {
                const res = await chapterBasedState(data);
                if (res?.data?.status) {
                    setChapterState(res?.data?.data?.chapterData
                    )
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [chapterBasedState, memberDetails]);

    useState(() => { }, [chapterState])

    useState(() => {
        const fetch = async () => {
            try {
                const res = await getAllSig()
                if (res?.data?.status) {
                    setAllSig(res?.data?.data)
                }
            }
            catch (err) {
                console.error(err)
            }

        }
        fetch()
    }, [])

    const handleOpen = () => {
        setOpen(true)
    }
    const handlerejctOpen = () => {
        setRejectOpen(true)
    }
    const handleSIGOpen = () => {
        setSigOpen(true)
    }

    // Reusable function to reset form values
    // Memoize the resetFormValues function using useCallback
    const resetFormValues = useCallback(() => {
        reset({
            chapter: memberDetails?.chapter || "",
            sigGroups: Array.isArray(memberDetails?.sigGroups)
                ? memberDetails.sigGroups
                : [],
        });
    }, [memberDetails, reset]);

    // Use the memoized resetFormValues in the useEffect hook
    useEffect(() => {
        resetFormValues();
    }, [resetFormValues]);


    const handleClose = () => {
        setOpen(false)
        setSigOpen(false)
        setRejectOpen(false)
        // sigReset()
        resetFormValues()
    }

    const updateChapterMember = (data) => {
        const chapterData = {
            isChangeChapterNetwork: true,
            oldChapter: memberDetails?.chapter || "",
            chapter: data.chapter,
        };


        const formData = new FormData();
        formData.append("id", id);
        formData.append("isChangeChapterNetwork", chapterData.isChangeChapterNetwork);
        formData.append("oldChapter", chapterData.oldChapter);
        formData.append("chapter", chapterData.chapter);

        const updateData = {
            id: id,
            data: formData,
        };
        const fetch = async () => {
            try {
                const res = await updateMember(updateData); // Ensure the function is awaited
                if (res?.data?.status) { // Adjust condition if necessary
                    fetchMemberDetails()
                    toast.success(res?.data?.message);
                    setOpen(false);
                    // reset();
                } else {
                    console.log("Status not true or missing in response.");
                }
            } catch (err) {
                console.error("Error in updateMember:", err); // Log any errors
            }
        };

        fetch();
    };

    const updateSIGMember = (data) => {
        const sigData = {
            isChangeSigNetwork: true,
            oldSigGroups: Array.isArray(oldSig) && oldSig.length > 0
                ? oldSig.filter(Boolean) // Remove undefined values
                : [""], // Ensure an empty string if it's empty
            sigGroups: Array.isArray(data.sigGroups) && data.sigGroups.length > 0
                ? data.sigGroups
                : [""], // Ensure an empty string if empty
        };

        const formData = new FormData();
        formData.append("id", id);

        Object.entries(sigData).forEach(([key, value]) => {
            if (key !== 'sigGroups' && key !== 'oldSigGroups' && value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });

        // **Fix: Ensure `sigGroups` is always appended correctly**
        if (sigData.sigGroups.length > 0) {
            sigData.sigGroups.forEach((item, index) => {
                formData.append(`sigGroups[${index}]`, item);
            });
        } else {
            formData.append("sigGroups", ""); // Set explicitly to an empty string
        }

        // **Fix: Ensure `oldSigGroups` is always appended correctly**
        if (sigData.oldSigGroups.length > 0) {
            sigData.oldSigGroups.forEach((item, index) => {
                formData.append(`oldSigGroups[${index}]`, item);
            });
        } else {
            formData.append("oldSigGroups", ""); // Explicitly set to an empty string
        }
        // **Async API call to update member**
        const fetch = async () => {
            try {
                const res = await updateMember({ id, data: formData }); // Await the API call


                if (res?.data?.status) {
                    fetchMemberDetails();
                    toast.success(res?.data?.message);
                    setSigOpen(false);
                    // reset();
                } else {
                    console.log("Status not true or missing in response.");
                    setSigOpen(false);
                    // reset();
                }
            } catch (err) {
                console.error("Error in updateMember:", err);
                setSigOpen(false);
                // reset();
            }
        };

        fetch();
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    const calculateAge = (isoDate) => {
        const birthDate = new Date(isoDate);
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If current month is before birth month or it's the birth month but current day is before birth day, subtract one year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    // Update width on window resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //to approve are reject the member in admin side
    const handleApproveReject = async (type) => {
        if (type === 1) {
            const isValid = await trigger("rejectionReason"); // Trigger validation manually
            if (!isValid) {
                return; // Do not proceed if validation fails
            }
        }

        const rejectionReason = getValues("rejectionReason");
        const data = {
            membersId: [id],
            type,
            ...(type === 1 && { rejectionReason }),
        };

        approveRejectMember(data)
            .then((res) => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message);
                    handleClose();
                    if (type === 1) {
                        resetreject({ rejectionReason: "" }); // Clear the input field
                    }
                    navigate("/admin/allMemberList")

                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };


    const handleFormNavigation = (key) => {
        // navigate(`/member/studentsProfile/${key}/${userId}`);
        // navigate(`/admin/studentsProfile/${key}/${userId}`);
        if (memberScope === "PORTAL_MEMBER") {
            navigate(`/member/studentsProfile/${key}/${userId}`);
        } else {
            navigate(`/admin/studentsProfile/${key}/${memberDetails._id}`);
        }
    };

    const getSalutation = (id) => {
        const salutation = Salutation.find(data => data?.id === id)
        return salutation ? salutation?.Name : "-"
    }

    const handleProfilePic = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        setImageUpdate(file);  // Set the file in state
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);  // Update the image preview
            setImageKey(prevKey => prevKey + 1);  // Re-render the image with a new key
            setShowUploadButton(true);  // Show the upload button after file selection
        };
        reader.readAsDataURL(file);  // Read the file for preview
    };


    const planTypeIdShow = (planTypeId) => {
        const plan = pricetype?.planType?.find((data) => data?._id === planTypeId);
        return plan ? plan?.planTypeName : "Unknown Plan";
    };

    const handleUpload = () => {
        setOldImage(selectedImage);
        const formData = new FormData();
        formData.append("imageUrl", imageUpdate);
        formData.append("_id", memberDetails._id);

        updateMember({ id: memberDetails._id, data: formData })
            .then((res) => {
                if (res?.data?.status) {
                    setShowUploadButton(false)
                    toast.success("Image Uploaded Successfully!!!");
                } else {
                    setShowUploadButton(false)
                    toast.error(res?.data?.message)
                    setSelectedImage(oldImage);
                }
                if (res.data.status) {
                    return getPendingMemberById(memberDetails?._id);
                } else {
                    setSelectedImage(oldImage)
                    throw new Error("Image upload failed");
                }
            })
            .then((memberRes) => {
                const memberData = memberRes?.data?.data;

                if (memberData) {
                    dispatch(setMemberDetails(memberData));

                    if (memberScope === "PORTAL_MEMBER") {
                        dispatch(setImageUrl({ imageUrl: memberRes?.data?.data?.imageUrl }));
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching member details:", error);
                setSelectedImage(oldImage);
                // Optionally, show a notification to the user
            });

    };

    const handleIconClick = () => {
        document.getElementById("uploadImage").click();
    };
    const planTypeShow = (id) => {
        const plan = planType?.find((data) => data?.planId === id);
        return plan ? plan?.planName : "Unknown Plan";
    };

    //Getting country full name
    const countries = Country.getAllCountries();
    const countryName = countries.find(item => item.isoCode === memberDetails?.country)?.name;

    const memberPage = () => {
        navigate("/admin/allMemberList")
    }

    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box>
                {/* <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Member Profile (Individual Student)</h2>
                        </div>
                    </div>
                </div> */}
                <Box>
                    <TopHeader />
                    <div className="pt-5 mb-4">
                        <div className="chapter-heading">
                            <h2 className="text-center title-font text-light py-3 mt-1"> Member Profile - Individual Student</h2>
                        </div>
                    </div>
                </Box>
            </Box>
            <Container maxWidth="lg" className='pb-2'>
                {memberScope === "PORTAL_SUPER_ADMIN" ?
                    <div className='w-100 text-end '>
                        <Button
                            onClick={() => memberPage()}
                            variant="contained"
                            className="menus-color"
                        >
                            Back
                        </Button>
                    </div> : <></>}
                <div className='d-flex justify-content-between  mt-5 mb-0 ms-3'>
                    <p className='signika-font fw-semibold teacher-member-header '>BASIC DETAILS:</p>
                    {memberScope === "PORTAL_MEMBER" &&
                        <Link to={`cardpdf-converter`}>
                            <p className='signika-font fw-semibold link-pdf-member '>
                                View Membership Card PDF
                            </p>
                        </Link>}
                </div>
                <div className='card teacher-member-card'>
                    {/* <div className='d-flex justify-content-end'>
                        
                            </div> */}
                    <div className='row'>
                        <div className='col-11'>
                            <div className='row'>
                                <div className='col-md-2 m-3 d-flex align-items-center justify-content-lg-start justify-content-center '>
                                    <div className='teacher-avatar-containter'>
                                        {memberDetails?.isDeleted !== 2 && (
                                            !showUploadButton && (
                                                <div className="techer-member-image-editIcon" onClick={handleIconClick}>
                                                    <CreateIcon sx={{ fontSize: '10px' }} />
                                                </div>
                                            )
                                        )}



                                        <input
                                            accept="image/jpeg, image/png"
                                            style={{ display: "none" }}
                                            id="uploadImage"
                                            type="file"
                                            onChange={handleProfilePic}  // Handle file selection
                                        />

                                        {selectedImage ? (
                                            <Avatar
                                                key={imageKey}
                                                alt="Member Logo"
                                                className="member-avatar"
                                                src={selectedImage}
                                                sx={{ width: 100, height: 100 }}
                                                onClick={handleIconClick}
                                            />
                                        ) : (
                                            <Avatar
                                                key={imageKey}
                                                alt="Member Logo"
                                                className="member-avatar"
                                                onClick={handleIconClick}
                                                src={memberDetails?.imageUrl}
                                                sx={{ width: 100, height: 100 }}
                                            />
                                        )}

                                        {showUploadButton && (
                                            <Button
                                                variant="contained"
                                                style={{ marginTop: "20px" }}
                                                onClick={handleUpload}
                                                className='menus-color'
                                            >
                                                Upload
                                            </Button>
                                        )}
                                    </div>

                                </div>
                                <div className='col-md-7  mt-3 text-center text-md-start'>
                                    <p className='signika-font fw-semibold mt-4'>{getSalutation(memberDetails?.salutation)}. {memberDetails?.fullName}</p>
                                    <p className='signika-font'>{memberDetails?.userId}</p>
                                    <p className='signika-font'>{formatDate(memberDetails?.dateOfBirth)}</p>
                                    <p className='signika-font'>{calculateAge(memberDetails?.dateOfBirth)} Year  - {(memberDetails?.gender === 0) ? "Male" : "Female"}</p>
                                </div>

                            </div>
                        </div>
                        <div className='col-1 p-2'>
                            {memberDetails?.isDeleted === 2 ? <></> : <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("basic")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>}
                        </div>
                    </div>

                </div>
                <p className='signika-font fw-semibold mt-5 ms-3 mb-0'>CONTACT DETAILS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                            <Container maxWidth="lg">
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        {/* <div className='row'>
                                            <div className='col-4'>
                                                <p className='signika-font fw-semibold'>Address</p>
                                                <p className='signika-font fw-semibold mt-4'>City / Town</p>
                                                <p className='signika-font fw-semibold mt-4'>Pin Code</p>
                                                <p className='signika-font fw-semibold mt-4'>State</p>
                                                <p className='signika-font fw-semibold mt-4'>Country</p>
                                            </div>
                                            <div className='col-8 address-container'>
                                                <p className='signika-font'><span className='me-3' style={{ whiteSpace: "nowrap" }}>:</span>{memberDetails?.address}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.city}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.pinCode}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.state}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{countryName}</p>
                                            </div>
                                        </div> */}

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="contact-details-grid">
                                                    <p className="label signika-font fw-semibold">Address</p>
                                                    <p className="value signika-font"><span className='me-3'>:</span>{memberDetails?.address}</p>

                                                    <p className="label signika-font fw-semibold">City / Town</p>
                                                    <p className="value signika-font"><span className='me-3'>:</span>{memberDetails?.city}</p>

                                                    <p className="label signika-font fw-semibold">Pin Code</p>
                                                    <p className="value signika-font"><span className='me-3'>:</span>{memberDetails?.pinCode}</p>

                                                    <p className="label signika-font fw-semibold">State</p>
                                                    <p className="value signika-font"><span className='me-3'>:</span>{memberDetails?.state}</p>

                                                    <p className="label signika-font fw-semibold">Country</p>
                                                    <p className="value signika-font"><span className='me-3'>:</span>{(countryName === undefined ? memberDetails?.country : countryName)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <p className='signika-font fw-semibold'>
                                                    {windowWidth > 513 ? (
                                                        <p>Mobile Number</p>
                                                    ) : (
                                                        <p>Mobile</p>
                                                    )}
                                                </p>
                                                <p className='signika-font fw-semibold mt-4 '>
                                                    {windowWidth > 513 ? (
                                                        <p>WhatsApp Number</p>
                                                    ) : (
                                                        <p>whatsApp</p>
                                                    )}
                                                </p>
                                                <p className='signika-font fw-semibold mt-4'>Email Id</p>
                                                <p className='signika-font fw-semibold mt-4'>
                                                    {windowWidth > 513 ? (
                                                        <p>Telephone Number</p>
                                                    ) : (
                                                        <p>Telephone</p>
                                                    )}
                                                </p>
                                                {/* <p className='signika-font fw-semibold mt-4'>Contact Information Visibility</p> */}
                                            </div>
                                            <div className='col-8'>
                                                <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.mobileNumber}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.whatsAppNumber}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.email}</p>
                                                <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.landlineNumber ? memberDetails?.landlineNumber : '-'}</p>
                                                {/* <p className='signika-font mt-4'><span className='me-3'>:</span>
                                                    Which contact information would
                                                    you like to share with other
                                                    members? (Please select all that
                                                    apply)
                                                </p> */}
                                                {/* <p className='signika-font  mt-4'>
                                                    <div className='row'>

                                                        {memberDetails ? (memberDetails?.contactInformation && memberDetails.contactInformation.map((data, i) => (
                                                            <div key={i} className='col-md-6 col-sm-12'>
                                                                <ul className='signika-font'>
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))) : "-"
                                                        }
                                                    </div>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="col-1 p-2">
                            {memberDetails?.isDeleted !== 2 && <Button variant='outlined' className='my-1 edit-button-profile-membership ' onClick={() => handleFormNavigation("contact")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>}
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 ms-3 mb-0'>YOUR ACADEMIC PROFILE LINKS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                            <Container maxWidth="lg">
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your Google Scholar Profile</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scholarProfile ? memberDetails?.scholarProfile : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your Scopus Profile link</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scopusProfileLink ? memberDetails?.scopusProfileLink : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your LinkedIn Profile</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.linkedInProfile ? memberDetails?.linkedInProfile : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your ORICID id</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.ORICID ? memberDetails.ORICID : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your blog / website</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.blog ? memberDetails?.blog : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your Youtube channel</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.youtubeChannel ? memberDetails?.youtubeChannel : "-"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Consent to Share Information</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>
                                                Do you
                                                consent to having your academic profile
                                                shared with other ELTAI members so
                                                they can search, find, and contact you for
                                                delivering talks, writing collaborative
                                                research papers, and other professional
                                                opportunities?
                                            </p>
                                            <ul className='list-unstyled'>
                                                <li className='ms-4'>{memberDetails?.shareInformation !== null ? (memberDetails?.shareInformation === 1 ? "Yes" : "No") : "-"}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Container>

                        </div>
                        <div className="col-1 p-2">
                            {memberDetails?.isDeleted !== 2 && <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("academic")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>}
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header ms-3 mt-5 mb-0'>Membership Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row p-2'>
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Date of first becoming a member</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[PriceDetails.length - 1]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Type of Current Membership</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{planTypeShow(pricetype?.planName)}({planTypeIdShow(PriceDetails[PriceDetails?.length - 1]?.planTypeId)})</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid from</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[PriceDetails.length - 1]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid till</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>  {formatDate(PriceDetails[PriceDetails.length - 1]?.dummyEndDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Payment UTR Number</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>  {PriceDetails[PriceDetails?.length - 1]?.bankTransactionId ? PriceDetails[PriceDetails?.length - 1]?.bankTransactionId : '-'}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Payment Detail</p>
                                    </div>
                                    {/* <div className='col-sm-8 m-0'>
                                        <p className='signika-font mb-0'><span className='me-3'>:</span>{PriceDetails[PriceDetails.length - 1]?.isPaid === 1 ? "Paid" : "Unpaid"}
                                            <uispan className='me-3'>
                                                <li className={`${(PriceDetails?.at(-1)?.paymentMethod === 'upi') ? "text-uppercase" : 'text-capitalize'}`} >
                                                    {PriceDetails?.at(-1)?.paymentMethod ? PriceDetails?.at(-1)?.paymentMethod : PriceDetails?.at(-1)?.paymentType === 0 ? 'Cash' : 'Cheque'}
                                                </li>
                                                {(PriceDetails[PriceDetails.length - 1]?.dummyStartDate) && (
                                                    <li>{PriceDetails[PriceDetails.length - 1]?.dummyCurrency === 1 ? "$" : "₹"}{PriceDetails[PriceDetails.length - 1]?.dummyAmount}</li>
                                                )}
                                            </uispan>
                                        </p>
                                    </div> */}
                                    <div className='col-sm-8 m-0'>
                                                <p className='signika-font mb-0'><span className='me-3'>:</span>{PriceDetails[PriceDetails.length - 1]?.isPaid === 1 ? "Paid" : "Unpaid"}
                                                    <span className='d-flex flex-column ms-3' >
                                                        <p className={`${(PriceDetails?.at(-1)?.paymentMethod === 'upi') ? "text-uppercase" : 'text-capitalize'} mb-0`} >
                                                        • {PriceDetails?.at(-1)?.paymentMethod ? PriceDetails?.at(-1)?.paymentMethod : PriceDetails?.at(-1)?.paymentType === 0 ? 'Cash' : 'Cheque'}
                                                        </p>
                                                        <p className='mb-0'>• {formatDate(PriceDetails[PriceDetails.length - 1]?.dummyStartDate)}</p>
                                                        <p className='mb-0'>• {PriceDetails[PriceDetails.length - 1]?.dummyCurrency === 1 ? "$" : "₹"}{PriceDetails[PriceDetails.length - 1]?.dummyAmount}</p>

                                                    </span>
                                                    {/* </div> */}
                                                </p>
                                            </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 ms-3 mb-0'>Your Network Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className='col-11'>
                            <div className='row p-2'>
                                <Container maxWidth="lg">
                                    <div className='row'>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <p className='signika-font fw-semibold mb-0'>Which chapter of ELTAI would
                                                    you like to be part of?&nbsp;&nbsp;{memberDetails?.isDeleted !== 2 && memberScope === "PORTAL_SUPER_ADMIN" ? (<span className='edit-button-green curser-to-pointer'><Tooltip title="Edit"><EditIcon onClick={handleOpen} style={{ fontSize: '16px' }} /></Tooltip></span>) : <></>}</p>
                                                {memberScope === "PORTAL_MEMBER" ? (
                                                    <Link
                                                        style={{ color: "darkgreen" }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to="/member/view-Chapter"
                                                    >
                                                        Click here for Chapter
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        style={{ color: "darkgreen" }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to="/admin/view-Chapter"
                                                    >
                                                        Click here for Chapter
                                                    </Link>
                                                )}
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box

                                                        className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            backgroundColor: 'white',
                                                            boxShadow: 24,
                                                            padding: "20px",
                                                            outline: 'none',
                                                        }}
                                                    >
                                                        <Typography
                                                            id="modal-modal-title"
                                                            style={{ color: "#104e29" }}
                                                            variant="h6"
                                                            component="h2"
                                                        >
                                                            Chapter
                                                        </Typography>
                                                        <form className='w-100' onSubmit={handleSubmit(updateChapterMember)}>
                                                            <Controller
                                                                control={control}
                                                                name="chapter"
                                                                rules={{ required: "Please select a chapter." }}

                                                                render={({ field, fieldState }) => (
                                                                    <FormControl fullWidth error={!!fieldState.error}
                                                                        sx={{
                                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                                color: 'black', // Custom label color on focus
                                                                            }
                                                                        }}
                                                                    >
                                                                        <InputLabel id="chapter-label">Which chapter of ELTAI would you like to be part of?</InputLabel>
                                                                        <Select
                                                                            labelId="chapter-label"
                                                                            id="chapter-select"
                                                                            label='Which chapter of ELTAI would you like to be part of?'
                                                                            value={chapterState.length === 0 ? "no-active-chapter" : field.value || ""}
                                                                            onChange={(e) => field.onChange(e.target.value)}
                                                                            className="bg-white"
                                                                            variant="outlined"
                                                                            disabled={chapterState.length === 0}
                                                                            fullWidth
                                                                        >
                                                                            {/* <MenuItem value="I will join a chapter later">
                                                                                I will join a chapter later
                                                                            </MenuItem> */}
                                                                            {chapterState.length === 0 ? (
                                                                                <MenuItem disabled value="no-active-chapter">No chapters available</MenuItem>
                                                                            ) : (
                                                                                chapterState.map((data) => (
                                                                                    <MenuItem key={data._id} value={data._id}>
                                                                                        {data.title}
                                                                                    </MenuItem>
                                                                                ))
                                                                            )}
                                                                        </Select>
                                                                        {fieldState.error && (
                                                                            <Typography variant="body2" color="error">
                                                                                {fieldState.error.message}
                                                                            </Typography>
                                                                        )}
                                                                    </FormControl>
                                                                )}
                                                            />
                                                            <div className='d-flex justify-content-center gap-2'>
                                                                <Button variant="outlined" color="error" className="  mt-3 rounded-0" onClick={handleClose}>
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    className="menus-color mt-3 rounded-0"
                                                                    disabled={chapterState.length === 0}
                                                                    sx={{
                                                                        color: chapterState.length === 0 && "white",
                                                                        backgroundColor: chapterState.length === 0 ? "#b0b0b0 !important" : "",
                                                                    }}
                                                                >
                                                                    Update
                                                                </Button>
                                                            </div>

                                                        </form>

                                                    </Box>
                                                </Modal>
                                            </div>
                                            <div className='col-sm-8'>
                                                <p className='signika-font d-flex'><span className='me-3'>:</span>
                                                    {chapter ? chapter : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <p className='signika-font fw-semibold mb-0'>Which  Special Interest Group (SIG) of ELTAI would
                                                    you like to join?&nbsp;&nbsp;{memberDetails?.isDeleted !== 2 && memberScope === "PORTAL_SUPER_ADMIN" ? (<span className='edit-button-green curser-to-pointer'><Tooltip title="Edit"><EditIcon onClick={handleSIGOpen} style={{ fontSize: '16px' }} /></Tooltip></span>) : <></>}</p>
                                                {memberScope === "PORTAL_MEMBER" ? (
                                                    <Link
                                                        style={{ color: "darkgreen" }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to="/member/addEditSigGroups"
                                                    >
                                                        Click here for SIG
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        style={{ color: "darkgreen" }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        to="/admin/addEditSigGroups"
                                                    >
                                                        Click here for SIG
                                                    </Link>
                                                )}
                                                <Modal
                                                    open={sigOpen}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box

                                                        className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            backgroundColor: 'white',
                                                            boxShadow: 24,
                                                            padding: "20px",
                                                            outline: 'none',
                                                        }}
                                                    >
                                                        <Typography
                                                            id="modal-modal-title"
                                                            style={{ color: "#104e29" }}
                                                            variant="h6"
                                                            component="h2"
                                                        >
                                                            SIG
                                                        </Typography>
                                                        <form className='w-100' onSubmit={handleSubmitSIG(updateSIGMember)}>
                                                            <Controller
                                                                control={controlsig}
                                                                name="sigGroups"
                                                                render={({ field, fieldState }) => {
                                                                    return (
                                                                        <Autocomplete
                                                                            multiple
                                                                            id="tags-outlined"
                                                                            limitTags={2}
                                                                            options={allSig ? allSig : ["Please Slect"]}
                                                                            name={field.name}
                                                                            getOptionLabel={(option) => {
                                                                                return option ? option.groupName : "";
                                                                            }}
                                                                            filterSelectedOptions
                                                                            inputValue={inputValue}
                                                                            onChange={(event, values, reason, details) => {
                                                                                const selectedIds = values.map((value) => value._id); // Extract _id from selected options
                                                                                // field.onChange(selectedIds); // Pass array of selected _ids to onChange
                                                                                if (selectedIds.length >= 1) {
                                                                                    field.onChange(selectedIds);
                                                                                }
                                                                                else {
                                                                                    field.onChange("")
                                                                                }
                                                                                if (reason === 'removeOption') {
                                                                                    const removedId = details?.option?._id || ""; // Ensure empty string instead of undefined
                                                                                    setOldSig((prev) => [...prev, removedId].filter(Boolean)); // Remove undefined values
                                                                                }
                                                                            }}

                                                                            getOptionSelected={(option, value) => option._id === value._id}
                                                                            onInputChange={(_, newInputValue) =>
                                                                                setInputValue(newInputValue)
                                                                            }
                                                                            value={
                                                                                field.value
                                                                                    ? field.value.map((id) =>
                                                                                        allSig.find((group) => group._id === id)
                                                                                    )
                                                                                    : []
                                                                            }
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    error={!!fieldState?.invalid}
                                                                                    {...params}
                                                                                    name="sigGroups"
                                                                                    label="SIG Groups"
                                                                                    placeholder="Select SIGs You are Interested in"
                                                                                    helperText={fieldState?.error?.message}
                                                                                />
                                                                            )}
                                                                        />
                                                                    );
                                                                }}
                                                            />

                                                            <div className='d-flex justify-content-center gap-2'>
                                                                <Button variant="outlined" color="error" className="  mt-3 rounded-0" onClick={handleClose}>
                                                                    Cancel
                                                                </Button>
                                                                <Button type="submit" variant="contained" className="menus-color  mt-3 rounded-0">
                                                                    Update
                                                                </Button>
                                                            </div>
                                                        </form>

                                                    </Box>
                                                </Modal>
                                            </div>
                                            <div className='col-sm-8'>
                                                <p className='signika-font d-flex'>
                                                    <span className='me-3'>:</span>
                                                    {sig && sig.length > 0 ? (
                                                        <ul >
                                                            {sig.map((data, i) => (
                                                                <li key={i}>{data}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p className="no-bullet">-</p>
                                                    )}

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                        <div className="col-1 p-2 ">
                            {/* {memberDetails?.isDeleted === 2 ? <></> : <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("netWork")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>} */}
                        </div>
                    </div>

                </div>
                <p className='signika-font fw-semibold  teacher-member-header mt-5 ms-3 mb-0'>Educational Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                            <Container maxWidth="lg">
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>What are you studying at
                                                present?</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font mb-1'><span className='me-3'>:</span>
                                                {getQualifications(memberDetails?.studying)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Have you got through any
                                                teacher eligibility examinations?</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'>
                                                <div className='col-3'></div>
                                                <span className='me-3'>:</span>{memberDetails?.eligibilityExamination?.length > 0 ? <>(Select all that apply)</> : <>(Select all that apply)   </>}
                                                <div className='row'>
                                                    {memberDetails?.eligibilityExamination?.length > 0 ? (
                                                        memberDetails.eligibilityExamination.map((data, i) => (
                                                            <div className='col-md-2 col-sm-12' key={i}>
                                                                <ul className='signika-font'>
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <span>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Name of your Institution</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.institution}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Address of your Institution</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font ml-3'><span className='me-3'>:</span>
                                                {memberDetails?.institutionCity ? memberDetails?.institutionCity : '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="col-1 p-2">
                            {memberDetails?.isDeleted !== 2 && <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("education")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>}
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 ms-3 mb-0'>Interest in your Professional Development</p>
                <div className='card teacher-member-card mb-3 '>
                    <div className='row'>
                        <div className="col-11 p-2">
                            <Container maxWidth="lg">
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Are you a member of any other
                                                Teachers’ Association?</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font mb-1'><span className='me-3'>:</span>{memberDetails?.teacherAssociation === 1 ? "Yes" : "No"}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>If yes, mention the name of those
                                                associations</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{(memberDetails?.teacherAssociation === 1) ? memberDetails?.associationName : "-"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Which of the following would
                                                you like to do for your
                                                continuous professional
                                                development?</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>(Select all that apply)</p>
                                            <div className='row'>
                                                {
                                                    memberDetails?.professionalDevelopment?.length ? (
                                                        memberDetails.professionalDevelopment.map((data, i) => (
                                                            <div className="col-md-4 col-sm-12" key={i}>
                                                                <ul className="signika-font">
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : ""
                                                }


                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Your Areas of Expertise</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>
                                                (Select all the areas in which you
                                                teach courses, publish research
                                                papers, and deliver talks)
                                            </p>
                                            <p className='signika-font ms-4 fw-bold'>Literary Studies</p>
                                            <div className='row ms-3'>
                                                {
                                                    memberDetails?.expertiseLiteraryStudies?.length > 0 ? (
                                                        memberDetails.expertiseLiteraryStudies.map((data, i) => (
                                                            <div className="col-md-4 col-sm-12" key={i}>
                                                                <ul className="signika-font">
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span className='ms-3'>-</span>
                                                    )
                                                }

                                            </div>
                                            <p className='fw-bold signika-font ms-4'>Linguistics</p>
                                            <div className='row ms-3'>
                                                {
                                                    memberDetails?.expertiseELT?.length > 0 ? (
                                                        memberDetails.expertiseLinguistics.map((data, i) => (
                                                            <div className="col-md-4 col-sm-12" key={i}>
                                                                <ul className="signika-font">
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span className='ms-3'>-</span>
                                                    )
                                                }
                                            </div>
                                            <p className='fw-bold signika-font ms-4'>English Language Teaching (ELT)</p>
                                            <div className='row ms-3'>
                                                {
                                                    memberDetails?.expertiseELT?.length > 0 ? (
                                                        memberDetails.expertiseELT.map((data, i) => (
                                                            <div className="col-md-4 col-sm-12" key={i}>
                                                                <ul className="signika-font">
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span className='ms-3'>-</span>
                                                    )
                                                }
                                            </div>
                                            <p className='fw-bold ms-4 signika-font '>Interdisciplinary Areas</p>
                                            <div className='row ms-3'>
                                                {
                                                    memberDetails?.expertiseInterdisciplinaryAreas?.length ? (
                                                        memberDetails.expertiseInterdisciplinaryAreas.map((data, i) => (
                                                            <div className="col-md-4 col-sm-12" key={i}>
                                                                <ul className="signika-font">
                                                                    <li>{data}</li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span className='ms-3'>-</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <p className='signika-font fw-semibold'>Areas of Expertise</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>
                                                Are you
                                                willing to have your areas of
                                                expertise or interest listed publicly
                                                so that other members can identify
                                                potential collaboration
                                                opportunities?
                                                <ul className='list-unstyled'><li className='ms-4'>{memberDetails?.areaOfExpertise !== null ? (memberDetails?.areaOfExpertise === 1 ? "Yes" : "No") : "-"}</li></ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className="col-1 p-2">
                            {memberDetails?.isDeleted !== 2 && <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("profession")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>}
                        </div>
                    </div>
                </div>
                {memberDetails?.isDeleted === 1 && (
                    <div className='approve text-end'>
                        <div >
                            <button className="btn btn-danger" onClick={handlerejctOpen}>Reject</button>
                            <button className="btn btn-primary ms-2" onClick={() => handleApproveReject(0)} style={{ backgroundColor: "#611f1e", border: 'none' }}>Approve</button>
                        </div>
                    </div>
                )}
            </Container>
            <p className='text-center signika-font fs-6 pb-3 mb-0'>
                <span className='fw-bold '>Withdrawal of Consent:</span>  Please note that you can withdraw your consent to share your information at any
                time by updating your privacy settings in your profile.
            </p>

            <Modal
                open={rejectOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                    <Typography variant="h4" sx={{ color: "#104e29" }}>
                        Rejection Reason
                    </Typography>
                    <TextField
                        fullWidth
                        inputProps={{ maxLength: 300 }}
                        label={
                            <Typography className="text-dark">
                                Reason For Rejection <span style={{ color: "red" }}>*</span>
                            </Typography>
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {300 - (watch("rejectionReason")?.length || 0)}
                                </InputAdornment>
                            ),
                        }}
                        error={!!rejecterror?.rejectionReason}
                        helperText={rejecterror?.rejectionReason?.message}
                        {...registerReject("rejectionReason", {
                            required: "Reason for rejection is required.",
                            maxLength: {
                                value: 300,
                                message: "Reason cannot exceed 300 characters.",
                            },
                        })}
                    />
                    <Box className="mt-3">
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            className="mx-2"
                            variant="contained"
                            color="success"
                            onClick={() => handleApproveReject(1)}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}


export default StudentMember

