import React, { useEffect, useState } from 'react';
import TopHeader from '../../../Component/Header/TopHeader';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, TextField, InputAdornment, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCreateAssistantAdminMutation, useUpdateAssistantAdminMutation, useLazyGetAssistantAdminByIdQuery } from '../../../app/services/assistantAdmin';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const AddEditAssistantAdmin = () => {
    const [createAssistantAdmin] = useCreateAssistantAdminMutation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [getAssistantAdminById] = useLazyGetAssistantAdminByIdQuery();
    const [updateAssistantAdmin] = useUpdateAssistantAdminMutation();
    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        control,
        setValue,
        reset,
        handleSubmit,
        getValues,
        formState: { errors },
        setError
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: 'PORTAL_ADMIN'
        }
    });

    useEffect(() => {
        if (id) {
            const fetchAssistantAdmin = async () => {
                try {
                    const res = await getAssistantAdminById(id).unwrap();
                    if (res?.status) {
                        Object.entries(res?.data).forEach(([key, value]) => setValue(key, value));
                    }
                } catch (err) {
                    console.error("Error fetching assistant admin:", err);
                }
            };
            fetchAssistantAdmin();
        }
    }, [id, setValue, getAssistantAdminById]);

    const onSaveAssistantAdmin = async (data) => {
        try {
            if (id) {
                const res = await updateAssistantAdmin({ id, data }).unwrap();
                if (res?.status) {
                    toast.success("Assistant Admin Updated Successfully");
                    navigate('/admin/assistantAdmin');
                } else {
                    console.log('hi1');
                    toast.error(res?.err?.message);
                }
            } else {
                const res = await createAssistantAdmin(data).unwrap();
                if (res?.status) {
                    toast.success(res?.message);
                    navigate('/admin/assistantAdmin');
                    reset();
                } else {
                    toast.error(res?.err?.message);
                    if (res?.err?.code === 999) {
                        setError("email", { type: "manual", message: res?.err?.message });
                    }
                }
            }
        } catch (err) {
            toast.error(err?.message || "Something went wrong!");
        }
    };

    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">  {id ? "Edit Assistant Admin" : "Add Assistant Admin"}</h2>
                </div>
            </div>

            {/* Add and Edit Assistant Form Starts */}
            <form className='mt-5' onSubmit={handleSubmit(onSaveAssistantAdmin)}>
                <div className='p-md-5 p-3 overallPadding'>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button onClick={() => navigate(-1)} variant='contained' style={{ color: 'white', textTransform: "uppercase" }} className="menus-color">Back</Button>
                    </Box>

                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextField fullWidth InputLabelProps={{ shrink: true }}
                                label={
                                    <Typography className="text-dark">
                                        Assistant Admin Name<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                placeholder="Assistant Admin Name"
                                {...register("name", {
                                    required: "Please Enter a Assistant Admin Name",
                                    minLength: { value: 3, message: "Assistant Admin name must be at least 3 characters long" }
                                })} error={!!errors?.name} helperText={errors?.name?.message} />


                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth InputLabelProps={{ shrink: true }}
                                label={
                                    <Typography className="text-dark">
                                        Mobile Number<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                placeholder="Mobile Number"
                                {...register("phone", {
                                    required: "Please Enter a Mobile Number",
                                    pattern: { value: /^\d{10}$/, message: 'Mobile Number must be 10 digits' }
                                })} error={!!errors?.phone} helperText={errors?.phone?.message} />

                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth InputLabelProps={{ shrink: true }}
                                label={
                                    <Typography className="text-dark">
                                        Email Address<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                {...register("email", {
                                    required: "Please Enter an Email Address",
                                    pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Enter a Valid Email ID' }
                                })} error={!!errors?.email} helperText={errors?.email?.message} placeholder="Email Address" disabled={id} />
                        </Grid>

                        {!id && (
                            <>
                                <Grid item xs={4}>
                                    <Controller control={control} name="password" render={({ field }) => (
                                        <TextField fullWidth type="password"
                                            label={
                                                <Typography className="text-dark">
                                                    Password<span style={{ color: "red" }}>*</span>
                                                </Typography>
                                            }
                                            {...field}
                                            error={!!errors?.password} helperText={errors?.password?.message} />
                                    )} rules={{
                                        required: "Password must contain at least 1 uppercase, 1 lowercase, 1 special character, and be at least 8 characters long",
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/,
                                            message: "Invalid password format"
                                        }
                                    }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        render={({ field }) => (
                                            <TextField
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                label={
                                                    <Typography className="text-dark">
                                                        Confirm Password
                                                    </Typography>
                                                }
                                                Typography
                                                {...field}
                                                error={!!errors?.confirmPassword}
                                                helperText={errors?.confirmPassword?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        rules={{
                                            validate: (value) => {
                                                const password = getValues("password");
                                                if (!password) {
                                                    return true;
                                                }
                                                if (value === "") {
                                                    return "Please re-enter the password";
                                                }
                                                if (value !== password) {
                                                    return "Passwords do not match";
                                                }
                                            },
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
                        <Button variant='contained' type="submit" style={{ color: 'white', textTransform: "uppercase" }} className="menus-color">{id ? "Update" : "Submit"}</Button>
                    </Box>
                </div>
            </form>
        </>
    );
};

export default AddEditAssistantAdmin;
