import React from 'react'
import '../../../Component/MemberShip/Membership/Membership.css';

const Membership = () => {
  return (
    <div className="pt-5 ourExecutiveCommittee-containter" >
      <header className="chapter-heading">
        <h2 className="text-center title-font text-light py-3 mt-1">Membership</h2>
      </header>
      <>
        <section className='p-md-5 p-3 overallPadding' >
          <p className='membershippage-paragraph content-font'>
            Members of the English Language Teachers’ Association of India (ELTAI) come from diverse
            professional backgrounds related to English language and literature education.
            Our community is enriched by the participation of pre-service, in-service and retired
            teachers, teacher educators, researchers, students, policymakers, administrators,
            curriculum designers, material developers, trainers, consultants, publishers,
            educational institutions, corporate sponsors, and anyone with a professional interest
            in English language and literature education.
          </p>
          <h3 className='membershippage-TopicTitles title-font'>Eligibility for Membership:</h3>
          <p className='membershippage-paragraph content-font'>
            Membership in ELTAI is open
            <ul>
              <li>to any Indian citizen, whether residing in India or abroad, and </li>
              <li>to individuals of any nationality who reside in India </li>
            </ul>
            To qualify for membership, applicants must be at least 18 years of age and the
            membership fee must be paid in Indian rupees.
          </p>
          <h3 className='membershippage-TopicTitles title-font'>Membership Categories: </h3>
          <p className='membershippage-paragraph content-font'>ELTAI will offer the following five categories of memberships.  </p>
          <section>
            <h3 className='membershippage-CategoryTitles title-font'>1. Individual Teacher Membership <span style={{ fontWeight: "normal" }}>(For 1 teacher):</span> </h3>
            <p className='membershippage-paragraph content-font'>This membership category is <b> open to pre-service, in-service, and retired teachers of
              English language and literature across schools, colleges, and universities. </b>
              Members enjoy access to a wide array of resources and professional development opportunities,
              such as events, workshops, webinars, conferences, and other materials designed to enhance
              their teaching skills and knowledge. Additional benefits include networking opportunities
              with fellow educators, access to ELTAI’s journals and newsletters, and eligibility for
              scholarships, grants, and awards. The duration of membership determines the extent of
              access to these offerings.  </p>
            <p className='membershippage-paragraph content-font'>Individual teacher membership offers three plans: </p>
            <div className="plans-container">
              <div className="plan-card py-4">
                <h3>Annual Plan</h3>
                <p>(Only for first-time members)</p>
                <p className="price">₹800</p>
              </div>
              <div className="plan-card">
                <h3>Short-term Plan</h3>
                <p>(3 years)</p>
                <p className="price">
                  <span className="original-price">₹2400</span>₹1800
                </p>
                <p className="discount">(25% OFF)</p>
              </div>
              <div className="plan-card">
                <h3>Long-term Plan</h3>
                <p>(10 years)</p>
                <p className="price">
                  <span className="original-price">₹8000</span>₹4800
                </p>
                <p className="discount">(40% OFF)</p>
              </div>
            </div>
          </section>
          <br />
          <section>
            <h3 className='membershippage-CategoryTitles title-font'>2. Dual Teacher Membership <span style={{ fontWeight: "normal" }}>(For 2 teachers):</span> </h3>
            <p className='membershippage-paragraph content-font'>This membership category is <b> available to married teaching
              couples residing at the same address </b> who wish to join ELTAI together under a single plan.
              It allows couples to participate in ELTAI activities as a unit, while each member receives
              individual benefits. Teacher Couple Membership includes all the advantages of Individual Teacher
              Memberships but at a reduced combined cost. Both members can attend events, access resources,
              and enjoy networking opportunities, while they will receive a single copy of the Journal of
              English Language Teaching to maintain cost-effectiveness. </p>
            <p className='membershippage-paragraph content-font'>Teacher couple membership offers two plans: </p>
            <div className="plans-container">
              <div className="plan-card">
                <h3>Short-term Plan</h3>
                <p>(3 years)</p>
                <p className="price">
                  <span className="original-price">₹4800</span>₹3000
                </p>
                <p className="discount">(37% OFF)</p>
              </div>
              <div className="plan-card">
                <h3>Long-term Plan</h3>
                <p>(10 years)</p>
                <p className="price">
                  <span className="original-price">₹16000</span>₹8000
                </p>
                <p className="discount">(50% OFF)</p>
              </div>
            </div>
          </section>
          <br />
          <section>
            <h3 className='membershippage-CategoryTitles title-font'>3. Individual Student Membership <span style={{ fontWeight: "normal" }}>(For 1 student):</span> </h3>
            <p className='membershippage-paragraph content-font'>This membership is tailored <b> for undergraduate and postgraduate
              students, as well as full-time Ph.D. research scholars</b> in English language, literature, and related
              fields, providing a platform to engage with the teaching community and access resources to support
              their academic and career development. Key benefits include access to ELTAI’s resources,
              including journals, webinars, and special events designed for students and research scholars.
              This membership may also offer internship opportunities and career guidance.</p>
            <p className='membershippage-paragraph content-font'>Individual student membership offers only short-term plan
              currently. It does not include a long-term plan, as students typically complete their studies
              before the duration of such a plan expires. </p>
            <div className="plans-container">
              <div className="plan-card">
                <h3>Short-term Plan</h3>
                <p>(3 years)</p>
                <p className="price">
                  <span className="original-price">₹2400</span>₹1200
                </p>
                <p className="discount">(50% OFF)</p>
              </div>
            </div>
          </section>
          <br />
          <section>
            <h3 className='membershippage-CategoryTitles title-font'>4. Institutional Membership</h3>
            <p className='membershippage-paragraph content-font'>This membership is <b>intended for educational institutions,
              such as schools, colleges, and universities,</b> that wish to affiliate with ELTAI.
              Institutions benefit from the opportunity to offer professional development
              opportunities to their educators and students, share information about openings in their institutions
              with all members of ELTAI, and collaborate with other institutions and professionals within the
              ELTAI network. This collaboration can lead to joint projects, faculty exchanges, research
              collaborations, and knowledge-sharing events. It also includes institutional recognition on
              ELTAI’s website, thereby increasing the institution’s visibility within the national and
              international education community. </p>
            <p className='membershippage-paragraph content-font'>Institutional membership offers two plans.
              It does not include an annual plan, simplifying the process for institutions and reducing
              the administrative burden associated with yearly renewals. </p>
            <div className="plans-container">
              <div className="plan-card py-4">
                <h3>Short-term Plan</h3>
                <p>(3 years)</p>
                <p className="price">₹2400</p>
              </div>
              <div className="plan-card">
                <h3>Long-term Plan</h3>
                <p>(10 years)</p>
                <p className="price">
                  <span className="original-price">₹8000</span>₹6000
                </p>
                <p className="discount">(25% OFF)</p>
              </div>
            </div>
          </section>
          <br />
          <section>
            <h3 className='membershippage-CategoryTitles title-font'>5. Corporate Supporter Membership </h3>
            <p className='membershippage-paragraph content-font'>This category is <b>ideal for companies, particularly those in
              the publishing, educational technology, and professional development sectors, as well as any
              other firms and organisations that want to support ELTAI’s mission while gaining visibility
              within the teaching community.</b> Corporate supporters receive acknowledgement on ELTAI’s website
              and can also become sponsors or partners in ELTAI’s events, thereby maximizing their visibility.
              Additionally, they can connect with a network of educators, showcase their products or services
              to members, and participate in events as exhibitors or speakers. </p>
            <p className='membershippage-paragraph content-font'>Corporate Supporter membership offers only long-term plan,
              simplifying the process for corporate supporters and reducing the administrative burden associated
              with yearly renewals.</p>
            <div className="plans-container">
              <div className="plan-card py-4">
                <h3>Long-term Plan</h3>
                <p>(10 years)</p>
                <p className="price">₹8000</p>
              </div>
            </div>
          </section>
          <br />
          <section>
            <h3 className='membershippage-paragraph content-font membershippage-note px-5 py-2'>
              <b>NOTE: </b>Any member who renews their lapsed membership between 01 January and 31 March 2025
              will receive an additional 10% discount on the revised membership plans.
            </h3>
          </section>
          <br />
          <section>
            <h3 className='membershippage-TopicTitles title-font'>How to Pay Membership Fees </h3>
            <p className='membershippage-paragraph content-font'>You can pay the membership fee through the following
              methods: </p>
            <ol>
              <li className='membershippage-CategoryTitles title-font'><b>Online Payment </b></li>
              <p className='membershippage-paragraph content-font'> Pay online via Internet Banking, UPI, or similar options after completing
                the membership form with your details. </p>
              <li className='membershippage-CategoryTitles title-font'><b>Payment for Institutional and Corporate Members </b></li>
              <p className='membershippage-paragraph content-font'> Institutional and Corporate Supporter Members can generate
                an invoice and pay via a crossed Cheque or Demand Draft, drawn in favour of English Language
                Teachers’ Association of India payable at Chennai. Mention your name and contact details on the
                back of the cheque/demand draft. Send it to our office via Registered/Speed Post or Courier only.</p>
              <li className='membershippage-CategoryTitles title-font'><b> Bank Transfer:  </b></li>
              <p className='membershippage-paragraph content-font'> Transfer the fee through NEFT/RTGS to the ELTAI bank account.
                Use the following bank account details for NEFT/RTGS: <br />
                Account Name: ELTAI (English Language Teachers’ Association of India) <br />
                Bank & Branch: SBI, Villivakkam Branch, Chennai - 600049 <br />
                Savings Account No.: 30870397943 <br />
                IFSC Code: SBIN0007108 </p>
            </ol>
            <h3 className='membershippage-TopicTitles title-font'>Important Instructions </h3>
            <p className='membershippage-paragraph content-font'>After completing a bank transfer, send a scanned copy of the transaction details
              (e.g., bank transfer challan, UTR/IMPS Reference Number) to ELTAI via email at&nbsp;
              <a href='mailto:indiaeltai@gmail.com'> indiaeltai@gmail.com</a>. For any queries, please contact us at the provided email. </p>
          </section>
          <br />
        </section>
      </>
    </div>
  )
}

export default Membership
