import {
  Alert,
  FormHelperText,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete
} from "@mui/material";
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import { useGetChapterBasedOnStateMutation } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import {
  currently_teaching,
  TypeOfInstitution,
  Salutation,
  PrimarilyTeachingForTeacher,
  planType,
} from "../../../constant/constant";
import { Country, State } from "country-state-city";
import {
  useCreateDualMemberMutation,
  useLazyGetMemberDetailsByIdQuery,
} from "../../../app/services/memberServices";
import {
  useLazyGetAllMemberShipDetailsQuery,
  useRemoveMemberShipPermanentlyMutation,
} from "../../../app/services/memberShipServices";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllSIGGroupQuery } from "../../../app/services/SIGGroupServices";
import { selectCurrentScope } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";

const steps = ["Member1", "Member2", "Payment Information"];
//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  minWidth: 300,
  height: '70%',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
};

const style1 = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  minWidth: 300,
  height: '60%',
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  overflowY: 'scroll'
};

function AddEditDualTeacher() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [getChaptersBasedOnState] = useGetChapterBasedOnStateMutation();
  const [disableChapter, setDisableChapter] = useState(false);
  const [showPrimarilyOtherInput, setShowPrimarilyOtherInput] = useState(false);
  const [costType, setCostType] = useState([]);
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [createMember] = useCreateDualMemberMutation();
  const [getAllplans] = useLazyGetAllMemberShipDetailsQuery();
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [states, setStates] = useState([]);
  const [selectedCountry1, setSelectedCountry1] = useState('IN');
  const [states1, setStates1] = useState([]);
  const [stepperOneEmail, setStepperOneEmail] = useState("");
  const [deleteMember] = useRemoveMemberShipPermanentlyMutation();
  const [memberDetails, setMemberDetails] = useState("");
  const [open, setOpen] = useState(false);
  const [allSIG, setAllSIG] = React.useState([]);
  const { refetch: refetchSIG } = useGetAllSIGGroupQuery();
  const memberScope = useSelector(selectCurrentScope);
  const [openForm, setOpenForm] = useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [newFormData, setNewFormData] = useState('')
  const countries = Country.getAllCountries();


  useEffect(() => {
    // Fetch states when a country is selected
    console.log(selectedCountry1, 'selectedCountry1')
    if (selectedCountry1) {
      const fetchedStates = State.getStatesOfCountry(selectedCountry1); // Fetch states of the selected country
      setStates1(fetchedStates); // Set the states in state
    }
  }, [selectedCountry1]); // Triggered when selectedCountry changes
  useEffect(() => {
    // Fetch states when a country is selected
    if (selectedCountry) {
      const fetchedStates = State.getStatesOfCountry(selectedCountry); // Fetch states of the selected country
      setStates(fetchedStates); // Set the states in state
    }
  }, [selectedCountry]); // Triggered when selectedCountry changes

  // const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const navigator = useNavigate();

  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      marriedCouple: "", // 0 - No, 1 - Yes
      weddingAnniversary: "",
      type: 4,
      chapter: "",
      role: "PORTAL_MEMBER",
      member: [
        {
          salutation: "",
          gender: "",
          address: "",
          fullName: "",
          dateOfBirth: "",
          city: "",
          pinCode: "",
          state: "",
          mobileNumber: "",
          whatsAppNumber: "",
          email: "",
          landlineNumber: "",
          teaching: "",
          currentDesignation: "",
          institution: "",
          primarilyTeachingLevel: "",
          otherprimarilyTeachingLevel: "",
          natureWork: "",
          country: countries.find(c => c.isoCode === 'IN').isoCode,
        },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "member",
  });
  const selectedplantypeId = watch("dummyPlanTypeId");

  const {
    register: registerAdd,
    setValue: setValueAdd,
    watch: watchAdd,
    formState: { errors: errorsAdd },
    handleSubmit: handleSubmitAdd,
    control: controlAdd,
    clearErrors: clearErrorsAdd
  } = useForm({
    defaultValues: {
      scope: memberScope ? memberScope : '',
      paymentMethod: "",
      emailSent: 0,
      paymentType: "0",
      currency: '',
      amount: '',
      bankTransactionId: ''
    },
    mode: 'onChange'
  })
  useEffect(() => {
    const handlePopState = () => {
      // You can check if you are on a specific route or just redirect unconditionally
      // For example, redirect to "/another" if the back button is pressed
      if (watch("paymentStatus")) {
        navigator("/");
      }
      else {
        window.history.back()
      }
    };

    window.history.pushState(null, "", window.location.href); // Adds an extra history entry
    window.onpopstate = handlePopState;

    return () => {
      window.onpopstate = null; // Clean up when the component is unmounted
    };
  }, [navigator, watch]);

  useEffect(() => {
    costType.forEach((data) => {
      if (data._id === selectedplantypeId) {
        const amountToSet = data.offer === 0 ? data.amount : data.offerAmount;
        setAmount(amountToSet);
        setCurrency(data.currencyType);
      }
    });
  }, [costType, selectedplantypeId]);

  useEffect(() => {
    if (amount && currency) {
      setValue("dummyAmount", amount);
      setValue("dummyCurrency", currency);
      setValueAdd('currency', currency);
      setValueAdd('amount', amount);
    }
  }, [setValue, setValueAdd, amount, currency]);

  useEffect(() => {
    getAllplans().then((res) => {
      if (res?.data?.status) {
        const filteredPlan = res?.data?.data?.find(
          (item) =>
            item.isDeleted === 0 && item.quantity === 2 && item.planName === "1"
        );
        setValue("dummyPlanId", filteredPlan?._id);
        setValue(
          "planName",
          filteredPlan
            ? planType.find((i) => i?.planId === "1")?.planName
            : "No active plan for this member type"
        );
        setCostType(filteredPlan?.planType || []);
      }
    });
  }, [getAllplans, setValue, setCostType]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpen(false);
  };

  React.useEffect(() => {
    refetchSIG().then((res) => {
      setAllSIG(res?.data?.data || []);
    });
  }, [refetchSIG])

  const handleFormClose = () => {
    setOpenForm(false)
  }


  const handlePaymentCancelled = useCallback(
    (id) => {
      toast.info("Payment was not completed.");
      setLoading(true); // Ensure loading state starts

      deleteMember(id)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [deleteMember] // Dependencies
  );

  //On submit for save
  const AddEditTeacherMember = useCallback((data) => {
    if (memberScope) {
      data.member[0].emailSent = 1;
      data.member[0].amount = amount;
      data.member[0].bankTransactionId = data.bankTransactionId;
      data.member[0].paymentType = data.paymentType
    }
    data = {
      ...data,
      chapter:
        (watch("chapter") === "No active Chapter on this state" || watch("chapter") === "I will join a chapter later")
          ? ""
          : data.chapter,
      dummyAmount: amount,
      dummyCurrency: currency
    };
    if (activeStep === steps.length - 1) {
      setLoading(false);
      if (memberScope && !isPaymentCompleted) {
        setOpenForm(true); // Open modal instead of submitting
        setValueAdd("amount", amount)
        setValueAdd("currency", currency);
        setLoading(false);
        return;
      }
      if (memberScope) {
        data.member[1].emailSent = 1;
      }

      createMember(data).then((res) => {
        if (res?.data?.data) {
          if (memberScope) {
            setLoading(false);
            toast.success(res?.data?.message);
            if (memberScope === 'PORTAL_SUPER_ADMIN') {
              navigator("/admin/membershipPage")
            }
            else if (memberScope === 'PORTAL_SIG_ADMIN') {
              navigator("/sigAdmin/membershipPage")
            }
            else {
              navigator("/chapterAdmin/membershipPage")
            }
          }
          else {
            var options = {
              key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
              amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: currency === 0 ? "INR" : "USD",
              config: {
                display: {
                  hide: [
                    { method: 'paylater' },
                    { method: 'emi' },
                    { method: 'wallet' }
                  ]
                }
              },
              name: res?.data?.data?.fullName,
              description: "Membership payment",
              order_id: res?.data?.data?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler: function (response) {
                gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                  if (res.data?.status) {
                    const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                    setValue("paymentStatus", isPaid);
                    toast.success("Member Saved Successfully");
                    setMemberDetails(res?.data?.data)
                    handleOpen()
                  }
                });
              },
              modal: {
                ondismiss: function () {
                  // Handle modal dismissal (user exits without payment)
                  handlePaymentCancelled(res?.data?.data?._id); // Call your custom function
                },
              },
            };
            var rzp1 = new window.Razorpay(options);
            setLoading(false);
            rzp1.open();
            rzp1.on("payment.failed", function (response) {
              toast.error("Payment Failed Try Again");
            });
          }
        } else {
          setLoading(false);
          setIsPaymentCompleted(prev => prev = false)
          toast.error(res?.error?.data?.error);
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setValue(`member[1].country`, 'IN') //in future if they want all country available  unset the value
    }
  }, [activeStep, memberScope, amount, createMember, setValue, currency, gettMemberDetailsByIdQuery, isPaymentCompleted, navigator, setValueAdd, watch, handlePaymentCancelled]);

  //Get all chapter
  const getChapters = useCallback(() => {
    const data = {
      state: watch("member.[0].state"),
      page: "",
      itemsPerPage: "",
      searchTerm: ""
    };
    getChaptersBasedOnState(data).then((res) => {
      setSelectedChapters(
        res?.data?.data?.chapterData || [
          {
            title: "No active Chapter on this state",
            _id: "No active Chapter on this state",
          },
        ]
      );
      if (!res?.data?.data?.chapterData) {
        setValue("chapter", "No active Chapter on this state");
        setDisableChapter(true);
      } else {
        setDisableChapter(false);
      }
    });
  }, [watch, getChaptersBasedOnState, setValue]);

  useEffect(() => {
    if (isPaymentCompleted) {
      AddEditTeacherMember(newFormData);
      setOpenForm(false); // Open modal instead of submitting
    }
  }, [isPaymentCompleted, newFormData, AddEditTeacherMember]);

  //to store the modal form data and the member form data
  const addMember = (formData) => {
    const data = getValues();
    const updatedData = { ...data, ...formData }
    setNewFormData(updatedData);
    setIsPaymentCompleted(prev => prev = true)
  }

  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">
            Dual Teacher Membership
          </h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box className="p-md-5 p-3 overallPadding">
          <div className="w-100 text-end mb-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color rounded-0"
            >
              Go back to plans page
            </Button>
          </div>

          <p className="descrption mt-3 mb-4" >This membership
            category is available to married teaching couples residing at the same address who wish to
            join ELTAI together under a single plan. It allows couples to participate in ELTAI activities
            as a unit, while each member receives individual benefits. Teacher Couple Membership
            includes all the advantages of Individual Teacher Memberships but at a reduced combined
            cost. Both members can attend events, access resources, and enjoy networking opportunities,
            while they will receive a single copy of the Journal of English Language Teaching to
            maintain cost-effectiveness.</p>
          <Stepper activeStep={activeStep} className="my-5">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form onSubmit={handleSubmit(AddEditTeacherMember)}>
            {activeStep === 0 && (
              <>
                <Grid container rowSpacing={3} columnSpacing={3} mb={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <FormControl
                      component="fieldset"
                      error={!!errors.marriedCouple}
                    >
                      <FormLabel className="text-dark" component="legend">
                        Are you a married couple?
                        <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Controller
                        name="marriedCouple"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Please select married couple or not",
                        }}
                        render={({ field }) => (
                          <RadioGroup row {...field}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                      {errors.marriedCouple && (
                        <FormHelperText>
                          {errors.marriedCouple.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your wedding anniversary
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="date"
                      value={
                        watch("weddingAnniversary")
                          ? watch("weddingAnniversary").split("T")[0]
                          : ""
                      }
                      {...register("weddingAnniversary", {
                        required:
                          Number(watch("marriedCouple")) === 1
                            ? "Please select wedding anniversary"
                            : "",
                        max: {
                          value: new Date().toISOString().split("T")[0],
                          message: "Future date is not allowed",
                        },
                      })}
                      error={!!errors?.weddingAnniversary}
                      helperText={errors?.weddingAnniversary?.message}
                      disabled={Number(watch("marriedCouple")) === 0} // Ensure numeric comparison
                    />
                  </Grid>
                </Grid>
                <Box>
                  {fields.map((field, index) => (
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      {/* Salutation Field */}
                      <Grid item lg={4} md={6} xs={12}>
                        <Controller
                          control={control}
                          name={`member[0].salutation`} // Dynamic name for the child
                          rules={{ required: "Please select a salutation" }} // Validation rules
                          render={({ field, fieldState }) => (
                            <FormControl
                              fullWidth
                              error={!!fieldState.error}
                              variant="outlined"
                            >
                              <InputLabel
                                shrink
                                className="text-dark"
                                id={`member[0].salutation-label`}
                              >
                                Salutation
                                <span style={{ color: "red" }}>*</span>
                              </InputLabel>
                              <Select
                                labelId={`member[0].salutation-label`}
                                id={`member[0].salutation`}
                                value={field.value === "" ? "" : field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                                label="Salutation *" // Properly bind the label
                                displayEmpty
                                className="bg-white"
                              >
                                <MenuItem value="">
                                  <em>Select a salutation</em>{" "}
                                  {/* Placeholder */}
                                </MenuItem>
                                {Salutation.map((data) => (
                                  <MenuItem key={data.id} value={data.id}>
                                    {data.Name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {fieldState.error && (
                                <FormHelperText>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Your Full Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member.[0].fullName`, {
                            required: "Please enter a full name",
                            minLength: {
                              value: 3,
                              message: "Please enter a valid name",
                            },
                          })}
                          error={!!errors?.member?.[0]?.fullName}
                          helperText={errors?.member?.[0]?.fullName?.message}
                          placeholder="Your Full Name"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Your Date of Birth{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          variant="outlined"
                          type="date"
                          value={watch(`member[0].dateOfBirth`)}
                          {...register(`member[0].dateOfBirth`, {
                            required: "Please select your date of birth",
                            max: {
                              value: new Date().toISOString().split("T")[0],
                              message: "Future date is not allowed",
                            },
                          })}
                          error={!!errors?.member?.[0]?.dateOfBirth}
                          helperText={errors?.member?.[0]?.dateOfBirth?.message}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl
                          component="fieldset"
                          error={!!errors?.member?.[0]?.gender}
                        >
                          <FormLabel className="text-dark" component="legend">
                            Your Gender <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Controller
                            control={control}
                            name={`member[0].gender`} // Correct dynamic name for gender field
                            rules={{ required: "Please select your gender" }}
                            render={({ field }) => (
                              <RadioGroup row {...field}>
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="3"
                                  control={<Radio />}
                                  label="Transgender"
                                />
                              </RadioGroup>
                            )}
                          />
                          {errors?.member?.[0]?.gender && (
                            <FormHelperText>
                              {errors?.member?.[0]?.gender.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Your Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].address`, {
                            required: "Please enter an address",
                          })}
                          error={!!errors?.member?.[0]?.address} // Accessing errors dynamically for each member
                          helperText={errors?.member?.[0]?.address?.message} // Displaying error message dynamically
                          placeholder="Your Address"
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Your City / Town
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].city`, {
                            required: "Please enter a city / town",
                          })}
                          error={!!errors?.member?.[0]?.city} // Ensure we check errors for `city`
                          helperText={errors?.member?.[0]?.city?.message} // Correct error reference
                          placeholder="Your City / Town"
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Pin Code <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].pinCode`, {
                            required: "Please enter a pin code",
                            pattern: {
                              value: /^\d{6}$/,
                              message: "Pincode must be 6 digits",
                            },
                          })}
                          error={!!errors?.member?.[0]?.pinCode}
                          helperText={errors?.member?.[0]?.pinCode?.message}
                          placeholder="Pin Code"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl
                          fullWidth
                          className="mt-0"
                          error={!!errors?.member?.[0]?.country}
                        >
                          <InputLabel className="text-dark">
                            Country <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Controller
                            name={`member[0].country`}
                            control={control}
                            defaultValue="" // Default empty value
                            rules={{
                              required: "Please select a country", // Validation rule
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                className="bg-white"
                                label={
                                  <Typography className="text-dark">
                                    Country{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Typography>
                                }
                                value={field.value || ""} // Bind selected value to field value
                                disabled
                                onChange={(e) => {
                                  setValue("member[0].state", "")
                                  setSelectedCountry(e.target.value);
                                  field.onChange(e);
                                }}
                              >
                                <MenuItem value="">
                                  <em>Select Country</em>
                                </MenuItem>
                                {Country.getAllCountries().map((country) => (
                                  <MenuItem
                                    key={country.isoCode}
                                    value={country.isoCode}
                                  >
                                    {country.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          {errors?.member?.[0]?.country && (
                            <FormHelperText>
                              {errors?.member?.[0]?.country.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <FormControl
                          fullWidth
                          disabled={!selectedCountry} // Disable state selection if no country is selected
                          error={!!errors?.member?.[0]?.state} // Highlight error state
                          variant="outlined"
                        >
                          <InputLabel className="text-dark">
                            State <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Controller
                            name={`member[0].state`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required:
                                selectedCountry && "Please select a state",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                value={field.value || ""}
                                onChange={(e) => {
                                  field.onChange(e.target.value)
                                  getChapters()
                                }} // Handle state selection
                                label="State *" // Associate label with Select
                                className={
                                  !selectedCountry && errors?.member?.[0]?.state
                                    ? "border border-danger bg-white"
                                    : "bg-white"
                                }
                              >
                                <MenuItem value="">
                                  <em>Select State</em>
                                </MenuItem>
                                {states.length > 0 &&
                                  states.map((state) => (
                                    <MenuItem
                                      key={state.isoCode}
                                      value={state.name}
                                    >
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                          {errors?.member?.[0]?.state && (
                            <FormHelperText>
                              {errors.member[0].state.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              District (If Any)
                            </Typography>
                          }
                          {...register("member[0].district",)}
                          placeholder="District (If Any)"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Mobile Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].mobileNumber`, {
                            required: "Please enter a mobile number",
                            pattern: {
                              value: /^\d{10}$/,
                              message: "Mobile number must be 10 digits",
                            },
                          })}
                          error={!!errors?.member?.[0]?.mobileNumber}
                          helperText={
                            errors?.member?.[0]?.mobileNumber?.message
                          }
                          placeholder="Mobile Number"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              WhatsApp Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].whatsAppNumber`, {
                            required: "Please enter a whatsApp number", // Required field validation
                            pattern: {
                              value: /^\d{10}$/, // Only allow 10 digit numbers
                              message: "WhatsApp number must be 10 digits", // Custom error message
                            },
                          })}
                          error={!!errors?.member?.[0]?.whatsAppNumber} // Check for errors for this field
                          helperText={
                            errors?.member?.[0]?.whatsAppNumber?.message
                          } // Display the error message if exists
                          placeholder="WhatsApp Number"
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Email Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`member[0].email`, {
                            required: "Please enter an email address",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: "Enter a valid email ID",
                            },
                          })}
                          error={!!errors?.member?.[0]?.email}
                          helperText={errors?.member?.[0]?.email?.message}
                          placeholder="Email Address"
                          onChange={(e) => {
                            setStepperOneEmail(e.target.value); // Store the email
                            clearErrors("member[0].email");
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label={
                            <Typography className="text-dark">
                              Landline Number
                            </Typography>
                          }
                          {...register(`member[0].landlineNumber`, {
                            maxLength: { value: 15, message: 'Please enter number below 15 digits' }
                          })}
                          error={!!errors?.member?.[0]?.landlineNumber}
                          helperText={
                            errors?.member?.[0]?.landlineNumber?.message
                          }
                          placeholder="Landline Number"
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <Controller
                          control={control}
                          rules={{
                            required: "Please select your currently teaching",
                          }}
                          name={`member[0].teaching`}
                          render={({ field: teachingField, fieldState }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={!!fieldState.error}
                            >
                              <InputLabel
                                className="text-dark"
                                id="member[0].teaching-label"
                              >
                                Are you currently teaching?
                                <span style={{ color: "red" }}>*</span>
                              </InputLabel>
                              <Select
                                labelId="member[0].teaching-label"
                                value={teachingField.value ?? ""}
                                className="bg-white"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  teachingField.onChange(value);
                                  if (value === 0) {
                                    setValue(
                                      `member[0].currentDesignation`,
                                      ""
                                    );
                                    setValue(`member[0].institution`, "");
                                  }
                                  clearErrors(`member[0].currentDesignation`);
                                  clearErrors(`member[0].institution`);
                                }}
                                label="Are you currently teaching?" // Ensure label text matches the InputLabel
                              >
                                {currently_teaching.map((data) => (
                                  <MenuItem key={data.id} value={data.id}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors?.member?.[0]?.teaching && (
                                <FormHelperText>
                                  {errors.member[0].teaching.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          label={
                            <Typography className="text-dark">
                              Your Designation{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register(`member[0].currentDesignation`, {
                            required:
                              getValues(`member[0].teaching`) === 1
                                ? "Please enter your designation"
                                : false,
                            minLength: {
                              value: 3,
                              message: "Please enter a valid designation",
                            },
                          })}
                          placeholder="Your Designation"
                          error={!!errors?.member?.[0]?.currentDesignation}
                          helperText={
                            errors?.member?.[0]?.currentDesignation?.message
                          }
                          disabled={getValues(`member[0].teaching`) !== 1}
                          fullWidth
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          label={
                            <Typography className="text-dark">
                              Name of Your Institution{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          InputLabelProps={{ shrink: true }}
                          {...register(`member[0].institution`, {
                            required:
                              getValues(`member[0].teaching`) === 1
                                ? "Please enter the name of your institution"
                                : undefined,
                            minLength: {
                              value: 3,
                              message: "Please enter a valid institution",
                            },
                          })}
                          error={!!errors?.member?.[0]?.institution}
                          helperText={errors?.member?.[0]?.institution?.message}
                          disabled={getValues(`member[0].teaching`) !== 1}
                          placeholder="Name of your Institution"
                          fullWidth
                        />
                      </Grid>

                      {/* Primarily Teaching Level */}
                      <Grid item lg={4} md={6} xs={12}>
                        <Controller
                          control={control}
                          rules={{
                            required:
                              "Please select level you are teaching / taught",
                          }}
                          name={`member[0].primarilyTeachingLevel`}
                          render={({
                            field: primarilyTeachingField,
                            fieldState,
                          }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className="bg-white"
                              error={!!fieldState.error}
                              style={{ backgroundColor: "#f0f0f0" }}
                            >
                              <InputLabel
                                className="text-dark"
                                id={`primarilyTeachingLevel-${index}`}
                              >
                                Level at which you are primarily teaching /
                                taught
                                <span style={{ color: "red" }}>*</span>
                              </InputLabel>
                              <Select
                                labelId={`primarilyTeachingLevel-${index}`}
                                value={primarilyTeachingField.value || ""}
                                onChange={(e) => {
                                  primarilyTeachingField.onChange(
                                    e.target.value
                                  );
                                  const isOther =
                                    e.target.value ===
                                    PrimarilyTeachingForTeacher.find(
                                      (option) => option.label === "Other"
                                    )?.id;
                                  setShowPrimarilyOtherInput(isOther); // Toggle "Other" input visibility
                                }}
                                label="Level at which you are primarily teaching/taught *"
                              >
                                {PrimarilyTeachingForTeacher.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {fieldState.error && !showPrimarilyOtherInput && (
                                <FormHelperText>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>

                      {/* Other Primarily Teaching Input */}
                      {showPrimarilyOtherInput && (
                        <Grid item lg={4} md={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            label={
                              <Typography className="text-dark">
                                Please Specify{" "}<span className="text-danger">*</span>
                              </Typography>
                            }
                            {...register(
                              `member[0].otherprimarilyTeachingLevel`,
                              {
                                required:
                                  showPrimarilyOtherInput &&
                                  "Please specify the other level of teaching",
                              }
                            )}
                            value={watch(
                              `member[0].otherprimarilyTeachingLevel`
                            )}
                            error={
                              !!errors?.member?.[0]?.otherprimarilyTeachingLevel
                            }
                            helperText={
                              errors?.member?.[0]?.otherprimarilyTeachingLevel
                                ?.message
                            }
                            fullWidth
                            placeholder="Specify other level of teaching"
                          />
                        </Grid>
                      )}

                      {/* Nature of Work */}
                      <Grid item lg={4} md={6} xs={12}>
                        <Controller
                          control={control}
                          rules={{
                            required: "Please select the nature of your work",
                          }}
                          name={`member[0].natureWork`}
                          render={({ field: natureWorkField, fieldState }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={!!fieldState.error}
                            >
                              <InputLabel
                                className="text-dark"
                                id={`natureWork-member-0`}
                              >
                                Nature of your work
                                <span style={{ color: "red" }}>*</span>
                              </InputLabel>
                              <Select
                                labelId={`natureWork-member-0`}
                                className="bg-white"
                                value={natureWorkField.value || ""}
                                onChange={(e) =>
                                  natureWorkField.onChange(e.target.value)
                                }
                                label="Nature of your work *" // This associates the InputLabel with Select
                              >
                                {TypeOfInstitution.map((data) => (
                                  <MenuItem key={data.id} value={data.id}>
                                    {data.type}
                                  </MenuItem>
                                ))}
                              </Select>
                              {fieldState.error && (
                                <FormHelperText>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </>
            )}
            {activeStep === 1 && (
              <>
                {fields.map((field, index) => (
                  <Grid container rowSpacing={3} columnSpacing={3}>
                    {/* Salutation Field */}
                    <Grid item lg={4} md={6} xs={12}>
                      <Controller
                        control={control}
                        name={`member[1].salutation`} // Dynamic name for the child
                        rules={{ required: "Please select a salutation" }} // Validation rules
                        render={({ field, fieldState }) => (
                          <FormControl
                            fullWidth
                            error={!!fieldState.error}
                            variant="outlined"
                          >
                            <InputLabel
                              shrink
                              className="text-dark"
                              id={`member[1].salutation-label`}
                            >
                              Salutation
                              <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              labelId={`member[1].salutation-label`}
                              id={`member[1].salutation`}
                              value={field.value === "" ? "" : field.value}
                              onChange={(e) => field.onChange(e.target.value)}
                              label="Salutation *" // Properly bind the label
                              displayEmpty
                              defaultValue={""}
                              className="bg-white"
                            >
                              <MenuItem value={""}>
                                <em>Select a salutation</em>{" "}
                              </MenuItem>
                              {Salutation.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.Name}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.error && (
                              <FormHelperText>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Full Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member.[1].fullName`, {
                          required: "Please enter a full name",
                          minLength: {
                            value: 3,
                            message: "Please enter a valid name",
                          },
                        })}
                        error={!!errors?.member?.[1]?.fullName}
                        helperText={errors?.member?.[1]?.fullName?.message}
                        placeholder="Your Full Name"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        variant="outlined"
                        type="date"
                        value={watch(`member[1].dateOfBirth`)}
                        {...register(`member[1].dateOfBirth`, {
                          required: "Please select your date of birth",
                          max: {
                            value: new Date().toISOString().split("T")[0],
                            message: "Future date is not allowed",
                          },
                        })}
                        error={!!errors?.member?.[1]?.dateOfBirth}
                        helperText={errors?.member?.[1]?.dateOfBirth?.message}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl
                        component="fieldset"
                        error={!!errors?.member?.[0]?.gender}
                      >
                        <FormLabel component="legend" className="text-dark">
                          Your Gender <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Controller
                          control={control}
                          name={`member[1].gender`} // Correct dynamic name for gender field
                          rules={{ required: "Please select your gender" }}
                          render={({ field }) => (
                            <RadioGroup row {...field}>
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Transgender"
                              />
                            </RadioGroup>
                          )}
                        />
                        {errors?.member?.[1]?.gender && (
                          <FormHelperText className="text-danger">
                            {errors?.member?.[1]?.gender.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your Address <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member[1].address`, {
                          required: "Please enter an address",
                        })}
                        error={!!errors?.member?.[1]?.address} // Accessing errors dynamically for each member
                        helperText={errors?.member?.[1]?.address?.message} // Displaying error message dynamically
                        placeholder="Your Address"
                      />
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Your City / Town
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member[1].city`, {
                          required: "Please enter a city / town",
                        })}
                        error={!!errors?.member?.[1]?.city} // Ensure we check errors for `city`
                        helperText={errors?.member?.[1]?.city?.message} // Correct error reference
                        placeholder="Your City / Town"
                      />
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Pin Code <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member[1].pinCode`, {
                          required: "Please enter a pin code",
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Pincode must be 6 digits",
                          },
                        })}
                        error={!!errors?.member?.[1]?.pinCode}
                        helperText={errors?.member?.[1]?.pinCode?.message}
                        placeholder="Pin Code"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl
                        fullWidth
                        className="mt-0"
                        error={!!errors?.member?.[1]?.country}
                      >
                        <InputLabel className="text-dark">
                          Country <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Controller
                          name={`member[1].country`}
                          control={control}
                          defaultValue="" // Default empty value
                          rules={{
                            required: "Please select a country", // Validation rule
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="bg-white"
                              label={
                                <Typography className="text-dark">
                                  Country{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }

                              value={field.value || ""} // Bind selected value to field value
                              onChange={(e) => {
                                setSelectedCountry1(field.value);
                                field.onChange(e);
                                // Update the selected country
                                setValue(`member[1].state`, "");
                              }}
                              disabled
                            >
                              <MenuItem value="">
                                <em>Select Country{field.value}</em>
                              </MenuItem>
                              {Country.getAllCountries().map((country) => (
                                <MenuItem
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors?.member?.[1]?.country && (
                          <FormHelperText>
                            {errors?.member?.[1]?.country.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <FormControl
                        fullWidth
                        disabled={!watch(`member[1].country`)} // Disable state selection if no country is selected
                        error={!!errors?.member?.[1]?.state} // Highlight the input field if there's an error
                        variant="outlined" // Use outlined variant for better UI style
                      >
                        <InputLabel className="text-dark" id="state-label">
                          State <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <Controller
                          name={`member[1].state`}
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              watch(`member[1].country`) &&
                              "Please select a state",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={field.value || ""}
                              className={
                                !watch(`member[1].country`) &&
                                  errors?.member?.[1]?.state
                                  ? "border border-danger bg-white"
                                  : "bg-white"
                              }
                              onChange={(e) => field.onChange(e.target.value)} // Ensure state change is handled
                              label="State *" // Properly associate label with the select input
                              labelId="state-label" // Ensures the label is properly connected
                            >
                              <MenuItem value="">
                                <em>Select State</em>
                              </MenuItem>
                              {states1.length > 0 &&
                                states1.map((state) => (
                                  <MenuItem
                                    key={state.isoCode}
                                    value={state.name}
                                  >
                                    {state.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        />
                        {errors?.member?.[1]?.state && (
                          <FormHelperText>
                            {errors?.member?.[1]?.state.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            District (If Any)
                          </Typography>
                        }
                        {...register("member[1].district",)}
                        placeholder="District (If Any)"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Mobile Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member[1].mobileNumber`, {
                          required: "Please enter a mobile number",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "Mobile number must be 10 digits",
                          },
                        })}
                        error={!!errors?.member?.[1]?.mobileNumber}
                        helperText={errors?.member?.[1]?.mobileNumber?.message}
                        placeholder="Mobile Number"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            WhatsApp Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member.[1].whatsAppNumber`, {
                          required: "Please enter a whatsApp number",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "WhatsApp number must be 10 digits",
                          },
                        })}
                        error={!!errors?.member?.[1]?.whatsAppNumber}
                        helperText={
                          errors?.member?.[1]?.whatsAppNumber?.message
                        }
                        placeholder="WhatsApp Number"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Email Address{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register(`member[1].email`, {
                          required: "Please enter an email address",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Enter a valid email ID",
                          },
                          validate: (value) =>
                            value !== stepperOneEmail ||
                            "This email is already used in previous member",
                        })}
                        error={!!errors?.member?.[1]?.email}
                        helperText={errors?.member?.[1]?.email?.message}
                        placeholder="Email address"
                        onChange={(e) => {
                          clearErrors("member[1].email");
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label={
                          <Typography className="text-dark">
                            Landline Number
                          </Typography>
                        }
                        {...register(`member[1].landlineNumber`, {
                          maxLength: { value: 15, message: 'Please enter number below 15 digits' }
                        })}
                        error={!!errors?.member?.[1]?.landlineNumber}
                        helperText={
                          errors?.member?.[1]?.landlineNumber?.message
                        }
                        placeholder="Landline Number"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Controller
                        control={control}
                        rules={{
                          required: "Please select your currently teaching",
                        }}
                        name={`member[1].teaching`}
                        render={({ field: teachingField, fieldState }) => (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!fieldState.error}
                          >
                            <InputLabel
                              className="text-dark"
                              id="member[1].teaching-label"
                            >
                              Are you currently teaching?
                              <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              labelId="member[1].teaching-label"
                              className="bg-white"
                              value={teachingField.value ?? ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                teachingField.onChange(value);
                                if (value === 0) {
                                  setValue(`member[1].currentDesignation`, "");
                                  setValue(`member[1].institution`, "");
                                }
                                clearErrors(`member[1].currentDesignation`);
                                clearErrors(`member[1].institution`);
                              }}
                              label="Are You Currently Teaching?" // Ensure label text matches the InputLabel
                            >
                              {currently_teaching.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors?.member?.[1]?.teaching && (
                              <FormHelperText>
                                {errors.member[1].teaching.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    {/* Designation Field */}
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        label={
                          <Typography className="text-dark">
                            Your Designation{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register(`member[1].currentDesignation`, {
                          required:
                            getValues(`member[1].teaching`) === 1 &&
                            "Please enter your designation",
                          minLength: {
                            value: 3,
                            message: "Please enter a valid designation",
                          },
                        })}
                        error={!!errors?.member?.[1]?.currentDesignation}
                        helperText={
                          errors?.member?.[1]?.currentDesignation?.message
                        }
                        placeholder="Your Designation"
                        disabled={getValues(`member[1].teaching`) !== 1}
                        fullWidth
                      />
                    </Grid>

                    {/* Institution Field */}
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        label={
                          <Typography className="text-dark">
                            Name of Your Institution{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        InputLabelProps={{ shrink: true }}
                        {...register(`member[1].institution`, {
                          required:
                            getValues(`member[1].teaching`) === 1 &&
                            "Please enter the name of your institution",
                          minLength: {
                            value: 3,
                            message: "Please enter a valid institution",
                          },
                        })}
                        error={!!errors?.member?.[1]?.institution}
                        helperText={errors?.member?.[1]?.institution?.message}
                        placeholder="Name of your institution"
                        disabled={getValues(`member[1].teaching`) !== 1}
                        fullWidth
                      />
                    </Grid>

                    {/* Primarily Teaching Level */}
                    <Grid item lg={4} md={6} xs={12}>
                      <Controller
                        control={control}
                        rules={{
                          required: "Please select a primary teaching level",
                        }}
                        name={`member[1].primarilyTeachingLevel`}
                        render={({
                          field: primarilyTeachingField,
                          fieldState,
                        }) => (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!fieldState.error}
                          >
                            <InputLabel
                              className="text-dark"
                              id={`primarilyTeachingLevel-${index}`}
                            >
                              Level at which you are primarily teaching/taught
                              <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              labelId={`primarilyTeachingLevel-${index}`}
                              value={primarilyTeachingField.value || ""}
                              className="bg-white"
                              onChange={(e) => {
                                primarilyTeachingField.onChange(e.target.value);
                              }}
                              label="Level at which you are primarily teaching/taught *"
                            >
                              {PrimarilyTeachingForTeacher.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.error && !showPrimarilyOtherInput && (
                              <FormHelperText>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    {/* Other Primarily Teaching Input */}
                    {watch(`member[1].primarilyTeachingLevel`) === 99 && (
                      <Grid item lg={4} md={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography className="text-dark">
                              Please Specify{" "}<span className="text-danger">*</span>
                            </Typography>
                          }
                          {...register(
                            `member[1].otherprimarilyTeachingLevel`,
                            {
                              required:
                                showPrimarilyOtherInput &&
                                "Please specify the other level of teaching",
                            }
                          )}
                          value={watch(`member[1].otherprimarilyTeachingLevel`)}
                          error={
                            !!errors?.member?.[1]?.otherprimarilyTeachingLevel
                          }
                          helperText={
                            errors?.member?.[1]?.otherprimarilyTeachingLevel
                              ?.message
                          }
                          placeholder="Specify other level of teaching"
                          fullWidth
                        />
                      </Grid>
                    )}

                    {/* Nature of Work */}
                    <Grid item lg={4} md={6} xs={12}>
                      <Controller
                        control={control}
                        rules={{
                          required: "Please select the nature of your work",
                        }}
                        name={`member[1].natureWork`}
                        render={({ field: natureWorkField, fieldState }) => (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!fieldState.error}
                          >
                            <InputLabel
                              className="text-dark"
                              id={`natureWork-member-1`}
                            >
                              Nature of your work
                              <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              labelId={`natureWork-member-1`}
                              value={natureWorkField.value || ""}
                              className="bg-white"
                              onChange={(e) =>
                                natureWorkField.onChange(e.target.value)
                              }
                              label="Nature of your work *" // This associates the InputLabel with Select
                            >
                              {TypeOfInstitution.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.type}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState.error && (
                              <FormHelperText>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
            {activeStep === 2 && (
              <div className="row row-gap-3">
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <Typography className="text-dark">
                          Membership Type{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("planName")}
                      InputProps={{ readOnly: true }}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    // className="mt-0"
                    fullWidth
                    error={!!errors.dummyPlanTypeId}
                    disabled={
                      watch("planName") ===
                      "No active plan for this member type"
                    }
                  >
                    <InputLabel
                      htmlFor="dummyPlanTypeId"
                      className="text-dark" // Ensure that the label is associated with the input
                    >
                      Membership Plan{" "}
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Controller
                      name="dummyPlanTypeId"
                      control={control}
                      rules={{
                        required:
                          watch("planName") ===
                            "No active plan for this member type"
                            ? "No active plan for this member type"
                            : "Please select a membership plan ",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Membership plan type"
                          className="bg-white"
                          id="dummyPlanTypeId"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            setAmount("");
                          }}
                          value={watch("dummyPlanTypeId") || ""}
                        >
                          <MenuItem value="">
                            <em>Select a membership plan </em>
                          </MenuItem>
                          {costType &&
                            costType.map((plan) => (
                              <MenuItem key={plan._id} value={plan._id}>
                                <span>
                                  {plan.planTypeName} ({(plan.planTypeName === ('Annual Plan' || 'Annual')) ? 'Only For New Members' : plan.duration + ' Years'})
                                  {plan.offer === 0 ? (
                                    <span className="mx-2">
                                      {(currency === 1 ? "$" : "₹") +
                                        plan.amount}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="text-decoration-line-through mx-2">
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.amount}
                                      </span>
                                      <span>
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.offerAmount}
                                      </span>
                                      <span className="ms-2">
                                        ({plan.offer + '% off '})
                                      </span>
                                    </>
                                  )}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.dummyPlanTypeId && (
                      <FormHelperText>
                        {errors.dummyPlanTypeId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <Controller
                    control={control}
                    name="chapter"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel className="text-dark" id="event">
                          Which chapter of ELTAI would you like to be part of?
                        </InputLabel>
                        <Select
                          labelId="event"
                          id="event"
                          className="bg-white"
                          label={
                            <Typography className="text-dark">
                              Which chapter of ELTAI would you like to be part
                              of?
                            </Typography>
                          }
                          value={field.value || ""}
                          variant="outlined"
                          onChange={(e) => field.onChange(e.target.value)}
                          disabled={disableChapter}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={"I will join a chapter later"}>
                            <b> I will join a chapter later</b>
                          </MenuItem>
                          {selectedChapters.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-secondary-new alata-light">
                    For more details about chapters{" "}
                    <Link
                      target="_blank"
                      className="text-secondary-new alata-light text-decoration-underline"
                      to="/view-Chapter"
                    >
                      click here
                    </Link>
                  </FormHelperText>
                </div>

                <div className="col-md-4">
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="sigGroups"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="sig"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          options={allSIG}
                          disableCloseOnSelect
                          getOptionLabel={(sig) => sig?.groupName}
                          filterSelectedOptions
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, values) => {
                            const sigGroups = values.map(
                              (value) => value?._id
                            ); // Extract _id from selected options
                            field.onChange(sigGroups); // Pass array of selected _ids to onChange
                          }}
                          value={
                            field.value
                              ? field.value.map((id) =>
                                allSIG.find(
                                  (group) => group._id === id
                                )
                              )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!fieldState?.error}
                              helperText={fieldState?.error?.message}
                              variant="outlined"
                              label={
                                <Typography className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                                  style={{ textOverflow: "ellipsis" }}>
                                  Which Special Interest Group (SIG)
                                  would you like to be part o{" "}
                                  <span style={{ color: "red" }}>
                                    *
                                  </span>
                                </Typography>
                              }
                              placeholder={watch('sigGroups') ? '' : "Select SIG"}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                </div>
                <div className="col-lg-4 col-md-6 col-12 ">
                  <FormControl
                    component="fieldset"
                    error={!!errors.emailMessage}
                  >
                    <FormLabel component="legend" className="text-dark w-75">
                      Would you like to receive updates from ELTAI by email?
                      <span className="text-danger">*</span>
                    </FormLabel>
                    <Controller
                      name="emailMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.emailMessage && (
                      <FormHelperText>
                        {errors.emailMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    component="fieldset"
                    error={!!errors.whatsAppMessage}
                  >
                    <FormLabel component="legend" className="text-dark w-75">
                      Would you like to receive updates from ELTAI by WhatsApp?
                      <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="whatsAppMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.whatsAppMessage && (
                      <FormHelperText>
                        {errors.whatsAppMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {amount && (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="card card-amount-membership">
                      <p className="fw-bold text-center p-3">
                        You have to pay {currency === 1 ? "$" : "₹"}
                        {amount}
                      </p>
                      {/* Assuming this is a form field */}
                      <input
                        type="hidden"
                        {...register("dummyAmount", { value: amount })}
                      />
                      <input
                        type="hidden"
                        {...register("dummyCurrency", { value: currency })}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color rounded-0"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                variant="contained"
                className="menus-color rounded-0"
              >
                {activeStep === steps.length - 1 ? "Pay Now" : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={style}
          container
          className="overflow-y-scroll"
        >
          <Grid item xs={12} mb={3}>
            <h3 className="invoice fw-bold fs-4 mb-4 title-font">Membership Details</h3>
            <p className="alata-regular fw-bold">Membership Type: <span className="fw-normal">{watch('planName')}</span></p>
            <p className="alata-regular fw-bold">Plan Name: <span className="fw-normal"> {costType.find(i => i._id === watch('dummyPlanTypeId'))?.planTypeName}</span></p>
            <p className="alata-regular fw-bold">Membership valid from: <span className="fw-normal">{memberDetails && memberDetails?.plans[0]?.dummyStartDate && memberDetails?.plans[0]?.dummyStartDate.split('T')[0].split('-').reverse().join('-')}</span></p>
            <p className="alata-regular fw-bold">Membership valid till: <span className="fw-normal">{memberDetails && memberDetails?.plans[0]?.dummyEndDate && memberDetails?.plans[0]?.dummyEndDate.split('T')[0].split('-').reverse().join('-')}</span></p>
          </Grid>
          <Grid item xs={12} mb={3}>
            <h3 className="invoice fw-bold fs-4 mb-4 title-font">Payment Details</h3>
            <p className="alata-regular fw-bold">
              Amount Paid:{" "}
              <span className="fw-normal">
                {memberDetails &&
                  memberDetails?.plans[0]?.amount}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Mode of Payment:{" "}
              <span className={`fw-normal ${(memberDetails && memberDetails?.plans[0]?.paymentMethod === 'upi') ? "text-uppercase" : 'text-capitalize'}`}>
                {memberDetails && memberDetails?.plans[0]?.paymentMethod}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Payment UTR Number: <span className="fw-normal">{memberDetails && memberDetails?.plans[0]?.bankTransactionId}</span>{" "}
            </p>
            <p className="alata-regular fw-bold">
              Payment Date:{" "}
              <span className="fw-normal">
                {memberDetails && memberDetails?.plans[0]?.paymentDate &&
                  memberDetails?.plans[0]?.paymentDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
            <Alert className="mb-3" style={{ width: '100%' }} severity="success">Payment Success</Alert>
            <p className="alata-regular fw-bold justified">Note: <span className="fw-normal text-danger">Your login credentials will be sent through email to these mail addresses <span className="menus-green-color">{watch('member[0].email') && watch('member[0].email')},{" "}{watch('member[1].email') && watch('member[1].email')}</span> once the admin activate your account</span></p>
          </Grid>
          <div className="text-center w-100">
            <Button
              className="mx-2"
              variant="contained"
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={() => navigator("/")}
            >
              Home
            </Button>
          </div>
        </Grid>
      </Modal>
      <Modal
        open={openForm}
        onClose={handleFormClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} component={'form'} onSubmit={handleSubmitAdd(addMember)} className="d-flex w-100 align-items-center flex-column gap-3">
          <h4 style={{ color: "#104e29" }} className="title-font">Payment Details</h4>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <span className="alata-regular">Payment Amount: &nbsp;<span className="text-decoration-none text-secondary-new">{watchAdd('currency') === "0" || watchAdd('currency') === 0 || watch('dummyCurrency') === "" ? '₹' : '$'}&nbsp;{watchAdd('amount')}</span>
              </span>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Controller
                control={controlAdd}
                rules={{ required: "Please select a payment type" }}
                name="paymentType"
                render={({ field }) => (
                  <FormControl fullWidth error={!!errorsAdd.paymentType}>
                    <InputLabel id="paymentType-label" >
                      Payment Type <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="paymentType-label"
                      id="paymentType"
                      label='Payment Type *'
                      variant="outlined"
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValueAdd('bankTransactionId', '')
                        setValueAdd('paymentMethod', '')
                        clearErrorsAdd("bankTransactionId");

                      }}
                    >
                      <MenuItem value="0">Cash</MenuItem>
                      <MenuItem value="1">Online</MenuItem>
                      <MenuItem value="2">Cheque /Demand Draft (DD)</MenuItem>
                    </Select>
                    <FormHelperText>{errorsAdd?.paymentType?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {watchAdd('paymentType') === "2" && <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                label={
                  <Typography className="text-dark">
                    Cheque ID / Demand Draft (DD) No<span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                {...registerAdd("bankTransactionId", {
                  required: watchAdd('paymentType') === "2" ? "Please enter Cheque ID" : false,
                })}
                error={!!errorsAdd.bankTransactionId}
                helperText={errorsAdd?.bankTransactionId?.message}
              />
            </Grid>}
            {watchAdd('paymentType') === "1" && <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={controlAdd}
                rules={{ required: "Please select a payment method" }}
                name="paymentMethod"
                render={({ field }) => (
                  <FormControl fullWidth error={!!errorsAdd.paymentMethod}>
                    <InputLabel id="paymentMethod-label" variant="outlined" className="text-dark">
                      Payment Method <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="paymentMethod-label"
                      id="paymentMethod"
                      label='Payment Method *'
                      variant="outlined"
                    >
                      <MenuItem value="upi">UPI</MenuItem>
                      <MenuItem value="netbanking">Net Banking</MenuItem>
                      <MenuItem value="card">Card</MenuItem>
                    </Select>
                    <FormHelperText>{errorsAdd?.paymentMethod?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>}
            {watchAdd('paymentType') === "1" && watchAdd('paymentMethod') !== "card" && <>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label={
                    <Typography className="text-dark">
                      Transaction ID <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  {...registerAdd("bankTransactionId", {
                    required: watchAdd('paymentType') === "1" ? "Please enter Transaction ID" : false,
                  })}
                  error={!!errorsAdd.bankTransactionId}
                  helperText={errorsAdd?.bankTransactionId?.message}
                />
              </Grid>
            </>
            }
          </Grid>
          <div className="d-flex gap-3 justify-content-center mt-3">
            <Button
              variant="outlined"
              color="error"
              className="rounded-0"
              onClick={handleFormClose}
            >
              cancel
            </Button>

            <Button
              variant="contained"
              className="menus-color"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal >
    </>
  );
}

export default AddEditDualTeacher;
