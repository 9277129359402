import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import "../../style/css/upcomingeventssection.css";
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetEventBasedOnFilterMutation } from "../../app/services/eventService";
import { Spinner } from "react-bootstrap";
import { usePaginationAnnualConferenceMutation } from "../../app/services/annualConferenceService";
import GetChapterName from "./GetChapterName";
import GetSIGName from "./GetSigName";
import { useGetEventTypeByIdQuery } from "../../app/services/eventTypeServices";
import { useMediaQuery, useTheme } from "@mui/material";
const GetEventType = ({ id }) => {
  const { data, error, isLoading } = useGetEventTypeByIdQuery(id);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!data) return <p>No event type found</p>;

  return <div>{data?.data?.name}</div>;
};

function UpComingEventsSection() {
  const [getAllEventList] = useGetEventBasedOnFilterMutation();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const memberScope = useSelector(selectCurrentScope); //get scope
  const [getAllAnnouncementPagination] = usePaginationAnnualConferenceMutation();
  const [allConference, setAllConference] = useState([]);
  const [itemsPerPage] = useState(3);
  const theme=useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  console.log("ismobile",isMobile);





  //format Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day of the month
    const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase(); // Get short month and convert to uppercase
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    var data = {
      page: 1,
      itemsPerPage: 1,
      role: ''
    };
    getAllAnnouncementPagination(data).then((res) => {
      if (res?.data?.status) {
        setAllConference(res?.data?.data?.annualConferenceDetails)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }, [getAllAnnouncementPagination])

  useEffect(() => {
  }, [allConference])

  //Get all events list
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: itemsPerPage,
      role: "",
      filter:1,
      isApproved:1
    };
    getAllEventList(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        // setTotalPage(res?.data?.data?.totalCount || 0);
        setAllEvents(res.data?.data?.eventDetails || []);
      } else {
        setLoading(false);
      }
    });
  }, [getAllEventList, itemsPerPage, page]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1); // Increment page count
    getAllPagination();
  };

  const handlePrevPage = () => {
    setPage((prev) => (prev - 1 <= 1 ? 0 : prev - 1));
    getAllPagination();
  };


  return (
    <>
      <div className="events p-md-5 p-3 overallPadding">
        <div className="d-flex justify-content-between mb-3">
          <h2 className="headingConfig custom-header title-font">Our Upcoming Events</h2>
          <div className="d-flex align-items-center">
            {/* <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="arrowConfig" /> </p> */}
            <div className="ms-auto align-items-center mt-2 mt-md-0 content-font">
              {memberScope === "PORTAL_SUPER_ADMIN" && (
                <p className="viewAllEvents mb-0"><Link to="/admin/eventFeed" className="commonColor">View All <EastIcon /> </Link> <ArrowBackIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
              )}
              {memberScope === "PORTAL_ADMIN" && (
                <p className="viewAllEvents mb-0"> <Link to="/assistantAdmin/eventFeed" className="commonColor">
                  View All <EastIcon /></Link>  <ArrowBackIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
              )}
              {memberScope === "PORTAL_SIG_ADMIN" && (
                <p className="viewAllEvents mb-0"> <Link to="/sigAdmin/eventFeed" className="commonColor">View All <EastIcon /> </Link>
                  <ArrowBackIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
              )}
              {memberScope === "PORTAL_CHAPTER_ADMIN" && (
                <p className="viewAllEvents mb-0"><Link to="/chapterAdmin/eventFeed" className="commonColor">View All <EastIcon /> </Link> <ArrowBackIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
              )}
              {memberScope === "PORTAL_MEMBER" && (
                <p className="viewAllEvents mb-0"><Link to="/member/eventFeed" className="commonColor">
                  View All <EastIcon /></Link>
                  <ArrowBackIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
              )}
              {(memberScope === "" ||
                memberScope === undefined ||
                memberScope === null) && (
                  <p className="viewAllEvents mb-0"><Link to="/eventFeed" className="commonColor">View All <EastIcon /> </Link> <ArrowBackIosIcon onClick={handlePrevPage} className="arrowConfig" />  <ArrowForwardIosIcon onClick={allEvents.length === itemsPerPage ? handleNextPage : null} className={`ms-1 arrowConfig ${allEvents.length < itemsPerPage ? 'disable-prev ' : ''} `} /> </p>
                )}
            </div>
          </div>
        </div>
        {allConference && allConference?.length >= 1 ? (
          <>
            {allConference?.map((data) =>
              <div className="conferenceCard">
                <img src={data.imageUrl} alt="img" className="conference"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }} />
                <div className="bg-light">
                  <div className="fullSection">
                    <div className="d-flex justify-content-between pe-4 ps-4 content-font">
                      <p className="bg-article text-light p-2">{data.date}</p>
                      <p className="bg-article text-light h-25 p-2 annaulConference">Annual Conference</p>
                    </div>
                    <h1 className="ps-4 m-0 text-start commonColor title-font">{data.conferenceName}</h1>
                    <div className="d-flex justify-content-between pe-4 ps-4">
                      <div className="mt-3 content-font">
                        <p className="commonColor mb-0">Hosted by</p>
                        <p className="commonColor mb-0"><b>{data.hostedBy}</b></p>
                      </div>
                      <div className="d-flex align-items-end content-font">
                        <Link to={'https://www.eltaiconferences.in'} target="_blank">
                          <p className="commonColor mb-0"><b>Know More</b> <EastIcon className="ms-1" /></p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (<></>)
        }

        {loading ? (
          <Spinner />
        ) : (
          <>
            {allEvents && allEvents?.length >= 1 ? (
              <div className=" events row m-0 pt-5">
                  {allEvents.map((data,index) => (
                    <div className={`col-sm-12 col-md-6 col-lg-4 p-0 gap-3 d-flex ${!isMobile?(index===0?'justify-content-start pe-3':index===2?'justify-content-end ps-3':'justify-content-center'):'justify-content-center px-2'}`}>
                      <div className="ecard mb-lg-0 mb-4">
                        <img src={data.imageUrl} alt="" style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }} />
                        <div className="eventFullSection">
                          <div className="d-flex justify-content-between pe-4 ps-4 content-font">
                            {data.endDate ? (
                              <p className="p-2 eventDate mb-0">
                                {formatDate(data.startDate.split("T")[0])} <br /> - <br /> {formatDate(data.endDate.split("T")[0])}
                              </p>
                            ) : (
                              <p className="oneDayEvent">
                                {formatDate(data.startDate.split("T")[0])}
                              </p>
                            )}
                            <p className="bg-article h-25 px-2">{<GetEventType id={data?.eventTypeId}/>}</p>
                          </div>
                          <h3 className="ps-4 pt-2 truncate-text title-font">{data.title}</h3>
                          <div className="d-flex justify-content-between align-items-end h-100 gap-3 px-4">
                            <div className="d-flex flex-column content-font">
                              <p className="mb-0 commonColor">Posted by</p>
                              <b className="mb-0 commonColor mt-auto">
                                {data?.createdBy?.role === "PORTAL_SUPER_ADMIN" ||
                                  data?.createdBy?.role === "PORTAL_ADMIN"
                                  ? "ELTAI"
                                  : data?.createdBy?.role === "PORTAL_CHAPTER_ADMIN"
                                    ? <GetChapterName id={data?.createdBy?.id} />
                                    : <GetSIGName id={data?.createdBy?.id} />}
                              </b>
                            </div>

                            {/* <a
                              className="d-flex align-items-end text-decoration-none"
                              href={data.meetingURL}
                              target="_blank"
                              style={{ color: "#FF8000" }}
                              role="button"
                              to={""
                                // memberScope === "PORTAL_SUPER_ADMIN"
                                //   ? /admin/VieweventFeed/${data?._id} :
                                //   memberScope === "PORTAL_ADMIN"
                                //     ? /assistantAdmin/VieweventFeed/${data?._id}
                                //     : memberScope === "PORTAL_SIG_ADMIN"
                                //       ? /sigAdmin/VieweventFeed/${data?._id}
                                //       : memberScope === "PORTAL_CHAPTER_ADMIN"
                                //         ? /chapterAdmin/VieweventFeed/${data?._id}
                                //         : memberScope === "PORTAL_MEMBER"
                                //           ? /MEMBER/VieweventFeed/${data?._id}
                                //           : /VieweventFeed/${data?._id}
                              }
                            >
                              <p className="mb-0 d-flex align-items-center commonColor">
                                Know More <EastIcon className="mt-1 ms-1" />
                              </p>
                            </a> */}
                            <Link
                              className="d-flex align-items-end text-decoration-none"
                              to={`/VieweventFeed/${data?._id}`} // Update routing logic as needed
                              style={{ color: "#FF8000" }}
                            >
                              <b className="mb-0 d-flex align-items-center commonColor content-font">
                                Know More <EastIcon className="mt-1 ms-1" />
                              </b>
                            </Link>
                          </div>

                        </div>
                      </div>
                    </div>
                  ))
                  }
              </div>
            ) : (<></>)
            }
          </>
        )}
      </div>

    </>
  );
}

export default UpComingEventsSection;