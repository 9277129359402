import { Box, Button, FormLabel, Grid, Stack, TextField } from '@mui/material'
import React from 'react'
import eltailogo from '../../../src/assets/images/logos/ELTA@I.svg';
import loginimg from '../../../src/assets/images/login/login2.jpg'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation } from '../../app/services/loginServices';
import { toast } from 'react-toastify';

const Forgotpassword = () => {
  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      email: ''
    }
  })
  const [forgotPass] = useForgotPasswordMutation()

  //Form submission
  const submitHandler = (formData) => {
    forgotPass(formData).then(res => {
      if (res?.data?.status) {
        toast.success(res?.data?.message)
        navigate('/login')
      }
      else {
        toast.error(res?.data?.message)
      }
    })
  }

  return (
    <>
      <Grid container className='login-page'>
        <Grid item xs={12} md={6} lg={6} >
          <img src={eltailogo} className="logo" alt="logo" height={75} onClick={() => navigate('/')} />
          <img src={loginimg} alt="Login" className="img-fluid h-100" style={{ objectFit: 'cover'}} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} className='d-flex justify-content-center align-items-center flex-column'>
          <h1 className="text-white fs-3">Let’s get Knowledge!</h1>
          <h2 className="text-white fs-4 lead display-3 text-capitalize">Join with ELT@I to start creating magic.</h2>
          <Box mt={3} component={'form'} onSubmit={handleSubmit(submitHandler)}>
            <Stack direction={'column'}>
              <Stack className='w-100 mb-3'>
                <FormLabel className='text-white mb-3'>Enter a Email ID to send link to change password</FormLabel>
                <TextField className='login-input' id='email' error={!!errors?.email} placeholder='Enter Your Email ID' {...register('email', { required: 'Please enter your email id', pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Please enter a valid mail id' } })}
                  InputProps={{
                    style: { color: 'white' }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'none'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white'
                      },
                    }
                  }}
                />
                {errors?.email?.message && <p className='text-white'>{errors?.email?.message}</p>}
              </Stack>
              <Stack direction={'row'} gap={3} justifyContent={"space-between"}>
                <Button onClick={() => { window.history.back() }} type='button' variant='outlined' style={{ color: "white", borderColor: "white", width: '100%' }} tabIndex={-1}>Back</Button>
                <Button type='submit' variant='contained' className='login-btn' style={{ width: '100%' }}>Submit</Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Forgotpassword