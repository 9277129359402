import {
  Autocomplete,
  Checkbox,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Select from "@mui/material/Select";
import "../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TopHeader from "../../Component/Header/TopHeader";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import FormControl from "@mui/material/FormControl";
import { useLazyGetAllEventTypeQuery } from "../../app/services/eventTypeServices";
import { useLazyGetAllSIGGroupQuery } from "../../app/services/SIGGroupServices";
import {
  useCreateEventMutation,
  useLazyGetEventByIdQuery,
} from "../../app/services/eventService";
import { useLazyGetAllChaptersQuery } from "../../app/services/chapterServices";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCurrentScope,
  selectUserId
} from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import placeholder from '../../../src/assets/images/placeholder/placeholder.png'
import logo from '../../../src/assets/images/logos/ELTA@I.svg'
import { useGetAllMemberMutation } from '../../app/services/memberListServices'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info';
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";

// Dummy steps for the stepper
const steps = [
  "Event Details",
  "Organiser & Sponsor Details",
  "Speaker Details",
  "Links & Attachments",
  "Contact Details"
];
const levelOfSponsors = [
  "Main Sponsor", "Joint Sponsor", "Platinum Sponsor", "Gold Sponsor", "Silver Sponsor", "Knowledge Partner", "Academic Partner"
]
const order = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

function EventForm() {
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { eventId } = useParams(); //get Id from params
  const [createEvent] = useCreateEventMutation();
  const [allEventType, setAllEventType] = React.useState([]);
  const [allSIG, setAllSIG] = React.useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [selectedEvent, setselectedEvent] = useState(false);
  const [getAllEventType] = useLazyGetAllEventTypeQuery();
  const [getAllSIG] = useLazyGetAllSIGGroupQuery();
  const [getAllChapters] = useLazyGetAllChaptersQuery();
  const [getEventById] =
    useLazyGetEventByIdQuery(eventId);
  const [selectedImage, setSelectedImage] = useState(null);
  const [getAllMembers] = useGetAllMemberMutation();
  const [institution, setInstitution] = useState([]);
  const [jointOrganisersInstitutionImage, setJointOrgnisersInstitutionImage] = useState([])
  const [sponsorsInstitutionImage, setSponsorsInstitutionImage] = useState([])
  const navigate = useNavigate();
  const chapter = useSelector(selectCurrentChapterAdmin)
  const sig = useSelector(selectCurrentSig)

  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      eventTypeId: "",//string required
      levelOfEvent: "",//string required
      title: "",//string required
      description: "",//string required
      imageUrl: "",//image 420x160 size<=2mb required
      startDate: "", // date required
      startTime: "", //time optional
      isOneDayEvent: "1",//number yes - 1 no-0 required
      endDate: "",//date optional required if isOneDayEvent ==0
      endTime: "",//time optional
      isPublic: false,//number 
      isMember: memberScope === 'PORTAL_SUPER_ADMIN' ? false : true,
      isEnableRegistration: false,
      eventMode: 'In-Person',//string required
      venue: "",//string optional
      mapLink: '',//string optional
      platform: '',//string optional
      meetingURL: '',//string optional
      jointOrganisers: [
        {
          levelOfOrganiser: 'Main Organiser',//string required
          orderOfOrganiser: '',//number required
          jointOrganiser: '',//string optional
          SIGGroupsId: [],//string optional
          chaptersId: [],//string optional
          memberId: '',//string optional
          institutionName: '',//string optional
          institutionAddress: '',//string optional
          institutionLogo: '',//image optional
          institutionWebsite: '',//string optional
          nonMemberName: '',//string optional
          nonMemberAddress: '',//string optional
          nonMemberLogo: '',//image optional
          nonMemberWebsite: '',//string optional
        }
      ],
      sponsors: [
        {
          levelOfSponsor: '',// string optional
          sponsor: '',//string optional
          SIGGroupsId: [],//string optional
          chaptersId: [],//string optional
          memerId: '',//string optional
          institutionName: '',//string optional
          institutionAddress: '',//string optional
          institutionLogo: '',//image optional
          institutionWebsite: '',//string optional
          nonMemberName: '',//string optional
          nonMemberAddress: '',//string optional
          nonMemberLogo: '',//image optional
          nonMemberWebsite: '',//string optional
        }
      ],
      speakers: [
        {
          role: '',//string optional
          orderOfSpeaker: '',//number required
          name: '',//string optional
          designation: '',//string optional
          institution: '',//string optional
          photo: '',//image optional
          bio: '',
        }
      ],
      isPaidEvent: '0',//number paid-1/free-0 required
      feeDetails: '',//string optional
      registrationLink: '',//string optional
      deadline: '',//string optional
      policy: '',//string optional
      flyer: '',//image optional
      brouchure: '',//pdf optional
      schedule: '',//pdf optional
      readingMaterial: '',//pdf optional
      contacts: [
        {
          role: '',//string optional
          name: '',//string optional
          designation: '',//string optional
          institution: '',//string optional
          mobile: '',//number optional
          email: '', //string optional
        }
      ],
      createdBy: {
        id: "",
        role: "",
      },//required
    },
  });


  const {
    fields: speakerFields,
    append: appendSpeaker,
    remove: removeSpeakerField,
  } = useFieldArray({
    control,
    name: "speakers",
  });


  const {
    fields: jointOrganisersFields,
    append: appendJointOrganisers,
    remove: removejointOrganisers,
  } = useFieldArray({
    control,
    name: "jointOrganisers",
  });

  const {
    fields: sponsorsFields,
    append: appendSponsors,
    remove: removeSponsors,
  } = useFieldArray({
    control,
    name: "sponsors",
  });

  const {
    fields: contactFields,
    append: appendContacts,
    remove: removeContacts,
  } = useFieldArray({
    control,
    name: "contacts",
  });

  const validateAMPM = (value) => {
    const time = dayjs(value)
    // Check if AM/PM is selected
    const timeString = time.format("hh:mm A");
    if (!timeString.includes('AM') && !timeString.includes('PM')) {
      return 'Please select a valid time';
    }
    return true;
  };
  const validateEndTime = (endTime) => {
    const time = dayjs(endTime)
    // Check if AM/PM is selected
    const timeString = time.format("hh:mm A");
    if (!timeString.includes('AM') && !timeString.includes('PM')) {
      return 'Please select a valid time';
    }
    const startTime = getValues("startTime");
    // If no start time, return true to avoid validation errors
    if (!startTime) return true;
    // Compare the end time with the start time using day.js
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    if (end.isBefore(start)) {
      return 'End time must be after start time';
    }
    return true;
  };

  function convertToFormData(rawData) {
    const formData = new FormData();
    Object.entries(rawData).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value instanceof Object && key !== "jointOrganisers" &&
        key !== "sponsors" &&
        key !== "speakers" &&
        key !== "contacts") {
        // If it's a nested object, iterate through its properties
        Object.keys(value).forEach(subKey => {
          formData.append(`${key}.${subKey}`, value[subKey]);
        });
      }
      else if (
        key !== "jointOrganisers" &&
        key !== "sponsors" &&
        key !== "speakers" &&
        key !== "contacts"
      ) {
        const filterFileKeys = ["flyerFile", "brouchureFile", "scheduleFile", "readingMaterialFile", "image"];
        if (!filterFileKeys.includes(key)) {
          formData.append(key, value);
        }
      }
    });
    // Handle nested jointOrganisers array
    if (rawData.jointOrganisers && Array.isArray(rawData.jointOrganisers)) {
      rawData.jointOrganisers.forEach((item, index) => {
        Object.entries(item).forEach(([subKey, subValue]) => {
          const filterFileKeys = ["nonMemberLogoImage", "institutionLogoImage"];
          if (!filterFileKeys.includes(subKey)) {
            if (subValue instanceof File) {
              formData.append(`jointOrganisers[${index}].${subKey}`, subValue ? subValue : '');
            } else {
              formData.append(`jointOrganisers[${index}].${subKey}`, subValue ? subValue : '');
            }
          }
        });
      });
    }
    // Handle nested sponsors array
    if (rawData.sponsors && Array.isArray(rawData.sponsors)) {
      rawData.sponsors.forEach((item, index) => {
        Object.entries(item).forEach(([subKey, subValue]) => {
          const filterFileKeys = ["nonMemberLogoImage", "institutionLogoImage"];
          if (!filterFileKeys.includes(subKey)) {
            if (subValue instanceof File) {
              formData.append(`sponsors[${index}].${subKey}`, subValue ? subValue : '');
            } else {
              formData.append(`sponsors[${index}].${subKey}`, subValue ? subValue : '');
            }
          }
        });
      });
    }
    // Handle nested speakers array
    if (rawData.speakers && Array.isArray(rawData.speakers)) {
      rawData.speakers.forEach((item, index) => {
        Object.entries(item).forEach(([subKey, subValue]) => {
          const filterFileKeys = ["photoImage",];
          if (!filterFileKeys.includes(subKey)) {
            if (subValue instanceof File) {
              formData.append(`speakers[${index}].${subKey}`, subValue ? subValue : '');
            } else {
              formData.append(`speakers[${index}].${subKey}`, subValue ? subValue : '');
            }
          }
        });
      });
    }
    // Handle nested contacts array
    if (rawData.contacts && Array.isArray(rawData.contacts)) {
      rawData.contacts.forEach((item, index) => {
        Object.entries(item).forEach(([subKey, subValue]) => {
          if (subValue instanceof File) {
            formData.append(`contacts[${index}].${subKey}`, subValue ? subValue : '');
          } else {
            formData.append(`contacts[${index}].${subKey}`, subValue ? subValue : '');
          }
        });
      });
    }
    return formData;
  }

  //Based on memberscope
  const getLinkPath = () => {
    switch (memberScope) {
      case "PORTAL_SUPER_ADMIN":
        return "/admin/eventList";
      case "PORTAL_ADMIN":
        return "/assistantAdmin/eventList";
      case "PORTAL_SIG_ADMIN":
        return "/sigAdmin/eventList";
      case "PORTAL_CHAPTER_ADMIN":
        return "/chapterAdmin/eventList";
      default:
        return "";
    }
  };


  //On submit for save and update
  const SubmitEvent = (data) => {
    if (activeStep === steps.length - 1) {
      setLoading(true);
      var newEventData = {
        ...data,
        startDate: new Date(data.startDate)
          .toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-"),
        endDate: ((watch('isOneDayEvent') === '0' || watch('isOneDayEvent') === 0) && data.endDate) ? new Date(data.endDate)
          .toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-") : "",
        startTime: data.startTime ? formatTime(data.startTime) : "",
        endTime: data.endTime ? formatTime(data.endTime) : "",
        feeDetails: (data.isPaidEvent === '1' || data.isPaidEvent === 1) ? data?.feeDetails : ''
      };
      if (
        (userId && memberScope === "PORTAL_SUPER_ADMIN") ||
        (userId && memberScope === "PORTAL_SIG_ADMIN") || (userId && memberScope === "PORTAL_ADMIN") ||
        (userId && memberScope === "PORTAL_CHAPTER_ADMIN")
      ) {
        if (!eventId) {
          newEventData.createdBy = {
            id: userId,
            role: memberScope,
          };
          newEventData.isApproved = selectedEvent?.isApproved;
          if(memberScope==='PORTAL_CHAPTER_ADMIN'){
            newEventData.chaptersId=[chapter?._id]
          }
          else if(memberScope==='PORTAL_SIG_ADMIN'){
            newEventData.SIGGroupsId=[sig?._id]
          }
        }
      }
      const formData = convertToFormData(newEventData)
      createEvent(formData).then((res) => {
        if (res.data.status || res?.data?.message === "Event created successfully" || res?.data?.message === 'Event updated successfully') {
          setLoading(false);
          toast.success(res.data?.message)
          navigate(getLinkPath())
        } else {
          setLoading(false);
          toast.error('Something went wrong !!!')
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  //Time format
  const formatTime = (timeString) => {
    const time = dayjs(timeString);
    // Format the time in 12-hour format with AM/PM
    const formattedTime = time.format("hh:mm A");
    return formattedTime;
  };

  //Get all chapter
  React.useEffect(() => {
    getAllEventType()
      .then((res) => {
        setAllEventType(res?.data?.data.filter(i => {
          if (eventId) {
            return i.isDeleted === 0 || i.isDeleted === 2
          }
          else {
            return i.isDeleted === 0
          }
        }) || []);
      })
      .catch((error) => { });
    getAllSIG().then((res) => {
      setAllSIG(res?.data?.data || []);
    });
    getAllChapters().then((res) => {
      setSelectedChapters(res?.data?.data || []);
    });
    getAllMembers().then(res => {
      if (res?.data?.status) {
        const filtered = res?.data?.data.filter(item => (item.type === ('2' || 2) && item.isDeleted === 0)).map(item => ({ ...item, fullAddress: `${item.address},${item.city},${item.pinCode}` }))
        setInstitution(filtered)
      }
      else {
        setInstitution([])
      }
    });
    if (eventId) {
      getEventById(eventId).then(res => {
        if (res?.data?.status) {
          setselectedEvent(res?.data?.data)
        }
      })
    }
  }, [
    setValue,
    eventId,
    getAllMembers,
    getAllChapters,
    getAllEventType,
    getAllSIG,
    getEventById
  ]);

  //Patch form values from chapterData
  useEffect(() => {
    if (selectedEvent) {
      const newData = { ...selectedEvent, jointOrganisers: selectedEvent?.jointOrganisers?.map(i => ({ ...i, chaptersId: i.chaptersId[0], SIGGroupsId: i.SIGGroupsId[0] })), sponsors: selectedEvent?.sponsors?.map(i => ({ ...i, chaptersId: i.chaptersId[0], SIGGroupsId: i.SIGGroupsId[0] })) }
      Object.entries(newData).forEach(([key, value]) => {
        let startTimeDayjs = dayjs(selectedEvent.startTime, "h:mm A");
        let endTimeDayjs = dayjs(selectedEvent.endTime, "h:mm A");
        if (String(startTimeDayjs.$d) !== "Invalid Date") {
          setValue("startTime", startTimeDayjs);
        } else {
          setValue("startTime", "");
        }
        if (String(endTimeDayjs.$d) !== "Invalid Date") {
          setValue("endTime", endTimeDayjs);
        } else {
          setValue("endTime", "");
        }
        if (key !== 'createdAt' && key !== 'updatedAt' && key !== 'nonMemberDetails' && key !== 'membersId') {
          setValue(key, value);
        }
      });
    }
  }, [selectedEvent, setValue]);

  useEffect(() => {
    if (eventId && selectedEvent) {
      setValue("startDate", formatDate(selectedEvent.startDate));
      setValue("endDate", formatDate(selectedEvent.endDate));
    }
  }, [eventId, setValue, selectedEvent]);

  const eventTypeId = watch("eventTypeId");

  //Date format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //Event image handling
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
    if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
      setSelectedImage(null);
      setValue('imageUrl', "")
      setValue('image', "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue('imageUrl', "")
      setValue('image', "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          if (img.width < 420 || img.width > 430 || img.height < 160 || img.height > 170) {
            setSelectedImage(null);
            setValue('imageUrl', "");
            setValue('image', "");
            setError("image", {
              type: "manual",
              message: "Image dimensions must be between 420x160 and 430x170 pixels",
            });
            return;
          }
          else {
            // Image is valid
            setSelectedImage(reader.result);
            setValue('imageUrl', file);
            clearErrors("image");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const validateFlyerFile = (fileList, key) => {
    const file = fileList[0];
    const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
    const maxSize = 2 * 1024 * 1024; // 2 MB size limit
    if (file && !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
      setValue(key, "");
      setValue(key + 'File', "");
      return "Only image (jpeg, png, jpg) files are allowed!";
    }
    if (file && (file.size > maxSize)) {
      setValue(key, "");
      setValue(key + 'File', "");
      return "File size should not exceed 2MB!";
    }
    setValue(key, file);
    return true
  };
  const validateFile = (fileList, key) => {
    const file = fileList[0];
    const allowedTypes = ["application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB size limit
    if (file && !allowedTypes.includes(file.type)) {
      setValue(key, "");
      setValue(key + 'File', "");
      return "Only PDF files are allowed!";
    }
    if (file && (file.size > maxSize)) {
      setValue(key, "");
      setValue(key + 'File', "");
      return "File size should not exceed 2MB!";
    }
    setValue(key, file);
    return true
  };

  const handleJointOrganisersInstituteImageChange = (event, index) => {
    const file = event.target.files[0];
    const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
    if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
      setJointOrgnisersInstitutionImage((prev) => prev.map((t, i) => i === index ? null : t));
      setValue(`jointOrganisers.${index}.institutionLogo`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setJointOrgnisersInstitutionImage((prev) => prev.map((t, i) => i === index ? null : t));
      setValue(`jointOrganisers.${index}.institutionLogo`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Image is valid
          setJointOrgnisersInstitutionImage((prevImages) => {
            const newImages = [...prevImages]; // Create a copy of the array
            newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
            return newImages;
          });
          setValue(`jointOrganisers.${index}.institutionLogo`, file);
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSponsorsInstituteImageChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSponsorsInstitutionImage((prev) => prev.map((t, i) => i === index ? null : t));
      setValue(`sponsors.${index}.institutionLogo`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSponsorsInstitutionImage((prev) => prev.map((t, i) => i === index ? null : t));
      setValue(`sponsors.${index}.institutionLogo`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Image is valid
          setSponsorsInstitutionImage((prevImages) => {
            const newImages = [...prevImages]; // Create a copy of the array
            newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
            return newImages;
          });
          setValue(`sponsors.${index}.institutionLogo`, file);
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const handleJointOrganisersNonInstituteImageChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setValue(`jointOrganisers.${index}.nonMemberLogoBase64`, '');
      setValue(`jointOrganisers.${index}.nonMemberLogoImage`, "")
      setValue(`jointOrganisers.${index}.nonMemberLogo`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setValue(`jointOrganisers.${index}.nonMemberLogoBase64`, '');
      setValue(`jointOrganisers.${index}.nonMemberLogoImage`, "")
      setValue(`jointOrganisers.${index}.nonMemberLogo`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Validate the image dimensions (140x140 to 150x150)
          if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
            // Reset the image if dimensions are invalid
            setValue(`jointOrganisers.${index}.nonMemberLogoBase64`, "");
            setValue(`jointOrganisers.${index}.nonMemberLogo`, "")
            setValue(`jointOrganisers.${index}.nonMemberLogoImage`, "")
            setError(`jointOrganisers.${index}.nonMemberLogoImage`, { message: "Image dimensions must be between 140x140 and 150x150 pixels" });
            return; // Early return to exit the function
          }
          setValue(`jointOrganisers.${index}.nonMemberLogoBase64`, reader?.result);
          setValue(`jointOrganisers.${index}.nonMemberLogo`, file);
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSponsorsNonInstituteImageChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setValue(`sponsors.${index}.nonMemberLogoBase64`, "");
      setValue(`sponsors.${index}.nonMemberLogoImage`, "")
      setValue(`sponsors.${index}.nonMemberLogo`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setValue(`sponsors.${index}.nonMemberLogoBase64`, "");
      setValue(`sponsors.${index}.nonMemberLogoImage`, "")
      setValue(`sponsors.${index}.nonMemberLogo`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Validate the image dimensions (140x140 to 150x150)
          if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
            // Reset the image if dimensions are invalid
            setValue(`sponsors.${index}.nonMemberLogoBase64`, "");
            setValue(`sponsors.${index}.nonMemberLogo`, "")
            setValue(`sponsors.${index}.nonMemberLogoImage`, "")
            setError(`sponsors.${index}.nonMemberLogoImage`, { message: "Image dimensions must be between 140x140 and 150x150 pixels" });
            return; // Early return to exit the function
          }
          setValue(`sponsors.${index}.nonMemberLogoBase64`, reader?.result);
          setValue(`sponsors.${index}.nonMemberLogo`, file);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //Speaker image handling
  const handleSpeakerImageChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      // setSelectedSpeakerImage((prevImages) => {
      //   const newImages = [...prevImages];
      //   newImages[index] = null;
      //   return newImages;
      // });
      setValue(`speakers.[${index}].photoBase64`, "")
      setValue(`speakers.[${index}].photoImage`, "")
      setValue(`speakers.${index}.photo`, "")
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      // setSelectedSpeakerImage((prevImages) => {
      //   const newImages = [...prevImages];
      //   newImages[index] = null;
      //   return newImages;
      // });
      setValue(`speakers.[${index}].photoBase64`, '')
      setValue(`speakers.[${index}].photoImage`, "")
      setValue(`speakers.${index}.photo`, "")
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Validate the image dimensions (140x140 to 150x150)
          if (img.width < 140 || img.width > 150 || img.height < 140 || img.height > 150) {
            // // Reset the image if dimensions are invalid
            // setSelectedSpeakerImage((prevImages) => {
            //   const newImages = [...prevImages];
            //   newImages[index] = null;
            //   return newImages;
            // });
            setValue(`speakers.[${index}].photoBase64`, '')
            setValue(`speakers.[${index}].photo`, "")
            setValue(`speakers.[${index}].photoImage`, "")
            setError(`speakers.${index}.photoImage`, { message: "Image dimensions must be between 140x140 and 150x150 pixels" });
            return; // Early return to exit the function
          }
          // Image is valid
          // setSelectedSpeakerImage((prevImages) => {
          //   const newImages = [...prevImages]; // Create a copy of the array
          //   newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
          //   return newImages;
          // });

          setValue(`speakers.[${index}].photoBase64`, reader.result)
          setValue(`speakers.[${index}].photo`, file);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const isMember = watch("isMember");
  const isPublic = watch("isPublic");
  const isEnableRegistration = watch('isEnableRegistration');


  //patch Value ispublic
  useEffect(() => {
    if (isPublic) {
      setValue("isMember", false);
    }
  }, [isPublic, setValue]);

  //patch Value member
  useEffect(() => {
    if (isMember) {
      setValue("isPublic", false);
    }
  }, [isMember, setValue]);

  //Validate year
  const validateYearLength = (value) => {
    const year = new Date(value).getFullYear().toString();
    if (year.length > 4) {
      return "Year cannot exceed 4 digits";
    }
    return true;
  };
  const validateStartDate = (value) => {
    const endDate = new Date(value);
    const startDate = new Date(getValues("startDate"));
    const year = new Date(value).getFullYear().toString();
    if (year.length > 4) {
      return "Year cannot exceed 4 digits";
    }
    if (endDate <= startDate) {
      return "Please select end date after start date by at least 1 days";
    }
    return true;
  };
  const removeImage = (key) => {
    // Clear the selected role image
    setSelectedImage('');
    // Update the form field value
    setValue(key, '');
    setValue(`${key}File`, '')
    // Optionally clear errors if the field is mandatory during editing
    clearErrors(`${key}File`);
  };
  const appendSponsorsCondition = () => {
    if (watch(`sponsors.${0}.sponsor`) === 'None') {
      return
    }
    else {
      appendSponsors({})
    }
  }

  const getTextWithEllipsis = (key) => {
    const name = watch(key)?.name || '';  // Get the name dynamically from 'watch'
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;  // Get the viewport width

    // Adjust the maximum number of characters based on the viewport width
    const maxChars = Math.floor(viewportWidth / 80);  // Adjust the divisor as needed
    // If name is too long, slice it and append ellipsis
    if (name.length > maxChars) {
      return `${name.slice(0, maxChars)}...`;
    }
    return name;
  };
  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">{eventId ? "Edit Event" : "Add Event"}</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          className="p-md-5 p-3 overallPadding"
          sx={{ width: "100%", }}
        >
          <div className="w-100 text-end mb-3">
            <Button
              onClick={() => navigate(getLinkPath())}
              variant="contained"
              className="menus-color"
            >
              Back to event table
            </Button>
          </div>
          <Stepper activeStep={activeStep} className="mt-5">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel className="alata-regular">{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form className="mt-5" onSubmit={handleSubmit(SubmitEvent)}>
            {activeStep === 0 && (
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} className="pt-1">
                  {(memberScope === "PORTAL_SUPER_ADMIN" || memberScope === "PORTAL_ADMIN") && (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={isPublic} />}
                        {...register("isPublic", { required: { value: !watch('isMember') && !watch('isPublic'), message: 'Please select either public or member' } })}
                        label="Public"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={isMember} />}
                        {...register("isMember")}
                        label="Member"
                      />
                    </>
                  )}
                  <FormControlLabel
                    control={<Checkbox checked={isEnableRegistration} />}
                    {...register("isEnableRegistration", { required: { value: !watch('isMember') && !watch('isPublic'), message: 'Please select either public or member' } })}
                    label="Enable Registration"
                  />
                  {(!watch('isMember') && !watch('isPublic')) && <FormHelperText error={!!(errors?.isMember || errors?.isPublic)}>{errors?.isMember?.message || errors?.isPublic?.message}</FormHelperText>}
                </Grid>
                <Grid item xs={12} className="pt-0">
                  <Typography className="alata-regular fw-semibold fs-5">Event</Typography>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Controller control={control} rules={{ required: "Please select type of event", }} name="eventTypeId" render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <InputLabel className="text-dark" id="event">
                        Type of Event <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="event"
                        id="event"
                        className="bg-white"
                        label={
                          <Typography className="text-dark">
                            Type of Event<span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        value={field.value ? field.value : ""}
                        name={field.eventTypeId}
                        variant="outlined"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!fieldState.error}
                        placeholder="Type of Event"
                      >
                        {allEventType.map((event) => (
                          <MenuItem key={event.id} value={event._id}>
                            {event.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!eventTypeId && (
                        <FormHelperText className="text-danger">
                          {fieldState?.error?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Controller control={control} rules={{ required: "Please select level of event", }} name="levelOfEvent" render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <InputLabel className="text-dark" id="level">
                        Level of the Event<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="event"
                        id="event"
                        className="bg-white"
                        label={
                          <Typography className="text-dark">
                            Level of the Event<span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        value={field.value ? field.value : ""}
                        name={field.eventTypeId}
                        variant="outlined"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!fieldState.error}
                        placeholder="Level of the Event"
                      >
                        <MenuItem value='International'>
                          International
                        </MenuItem>
                        <MenuItem value='National'>
                          National
                        </MenuItem>
                        <MenuItem value='State-level'>
                          State-Level
                        </MenuItem>
                        <MenuItem value='Regional'>
                          Regional
                        </MenuItem>
                      </Select>
                      {fieldState?.error && (
                        <FormHelperText className="text-danger">
                          {fieldState?.error?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        Event Title <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("title", {
                      required: "Please enter a title",
                    })}
                    error={!!errors?.title}
                    helperText={errors?.title?.message}
                    placeholder="Event Title"
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    rows={4}
                    label={
                      <Typography className="text-dark">
                        Event Description(250 words) <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register("description", {
                      required: "Please enter a description", validate: (value) => {
                        if (value.split(' ').length > 250) {
                          return 'Only 250 words allowed'
                        }
                        return true
                      }
                    })}
                    error={!!errors?.description}
                    helperText={errors?.description?.message}
                    placeholder="Event Description"
                  />
                </Grid>
                <Grid item lg={5} md={6} xs={12}>
                  <FormLabel className="ps-3 fs-6">
                    <Typography className="text-dark">Event Cover Image <span className="text-danger">*</span></Typography>
                  </FormLabel>
                  <div>
                    <img
                      src={selectedImage ? selectedImage : watch("imageUrl") ? `${watch("imageUrl")}?t=${new Date().getTime()}` : placeholder} // Fetched image from backend with cache busting
                      height="100"
                      alt="eventform"
                      style={{
                        objectFit: "scale-down",
                        width: "200px",
                      }}
                    />
                    <TextField
                      sx={{ mt: 2 }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: "image/jpeg, image/png" }}
                      id="imageUrl"
                      label={
                        <Typography className="text-dark">
                          Event Cover Image <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      style={{ display: 'none' }}
                      type="file"
                      {...register(`image`, {
                        required: { value: !watch('imageUrl'), message: 'Please select a image' }, validate: (value) => {
                          const file = value[0];
                          if (!watch(`imageUrl`)) {
                            const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                            if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                              setValue(`imageUrl`, "")
                              return "Please select a image file"
                            }
                            if (file && file.size > 2 * 1024 * 1024) {
                              setValue(`imageUrl`, "")
                              return "File size exceeds 2mb"
                            }
                            return true;
                          }
                        }, onChange: (e) => handleImageChange(e),
                      })}
                    />
                    <label
                      htmlFor="imageUrl"
                      style={{ marginLeft: "10px" }}
                    >
                      <Button
                        variant="contained"
                        className="menus-color"
                        component="span"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                      </Button>
                    </label>
                    {!!errors?.image && (
                      <FormHelperText className="ps-3" error={!!errors?.image}>
                        {errors?.image?.message}
                      </FormHelperText>
                    )}
                  </div>
                  <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 420x160 and 430x170 pixels</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                  <Typography className="alata-regular fw-semibold fs-5">Date & Time</Typography>
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <Controller
                    control={control}
                    name="isOneDayEvent"
                    render={({ field, fieldState }) => (
                      <>
                        <FormControl fullWidth>
                          <FormLabel className="text-dark">
                            <Typography >Is it a one-day event?</Typography>
                          </FormLabel>
                          <RadioGroup
                            row
                            value={field.value}
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              field.onChange(selectedValue);
                              if (selectedValue === "1" || selectedValue === "1") {
                                setValue('endDate', '')
                              }
                              else {
                                setValue('endTime', '')
                                setValue('startTime', '')
                                setValue('endDate', '')
                              }
                            }}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label={'Yes'}
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label={'No'}
                            />
                          </RadioGroup>
                          {fieldState?.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="startDate"
                    label={
                      <Typography className="text-dark">
                        Event Start Date <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    type="date"
                    {...register("startDate", {
                      required: "Please select a start date", validate: validateYearLength, onChange: () => {
                        setValue('endDate', '')
                      }, min: { value: eventId ? "" : today.toISOString().split("T")[0], message: 'Please select future or present date' }
                    })}
                    error={!!errors?.startDate}
                    helperText={errors?.startDate?.message}
                  />
                </Grid>
                {(watch('isOneDayEvent') === "1" || watch('isOneDayEvent') === 1) &&
                  <>
                    <Grid item lg={4} md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          control={control}
                          name="startTime"
                          rules={{ required: 'Please select event start time', validate: validateAMPM }}
                          render={({ field: { onChange, value }, fieldState }) => (
                            <TimePicker
                              label={<Typography className="text-dark">Event Start Time <span className="text-danger">*</span></Typography>}
                              className={'time-picker'}
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              value={value || null}
                              slotProps={{
                                textField: {
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                }
                              }}
                              onChange={(newTime) => {
                                onChange(newTime);
                                setValue('endTime', '')
                                clearErrors('endTime')
                              }}
                              renderInput={(params) => (
                                <TextField {...params}
                                />
                              )}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          control={control}
                          name="endTime"
                          rules={{ required: 'Please select event end time', validate: validateEndTime }}
                          render={({ field: { onChange, value }, fieldState }) => (
                            <>
                              <TimePicker
                                className={'time-picker'}
                                label={<Typography className="text-dark">Event End Time <span className="text-danger">*</span></Typography>}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={value || null}
                                slotProps={{
                                  textField: {
                                    error: !!fieldState.error,
                                    helperText: fieldState.error?.message,
                                  }
                                }}
                                onChange={(endTime) => {
                                  onChange(endTime);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                  />
                                )}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>}
                {(watch('isOneDayEvent') === "0" || watch('isOneDayEvent') === 0) &&
                  <>
                    <Grid item lg={4} md={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="endDate"
                        label={
                          <Typography className="text-dark">
                            Event End Date <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        inputProps={{
                          min: watch("startDate"),
                        }}
                        variant="outlined"
                        type="date"
                        {...register("endDate", {
                          required: "Please select a event end date",
                          validate: (value) => (validateStartDate(value)),
                        })}
                        error={!!errors?.endDate}
                        helperText={errors?.endDate?.message}
                      />
                    </Grid>

                  </>
                }
                <Grid item xs={12}>
                  <hr />
                  <Typography className="alata-regular fw-semibold fs-5">Event Location</Typography>
                </Grid>
                <Grid item xs={12} className="pt-1">
                  <Controller
                    control={control}
                    name="eventMode"
                    render={({ field, fieldState }) => (
                      <>
                        <FormControl fullWidth>
                          <FormLabel className="text-dark">
                            <Typography >Event Mode</Typography>
                          </FormLabel>
                          <RadioGroup
                            row
                            value={field.value}
                            onChange={(event) => {
                              const selectedValue = event.target.value;
                              field.onChange(selectedValue);
                              if (selectedValue === 'In-Person') {
                                setValue('meetingURL', '');
                                setValue('platform')
                              }
                              else if (selectedValue === 'Online') {
                                setValue("mapLink", "");
                                setValue("venue", "");
                              }
                            }}
                          >
                            <FormControlLabel
                              value="In-Person"
                              control={<Radio />}
                              label={'In-Person'}
                            />
                            <FormControlLabel
                              value="Online"
                              control={<Radio />}
                              label={'Online'}
                            />
                            <FormControlLabel
                              value="Hybrid"
                              control={<Radio />}
                              label={'Hybrid'}
                            />
                          </RadioGroup>
                          {fieldState?.error && (
                            <FormHelperText error>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                {
                  (watch('eventMode') === 'In-Person') ?
                    <>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{ width: "100%" }}
                          label={
                            <Typography className="text-dark">
                              Venue <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register("venue", {
                            required: "Please enter a venue",
                          })}
                          error={!!errors?.venue}
                          helperText={errors?.venue?.message}
                          placeholder="Event Venue"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{ width: "100%" }}
                          label={
                            <Typography className="text-dark">
                              Google Map Link <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register("mapLink", {
                            required: "Please enter google map link",
                            pattern: {
                              value:
                                /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                              message: "Please enter a valid link",
                            },
                          })}
                          error={!!errors?.mapLink}
                          helperText={errors?.mapLink?.message}
                          placeholder="Google Map Link"
                        />
                      </Grid>
                    </>
                    :
                    (watch('eventMode') === 'Online') ?
                      <>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Controller
                            control={control}
                            name="platform"
                            rules={{ required: 'Please select meeting platform' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth error={!!fieldState?.error}>
                                  <FormLabel className="text-dark">
                                    <Typography >Meeting Platform <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Zoom"
                                      control={<Radio />}
                                      label={'Zoom'}
                                    />
                                    <FormControlLabel
                                      value="Google Meet"
                                      control={<Radio />}
                                      label={'Google Meet'}
                                    />
                                    <FormControlLabel
                                      value="MS Teams"
                                      control={<Radio />}
                                      label={'MS Teams'}
                                    />
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState?.error?.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Meeting Link <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register('meetingURL', {
                              required: "Please enter a meeting link",
                              pattern: {
                                value:
                                  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                message: "Please enter a valid link",
                              },
                            })}
                            error={!!errors?.meetingURL}
                            helperText={errors?.meetingURL?.message}
                            placeholder="Meeting Link"
                          />
                        </Grid>
                      </>
                      : <>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Venue <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register("venue", {
                              required: "Please enter a venue",
                            })}
                            error={!!errors?.venue}
                            helperText={errors?.venue?.message}
                            placeholder="Event Venue"
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Google Map Link <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register("mapLink", {
                              required: "Please enter a map link",
                              pattern: {
                                value:
                                  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                message: "Please enter a valid link",
                              },
                            })}
                            error={!!errors?.mapLink}
                            helperText={errors?.mapLink?.message}
                            placeholder="Google Map Link"
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Controller
                            control={control}
                            name="platform"
                            rules={{ required: 'Please select meeting platform' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth>
                                  <FormLabel className="text-dark">
                                    <Typography >Meeting Platform <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Zoom"
                                      control={<Radio />}
                                      label={'Zoom'}
                                    />
                                    <FormControlLabel
                                      value="Google Meet"
                                      control={<Radio />}
                                      label={'Google Meet'}
                                    />
                                    <FormControlLabel
                                      value="MS Teams"
                                      control={<Radio />}
                                      label={'MS Teams'}
                                    />
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Meeting Link <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register('meetingURL', {
                              required: "Please enter a meeting link",
                            })}
                            error={!!errors?.meetingURL}
                            helperText={errors?.meetingURL?.message}
                            placeholder="Meeting Link"
                          />
                        </Grid>
                      </>
                }
              </Grid>
            )}
            {activeStep === 1 && (
              <>
                <Grid container rowSpacing={3} columnSpacing={3} className="mt-3 d-flex align-items-center">
                  <Grid item lg={4} md={6} xs={12} className="me-auto">
                    <h3 className="alata-regular fs-5 title-font">Organisers</h3>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} className="ms-auto text-end">
                    <Button onClick={() => appendJointOrganisers({})} variant="contained" sx={{ minWidth: '300px' }} className="menus-color">Add Organisers</Button>
                  </Grid>
                </Grid>
                {jointOrganisersFields && jointOrganisersFields.map((field, index) => {
                  return (
                    <>
                      {index === 0 && <hr />}
                      <Grid className={`${index !== 0 ? 'mt-2' : ''}`} container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12} className={`d-flex justify-content-between ${index === 0 ? 'flex-sm-row flex-column' : ''} align-items-center`}>
                          <Controller
                            control={control}
                            name={`jointOrganisers.${index}.jointOrganiser`}
                            rules={{ required: 'Please select a option' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth>
                                  <FormLabel className="text-dark">
                                    <h4 >{index === 0 ? 'Main Organiser' : `Collaborator ${index}`}</h4>
                                  </FormLabel>
                                  <FormLabel className="text-dark">
                                    <Typography >Type of the Organiser <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    className="d-flex flex-sm-row flex-column"
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                      setValue(`jointOrganisers.${index}`, {
                                        levelOfOrganiser: '',
                                        orderOfOrganiser: '',
                                        jointOrganiser: selectedValue,
                                        chaptersId: '',
                                        SIGGroupsId: '',
                                        institutionAddress: '',
                                        institutionLogo: '',
                                        institutionName: '',
                                        institutionWebsite: '',
                                        nonMemberAddress: '',
                                        nonMemberLogo: '',
                                        nonMemberName: '',
                                        nonMemberWebsite: ''
                                      })
                                      setValue(`jointOrganisers.${0}.levelOfOrganiser`, 'Main Organiser')
                                      clearErrors('jointOrganisers')
                                    }}
                                  >
                                    {((watch(`jointOrganisers`).every((t, i) => t.jointOrganiser !== 'ELTAI') || watch(`jointOrganisers`).findIndex(t => t.jointOrganiser === 'ELTAI') === index)) && <FormControlLabel
                                      value="ELTAI"
                                      control={<Radio />}
                                      label={'ELTAI'}
                                    />}
                                    <FormControlLabel
                                      value="ELTAI SIG"
                                      control={<Radio />}
                                      label={'ELTAI SIG'}
                                      disabled={allSIG.length < 1}
                                    />
                                    <FormControlLabel
                                      value="ELTAI Chapter"
                                      control={<Radio />}
                                      label={'ELTAI Chapter'}
                                      disabled={selectedChapters.length < 1}
                                    />
                                    <FormControlLabel
                                      value="Member Institution"
                                      control={<Radio />}
                                      label={'Member Institution'}
                                      disabled={institution.length < 1}
                                    />
                                    <FormControlLabel
                                      value="Non-Member Institution"
                                      control={<Radio />}
                                      label={'Non-Member Institution'}
                                    />
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                          {index !== 0 && <div><Tooltip title='Remove organiser'><Button onClick={() => removejointOrganisers(index)} className="menus-color" variant="contained"><DeleteIcon></DeleteIcon></Button></Tooltip></div>}
                        </Grid>
                        {watch(`jointOrganisers.${index}.jointOrganiser`) &&
                          <>
                            <Grid item lg={4} md={6} xs={12} >
                              <Controller
                                control={control}
                                name={`jointOrganisers.${index}.levelOfOrganiser`}
                                rules={{ required: 'Please select a level of organiser' }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <FormControl fullWidth error={!!fieldState.error}>
                                      <InputLabel className="text-dark" id="event">
                                        Level of the Organiser <span className="text-danger">*</span>
                                      </InputLabel>
                                      <Select
                                        className="bg-white"
                                        labelId="event"
                                        id="event"
                                        label={
                                          <Typography className="text-dark">
                                            Level of the Organiser
                                          </Typography>
                                        }
                                        disabled={index === 0}
                                        value={field.value || ""}
                                        variant="outlined"
                                        onChange={(e) => field.onChange(e.target.value)}
                                      >
                                        <MenuItem value={'Main Organiser'}>
                                          Main Organiser
                                        </MenuItem>
                                        <MenuItem value={'Collaborator'}>
                                          Collaborator
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    <FormHelperText error={!!fieldState.error}>
                                      {fieldState?.error?.message}
                                    </FormHelperText>
                                  </>
                                )}
                              />
                            </Grid>
                            <Grid item lg={4} md={6} xs={12} >
                              <Controller
                                control={control}
                                name={`jointOrganisers.${index}.orderOfOrganiser`}
                                rules={{ required: 'Please select a order of organiser' }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <FormControl fullWidth error={!!fieldState.error}>
                                      <InputLabel className="text-dark" id="event">
                                        Order of the Organiser
                                      </InputLabel>
                                      <Select
                                        className="bg-white"
                                        labelId="event"
                                        id="event"
                                        label={
                                          <Typography className="text-dark">
                                            Order of the Organiser
                                          </Typography>
                                        }
                                        value={field.value || ""}
                                        variant="outlined"
                                        onChange={(e) => field.onChange(e)}
                                      >
                                        {order.map(option => {
                                          const isDisabled = watch('jointOrganisers').some(organiser => organiser.orderOfOrganiser === option);
                                          return (
                                            <MenuItem key={option} value={option} disabled={isDisabled}>
                                              {option}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormHelperText error={!!fieldState.error}>
                                      {fieldState?.error?.message}
                                    </FormHelperText>
                                  </>
                                )}
                              />
                            </Grid>

                          </>
                        }
                        {watch(`jointOrganisers.${index}.jointOrganiser`) === 'ELTAI' ?
                          <>
                            <Grid item lg={4} md={6} sm={6} xs={12} className="text-center" >
                              <img src={logo} alt="ELTAI LOGO" className="w-50 img-fluid" />
                              <Typography className="alata-medium">English Language Teachers’ Association of India (ELTAI)</Typography>
                            </Grid>
                          </> : watch(`jointOrganisers.${index}.jointOrganiser`) === 'ELTAI SIG' ?
                            <>
                              <Grid item lg={4} md={6} sm={6} xs={12} >
                                <Controller
                                  control={control}
                                  name={`jointOrganisers.${index}.SIGGroupsId`}
                                  rules={{ required: 'Please select a SIG group' }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <Autocomplete
                                        id="tags-outlined-chapter"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        options={allSIG}
                                        getOptionLabel={(option) =>
                                          option?.groupName || ""
                                        }
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        getOptionDisabled={(option) => watch('jointOrganisers').some((item2) => { return option._id === item2.SIGGroupsId })}
                                        filterSelectedOptions
                                        onChange={(event, value) => {
                                          field.onChange(value ? value._id : "");// Pass the selected _id to onChange
                                        }}
                                        value={
                                          field.value
                                            ? allSIG.find((group) => group._id === field.value) || null
                                            : null
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="memberId"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            label={
                                              <Typography className="text-dark">
                                                Name of the Organiser{" "}
                                                <span style={{ color: "red" }}>*</span>
                                              </Typography>
                                            }
                                            placeholder="Select SIG"
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                />
                              </Grid>
                            </> : watch(`jointOrganisers.${index}.jointOrganiser`) === 'ELTAI Chapter' ?
                              <>
                                <Grid item lg={4} md={6} sm={6} xs={12} >
                                  <Controller
                                    control={control}
                                    name={`jointOrganisers.${index}.chaptersId`}
                                    rules={{ required: 'Please select a chapter' }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <Autocomplete
                                          id="tags-outlined-chapter"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          options={selectedChapters}
                                          getOptionLabel={(option) =>
                                            option?.title || ""
                                          }
                                          isOptionEqualToValue={(option, value) => option._id === value._id}
                                          getOptionDisabled={(option) => watch('jointOrganisers').some((item2) => { return option._id === item2.chaptersId })}
                                          filterSelectedOptions
                                          onChange={(event, value) => {
                                            // Check if value is not null before accessing _id
                                            field.onChange(value ? value._id : "");
                                          }}
                                          value={
                                            field.value
                                              ? selectedChapters.find((group) => group._id === field.value) || null
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="memberId"
                                              error={!!fieldState.error}
                                              helperText={fieldState.error?.message}
                                              label={
                                                <Typography className="text-dark">
                                                  Name of the Organiser{" "}
                                                  <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                              }
                                              placeholder="Select Chapter"
                                            />
                                          )}
                                        />
                                      </>
                                    )}
                                  />
                                </Grid>
                              </> : watch(`jointOrganisers.${index}.jointOrganiser`) === 'Member Institution' ?
                                <>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <Controller
                                      control={control}
                                      name={`jointOrganisers.${index}.memberId`}
                                      rules={{
                                        required: "Please select an institution member ID",
                                      }}
                                      render={({ field, fieldState }) => (
                                        <Autocomplete
                                          id="tags-outlined-chapter"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          options={institution}
                                          getOptionLabel={(option) =>
                                            option?.userId || ""
                                          }
                                          isOptionEqualToValue={(option, value) => option._id === value._id}
                                          getOptionDisabled={(option) => watch('jointOrganisers').some((item2) => { return option._id === item2.memberId })}
                                          filterSelectedOptions
                                          onChange={(event, value) => {
                                            if (value) {
                                              field.onChange(value._id); // Pass the selected _id to onChange
                                              setValue(`jointOrganisers.${index}.institutionName`, value?.institution || value?.organisationName || "");
                                              setValue(`jointOrganisers.${index}.institutionAddress`, value.fullAddress || "");
                                              setValue(`jointOrganisers.${index}.institutionLogo`, value.uploadInstitutionLogo || value.uploadOrganisationLogo || "");
                                              setValue(`jointOrganisers.${index}.institutionWebsite`, value.websiteAddressInstitution || value.websiteAddressOrganisation || "");
                                              clearErrors(`jointOrganisers.${index}.institutionName`);
                                              clearErrors(`jointOrganisers.${index}.institutionAddress`);
                                              clearErrors(`jointOrganisers.${index}.institutionLogo`);
                                              clearErrors(`jointOrganisers.${index}.institutionLogoImage`);
                                              clearErrors(`jointOrganisers.${index}.institutionWebsite`);
                                            } else {
                                              field.onChange(null); // Clear value if no selection
                                              setValue(`jointOrganisers.${index}.institutionName`, "");
                                              setValue(`jointOrganisers.${index}.institutionAddress`, "");
                                              setValue(`jointOrganisers.${index}.institutionLogo`, "");
                                              setValue(`jointOrganisers.${index}.institutionWebsite`, "");
                                            }
                                          }}
                                          value={
                                            field.value
                                              ? institution.find((group) => group._id === field.value) || null
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="memberId"
                                              error={!!fieldState.error}
                                              helperText={fieldState.error?.message}
                                              label={
                                                <Typography className="text-dark">
                                                  Institution Member ID{" "}
                                                  <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                              }
                                              placeholder="Select Member Institution"
                                            />
                                          )}
                                        />
                                      )}
                                    />

                                  </Grid>

                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Name of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.institutionName`, {
                                        required: "Please enter institution name",
                                        minLength: { value: 3, message: 'Please enter a valid name' }
                                      })}
                                      error={!!!!errors?.jointOrganisers?.[index]?.institutionName}
                                      helperText={errors?.jointOrganisers?.[index]?.institutionName?.message}
                                      placeholder="Name of the Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Address of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.institutionAddress`, {
                                        required: "Please enter institution address",
                                      })}
                                      error={!!!!errors?.jointOrganisers?.[index]?.institutionAddress}
                                      helperText={errors?.jointOrganisers?.[index]?.institutionAddress?.message}
                                      placeholder="Address of the Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Website of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.institutionWebsite`, {
                                        required: "Please enter institution website",
                                        pattern: {
                                          value:
                                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                          message: "Please enter a valid website address",
                                        },
                                      })}
                                      error={!!errors?.jointOrganisers?.[index]?.institutionWebsite}
                                      helperText={errors?.jointOrganisers?.[index]?.institutionWebsite?.message}
                                      placeholder="Website of the Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12}>
                                    <div className="d-flex flex-column">
                                      <FormLabel className="ps-3 fs-6">
                                        <Typography className="text-dark">Logo of the Member Institution <span className="text-danger">*</span></Typography>
                                      </FormLabel>
                                      <img
                                        src={jointOrganisersInstitutionImage[index] ? jointOrganisersInstitutionImage[index] : watch(`jointOrganisers.${index}.institutionLogo`) ? watch(`jointOrganisers.${index}.institutionLogo`) : placeholder} // Fetched image from backend with cache busting
                                        height="100"
                                        alt="eventform"
                                        style={{
                                          objectFit: "scale-down",
                                          width: "200px",
                                        }}
                                      />
                                      <TextField
                                        sx={{ mt: 2 }}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        id={`institutionLogo${index}`}
                                        label={
                                          <Typography className="text-dark">
                                            Logo of the Member Institution <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        style={{ display: 'none' }}
                                        type="file"
                                        {...register(`jointOrganisers.${index}.institutionLogoImage`, {
                                          required: !watch(`jointOrganisers.${index}.institutionLogo`) && 'Please select a image', validate: (value) => {
                                            const file = value[0];
                                            if (!watch(`jointOrganisers.${index}.institutionLogo`)) {
                                              const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                                              if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                                                setValue(`jointOrganisers.${index}.institutionLogo`, "")
                                                return "Please select a image file"
                                              }
                                              if (file && file.size > 2 * 1024 * 1024) {
                                                setValue(`jointOrganisers.${index}.institutionLogo`, "")
                                                return "File size exceeds 2mb"
                                              }
                                              return true;
                                            }
                                          }, onChange: (e) => handleJointOrganisersInstituteImageChange(e, index),
                                        })}
                                      />
                                      {/* <label
                                        htmlFor={`institutionLogo${index}`}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="menus-color"
                                          component="span"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                                        </Button>
                                      </label> */}
                                      {!!errors?.jointOrganisers?.[index]?.institutionLogoImage && (
                                        <FormHelperText className="w-100 alata-regular text-danger mt-2 mb-0 ps-3">
                                          {errors?.jointOrganisers?.[index].institutionLogoImage?.message}
                                        </FormHelperText>
                                      )}
                                    </div>
                                    <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 140x140 and 150x150 pixels only accepted</FormHelperText>
                                  </Grid>

                                </> : watch(`jointOrganisers.${index}.jointOrganiser`) === 'Non-Member Institution' &&
                                <>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Name of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.nonMemberName`, {
                                        required: "Please enter institution name",
                                      })}
                                      error={!!errors?.jointOrganisers?.[index]?.nonMemberName}
                                      helperText={errors?.jointOrganisers?.[index]?.nonMemberName?.message}
                                      placeholder="Name of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Address of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.nonMemberAddress`, {
                                        required: "Please enter institution address",
                                      })}
                                      error={!!errors?.jointOrganisers?.[index]?.nonMemberAddress}
                                      helperText={errors?.jointOrganisers?.[index]?.nonMemberAddress?.message}
                                      placeholder="Address of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Website of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`jointOrganisers.${index}.nonMemberWebsite`, {
                                        required: "Please enter institution website",
                                        pattern: {
                                          value:
                                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                          message: "Please enter a valid website address",
                                        },
                                      })}
                                      error={!!errors?.jointOrganisers?.[index]?.nonMemberWebsite}
                                      helperText={errors?.jointOrganisers?.[index]?.nonMemberWebsite?.message}
                                      placeholder="Website of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12}>
                                    <FormLabel className="ps-3 fs-6">
                                      <Typography className="text-dark">Logo of the Non-Member Institution <span className="text-danger">*</span></Typography>
                                    </FormLabel>
                                    <div>
                                      <img
                                        src={watch(`jointOrganisers.${index}.nonMemberLogoBase64`) ? watch(`jointOrganisers.${index}.nonMemberLogoBase64`) : watch(`jointOrganisers.${index}.nonMemberLogo`) ? watch(`jointOrganisers.${index}.nonMemberLogo`) : placeholder} // Fetched image from backend with cache busting
                                        height="100"
                                        alt="eventform"
                                        style={{
                                          objectFit: "scale-down",
                                          width: "200px",
                                        }}
                                      />
                                      <TextField
                                        sx={{ mt: 2 }}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        id={`jointOrganisernonMemberLogo${index}`}
                                        label={
                                          <Typography className="text-dark">
                                            Image <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        style={{ display: 'none' }}
                                        type="file"
                                        {...register(`jointOrganisers.${index}.nonMemberLogoImage`, {
                                          required: { value: !watch(`jointOrganisers.${index}.nonMemberLogo`), message: 'Please select a image' }, validate: (value) => {
                                            const file = value[0];
                                            if (!watch(`jointOrganisers.${index}.nonMemberLogo`)) {
                                              const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                                              if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                                                setValue(`jointOrganisers.${index}.nonMemberLogo`, "")
                                                return "Please select a image file"
                                              }
                                              if (file && file.size > 2 * 1024 * 1024) {
                                                setValue(`jointOrganisers.${index}.nonMemberLogo`, "")
                                                return "File size exceeds 2mb"
                                              }
                                              return true;
                                            }
                                          }, onChange: (e) => handleJointOrganisersNonInstituteImageChange(e, index),
                                        })}
                                      />
                                      <label
                                        htmlFor={`jointOrganisernonMemberLogo${index}`}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="menus-color"
                                          component="span"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                                        </Button>
                                      </label>
                                      {!!errors?.jointOrganisers?.[index]?.nonMemberLogoImage && (
                                        <FormHelperText className="w-100 alata-regular text-danger ps-3 mt-2 mb-0">
                                          {errors?.jointOrganisers?.[index]?.nonMemberLogoImage?.message}
                                        </FormHelperText>
                                      )}
                                    </div>
                                    <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 140x140 and 150x150 pixels only accepted</FormHelperText>
                                  </Grid>
                                </>
                        }
                      </Grid >
                    </>
                  )
                })}
                <Grid container rowSpacing={3} columnSpacing={3} className="mt-3 d-flex align-items-center">
                  <Grid item lg={4} md={6} xs={12} className="me-auto">
                    <h3 className="alata-regular fs-5 title-font">Sponsors</h3>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} className="ms-auto text-end">
                    <Button onClick={() => appendSponsorsCondition()} style={{ cursor: (watch(`sponsors.${0}.sponsor`) === 'None' ? 'not-allowed' : 'pointer'), pointerEvents: 'unset' }} disabled={watch(`sponsors.${0}.sponsor`) === 'None'} variant="contained" sx={{ minWidth: '300px' }} className="menus-color">Add Sponsors</Button>
                  </Grid>
                </Grid>
                {sponsorsFields && sponsorsFields.map((field, index) => {
                  return (
                    <>
                      <hr />
                      <Grid container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12} className={`d-flex justify-content-between ${index === 0 ? 'flex-sm-row flex-column' : ''} align-items-center`}>
                          <Controller
                            control={control}
                            name={`sponsors.${index}.sponsor`}
                            rules={{ required: 'Please select a option' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth>
                                  <FormLabel className="text-dark">
                                    <h4 >Sponsor {index + 1}<span className="text-danger">*</span></h4>
                                  </FormLabel>
                                  <FormLabel>
                                    <Typography className="text-dark">Type of the Sponsor <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    className="d-flex flex-sm-row flex-column"
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                      setValue(`sponsors.${index}`, {
                                        levelOfSponsor: '',
                                        sponsor: selectedValue,
                                        chaptersId: '',
                                        SIGGroupsId: '',
                                        institutionAddress: '',
                                        institutionLogo: '',
                                        institutionName: '',
                                        institutionWebsite: '',
                                        nonMemberAddress: '',
                                        nonMemberLogo: '',
                                        nonMemberName: '',
                                        nonMemberWebsite: '',
                                      })
                                      clearErrors('sponsors')
                                    }}
                                  >
                                    {(watch(`sponsors`).every((t, i) => t.sponsor !== 'ELTAI') || watch(`sponsors`).findIndex(t => t.sponsor === 'ELTAI') === index) && <FormControlLabel
                                      value="ELTAI"
                                      control={<Radio />}
                                      label={'ELTAI'}
                                    />}
                                    <FormControlLabel
                                      value="ELTAI SIG"
                                      control={<Radio />}
                                      label={'ELTAI SIG'}
                                      disabled={allSIG.length < 1}
                                    />
                                    <FormControlLabel
                                      value="ELTAI Chapter"
                                      control={<Radio />}
                                      label={'ELTAI Chapter'}
                                      disabled={selectedChapters.length < 1}
                                    />
                                    <FormControlLabel
                                      value="Member Institution"
                                      control={<Radio />}
                                      label={'Member Institution'}
                                      disabled={institution.length < 1}
                                    />
                                    <FormControlLabel
                                      value="Non-Member Institution"
                                      control={<Radio />}
                                      label={'Non-Member Institution'}
                                    />
                                    {index === 0 && <FormControlLabel
                                      value="None"
                                      control={<Radio />}
                                      label={'None'}
                                      onClick={() => {
                                        setValue(`sponsors`, [])
                                        clearErrors('sponsors')
                                        setValue(`sponsors.${0}.sponsor`, 'None')
                                      }}
                                    />}
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                          {index !== 0 && <div><Tooltip title='Remove Sponsor'><Button onClick={() => removeSponsors(index)} className="menus-color" variant="contained"><DeleteIcon></DeleteIcon></Button></Tooltip></div>}
                        </Grid>
                        {watch(`sponsors.${index}.sponsor`) && watch(`sponsors.${index}.sponsor`) !== 'None' && <Grid item lg={4} md={6} xs={12} >
                          <Controller
                            control={control}
                            name={`sponsors.${index}.levelOfSponsor`}
                            rules={{ required: 'Please select a level of sponsor' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth error={!!fieldState.error}>
                                  <InputLabel className="text-dark" id="event">
                                    Level of the Sponsor <span className="text-danger">*</span>
                                  </InputLabel>
                                  <Select
                                    className="bg-white"
                                    labelId="event"
                                    id="event"
                                    label={
                                      <Typography className="text-dark">
                                        Level of the Sponsor <span className="text-danger">*</span>
                                      </Typography>
                                    }
                                    value={field.value || ""}
                                    variant="outlined"
                                    onChange={(e) => field.onChange(e.target.value)}
                                  >
                                    {levelOfSponsors.map((level, i) => (
                                      <MenuItem key={i} value={level}>
                                        {level}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormHelperText error={!!fieldState.error}>
                                  {fieldState?.error?.message}
                                </FormHelperText>
                              </>
                            )}
                          />
                        </Grid>}
                        {watch(`sponsors.${index}.sponsor`) === 'ELTAI' ?
                          <>
                            <Grid item lg={4} md={6} xs={12} className="text-center" >
                              <img src={logo} alt="ELTAI LOGO" className="w-50 img-fluid" />
                              <Typography className="alata-medium">English Language Teachers’ Association of India (ELTAI)</Typography>
                            </Grid>
                          </> : watch(`sponsors.${index}.sponsor`) === 'ELTAI SIG' ?
                            <>
                              <Grid item lg={4} md={6} xs={12} >
                                <Controller
                                  control={control}
                                  name={`sponsors.${index}.SIGGroupsId`}
                                  rules={{ required: 'Please select a SIG group' }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <Autocomplete
                                        id="tags-outlined-chapter"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        options={allSIG}
                                        getOptionLabel={(option) =>
                                          option?.groupName || ""
                                        }
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        getOptionDisabled={(option) => watch('sponsors').some((item2) => { return option._id === item2.SIGGroupsId })}
                                        filterSelectedOptions
                                        onChange={(event, value) => {
                                          field.onChange(value ? value._id : "");// Pass the selected _id to onChange
                                        }}
                                        value={
                                          field.value
                                            ? allSIG.find((group) => group._id === field.value) || null
                                            : null
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="memberId"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            label={
                                              <Typography className="text-dark">
                                                Name of the Sponsor{" "}
                                                <span style={{ color: "red" }}>*</span>
                                              </Typography>
                                            }
                                            placeholder="Select SIG"
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                />
                              </Grid>
                            </> : watch(`sponsors.${index}.sponsor`) === 'ELTAI Chapter' ?
                              <>
                                <Grid item lg={4} md={6} xs={12} >
                                  <Controller
                                    control={control}
                                    name={`sponsors.${index}.chaptersId`}
                                    rules={{ required: 'Please select a chapter' }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <Autocomplete
                                          id="tags-outlined-chapter"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          options={selectedChapters}
                                          getOptionLabel={(option) =>
                                            option?.title || ""
                                          }
                                          isOptionEqualToValue={(option, value) => option._id === value._id}
                                          getOptionDisabled={(option) => watch('sponsors').some((item2) => { return option._id === item2.chaptersId })}
                                          filterSelectedOptions
                                          onChange={(event, value) => {
                                            // Check if value is not null before accessing _id
                                            field.onChange(value ? value._id : "");
                                          }}
                                          value={
                                            field.value
                                              ? selectedChapters.find((group) => group._id === field.value) || null
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="memberId"
                                              error={!!fieldState.error}
                                              helperText={fieldState.error?.message}
                                              label={
                                                <Typography className="text-dark">
                                                  Name of the Sponsor{" "}
                                                  <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                              }
                                              placeholder="Select Chapter"
                                            />
                                          )}
                                        />
                                      </>
                                    )}
                                  />
                                </Grid>
                              </> : watch(`sponsors.${index}.sponsor`) === 'Member Institution' ?
                                <>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <Controller
                                      control={control}
                                      name={`sponsors.${index}.memberId`}
                                      rules={{
                                        required: "Please select an institution member",
                                      }}
                                      render={({ field, fieldState }) => (
                                        <Autocomplete
                                          id="tags-outlined-chapter"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          options={institution}
                                          getOptionLabel={(option) =>
                                            option?.userId || ""
                                          }
                                          isOptionEqualToValue={(option, value) => option._id === value._id}
                                          filterSelectedOptions
                                          getOptionDisabled={(option) => watch('sponsors').some((item2) => { return option._id === item2.memberId })}
                                          onChange={(event, value) => {
                                            if (value) {
                                              field.onChange(value._id); // Pass the selected _id to onChange
                                              setValue(`sponsors.${index}.institutionName`, value?.institution || value?.organisationName || "");
                                              setValue(`sponsors.${index}.institutionAddress`, value.fullAddress || "");
                                              setValue(`sponsors.${index}.institutionLogo`, value.uploadInstitutionLogo || value.uploadOrganisationLogo || "");
                                              setValue(`sponsors.${index}.institutionWebsite`, value.websiteAddressInstitution || value.websiteAddressOrganisation || "");
                                              clearErrors(`sponsors.${index}.institutionName`);
                                              clearErrors(`sponsors.${index}.institutionAddress`);
                                              clearErrors(`sponsors.${index}.institutionLogo`);
                                              clearErrors(`sponsors.${index}.institutionLogoImage`);
                                              clearErrors(`sponsors.${index}.institutionWebsite`);
                                            } else {
                                              field.onChange(null); // Clear value if no selection
                                              setValue(`sponsors.${index}.institutionName`, "");
                                              setValue(`sponsors.${index}.institutionAddress`, "");
                                              setValue(`sponsors.${index}.institutionLogo`, "");
                                              setValue(`sponsors.${index}.institutionWebsite`, "");
                                            }
                                          }}
                                          value={
                                            field.value
                                              ? institution.find((group) => group._id === field.value) || null
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="memberId"
                                              error={!!fieldState.error}
                                              helperText={fieldState.error?.message}
                                              label={
                                                <Typography className="text-dark">
                                                  Member Institution ID{" "}
                                                  <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                              }
                                              placeholder={watch('memberId') ? '' : "Select a Member Institution"}
                                            />
                                          )}
                                        />
                                      )}
                                    />

                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Name of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.institutionName`, {
                                        required: "Please enter institution name",
                                      })}
                                      error={!!!!errors?.sponsors?.[index]?.institutionName}
                                      helperText={errors?.sponsors?.[index]?.institutionName?.message}
                                      placeholder="Name of the Member Institution "
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} sm={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Address of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.institutionAddress`, {
                                        required: "Please enter institution address",
                                      })}
                                      error={!!!!errors?.sponsors?.[index]?.institutionAddress}
                                      helperText={errors?.sponsors?.[index]?.institutionAddress?.message}
                                      placeholder="Address of the Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Website of the Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.institutionWebsite`, {
                                        required: "Please enter institution website",
                                        pattern: {
                                          value:
                                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                          message: "Please enter a valid website address",
                                        },
                                      })}
                                      error={!!errors?.sponsors?.[index]?.institutionWebsite}
                                      helperText={errors?.sponsors?.[index]?.institutionWebsite?.message}
                                      placeholder="Website of the Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12}>
                                    <div className="d-flex flex-column">
                                      <FormLabel className="ps-3 fs-6">
                                        <Typography className="text-dark">Logo of the Member Institution <span className="text-danger">*</span></Typography>
                                      </FormLabel>
                                      <img
                                        src={sponsorsInstitutionImage[index] ? sponsorsInstitutionImage[index] : watch(`sponsors.${index}.institutionLogo`) ? watch(`sponsors.${index}.institutionLogo`) : placeholder} // Fetched image from backend with cache busting
                                        height="100"
                                        alt="eventform"
                                        style={{
                                          objectFit: "scale-down",
                                          width: "200px",
                                        }}
                                      />
                                      <TextField
                                        sx={{ mt: 2 }}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        id={`institutionLogo${index}`}
                                        label={
                                          <Typography className="text-dark">
                                            Logo of the Member Institution <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        style={{ display: 'none' }}
                                        type="file"
                                        {...register(`sponsors.${index}.institutionLogoImage`, {
                                          required: !watch(`sponsors.${index}.institutionLogo`) && 'Please select a image', validate: (value) => {
                                            const file = value[0];
                                            if (!watch(`sponsors.${index}.institutionLogo`)) {
                                              const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                                              if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                                                setValue(`sponsors.${index}.institutionLogo`, "")
                                                return "Please select a image file"
                                              }
                                              if (file && file.size > 2 * 1024 * 1024) {
                                                setValue(`sponsors.${index}.institutionLogo`, "")
                                                return "File size exceeds 2mb"
                                              }
                                              return true;
                                            }
                                          }, onChange: (e) => handleSponsorsInstituteImageChange(e, index),
                                        })}
                                      />
                                      {/* <label
                                        htmlFor={`institutionLogo${index}`}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="menus-color"
                                          component="span"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                                        </Button>
                                      </label> */}
                                      {!!errors?.sponsors?.[index]?.institutionLogoImage && (
                                        <FormHelperText className="w-100 alata-regular text-danger mt-2 mb-0 ps-3">
                                          {errors?.sponsors?.[index].institutionLogoImage?.message}
                                        </FormHelperText>
                                      )}
                                    </div>
                                    <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 140x140 and 150x150 pixels only accepted</FormHelperText>
                                  </Grid>

                                </> : watch(`sponsors.${index}.sponsor`) === 'Non-Member Institution' &&
                                <>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Name of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.nonMemberName`, {
                                        required: "Please Enter Institution Name",
                                      })}
                                      error={!!errors?.sponsors?.[index]?.nonMemberName}
                                      helperText={errors?.sponsors?.[index]?.nonMemberName?.message}
                                      placeholder="Name of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Address of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.nonMemberAddress`, {
                                        required: "Please inter institution address",
                                      })}
                                      error={!!errors?.sponsors?.[index]?.nonMemberAddress}
                                      helperText={errors?.sponsors?.[index]?.nonMemberAddress?.message}
                                      placeholder="Address of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12} >
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      style={{ width: "100%" }}
                                      label={
                                        <Typography className="text-dark">
                                          Website of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                        </Typography>
                                      }
                                      {...register(`sponsors.${index}.nonMemberWebsite`, {
                                        required: "Please enter institution website",
                                        pattern: {
                                          value:
                                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                                          message: "Please enter a valid website address",
                                        },
                                      })}
                                      error={!!errors?.sponsors?.[index]?.nonMemberWebsite}
                                      helperText={errors?.sponsors?.[index]?.nonMemberWebsite?.message}
                                      placeholder="Website of the Non-Member Institution"
                                    />
                                  </Grid>
                                  <Grid item lg={4} md={6} xs={12}>
                                    <FormLabel className="ps-3 fs-6">
                                      <Typography className="text-dark">Log of the Non-Member Institution <span className="text-danger">*</span></Typography>
                                    </FormLabel>
                                    <div>
                                      <img
                                        src={watch(`sponsors.${index}.nonMemberLogoBase64`) ? watch(`sponsors.${index}.nonMemberLogoBase64`) : watch(`sponsors.${index}.nonMemberLogo`) ? watch(`sponsors.${index}.nonMemberLogo`) : placeholder}
                                        height="100"
                                        alt="eventform"
                                        style={{
                                          objectFit: "scale-down",
                                          width: "200px",
                                        }}
                                      />
                                      <TextField
                                        sx={{ mt: 2 }}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        id={`sponsorsnonMemberLogo${index}`}
                                        label={
                                          <Typography className="text-dark">
                                            Logo of the Non-Member Institution <span style={{ color: "red" }}>*</span>
                                          </Typography>
                                        }
                                        style={{ display: 'none' }}
                                        type="file"
                                        {...register(`sponsors.${index}.nonMemberLogoImage`, {
                                          required: { value: !watch(`sponsors.${index}.nonMemberLogo`), message: 'Please Select a Image' }, validate: (value) => {
                                            const file = value[0];
                                            if (!watch(`sponsors.${index}.nonMemberLogo`)) {
                                              const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                                              if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                                                setValue(`sponsors.${index}.nonMemberLogo`, "")
                                                return "Please select a image file"
                                              }
                                              if (file && file.size > 2 * 1024 * 1024) {
                                                setValue(`sponsors.${index}.nonMemberLogo`, "")
                                                return "File size exceeds 2mb"
                                              }
                                              return true;
                                            }
                                          }, onChange: (e) => handleSponsorsNonInstituteImageChange(e, index),
                                        })}
                                      />
                                      <label
                                        htmlFor={`sponsorsnonMemberLogo${index}`}
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="menus-color"
                                          component="span"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                                        </Button>
                                      </label>
                                      {!!errors?.sponsors?.[index]?.nonMemberLogoImage && (
                                        <FormHelperText className="w-100 alata-regular text-danger ps-3 mt-2 mb-0">
                                          {errors?.sponsors?.[index]?.nonMemberLogoImage?.message}
                                        </FormHelperText>
                                      )}
                                    </div>
                                    <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 140x140 and 150x150 pixels only accepted</FormHelperText>
                                  </Grid>
                                </>
                        }
                      </Grid >
                    </>
                  )
                })}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item lg={4} md={6} xs={12} className="me-auto">
                    <h3 className="alata-regular fs-5 mb-0 pb-0 title-font">Speakers</h3>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} className="ms-auto text-end">
                    <Button onClick={() => appendSpeaker({})} variant="contained" sx={{ minWidth: '300px' }} className="menus-color">Add Speaker</Button>
                  </Grid>
                </Grid>
                {speakerFields && speakerFields.map((field, index) => {
                  return (
                    <>
                      <hr />
                      <Grid container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12} className={`d-flex justify-content-between ${index === 0 ? 'flex-sm-row flex-column' : ''} align-items-center`}>
                          <Controller
                            control={control}
                            name={`speakers.${index}.role`}
                            rules={{ required: 'Please select a role' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth>
                                  <FormLabel className="text-dark">
                                    <h4>Speaker {index + 1}<span className="text-danger">*</span></h4>
                                  </FormLabel>
                                  <FormLabel className="text-dark">
                                    <Typography >Role <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    className="d-flex flex-sm-row flex-column"
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                      clearErrors('speakers')
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Speaker"
                                      control={<Radio />}
                                      label={'Speaker'}
                                    />
                                    <FormControlLabel
                                      value="Moderator"
                                      control={<Radio />}
                                      label={'Moderator'}
                                    />
                                    <FormControlLabel
                                      value="Panelist"
                                      control={<Radio />}
                                      label={'Panelist'}
                                    />
                                    <FormControlLabel
                                      value="Discussant"
                                      control={<Radio />}
                                      label={'Discussant'}
                                    />
                                    <FormControlLabel
                                      value="Chair Person"
                                      control={<Radio />}
                                      label={'Chair Person'}
                                    />
                                    <FormControlLabel
                                      value="Host"
                                      control={<Radio />}
                                      label={'Host'}
                                    />
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                          {index !== 0 && <div><Tooltip title='Remove Speaker'><Button onClick={() => removeSpeakerField(index)} className="menus-color" variant="contained"><DeleteIcon></DeleteIcon></Button></Tooltip></div>}
                        </Grid>
                        <Grid item lg={4} md={6} xs={12} >
                          <Controller
                            control={control}
                            name={`speakers.${index}.orderOfSpeaker`}
                            rules={{ required: 'Please select a order of speaker' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth error={!!fieldState.error}>
                                  <InputLabel className="text-dark" id="event">
                                    Order of the Speaker <span className="text-danger">*</span>
                                  </InputLabel>
                                  <Select
                                    className="bg-white"
                                    labelId="event"
                                    id="event"
                                    label={
                                      <Typography className="text-dark">
                                        Order of the Speaker <span className="text-danger">*</span>
                                      </Typography>
                                    }
                                    value={field.value || ""}
                                    variant="outlined"
                                    onChange={(e) => field.onChange(e)}
                                  >
                                    {order.map(option => {
                                      const isDisabled = watch('speakers').some(speaker => speaker?.orderOfSpeaker === option);
                                      return (
                                        <MenuItem key={option} value={option} disabled={isDisabled}>
                                          {option}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <FormHelperText error={!!fieldState.error}>
                                  {fieldState?.error?.message}
                                </FormHelperText>
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Name <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`speakers.${index}.name`, {
                              required: `Please enter ${watch(`speakers.${index}.role`) ? watch(`speakers.${index}.role`) : 'speaker'} name`,
                            })}
                            error={!!!!errors?.speakers?.[index]?.name}
                            helperText={errors?.speakers?.[index]?.name?.message}
                            placeholder={`${watch(`speakers.${index}.role`) ? watch(`speakers.${index}.role`) : 'Speaker'} Name`}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Designation <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`speakers.${index}.designation`, {
                              required: `Please enter ${watch(`speakers.${index}.role`) ? watch(`speakers.${index}.role`) : 'speaker'} designation`,
                            })}
                            error={!!!!errors?.speakers?.[index]?.designation}
                            helperText={errors?.speakers?.[index]?.designation?.message}
                            placeholder={`${watch(`speakers.${index}.role`) ? watch(`speakers.${index}.role`) : 'Speaker'} Designation`}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Institution / Organisation <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`speakers.${index}.institution`, {
                              required: "Please enter Institution / Organisation",
                            })}
                            error={!!errors?.speakers?.[index]?.institution}
                            helperText={errors?.speakers?.[index]?.institution?.message}
                            placeholder="Institution / Organisation"
                          />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                          <FormLabel className="ps-3 fs-6">
                            <Typography className="text-dark">Photograph <span className="text-danger">*</span></Typography>
                          </FormLabel>
                          <div>
                            <img
                              src={watch(`speakers.[${index}].photoBase64`) ? watch(`speakers.[${index}].photoBase64`) : watch(`speakers.${index}.photo`) ? watch(`speakers.${index}.photo`) : placeholder} // Fetched image from backend with cache busting
                              height="100"
                              alt="eventform"
                              style={{
                                objectFit: "scale-down",
                                width: "200px",
                              }}
                            />
                            <TextField
                              sx={{ mt: 2 }}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ accept: "image/jpeg, image/png" }}
                              id={`photo${index}`}
                              label={
                                <Typography className="text-dark">
                                  Image <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              style={{ display: 'none' }}
                              type="file"
                              {...register(`speakers.${index}.photoImage`, {
                                required: !watch(`speakers.${index}.photo`) && 'Please select a image', validate: (value) => {
                                  const file = value[0];
                                  if (!watch(`speakers.${index}.photo`)) {
                                    const allowedTypes = ["jpeg", "png", "jpg", 'jfif'];
                                    if (!file || !allowedTypes.includes(file.name.split('.')[file.name.split('.').length - 1])) {
                                      setValue(`speakers.${index}.photo`, "")
                                      return "Please select a image file"
                                    }
                                    if (file && file.size > 2 * 1024 * 1024) {
                                      setValue(`speakers.${index}.photo`, "")
                                      return "File size exceeds 2mb"
                                    }
                                    return true;
                                  }
                                }, onChange: (e) => handleSpeakerImageChange(e, index),
                              })}
                            />
                            <label
                              htmlFor={`photo${index}`}
                              style={{ marginLeft: "10px" }}
                            >
                              <Button
                                variant="contained"
                                className="menus-color"
                                component="span"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Choose File <span style={{ color: "white", paddingLeft: "5px" }}>*</span>
                              </Button>
                            </label>
                            {!!errors?.speakers?.[index]?.photoImage && (
                              <FormHelperText className="w-100 alata-regular text-danger mt-2 mb-0 ps-3">
                                {errors?.speakers?.[index].photoImage?.message}
                              </FormHelperText>
                            )}
                          </div>
                          <FormHelperText className="w-100 d-flex align-items-start mb-3 gap-1"><InfoIcon className="text-secondary-new" />Image (JPEG, JPG, JFIF or PNG) dimensions must be between 140x140 and 150x150 pixels only accepted</FormHelperText>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            multiline
                            rows={4}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Breif Bio (100 words) <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`speakers.${index}.bio`, {
                              required: "Please enter bio", validate: (value) => {
                                if (value.split(' ').length > 100) {
                                  return 'Only 100 words allowed'
                                }
                                return true
                              }
                            })}
                            error={!!errors?.speakers?.[index]?.bio}
                            helperText={errors?.speakers?.[index]?.bio?.message}
                            placeholder="Breif Bio (100 words)"
                          />
                        </Grid>
                      </Grid >
                    </>
                  )
                })}

              </>
            )}
            {activeStep === 3 && (
              <>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12}>
                    <h3 className="alata-regular fs-4 title-font">Registration Fee and Certificate</h3>
                    <Controller
                      control={control}
                      name="isPaidEvent"
                      render={({ field, fieldState }) => (
                        <>
                          <FormControl fullWidth>
                            <FormLabel className="text-dark">
                              <Typography >Is it a paid event?</Typography>
                            </FormLabel>
                            <RadioGroup
                              row
                              value={field.value}
                              onChange={(event) => {
                                const selectedValue = event.target.value;
                                field.onChange(selectedValue);
                                if (selectedValue === '0') {
                                  setValue('feeDetails', ''); 
                                }
                                if(selectedValue === '0' || selectedValue === '1') {
                                  clearErrors(['feeDetails', 'registrationLink', 'deadline', 'policy']);
                                }
                              }}
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={'Free'}
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={'Paid'}
                              />
                            </RadioGroup>
                            {fieldState?.error && (
                              <FormHelperText error>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </>
                      )}
                    />
                  </Grid>
                  {
                    (watch('isPaidEvent') === '1' || watch('isPaidEvent') === 1) &&
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "100%" }}
                        multiline
                        label={
                          <Typography className="text-dark">
                            Registration Fee Details <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...register("feeDetails", {
                          required: "Please enter registration fee details",
                        })}
                        error={!!errors?.feeDetails}
                        helperText={errors?.feeDetails?.message}
                        placeholder="Registration Fee Details"
                      />
                      <FormHelperText className="text-secondary-new">To add information about the registration fee details</FormHelperText>
                    </Grid>
                  }
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      style={{ width: "100%" }}
                      label={
                        <Typography className="text-dark">
                          Link to register for the event <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("registrationLink", {
                        required: "Please enter link to register for the event",
                        pattern: {
                          value:
                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[\w .-]*)*\/?$/,
                          message: "Please enter a valid link",
                        },
                      })}
                      error={!!errors?.registrationLink}
                      helperText={errors?.registrationLink?.message}
                      placeholder="Link to register for the event"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      id="deadline"
                      label={
                        <Typography className="text-dark">
                          Last Date to Register <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="date"
                      {...register("deadline", {
                        required: "Please select a dealine to register", validate: validateYearLength,
                        max: { value: watch('startDate'), message: 'Please select a date before start date' },
                        min: { value: new Date().toISOString().split('T')[0], message: 'Please select a future or present date' }
                      })}
                      error={!!errors?.deadline}
                      helperText={errors?.deadline?.message}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      style={{ width: "100%" }}
                      multiline
                      label={
                        <Typography className="text-dark">
                          Certificate Policy <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("policy", {
                        required: "Please enter certificate policy",
                      })}
                      error={!!errors?.policy}
                      helperText={errors?.policy?.message}
                      placeholder="Certificate Policy"
                    />
                    <FormHelperText className="text-secondary-new">Describe in complete sentence who are eligible to receive certificate of participation</FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 className="alata-regular fs-4 title-font">Attachments </h3>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    {watch("flyer") ? (
                      <>
                        <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <span className="alata-regular">
                              {eventId ? <>
                                {watch('flyer') instanceof File ? <Tooltip title={watch('flyer')?.name} >  {getTextWithEllipsis('flyer')}</Tooltip> : <div>
                                  <a
                                    href={watch('flyer')}
                                    cursor="pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: "block", marginTop: "10px" }}
                                  >
                                    View Uploaded Image
                                  </a>
                                </div>}
                              </> : watch("flyer")?.name && <Tooltip title={watch('flyer')?.name} >  {getTextWithEllipsis('flyer')}</Tooltip>}
                            </span>

                          </div>
                          <div className="d-flex" style={{ marginRight: '-8.5px' }}>
                            <FormLabel
                              htmlFor="flyer"
                            >
                              <Button
                                variant="contained"
                                className="menus-color"
                                component="span"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Choose File
                              </Button>
                            </FormLabel>
                            <IconButton
                              aria-label="toggle password visibility"
                            >
                              <Tooltip title='Delete Image'>
                                <DeleteIcon className="text-danger" onClick={() => removeImage('flyer')} />
                              </Tooltip>
                            </IconButton>
                          </div>
                        </div>
                        <TextField
                          className="d-none"
                          InputProps={{ type: "file" }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.flyerFile}
                          helperText={errors?.flyerFile?.message}
                          label={
                            <Typography className="text-dark">
                              Flyer / Poster
                            </Typography>
                          }
                          inputProps={{ accept: "image/*" }}
                          id="flyer"
                          {...register("flyerFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFlyerFile(fileList, 'flyer');
                              if (validationResult === true) {
                                setValue("flyer", fileList[0]);
                                clearErrors("flyerFile");
                              } else {
                                setValue('flyer', '')
                                setValue('flyerFile', '')
                                setError("flyerFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography
                              className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                              style={{ textOverflow: "ellipsis" }}
                            >
                              Flyer / Poster
                            </Typography>
                          }
                          inputProps={{ accept: "image/*" }}
                          InputProps={{
                            type: "file",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  sx={{
                                    marginRight: "-8.5px",
                                  }}
                                >
                                  <Tooltip title='Delete Image'>
                                    <DeleteIcon className="text-danger" onClick={() => removeImage('flyer')} />
                                  </Tooltip>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id="file-upload"
                          {...register("flyerFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFlyerFile(fileList, 'flyer');
                              if (validationResult === true) {
                                setValue("flyer", fileList[0]);
                                clearErrors("flyerFile");
                              } else {
                                setValue('flyer', "")
                                setValue('flyerFile', '')
                                setError("flyerFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                          error={!!errors?.flyerFile}
                          helperText={errors?.flyerFile?.message}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    {watch("brouchure") ? (
                      <>
                        <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                          <div className="d-flex align-items-center">
                            <span className="alata-regular">
                              {eventId ? <>
                                {watch('brouchure') instanceof File ? <Tooltip title={watch('brouchure')?.name} >  {getTextWithEllipsis('brouchure')}</Tooltip> : <div>
                                  <a
                                    href={watch('brouchure')}
                                    cursor="pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: "block", marginTop: "10px" }}
                                  >
                                    View Uploaded PDF
                                  </a>
                                </div>}
                              </> : watch("brouchure")?.name && <Tooltip title={watch('brouchure')?.name} >  {getTextWithEllipsis('brouchure')}</Tooltip>}
                            </span>
                          </div>
                          <div className="d-flex" style={{ marginRight: '-8.5px' }}>

                          <FormLabel
                            htmlFor="brouchure"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              variant="contained"
                              className="menus-color"
                              component="span"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Choose File
                            </Button>
                          </FormLabel>
                          <IconButton
                            aria-label="toggle password visibility"
                          >
                            <Tooltip title='Delete Image'>
                              <DeleteIcon className="text-danger" onClick={() => removeImage('brouchure')} />
                            </Tooltip>
                          </IconButton>
                          </div>
                        </div>
                        <TextField
                          className="d-none"
                          InputProps={{ type: "file" }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.brouchureFile}
                          helperText={errors?.brouchureFile?.message}
                          label={
                            <Typography className="text-dark">
                              Brouchure
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="brouchure"
                          {...register("brouchureFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'brouchure');
                              if (validationResult === true) {
                                setValue("brouchure", fileList[0]);
                                clearErrors("brouchureFile");
                              } else {
                                setValue('brouchure', '')
                                setValue('brouchureFile', '')
                                setError("brouchureFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          InputProps={{
                            type: "file",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  sx={{
                                    marginRight: "-8.5px",
                                  }}
                                >
                                  <Tooltip title='Delete Image'>
                                    <DeleteIcon className="text-danger" onClick={() => removeImage('brouchure')} />
                                  </Tooltip>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography
                              className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                              style={{ textOverflow: "ellipsis" }}
                            >
                              Brouchure
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="file-upload"
                          {...register("brouchureFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'brouchure');
                              if (validationResult === true) {
                                setValue("brouchure", fileList[0]);
                                clearErrors("brouchureFile");
                              } else {
                                setValue('brouchure', "")
                                setValue('brouchureFile', '')
                                setError("brouchureFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                          error={!!errors?.brouchureFile}
                          helperText={errors?.brouchureFile?.message}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    {watch("schedule") ? (
                      <>
                        <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                          <div className="d-flex align-items-center">
                            <span className="alata-regular">
                              {eventId ? <>
                                {watch('schedule') instanceof File ? <Tooltip title={watch('schedule')?.name} >  {getTextWithEllipsis('schedule')}</Tooltip> : <div>
                                  <a
                                    href={watch('schedule')}
                                    cursor="pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: "block", marginTop: "10px" }}
                                  >
                                    View Uploaded PDF
                                  </a>
                                </div>}
                              </> : watch("schedule")?.name && <Tooltip title={watch('schedule')?.name} >  {getTextWithEllipsis('schedule')}</Tooltip>}
                            </span>
                          </div>
                          <div className="d-flex" style={{ marginRight: '-8.5px' }}>

                          <FormLabel
                            htmlFor="schedule"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              variant="contained"
                              className="menus-color"
                              component="span"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Choose File
                            </Button>
                          </FormLabel>
                          <IconButton
                            aria-label="toggle password visibility"
                          >
                            <Tooltip title='Delete Image'>
                              <DeleteIcon className="text-danger" onClick={() => removeImage('schedule')} />
                            </Tooltip>
                          </IconButton>
                          </div>
                        </div>
                        <TextField
                          className="d-none"
                          InputProps={{ type: "file" }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.scheduleFile}
                          helperText={errors?.scheduleFile?.message}
                          label={
                            <Typography className="text-dark">
                              Schedule
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="schedule"
                          {...register("scheduleFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'schedule');
                              if (validationResult === true) {
                                setValue("schedule", fileList[0]);
                                clearErrors("scheduleFile");
                              } else {
                                setValue('schedule', '')
                                setValue('scheduleFile', '')
                                setError("scheduleFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                        />
                      </>
                    ) : (
                      <>

                        <TextField
                          InputProps={{
                            type: "file",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  sx={{
                                    marginRight: "-8.5px",
                                  }}
                                >
                                  <Tooltip title='Delete Image'>
                                    <DeleteIcon className="text-danger" onClick={() => removeImage('schedule')} />
                                  </Tooltip>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography
                              className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                              style={{ textOverflow: "ellipsis" }}
                            >
                              Schedule
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="file-upload"
                          {...register("scheduleFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'schedule');
                              if (validationResult === true) {
                                setValue("schedule", fileList[0]);
                                clearErrors("scheduleFile");
                              } else {
                                setValue('schedule', "")
                                setValue('scheduleFile', '')
                                setError("scheduleFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                          error={!!errors?.scheduleFile}
                          helperText={errors?.scheduleFile?.message}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    {watch("readingMaterial") ? (
                      <>
                        <div className="border border-secondary-subtle rounded border-grey d-flex justify-content-between align-items-center px-3 py-2">
                          <div className="d-flex align-items-center">
                            <span className="alata-regular">
                              {eventId ? <>
                                {watch('readingMaterial') instanceof File ? <Tooltip title={watch('readingMaterial')?.name} >  {getTextWithEllipsis('readingMaterial')}</Tooltip> : <div>
                                  <a
                                    href={watch('readingMaterial')}
                                    cursor="pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ display: "block", marginTop: "10px" }}
                                  >
                                    View Uploaded PDF
                                  </a>
                                </div>}
                              </> : watch("readingMaterial")?.name && <Tooltip title={watch('readingMaterial')?.name} >  {getTextWithEllipsis('readingMaterial')}</Tooltip>}
                            </span>
                          </div>
                          <div className="d-flex" style={{ marginRight: '-8.5px' }}>

                          <FormLabel
                            htmlFor="readingMaterial"
                            style={{ marginLeft: "10px" }}
                          >
                            <Button
                              variant="contained"
                              className="menus-color"
                              component="span"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Choose File
                            </Button>
                          </FormLabel>
                          <IconButton
                            aria-label="toggle password visibility"
                          >
                            <Tooltip title='Delete Image'>
                              <DeleteIcon className="text-danger" onClick={() => removeImage('readingMaterial')} />
                            </Tooltip>
                          </IconButton>
                          </div>
                        </div>
                        <TextField
                          className="d-none"
                          InputProps={{ type: "file" }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.readingMaterialFile}
                          helperText={errors?.readingMaterialFile?.message}
                          label={
                            <Typography className="text-dark">
                              Reading Material
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="readingMaterial"
                          {...register("readingMaterialFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'readingMaterial');
                              if (validationResult === true) {
                                setValue("readingMaterial", fileList[0]);
                                clearErrors("readingMaterialFile");
                              } else {
                                setValue('readingMaterial', '')
                                setValue('readingMaterialFile', '')
                                setError("readingMaterialFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                        />
                      </>
                    ) : (
                      <>

                        <TextField
                          InputProps={{
                            type: "file",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  sx={{
                                    marginRight: "-8.5px",
                                  }}
                                >
                                  <Tooltip title='Delete Image'>
                                    <DeleteIcon className="text-danger" onClick={() => removeImage('readingMaterial')} />
                                  </Tooltip>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography
                              className="text-dark w-100 d-inline-block overflow-hidden text-nowrap"
                              style={{ textOverflow: "ellipsis" }}
                            >
                              Reading Material
                            </Typography>
                          }
                          inputProps={{ accept: ".pdf" }}
                          id="file-upload"
                          {...register("readingMaterialFile", {
                            onChange: (e) => {
                              const fileList = e.target.files;
                              const validationResult = validateFile(fileList, 'readingMaterial');
                              if (validationResult === true) {
                                setValue("readingMaterial", fileList[0]);
                                clearErrors("readingMaterialFile");
                              } else {
                                setValue('readingMaterial', "")
                                setValue('readingMaterialFile', '')
                                setError("readingMaterialFile", {
                                  message: validationResult,
                                });
                              }
                            },
                          })}
                          error={!!errors?.readingMaterialFile}
                          helperText={errors?.readingMaterialFile?.message}
                        />
                      </>
                    )}
                  </Grid>



                </Grid>
              </>
            )}
            {activeStep === 4 && (
              <>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item lg={4} md={6} xs={12} className="me-auto">
                    <h3 className="alata-regular fs-4 title-font">Contact Details</h3>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} className="ms-auto text-end">
                    <Button onClick={() => appendContacts({})} variant="contained" sx={{ minWidth: '300px' }} className="menus-color">Add a Contact Person</Button>
                  </Grid>
                </Grid>
                {contactFields && contactFields.map((field, index) => {
                  return (
                    <>
                      <hr />
                      <Grid container rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={12} className={`d-flex justify-content-between align-items-center`}>
                          <Controller
                            control={control}
                            name={`contacts.${index}.role`}
                            rules={{ required: 'Please select a role' }}
                            render={({ field, fieldState }) => (
                              <>
                                <FormControl fullWidth>
                                  <FormLabel className="text-dark">
                                    <h4 >Contact Person {index + 1}<span className="text-danger">*</span></h4>
                                  </FormLabel>
                                  <FormLabel className="text-dark">
                                    <Typography >Role <span className="text-danger">*</span></Typography>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    className="d-flex flex-sm-row flex-column"
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      field.onChange(selectedValue);
                                      clearErrors('contacts')
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Convenor"
                                      control={<Radio />}
                                      label={'Convenor'}
                                    />
                                    <FormControlLabel
                                      value="Co-convenor"
                                      control={<Radio />}
                                      label={'Co-convenor'}
                                    />
                                    <FormControlLabel
                                      value="Organising Secretary"
                                      control={<Radio />}
                                      label={'Organising Secretary'}
                                    />
                                  </RadioGroup>
                                  {fieldState?.error && (
                                    <FormHelperText error>
                                      {fieldState.error.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </>
                            )}
                          />
                          {index !== 0 && <div><Tooltip title='Remove Conatct Person'><Button onClick={() => removeContacts(index)} className="menus-color" variant="contained"><DeleteIcon></DeleteIcon></Button></Tooltip></div>}
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Name <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`contacts.${index}.name`, {
                              required: `Please enter ${watch(`contacts.${index}.role`) ? watch(`contacts.${index}.role`) : 'conatct'} name`,
                            })}
                            error={!!!!errors?.contacts?.[index]?.name}
                            helperText={errors?.contacts?.[index]?.name?.message}
                            placeholder={`${watch(`contacts.${index}.role`) ? watch(`contacts.${index}.role`) : 'Contact'} Name`}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Designation <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`contacts.${index}.designation`, {
                              required: `Please enter ${watch(`contacts.${index}.role`) ? watch(`contacts.${index}.role`) : 'contact'} designation`,
                            })}
                            error={!!!!errors?.contacts?.[index]?.designation}
                            helperText={errors?.contacts?.[index]?.designation?.message}
                            placeholder={`${watch(`contacts.${index}.role`) ? watch(`contacts.${index}.role`) : 'Contact'} Designation`}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Institution / Organisation <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`contacts.${index}.institution`, {
                              required: "Please enter institution / organisation",
                            })}
                            error={!!errors?.contacts?.[index]?.institution}
                            helperText={errors?.contacts?.[index]?.institution?.message}
                            placeholder="Institution / Organisation"
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            multiline
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Mobile Number <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`contacts.${index}.mobile`, {
                              required: "Please enter a mobile number",
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Mobile number must be 10 digits",
                              },
                            })}
                            error={!!errors?.contacts?.[index]?.mobile}
                            helperText={errors?.contacts?.[index]?.mobile?.message}
                            placeholder="Mobile Number"
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            multiline
                            style={{ width: "100%" }}
                            label={
                              <Typography className="text-dark">
                                Email Address <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`contacts.${index}.email`, {
                              required: "Please enter a email address",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Enter a valid email address",
                              },
                            })}
                            error={!!errors?.contacts?.[index]?.email}
                            helperText={errors?.contacts?.[index]?.email?.message}
                            placeholder="Email Address"
                          />
                        </Grid>
                      </Grid >
                    </>
                  )
                })}

              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" variant="contained" className="menus-color"
                style={{
                  cursor: 'pointer',
                  color: "white"
                }} >
                {activeStep === steps.length - 1
                  ? eventId
                    ? "Update"
                    : "Submit"
                  : "Next"}
              </Button>
            </Box>
          </form>
        </Box >
      )
      }
    </>
  );
}

export default EventForm;
