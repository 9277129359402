import React, { useState } from 'react';
import './CategoryWiseMembership.css';
import html2pdf from 'html2pdf.js';
import { Button } from '@mui/material';

const CategoryWiseMembership = () => {
    const [isLoading, setIsLoading] = useState(false);

    const data = [
        {
            date: "15.03.2024",
            teacher: {
                annual: { new: 4, renew: 0 },
                shortTerm: { new: 43, renew: 0 },
                longTerm: { new: 5, renew: 0 },
                total: { new: 5, renew: 0 },
            },
            institute: {
                annual: { new: 0, renew: 0 },
                shortTerm: { new: 0, renew: 0 },
                longTerm: { new: 0, renew: 0 },
                total: { new: 5, renew: 0 },
            },
            student: { shortTerm: { new: 0, renew: 0 }, total: 0 },
            grandTotal: 56,
        },
        {
            date: "16.03.2024",
            teacher: {
                annual: { new: 4, renew: 0 },
                shortTerm: { new: 0, renew: 0 },
                longTerm: { new: 0, renew: 0 },
                total: { new: 5, renew: 0 },
            },
            institute: {
                annual: { new: 0, renew: 0 },
                shortTerm: { new: 0, renew: 0 },
                longTerm: { new: 0, renew: 0 },
                total: { new: 5, renew: 0 },
            },
            student: { shortTerm: { new: 0, renew: 0 }, total: 0 },
            grandTotal: 0,
        },
        {
            date: "18.03.2024",
            teacher: {
                annual: { new: 4, renew: 0 },
                shortTerm: { new: 0, renew: 0 },
                longTerm: { new: 0, renew: 0 },
                total: { new: 5, renew: 0 },
            },
            institute: {
                annual: { new: 0, renew: 0 },
                shortTerm: { new: 0, renew: 0 },
                longTerm: { new: 0, renew: 11 },
                total: { new: 5, renew: 0 },
            },
            student: { shortTerm: { new: 0, renew: 0 }, total: 0 },
            grandTotal: 20,

        },
    ];

    const generatePDF = () => {
        setIsLoading(true); // Start loading

        const element = document.getElementById('pdf-content');
        const options = {
            margin: [10, 10, 10, 10],
            filename: 'CategoryWiseMembership.pdf',
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };

        html2pdf()
            .set(options)
            .from(element)
            .save()
            .then(() => setIsLoading(false)) // Stop loading on success
            .catch(() => setIsLoading(false)); // Stop loading on error
    };

    return (
        <div className="ourExecutiveCommittee-container">
            <div className="pt-5">
                <header className="chapter-heading">
                    <h2 className="text-center title-font text-light py-3 mt-1">
                        Category-wise Membership Report
                    </h2>
                </header>
            </div>
            <div className="d-flex justify-content-end mt-5 mx-5 flex-wrap">
                <Button
                    onClick={generatePDF}
                    variant="contained"
                    className="menus-color mb-4"
                    style={{ minWidth: '150px', fontSize: '14px' }}
                    disabled={isLoading}
                >
                    {isLoading ? 'Generating PDF...' : 'Download PDF'}
                </Button>
            </div>
            <div className="container mb-5">
                <div
                    className="table-responsive"
                    style={{
                        border: '1px solid #ccc',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <table
                        className="table table-bordered text-center mb-0"
                        id="pdf-content"
                        style={{ tableLayout: 'auto' }}
                    >
                        <thead className="bg-dark text-light">
                            <tr>
                                <th
                                    rowSpan="3"
                                    className="align-middle"
                                    style={{
                                        verticalAlign: 'middle',
                                        width: '150px',
                                        padding: '10px',
                                    }}
                                >
                                    Date
                                </th>
                                <th colSpan="8">Individual Teacher</th>
                                <th colSpan="8">Educational Institute</th>
                                <th colSpan="3">Individual Student</th>
                                <th
                                    rowSpan="3"
                                    className="align-middle"
                                    style={{
                                        verticalAlign: 'middle',
                                        whiteSpace: 'normal',
                                        width: '100px',
                                        padding: '10px',
                                    }}
                                >
                                    Grand Total
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="2">Annual</th>
                                <th colSpan="2">Short Term</th>
                                <th colSpan="2">Long Term</th>
                                <th colSpan="2">Total</th>
                                <th colSpan="2">Annual</th>
                                <th colSpan="2">Short Term</th>
                                <th colSpan="2">Long Term</th>
                                <th colSpan="2">Total</th>
                                <th colSpan="2">Short Term</th>
                                <th rowSpan="2" className="align-middle"
                                    style={{
                                        verticalAlign: 'middle',
                                        whiteSpace: 'normal',
                                        width: '100px',
                                        padding: '10px',
                                    }}>Total</th>
                            </tr>
                            <tr>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                                <th>New</th>
                                <th>Renew</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.teacher.annual.new}</td>
                                    <td>{row.teacher.annual.renew}</td>
                                    <td>{row.teacher.shortTerm.new}</td>
                                    <td>{row.teacher.shortTerm.renew}</td>
                                    <td>{row.teacher.longTerm.new}</td>
                                    <td>{row.teacher.longTerm.renew}</td>
                                    <td className="text-danger fw-bold">
                                        {row.teacher.total.new}
                                    </td>
                                    <td className="text-danger fw-bold">
                                        {row.teacher.total.renew}
                                    </td>
                                    <td>{row.institute.annual.new}</td>
                                    <td>{row.institute.annual.renew}</td>
                                    <td>{row.institute.shortTerm.new}</td>
                                    <td>{row.institute.shortTerm.renew}</td>
                                    <td>{row.institute.longTerm.new}</td>
                                    <td>{row.institute.longTerm.renew}</td>
                                    <td className="text-danger fw-bold">
                                        {row.institute.total.new}
                                    </td>
                                    <td className="text-danger fw-bold">
                                        {row.institute.total.renew}
                                    </td>
                                    <td>{row.student.shortTerm.new}</td>
                                    <td>{row.student.shortTerm.renew}</td>
                                    <td className="text-danger fw-bold">
                                        {row.student.total}
                                    </td>
                                    <td className="text-success fw-bold">
                                        {row.grandTotal}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className='fw-bold'>Total</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold text-danger'>0</td>
                                <td className='fw-bold text-danger'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold text-danger'>0</td>
                                <td className='fw-bold text-danger'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='fw-bold'>0</td>
                                <td className='text-danger fw-bold'>0</td>
                                <td className='text-success fw-bold'>0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default CategoryWiseMembership;
