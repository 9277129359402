import { BrowserRouter } from "react-router-dom"
import Routing from './routes/route'
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "./app/auth/authSlice";
import { api } from "./app/services/api";
import ScrolToTop from "./Component/ScrolToTop";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
const contentFontFamily = 'poppins';
const headerFontFamily = 'Alata';
const theme = createTheme({
  palette: {
    primary: {
      main: '#104e29',
      dark:'#104035'
    },
    secondary: {
      main: '#601e1d',
      dark: '#701e1d'
    },
  },
  typography: {
    h1: {
      fontFamily: headerFontFamily,
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: headerFontFamily,
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: headerFontFamily,
    },
    h4: {
      fontFamily: headerFontFamily,
    },
    h5: {
      fontFamily: headerFontFamily,
    },
    h6: {
      fontFamily: headerFontFamily,
    },
    p: {
      fontFamily: contentFontFamily,
    },
    span: {
      fontFamily: contentFontFamily,
    },
    a: {
      fontFamily: contentFontFamily,
    },
    b: {
      fontFamily: contentFontFamily,
    },
    strong: {
      fontFamily: contentFontFamily,
    },
    em: {
      fontFamily: contentFontFamily,
    },
    i: {
      fontFamily: contentFontFamily,
    },
    // Set custom font family for the body text (content)
    body1: {
      fontFamily: contentFontFamily,
    },
    body2: {
      fontFamily: contentFontFamily,
    },
    // Optionally, you can specify the font for other elements (like button text, captions, etc.)
    button: {
      fontFamily: contentFontFamily,
    },
    input:{
      fontFamily: contentFontFamily,  
    },
    label:{
      fontFamily: contentFontFamily, 
    },
    form:{
      fontFamily: contentFontFamily, 
    }
  },
});

function App() {
  const dispatch = useDispatch()


  useEffect(() => {
    let idleTimer;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => { dispatch(logOut()); dispatch(api.util.resetApiState()) }, 1800000); // Set idle after 5 seconds of inactivity
    };

    const handleUserActivity = () => {
      resetIdleTimer();
    };

    // Initial setup
    resetIdleTimer();

    // Event listeners
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(idleTimer);
    };
  }, [dispatch]);
  return (
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer className="mt-5" />
        <ScrolToTop />
        <Routing />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App
