import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useLazyGetAllSIGQuery } from "../../app/services/SIGServices";
import { useLazyGetAllChaptersQuery } from "../../app/services/chapterServices";
import { useLazyGetAllMemberShipDetailsQuery } from "../../app/services/memberShipServices";

const MemberShipInfo = ({ planIdFromRoute, planTypeIdFromRoute }) => {
  const [SIGGroups, setSIGGroups] = useState([]);
  const [allChapters, setAllChapters] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [membership, setMemberShip] = useState([]);
  const [membershipSub, setMemberShipSub] = useState([]);
  const { control, watch, getValues, setValue } = useFormContext();
  const [getAllChapters, { isLoading }] = useLazyGetAllChaptersQuery();
  const [getAllSIG] = useLazyGetAllSIGQuery();
  const [getAllMershipPlans] = useLazyGetAllMemberShipDetailsQuery();  

  //All SIG
  useEffect(() => {
    getAllSIG().then((res) => {
      setSIGGroups(res?.data?.data);
    });

    //ALL chapter
    getAllChapters().then((res) => {
      setAllChapters(res?.data?.data);
    });

    //getAllMembershipPlan
    getAllMershipPlans().then((res) => {
      const allPlans = res?.data?.data;
      const activePlans = allPlans?.filter((plan) => plan?.isDeleted === 0);
      setMemberShip(activePlans);
    });
  }, [SIGGroups, getAllSIG, getAllChapters, allChapters, getAllMershipPlans]);

  //getSubcategory
  const getSubCategory = useCallback(
    (planId) => {
      membership.forEach((item) => {
        if (item._id === planId) {
          setMemberShipSub(item.planType);
          }
      });
    },
    [membership]
  );

  //getSubcategory
  useEffect(() => {
    const membership = getValues("planId");
    if (membership) {
      getSubCategory(membership);
    }
  }, [membership, getValues, getSubCategory]);

 //setAmount
  const setAmount = (value) => {
    const selectedPlanType = membershipSub.filter((item) => item._id === value);
    setValue("amount", selectedPlanType[0]?.amount);
  };

  const password = watch("password", "");
  
  //patch value
  useEffect(() => {
    if (planIdFromRoute && planTypeIdFromRoute) {
      setValue('planId', planIdFromRoute);
      getSubCategory(planIdFromRoute);
      setValue('planTypeId', planTypeIdFromRoute);
      const selectedPlanType = membershipSub.filter((item) => item._id === planTypeIdFromRoute);
      setValue("amount", selectedPlanType[0]?.amount);
    }
  }, [planIdFromRoute, setValue, planTypeIdFromRoute, getSubCategory, membershipSub]);

  return (
    <>
      <Stack spacing={5} direction={"column"} width={"100%"}>
        <Stack spacing={2} direction={"row"}>
          <Controller
            control={control}
            name="planId"
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel id="membership">
                  <Typography component="span" className="text-dark">
                    MemberShip Plan <span style={{ color: "red" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  labelId="membership"
                  id="membership"
                  value={field.value ? field.value : ""}
                  label="Membership plan"
                  name={field.name}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue('planTypeId','')                   
                    getSubCategory(e.target.value);
                  }}
                  error={!!fieldState?.invalid}
                  readOnly={planIdFromRoute ? true : false}
                >
                  {membership?.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.planName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="text-danger">
                  {fieldState?.error?.message}
                </FormHelperText>
              </FormControl>
            )}
            rules={{ required: "Please Select membership plan" }}
          />
          <Controller
            control={control}
            name="planTypeId"
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel id="membership">
                  <Typography component="span" className="text-dark">
                    MemberShip Plan Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                </InputLabel>
                <Select
                  labelId="membership"
                  id="membership"
                  value={field.value ? field.value : ""}
                  label="Membership sub category"
                  name={field.name}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setAmount(e.target.value);
                  }}
                  error={!!fieldState?.invalid}
                  readOnly={planTypeIdFromRoute  ? true : false}
                >
                  {membershipSub.length > 0 ? (
                    membershipSub.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.planTypeName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>Please Select a membership type</MenuItem>
                  )}
                </Select>
                <FormHelperText className="text-danger">
                  {fieldState?.error?.message}
                </FormHelperText>
              </FormControl>
            )}
            rules={{ required: "Please Select membership plan type" }}
          />
        </Stack>

        <Controller
          control={control}
          name="chapter"
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel id="membership">Chapters</InputLabel>
              <Select
                labelId="membership"
                id="membership"
                value={field.value ? field.value : ""}
                label="Membership Plan"
                name={field.name}
                onChange={field.onChange}
                error={!!fieldState?.invalid}
              >
                {!isLoading && allChapters.length > 0 ? (
                  allChapters.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>Loading....</MenuItem>
                )}
              </Select>
              <FormHelperText className="text-danger">
                {fieldState?.error?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="sigGroups"
          render={({ field, fieldState }) => {
            return (
              <Autocomplete
                multiple
                limitTags={2}
                id="tags-outlined"
                options={SIGGroups ? SIGGroups : ["Please Slect"]}
                name={field.name}
                getOptionLabel={(option) => {
                  return option ? option.groupName : "";
                }}
                filterSelectedOptions
                inputValue={inputValue}
                onChange={(event, values) => {
                  const selectedIds = values.map((value) => value._id); // Extract _id from selected options
                  field.onChange(selectedIds); // Pass array of selected _ids to onChange
                }}
                getOptionSelected={(option, value) => option._id === value._id}
                onInputChange={(_, newInputValue) =>
                  setInputValue(newInputValue)
                }
                value={
                  field.value
                    ? field.value.map((id) =>
                        SIGGroups.find((group) => group._id === id)
                      )
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    error={!!fieldState?.invalid}
                    {...params}
                    name="sigGroups"
                    label="SIG Groups"
                    placeholder={watch('sigGroups') ? '' : "Select SIGs You are Interested in"}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            );
          }}
        />
        <Stack spacing={2} direction={"row"}>
          <Controller
            control={control}
            name="password"
            render={({ field, formState }) => (
              <TextField
                fullWidth
                type="password"
                label={
                  <Typography className="text-dark">
                    Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                id="password"
                value={field.value}
                error={!!formState?.errors?.password}
                helperText={formState?.errors?.password?.message}
                placeholder="Enter a new password"
                {...field}
              />
            )}
            rules={{
              required: "Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$/,
                message:
                  "Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8",
              },
            }}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, formState }) => (
              <TextField
                fullWidth
                type="password"
                label={
                  <Typography className="text-dark">
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                id="confirmPassword"
                value={field.value}
                error={!!formState?.errors?.confirmPassword}
                helperText={formState?.errors?.confirmPassword?.message}
                placeholder="Enter Your Password Again"
                {...field}
              />
            )}
            rules={{
              required: "Password do not match",
              validate: (value) => {
                if (value !== password) {
                  return "Password do not match";
                }
                return true;
              },
            }}
          />
        </Stack>
        <Controller
          control={control}
          name="termsCondition"
          render={({ field, formState }) => (
            <FormControl {...field} error={!!formState?.errors?.termsCondition}>
              <FormControlLabel
                className="text-color-head"
                control={<Checkbox value={field.value} checked={field.value} />}
                label={
                  <Typography>
                    Accept Terms and Conditions to Continue 
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                }
              />
              <FormHelperText>
                {formState?.errors?.termsCondition?.message}
              </FormHelperText>
            </FormControl>
          )}
          rules={{ required: "Please accept Terms and Conditions to continue" }}
        />
      </Stack>
    </>
  );
};

export default MemberShipInfo;
