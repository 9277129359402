import { Box, Button, FormHelperText, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TopHeader from "../../Component/Header/TopHeader";
import {
  useSaveTestimonialMutation,
  useLazyGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
} from "../../app/services/testimonialService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import Grid from "@mui/material/Grid";
import userlogo from "../../assets/images/login/userLogo.jpg";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";

const AddEditTestimonals = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    getValues,
    watch
  } = useForm();
  const [saveTestimonial] = useSaveTestimonialMutation();
  const [updateTestimonial] = useUpdateTestimonialMutation();
  const [getTestimonialById] = useLazyGetTestimonialByIdQuery();
  const { testimonialId } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [isImageError, setIsImageError] = useState(false);
  const memberScope = useSelector(selectCurrentScope);


  //get Testimonial By Id 
  useEffect(() => {
    if (testimonialId) {
      setLoading(true);
      getTestimonialById(testimonialId).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          const data = res.data?.data;
          if (data.imageUrl.length > 0) {
            // Add a timestamp to the URL to force reload (cache-busting)
            const cacheBustedUrl = `${data.imageUrl}?t=${new Date().getTime()}`;
            setValue("imageUrl", cacheBustedUrl);
          } else {
            setValue("imageUrl", "");
          }
          setValue("content", data.content);
          setValue("name", data.name);
        }
      });
    }
  }, [testimonialId, getTestimonialById, setValue]);

  //submit& update
  const onFormSubmit = (formData) => {
    if (isImageError) {
      return;
    }
    if (isValid) {
      setLoading(true);
      if (testimonialId) {
        let updateData;
        const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
        if (base64Regex.test(formData.imageUrl)) {
          updateData = { id: testimonialId, formData };
        } else {
          updateData = {
            id: testimonialId,
            formData: { content: formData.content, name: formData.name, imageUrl: formData.imageUrl },
          };
        }
        updateTestimonial(updateData).then((res) => {
          if (res.data?.status) {
            toast.success("Testimonial Updated Successfully!!!");
            setLoading(false);
            if (memberScope === 'PORTAL_SUPER_ADMIN') {
              navigate("/admin/viewTestimonials");
            }
            else {
              navigate("/assistantadmin/viewTestimonials");
            }
            reset();
          } else {
            toast.error(res.data?.err?.message);
          }
        });
      } else {
        saveTestimonial(formData).then((res) => {
          if (res.data?.status) {
            toast.success("Testimonial Added Successfully!!!");
            setLoading(false);
            if (memberScope === 'PORTAL_SUPER_ADMIN') {
              navigate("/admin/viewTestimonials");
            }
            else {
              navigate("/assistantadmin/viewTestimonials");
            }
            reset();
          } else {
            toast.error(res.data?.err?.message);
          }
        });
      }
    }
  };

  //handleImageChange
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setValue("imageUrl", "");
      setImageErrorMessage("");
      setSelectedImage(null);
      setIsImageError(false);
      return;
    }
    if (!file.type.startsWith("image/")) {
      setImageErrorMessage("Please select a image file.");
      setSelectedImage(null);
      setIsImageError(true);
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      setImageErrorMessage("File size exceeds 2 MB.");
      setSelectedImage(null);
      setIsImageError(true);
      return;
    } else {
      setImageErrorMessage("");
      setIsImageError(false);
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue("imageUrl", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center title-font text-light py-3 mt-1">{testimonialId ? "Edit Testimonial" : "Add Testimonial"}</h2>
        </div>
      </div>
      <div className=" mt-5">
        <div className="row">
          <div className='p-md-5 p-3 overallPadding' >
            {loading ? (
              <Spinner />
            ) : (
              <Box
                component={"form"}
                className="d-flex flex-column "
                onSubmit={handleSubmit(onFormSubmit)}
              >
                <div className=" text-end mb-3">
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    className="menus-color"
                    style={{ float: "right" }}
                  >
                    Back
                  </Button>
                </div>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  {testimonialId ? (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <img
                          src={selectedImage || getValues("imageUrl") || userlogo}
                          className="w-100"
                          height="180"
                          alt="logo"
                        />
                        <div className="d-flex justify-content-center mt-3">
                          <input
                            accept="image/jpeg, image/png"
                            id="imageUrl"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />
                          <label htmlFor="imageUrl">
                            <Button
                              variant="contained"
                              className="menus-color"
                              component="span"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Choose File
                            </Button>
                          </label>
                        </div>
                        {imageErrorMessage && (
                          <FormHelperText error={imageErrorMessage}>
                            {imageErrorMessage}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          InputLabelProps={{ shrink: "true" }}
                          id="name"
                          label={
                            <Typography className="text-dark">
                              Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          placeholder="Enter name"
                          style={{ height: "max-content" }}
                          {...register("name", {
                            required: "Please enter your name",
                          })}
                          className="form-control"
                          error={!!errors?.name}
                          helperText={<span className="error-message">{errors?.name?.message}</span>}
                        ></TextField>
                        <TextField
                          rows={4}
                          multiline
                          id="message"
                          {...register("content", {
                            required: "Please enter your review",
                          })}
                          placeholder="Enter review"
                          className="form-control"
                          inputProps={{ maxLength: 150 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="text-success fw-medium">
                                {150 - (watch('content')?.length != null || 'NAN' || undefined) ? 150 - watch('content')?.length : 150}
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors?.content}
                          helperText={errors?.content?.message}
                        ></TextField>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6} lg={6} className="d-flex flex-column gap-3">
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                          id="imageUrl"
                          label="User Image"
                          variant="outlined"
                          type="file"
                          placeholder="Please Select a image"
                          onChange={handleImageChange}
                          error={imageErrorMessage}
                          helperText={imageErrorMessage}
                        />
                        <TextField
                          InputLabelProps={{ shrink: "true" }}
                          id="name"
                          label={
                            <Typography className="text-dark">
                              Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          placeholder="Enter name"
                          style={{ height: "max-content" }}
                          {...register("name", {
                            required: "Please enter your name",
                          })}
                          className="form-control"
                          error={!!errors?.name}
                          helperText={<span className="error-message">{errors?.name?.message}</span>}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          rows={4}
                          multiline
                          id="message"
                          {...register("content", {
                            required: "Please enter your review",
                          })}
                          placeholder="Enter review"
                          className="form-control"
                          inputProps={{ maxLength: 150 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="text-success fw-medium">
                                {150 - (watch('content')?.length != null || 'NAN' || undefined) ? 150 - watch('content')?.length : 150}
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors?.content}
                          helperText={errors?.content?.message}
                        ></TextField>
                      </Grid>
                    </>
                  )}
                </Grid>
                <div className="d-flex justify-content-center mt-3">
                  <Button variant="contained" className="menus-color" type="submit">
                    {testimonialId ? "Update" : "Submit"}
                  </Button>
                </div>
              </Box>

            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditTestimonals;
