import React, { useState, useEffect } from 'react'
import './AnnulConference.css';
import { Link } from 'react-router-dom';
import Report from '../../../assets/images/interface/icons.png'
import Gallery from '../../../assets/images/interface/Vector.png'
import { keyFeaturesofEltai, AttendingEltaiAnnualConferences } from '../../../constant/constant'
import { useLazyGetAllAnnualConferenceQuery, useGetGalleryByCategoryMutation } from "../../../app/services/annualConferenceService";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AnnualConference = () => {
  const [getAllAnnualConference] = useLazyGetAllAnnualConferenceQuery();
  const [getAll, setGetAll] = useState([]);
  const [getGallery] = useGetGalleryByCategoryMutation();
  const navigator = useNavigate()

  useEffect(() => {
    getAllAnnualConference().then((res) => {
      if (res?.data?.status) {
        setGetAll(res?.data?.data)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }, [getAllAnnualConference])

  useEffect(() => {
  }, [getAll])

  const handleGallery = (Name) => {
    const data = {
      categoryName: Name
    }
    getGallery(data).then((res) => {
      let GalleryData
      if (res?.data?.status) {
        GalleryData = res?.data?.data
        const isGallery = GalleryData?.find((get) => get.category === Name)
        navigator(`/showGallery/${isGallery._id}`)

      } else {
        toast.error('No Data Found')
      }
    }).catch((err) => console.error(err))
  }

  return (
    <div className="pt-5">
      <header className="chapter-heading">
        <h2 className="text-center title-font text-light py-3 mt-1">ELTAI Annual Conferences</h2>
      </header>
      <div className='p-md-5 p-3  overallPadding'>
        <div maxWidth="xl">
          <p className='SigEltai-paragraph content-font'>
            The English Language Teachers’ Association of India (ELTAI) hosts annual conferences that serve as a
            premier gathering for educators, researchers,and practitioners in the field of English language teaching.
            These conferences are designed to foster professional development,facilitate networking, and promote the
            exchange of innovative ideas and practices.
          </p>
          <div className='d-flex justify-content-center justify-content-md-end'>
            <div className='btn btn-primary px-5 py-2 annual-conference-background-button content-font rounded-pill'>The 18th International and 54th
              <br /> Annual Conferences of ELT@I </div>
          </div>
          <div className='d-flex justify-content-center justify-content-md-end'>
            <a className='btn btn-primary px-5 py-2 annual-conference-background-button1 content-font rounded-pill h-50' href="https://www.eltaiconferences.in/" target="_blank" rel="noreferrer">https://www.eltaiconferences.in/
              <br /> 23rd - 26th (Wed-Sat)<br />October 2024
            </a>
          </div>
          <h3 className='india-reads-header-font fw-semibold title-font'>Key Features of ELTAI Annual Conferences</h3>
          {keyFeaturesofEltai.map((data, i) => {
            return (
              <>
                <ul key={i}>
                  <li>
                    <p className='content-font SigEltai-paragraph '>
                      <span className='title-font SigEltai-paragraph fw-semibold '>{data.header}:</span>
                      {data.content}
                    </p>
                  </li>
                </ul>
              </>
            )
          })}
          <h3 className='india-reads-header-font mt-5 fw-semibold title-font'>
            Benefits of Attending ELTAI Annual Conferences
          </h3>
          {AttendingEltaiAnnualConferences.map((data, i) => {
            return (
              <>
                <ul key={i}>
                  <li>
                    <p className='content-font SigEltai-paragraph '>
                      <span className='title-font SigEltai-paragraph fw-semibold '>{data.header}:</span>
                      {data.content}
                    </p>
                  </li>
                </ul>
              </>
            )
          })}
          <h3 className='india-reads-header-font mt-5 fw-semibold title-font'>
            Join Us at the Next ELTAI Annual Conference
          </h3>
          <p className='content-font SigEltai-paragraph'>Whether you are a seasoned educator or new to the field, ELTAI annual conferences offer something for
            everyone. Stay tuned for announcements about our next conference and be a part of this enriching
            experience. For more information, visit our website https://eltaiconferences.in or contact us at
            <a className='p-1 content-font' style={{ color: '#1C367A' }} href="mailto:indiaeltai@gmail.com">indiaeltai@gmail.com</a></p>
          <p className='title-font SigEltai-paragraph fw-bold pt-3'>Come, learn, share, and grow with ELTAI!</p>
        </div>
        <div maxWidth="xl" className='mb-5'>
          <h2 className='title-font fw-semibold text-center mt-5 mb-4'>Annual Conference</h2>
          <div className='d-none d-md-inline'>
            <>
              <table class="table table-bordered gap-between-header-body">
                <thead>
                  <tr>
                    <th scope="col" className='annual-table-odd title-font'>S.No</th>
                    <th scope="col" className='annual-table-even title-font'>Name of the Conference</th>
                    <th scope="col" className='annual-table-odd title-font'>Venue</th>
                    <th scope="col" className='annual-table-even title-font'>Date</th>
                    <th scope="col" className='annual-table-odd title-font'>Media</th>
                  </tr>
                </thead>
                <tbody className='pt-4'>
                  {getAll && getAll.length > 0 ? <>
                    {getAll.map((data, i) => {
                      return (
                        <tr className=' ' key={i}>
                          <td className=' annual-table-body content-font'>{i + 1}</td>
                          <td className='text-center annual-table-body content-font'>{data.conferenceName ? <>{data.conferenceName}</> : <>----</>}</td>
                          <td className='text-center annual-table-body content-font'>{data.venue}</td>
                          <td className='text-center annual-table-body content-font'>{data.date}</td>
                          {/* <td className='text-center annual-table-body'>{data.media ? (<> <a style={{color:"#000"}} href="mailto:indiaeltai@gmail.com">Report</a><Link  style={{color:"#000"}}  to="/gallery-table">Gallery</Link></>) : (<></>)}</td> */}
                          <td className='text-center annual-table-body content-font'>
                            {getAll ? (
                              <>
                                {/* Text Links for Desktop/Laptop */}
                                <span className="d-none d-lg-inline">
                                  <a style={{ color: "#000" }} href="mailto:indiaeltai@gmail.com">Report</a>
                                  {/* <Link style={{ color: "#000", marginLeft: '10px' }} to="/gallery-table">Gallery</Link> */}
                                  <Link style={{ color: "#000", marginLeft: '10px' }} className="cursor-pointer" to="" onClick={() => handleGallery(data.conferenceName)}>Gallery</Link>
                                </span>
                                {/* Icon Links for Mobile/Tablet */}
                                <span className="d-inline d-lg-none">
                                  <a href="mailto:indiaeltai@gmail.com" style={{ color: "#000" }}>
                                    <img src={Report} alt='report' />
                                  </a>
                                  <Link to="/gallery-table" style={{ color: "#000", marginLeft: '10px' }}>
                                    <img src={Gallery} alt='gallery' className="cursor-pointer" onClick={() => handleGallery(data.conferenceName)} />
                                  </Link>
                                </span>
                              </>
                            ) : <div className='text-center'>----</div>}
                          </td>
                        </tr>
                      )
                    })}
                  </> :
                    <tr>
                      <td colSpan={5} align='center' className='text-danger fs-5 signika-font-startingchapter text-center'>No Data Found</td>
                    </tr>}
                </tbody>
              </table>
            </>
          </div>
          <div className='d-inline d-md-none'>
            <div className='row'>
              {getAll && getAll.length > 0 ?
                <>
                  {getAll.map((data, i) => (<>
                    <div className='col-md-4 mt-3'>
                      <div className='card shadow p-3  px-4'>
                        {data.name ? <>
                          <p className='SigEltai-paragraph signika-font SigEltai-Green fw-semibold mobile-view-annual-header pt-3'>Name: <span className='signika-font mobile-view-annual-conf text-dark '>{data.conferenceName ? <>{data.conferenceName}</> : <>----</>}</span></p>
                        </> : <></>
                        }
                        <p className='SigEltai-paragraph signika-font  fw-semibold mobile-view-annual-header pt-2 annual-brown-color'>Venue: <span className='signika-font mobile-view-annual-conf text-dark '>{data.venue}</span></p>
                        <p className='SigEltai-paragraph signika-font SigEltai-Green fw-semibold mobile-view-annual-header pt-2'>Date: <span className='signika-font mobile-view-annual-conf text-dark '>{data.date}</span></p>
                        {getAll ? <>
                          <p className='text-center signika-font annual-brown-color fw-semibold mobile-view-annual-header pt-2'>Media</p>
                          <div className='d-flex justify-content-center gap-5'>
                            <img src={Report} alt='report' />
                            <img src={Gallery} alt='gallery' className="cursor-pointer" onClick={() => handleGallery(data.conferenceName)} />
                            <div className='row'>
                            </div>
                          </div>
                        </> : <div className='text-center'>------</div>}
                        {getAll ? <>
                          <div className='d-flex justify-content-center gap-5'>
                            <p className='signika-font mobile-view-annual-conf'>Report</p>
                            <p className='signika-font mobile-view-annual-conf cursor-pointer' onClick={() => handleGallery(data.conferenceName)}>Gallery</p>
                          </div>
                        </> : <></>}
                      </div>
                    </div>
                  </>))}
                </> : <> No Data Found</>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnualConference