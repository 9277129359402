import React, { useEffect, useRef, useState } from "react";
import "../../../src/style/css/footer.css";
import { Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useSaveMailMutation } from "../../app/services/subscription";
import logo from "../../assets/images/logos/ELTA@I Logo Source File2.png";
import bg from "../../assets/images/footer/footer.jpg";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import XIcon from '@mui/icons-material/X';

function Footer() {
  const [saveSubscriptionMail] = useSaveMailMutation();
  const [successMessage, setSuccessMessage] = React.useState("");
  const sendButtonRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(true);
  const [isTargetedIpad, setIsTargetedIpad] = useState(false);
  const iframeRef = useRef(null);


  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    // Detect iPad Air running iOS 12.5.7
    const userAgent = navigator.userAgent || "";

    if (/iPad/.test(userAgent) && /OS 12_5_7/.test(userAgent)) {
      setIsTargetedIpad(true);
    }

    if (isTargetedIpad && iframeRef.current) {
      const iframe = iframeRef.current;

      // Define event handlers
      const handleLoad = () => setMapLoaded(true);
      const handleError = () => setMapLoaded(false);

      // Add event listeners to the iframe for Chrome and Firefox compatibility
      iframe.onload = handleLoad; // for Chrome and Firefox
      iframe.onerror = handleError; // explicitly handle errors for iframe

      // Set a timeout to show fallback message if iframe doesn't load within 5 seconds
      const loadTimeout = setTimeout(() => {
        setMapLoaded(false);
      }, 5000);

      // Clean up listeners and timeout
      return () => {
        clearTimeout(loadTimeout);
        iframe.onload = null;
        iframe.onerror = null;
      };
    }
  }, [isTargetedIpad]);

  const saveMail = (FormData) => {
    saveSubscriptionMail(FormData).then((res) => {
      setSuccessMessage("Subscribed successfully.");
      reset();
      setTimeout(() => setSuccessMessage(false), 2000);
    });
  };

  useEffect(() => {
    const button = document.querySelector("#back-to-top button");
    button?.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    // Cleanup function to remove event listener when component unmounts
    return () => {
      button?.removeEventListener("click", () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    };
  }, []);

  return (
    <footer style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "auto" }}>
      <div className="footerScale d-flex justify-content-center justify-content-lg-between">
        <div className="d-flex justify-content-center ">
          {isTargetedIpad && !mapLoaded ? (
            <p style={{ color: "white" }}>To view the map, please update your browser.</p>

          ) : (
            <iframe
              ref={iframeRef}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.8350962890604!2d80.1966964779552!3d13.109631054262467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52646dcf38879d%3A0x66d3a4d2b75a093e!2sAnandam%20Flats%2C%201st%20Main%20Rd%2C%20South%20Jagannatha%20Nagar%2C%20Villivakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600049!5e0!3m2!1sen!2sin!4v1708067003584!5m2!1sen!2sin"
              style={{ border: "0" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="footer"
            />
          )}
        </div>
        <div className=" mt-5 px-md-5 px-lg-0 mt-lg-0">
          <div className="ms-4 ms-md-0">
            <h2 className=" signika-font fw-bold text-start text-light">Reach Us</h2>
            <div>
              <div className="d-flex justify-content-start align-items-start">
                <div className="me-2">
                  <LocationOnIcon className="text-light" />
                </div>
                <div>
                  <p className="ps-0 signika-font mb-0 text-start text-light">
                    English Language Teachers' Association of India
                    <br />D-54 Third Floor,
                    <br /> Anandham Apartments,
                    <br /> 156, SIDCO Nagar Main Road,
                    <br /> Villivakkam, Chennai.
                    <br /> Tamilnadu, India - 600049.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center my-2">
                <div className="me-2">
                  <LocalPhoneIcon className="text-light" />
                </div>
                <a href="tel:+919344425159" className="ps-0 signika-font text-light">
                  +91-9344425159
                </a>
              </div>
              <div className="d-flex justify-content-start align-items-center mb-3">
                <div className="me-2"><EmailIcon className="text-light" /></div>
                <a
                  href="mailto:indiaeltai@gmail.com"
                  className="ps-0 signika-font text-light">
                  indiaeltai@gmail.com
                </a>
              </div>
              <div className="d-flex justify-content-start align-items-center" style={{ color: "#1d924c" }}>
                <ul className="d-flex list-unstyled mb-0 ul-foot">
                  <li className="me-3"><a className='ps-0 a-className' href="https://www.facebook.com/ELTAI.India" target="blank"><i className="fa fa-facebook icon-config"></i></a></li>
                  <li className="me-3"><a className='ps-0 a-className' href="https://www.instagram.com/eltai.india" target="blank"><i className="fa fa-instagram icon-config-insta"></i></a></li>
                  <li className="me-3"><a className='ps-0 a-className' href="https://www.youtube.com/@ELTAIIndia" target="blank"><i className="fa fa-youtube icon-config-utube"></i></a></li>
                  <li className="twitter-list"><a className='ps-0 a-className' href="https://x.com/EltaiIndia" target="blank"><XIcon className="social-media-footer-icon-header" /></a></li>
                </ul>
              </div>

            </div>
          </div>

        </div>
        <div className="d-flex justify-content-md-center justify-content-lg-end justify-content-start align-items-center  mt-5">
          <div className="d-flex flex-column align-items-center mt-4 mt-md-0  footer-card2">
            <img src={logo} alt="eltai logo" className="img-fluid" width="300px" style={{ marginBottom: "20px" }} />
            <form action="#" onSubmit={handleSubmit(saveMail)} className="w-100 mt-2">
              <div className="form-row d-flex justify-content-center w-sm-100 ">
                <div className="d-flex w-100">
                  <input type="email" className="p-2 subscription border-md" {...register("email", { required: true })} placeholder="Subscribe to Our Newsletter" style={{ color: "white", background: '#0f3d20', height: "45px" }}
                  />
                  <Button type="submit"
                    className="menus-color"
                    ref={sendButtonRef}
                    style={{
                      pointerEvents: watch('email') ? 'auto' : 'none', height: "45px", minWidth: "53px" // Disable click interaction when empty
                    }}>
                    <i className="fa fa-send text-light" ></i>
                  </Button>
                </div>
              </div>
            </form>
            <div className="empty-none" style={{ width: "200%", maxWidth: "550px", minHeight: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {successMessage && (
                <Alert style={{ width: "45%" }} variant="filled" severity="success">
                  {successMessage}
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-md-5 px-3 mx-4 flex-md-row flex-column ">
        <div><p class=" text-white fw-bold pb-0 mb-0">Disclaimer :</p></div>
        <div><p class=" text-light" style={{ textAlign: "justify",fontSize:13 }}>For optimal performance, please update your chrome browser version 130.0.6723.117  and  safari  version 17</p></div>
      </div>
      <div className="copyright" style={{ backgroundColor: "#d3dcc7" }}>
        <div className="p-2 px-4">
          <div className="d-flex flex-md-row flex-column justify-content-md-around justify-content-center gap-3 align-items-start align-items-md-center">
            <span className="text-black ">
              Copyright © {new Date().getFullYear()}<b> ELTAI</b> - All Rights Reserved
            </span>
            <span>
              {/* Website last updated on <span className="fw-bold">{new Date().toLocaleDateString()}</span> | Visitors : <span className="fw-bold"> 100202122</span> */}
            </span>
            <span>
              Designed with by
              <a
                className="text-decoration-none text-black p-0 ms-1"
                target="_blank"
                href="https://www.benhive.com"
                rel="noreferrer"
              >
                <b>BenHive{" "}Technologies</b>
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
