import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: "Alata", // Set default font for the entire app
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Alata !important",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    fontFamily: "Alata !important",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "Alata !important",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: "Alata !important",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Alata !important",
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins !important",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins !important",
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins !important",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins !important",
                },
            },
        },
    },
});

export default theme;
